import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

/* React functional component */
const MaterialsScreenLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* useLocation destructuring */
  const location = useLocation();

  /* variable to store active tab key */
  let activeKey = 'rawMaterialsKey';

  /* update heading and activeKey according to active route */
  if (location.pathname.includes('products')) {
    activeKey = 'productsKey';
  } else if (location.pathname.includes('items')) {
    activeKey = 'itemsKey';
  } else if (location.pathname.includes('productTypes')) {
    activeKey = 'productTypesKey';
  }

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="rawMaterialsKey"
        onTabClick={(key: string) => {
          if (key === 'rawMaterialsKey') {
            navigate('rawMaterials');
          } else if (key === 'productsKey') {
            navigate('products');
          } else if (key === 'productTypesKey') {
            navigate('productTypes');
          } else {
            navigate('items');
          }
        }}
        activeKey={activeKey}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab="Raw Materials" key="rawMaterialsKey">
          <Outlet />
        </TabPane>
        <TabPane tab="Products" key="productsKey">
          <Outlet />
        </TabPane>
        <TabPane tab="Product Types" key="productTypesKey">
          <Outlet />
        </TabPane>
        <TabPane tab="Items" key="itemsKey">
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MaterialsScreenLayout;
