import React, { useState } from 'react';
import { useForm, FieldError } from 'react-hook-form';
import { message, Button } from 'antd';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, ApolloError, Reference, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { errorHandlerForAddOrEditVendorForm, logger } from '../../../utils/helpers';
import {
  CreateVendorMutation,
  CreateVendorMutationVariables,
  UpdateVendorByPkMutation,
  UpdateVendorByPkMutationVariables,
  GetAllItemCategoriesQuery,
  GetAllItemCategoriesQueryVariables,
  Enum_Item_Category_Enum,
  RmSellers,
} from '../../../graphql/graphql-types';
import {
  statesOptionsForSelect,
  vendorOrBuyerHistoryFormItemStyleProps,
} from '../../../utils/globals';
import Select from '../../../components/Select';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import { inputComponentCommonStyle } from '../../../utils/globals';
import { OptionsDataType, AddressType, EditVendorDataType } from '../../../utils/types';
import RequiredMessage from '../../../components/RequiredMessage';
import RadioGroup from '../../../components/RadioGroup';

/* AddOrEditVendor form component props type */
type AddOrEditVendorFormComponentPropsType = {
  /* to check whether the AddOrEditVendor form is being called from edit mode or add mode */
  mode: 'edit' | 'add';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  editVendorData?: EditVendorDataType;
  /* below prop types get used when this form is called from 'Reports:-> 'Farmer/Trader purchase history' section  */
  /* this prop used to determine working of form buttons and UI */
  editVendorCalledFrom?: 'seller' | 'farmerOrTraderHistory';
  /* this prop used to close modal when form is called from 'Reports:-> 'Farmer/Trader purchase history' ' section */
  closeModal?: () => void;
};

/* add or edit vendor useForm type */
type AddOrEditVendorFormType = Pick<
  RmSellers,
  | 'id'
  | 'name'
  | 'gstin'
  | 'primaryContactEmail'
  | 'primaryContactMobile'
  | 'primaryContactName'
  | 'doesMaintenance'
> & {
  /* item category */
  itemCategory: Enum_Item_Category_Enum[];
} & AddressType;

/* formItem component styling props */
const editOrAddVendorFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 3,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* add or edit vendor form validation */
const schema = yup.object({
  name: yup.string().required(`Please enter name and try again.`),
  itemCategory: yup
    .array()
    .min(1, 'Please select item category and try again.')
    .required('Please select item category and try again'),
  primaryContactEmail: yup
    .string()
    .email('Please enter a valid email id and try again.')
    .nullable(),
  primaryContactName: yup.string().required(`Please enter contact name and try again.`).nullable(),
  gstin: yup
    .string()
    .matches(validationRegex.gstin, {
      message: 'Please enter a valid GSTIN ',
      excludeEmptyString: true,
    })
    .nullable(),
  primaryContactMobile: yup
    .string()
    .required(`Please enter mobile number and try again`)
    .matches(validationRegex.mobile, {
      message: 'Please enter a valid mobile number',
    })
    .nullable(),
  city: yup
    .string()
    .typeError('Please enter valid city name')
    .matches(/^[aA-zZ\s]+$/, {
      message: 'Please enter a valid name with alphabets only',
      excludeEmptyString: true,
    })
    .nullable(),
  pincode: yup.string().nullable().matches(validationRegex.pincode, {
    message: 'Please enter a valid 6-digit pincode',
    excludeEmptyString: true,
  }),
  doesMaintenance: yup
    .boolean()
    .when(['itemCategory'], {
      is: (itemCategory: Array<string>) => itemCategory.includes(Enum_Item_Category_Enum.PaddyBag),
      then: yup.boolean().required('Please select does maintenance and try again').nullable(),
    })
    .nullable(),
});

/* loading create vendor mutation  */
const createVendorMutation = loader('../../../graphql/mutations/createVendorMutation.graphql');

/* loading update vendor mutation */
const updateVendorMutation = loader('../../../graphql/mutations/updateVendorByPkMutation.graphql');

/* loading get all Item Categories vendors query */
const getItemCategoriesQuery = loader('../../../graphql/queries/getAllItemCategoriesQuery.graphql');

/* React functional component */
const AddOrEditVendorForm = ({
  mode,
  editVendorData = undefined,
  editVendorCalledFrom = 'seller',
  closeModal = () => {},
}: AddOrEditVendorFormComponentPropsType) => {
  /* this state used to show loading indicator on 'create Vendor' or 'Update' button, when creating new vendor or editing existing vendor form get submit */
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /* create new vendor mutation */
  const [createVendor] = useMutation<CreateVendorMutation, CreateVendorMutationVariables>(
    createVendorMutation,
  );

  /* update existing vendor mutation */
  const [updateVendor] = useMutation<UpdateVendorByPkMutation, UpdateVendorByPkMutationVariables>(
    updateVendorMutation,
  );

  /* get all Item Categories query */
  const { data: getItemCategoriesData } = useQuery<
    GetAllItemCategoriesQuery,
    GetAllItemCategoriesQueryVariables
  >(getItemCategoriesQuery);

  /* after loading getItemCategoriesData, this variable used to store item category options list. Which is then pass to Item Category select component */
  let itemCategoryOptions: OptionsDataType[] = [];

  /* if getItemCategoriesData loaded successfully , then show option list on item category select component */
  if (getItemCategoriesData) {
    /*store item category options */
    itemCategoryOptions = getItemCategoriesData.enum_item_category.map((item) => ({
      value: item.value,
      label: item.display_name as string,
    }));
  }

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* this const used to store formItem style props based on 'editVendorCalledFrom' prop value */
  const formItemStyleProps =
    editVendorCalledFrom === 'seller'
      ? editOrAddVendorFormItemStyleProps
      : vendorOrBuyerHistoryFormItemStyleProps;

  /* this function used to navigate or close modal, when form is in 'edit' mode */
  const navigateOrCloseModal = () => {
    /* if this form is called from vendor screen (management section) then navigate */
    if (editVendorCalledFrom === 'seller') {
      navigate('/management/sellers');
    } else {
      /* if this form is called from farmer/Trader purchase history (report section) then close modal */
      closeModal();
    }
  };

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<AddOrEditVendorFormType>({
    resolver: yupResolver(schema),
    defaultValues:
      mode === 'edit' && editVendorData
        ? {
            name: editVendorData.name,
            itemCategory: editVendorData.vendorsItemCategories.map((vendor) => vendor.itemCategory),
            gstin: editVendorData.gstin,
            primaryContactEmail: editVendorData.primaryContactEmail,
            primaryContactName: editVendorData.primaryContactName,
            primaryContactMobile: editVendorData.primaryContactMobile,
            address: editVendorData.address ? editVendorData.address.address : '',
            state: editVendorData.address ? editVendorData.address.state : null,
            city: editVendorData.address ? editVendorData.address.city : '',
            pincode: editVendorData.address ? editVendorData.address.pincode : '',
            doesMaintenance: editVendorData.doesMaintenance,
          }
        : {
            name: '',
            itemCategory: [],
            gstin: '',
            primaryContactEmail: '',
            primaryContactName: '',
            primaryContactMobile: '',
            address: '',
            state: null,
            city: '',
            pincode: '',
            doesMaintenance: null,
          },
    mode: 'onChange',
  });

  /* function to handle submitted data */
  const onSubmit = handleSubmit((data) => {
    /* when user entered valid form input, set isSubmitBtnLoading state to true */
    setIsSubmitBtnLoading(true);

    /* const used to store mutation variables which are common in both updateVendor and createVendor mutation */
    const commonMutationVariables = {
      name: data.name,
      address: {
        address: data.address || null,
        state: data.state || null,
        city: data.city || null,
        pincode: data.pincode || null,
      },
      gstin: data.gstin || null,
      primaryContactMobile: data.primaryContactMobile,
      primaryContactEmail: data.primaryContactEmail || null,
      primaryContactName: data.primaryContactName,
      doesMaintenance: watch('itemCategory').includes(Enum_Item_Category_Enum.PaddyBag)
        ? data.doesMaintenance
        : null,
    };

    /* if form is in edit mode, update existing vendor profile */
    if (mode === 'edit' && editVendorData) {
      updateVendor({
        variables: {
          id: editVendorData.id,
          objects: data.itemCategory.map((item) => ({
            itemCategory: item,
            vendorId: editVendorData.id,
          })),
          _set: {
            ...commonMutationVariables,
          },
        },
        update(cache, { data: updateData }) {
          /* data to update */
          const dataToUpdate = updateData?.updateRmSeller;
          cache.modify({
            fields: {
              rmSellers(existingRmSeller: Array<Reference>, { readField }) {
                if (dataToUpdate) {
                  return existingRmSeller.map((rMSellerData) => {
                    if (dataToUpdate.id === readField('id', rMSellerData)) {
                      return dataToUpdate;
                    }
                    return rMSellerData;
                  });
                }
                return existingRmSeller;
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Vendor has been updated successfully.');
          /* if this form is called from vendor screen (management section) then navigate otherwise close modal */
          navigateOrCloseModal();
          reset();
          setIsSubmitBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorHandlerForAddOrEditVendorForm(error));
          logger(error);
          setIsSubmitBtnLoading(false);
        });
    } else {
      createVendor({
        variables: {
          object: {
            ...commonMutationVariables,
            vendorsItemCategories: {
              data: data.itemCategory.map((item) => ({ itemCategory: item })),
            },
          },
        },
        update(cache, { data: addedVendor }) {
          /* extracting the new data added by user */
          const dataToAdd = addedVendor?.createRmSeller;
          cache.modify({
            fields: {
              rmSellers(existingVendors: Array<Reference>) {
                return [...existingVendors, dataToAdd];
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Vendor has been added successfully.');
          navigate('/management/sellers');
          reset();
          setIsSubmitBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorHandlerForAddOrEditVendorForm(error));
          setIsSubmitBtnLoading(false);
          logger(error);
        });
    }
  });

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={onSubmit}
      >
        <FormItem
          label="Name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter name of the vendor"
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Item Category"
          isRequired
          errorText={
            errors && errors.itemCategory
              ? (errors.itemCategory as unknown as FieldError).message
              : undefined
          }
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select item category"
            name="itemCategory"
            rhfControllerProps={{
              control,
            }}
            selectProps={{ showSearch: true }}
            mode="multiple"
            options={itemCategoryOptions}
          />
        </FormItem>
        <FormItem
          label="GST"
          {...formItemStyleProps}
          errorText={errors && errors.gstin ? errors.gstin.message : undefined}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter GST number of the vendor"
            name="gstin"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Contact Name"
          isRequired
          errorText={
            errors && errors.primaryContactName ? errors.primaryContactName.message : undefined
          }
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter contact name for vendor"
            name="primaryContactName"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Contact Mobile"
          isRequired
          errorText={
            errors && errors.primaryContactMobile ? errors.primaryContactMobile.message : undefined
          }
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter contact mobile number"
            name="primaryContactMobile"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Contact Email"
          errorText={
            errors && errors.primaryContactEmail ? errors.primaryContactEmail.message : undefined
          }
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter contact email ID"
            name="primaryContactEmail"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Address"
          errorText={errors && errors.address ? errors.address.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            name="address"
            isTextAreaInput={true}
            placeholder="Please enter address of the vendor"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
            textAreaProps={{ rows: 4 }}
          />
        </FormItem>
        <FormItem
          label="City"
          errorText={errors && errors.city ? errors.city.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter city of the vendor"
            name="city"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="State"
          errorText={errors && errors.state ? errors.state.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select state of the vendor"
            name="state"
            rhfControllerProps={{
              control,
            }}
            selectProps={{ showSearch: true }}
            options={statesOptionsForSelect}
          />
        </FormItem>
        <FormItem
          label="Pincode"
          errorText={errors && errors.pincode ? errors.pincode.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter pincode of the vendor"
            name="pincode"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>

        {watch('itemCategory').includes(Enum_Item_Category_Enum.PaddyBag) ? (
          <FormItem
            label="Does maintenance?"
            isRequired
            {...formItemStyleProps}
            errorText={
              errors && errors.doesMaintenance ? errors.doesMaintenance.message : undefined
            }
          >
            <RadioGroup
              name="doesMaintenance"
              options={[
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ]}
              rhfControllerProps={{
                control,
              }}
            />
          </FormItem>
        ) : null}
        <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
          <div
            style={
              editVendorCalledFrom === 'farmerOrTraderHistory'
                ? { display: 'flex', justifyContent: 'end' }
                : { display: 'block' }
            }
          >
            <Button
              htmlType="submit"
              type="primary"
              loading={isSubmitBtnLoading}
              style={{ marginRight: 10 }}
            >
              {mode === 'edit' ? 'Update' : 'Create Vendor'}
            </Button>
            <Button
              type="default"
              onClick={() => {
                /* if this form is called from vendor screen (management section) then navigate otherwise close modal */
                navigateOrCloseModal();
              }}
            >
              Cancel
            </Button>
          </div>
        </FormItem>
      </form>
    </>
  );
};

export default AddOrEditVendorForm;
