import React, { useState } from 'react';
import { Button, message, Popconfirm, Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { WarningFilled } from '@ant-design/icons';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import {
  DeletePurchaseOrderMutationMutation,
  DeletePurchaseOrderMutationMutationVariables,
  GetAllPurchaseOrderBasedOnItemCategoryQuery,
  GetAllPurchaseOrderBasedOnItemCategoryQueryVariables,
  PurchaseOrders,
  PurchaseOrderItems,
} from '../../graphql/graphql-types';
import { loader } from 'graphql.macro';
import dayjs from 'dayjs';
import { logger } from '../../utils/helpers';
import colors from '../../scss/variables.module.scss';
import getItemCategoryData from '../../utils/helpers/getItemCategoryData';

/* loading delete Purchase Order mutation  */
const deletePurchaseOrderMutation = loader(
  '../../graphql/mutations/deletePurchaseOrderMutation.graphql',
);

/* loading get All Purchase Order Based On Item Category query */
const getAllPurchaseOrderBasedOnItemCategory = loader(
  '../../graphql/queries/getAllPurchaseOrderBasedOnItemCategoryQuery.graphql',
);

/* type for view all PO */
type ViewAllPoTableType = Pick<
  PurchaseOrders,
  'created_at' | 'id' | 'poApproved' | 'poNumber' | 'delivery_completed_at'
> & {
  purchaseOrderItems: Array<
    Pick<PurchaseOrderItems, 'remarks'> & {
      /* item data */
      item: {
        /*item id */
        id: string;
        /* item name */
        name: string;
      };
      /*vendor data */
      vendor: {
        /* vendor id */
        id: number;
        /*vendor name */
        name: string;
      };
    }
  >;
};
/* React functional component */
const ViewAllPurchaseOrderScreen = (): JSX.Element => {
  /* useNavigate destructuring */
  const navigate = useNavigate();

  /* useLocation destructuring */
  const location = useLocation();

  // state to set the id of order whose delete button is clicked so that it can be se for loading indication while deleting data
  const [purchaseOrderIdToBtnLoading, setPurchaseOrderIdToBtnLoading] = useState<number | null>(
    null,
  );

  /* delete purchase order mutation */
  const [deletePurchaseOrder] = useMutation<
    DeletePurchaseOrderMutationMutation,
    DeletePurchaseOrderMutationMutationVariables
  >(deletePurchaseOrderMutation);

  /** calling `getItemCategoryData` function to get itemCategory data  */
  const itemCategory = getItemCategoryData({
    locationPathname: location.pathname,
  });

  /* get All Purchase Order Based On Item Category Data based on category name */
  const {
    data: getAllPurchaseOrderBasedOnItemCategoryData,
    loading: getAllPurchaseOrderBasedOnItemCategoryLoading,
    error: getAllPurchaseOrderBasedOnItemCategoryError,
  } = useQuery<
    GetAllPurchaseOrderBasedOnItemCategoryQuery,
    GetAllPurchaseOrderBasedOnItemCategoryQueryVariables
  >(getAllPurchaseOrderBasedOnItemCategory, { variables: { item_category: itemCategory.value } });

  /* function to be called when user click delete  button */
  const deleteFun = (purchaseId: number) => {
    setPurchaseOrderIdToBtnLoading(purchaseId);
    // delete purchase order mutation
    deletePurchaseOrder({
      variables: {
        id: purchaseId,
      },
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a purchaseOrder that was just removed. */
        const deletedDataId = deleteData?.delete_purchaseOrders_by_pk?.id;
        cache.modify({
          fields: {
            purchaseOrders(existingPurchase: Array<Reference>, { readField }) {
              if (deletedDataId) {
                return existingPurchase.filter(
                  (purchaseRef) => deletedDataId !== readField('id', purchaseRef),
                );
              }
              return existingPurchase;
            },
          },
        });
      },
    })
      .then(() => {
        setPurchaseOrderIdToBtnLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Purchase order has been successfully deleted.');
      })
      .catch((error: ApolloError) => {
        setPurchaseOrderIdToBtnLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error);
        logger(error);
      });
  };

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getAllPurchaseOrderBasedOnItemCategoryError) {
    return <div className="errorText">{getAllPurchaseOrderBasedOnItemCategoryError.message}</div>;
  }

  return (
    <>
      <Table<ViewAllPoTableType>
        dataSource={
          getAllPurchaseOrderBasedOnItemCategoryData &&
          getAllPurchaseOrderBasedOnItemCategoryData.purchaseOrders
        }
        loading={getAllPurchaseOrderBasedOnItemCategoryLoading}
        className="tableStyle"
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
        rowKey={(record) => record.id}
      >
        <Table.Column<ViewAllPoTableType> key="poNumber" title="PO No." dataIndex="poNumber" />
        <Table.Column<ViewAllPoTableType>
          key="created_at"
          title="Date"
          dataIndex="created_at"
          render={(text, record) => {
            return <>{dayjs(record.created_at).format('MMM DD, YYYY')}</>;
          }}
        />
        <Table.Column<ViewAllPoTableType>
          title="Vendor"
          dataIndex="vendorName"
          key="vendorName"
          render={(text, record) => {
            // we are fetching on 0th item because we added logic in create PO page that one order only one vendor can be selected.
            return (
              record.purchaseOrderItems[0] &&
              record.purchaseOrderItems[0].vendor &&
              record.purchaseOrderItems[0].vendor.name
            );
          }}
        />
        <Table.Column<ViewAllPoTableType>
          title="Items"
          dataIndex="item"
          key="item"
          render={(text, record) => {
            return record.purchaseOrderItems.map((data) => {
              return (
                <p key={data.item.id} style={{ margin: 0, padding: 0 }}>
                  {data.item.name}
                </p>
              );
            });
          }}
        />

        <Table.Column<ViewAllPoTableType>
          title="Status"
          dataIndex="status"
          key="status"
          filters={[
            {
              text: 'Draft',
              value: 'draft',
            },
            {
              text: 'Open',
              value: 'open',
            },
            {
              text: 'Close',
              value: 'close',
            },
          ]}
          onFilter={(value, record) => {
            if (value === 'open' && record.poApproved && !record.delivery_completed_at) {
              return true;
            }
            if (value === 'close' && !!record.delivery_completed_at) {
              return true;
            }
            if (!record.poApproved && value === 'draft') {
              return true;
            }
            return false;
          }}
          render={(text, record) => {
            let status;
            if (!!record.delivery_completed_at) {
              status = 'Close';
            } else if (record.poApproved) {
              status = 'Open';
            } else {
              status = 'Draft';
            }
            return status;
          }}
        />
        <Table.Column<ViewAllPoTableType>
          title="Delivery Summary"
          dataIndex="deliverySummary"
          width={150}
          key="deliverySummary"
          render={(val, record) => {
            const isPoMarkAsCompleted = !!record.delivery_completed_at;

            if (isPoMarkAsCompleted) {
              return (
                <Button
                  type="link"
                  onClick={() => {
                    navigate(`deliver/${record.id}`);
                  }}
                >
                  View
                </Button>
              );
            }
            return <span style={{ marginLeft: 20 }}>-</span>;
          }}
        />
        <Table.Column<ViewAllPoTableType>
          title="Actions"
          dataIndex="actions"
          key="actions"
          width={300}
          render={(text, record) => {
            const isPoMarkAsCompleted = !!record.delivery_completed_at;

            const approve = (
              <Button
                type="primary"
                style={{ marginRight: 10, width: 80 }}
                onClick={() => {
                  navigate(`approve/${record.id}`);
                }}
              >
                Approve
              </Button>
            );

            const deliver = (
              <Button
                type="primary"
                style={{
                  marginRight: 10,
                  width: 80,
                  backgroundColor: colors.colorGreen,
                  border: colors.colorGreen,
                }}
                onClick={() => {
                  navigate(`deliver/${record.id}`);
                }}
              >
                Deliver
              </Button>
            );

            const print = (
              <Button
                style={{ marginRight: 10, width: 80 }}
                onClick={() => {
                  navigate(`/purchaseOrders/print/${record.id}`, {
                    state: { poCreated: itemCategory.key },
                  });
                }}
              >
                Print
              </Button>
            );

            const deleteItem = (
              <Popconfirm
                title="Delete Order. Are you sure?"
                okText="Yes"
                onConfirm={() => {
                  deleteFun(record.id);
                }}
                okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                cancelText="No"
                icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                style={{ marginRight: 10, width: 80 }}
              >
                <Button
                  className="deleteButton"
                  loading={record.id === purchaseOrderIdToBtnLoading}
                >
                  Delete
                </Button>
              </Popconfirm>
            );

            if (isPoMarkAsCompleted) {
              return <>{print}</>;
            } else if (record.poApproved) {
              return (
                <>
                  {deliver}
                  {print}
                </>
              );
            } else {
              return (
                <>
                  {approve}
                  {deleteItem}
                </>
              );
            }
          }}
        />
      </Table>
    </>
  );
};

export default ViewAllPurchaseOrderScreen;
