import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, message } from 'antd';
import { loader } from 'graphql.macro';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { ApolloError, useMutation } from '@apollo/client';
import {
  Enum_User_Role_Enum,
  UserLoginMutation,
  UserLoginMutationVariables,
} from '../graphql/graphql-types';
import FormItem from '../components/FormItem';
import { useApp } from '../context/AppContext';
import { logger } from '../utils/helpers';
import InputComponent from '../components/Input';
import colors from '../scss/variables.module.scss';
import styles from './LoginScreen.module.scss';

/* loading the login mutation with the help of loader */
const userLoginMutation = loader('../graphql/mutations/userLoginMutation.graphql');

/* Login form fields type */
type LoginFormFieldsType = {
  /* Mobile number */
  mobile: string;
  /* Password */
  password: string;
};

/* React functional component */
const LoginScreen = () => {
  /* useApp destructuring */
  const { setUser } = useApp();

  /* Mutation used to login user */
  const [userLogin] = useMutation<UserLoginMutation, UserLoginMutationVariables>(userLoginMutation);

  /* State used to show loading indicator on login btn while submitting the form */
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormFieldsType>({
    defaultValues: {
      mobile: '',
      password: '',
    },
  });

  /* to handle form submit data */
  const onSubmit = (data: LoginFormFieldsType) => {
    setIsSubmitting(true);
    userLogin({
      variables: {
        mobile: data.mobile,
        password: data.password,
      },
    })
      .then((res) => {
        if (res.data?.login) {
          const contextData = {
            ...res.data.login,
            role: res.data.login.role as Enum_User_Role_Enum,
          };
          setUser(contextData);
          setIsSubmitting(false);
        }
      })
      .catch((err: ApolloError) => {
        logger(err);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(err.message);
        setIsSubmitting(false);
      });
  };

  return (
    <div className={styles.loginContainer}>
      <h1 style={{ fontSize: 40, fontWeight: 500, textAlign: 'center' }}>
        Welcome to {process.env.REACT_APP_APP_NAME} App
      </h1>
      <h2 style={{ textAlign: 'center' }}>LOGIN</h2>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '85%' }}
        >
          <FormItem
            errorText={errors.mobile ? errors.mobile.message : ''}
            inputColSpan={24}
            label="Mobile"
            displayMode="column"
            labelStyle={{ fontSize: 17, marginBottom: 5 }}
            requiredMark="after"
            isRequired
          >
            <InputComponent
              name="mobile"
              placeholder="Please enter mobile number"
              rhfControllerProps={{
                control,
                rules: {
                  required: {
                    value: true,
                    message: 'Please enter your mobile number and try again',
                  },
                  validate: (value: string) =>
                    validationRegex.mobile.test(value) ||
                    'Please enter correct mobile number and try again.',
                },
              }}
              inputProps={{
                maxLength: 10,
                prefix: (
                  <div
                    style={{
                      backgroundColor: colors.inputPrefixBGColor,
                      padding: '8px 10px 8px 10px',
                      borderRight: `1px solid ${colors.inputPrefixBorder}`,
                      marginRight: 3,
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 5,
                    }}
                  >
                    +91
                  </div>
                ),
              }}
              customStyles={{
                borderColor: errors.mobile ? colors.errorTextColor : undefined,
                padding: 0,
                paddingRight: 4,
              }}
            />
          </FormItem>
          <FormItem
            errorText={errors.password ? errors.password.message : ''}
            label="Password"
            inputColSpan={24}
            displayMode="column"
            labelStyle={{ fontSize: 17, marginBottom: 5 }}
            requiredMark="after"
            isRequired
          >
            <InputComponent
              name="password"
              placeholder="Please enter password "
              rhfControllerProps={{
                control,
                rules: {
                  required: {
                    value: true,
                    message: 'Please enter your password and try again',
                  },
                },
              }}
              isPasswordInput
              customStyles={{
                borderColor: errors.password ? colors.errorTextColor : undefined,
                padding: 8,
              }}
            />
          </FormItem>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            className={styles.loginBtn}
            style={{
              borderRadius: 5,
              paddingBottom: 30,
              paddingLeft: 30,
              paddingRight: 30,
              fontSize: 15,
              marginTop: 25,
            }}
          >
            Log in
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginScreen;
