import React, { ReactNode } from 'react';
import { Row, Col } from 'antd';
import { DisplayModeType, ShowErrorType } from '../utils/types';
import styles from './FormItem.module.scss';

// This is the types of props
type FormItemProps = {
  /* Label of form field */
  label?: string | ReactNode;
  /* span which is passed in the <Col> for label */
  labelColSpan?: number;
  /* span which is passed in the <Col> for input */
  inputColSpan?: number;
  /* error text of the field */
  errorText?: string;
  /* when field is required then this is true */
  isRequired?: boolean;
  /* JSX element which we define inside this component */
  children: JSX.Element;
  /* style which we pass from parent component */
  customStyle?: React.CSSProperties;
  /* Should the label and children be shown in row or column view */
  displayMode?: DisplayModeType;
  /*  style which we pass from parent component */
  labelStyle?: React.CSSProperties;
  /* Prop to decide whether to show error at bottom of input or at right side of input */
  showErrorAt?: ShowErrorType;
  /* Prop to decode where to show required mark */
  requiredMark?: 'after' | 'before';
  /* to set the styling of errorText */
  errorTextStyle?: React.CSSProperties;
};

/* React functional component */
const FormItem = ({
  label,
  labelColSpan = 3,
  inputColSpan = 21,
  errorText = '',
  isRequired = false,
  children,
  customStyle = {},
  displayMode = 'row' as DisplayModeType,
  labelStyle = {},
  showErrorAt = 'bottom',
  requiredMark = 'before',
  errorTextStyle = {},
}: FormItemProps): JSX.Element => {
  /* Variable to decide required class based on where to show required mark */
  const requiredClass = requiredMark === 'before' ? 'requiredFieldBefore' : 'requiredFieldAfter';

  return (
    <Row className={styles.inputContainer} style={{ paddingTop: 25, ...customStyle }}>
      {/* added 'OR'condition so that, we can achieve UI for form submit buttons (in such case 'labelColSpan' still present and formItem label will be 'undefined') */}
      {(label && displayMode === 'row') || displayMode === 'row' ? (
        <Col
          span={labelColSpan}
          className={`${styles.labelName} ${isRequired ? requiredClass : ''}`}
          style={labelStyle}
        >
          <label className="form-label">{label}</label>
        </Col>
      ) : null}
      <Col span={showErrorAt === 'right' ? 13 : inputColSpan}>
        {label && displayMode === 'column' ? (
          <div style={{ marginBottom: 5 }} className={`${isRequired ? requiredClass : ''}`}>
            <label className="form-label" style={labelStyle}>
              {label}
            </label>
          </div>
        ) : null}
        {children}

        {errorText && showErrorAt === 'bottom' ? (
          <div className={styles.errorText} style={errorTextStyle}>
            {errorText}
          </div>
        ) : null}
      </Col>
      {showErrorAt === 'right' ? (
        <Col span={9} style={{ display: 'flex', alignItems: 'center' }}>
          {errorText ? (
            <div className={styles.errorText} style={{ marginLeft: 10, ...errorTextStyle }}>
              {errorText}
            </div>
          ) : null}
        </Col>
      ) : null}
    </Row>
  );
};

export default FormItem;
