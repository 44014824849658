import React, { useState } from 'react';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  DeleteProductTypeMutation,
  DeleteProductTypeMutationVariables,
  GetAllProductsAndProductTypesQuery,
  GetAllProductsAndProductTypesQueryVariables,
} from '../../../graphql/graphql-types';
import { Button, message, Popconfirm, Table } from 'antd';
import { PlusOutlined, WarningFilled } from '@ant-design/icons';
import { logger } from '../../../utils/helpers';
import colors from '../../../scss/variables.module.scss';
import AddOrEditProductTypeForm from './AddOrEditProductTypeForm';
import { AddOrEditDataModalType, ProductType } from '../../../utils/types';

/* loading delete product type mutation  */
const deleteProductTypeMutation = loader(
  '../../../graphql/mutations/deleteProductTypeMutation.graphql',
);

/* loading get all products and product types query */
const getAllProductsAndProductTypeQuery = loader(
  '../../../graphql/queries/getAllProductsAndProductTypesQuery.graphql',
);

/* React functional component */
const ViewAllProductTypesScreen = () => {
  /* state used to store id of the product type whose 'delete' button is clicked which is then used to show loading indicator on delete button while deleting a product type */
  const [productTypeIdToDltAndShowLoading, setProductTypeIdToDltAndShowLoading] = useState<
    number | null
  >(null);

  /* state used to store product type data whose 'edit' button is clicked and to manage form modal visibility */
  const [productTypeDataAndIsFormVisible, setProductTypeDataAndIsFormVisible] =
    useState<AddOrEditDataModalType>(null);

  /* delete a product type mutation */
  const [deleteProductType] = useMutation<
    DeleteProductTypeMutation,
    DeleteProductTypeMutationVariables
  >(deleteProductTypeMutation);

  /* get all products and product types data query */
  const {
    data: getAllProductsAndProductTypesData,
    loading: getAllProductsAndProductTypesLoading,
    error: getAllProductsAndProductTypesError,
  } = useQuery<GetAllProductsAndProductTypesQuery, GetAllProductsAndProductTypesQueryVariables>(
    getAllProductsAndProductTypeQuery,
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllProductsAndProductTypesError) {
    return <div className="errorText">{getAllProductsAndProductTypesError.message}</div>;
  }

  /* function to handle delete product type */
  const deleteProductTypeFunc = (productTypeId: number) => {
    setProductTypeIdToDltAndShowLoading(productTypeId);
    deleteProductType({
      variables: {
        id: productTypeId,
      },
      /* after deleting a product type, the update function is used to update the cache and display an updated product types array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a product type that was just removed. */
        const deletedDataId = deleteData?.deleteProductType?.id;
        cache.modify({
          fields: {
            productTypes(existingProductTypes: Array<Reference>, { readField }) {
              if (deletedDataId) {
                return existingProductTypes.filter(
                  (productTypeRef) => deletedDataId !== readField('id', productTypeRef),
                );
              }
              return existingProductTypes;
            },
          },
        });
      },
    })
      .then(() => {
        setProductTypeIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Product type has been deleted successfully.');
      })
      .catch((deleteProductTypeError: ApolloError) => {
        let errorMsg = deleteProductTypeError.message;
        setProductTypeIdToDltAndShowLoading(null);
        if (deleteProductTypeError.message.includes('Foreign key violation.')) {
          errorMsg = 'Cannot delete since product type is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteProductTypeError);
      });
  };

  /* function used to close the visibility of modal by setting state value to null. */
  const closeAddOrEditProductTypeForm = () => {
    setProductTypeDataAndIsFormVisible(null);
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          /* set AddOrEditProductTypeForm mode to 'add', to add product type and open AddOrEditProductTypeForm. */
          setProductTypeDataAndIsFormVisible({ mode: 'add' });
        }}
      >
        Create product type
      </Button>
      <form>
        <Table<ProductType>
          dataSource={
            getAllProductsAndProductTypesData &&
            getAllProductsAndProductTypesData.getAllProductTypes
          }
          loading={getAllProductsAndProductTypesLoading}
          className="tableStyle"
          bordered
          size="small"
          style={{ width: '80%' }}
          pagination={{ showSizeChanger: true }}
        >
          <Table.Column<ProductType>
            key="name"
            title="Product Types"
            dataIndex="name"
            align="center"
            render={(productType) => {
              return <span>{productType || '-'}</span>;
            }}
          />
          <Table.Column<ProductType>
            key="Actions"
            title="Actions"
            dataIndex="actions"
            align="center"
            width={300}
            render={(text, record) => {
              return (
                <div className="buttonContainer">
                  <Button
                    onClick={() => {
                      /* set AddOrEditProductTypeForm mode to 'edit' and store product type data */
                      setProductTypeDataAndIsFormVisible({
                        mode: 'edit',
                        dataToEdit: record,
                      });
                    }}
                    type="default"
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title="Delete Product Type. Are you sure?"
                    okText="Yes"
                    okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                    cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                    cancelText="No"
                    icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                    onConfirm={() => {
                      deleteProductTypeFunc(record.id);
                    }}
                  >
                    <Button
                      className="deleteButton"
                      loading={record.id === productTypeIdToDltAndShowLoading}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              );
            }}
          />
        </Table>
      </form>
      {productTypeDataAndIsFormVisible ? (
        <AddOrEditProductTypeForm
          mode={productTypeDataAndIsFormVisible.mode}
          dataToEdit={productTypeDataAndIsFormVisible.dataToEdit as ProductType}
          closeModal={closeAddOrEditProductTypeForm}
        />
      ) : null}
    </>
  );
};

export default ViewAllProductTypesScreen;
