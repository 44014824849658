import React from 'react';
import CreateBagInwardOrOutwardShipmentForm from '../../../components/CreateBagInwardOrOutwardShipmentForm';
import { Enum_Item_Category_Enum } from '../../../graphql/graphql-types';

/* React functional component */
const OutwardPaddyBagsScreen = () => {
  return (
    <CreateBagInwardOrOutwardShipmentForm
      bagType={Enum_Item_Category_Enum.PaddyBag}
      shipmentType="outward"
    />
  );
};

export default OutwardPaddyBagsScreen;
