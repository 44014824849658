import React, { useState, useEffect } from 'react';
import localforage from 'localforage';
import { AppContext } from './context/AppContext';
import LoadingScreen from './screens/LoadingScreen';
import LoginScreen from './screens/LoginScreen';
import AppRoutes from './AppRoutes';
import { logger } from './utils/helpers';
import { ContextUserDataType } from './utils/types';
import authData from './utils/authData';
import './App.scss';

const App = (): JSX.Element => {
  /* state to show whether the app is loading or not at the start */
  const [isAppLoading, setIsAppLoading] = useState<boolean>(true);
  /* state to store the current logged in user */
  const [user, setUser] = useState<ContextUserDataType | undefined>(undefined);

  /* function to update user data from localforage data */
  const updateUserData = (userData: ContextUserDataType): void => {
    /* to set token of user */
    if (userData.token) {
      authData.setToken(userData.token);
    }
    setUser(userData);
  };

  /* function to set and store user data */
  const setAndStoreUserData = (userData: ContextUserDataType | undefined): void => {
    if (userData) {
      // we are updating the localforage with user context data
      localforage
        .setItem<ContextUserDataType>('user', userData)
        .then(() => {
          updateUserData(userData);
        })
        .catch((err) => {
          logger(err as Error);
        });
    } else {
      setUser(undefined);
    }
  };

  useEffect(() => {
    /* App init function */
    const init = async (): Promise<void> => {
      try {
        /* to store data if the user data is already present in local forage */
        const storedUserData = await localforage.getItem<ContextUserDataType>('user');

        if (storedUserData) {
          updateUserData(storedUserData);
        }
      } catch (error) {
        logger(error as Error);
      }
      setTimeout(() => {
        setIsAppLoading(false);
      }, 2000);
    };

    init().catch((error) => {
      logger(error as Error);
    });
  }, []);

  if (isAppLoading) {
    return <LoadingScreen />;
  }

  return (
    <AppContext.Provider value={{ user, setUser: setAndStoreUserData }}>
      {user ? <AppRoutes /> : <LoginScreen />}
    </AppContext.Provider>
  );
};

export default App;
