import React from 'react';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetAllOutwardShipmentsForOtherItemsQueryQuery,
  GetAllOutwardShipmentsForOtherItemsQueryQueryVariables,
} from '../../../../graphql/graphql-types';
import { DailyReportsPropType } from '../../../../utils/types';
import DailyReportOutwardBagOrOtherItemTable from './DailyReportOutwardBagOrOtherItemTable';

/* loading get all outward shipment for OtherItems Query */
const getAllOutwardShipmentsForOtherItemsQuery = loader(
  '../../../../graphql/queries/getAllOutwardShipmentsForOtherItemsQuery.graphql',
);

/* React functional component */
const DailyReportsOutwardOtherItemsScreen = ({ startDate, endDate }: DailyReportsPropType) => {
  /* get All outward Shipment For OtherItems data using query with start and end date from date range field */
  const {
    data: getAllOutwardShipmentsForOtherItemsData,
    loading: getAllOutwardShipmentsForOtherItemsLoading,
    error: getAllOutwardShipmentsForOtherItemsError,
  } = useQuery<
    GetAllOutwardShipmentsForOtherItemsQueryQuery,
    GetAllOutwardShipmentsForOtherItemsQueryQueryVariables
  >(getAllOutwardShipmentsForOtherItemsQuery, {
    variables: {
      createdAt:
        startDate && endDate
          ? {
              _gte: startDate,
              _lte: endDate,
            }
          : {},
    },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getAllOutwardShipmentsForOtherItemsLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getAllOutwardShipmentsForOtherItemsError) {
    return <div className="errorText">{getAllOutwardShipmentsForOtherItemsError.message}</div>;
  }

  return (
    <>
      {getAllOutwardShipmentsForOtherItemsData ? (
        <DailyReportOutwardBagOrOtherItemTable
          data={getAllOutwardShipmentsForOtherItemsData.outwardShipments}
          outwardShipmentType="otherItems"
          reportScreenType="daily"
        />
      ) : null}
    </>
  );
};

export default DailyReportsOutwardOtherItemsScreen;
