import React from 'react';

/* React functional component which will be used to show useForm fields are required message */
const RequiredMessage = (): JSX.Element => {
  return (
    <h4 style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
      All fields marked with <span className="requiredFieldAfter" />
      &nbsp; are required!
    </h4>
  );
};

export default RequiredMessage;
