import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import 'antd/es/date-picker/style/index';

// as we follow dayjs as a practice , but antd date picker excepts moment type and hence in order to convert the date picker to accept dayjs, this conversion is needed
const DatePickerWithDayjs = generatePicker<Dayjs>(dayjsGenerateConfig);

/* Date picker component props type */
type DatePickerComponentType = {
  // name of the date picker field
  name: string;
  // This is the default value of date picker field
  defaultValue?: string;
  // controller props of react-hook-form
  rhfControllerProps: Omit<UseControllerProps<any>, 'name' | 'defaultValue'>;
  // This is the custom style for date picker
  customStyles?: React.CSSProperties;
  placeholder?: string;
  // This function is called when functionality is different from the regular onChange
  onChange?: (value: string | null) => void;
};

/* React functional component */
const DatePicker: React.FC<DatePickerComponentType> = ({
  name,
  defaultValue = undefined,
  rhfControllerProps,
  customStyles = {},
  placeholder = undefined,
  onChange,
}) => {
  // destructuring fields from useController hook
  const { field } = useController({
    name,
    defaultValue,
    ...rhfControllerProps,
  });

  // destructuring field props
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { onChange: rhfOnChange, value, ...rhfFields } = field;

  return (
    <DatePickerWithDayjs
      value={value ? dayjs(value as string) : null}
      style={customStyles}
      onChange={(dateValue) => {
        if (dateValue) {
          /* formatting date */
          const date = dayjs(dateValue).format('YYYY-MM-DD');
          if (onChange) {
            onChange(date);
          }
          rhfOnChange(date);
        } else {
          if (onChange) {
            onChange(null);
          }
          rhfOnChange(dateValue);
        }
      }}
      {...rhfFields}
      placeholder={placeholder}
    />
  );
};

export default DatePicker;
