import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Divider, message, Popconfirm, Spin, Table } from 'antd';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation, useQuery, Reference, useApolloClient } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import RequiredMessage from '../../../components/RequiredMessage';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import { inputComponentCommonStyle } from '../../../utils/globals';
import {
  CreateInwardShipmentMutation,
  CreateInwardShipmentMutationVariables,
  UpdateInwardShipmentMutation,
  UpdateInwardShipmentMutationVariables,
  DeleteInwardShipmentMutation,
  DeleteInwardShipmentMutationVariables,
  GetOpenInwardShipmentsQuery,
  GetOpenInwardShipmentsQueryVariables,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
  InwardShipments,
  GetSettingByNameQuery,
  GetSettingByNameQueryVariables,
} from '../../../graphql/graphql-types';
import InputNumber from '../../../components/InputNumber';
import { handleGetWeightFunction, logger } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { WarningFilled } from '@ant-design/icons';
import colors from '../../../scss/variables.module.scss';

/* create or update inward shipment useForm type */
type CreateOrUpdateInwardShipmentFormType = Pick<
  InwardShipments,
  'driverName' | 'vehicleNumber' | 'fullVehicleImage' | 'id' | 'grn'
> & {
  /* this type used to store full vehicle weight */
  fullVehicleWtKg: number | null;
};

/* open inward shipments table type */
type OpenInwardShipmentsTableType = Pick<
  InwardShipments,
  'grn' | 'driverName' | 'fullVehicleWtKg' | 'vehicleNumber' | 'id' | 'fullVehicleImage'
>;

/* loading create raw material inward shipment mutation  */
const createInwardShipmentMutation = loader(
  '../../../graphql/mutations/createInwardShipmentMutation.graphql',
);

/* loading update raw material inward shipment mutation  */
const updateInwardShipmentMutation = loader(
  '../../../graphql/mutations/updateInwardShipmentMutation.graphql',
);

/* loading delete raw material inward shipment mutation  */
const deleteInwardShipmentMutation = loader(
  '../../../graphql/mutations/deleteInwardShipmentMutation.graphql',
);

/* loading get open inward shipment query */
const getOpenInwardShipmentQuery = loader(
  '../../../graphql/queries/getOpenInwardShipmentsQuery.graphql',
);

/* loading get bhagwati mill id query */
const getBhagwatiMillIdQuery = loader('../../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* loading get setting by name query with the help of loader */
const getSettingByNameQuery = loader('../../../graphql/queries/getSettingByNameQuery.graphql');

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
};

/* validations required for useForm */
const schema = yup.object({
  vehicleNumber: yup
    .string()
    .required('Please enter vehicle number and try again')
    .matches(validationRegex.vehicleRegistrationNumber, {
      message: 'Please enter a valid vehicle number',
    }),
  driverName: yup
    .string()
    .required('Please enter driver name and try and again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  fullVehicleWtKg: yup
    .number()
    .required('Please enter weight-1 (in kgs) and try again')
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
});

/* React functional component */
const CreateShipmentScreen: React.FC = () => {
  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* this state used to show loading indicator on submit or update button */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* state to show loading indicator on 'get weight' button */
  const [getWeightButtonLoading, setGetWeightButtonLoading] = useState<boolean>(false);

  /* state used to store id of the shipment whose delete button is clicked which is then used to show loading indicator on delete button while deleting a shipment */
  const [shipmentIdToDltAndShowLoading, setShipmentIdToDltAndShowLoading] = useState<number | null>(
    null,
  );

  /* state used to store id of the shipment whose edit button is clicked which is then used to update that shipment data */
  const [shipmentIdToEdit, setShipmentIdToEdit] = useState<number | null>(null);

  /* create inward shipment mutation */
  const [createInwardShipment] = useMutation<
    CreateInwardShipmentMutation,
    CreateInwardShipmentMutationVariables
  >(createInwardShipmentMutation);

  /* update inward shipment mutation */
  const [updateInwardShipment] = useMutation<
    UpdateInwardShipmentMutation,
    UpdateInwardShipmentMutationVariables
  >(updateInwardShipmentMutation);

  /* delete inward shipment mutation */
  const [deleteInwardShipment] = useMutation<
    DeleteInwardShipmentMutation,
    DeleteInwardShipmentMutationVariables
  >(deleteInwardShipmentMutation);

  /* get all open inward shipments */
  const {
    data: getOpenInwardShipmentData,
    loading: getOpenInwardShipmentDataLoading,
    error: getOpenInwardShipmentDataError,
  } = useQuery<GetOpenInwardShipmentsQuery, GetOpenInwardShipmentsQueryVariables>(
    getOpenInwardShipmentQuery,
  );

  /* get bhagwati mill id query used to show Mill name */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* setting by name query is used to determine whether the 'fullVehicleWtKg' field is enabled or disabled. */
  const {
    data: weightInputDisabledData,
    error: getSettingByNameError,
    loading: getSettingByNameLoader,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weightInputDisabled' },
  });

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm<CreateOrUpdateInwardShipmentFormType>({
    defaultValues: {
      vehicleNumber: '',
      driverName: '',
      fullVehicleImage: '',
      fullVehicleWtKg: null,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getOpenInwardShipmentDataLoading || getBhagwatiMillIdQueryLoader || getSettingByNameLoader) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getOpenInwardShipmentDataError || getBhagwatiMillIdQueryError || getSettingByNameError) {
    let errorMessage;
    if (getOpenInwardShipmentDataError) {
      errorMessage = getOpenInwardShipmentDataError.message;
    } else if (getBhagwatiMillIdQueryError) {
      errorMessage = getBhagwatiMillIdQueryError.message;
    } else if (getSettingByNameError) {
      errorMessage = getSettingByNameError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* function to get weight of vehicle */
  const getVehicleWeight = async () => {
    try {
      setGetWeightButtonLoading(true);

      // const to store vehicle weight from weighbridge
      const vehicleWeight = await handleGetWeightFunction(apolloClient);

      // const to store vehicle image
      // const vehicleImageData = await getWeightAndCaptureCctvImg(false);

      // setting vehicle weight
      setValue('fullVehicleWtKg', vehicleWeight);

      /* if 'fullVehicleWtKg' form field has any error, then clear it. after setting it's value  */
      if (errors.fullVehicleWtKg) {
        clearErrors('fullVehicleWtKg');
      }
      /* if function return has value then set that image to 'fullVehicleImage' field */
      // if (vehicleImageData.s3Key) {
      //   setValue('fullVehicleImage', vehicleImageData.s3Key);
      // }
      setGetWeightButtonLoading(false);
    } catch (err) {
      logger(err as ApolloError);
      setGetWeightButtonLoading(false);
    }
  };

  /* function to handle delete open inward shipment */
  const deleteInwardShipmentFunc = (shipmentId: number) => {
    setShipmentIdToDltAndShowLoading(shipmentId);
    deleteInwardShipment({
      variables: {
        id: shipmentId,
      },
      /* after deleting a shipment, the update function is used to update the cache and display an updated shipment array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a shipment that was just removed. */
        const deletedDataId = deleteData?.deleteInwardShipment?.id;
        cache.modify({
          fields: {
            inwardShipments(existingInwardShipments: Array<Reference>, { readField }) {
              if (deletedDataId) {
                return existingInwardShipments.filter(
                  (shipmentRef) => deletedDataId !== readField('id', shipmentRef),
                );
              }
              return existingInwardShipments;
            },
          },
        });
      },
    })
      .then(() => {
        setShipmentIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment has been deleted successfully.');
      })
      .catch((deleteGodownError: ApolloError) => {
        const errorMsg = deleteGodownError.message;
        setShipmentIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteGodownError);
      });
  };

  /* function to handle on save data */
  const onSubmit = (submittedFormData: CreateOrUpdateInwardShipmentFormType) => {
    setIsSubmitOrUpdateBtnLoading(true);

    /* this const used to store mutation variables which are common in both create and update inward shipment */
    const commonMutationVariables = {
      vehicleNumber: submittedFormData.vehicleNumber as string,
      driverName: submittedFormData.driverName as string,
      fullVehicleWtKg: submittedFormData.fullVehicleWtKg ? submittedFormData.fullVehicleWtKg : null,
    };

    /* if user has clicked on 'edit' shipment then execute update inward shipment mutation  */
    if (shipmentIdToEdit) {
      updateInwardShipment({
        variables: {
          id: shipmentIdToEdit,
          ...commonMutationVariables,
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been updated successfully.');
          reset();
          setShipmentIdToEdit(null);
          setIsSubmitOrUpdateBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          let errorMessage = error.message;
          if (error.message === 'database query error') {
            errorMessage = 'Vehicle is already in use!';
          }
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorMessage);
          setIsSubmitOrUpdateBtnLoading(false);
          logger(error);
        });
    } else {
      createInwardShipment({
        variables: {
          ...commonMutationVariables,
          fullVehicleImage: submittedFormData.fullVehicleImage || null,
        },
        refetchQueries: [{ query: getOpenInwardShipmentQuery }],
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been successfully created.');
          reset();
          setIsSubmitOrUpdateBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          let errorMessage = error.message;
          if (error.message === 'database query error') {
            errorMessage = 'Vehicle is already in use!';
          }
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorMessage);
          setIsSubmitOrUpdateBtnLoading(false);
          logger(error);
        });
    }
  };

  return (
    <>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <RequiredMessage />
        {shipmentIdToEdit ? (
          <FormItem label="GRN" {...formItemStyleProps}>
            <Input
              customStyles={inputComponentCommonStyle}
              name="grn"
              placeholder="Please enter grn number"
              rhfControllerProps={{
                control,
              }}
              inputProps={{ disabled: true }}
            />
          </FormItem>
        ) : null}
        <FormItem
          label="Vehicle Number"
          isRequired
          requiredMark="after"
          errorText={errors.vehicleNumber ? errors.vehicleNumber.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            name="vehicleNumber"
            placeholder="Please enter Vehicle number"
            rhfControllerProps={{
              control,
            }}
            onChange={(rhfOnChange, value) => {
              rhfOnChange(value.toUpperCase());
            }}
          />
        </FormItem>
        <FormItem
          label="Driver Name"
          isRequired
          requiredMark="after"
          errorText={errors.driverName ? errors.driverName.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            name="driverName"
            placeholder="Please enter driver name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem label="Mill Name" {...formItemStyleProps}>
          <>
            {getBhagwatiMillIdData &&
              getBhagwatiMillIdData.mills[0] &&
              getBhagwatiMillIdData.mills[0].name}
          </>
        </FormItem>
        <FormItem
          label="Weight-1 (in kgs)"
          isRequired
          requiredMark="after"
          errorText={errors && errors.fullVehicleWtKg ? errors.fullVehicleWtKg.message : undefined}
          {...formItemStyleProps}
        >
          <div style={{ display: 'flex' }}>
            <InputNumber
              customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
              placeholder="Please enter weight-1 (in kgs)"
              name="fullVehicleWtKg"
              rhfControllerProps={{
                control,
              }}
              inputNumberProps={{
                min: 0,
                precision: 2,
                disabled:
                  weightInputDisabledData && !weightInputDisabledData.getSettingByName[0].intValue
                    ? true
                    : false,
              }}
            />
            <Button
              type="primary"
              style={{ marginLeft: 5 }}
              onClick={() => {
                getVehicleWeight().catch((err: ApolloError) => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.error(err.message);
                });
              }}
              loading={getWeightButtonLoading}
            >
              Get Weight
            </Button>
          </div>
        </FormItem>
        <FormItem {...formItemStyleProps} customStyle={{ paddingBottom: 10 }}>
          <div style={{ display: 'flex' }}>
            <Button htmlType="submit" type="primary" loading={isSubmitOrUpdateBtnLoading}>
              {shipmentIdToEdit ? 'Update Inward Shipment' : 'Create Inward Shipment'}
            </Button>
            {shipmentIdToEdit ? (
              <Button
                type="default"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  /* set shipment edit id to 'null' */
                  setShipmentIdToEdit(null);
                  /* and reset useForm */
                  reset();
                }}
              >
                Cancel
              </Button>
            ) : null}
          </div>
        </FormItem>
      </form>
      <Divider style={{ marginTop: 5 }} />
      <h2>Open inward shipments</h2>
      <Table<OpenInwardShipmentsTableType>
        dataSource={getOpenInwardShipmentData && getOpenInwardShipmentData.getOpenInwardShipments}
        loading={getOpenInwardShipmentDataLoading}
        className="tableStyle"
        bordered
        rowKey="grn"
        size="small"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column
          title="GRN"
          dataIndex="grn"
          key="grn"
          align="center"
          render={(grnNumber) => <span>{grnNumber || '-'}</span>}
        />
        <Table.Column
          title="Vehicle No."
          dataIndex="vehicleNumber"
          key="vehicleNumber"
          align="center"
          render={(vehicleNumber) => <span>{vehicleNumber || '-'}</span>}
        />
        <Table.Column
          title="Driver Name"
          dataIndex="driverName"
          key="driverName"
          align="center"
          render={(driverName) => (
            <span style={{ textTransform: 'capitalize' }}>{driverName || '-'}</span>
          )}
        />
        <Table.Column<OpenInwardShipmentsTableType>
          title="Weight - 1 (in kgs)"
          dataIndex="fullVehicleWtKg"
          align="center"
          key="fullVehicleWtKg"
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          render={(text, record) => record.fullVehicleWtKg}
        />
        <Table.Column<OpenInwardShipmentsTableType>
          key="Actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    navigate(`../unload/${record.id}`);
                  }}
                >
                  Unload
                </Button>
                <Button
                  onClick={() => {
                    /* scroll to the top of the screen, after click on button,so that user can easily see opened form  */
                    window.scroll(0, 0);
                    /* set shipment id whose 'edit' button has clicked */
                    setShipmentIdToEdit(record.id);
                    /* set 'grn' field of the useForm to show edit shipment's grn number  */
                    setValue('grn', record.grn);
                    /* set 'driverName' field of the useForm to edit that deriver name */
                    setValue('driverName', record.driverName);
                    /* set 'fullVehicleImage' field of the useForm to edit that vehicle image */
                    setValue('fullVehicleImage', record.fullVehicleImage);
                    /* set 'fullVehicleWtKg' field of the useForm to edit that full vehicle weight */
                    setValue(
                      'fullVehicleWtKg',
                      record.fullVehicleWtKg ? (record.fullVehicleWtKg as number) : null,
                    );
                    /* set 'vehicleNumber' field  of the useForm to edit that vehicle number */
                    setValue('vehicleNumber', record.vehicleNumber);
                  }}
                  disabled={record.id === shipmentIdToEdit ? true : false}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete inward shipment. Are you sure?"
                  okText="Yes"
                  onConfirm={() => {
                    deleteInwardShipmentFunc(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                  disabled={record.id === shipmentIdToEdit}
                >
                  <Button
                    className="deleteButton"
                    disabled={record.id === shipmentIdToEdit}
                    loading={record.id === shipmentIdToDltAndShowLoading ? true : false}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};

export default CreateShipmentScreen;
