import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import PrimaryNavBar from './components/PrimaryNavBar';
import UserTabLayout from './screens/management/users/UserTabLayout';
import ViewAllUsersScreen from './screens/management/users/ViewAllUsersScreen';
import ManagementTabLayout from './screens/management/ManagementTabLayout';
import TransporterTabLayout from './screens/management/transporters/TransporterTabLayout';
import ViewAllTransportersScreen from './screens/management/transporters/ViewAllTransportersScreen';
import TransporterVehicleTabLayout from './screens/management/transporterVehicles/TransporterVehicleTabLayout';
import ViewAllTransportersVehicleScreen from './screens/management/transporterVehicles/ViewAllTransportersVehicleScreen';
import BuyersTabLayout from './screens/management/buyers/BuyersTabLayout';
import ViewAllBuyersScreen from './screens/management/buyers/ViewAllBuyersScreen';
import ViewAllBrokersScreen from './screens/management/brokers/ViewAllBrokersScreen';
import BrokersTabLayout from './screens/management/brokers/BrokersTabLayout';
import CreateVendorScreen from './screens/management/vendors/CreateVendorScreen';
import EditVendorScreen from './screens/management/vendors/EditVendorScreen';
import TransporterDriverTabLayout from './screens/management/drivers/TransporterDriverTabLayout';
import ViewAllTransporterDriversScreen from './screens/management/drivers/ViewAllTransporterDriversScreen';
import CreateTransporterDriverScreen from './screens/management/drivers/CreateTransporterDriverScreen';
import EditTransporterDriverScreen from './screens/management/drivers/EditTransporterDriverScreen';
import WeighBridgeTabLayout from './screens/weighBridge/WeighBridgeTabLayout';
import CreateWeighBridgeReceipt from './screens/weighBridge/CreateWeighBridgeReceipt';
import EditWeighbridgeReceipts from './screens/weighBridge/EditWeighbridgeReceipts';
import VendorsTabLayout from './screens/management/vendors/ProductVendorsTabLayout';
import ViewAllVendorsScreen from './screens/management/vendors/ViewAllProductVendorsScreen';
import GodownsTabLayout from './screens/management/godowns/GodownsTabLayout';
import ViewAllGodownsScreen from './screens/management/godowns/ViewAllGodownsScreen';
import CreateGodownScreen from './screens/management/godowns/CreateGodownScreen';
import EditGodownScreen from './screens/management/godowns/EditGodownScreen';
import MaterialsScreenLayout from './screens/management/materials/MaterialsScreenLayout';
import ViewAllItemsScreen from './screens/management/materials/ViewAllItemsScreen';
import ViewAllRawMaterialScreen from './screens/management/materials/ViewAllRawMaterialsScreen';
import SettingsScreen from './screens/management/settings/SettingsScreen';
import PurchaseOrderApproveScreen from './screens/purchase/PurchaseOrderApproveScreen';
import ViewAllPurchaseOrderScreen from './screens/purchase/ViewAllPurchaseOrderScreen';
import PaddyOrdersTabLayout from './screens/purchase/PaddyOrdersTabLayout';
import PurchaseOrderPrintScreen from './screens/purchase/PurchaseOrderPrintScreen';
import InternalTransferTabLayout from './screens/internalTransfer/InternalTransferTabLayout';
import ManualInternalTransferScreen from './screens/internalTransfer/manual/ManualInternalTransferScreen';
import WeighbridgePaddyTabLayout from './screens/internalTransfer/weighbridgePaddy/WeighbridgePaddyTabLayout';
import CreateUserScreen from './screens/management/users/CreateUserScreen';
import EditUserScreen from './screens/management/users/EditUserScreen';
import CreateTransporterScreen from './screens/management/transporters/CreateTransporterScreen';
import EditTransporterScreen from './screens/management/transporters/EditTransporterScreen';
import CreateBuyerScreen from './screens/management/buyers/CreateBuyerScreen';
import EditBuyerScreen from './screens/management/buyers/EditBuyerScreen';
import CreateBrokerScreen from './screens/management/brokers/CreateBrokerScreen';
import EditBrokerScreen from './screens/management/brokers/EditBrokerScreen';
import CreateTransporterVehicleScreen from './screens/management/transporterVehicles/CreateTransporterVehicleScreen';
import EditTransporterVehicleScreen from './screens/management/transporterVehicles/EditTransporterVehicleScreen';
import ViewAllProductsScreen from './screens/management/materials/ViewAllProductsScreen';
import ViewAllProductTypesScreen from './screens/management/materials/ViewAllProductTypesScreen';
import PurchaseTabLayout from './screens/purchase/PurchaseTabLayout';
import ProductOrdersTabLayout from './screens/purchase/ProductOrdersTabLayout';
import InwardUserTabLayout from './screens/inwardUser/InwardUserTabLayout';
import BagsTabLayout from './screens/inwardUser/bags/BagsTabLayout';
import OtherItemsScreen from './screens/inwardUser/otherItems/OtherItemsScreen';
import PaddyBagScreen from './screens/inwardUser/bags/PaddyBagScreen';
import ProductBagScreen from './screens/inwardUser/bags/ProductBagScreen';
import CreateShipmentScreen from './screens/inwardUser/rawMaterialPaddy/CreateShipmentScreen';
import OutwardOtherItemsScreen from './screens/outward/otherItems/OutwardOtherItemsScreen';
import ReportsTabLayout from './screens/reports/ReportsTabLayout';
import InwardShipmentReportTabLayout from './screens/reports/inwardShipments/InwardShipmentReportTabLayout';
import InwardShipmentRawMaterialScreen from './screens/reports/inwardShipments/rawMaterial(paddy)/InwardShipmentRawMaterialScreen';
import InwardShipmentBag from './screens/reports/inwardShipments/bags/InwardShipmentBag';
import InwardShipmentOtherItem from './screens/reports/inwardShipments/otherItems/InwardShipmentOtherItem';
import BuyerHistoryReportScreen from './screens/reports/buyerHistory/BuyerHistoryReportScreen';
import InternalShipmentsReportScreen from './screens/reports/internalShipments/InternalShipmentsReportScreen';
import WeighBridgeReportScreen from './screens/reports/weighBridge/WeighBridgeReportScreen';
import MillingReportScreen from './screens/reports/milling/MillingReportScreen';
import OutwardPaddyBagsScreen from './screens/outward/bags/OutwardPaddyBagsScreen';
import OutwardUserTabLayout from './screens/outward/OutwardUserTabLayout';
import OutwardBagsTabLayout from './screens/outward/bags/OutwardBagsTabLayout';
import InternalShipmentSummaryScreen from './screens/reports/internalShipments/InternalShipmentSummaryScreen';
import RawMaterialTabLayout from './screens/inwardUser/rawMaterialPaddy/RawMaterialTabLayout';
import FinishShipmentScreen from './screens/inwardUser/rawMaterialPaddy/FinishShipmentScreen';
import MaterialUnloadingScreen from './screens/inwardUser/rawMaterialPaddy/MaterialUnloadingScreen';
import InternalShipmentMaterialUnloadingScreen from './screens/internalTransfer/weighbridgePaddy/InternalShipmentMaterialUnloadingScreen';
import InwardShipmentSummaryScreen from './screens/reports/inwardShipments/rawMaterial(paddy)/InwardShipmentSummaryScreen';
import InwardShipmentPrintScreen from './screens/reports/inwardShipments/InwardShipmentPrintScreen';
import OutwardProductTabLayout from './screens/outward/product/OutwardProductTabLayout';
import CreateProductOutwardShipmentScreen from './screens/outward/product/CreateProductOutwardShipmentScreen';
import OutwardProductShipmentOrderScreen from './screens/outward/product/OutwardProductShipmentOrderScreen';
import OutwardProductLoadingScreen from './screens/outward/product/OutwardProductLoadingScreen';
import OutwardProductTruckOutwardScreen from './screens/outward/product/OutwardProductTruckOutwardScreen';
import OutwardProductInvoicingScreen from './screens/outward/product/OutwardProductInvoicingScreen';
import MonitorShipmentTabLayout from './screens/reports/monitorShipments/MonitorShipmentTabLayout';
import MonitorShipmentsInward from './screens/reports/monitorShipments/inward/MonitorShipmentsInward';
import MonitorShipmentsOutward from './screens/reports/monitorShipments/outward/MonitorShipmentsOutward';
import OutwardShipmentSummaryScreen from './screens/reports/monitorShipments/outward/OutwardShipmentSummaryScreen';
import DailyReportsTabLayout from './screens/reports/dailyReports/DailyReportsTabLayout';
import OutwardShipmentBagScreen from './screens/reports/outwardShipments/OutwardShipmentBagScreen';
import OutwardShipmentOtherItemsScreen from './screens/reports/outwardShipments/OutwardShipmentOtherItemsScreen';
import WeighbridgeProductTabLayout from './screens/internalTransfer/weighbridgeProduct/WeighbridgeProductTabLayout';
import MaterialLoadingForWeighbridgeProductScreen from './screens/internalTransfer/weighbridgeProduct/MaterialLoadingForWeighbridgeProductScreen';
import WeighbridgePaddyOrProductCreateShipmentScreen from './screens/internalTransfer/WeighbridgePaddyOrProductCreateShipmentScreen';
import FinishInternalShipmentScreen from './screens/internalTransfer/FinishInternalShipmentScreen';
import OutwardShipmentProductsScreen from './screens/reports/outwardShipments/OutwardShipmentProductsScreen';
import OutwardShipmentTabLayout from './screens/reports/outwardShipments/OutwardShipmentTabLayout';
import WeighbridgeReceiptComponent from './components/WeighbridgeReceiptComponent';
import InwardAdminTabLayout from './screens/inwardAdmin/InwardAdminTabLayout';
import OpenGRNScreen from './screens/inwardAdmin/OpenGRNScreen';
import ProcessGRNScreen from './screens/inwardAdmin/ProcessGRNScreen';
import ModifyGRNScreen from './screens/inwardAdmin/ModifyGRNScreen';
import EditCompletedOutwardShipmentScreen from './screens/reports/outwardShipments/EditCompletedOutwardShipmentScreen';
import KesharKaliCodesScreen from './screens/management/kesharKaliCodes/KesharKaliCodesScreen';
import OtherItemsOrdersTabLayout from './screens/purchase/OtherItemsOrdersTabLayout';
import CreatePurchaseOrderScreen from './screens/purchase/CreatePurchaseOrderScreen';
import PurchaseOrderDeliverScreen from './screens/purchase/PurchaseOrderDeliverScreen';
import FarmerOrTraderHistoryScreen from './screens/reports/farmerOrTrader/FarmerOrTraderHistoryScreen';

/* React functional component */
const AppRoutes = (): JSX.Element => {
  return (
    <BrowserRouter>
      <PrimaryNavBar />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="management" element={<ManagementTabLayout />}>
          <Route path="users" element={<UserTabLayout />}>
            <Route index element={<ViewAllUsersScreen />} />
            <Route path="create" element={<CreateUserScreen />} />
            <Route path=":id/edit" element={<EditUserScreen />} />
          </Route>
          <Route path="transporters" element={<TransporterTabLayout />}>
            <Route index element={<ViewAllTransportersScreen />} />
            <Route path="create" element={<CreateTransporterScreen />} />
            <Route path=":id/edit" element={<EditTransporterScreen />} />
          </Route>
          <Route path="transporterVehicles" element={<TransporterVehicleTabLayout />}>
            <Route index element={<ViewAllTransportersVehicleScreen />} />
            <Route path="create" element={<CreateTransporterVehicleScreen />} />
            <Route path=":id/edit" element={<EditTransporterVehicleScreen />} />
          </Route>
          <Route path="buyers" element={<BuyersTabLayout />}>
            <Route index element={<ViewAllBuyersScreen />} />
            <Route path="create" element={<CreateBuyerScreen />} />
            <Route path=":id/edit" element={<EditBuyerScreen />} />
          </Route>
          <Route path="sellers" element={<VendorsTabLayout />}>
            <Route index element={<ViewAllVendorsScreen />} />
            <Route path="create" element={<CreateVendorScreen />} />
            <Route path=":id/edit" element={<EditVendorScreen />} />
          </Route>
          <Route path="brokers" element={<BrokersTabLayout />}>
            <Route index element={<ViewAllBrokersScreen />} />
            <Route path="create" element={<CreateBrokerScreen />} />
            <Route path=":id/edit" element={<EditBrokerScreen />} />
          </Route>
          <Route path="drivers" element={<TransporterDriverTabLayout />}>
            <Route index element={<ViewAllTransporterDriversScreen />} />
            <Route path="create" element={<CreateTransporterDriverScreen />} />
            <Route path=":id/edit" element={<EditTransporterDriverScreen />} />
          </Route>
          <Route path="godowns" element={<GodownsTabLayout />}>
            <Route index element={<ViewAllGodownsScreen />} />
            <Route path="create" element={<CreateGodownScreen />} />
            <Route path=":id/edit" element={<EditGodownScreen />} />
          </Route>
          <Route path="materials" element={<MaterialsScreenLayout />}>
            <Route path="rawMaterials" element={<ViewAllRawMaterialScreen />} />
            <Route path="products" element={<ViewAllProductsScreen />} />
            <Route path="productTypes" element={<ViewAllProductTypesScreen />} />
            <Route path="items" element={<ViewAllItemsScreen />} />
          </Route>
          <Route path="settings" element={<SettingsScreen />} />
          <Route path="kesharKaliCodes" element={<KesharKaliCodesScreen />} />
        </Route>
        <Route path="purchaseOrders" element={<PurchaseTabLayout />}>
          <Route path="print/:id" element={<PurchaseOrderPrintScreen />} />
          <Route path="productBags" element={<ProductOrdersTabLayout />}>
            <Route index element={<ViewAllPurchaseOrderScreen />} />
            <Route path="create" element={<CreatePurchaseOrderScreen />} />
            <Route path="approve/:id" element={<PurchaseOrderApproveScreen />} />
            <Route path="deliver/:id" element={<PurchaseOrderDeliverScreen />} />
          </Route>
          <Route path="paddyBags" element={<PaddyOrdersTabLayout />}>
            <Route index element={<ViewAllPurchaseOrderScreen />} />
            <Route path="create" element={<CreatePurchaseOrderScreen />} />
            <Route path="approve/:id" element={<PurchaseOrderApproveScreen />} />
            <Route path="deliver/:id" element={<PurchaseOrderDeliverScreen />} />
          </Route>
          <Route path="otherItems" element={<OtherItemsOrdersTabLayout />}>
            <Route index element={<ViewAllPurchaseOrderScreen />} />
            <Route path="create" element={<CreatePurchaseOrderScreen />} />
            <Route path="approve/:id" element={<PurchaseOrderApproveScreen />} />
            <Route path="deliver/:id" element={<PurchaseOrderDeliverScreen />} />
          </Route>
        </Route>
        <Route path="weighBridgeReceipts" element={<WeighBridgeTabLayout />}>
          <Route path="create" element={<CreateWeighBridgeReceipt />} />
          <Route path="edit" element={<EditWeighbridgeReceipts />} />
        </Route>
        <Route path="/shipment/inward" element={<InwardUserTabLayout />}>
          <Route path="rawMaterial" element={<RawMaterialTabLayout />}>
            <Route path="create" element={<CreateShipmentScreen />} />
            <Route path="unload/:id" element={<MaterialUnloadingScreen />} />
            <Route path="unload" element={<MaterialUnloadingScreen />} />
            <Route path="truckOut" element={<FinishShipmentScreen />} />
          </Route>
          <Route path="bags" element={<BagsTabLayout />}>
            <Route path="paddy" element={<PaddyBagScreen />} />
            <Route path="product" element={<ProductBagScreen />} />
          </Route>

          <Route path="otherItems" element={<OtherItemsScreen />} />
        </Route>
        <Route path="/shipment/outward" element={<OutwardUserTabLayout />}>
          <Route path="bags" element={<OutwardBagsTabLayout />}>
            <Route path="paddy" element={<OutwardPaddyBagsScreen />} />
          </Route>
          <Route path="otherItems" element={<OutwardOtherItemsScreen />} />
          <Route path="product" element={<OutwardProductTabLayout />}>
            <Route path="create" element={<CreateProductOutwardShipmentScreen />} />
            <Route path="orders" element={<OutwardProductShipmentOrderScreen />} />
            <Route path="loading" element={<OutwardProductLoadingScreen />} />
            <Route path="truckOut" element={<OutwardProductTruckOutwardScreen />} />
            <Route
              path="invoicing"
              element={<OutwardProductInvoicingScreen screenType="invoice" />}
            />
          </Route>
        </Route>
        <Route
          path="/viewReceipt/:id"
          element={<WeighbridgeReceiptComponent screenType="weighbridgeTab" />}
        />
        <Route path="/shipment/admin/inward" element={<InwardAdminTabLayout />}>
          <Route path="create" element={<OpenGRNScreen screenType="open" />} />
          <Route path="review" element={<OpenGRNScreen screenType="adminReview" />} />
          <Route path="process/:id" element={<ProcessGRNScreen />} />
          <Route path="edit/:id" element={<ModifyGRNScreen />} />
        </Route>
        <Route path="/shipment/admin/inward/create" element={<HomeScreen />} />
        <Route path="/user/milling/create" element={<HomeScreen />} />
        <Route path="/weighbridgeReceipts/create" element={<HomeScreen />} />
        <Route path="/shipment/internalTransfer" element={<InternalTransferTabLayout />}>
          <Route path="weighbridge/product" element={<WeighbridgeProductTabLayout />}>
            <Route
              path="create"
              element={<WeighbridgePaddyOrProductCreateShipmentScreen weighbridgeType="product" />}
            />
            <Route path="load" element={<MaterialLoadingForWeighbridgeProductScreen />} />
            <Route
              path="finish"
              element={<FinishInternalShipmentScreen weighbridgeType="product" />}
            />
          </Route>
          <Route path="weighbridge/paddy" element={<WeighbridgePaddyTabLayout />}>
            <Route
              path="create"
              element={<WeighbridgePaddyOrProductCreateShipmentScreen weighbridgeType="paddy" />}
            />
            <Route path="unload" element={<InternalShipmentMaterialUnloadingScreen />} />
            <Route
              path="finish"
              element={<FinishInternalShipmentScreen weighbridgeType="paddy" />}
            />
          </Route>
          <Route path="manual" element={<ManualInternalTransferScreen />} />
        </Route>
        <Route path="report" element={<ReportsTabLayout />}>
          <Route path="buyer/history" element={<BuyerHistoryReportScreen />} />
          <Route path="seller/history" element={<FarmerOrTraderHistoryScreen />} />
          <Route path="daily" element={<DailyReportsTabLayout />} />
          <Route path="shipments/inward" element={<InwardShipmentReportTabLayout />}>
            <Route path="paddy" element={<InwardShipmentRawMaterialScreen />} />
            <Route path="bags" element={<InwardShipmentBag />} />
            <Route path="otherItems" element={<InwardShipmentOtherItem />} />
          </Route>
          <Route path="shipments/inward/:type/:id/print" element={<InwardShipmentPrintScreen />} />
          <Route path="shipments/outward" element={<OutwardShipmentTabLayout />}>
            <Route path="products" element={<OutwardShipmentProductsScreen />} />
            <Route path="bags" element={<OutwardShipmentBagScreen />} />
            <Route path="otherItems" element={<OutwardShipmentOtherItemsScreen />} />
          </Route>
          <Route path="shipment/internal" element={<InternalShipmentsReportScreen />} />
          <Route path="shipment/internal/:id" element={<InternalShipmentSummaryScreen />} />
          <Route path="admin/monitor/shipments" element={<MonitorShipmentTabLayout />}>
            <Route path="inward" element={<MonitorShipmentsInward />} />
            <Route path="outward" element={<MonitorShipmentsOutward />} />
          </Route>
          <Route
            path="admin/monitor/shipments/inward/:type/:id"
            element={<InwardShipmentSummaryScreen />}
          />
          <Route
            path="admin/monitor/shipments/inward/:type/:id/print"
            element={<InwardShipmentPrintScreen />}
          />
          <Route path="milling" element={<MillingReportScreen />} />
          <Route path="weighbridge" element={<WeighBridgeReportScreen />} />
          <Route
            path="weighbridge/print/:id"
            element={<WeighbridgeReceiptComponent screenType="weighbridgeReport" />}
          />
        </Route>
        <Route path="/shipments/outward/:id" element={<OutwardShipmentSummaryScreen />} />
        <Route
          path="/shipment/completed/admin/outward/edit/:id"
          element={<EditCompletedOutwardShipmentScreen />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
