import React, { useState } from 'react';
import { Table, Button, Popconfirm, message } from 'antd';
import { PlusOutlined, WarningFilled, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { logger } from '../../../utils/helpers';
import { useQuery, useMutation, Reference, ApolloError, useApolloClient } from '@apollo/client';
import {
  GetAllTransportersAndTransporterVehiclesQuery,
  GetAllTransportersAndTransporterVehiclesQueryVariables,
  DeleteTransporterVehicleMutation,
  DeleteTransporterVehicleMutationVariables,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
  TransporterVehicles,
  Transporters,
} from '../../../graphql/graphql-types';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import colors from '../../../scss/variables.module.scss';
import { UploadedFileKeyAndUrlType } from '../../../utils/types';

/* type of transporter vehicles */
type TransporterVehiclesType = Pick<
  TransporterVehicles,
  'id' | 'number' | 'engineNo' | 'chassisNo' | 'capacityKgs' | 'rcS3Key'
> & {
  transporter: Pick<Transporters, 'id' | 'name'>;
};

/* loading get files url query with the help of loader */
const getFilesUrlQuery = loader('../../../graphql/queries/getFilesURLQuery.graphql');

/* loading get all transporters and transporter vehicles query with the help of loader */
const getAllTransportersAndTransporterVehiclesQuery = loader(
  '../../../graphql/queries/getAllTransportersAndTransporterVehiclesQuery.graphql',
);

/* loading delete transporter vehicle mutation with the help of loader */
const deleteTransporterVehicleMutation = loader(
  '../../../graphql/mutations/deleteTransporterVehicleMutation.graphql',
);

/* React functional component */
const ViewAllTransportersVehicleScreen = () => {
  /* use state to set the url and key of file(rc copy) */
  const [rcCopyUrlAndKey, setRcCopyUrlAndKey] = useState<Array<UploadedFileKeyAndUrlType> | null>(
    null,
  );

  /* useState to store selected transporters vehicle id when user hits delete button which will then use for loading indication */
  const [deleteTransporterVehicleId, setDeleteTransporterVehicleId] = useState<number | null>(null);

  /* extracting apollo Client from use use apollo client*/
  const apolloClient = useApolloClient();

  /* extracting navigate from use Navigate hook */
  const navigate = useNavigate();

  /* get all transporter and transporters vehicle query */
  const { data, loading, error } = useQuery<
    GetAllTransportersAndTransporterVehiclesQuery,
    GetAllTransportersAndTransporterVehiclesQueryVariables
  >(getAllTransportersAndTransporterVehiclesQuery, {
    onCompleted: ({ getAllTransporterVehicles }) => {
      const transporterVehicleWithNotNullRcS3Key = getAllTransporterVehicles.filter(
        (e) => e.rcS3Key && e.rcS3Key !== '',
      );
      apolloClient
        .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
          query: getFilesUrlQuery,
          variables: {
            keys: transporterVehicleWithNotNullRcS3Key.map((e) => e.rcS3Key as string),
          },
        })
        .then((res) => {
          const { getFilesUrl } = res.data;
          /* array to store generated url and key which will be displayed in the RC copy column as a Download link */
          const fileUrlArray: Array<UploadedFileKeyAndUrlType> = [];
          if (getFilesUrl) {
            getFilesUrl.forEach((item) => {
              if (item && item.url && item.key) {
                fileUrlArray.push({
                  key: item.key,
                  url: item.url,
                });
              }
            });
          }
          setRcCopyUrlAndKey(fileUrlArray);
        })
        .catch((getFilesUrlError: ApolloError) => {
          logger(getFilesUrlError);
        });
    },
  });

  /* delete transporter vehicle mutation */
  const [deleteTransporterVehicle] = useMutation<
    DeleteTransporterVehicleMutation,
    DeleteTransporterVehicleMutationVariables
  >(deleteTransporterVehicleMutation);

  /* function to handle delete transporter vehicle */
  const handleDeleteTransporterVehicle = (transporterVehicleId: number) => {
    deleteTransporterVehicle({
      variables: {
        id: transporterVehicleId,
      },
      update(cache, { data: deleteData }) {
        /* to get the id of the data to be deleted */
        const idToRemove = deleteData?.deleteTransporterVehicle?.id;
        cache.modify({
          fields: {
            transporterVehicles(existingTransporterVehicles: Array<Reference>, { readField }) {
              if (idToRemove) {
                return existingTransporterVehicles.filter(
                  (transportersData) => idToRemove !== readField('id', transportersData),
                );
              }
              return existingTransporterVehicles;
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteTransporterVehicleId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Transporter Vehicle has been successfully deleted.');
      })
      .catch((deleteTransporterVehicleError: ApolloError) => {
        let errorMsg = deleteTransporterVehicleError.message;
        setDeleteTransporterVehicleId(null);
        if (deleteTransporterVehicleError.message.includes('Foreign key violation.')) {
          errorMsg = 'Cannot delete since transporter vehicle is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteTransporterVehicleError);
      });
  };

  if (error) {
    return <div className="errorText">{error.message}</div>;
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          navigate('create');
        }}
      >
        Create vehicle
      </Button>
      <Table<TransporterVehiclesType>
        dataSource={data ? data.getAllTransporterVehicles : []}
        bordered
        size="small"
        className="tableStyle"
        loading={loading}
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<TransporterVehiclesType>
          key="number"
          title="Vehicle No"
          dataIndex="number"
          align="center"
          onFilter={(value, record) => {
            return record.number
              ? record.number.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="vehicle number"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<TransporterVehiclesType>
          key="transporter"
          title="Transporter Name"
          dataIndex="transporter"
          align="center"
          render={(text, record) => record.transporter.name.toUpperCase()}
          onFilter={(value, record) => {
            return record.transporter.name
              ? record.transporter.name.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="transporter name"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column
          key="engineNo"
          title="Engine No"
          dataIndex="engineNo"
          align="center"
          render={(engineNumber: string) => (engineNumber ? engineNumber.toUpperCase() : '-')}
        />
        <Table.Column
          key="chassisNo"
          title="Chassis No."
          dataIndex="chassisNo"
          align="center"
          render={(chassisNumber: string) => chassisNumber || '-'}
        />
        <Table.Column
          key="capacityKgs"
          title="Vehicle Capacity (tonnes)"
          dataIndex="capacityKgs"
          align="center"
          render={(capacity) => capacity / 1000 || '-'}
        />
        <Table.Column<TransporterVehiclesType>
          key="rcS3Key"
          title="RC Copy"
          dataIndex="rcS3Key"
          align="center"
          render={(text, record) => {
            if (Array.isArray(rcCopyUrlAndKey) && rcCopyUrlAndKey.length > 0) {
              const getItemUrlAndKey = rcCopyUrlAndKey.find((item) => item.key === record.rcS3Key);
              if (getItemUrlAndKey !== undefined && record.rcS3Key !== null) {
                return <a href={getItemUrlAndKey.url}>Download</a>;
              }
            }
            return '-';
          }}
        />
        <Table.Column<TransporterVehiclesType>
          key="actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    navigate(`${record.id}/edit`);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete Transporter Vehicle. Are you sure?"
                  okText="Yes"
                  onConfirm={() => {
                    handleDeleteTransporterVehicle(record.id);
                    setDeleteTransporterVehicleId(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                >
                  <Button
                    className="deleteButton"
                    loading={record.id === deleteTransporterVehicleId}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};

export default ViewAllTransportersVehicleScreen;
