import React, { useState } from 'react';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { FieldError, useForm } from 'react-hook-form';
import {
  CreateRawMaterialMutation,
  CreateRawMaterialMutationVariables,
  UpdateRawMaterialMutationMutation,
  UpdateRawMaterialMutationMutationVariables,
  GetAllProductsAndProductTypesQuery,
  CheckRiceRawMaterialQuery,
  CheckRiceRawMaterialQueryVariables,
  RawMaterials,
} from '../../../graphql/graphql-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, message, Modal, Spin } from 'antd';
import { logger } from '../../../utils/helpers';
import { inputComponentCommonStyle } from '../../../utils/globals';
import { OptionsDataType, RawMaterialType } from '../../../utils/types';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import RequiredMessage from '../../../components/RequiredMessage';
import RadioGroup from '../../../components/RadioGroup';

/* add or edit raw material form prop type */
type AddOrEditRawMaterialFormPropType = {
  /* prop type used to check whether the AddOrEditRawMaterial form is being called from 'edit' mode or 'add' mode */
  mode: 'add' | 'edit';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  rawMaterialDataToEdit?: RawMaterialType;
  /* prop type used to close AddOrEditRawMaterial form modal */
  closeModal: () => void;
};

/* AddOrEditRawMaterial form type */
type AddOrEditRawMaterialFormType = Pick<RawMaterials, 'id' | 'name' | 'type'> & {
  /* raw material product types */
  rawMaterialsProductTypes: number[];
};

/* load create raw material mutation */
const createRawMaterialMutation = loader(
  '../../../graphql/mutations/createRawMaterialMutation.graphql',
);

/* load update raw material mutation */
const updateRawMaterialMutation = loader(
  '../../../graphql/mutations/updateRawMaterialMutation.graphql',
);

/* loading check rice raw material query */
const checkRiceRawMaterialQuery = loader(
  '../../../graphql/queries/checkRiceRawMaterialQuery.graphql',
);

/* loading get all products and product types query */
const getAllProductsAndProductTypesQuery = loader(
  '../../../graphql/queries/getAllProductsAndProductTypesQuery.graphql',
);

/* loading get all raw material query */
const getAllRawMaterialQuery = loader('../../../graphql/queries/getAllRawMaterialsQuery.graphql');

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 9,
  /* input column span of FormItem */
  inputColSpan: 12,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field) */
  requiredMark: 'after' as 'after' | 'before',
};

/* add or edit raw material form validation */
const schema = yup.object({
  name: yup.string().required('Please enter raw material name and try again.').nullable(),
  rawMaterialsProductTypes: yup
    .array()
    .min(1, 'Please select product types and try again.')
    .required('Please select product types and try again.')
    .nullable(),
});

/* react functional component */
const AddOrEditRawMaterialForm = ({
  mode,
  rawMaterialDataToEdit = undefined,
  closeModal,
}: AddOrEditRawMaterialFormPropType) => {
  /* state used show loading indicator on 'Create 'or 'Update' button when creating or updating raw material */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* Mutation used to create new raw material */
  const [createRawMaterial] = useMutation<
    CreateRawMaterialMutation,
    CreateRawMaterialMutationVariables
  >(createRawMaterialMutation);

  /* Mutation used to edit/update raw material */
  const [updateRawMaterial] = useMutation<
    UpdateRawMaterialMutationMutation,
    UpdateRawMaterialMutationMutationVariables
  >(updateRawMaterialMutation);

  /* get check raw material query */
  const {
    data: checkRiceRawMaterialData,
    loading: checkRiceRawMaterialLoading,
    error: checkRiceRawMaterialError,
  } = useQuery<CheckRiceRawMaterialQuery, CheckRiceRawMaterialQueryVariables>(
    checkRiceRawMaterialQuery,
  );

  /* get all products and product types query */
  const {
    data: getAllProductsAndProductTypesData,
    loading: getAllProductsAndProductTypesLoading,
    error: getAllProductsAndProductTypesError,
  } = useQuery<GetAllProductsAndProductTypesQuery, GetAllProductsAndProductTypesQuery>(
    getAllProductsAndProductTypesQuery,
  );

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddOrEditRawMaterialFormType>({
    defaultValues:
      /* when form is in 'edit' mode, set raw material values as default values of the form */
      rawMaterialDataToEdit && mode === 'edit'
        ? {
            name: rawMaterialDataToEdit.name,
            type: rawMaterialDataToEdit.type,
            rawMaterialsProductTypes: rawMaterialDataToEdit.rawMaterialsProductTypes.map(
              (rawMaterial) => rawMaterial.productType.id,
            ),
          }
        : { name: '', rawMaterialsProductTypes: [], type: undefined },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* variable to store array of product types. which then pass as options to select 'Linked product types' field */
  let productTypeOptions: OptionsDataType[] = [];

  /* if getAllProductsAndProductTypesData loaded successfully , then show option list on linked product types select component */
  if (getAllProductsAndProductTypesData) {
    /* store product type as an options */
    productTypeOptions = getAllProductsAndProductTypesData.getAllProductTypes.map((product) => ({
      value: product.id,
      label: product.name,
    }));
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (checkRiceRawMaterialLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllProductsAndProductTypesError || checkRiceRawMaterialError) {
    return (
      <div className="errorText">
        {checkRiceRawMaterialError
          ? checkRiceRawMaterialError.message
          : getAllProductsAndProductTypesError && getAllProductsAndProductTypesError.message}
      </div>
    );
  }

  /* this const store rice material count, which is then used to disable 'raw material type' radio buttons */
  const riceMaterialCount =
    checkRiceRawMaterialData &&
    checkRiceRawMaterialData.rawMaterials_aggregate.aggregate &&
    checkRiceRawMaterialData.rawMaterials_aggregate.aggregate.count &&
    checkRiceRawMaterialData.rawMaterials_aggregate.aggregate.count > 0;

  /* function used to handle add or edit raw material form submit */
  const onSubmit = handleSubmit((data) => {
    setIsSubmitOrUpdateBtnLoading(true);

    /* when form is in 'edit' mode, execute update raw material mutation */
    if (mode === 'edit' && rawMaterialDataToEdit) {
      /* array to store initially linked product type id with selected rawMaterial to edit */
      const initialLinkedProductTypeIds = rawMaterialDataToEdit.rawMaterialsProductTypes.map(
        (item) => item.productType.id,
      );

      /* array to store id of newly selected product type id which will then pass to mutation argument as a newly selected product type ids */
      const newlyAddedProductTypeIds = data.rawMaterialsProductTypes
        .filter((productTypeId) => !initialLinkedProductTypeIds.includes(productTypeId))
        .map((item) => {
          return {
            productTypeId: item,
            rawMaterialId: rawMaterialDataToEdit.id,
          };
        });

      /* array to store ids of product types which are deleted from initial product type ids */
      const deletedLinkedProductTypeIds = initialLinkedProductTypeIds.filter(
        (productTypeId) => !data.rawMaterialsProductTypes.includes(productTypeId),
      );

      updateRawMaterial({
        variables: {
          name: data.name,
          type: data.type,
          rawMaterialId: rawMaterialDataToEdit.id,
          dltProductTypeIds: deletedLinkedProductTypeIds,
          objects: newlyAddedProductTypeIds,
        },
        refetchQueries: [{ query: getAllRawMaterialQuery }],
      })
        .then(() => {
          setIsSubmitOrUpdateBtnLoading(false);
          /* close Modal */
          closeModal();
          /* reset useForm */
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Raw material has been updated successfully.');
        })
        .catch((error: ApolloError) => {
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    } else {
      /* when form is in 'add' mode execute 'Create raw material' mutation */
      createRawMaterial({
        variables: {
          name: data.name,
          type: data.type,
          data: data.rawMaterialsProductTypes.map((product) => ({
            productTypeId: product,
          })),
        },
        /* after adding a new raw material, the update function is used to update the cache and display an updated raw materials array. */
        update(cache, { data: addedRawMaterial }) {
          cache.modify({
            fields: {
              rawMaterials(existingRawMaterials: Array<Reference>) {
                return [...existingRawMaterials, addedRawMaterial];
              },
            },
          });
        },
      })
        .then(() => {
          setIsSubmitOrUpdateBtnLoading(false);
          /* close Modal */
          closeModal();
          /* reset useForm */
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Raw material has been created successfully.');
        })
        .catch((error: ApolloError) => {
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  });

  return (
    <form>
      <Modal
        title={mode === 'add' ? 'Create new raw material' : 'Edit raw material'}
        visible={mode === 'add' || mode === 'edit'}
        onCancel={() => {
          /* close Modal */
          closeModal();
        }}
        footer={[
          <Button
            type="primary"
            key="add"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
            loading={isSubmitOrUpdateBtnLoading}
          >
            {mode === 'add' ? 'Create raw material' : 'Update'}
          </Button>,
          <Button
            type="default"
            key="cancel"
            onClick={() => {
              /* close Modal */
              closeModal();
              /* reset useForm */
              reset();
            }}
          >
            Cancel
          </Button>,
        ]}
        destroyOnClose
      >
        <RequiredMessage />
        <FormItem
          label="Raw material name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter raw material name "
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Raw material type"
          isRequired
          errorText={errors && errors.type ? errors.type.message : undefined}
          {...formItemStyleProps}
        >
          <RadioGroup
            name="type"
            rhfControllerProps={{
              control,
            }}
            defaultValue={'paddy'}
            options={[
              { label: 'Paddy', value: 'paddy' },
              {
                label: 'Rice',
                value: 'rice',
                disabled: riceMaterialCount ? true : false,
              },
            ]}
            disabled={rawMaterialDataToEdit && riceMaterialCount ? true : false}
          />
        </FormItem>
        <FormItem
          label="Linked product types"
          isRequired
          errorText={
            errors && errors.rawMaterialsProductTypes
              ? (errors.rawMaterialsProductTypes as unknown as FieldError).message
              : undefined
          }
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select linked product types "
            name="rawMaterialsProductTypes"
            rhfControllerProps={{
              control,
            }}
            mode="multiple"
            selectProps={{
              loading: getAllProductsAndProductTypesLoading,
              showSearch: true,
              optionFilterProp: 'children',
              filterOption: (input, option) => {
                let value;
                if (productTypeOptions && option) {
                  value = productTypeOptions.find((item) => item.value === option.value);
                }
                if (value) {
                  return value.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              },
            }}
            options={productTypeOptions}
          />
        </FormItem>
      </Modal>
    </form>
  );
};

export default AddOrEditRawMaterialForm;
