import { Button, Divider, message } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormItem from '../../../components/FormItem';
import dayjs from 'dayjs';
import InputNumber from '../../../components/InputNumber';
import { inputComponentCommonStyle } from '../../../utils/globals';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation } from '@apollo/client';
import {
  AddKesharKaliCodesMutation,
  AddKesharKaliCodesMutationVariables,
} from '../../../graphql/graphql-types';
import generateRandomCodes from '../../../utils/helpers/generateRandomCodes';
import FileSaver from 'file-saver';
import colors from '../../../scss/variables.module.scss';

const addKesharKaliCodesMutation = loader(
  '../../../graphql/mutations/addKesharKaliCodesMutation.graphql',
);

type KesharKaliCodesFormType = {
  codes: number | null;
};

const schema = yup.object({
  codes: yup
    .number()
    .min(1, 'Codes must be greater than zero')
    .max(40000, 'Codes must be less than or equal to 40,000')
    .required('Please enter number of codes')
    .nullable(),
});

const KesharKaliCodesScreen = () => {
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /** array to store generated codes which is used to download generated codes file */
  const generatedCodesArray: string[] = [];

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<KesharKaliCodesFormType>({
    defaultValues: {
      codes: null,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [addKesharKaliCodes] = useMutation<
    AddKesharKaliCodesMutation,
    AddKesharKaliCodesMutationVariables
  >(addKesharKaliCodesMutation);

  /**
   * The function `insertKesharKaliCodes` asynchronously inserts an array of codes, generates random codes for any
   * codes that were not successfully inserted, and handles errors by displaying an error message.
   * @param {string[]} codes - The `codes` parameter in the `insertKesharKaliCodes` function is an array of
   * strings that contains the codes to be inserted into a database. The function processes each code in
   * the array, adds them to the database using the `addKesharKaliCodes` mutation, and then checks for
   * any repeated code exists. If repeated codes exists then it again calls `insertKesharKaliCodes` function for
   * remaining codes which are not repeated in previous function call.
   */
  const insertKesharKaliCodes = async (codes: string[]) => {
    try {
      const result = await addKesharKaliCodes({
        variables: {
          objects: codes.map((code) => ({
            code,
          })),
        },
      });

      /** const to store codes which are stored in database */
      const insertedCodes =
        result &&
        result.data &&
        result.data.ecp13 &&
        result.data.ecp13.insert_labels &&
        result.data.ecp13.insert_labels.returning
          ? result.data.ecp13.insert_labels.returning.map((label) => label.code)
          : undefined;

      /** appending codes into `generatedCodesArray` which are successfully inserted into database */
      generatedCodesArray.push(...(insertedCodes as string[]));

      /** filtering codes from codes array which are not inserted into database because of repetition */
      const duplicatedCodes = codes.filter(
        (code) => insertedCodes && !insertedCodes.includes(code),
      );

      /** If codes are repeated then again generating random codes and inserting them into database  */
      if (Array.isArray(duplicatedCodes) && duplicatedCodes.length > 0) {
        const newKesharKaliCodes: string[] = [];
        for (let i = 0; i < duplicatedCodes.length; i++) {
          newKesharKaliCodes.push(generateRandomCodes(7));
        }

        await insertKesharKaliCodes(newKesharKaliCodes);
      }

      return true;
    } catch (error) {
      const errorObj = error as ApolloError;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(errorObj.message);
    }
  };

  return (
    <div className="tabLayout">
      <div style={{ paddingBottom: 0, marginTop: 0 }}>
        <h2 style={{ marginTop: '19px' }}>Keshar Kali Codes</h2>
      </div>
      <Divider style={{ marginTop: '5px', marginBottom: '0px' }} />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(async (formData) => {
          setIsSubmitBtnLoading(true);

          try {
            /* Array to store random generated codes */
            const codesArr = [];

            /*  loop to generate random codes according to input entered by user */
            for (let i = 0; i < (formData.codes as number); i++) {
              codesArr.push(generateRandomCodes(7));
            }

            await insertKesharKaliCodes(codesArr);

            /** const to store current date which is used for naming the downloadable file */
            const currentDate = dayjs().format('YYYY-MM-DD');

            /** creating a Blob object from an array of strings */
            const blob = new Blob([generatedCodesArray.join('\n')]);

            FileSaver.saveAs(blob, `KesharKaliCodes_${currentDate}.txt`);

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Labels have been added successfully');

            setIsSubmitBtnLoading(false);
          } catch (error) {
            const errorObj = error as ApolloError;

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(errorObj.message);

            setIsSubmitBtnLoading(false);
          }
        })}
      >
        <FormItem
          label="Number of codes :"
          labelColSpan={5}
          inputColSpan={10}
          errorText={errors && errors.codes ? errors.codes.message : undefined}
        >
          <>
            <InputNumber
              placeholder="Please enter number of codes"
              name="codes"
              rhfControllerProps={{
                control,
              }}
              customStyles={{ ...inputComponentCommonStyle, width: '70%' }}
            />
            <h5 style={{ fontStyle: 'italic', color: colors.helpTextColor, marginTop: 5 }}>
              You can generate maximum 40,000 codes
            </h5>
          </>
        </FormItem>
        <FormItem inputColSpan={10} labelColSpan={5}>
          <Button type="primary" htmlType="submit" loading={isSubmitBtnLoading}>
            Create
          </Button>
        </FormItem>
      </form>
    </div>
  );
};

export default KesharKaliCodesScreen;
