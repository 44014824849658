import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, message } from 'antd';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormItem from '../../../components/FormItem';
import {
  inputComponentCommonStyle,
  formItemStyleProps as AddInternalShipmentItemFormItemStyleProps,
} from '../../../utils/globals';
import {
  CreateInternalTransferShipmentItemMutation,
  CreateInternalTransferShipmentItemMutationVariables,
  UpdateInternalTransferShipmentItemMutation,
  UpdateInternalTransferShipmentItemMutationVariables,
  GetAllRawMaterialsQuery,
  GetAllRawMaterialsQueryVariables,
  GetAllGodownsMillsQuery,
  GetAllGodownsMillsQueryVariables,
  Enum_InternalTransfer_Source_Destination_Enum,
} from '../../../graphql/graphql-types';
import InputNumber from '../../../components/InputNumber';
import { logger } from '../../../utils/helpers';
import Select from '../../../components/Select';
import RadioGroup from '../../../components/RadioGroup';
import { CreateOrUpdateInternalShipmentItemFormType } from '../../../utils/types';

/* create or edit/update internal shipment item form prop type */
type CreateOrEditInternalShipmentFormPropType = {
  /* prop type used to check whether the CreateOrEditShipment form is being called from 'edit' mode or 'add' mode */
  mode: 'add' | 'edit';
  /* this prop type used to store id of the shipment which is selected to unload */
  shipmentIdSelectedToUnload: string;
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  shipmentItemDataToEdit?: CreateOrUpdateInternalShipmentItemFormType;
  /* prop type used to close CreateOrEditShipmentItem form modal */
  closeModal?: () => void;
};

/* loading create internal shipment item mutation  */
const createInternalShipmentItemMutation = loader(
  '../../../graphql/mutations/createInternalTransferShipmentItemMutation.graphql',
);

/* loading update internal shipment item mutation  */
const updateInternalShipmentItemMutation = loader(
  '../../../graphql/mutations/updateInternalTransferShipmentItemMutation.graphql',
);

/* loading get all raw material query */
const getAllRawMaterialQuery = loader('../../../graphql/queries/getAllRawMaterialsQuery.graphql');

/* loading get all godowns and mills query */
const getGodownAndMillsQuery = loader('../../../graphql/queries/getAllGodownsMillsQuery.graphql');

/* loading get open internal transfer shipment query */
const getInternalTransferOpenShipmentItemsQuery = loader(
  '../../../graphql/queries/internalTransferShipmentsItemsQuery.graphql',
);

/* this formItem style used when CreateOrEdit shipment item form is in edit mode */
const EditInternalShipmentItemFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 9,
  /* input column span of FormItem */
  inputColSpan: 14,
  /* formItem label style */
  labelStyle: { fontSize: 14 } as React.CSSProperties,
  /* formItem content style */
  customStyle: { paddingTop: 12, fontSize: 13 } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* validations required for useForm */
const schema = yup.object({
  rawMaterialId: yup.number().nullable().required('Please select raw material and try again.'),
  rmPaddyGrade: yup
    .string()
    .nullable()
    .when(['rawMaterialId'], {
      is: (rawMaterialId: number | null) => rawMaterialId !== null,
      then: yup.string().nullable().required('Please select paddy grade and try again.'),
    }),
  bagsCount: yup
    .number()
    .required('Please enter number of bags and try again.')
    .integer('Please enter valid number.')
    .min(0, 'Please enter valid number')
    .nullable(),
  sourceMillId: yup
    .number()
    .nullable()
    .when(['source'], {
      is: (source: string) => source === 'mill',
      then: yup.number().nullable().required('Please select mill and try again.'),
    }),
  sourceGodownId: yup
    .number()
    .nullable()
    .when(['source'], {
      is: (source: string) => source === 'godown',
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
  destinationGodownId: yup
    .number()
    .nullable()
    .when(['destination'], {
      is: (destination: string) => destination === 'godown',
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
  destinationMillId: yup
    .number()
    .nullable()
    .when(['destination'], {
      is: (destination: string) => destination === 'mill',
      then: yup.number().nullable().required('Please select mill and try again.'),
    }),
  rmEmptyBagsWtKg: yup
    .number()
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
});

/* react functional component */
const CreateOrEditInternalShipmentItemForm = ({
  mode,
  shipmentItemDataToEdit = undefined,
  shipmentIdSelectedToUnload,
  closeModal = () => {},
}: CreateOrEditInternalShipmentFormPropType) => {
  /* this state used to show loading indicator on submit or update button */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* this const used to store formItem style props based on 'mode & shipmentItemDataToEdit' prop value */
  const formItemStyleProps =
    shipmentItemDataToEdit && mode === 'edit'
      ? EditInternalShipmentItemFormItemStyleProps
      : AddInternalShipmentItemFormItemStyleProps;

  /* create internal shipment mutation */
  const [createInternalShipmentItem] = useMutation<
    CreateInternalTransferShipmentItemMutation,
    CreateInternalTransferShipmentItemMutationVariables
  >(createInternalShipmentItemMutation);

  /* update internal shipment mutation */
  const [updateInternalShipmentItem] = useMutation<
    UpdateInternalTransferShipmentItemMutation,
    UpdateInternalTransferShipmentItemMutationVariables
  >(updateInternalShipmentItemMutation);

  /* get all raw material data query used to show list of raw material as options on 'Raw material type' field */
  const {
    data: getAllRawMaterialData,
    loading: getAllRawMaterialLoading,
    error: getAllRawMaterialError,
  } = useQuery<GetAllRawMaterialsQuery, GetAllRawMaterialsQueryVariables>(getAllRawMaterialQuery);

  /* get all godown and mills data query used to show list of godowns and mills as options on 'Select source or destination godown' & 'select source or destination mill' fields */
  const {
    data: getGodownAndMillsData,
    loading: getGodownAndMillsDataLoading,
    error: getGodownAndMillsDataError,
  } = useQuery<GetAllGodownsMillsQuery, GetAllGodownsMillsQueryVariables>(getGodownAndMillsQuery);

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm<CreateOrUpdateInternalShipmentItemFormType>({
    defaultValues:
      mode === 'edit' && shipmentItemDataToEdit
        ? {
            rawMaterialId: shipmentItemDataToEdit.rawMaterialId,
            rmPaddyGrade: shipmentItemDataToEdit.rmPaddyGrade,
            bagsCount: shipmentItemDataToEdit.bagsCount,
            source: shipmentItemDataToEdit.source,
            sourceGodownId: shipmentItemDataToEdit.sourceGodownId,
            sourceMillId: shipmentItemDataToEdit.sourceMillId,
            destination: shipmentItemDataToEdit.destination,
            destinationGodownId: shipmentItemDataToEdit.destinationGodownId,
            destinationMillId: shipmentItemDataToEdit.destinationMillId,
            rmEmptyBagsWtKg: shipmentItemDataToEdit.rmEmptyBagsWtKg,
          }
        : {
            rawMaterialId: null,
            rmPaddyGrade: null,
            bagsCount: null,
            source: Enum_InternalTransfer_Source_Destination_Enum.Godown,
            sourceGodownId: null,
            sourceMillId: null,
            destination: Enum_InternalTransfer_Source_Destination_Enum.Godown,
            destinationGodownId: null,
            destinationMillId: null,
            rmEmptyBagsWtKg: null,
          },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* this variable used to determine 'paddy grade' field's visibility, based on selected raw material type */
  let isPaddyGradeFieldVisible = false;
  if (getAllRawMaterialData) {
    if (watch('rawMaterialId')) {
      /* const used to store selected raw material data from dropdown */
      const selectedRawMaterial = getAllRawMaterialData.getAllRawMaterials.find(
        (item) => item.id === watch('rawMaterialId'),
      );

      /* set 'paddy grade' field visible if selected raw material type is 'paddy' */
      isPaddyGradeFieldVisible =
        selectedRawMaterial && selectedRawMaterial.type === 'paddy' ? true : false;
    }
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getGodownAndMillsDataError || getAllRawMaterialError) {
    let errorMessage;
    if (getGodownAndMillsDataError) {
      errorMessage = getGodownAndMillsDataError.message;
    } else if (getAllRawMaterialError) {
      errorMessage = getAllRawMaterialError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* function to handle form submit */
  const onSubmit = (submittedFormData: CreateOrUpdateInternalShipmentItemFormType) => {
    /* destructing form data */
    const {
      rawMaterialId,
      rmPaddyGrade,
      bagsCount,
      source,
      sourceGodownId,
      sourceMillId,
      destination,
      destinationGodownId,
      destinationMillId,
      rmEmptyBagsWtKg,
    } = submittedFormData;

    /* this const used to store mutation variables which are common in both 'create internal shipment item' and 'update internal shipment item' mutation */
    const commonMutationVariables = {
      internalTransferShipmentId: shipmentIdSelectedToUnload,
      bagsCount: bagsCount,
      rmEmptyBagsWtKg: rmEmptyBagsWtKg || null,
      rmPaddyGrade: rmPaddyGrade || null,
      rawMaterialId: rawMaterialId,
      source: source || null,
      sourceGodownId: sourceGodownId || null,
      sourceMillId: sourceMillId || null,
      destination: destination || null,
      destinationGodownId: destinationGodownId || null,
      destinationMillId: destinationMillId || null,
    };

    /* if user has selected any shipment to unload then, add or edit shipment items for that shipment */
    if (shipmentIdSelectedToUnload) {
      /* when form is in 'edit' mode execute update shipment mutation */
      if (mode === 'edit' && shipmentItemDataToEdit) {
        updateInternalShipmentItem({
          variables: {
            id: shipmentItemDataToEdit.id,
            _set: commonMutationVariables,
          },
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Shipment item has been successfully updated.');
            reset();
            /* close modal */
            closeModal();
            setIsSubmitOrUpdateBtnLoading(false);
          })
          .catch((error: ApolloError) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(error.message);
            setIsSubmitOrUpdateBtnLoading(false);
            logger(error);
          });
      } else {
        createInternalShipmentItem({
          variables: {
            object: commonMutationVariables,
          },
          /* after creating a new shipment item, refetch open shipment items query to get updated shipment item list */
          refetchQueries: [
            {
              query: getInternalTransferOpenShipmentItemsQuery,
              variables: { _internalTransferShipmentId: shipmentIdSelectedToUnload },
            },
          ],
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Shipment item has been successfully created.');
            reset();
            setIsSubmitOrUpdateBtnLoading(false);
          })
          .catch((error: ApolloError) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(error.message);
            setIsSubmitOrUpdateBtnLoading(false);
            logger(error);
          });
      }
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormItem
        label="Raw Material"
        isRequired
        requiredMark="after"
        errorText={errors && errors.rawMaterialId ? errors.rawMaterialId.message : undefined}
        {...formItemStyleProps}
      >
        <Select
          customStyles={inputComponentCommonStyle}
          placeholder="Please select raw material"
          name="rawMaterialId"
          rhfControllerProps={{
            control,
          }}
          selectProps={{
            showSearch: true,
            loading: getAllRawMaterialLoading,
            optionFilterProp: 'children',
          }}
          options={
            getAllRawMaterialData
              ? getAllRawMaterialData.getAllRawMaterials.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))
              : []
          }
        />
      </FormItem>
      {isPaddyGradeFieldVisible ? (
        <FormItem
          label="Paddy Grade "
          requiredMark="after"
          isRequired
          errorText={errors && errors.rmPaddyGrade ? errors.rmPaddyGrade.message : undefined}
          {...formItemStyleProps}
        >
          <RadioGroup
            name="rmPaddyGrade"
            rhfControllerProps={{
              control,
            }}
            options={[
              { label: '1 (One)', value: '1 (One)' },
              {
                label: '2 (Two)',
                value: '2 (Two)',
              },
              {
                label: '3 (Three)',
                value: '3 (Three)',
              },
            ]}
          />
        </FormItem>
      ) : null}
      <FormItem
        label="No. Of Bags"
        isRequired
        requiredMark="after"
        errorText={errors && errors.bagsCount ? errors.bagsCount.message : undefined}
        {...formItemStyleProps}
      >
        <InputNumber
          customStyles={inputComponentCommonStyle}
          placeholder="Please enter number of bags"
          name="bagsCount"
          rhfControllerProps={{
            control,
          }}
          inputNumberProps={{ min: 0 }}
        />
      </FormItem>
      <FormItem
        label="Source"
        isRequired
        requiredMark="after"
        errorText={errors && errors.source ? errors.source.message : undefined}
        {...formItemStyleProps}
      >
        <RadioGroup
          name="source"
          rhfControllerProps={{
            control,
          }}
          defaultValue={null}
          options={[
            { label: 'Godown', value: 'godown' },
            {
              label: 'Mill',
              value: 'mill',
            },
          ]}
          onChange={(e) => {
            /* on selection of 'source', set 'source godown id ' or 'source mill id ' as a null */
            if (e.target.value === 'mill') {
              setValue('sourceGodownId', null);
            } else {
              setValue('sourceMillId', null);
            }
          }}
        />
      </FormItem>
      {watch('source') === 'mill' ? (
        <FormItem
          label="Select Source Mill ID"
          isRequired
          requiredMark="after"
          errorText={errors && errors.sourceMillId ? errors.sourceMillId.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select mill"
            name="sourceMillId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getGodownAndMillsDataLoading,
            }}
            options={
              getGodownAndMillsData
                ? getGodownAndMillsData.getAllMills.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                : []
            }
          />
        </FormItem>
      ) : null}
      {watch('source') === 'godown' ? (
        <FormItem
          label="Select Source Godown ID"
          isRequired
          requiredMark="after"
          errorText={errors && errors.sourceGodownId ? errors.sourceGodownId.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select godown"
            name="sourceGodownId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getGodownAndMillsDataLoading,
            }}
            options={
              getGodownAndMillsData
                ? getGodownAndMillsData.getAllGodowns.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                : []
            }
          />
        </FormItem>
      ) : null}

      <FormItem
        label="Destination"
        isRequired
        requiredMark="after"
        errorText={errors && errors.destination ? errors.destination.message : undefined}
        {...formItemStyleProps}
      >
        <RadioGroup
          name="destination"
          rhfControllerProps={{
            control,
          }}
          defaultValue={null}
          options={[
            { label: 'Godown', value: 'godown' },
            {
              label: 'Mill',
              value: 'mill',
            },
          ]}
          onChange={(e) => {
            /* on selection of 'destination', set 'destination godown id ' or 'destination mill id ' as a null */
            if (e.target.value === 'mill') {
              setValue('destinationGodownId', null);
            } else {
              setValue('destinationMillId', null);
            }
          }}
        />
      </FormItem>
      {watch('destination') === 'mill' ? (
        <FormItem
          label="Select Destination Mill ID"
          isRequired
          requiredMark="after"
          errorText={
            errors && errors.destinationMillId ? errors.destinationMillId.message : undefined
          }
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select mill ID"
            name="destinationMillId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getGodownAndMillsDataLoading,
            }}
            options={
              getGodownAndMillsData
                ? getGodownAndMillsData.getAllMills.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                : []
            }
          />
        </FormItem>
      ) : null}
      {watch('destination') === 'godown' ? (
        <FormItem
          label="Select Destination Godown ID"
          isRequired
          requiredMark="after"
          errorText={
            errors && errors.destinationGodownId ? errors.destinationGodownId.message : undefined
          }
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select godown ID"
            name="destinationGodownId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getGodownAndMillsDataLoading,
            }}
            options={
              getGodownAndMillsData
                ? getGodownAndMillsData.getAllGodowns.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                : []
            }
          />
        </FormItem>
      ) : null}

      <FormItem
        label="Empty Bag Weight (in kgs)"
        errorText={errors && errors.rmEmptyBagsWtKg ? errors.rmEmptyBagsWtKg.message : undefined}
        {...formItemStyleProps}
      >
        <InputNumber
          customStyles={inputComponentCommonStyle}
          placeholder="Please enter empty bag weight(in kgs)"
          name="rmEmptyBagsWtKg"
          rhfControllerProps={{
            control,
          }}
          inputNumberProps={{ min: 0, precision: 2 }}
        />
      </FormItem>
      <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
        <div
          style={
            mode === 'edit' ? { display: 'flex', justifyContent: 'end' } : { display: 'block' }
          }
        >
          <Button
            htmlType="submit"
            type="primary"
            loading={isSubmitOrUpdateBtnLoading}
            style={{ marginRight: 10 }}
          >
            {mode === 'edit' ? 'Update' : 'Submit'}
          </Button>
          {mode === 'edit' ? (
            <Button
              type="default"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
          ) : null}
        </div>
      </FormItem>
    </form>
  );
};

export default CreateOrEditInternalShipmentItemForm;
