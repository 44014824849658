import React from 'react';
import CreateBagInwardOrOutwardShipmentForm from '../../../components/CreateBagInwardOrOutwardShipmentForm';
import { Enum_Item_Category_Enum } from '../../../graphql/graphql-types';

/* React functional component */
const ProductBagScreen: React.FC = () => {
  return (
    <CreateBagInwardOrOutwardShipmentForm
      bagType={Enum_Item_Category_Enum.ProductBag}
      shipmentType="inward"
    />
  );
};

export default ProductBagScreen;
