import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

const OutwardShipmentTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();
  /* useLocation destructuring */
  const location = useLocation();

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('bags')) {
      return 'bagsKey';
    }
    if (location.pathname.includes('otherItems')) {
      return 'otherItemsKey';
    }
    return 'productsKey';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="productsKey"
        onTabClick={(key: string) => {
          if (key === 'bagsKey') {
            navigate('bags');
          } else if (key === 'otherItemsKey') {
            navigate('otherItems');
          } else {
            navigate('products');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab="Products" key="productsKey">
          <Outlet />
        </TabPane>
        <TabPane tab="Bags" key="bagsKey">
          <Outlet />
        </TabPane>
        <TabPane tab="Other Items" key="otherItemsKey">
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OutwardShipmentTabLayout;
