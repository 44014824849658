import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, message, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { loader } from 'graphql.macro';
import { logger } from '../../../utils/helpers';
import { ApolloError, useMutation, useQuery, Reference, useApolloClient } from '@apollo/client';
import { UploadFileDetailsType } from '../../../utils/types';
import {
  CreateTransporterVehicleMutation,
  CreateTransporterVehicleMutationVariables,
  GetAllTransportersQuery,
  GetAllTransportersQueryVariables,
  UpdateTransporterVehicleMutation,
  UpdateTransporterVehicleMutationVariables,
  DeleteS3FileMutation,
  DeleteS3FileMutationVariables,
} from '../../../graphql/graphql-types';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import InputNumber from '../../../components/InputNumber';
import Select from '../../../components/Select';
import { inputComponentCommonStyle } from '../../../utils/globals';
import { handleSingleFileUploadFunc } from '../../../utils/helpers';
import Upload from '../../../components/Upload';
import RequiredMessage from '../../../components/RequiredMessage';

/* add or edit transporter vehicle form type */
type AddOrEditTransporterVehicleFormType = {
  /* name of transporter */
  transporterId: number | null;
  /* vehicle number of transporter */
  vehicleNumber: string;
  /* engine number of vehicle */
  engineNumber: string;
  /* chassis number */
  chassisNumber: string;
  /* capacity */
  capacity: number | null;
  /* rc copy */
  rcCopy: UploadFileDetailsType | null;
};

// transporter vehicle form props type
type TransporterVehicleFormPropsType = {
  /* to check weather the transporter vehicle form is being called from  edit mode  or add mode */
  mode: 'edit' | 'add';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  dataToEdit?: AddOrEditTransporterVehicleFormType & {
    /* transporter id */
    idOfTransporter?: number;
    /* rc copy url used to download the file when user clicks on download button */
    rcCopyUrl: string | null;
    /* rcS3key to delete the file when user clicks on Delete and upload and also to delete the file when some uniqueness error has occurred given the file has already been uploaded to the server */
    rcS3Key: string | null;
  };
  /* when this form is called from the 'Outward -> Create Shipment' section, this prop type is used to handle the form's "UI." */
  calledFrom?: 'outward' | 'management';
  /* this prop type used to close modal (because In 'Outward section' we  used modal to display this form), when when this form will be called from 'Outward -> Create Shipment' section */
  closeModal?: () => void;
  /* this prop type used to define initial transporter id value when this form is called from 'Outward -> create shipment' section */
  initialTransporterIdValue?: number | null;
};

/* common props which are being passed to form Item */
const defaultTransporterFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input col span */
  inputColSpan: 8,
  /* error text style */
  errorTextStyle: { marginLeft: 20 },
};

/* this formItem style used when 'AddOrEditTransporter' form is called from 'Outward -> create shipment' section */
const outwardTransporterFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 9,
  /* input column span of FormItem */
  inputColSpan: 15,
  /* formItem label style */
  labelStyle: { fontSize: 14 } as React.CSSProperties,
  /* formItem content style */
  customStyle: { paddingTop: 15, fontSize: 14 } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* validations required for each input field */
const schema = yup.object({
  transporterId: yup.number().required(`Please select transporter's name and try again`).nullable(),
  vehicleNumber: yup
    .string()
    .required('Please enter vehicle number and try again')
    .matches(validationRegex.vehicleRegistrationNumber, {
      message: 'Please enter a valid vehicle number',
    }),
  engineNumber: yup.string(),
  chassisNumber: yup.string(),
  capacity: yup
    .number()
    .required('Please entry capacity(in tonnes) and try again')
    .nullable()
    .positive('Please enter a valid number.'),
});

/* loading delete S3 file mutation with the help of loader */
const deleteS3FileMutation = loader('../../../graphql/mutations/deleteS3FileMutation.graphql');

/* loading update transporter vehicle mutation with the help of loader */
const updateTransporterVehicleMutation = loader(
  '../../../graphql/mutations/updateTransporterVehicleMutation.graphql',
);

/* loading create transporter vehicle mutation with the help of loader */
const createTransporterVehicleMutation = loader(
  '../../../graphql/mutations/createTransporterVehicleMutation.graphql',
);

/* loading get All transporters  query with the help of loader */
const getAllTransportersQuery = loader('../../../graphql/queries/getAllTransportersQuery.graphql');

/* React functional component */
const AddOrEditTransporterVehicleForm = ({
  mode,
  dataToEdit = undefined,
  calledFrom = 'management',
  closeModal = () => {},
  initialTransporterIdValue,
}: TransporterVehicleFormPropsType) => {
  /* extracting navigate from use Navigate hook */
  const navigate = useNavigate();

  /* extracting params from use params hook */
  const params = useParams();

  /* state to set the loading condition of button */
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* this const used to store formItem style props based on 'calledFrom' prop value */
  const formItemStyleProps =
    calledFrom === 'outward'
      ? outwardTransporterFormItemStyleProps
      : defaultTransporterFormItemStyleProps;

  /* create Transporter vehicle mutation */
  const [createTransporterVehicle] = useMutation<
    CreateTransporterVehicleMutation,
    CreateTransporterVehicleMutationVariables
  >(createTransporterVehicleMutation);

  /* get All transporters query */
  const { data, loading, error } = useQuery<
    GetAllTransportersQuery,
    GetAllTransportersQueryVariables
  >(getAllTransportersQuery);

  /* update transporter vehicle mutation */
  const [updateTransporterVehicle] = useMutation<
    UpdateTransporterVehicleMutation,
    UpdateTransporterVehicleMutationVariables
  >(updateTransporterVehicleMutation);

  /* delete S3 file mutation */
  const [deleteS3File] = useMutation<DeleteS3FileMutation, DeleteS3FileMutationVariables>(
    deleteS3FileMutation,
  );

  /* extracting required content from use form hook */
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<AddOrEditTransporterVehicleFormType>({
    defaultValues:
      mode === 'edit' && dataToEdit
        ? {
            transporterId: dataToEdit.transporterId,
            vehicleNumber: dataToEdit.vehicleNumber,
            engineNumber: dataToEdit.engineNumber ? dataToEdit.engineNumber : '',
            chassisNumber: dataToEdit.chassisNumber ? dataToEdit.chassisNumber : '',
            capacity: dataToEdit.capacity ? dataToEdit.capacity / 1000 : null,
            rcCopy: dataToEdit.rcCopy,
          }
        : {
            transporterId: initialTransporterIdValue ? initialTransporterIdValue : null,
            vehicleNumber: '',
            engineNumber: '',
            chassisNumber: '',
            capacity: null,
            rcCopy: null,
          },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (loading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (error) {
    return <div className="errorText">{error.message}</div>;
  }

  /* error handling function for add and update transporter vehicle */
  const errorHandlingForAddAndUpdateTransporterVehicle = (addAndUpdateErrors: ApolloError) => {
    if (
      addAndUpdateErrors.message ===
      'Uniqueness violation. duplicate key value violates unique constraint "transporterVehicles_number_key"'
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('Vehicle number is already registered');
    } else if (
      addAndUpdateErrors.message ===
      'Uniqueness violation. duplicate key value violates unique constraint "custom_unique_transporterVehicles_chassisNo"'
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('Chassis number is already registered');
    } else if (
      addAndUpdateErrors.message ===
      'Uniqueness violation. duplicate key value violates unique constraint "custom_unique_transporterVehicles_engineNo"'
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('Engine number is already registered');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(addAndUpdateErrors.message);
    }
  };

  /* function to handle file delete */
  const handleFileDelete = (key: string) => {
    deleteS3File({
      variables: {
        s3Key: key,
      },
    })
      .then(() => {})
      .catch((deleteFileError: ApolloError) => {
        logger(deleteFileError);
      });
  };

  /* function to handle onSubmit data */
  // working of onSubmit function - first we run the create or update mutation without passing the rcCopyKey(this is done because
  // we want to avoid file upload to server before checking for all the other fields). After running the mutations we check for
  // fileData- so, if file data is empty which means the user has not done anything w.r.t the file input field so we dont do anything,
  // and if the user has uploaded a file (both for create and edit mode) then we will run the update mutation again ,this time
  // by passing the key of the file
  const onSubmit = async (transporterVehicleFormData: AddOrEditTransporterVehicleFormType) => {
    /* to store the result returned from create transporter vehicle mutation */
    let createTransporterVehicleMutationResult;
    /* to store the result returned from update transporter vehicle mutation */
    let updateTransporterVehicleMutationResult;
    setBtnLoader(true);
    try {
      // here we pass all input fields for both add and edit mode(depending upon which mode it is) except rcCopy field- This is done as we have to check for all the fields and if there is any error.
      // This approach of checking for all other input fields error is prudent as it will help to avoid unnecessary file upload on server
      if (mode === 'add') {
        createTransporterVehicleMutationResult = await createTransporterVehicle({
          variables: {
            object: {
              transporterId: transporterVehicleFormData && transporterVehicleFormData.transporterId,
              number: transporterVehicleFormData.vehicleNumber,
              capacityKgs: transporterVehicleFormData.capacity
                ? transporterVehicleFormData.capacity * 1000
                : null,
              engineNo: transporterVehicleFormData.engineNumber
                ? transporterVehicleFormData.engineNumber
                : null,
              chassisNo: transporterVehicleFormData.chassisNumber
                ? transporterVehicleFormData.chassisNumber
                : null,
            },
          },
          update(cache, { data: addedTransporterVehicle }) {
            /* extracting the new data added by user */
            const dataToAdd = addedTransporterVehicle?.createTransporterVehicle;
            cache.modify({
              fields: {
                transporterVehicles(existingTransporterVehicles: Array<Reference>) {
                  return [...existingTransporterVehicles, dataToAdd];
                },
              },
            });
          },
        });
      } else {
        updateTransporterVehicleMutationResult = await updateTransporterVehicle({
          variables: {
            id: Number(params.id),
            _set: {
              transporterId: transporterVehicleFormData.transporterId || null,
              number: transporterVehicleFormData.vehicleNumber,
              capacityKgs: transporterVehicleFormData.capacity
                ? transporterVehicleFormData.capacity * 1000
                : null,
              engineNo: transporterVehicleFormData.engineNumber
                ? transporterVehicleFormData.engineNumber
                : null,
              chassisNo: transporterVehicleFormData.chassisNumber
                ? transporterVehicleFormData.chassisNumber
                : null,
            },
          },
          update(cache, { data: updateData }) {
            /* data to update */
            const dataToUpdate = updateData?.updateTransporterVehicle;
            cache.modify({
              fields: {
                transporterVehicles(existingTransporterVehicles: Array<Reference>, { readField }) {
                  if (dataToUpdate) {
                    return existingTransporterVehicles.map((transportersData) => {
                      if (dataToUpdate.id === readField('id', transportersData)) {
                        return dataToUpdate;
                      }
                      return transportersData;
                    });
                  }
                  return existingTransporterVehicles;
                },
              },
            });
          },
        });
      }
      /* file list data which will be passed down to handleSingleFileUpload function */
      // It is important to pass fileData as file(see uploadFileDetailsType) to the handleSingleUpload function as passing fileListData gives a bug in which the file contents
      // do not get loaded. File is downloaded correctly but the content is not loaded
      const fileData = transporterVehicleFormData.rcCopy
        ? transporterVehicleFormData.rcCopy.file
        : null;

      if (fileData) {
        let driverId;
        if (
          createTransporterVehicleMutationResult &&
          createTransporterVehicleMutationResult.data &&
          createTransporterVehicleMutationResult.data.createTransporterVehicle
        ) {
          driverId = createTransporterVehicleMutationResult.data.createTransporterVehicle.id;
        }
        // after create and update mutation we run the update mutation again to update the rcCopyKey- so we require id of user
        // when in create mode as params.id will be null in that case.
        driverId = params.id ? params.id : driverId;

        /* const to store file s3Key which we will get after successful execution of handleSingleFileUploadFunc() */
        const newRcCopyKey = await handleSingleFileUploadFunc(apolloClient, fileData);

        // when the user clicks on re-upload and upload's a new file in that case we delete the previous file from the server
        if (newRcCopyKey && dataToEdit && dataToEdit.rcS3Key) {
          handleFileDelete(dataToEdit.rcS3Key);
        }

        // if there is fileData and if newRCCopyKey i.e if we successfully upload file on server then we will run update mutation just to update the rcS3Key value. ( rest all mutation argument are same as we used in create or update mutation)
        await updateTransporterVehicle({
          variables: {
            id: driverId as number,
            _set: {
              transporterId: dataToEdit && dataToEdit.idOfTransporter,
              number: transporterVehicleFormData.vehicleNumber,
              capacityKgs: transporterVehicleFormData.capacity
                ? transporterVehicleFormData.capacity * 1000
                : null,
              engineNo: transporterVehicleFormData.engineNumber
                ? transporterVehicleFormData.engineNumber
                : null,
              chassisNo: transporterVehicleFormData.chassisNumber
                ? transporterVehicleFormData.chassisNumber
                : null,
              rcS3Key: newRcCopyKey,
            },
          },
          update(cache, { data: updateData }) {
            /* data to update */
            const dataToUpdate = updateData?.updateTransporterVehicle;
            cache.modify({
              fields: {
                transporterVehicles(existingTransporterVehicles: Array<Reference>, { readField }) {
                  if (dataToUpdate) {
                    return existingTransporterVehicles.map((transportersData) => {
                      if (dataToUpdate.id === readField('id', transportersData)) {
                        return dataToUpdate;
                      }
                      return transportersData;
                    });
                  }
                  return existingTransporterVehicles;
                },
              },
            });
          },
        });
      }
      setBtnLoader(false);
      /* if this form is called from 'management -> Transporter vehicle section' then navigate to view all transporter vehicle screen.
          other wise close modal */
      if (calledFrom === 'management') {
        /* navigate to view all transporter vehicle screen */
        navigate('/management/transporterVehicles');
      } else {
        /* if this form is called from 'Outward -> create shipment' section then close modal */
        closeModal();
      }

      /* this const used to display message after adding transporter vehicle successfully, based on 'calledFrom' prop value  */
      const successMessage =
        calledFrom === 'outward'
          ? 'New transporter vehicle created successfully.'
          : 'Transporter Vehicle has been successfully created.';

      if (mode === 'edit' && updateTransporterVehicleMutationResult) {
        //eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Transporter Vehicle has been successfully updated.');
      }
      if (mode === 'add' && createTransporterVehicleMutationResult) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(successMessage);
      }
    } catch (addAndUpdateMutationErrors) {
      const addAndUpdateError = addAndUpdateMutationErrors as ApolloError;
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      errorHandlingForAddAndUpdateTransporterVehicle(addAndUpdateError);
      setBtnLoader(false);
    }
  };

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormItem
          label={calledFrom === 'management' ? 'Select Transporter Name' : 'Transporter Name'}
          isRequired
          errorText={errors && errors.transporterId ? errors.transporterId.message : undefined}
          requiredMark="after"
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select name of transporter"
            name="transporterId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              disabled: initialTransporterIdValue ? true : false,
              optionFilterProp: 'children',
              showSearch: true,
            }}
            options={
              data
                ? data.getAllTransporters.map((transporter) => {
                    return { value: transporter.id, label: transporter.name.toUpperCase() };
                  })
                : []
            }
          />
        </FormItem>
        <FormItem
          label="Vehicle No"
          isRequired
          requiredMark="after"
          errorText={errors && errors.vehicleNumber ? errors.vehicleNumber.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter vehicle number"
            name="vehicleNumber"
            rhfControllerProps={{
              control,
            }}
            onChange={(rhfOnChange, value) => {
              rhfOnChange(value.toUpperCase());
            }}
          />
        </FormItem>
        <FormItem
          label="Engine No"
          errorText={errors && errors.engineNumber ? errors.engineNumber.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter engine number"
            name="engineNumber"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Chassis No"
          errorText={errors && errors.chassisNumber ? errors.chassisNumber.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter chassis number"
            name="chassisNumber"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Capacity (in tonnes)"
          isRequired
          requiredMark="after"
          errorText={errors && errors.capacity ? errors.capacity.message : undefined}
          {...formItemStyleProps}
        >
          <InputNumber
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter capacity (in tonnes)"
            name="capacity"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        <FormItem
          label="Upload RC Copy"
          errorTextStyle={{ marginLeft: 20 }}
          {...formItemStyleProps}
        >
          {mode === 'edit' && dataToEdit && dataToEdit.rcS3Key ? (
            <>
              <Button
                style={{
                  border: '1px solid black',
                  display: watch('rcCopy') ? 'none' : 'inline-block',
                }}
                href={dataToEdit && (dataToEdit.rcCopyUrl as string)}
              >
                Download
              </Button>
              <Upload
                name="rcCopy"
                rhfControllerProps={{
                  control,
                }}
                children={
                  <Button
                    icon={<UploadOutlined />}
                    name="rcCopy"
                    style={{ border: '1px solid black', borderRadius: 5, marginLeft: 20 }}
                  >
                    Re-upload
                  </Button>
                }
              />
            </>
          ) : (
            <Upload
              name="rcCopy"
              rhfControllerProps={{
                control,
              }}
              children={
                <Button
                  icon={<UploadOutlined />}
                  name="rcCopy"
                  style={{ border: '1px solid black' }}
                >
                  Click to Upload
                </Button>
              }
            />
          )}
        </FormItem>
        <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
          <div
            style={
              calledFrom === 'outward'
                ? { display: 'flex', justifyContent: 'end' }
                : { display: 'block' }
            }
          >
            <Button
              htmlType="submit"
              type="primary"
              loading={btnLoader}
              style={{ marginRight: 10 }}
            >
              {mode === 'edit' ? 'Update' : 'Create Vehicle'}
            </Button>
            <Button
              type="default"
              onClick={() => {
                reset();
                /* if this form is called from 'management -> Transporter vehicle section' then navigate to view all transporter vehicle screen.
                  other wise close modal */
                if (calledFrom === 'management') {
                  /* navigate to view all transporter vehicle*/
                  navigate('/management/transporterVehicles');
                } else {
                  /* close modal */
                  closeModal();
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </FormItem>
      </form>
    </>
  );
};

export default AddOrEditTransporterVehicleForm;
