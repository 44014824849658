import React, { useState } from 'react';
import { ApolloError, Reference, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import {
  AddItemMutation,
  AddItemMutationVariables,
  Items_Insert_Input,
  UpdateItemMutation,
  UpdateItemMutationVariables,
  Items,
} from '../../../graphql/graphql-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, message, Modal } from 'antd';
import { logger } from '../../../utils/helpers';
import { inputComponentCommonStyle } from '../../../utils/globals';
import { OptionsDataType } from '../../../utils/types';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import RequiredMessage from '../../../components/RequiredMessage';

/* add or edit item form prop type */
type AddOrEditItemFormPropType = {
  /* prop type used to check whether the AddOrEditItem form is being called from 'edit' mode or 'add' mode */
  mode: 'add' | 'edit';
  /* prop type used to get options for item category select field */
  itemCategoryOptions: OptionsDataType[];
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  itemDataToEdit?: Items;
  /* prop type used to close AddOrEditItem form modal */
  closeModal: () => void;
};

/* load add Item mutation */
const addItemMutation = loader('../../../graphql/mutations/addItemMutation.graphql');

/* load update Item mutation */
const updateItemMutation = loader('../../../graphql/mutations/updateItemMutation.graphql');

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 6,
  /* input column span of FormItem */
  inputColSpan: 17,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field) */
  requiredMark: 'after' as 'after' | 'before',
};

/* add or edit item form validation */
const schema = yup.object({
  category: yup.string().required('Please select item category and try again.').nullable(),
  name: yup.string().required('Please enter item name and try again.').nullable(),
});

/* React functional component */
const AddOrEditItemForm = ({
  itemDataToEdit = undefined,
  mode,
  itemCategoryOptions,
  closeModal,
}: AddOrEditItemFormPropType) => {
  /* state used show loading indicator on 'Create' or 'Update' button when creating a new Item or updating existing item */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* Mutation used to add new Item */
  const [createItem] = useMutation<AddItemMutation, AddItemMutationVariables>(addItemMutation);

  /* Mutation used to edit/update Item */
  const [updateItem] = useMutation<UpdateItemMutation, UpdateItemMutationVariables>(
    updateItemMutation,
  );

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Items_Insert_Input>({
    defaultValues:
      /* when AddOrEditItem form is in edit mode, set item data values as a default values of form */
      itemDataToEdit && mode === 'edit'
        ? {
            name: itemDataToEdit.name,
            category: itemDataToEdit.category,
          }
        : { name: '', category: null },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* function used to handle add or edit Item form submit */
  const onSubmit = handleSubmit((data) => {
    setIsSubmitOrUpdateBtnLoading(true);

    /* variable to store common mutation variables, common to both create and update item mutation */
    const commonMutationVariables = {
      name: data.name,
      category: data.category,
    };

    if (mode === 'edit' && itemDataToEdit) {
      updateItem({
        variables: {
          id: itemDataToEdit.id,
          _set: commonMutationVariables,
        },
      })
        .then(() => {
          setIsSubmitOrUpdateBtnLoading(false);
          /* close Modal */
          closeModal();
          /* reset useForm */
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Item has been updated successfully.');
        })
        .catch((error: ApolloError) => {
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    } else {
      createItem({
        variables: {
          object: commonMutationVariables,
        },
        /* after adding a new Item, the update function is used to update the cache and display an updated Items array. */
        update(cache, { data: addedItem }) {
          cache.modify({
            fields: {
              items(existingItems: Array<Reference>) {
                return [...existingItems, addedItem];
              },
            },
          });
        },
      })
        .then(() => {
          setIsSubmitOrUpdateBtnLoading(false);
          /* close Modal */
          closeModal();
          /* reset useForm */
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Item has been created successfully.');
        })
        .catch((error: ApolloError) => {
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  });

  return (
    <form>
      <Modal
        title={mode === 'add' ? 'Create new item' : 'Edit item'}
        visible={mode === 'add' || mode === 'edit'}
        onCancel={() => {
          /* close modal */
          closeModal();
        }}
        footer={[
          <Button
            type="primary"
            key="update"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
            loading={isSubmitOrUpdateBtnLoading}
          >
            {mode === 'add' ? 'Create Item' : 'Update'}
          </Button>,
          <Button
            type="default"
            key="cancel"
            onClick={() => {
              /* close modal */
              closeModal();
              /* reset useForm */
              reset();
            }}
          >
            Cancel
          </Button>,
        ]}
        destroyOnClose
      >
        <RequiredMessage />
        <FormItem
          label="Item Category"
          isRequired
          errorText={errors && errors.category ? errors.category.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select item category "
            name="category"
            rhfControllerProps={{
              control,
            }}
            selectProps={{ showSearch: true }}
            options={itemCategoryOptions}
          />
        </FormItem>
        <FormItem
          label="Item name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter item name "
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
      </Modal>
    </form>
  );
};

export default AddOrEditItemForm;
