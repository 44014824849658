import React, { useState } from 'react';
import { ApolloError, Reference, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import {
  CreateProductMutation,
  CreateProductMutationVariables,
  UpdateProductMutation,
  UpdateProductMutationVariables,
} from '../../../graphql/graphql-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, message, Modal } from 'antd';
import { logger } from '../../../utils/helpers';
import { inputComponentCommonStyle } from '../../../utils/globals';
import { OptionsDataType, Product } from '../../../utils/types';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import RequiredMessage from '../../../components/RequiredMessage';

/* add or edit product form prop type */
type AddOrEditProductFormPropType = {
  /* prop type used to check whether the AddOrEditProduct form is being called from 'edit' mode or 'add' mode */
  mode: 'add' | 'edit';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  productDataToEdit?: Product;
  /* prop type used to get product types list which will be used as options for select product type component */
  productTypesList: OptionsDataType[];
  /* prop type used to close AddOrEditProductForm form modal */
  closeModal: () => void;
};

/* load add product mutation */
const addProductMutation = loader('../../../graphql/mutations/createProductMutation.graphql');

/* load edit product mutation */
const editProductMutation = loader('../../../graphql/mutations/updateProductMutation.graphql');

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 6,
  /* input column span of FormItem */
  inputColSpan: 17,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field) */
  requiredMark: 'after' as 'after' | 'before',
};

/* add or edit product form validation */
const schema = yup.object({
  brand: yup.string().required('Please enter product name and try again.'),
  productTypeId: yup.number().required('Please select product type and try again.').nullable(),
});

/* React functional component */
const AddOrEditProductForm = ({
  mode,
  productDataToEdit = undefined,
  closeModal,
  productTypesList,
}: AddOrEditProductFormPropType) => {
  /* state used show loading indicator on 'Create' or 'Update' button */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* Mutation used to add new product */
  const [createProduct] = useMutation<CreateProductMutation, CreateProductMutationVariables>(
    addProductMutation,
  );

  /* Mutation used to update existing product */
  const [updateProduct] = useMutation<UpdateProductMutation, UpdateProductMutationVariables>(
    editProductMutation,
  );

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Product>({
    defaultValues:
      mode === 'edit' && productDataToEdit
        ? { brand: productDataToEdit.brand, productTypeId: productDataToEdit.productTypeId }
        : { brand: '', productTypeId: null },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* function used to handle add or edit Product form submit */
  const onSubmit = handleSubmit((data) => {
    setIsSubmitOrUpdateBtnLoading(true);
    if (mode === 'edit' && productDataToEdit) {
      updateProduct({
        variables: {
          id: productDataToEdit.id,
          brand: data.brand,
          productTypeId: data.productTypeId as number,
        },
      })
        .then(() => {
          setIsSubmitOrUpdateBtnLoading(false);
          /* close Modal */
          closeModal();
          /* reset useForm */
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Product has been updated successfully.');
        })
        .catch((error: ApolloError) => {
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    } else {
      createProduct({
        variables: {
          brand: data.brand,
          productTypeId: data.productTypeId as number,
        },
        /* after adding a new product, the update function is used to update the cache and display an updated products array. */
        update(cache, { data: addedProductType }) {
          cache.modify({
            fields: {
              products(existingProducts: Array<Reference>) {
                return [...existingProducts, addedProductType];
              },
            },
          });
        },
      })
        .then(() => {
          setIsSubmitOrUpdateBtnLoading(false);
          /* close Modal */
          closeModal();
          /* reset useForm */
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Product has been created successfully.');
        })
        .catch((error: ApolloError) => {
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  });

  return (
    <form>
      <Modal
        title={mode === 'add' ? 'Create product' : 'Edit product'}
        visible={mode === 'add' || mode === 'edit'}
        onCancel={() => {
          /* close modal */
          closeModal();
        }}
        footer={[
          <Button
            type="primary"
            key="update"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
            loading={isSubmitOrUpdateBtnLoading}
          >
            {mode === 'add' ? 'Create product' : 'Update'}
          </Button>,
          <Button
            type="default"
            key="cancel"
            onClick={() => {
              /* close modal */
              closeModal();
              /* reset useForm */
              reset();
            }}
          >
            Cancel
          </Button>,
        ]}
        destroyOnClose
      >
        <RequiredMessage />
        <FormItem
          label="Product name"
          isRequired
          errorText={errors && errors.brand ? errors.brand.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter product name. "
            name="brand"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Product type"
          isRequired
          errorText={errors && errors.productTypeId ? errors.productTypeId.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select product type"
            name="productTypeId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              filterOption: (input, option) => {
                let value;
                if (productTypesList && option) {
                  value = productTypesList.find((item) => item.value === option.value);
                }
                if (value) {
                  return value.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }
                return false;
              },
            }}
            options={productTypesList}
          />
        </FormItem>
      </Modal>
    </form>
  );
};

export default AddOrEditProductForm;
