import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Descriptions, message, Spin } from 'antd';
import { loader } from 'graphql.macro';
import { ApolloError, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import RequiredMessage from '../../../components/RequiredMessage';
import FormItem from '../../../components/FormItem';
import { inputComponentCommonStyle, formItemStyleProps } from '../../../utils/globals';
import {
  CompleteInwardShipmentMutation,
  CompleteInwardShipmentMutationVariables,
  GetUnloadedInwardShipmentsQuery,
  GetUnloadedInwardShipmentsQueryVariables,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
  InwardShipments,
  GetSettingByNameQuery,
  GetSettingByNameQueryVariables,
} from '../../../graphql/graphql-types';
import InputNumber from '../../../components/InputNumber';
import { handleGetWeightFunction, logger } from '../../../utils/helpers';
import Select from '../../../components/Select';

/* complete inward shipment useForm type */
type CompleteInwardShipmentFormType = Pick<InwardShipments, 'emptyVehicleImage'> & {
  /* this type used to store empty vehicle weight */
  emptyVehicleWtKg: number | null;
  /* prop type used to store 'id' of the shipment whose vehicle number get selected from 'select vehicle' dropdown */
  shipmentIdFromVehicleNumber: number | null;
};

/* loading complete raw material inward shipment mutation  */
const completeInwardShipmentMutation = loader(
  '../../../graphql/mutations/completeInwardShipmentMutation.graphql',
);

/* loading get unloaded inward shipment query */
const getUnloadedInwardShipmentQuery = loader(
  '../../../graphql/queries/getUnloadedInwardShipmentsQuery.graphql',
);

/* loading get admin open inward shipments Query */
const getAdminOpenInwardShipmentsQuery = loader(
  '../../../graphql/queries/getAdminOpenInwardShipmentsQuery.graphql',
);

/* loading get bhagwati mill id query */
const getBhagwatiMillIdQuery = loader('../../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* loading get setting by name query with the help of loader */
const getSettingByNameQuery = loader('../../../graphql/queries/getSettingByNameQuery.graphql');

/* validations required for useForm */
const schema = yup.object({
  emptyVehicleWtKg: yup
    .number()
    .required('Please enter weight-2 (in kgs) and try again')
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
});

/* React functional component */
const FinishShipmentScreen = () => {
  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* this state used to show loading indicator on submit button */
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /* state to show loading indicator on 'get weight' button */
  const [getWeightButtonLoading, setGetWeightButtonLoading] = useState<boolean>(false);

  /* complete inward shipment mutation */
  const [completeInwardShipment] = useMutation<
    CompleteInwardShipmentMutation,
    CompleteInwardShipmentMutationVariables
  >(completeInwardShipmentMutation);

  /* get bhagwati mill id query used to show Mill name */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* get all unloaded inward shipments */
  const {
    data: getUnloadedInwardShipmentData,
    loading: getUnloadedInwardShipmentDataLoading,
    error: getUnloadedInwardShipmentDataError,
  } = useQuery<GetUnloadedInwardShipmentsQuery, GetUnloadedInwardShipmentsQueryVariables>(
    getUnloadedInwardShipmentQuery,
  );

  /* setting by name query is used to determine whether the 'emptyVehicleWtKg' field is enabled or disabled. */
  const {
    data: weightInputDisabledData,
    error: getSettingByNameError,
    loading: getSettingByNameLoader,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weightInputDisabled' },
  });

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
    clearErrors,
  } = useForm<CompleteInwardShipmentFormType>({
    defaultValues: {
      shipmentIdFromVehicleNumber: null,
      emptyVehicleWtKg: null,
      emptyVehicleImage: null,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getUnloadedInwardShipmentDataError || getBhagwatiMillIdQueryError || getSettingByNameError) {
    let errorMessage;
    if (getUnloadedInwardShipmentDataError) {
      errorMessage = getUnloadedInwardShipmentDataError.message;
    } else if (getBhagwatiMillIdQueryError) {
      errorMessage = getBhagwatiMillIdQueryError.message;
    } else if (getSettingByNameError) {
      errorMessage = getSettingByNameError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* function to get weight of empty vehicle */
  const getVehicleWeight = async () => {
    try {
      setGetWeightButtonLoading(true);

      // const to store vehicle weight from weighbridge
      const vehicleWeight = await handleGetWeightFunction(apolloClient);

      // const to store vehicle image
      // const vehicleImageData = await getWeightAndCaptureCctvImg(false);

      // setting vehicle weight
      setValue('emptyVehicleWtKg', vehicleWeight);

      /* if 'emptyVehicleWtKg' form field has any error, then clear it. after setting it's value  */
      if (errors.emptyVehicleWtKg) {
        clearErrors('emptyVehicleWtKg');
      }
      /* if function return has data then set that image to 'emptyVehicleImage' field */
      // if (vehicleImageData.s3Key) {
      //   setValue('emptyVehicleImage', vehicleImageData.s3Key);
      // }
      setGetWeightButtonLoading(false);
    } catch (err) {
      logger(err as ApolloError);
      setGetWeightButtonLoading(false);
    }
  };

  /* function to handle form submit */
  const onSubmit = (submittedFormData: CompleteInwardShipmentFormType) => {
    setIsSubmitBtnLoading(true);

    /* destructing form data */
    const { shipmentIdFromVehicleNumber, emptyVehicleWtKg, emptyVehicleImage } = submittedFormData;

    /* execute complete inward shipment mutation */
    completeInwardShipment({
      variables: {
        id: shipmentIdFromVehicleNumber as number,
        emptyVehicleWtKg: emptyVehicleWtKg,
        emptyVehicleImage: emptyVehicleImage || null,
        emptyWtTakenAt: new Date(),
      },
      /* refetching unloaded inward shipment query to get updated 'unloaded shipment data' after finishing a shipment */
      refetchQueries: [
        { query: getUnloadedInwardShipmentQuery },
        { query: getAdminOpenInwardShipmentsQuery },
      ],
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment has been successfully finished');
        reset();
        setIsSubmitBtnLoading(false);
      })
      .catch((error: ApolloError) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        setIsSubmitBtnLoading(false);
        logger(error);
      });
  };

  /* if getUnloadedInwardShipment query loaded successfully, then show that data  */
  if (
    getUnloadedInwardShipmentData &&
    !getUnloadedInwardShipmentDataLoading &&
    !getBhagwatiMillIdQueryLoader &&
    !getSettingByNameLoader
  ) {
    /* destructing getUnloadedInwardShipment query data */
    const { getUnloadedInwardShipments } = getUnloadedInwardShipmentData;

    /* const used to store shipment data, which is selected to finish/complete */
    const shipmentSelectedToFinish = getUnloadedInwardShipments.find(
      (item) => item.id === watch('shipmentIdFromVehicleNumber'),
    );

    return (
      <>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <RequiredMessage />
          <FormItem label="Select Vehicle" isRequired requiredMark="after" {...formItemStyleProps}>
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select vehicle number"
              name="shipmentIdFromVehicleNumber"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                optionFilterProp: 'children',
              }}
              options={getUnloadedInwardShipments.map((item) => ({
                value: item.id,
                label: item.vehicleNumber as string,
              }))}
            />
          </FormItem>
          {watch('shipmentIdFromVehicleNumber') && shipmentSelectedToFinish ? (
            <>
              <Descriptions
                colon={false}
                column={1}
                labelStyle={{ width: '17%' }}
                style={{ marginTop: 15 }}
                contentStyle={{ textTransform: 'capitalize' }}
              >
                <Descriptions.Item label="GRN">{shipmentSelectedToFinish.grn}</Descriptions.Item>
                <Descriptions.Item label="Vehicle Number">
                  {shipmentSelectedToFinish.vehicleNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Driver Name">
                  {shipmentSelectedToFinish.driverName}
                </Descriptions.Item>
                <Descriptions.Item label="Weight-1 (in kgs)">
                  {shipmentSelectedToFinish.fullVehicleWtKg &&
                    (shipmentSelectedToFinish.fullVehicleWtKg as number).toFixed(2)}
                </Descriptions.Item>
                <Descriptions.Item label="Mill Name">
                  {getBhagwatiMillIdData &&
                    getBhagwatiMillIdData.mills[0] &&
                    getBhagwatiMillIdData.mills[0].name}
                </Descriptions.Item>
              </Descriptions>
              <FormItem
                label="Weight-2 (in kgs)"
                isRequired
                requiredMark="after"
                errorText={
                  errors && errors.emptyVehicleWtKg ? errors.emptyVehicleWtKg.message : undefined
                }
                {...formItemStyleProps}
                customStyle={{ paddingTop: 0 }}
              >
                <div style={{ display: 'flex' }}>
                  <InputNumber
                    customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
                    placeholder="Please enter weight-2 (in kgs)"
                    name="emptyVehicleWtKg"
                    rhfControllerProps={{
                      control,
                    }}
                    inputNumberProps={{
                      min: 0,
                      precision: 2,
                      disabled:
                        weightInputDisabledData &&
                        !weightInputDisabledData.getSettingByName[0].intValue
                          ? true
                          : false,
                    }}
                  />
                  <Button
                    type="primary"
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                      getVehicleWeight().catch((err: ApolloError) => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        message.error(err.message);
                      });
                    }}
                    loading={getWeightButtonLoading}
                  >
                    Get Weight
                  </Button>
                </div>
              </FormItem>
              <FormItem {...formItemStyleProps} customStyle={{ paddingBottom: 10 }}>
                <Button htmlType="submit" type="primary" loading={isSubmitBtnLoading}>
                  Submit
                </Button>
              </FormItem>
            </>
          ) : null}
        </form>
      </>
    );
  }
  return <Spin className="loadingSpinner" />;
};

export default FinishShipmentScreen;
