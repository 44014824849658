import React from 'react';
import { Table } from 'antd';
import { Enum_Item_Category, InternalTransferShipmentItems } from '../../../graphql/graphql-types';

/* internal shipment summary report table prop type */
type InternalShipmentSummaryTablePropType = {
  /* prop type used to get data for the table */
  data: Array<InternalTransferShipmentItems>;
  /* prop type used to get material type of shipement */
  shipmentMaterialType: Enum_Item_Category;
};

/* react functional component */
const InternalShipmentSummaryTable = ({
  data,
  shipmentMaterialType,
}: InternalShipmentSummaryTablePropType) => {
  return (
    <Table
      dataSource={data}
      style={{ marginTop: 15 }}
      rowKey="id"
      className="tableStyle"
      bordered
      size="small"
      pagination={{ showSizeChanger: true }}
    >
      {shipmentMaterialType.value === 'raw_material' ? (
        <Table.Column<InternalTransferShipmentItems>
          title="Raw Material"
          dataIndex="rawMaterial"
          align="center"
          render={(text, record) => (record.rawMaterial ? record.rawMaterial.name : '-')}
        />
      ) : null}

      {shipmentMaterialType.value === 'raw_material' ? (
        <Table.Column
          title="Paddy Grade"
          dataIndex="rmPaddyGrade"
          align="center"
          render={(paddyGrade) => <span>{paddyGrade || '-'}</span>}
        />
      ) : null}

      {shipmentMaterialType.value === 'product' ? (
        <Table.Column<InternalTransferShipmentItems>
          title="Product Type"
          dataIndex="productType"
          width={200}
          align="center"
          render={(text, record) => {
            return record.product ? record.product.productType.name || '-' : null;
          }}
        />
      ) : null}

      {shipmentMaterialType.value === 'product' ? (
        <Table.Column<InternalTransferShipmentItems>
          title="Product"
          dataIndex="product"
          align="center"
          width={200}
          render={(text, record) => {
            return (
              <span style={{ textTransform: 'capitalize', marginTop: 10 }}>
                {record.product ? record.product.brand || '-' : null}
              </span>
            );
          }}
        />
      ) : null}

      {shipmentMaterialType.value === 'product' ? (
        <Table.Column
          title="Single Bag Weight (in kgs)"
          dataIndex="bagWtKg"
          align="center"
          width={200}
          render={(singleBagWeight) => <span>{singleBagWeight || '-'}</span>}
        />
      ) : null}

      <Table.Column
        title="No of Bags"
        dataIndex="bagsCount"
        align="center"
        render={(noOfBags) => <span>{noOfBags || '-'}</span>}
      />

      {shipmentMaterialType.value === 'raw_material' ? (
        <Table.Column
          title="Empty Bag Weight (in kgs)"
          dataIndex="rmEmptyBagsWtKg"
          align="center"
          width={150}
          render={(emptyBagWeight) => <span>{emptyBagWeight || '-'}</span>}
        />
      ) : null}

      {shipmentMaterialType.value === 'raw_material' ? (
        <Table.Column<InternalTransferShipmentItems>
          title="Net Material Weight (kgs/bag)"
          dataIndex="netMaterialWtPerBagKg"
          width={150}
          align="center"
          render={(netMaterialWeight) => <span>{netMaterialWeight || '-'}</span>}
        />
      ) : null}

      <Table.Column<InternalTransferShipmentItems>
        title="Source"
        dataIndex="source"
        align="center"
        width={230}
        render={(source, record) => {
          return (
            <>
              <span style={{ fontWeight: 'bold' }}>
                {source === 'godown' ? 'Godown: ' : 'Mill: '}
              </span>
              <span style={{ textTransform: 'capitalize' }}>
                {source === 'godown' && record.sourceGodown
                  ? record.sourceGodown.name || '-'
                  : (record.sourceMill && record.sourceMill.name) || '-'}
              </span>
            </>
          );
        }}
      />
      <Table.Column<InternalTransferShipmentItems>
        title="Destination"
        dataIndex="destination"
        align="center"
        width={230}
        render={(destination, record) => {
          return (
            <>
              {destination === 'godown' ? (
                <span style={{ fontWeight: 'bold' }}>Godown: </span>
              ) : (
                <span style={{ textTransform: 'capitalize' }}>
                  <span style={{ fontWeight: 'bold', textAlign: 'left' }}>Mill: </span>
                  {(record.destinationMill && record.destinationMill.name) || '-'}
                  <br />
                </span>
              )}

              {destination === 'godown' ? (
                <span style={{ textTransform: 'capitalize' }}>
                  {(record.destinationGodown && record.destinationGodown.name) || '-'}
                </span>
              ) : null}
            </>
          );
        }}
      />
    </Table>
  );
};

export default InternalShipmentSummaryTable;
