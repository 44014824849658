import React, { useState } from 'react';
import { Button, message, Modal, Popconfirm, Table } from 'antd';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation, useQuery, Reference } from '@apollo/client';
import {
  Enum_Item_Category_Enum,
  InternalTransferOpenShipmentsQuery,
  InternalTransferOpenShipmentsQueryVariables,
  DeleteInternalTransferShipmentMutationVariables,
  DeleteInternalTransferShipmentMutation,
} from '../../graphql/graphql-types';
import {
  CreateOrUpdateInternalShipmentFormType,
  PaddyOrProductInternalTransferShipmentType,
} from '../../utils/types';
import { logger } from '../../utils/helpers';
import { WarningFilled } from '@ant-design/icons';
import colors from '../../scss/variables.module.scss';
import CreateOrEditInternalShipmentFormComponent from './CreateOrEditInternalShipmentFormComponent';

/* loading delete internal shipment mutation  */
const deleteInternalShipmentMutation = loader(
  '../../graphql/mutations/deleteInternalTransferShipmentMutation.graphql',
);

/* loading get open internal transfer shipment query */
const getInternalTransferOpenShipmentsQuery = loader(
  '../../graphql/queries/internalTransferOpenShipmentsQuery.graphql',
);

/* create shipment for weighbridge paddy or product component prop type */
type CreateShipmentForWeighbridgePaddyOrProductComponentPropType = {
  /* this prop is used to know from which screen the component is called weighbridge paddy or product */
  weighbridgeType: 'paddy' | 'product';
};

/* React functional component */
const WeighbridgePaddyOrProductCreateShipmentScreen = ({
  weighbridgeType,
}: CreateShipmentForWeighbridgePaddyOrProductComponentPropType) => {
  /* state used to store id of the shipment whose delete button is clicked which is then used to show loading indicator on delete button while deleting a shipment */
  const [shipmentIdToDltAndShowLoading, setShipmentIdToDltAndShowLoading] = useState<string | null>(
    null,
  );

  /* state used to store data of the shipment whose edit button is clicked which is then used to update that shipment data & maintain modal visibility */
  const [shipmentDataAndModalVisible, setShipmentDataAndModalVisible] =
    useState<CreateOrUpdateInternalShipmentFormType | null>(null);

  /* get all open internal shipments */
  const {
    data: getOpenInternalShipmentData,
    loading: getOpenInternalShipmentDataLoading,
    error: getOpenInternalShipmentDataError,
  } = useQuery<InternalTransferOpenShipmentsQuery, InternalTransferOpenShipmentsQueryVariables>(
    getInternalTransferOpenShipmentsQuery,
    {
      variables: {
        materialType:
          weighbridgeType === 'paddy'
            ? Enum_Item_Category_Enum.RawMaterial
            : Enum_Item_Category_Enum.Product,
      },
    },
  );

  /* delete internal shipment mutation */
  const [deleteInternalShipment] = useMutation<
    DeleteInternalTransferShipmentMutation,
    DeleteInternalTransferShipmentMutationVariables
  >(deleteInternalShipmentMutation);

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getOpenInternalShipmentDataError) {
    return <div className="errorText">{getOpenInternalShipmentDataError.message}</div>;
  }

  /* function to handle delete open internal shipment */
  const deleteInternalShipmentFunc = (shipmentId: string) => {
    setShipmentIdToDltAndShowLoading(shipmentId);
    deleteInternalShipment({
      variables: {
        id: shipmentId,
      },
      /* after deleting a shipment, the update function is used to update the cache and display an updated shipment array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a shipment that was just removed. */
        const deletedDataId = deleteData?.delete_internalTransferShipments_by_pk?.id;
        cache.modify({
          fields: {
            internalTransferShipments(existingInternalShipments: Array<Reference>, { readField }) {
              if (deletedDataId) {
                return existingInternalShipments.filter(
                  (shipmentRef) => deletedDataId !== readField('id', shipmentRef),
                );
              }
              return existingInternalShipments;
            },
          },
        });
      },
    })
      .then(() => {
        setShipmentIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment has been successfully deleted');
      })
      .catch((error: ApolloError) => {
        setShipmentIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  return (
    <>
      {shipmentDataAndModalVisible ? (
        <Modal
          visible={shipmentDataAndModalVisible ? true : false}
          title="Edit Shipment"
          footer={null}
          className="editInternalShipmentModal"
          onCancel={() => {
            setShipmentDataAndModalVisible(null);
          }}
          destroyOnClose
        >
          <CreateOrEditInternalShipmentFormComponent
            mode="edit"
            shipmentDataToEdit={shipmentDataAndModalVisible}
            closeModal={() => {
              setShipmentDataAndModalVisible(null);
            }}
            weighbridgeType={weighbridgeType}
          />
        </Modal>
      ) : null}
      <CreateOrEditInternalShipmentFormComponent mode="add" weighbridgeType={weighbridgeType} />
      <h3>Open Shipments</h3>
      <Table<PaddyOrProductInternalTransferShipmentType>
        dataSource={
          getOpenInternalShipmentData && getOpenInternalShipmentData.internalTransferShipments
        }
        loading={getOpenInternalShipmentDataLoading}
        className="tableStyle"
        bordered
        size="small"
        rowKey="grn"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<PaddyOrProductInternalTransferShipmentType>
          title="GRN"
          dataIndex="grn"
          key="grn"
          align="center"
          render={(text, record) => record.grn || '-'}
        />
        <Table.Column<PaddyOrProductInternalTransferShipmentType>
          title="Vehicle No."
          dataIndex="vehicleNumber"
          key="vehicleNumber"
          align="center"
          render={(text, record) => record.vehicleNumber || '-'}
        />
        <Table.Column
          title="Driver Name"
          dataIndex="personName"
          key="personName"
          align="center"
          render={(personName) => (
            <span style={{ textTransform: 'capitalize' }}>{personName || '-'}</span>
          )}
        />
        <Table.Column<PaddyOrProductInternalTransferShipmentType>
          title="Weight - 1 (in kgs)"
          dataIndex="firstVehicleWtKg"
          align="center"
          key="firstVehicleWtKg"
          render={(text, record) => record.firstVehicleWtKg}
        />
        <Table.Column<PaddyOrProductInternalTransferShipmentType>
          key="Actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    setShipmentDataAndModalVisible({
                      id: record.id,
                      personName: record.personName,
                      firstVehicleWtKg: record.firstVehicleWtKg || null,
                      firstVehicleImage: record.firstVehicleImage,
                      vehicleNumber: record.vehicleNumber,
                      firstVehicleWtTakenAt: record.firstVehicleWtTakenAt,
                    });
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete shipment. Are you sure?"
                  okText="Yes"
                  onConfirm={() => {
                    deleteInternalShipmentFunc(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                >
                  <Button
                    className="deleteButton"
                    loading={record.id === shipmentIdToDltAndShowLoading ? true : false}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};

export default WeighbridgePaddyOrProductCreateShipmentScreen;
