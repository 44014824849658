import React, { useEffect, useState } from 'react';
import { Button, Divider, message, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import {
  GetBhagwatiMillIdQuery,
  GetSettingByNameQuery,
  GetSettingByNameQueryVariables,
  UpdateMillDetailsMutation,
  UpdateMillDetailsMutationVariables,
  UpdateSettingMutation,
  UpdateSettingMutationVariables,
} from '../../../graphql/graphql-types';
import { logger } from '../../../utils/helpers';
import InputNumber from '../../../components/InputNumber';
import { inputComponentCommonStyle } from '../../../utils/globals';
import FormItem from '../../../components/FormItem';
import Switch from '../../../components/Switch';
import Input from '../../../components/Input';

/* loading get setting by name query */
const getSettingByNameQuery = loader('../../../graphql/queries/getSettingByNameQuery.graphql');

/* load update Setting Mutation mutation */
const updateSettingMutation = loader('../../../graphql/mutations/updateSettingMutation.graphql');

/* loading get bhagwati mill id query */
const getBhagwatiMillIdQuery = loader('../../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* load update mill address mutation */
const updateMillDetailsMutation = loader(
  '../../../graphql/mutations/updateMillDetailsMutation.graphql',
);

/* validations required for input field */
const schema = yup.object({
  /*  validation for weightBridge Charges  */
  weightBridgeCharges: yup.number().nullable().positive('Please enter a valid number'),
  millAddress: yup.string().required('Please enter address and try again.'),
  companyName: yup.string().required('Please enter company name and try again.'),
});

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 5,
  /* input column span of FormItem */
  inputColSpan: 10,
};

/* Setting Form Field type  */
type SettingFormField = {
  /* Weighbridge Manual Entry */
  weightInputDisabled: boolean;
  /* Weighbridge Charges */
  weightBridgeCharges: number;
  // mill address
  millAddress: string;
  // company name
  companyName: string;
};

/* react functional component */
const SettingsScreen = () => {
  /* get setting for Weighbridge Manual Entry fields using GetSettingByNameQuery  */
  const {
    data: getSettingByWeightInputDisabledData,
    error: getSettingByWeightInputDisabledError,
    loading: getSettingByWeightInputDisabledLoading,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weightInputDisabled' },
  });

  /* get setting for Weighbridge Charges fields using GetSettingByNameQuery  */
  const {
    data: getSettingByWeighbridgeChargesData,
    error: getSettingByWeighbridgeChargesError,
    loading: getSettingByWeighbridgeChargesLoading,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weighbridgeCharges' },
  });

  /* fetching mill data from query */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdError,
    loading: getBhagwatiMillIdLoading,
  } = useQuery<GetBhagwatiMillIdQuery, GetSettingByNameQueryVariables>(getBhagwatiMillIdQuery);

  /* create update setting mutation */
  const [updateSetting] = useMutation<UpdateSettingMutation, UpdateSettingMutationVariables>(
    updateSettingMutation,
  );

  /* update mill address mutation */
  const [updateMillDetails] = useMutation<
    UpdateMillDetailsMutation,
    UpdateMillDetailsMutationVariables
  >(updateMillDetailsMutation);

  /* this state used to show loading indicator on save button of (Weighbridge Manual Entry or mill details or Weigh bridge Charges) form*/
  const [isSaveButtonLoading, setIsSaveButtonLoading] = useState<
    'weightInputDisabled' | 'weighbridgeCharges' | 'updateMillDetails' | null
  >(null);

  /* variable used to set value for disable prop of weightInput field */
  let disableWeightInputInitialValue = true;

  /* if int value is 1 then weight input will be enable in all other cases its will be disable */
  if (
    getSettingByWeightInputDisabledData &&
    Object.keys(getSettingByWeightInputDisabledData).length > 0 &&
    getSettingByWeightInputDisabledData.getSettingByName[0].intValue === 1
  ) {
    disableWeightInputInitialValue = false;
  }

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<SettingFormField>({
    resolver: yupResolver(schema),
    defaultValues: {
      weightInputDisabled: true,
      weightBridgeCharges: 0,
      millAddress: '',
      companyName: '',
    },
    mode: 'onChange',
  });

  /* useEffect used for set values of form fields */
  useEffect(() => {
    const millDetails = getBhagwatiMillIdData && getBhagwatiMillIdData.mills[0];

    setValue('weightInputDisabled', !disableWeightInputInitialValue);
    setValue(
      'weightBridgeCharges',
      getSettingByWeighbridgeChargesData?.getSettingByName[0].floatValue as number,
    );
    setValue('millAddress', (millDetails && millDetails.address) as string);
    setValue('companyName', (millDetails && millDetails.company_name) as string);
  }, [
    setValue,
    disableWeightInputInitialValue,
    getSettingByWeighbridgeChargesData,
    getBhagwatiMillIdData,
  ]);

  // /* show error message on the screen, if it has any error to fetch data from the server */
  if (
    getSettingByWeighbridgeChargesError ||
    getSettingByWeightInputDisabledError ||
    getBhagwatiMillIdError
  ) {
    let errorMessage;
    if (getSettingByWeighbridgeChargesError) {
      errorMessage = getSettingByWeighbridgeChargesError.message;
    } else if (getSettingByWeightInputDisabledError) {
      errorMessage = getSettingByWeightInputDisabledError.message;
    } else if (getBhagwatiMillIdError) {
      errorMessage = getBhagwatiMillIdError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (
    getSettingByWeighbridgeChargesLoading ||
    getSettingByWeightInputDisabledLoading ||
    getBhagwatiMillIdLoading
  ) {
    return <Spin className="loadingSpinner" />;
  }

  /* function used to handle Weighbridge Manual Entry on save button */
  const onSubmitFunctionForWeightBridgeManualEntry = handleSubmit((data: SettingFormField) => {
    setIsSaveButtonLoading('weightInputDisabled');
    /* update setting of Weighbridge Manual Entry using update setting mutation  */
    updateSetting({
      variables: {
        name: 'weightInputDisabled',
        intValue: data.weightInputDisabled ? 1 : 0,
      },
    })
      .then(() => {
        setIsSaveButtonLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(
          data.weightInputDisabled
            ? 'Weighbridge manual entry is enabled'
            : 'Weighbridge manual entry is disabled',
        );
      })
      .catch((error: ApolloError) => {
        setIsSaveButtonLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error);
        logger(error);
      });
  });

  /* function used to handle Weighbridge Charges on save button */
  const onSubmitFunctionForWeighbridgeCharges = handleSubmit((data: SettingFormField) => {
    setIsSaveButtonLoading('weighbridgeCharges');
    /* update setting of Weighbridge Charges using update setting mutation  */
    updateSetting({
      variables: {
        name: 'weighbridgeCharges',
        floatValue: data.weightBridgeCharges,
      },
    })
      .then(() => {
        setIsSaveButtonLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Weighbridge charges have been updated successfully');
      })
      .catch((error: ApolloError) => {
        setIsSaveButtonLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error);
        logger(error);
      });
  });

  /* Function used to update mill address on save button  */
  const onSubmitFunctionForUpdateMillAddress = handleSubmit((data: SettingFormField) => {
    setIsSaveButtonLoading('updateMillDetails');

    const millId =
      getBhagwatiMillIdData && getBhagwatiMillIdData.mills[0] && getBhagwatiMillIdData.mills[0].id;

    updateMillDetails({
      variables: {
        id: millId as number,
        address: data.millAddress,
        company_name: data.companyName,
      },
    })
      .then(() => {
        setIsSaveButtonLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Mill details has been updated successfully.');
      })
      .catch((error: ApolloError) => {
        setIsSaveButtonLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error);
        logger(error);
      });
  });

  return (
    <div className="tabLayout">
      <div style={{ paddingBottom: 0, marginTop: 0 }}>
        <h2 style={{ marginTop: '19px' }}>Weighbridge</h2>
      </div>
      <Divider style={{ marginTop: '5px', marginBottom: '0px' }} />
      <form>
        <FormItem label="Weighbridge Manual Entry" {...formItemStyleProps}>
          <>
            <Switch
              name="weightInputDisabled"
              rhfControllerProps={{
                control,
              }}
            />
            <span style={{ marginLeft: 30 }}>
              {watch('weightInputDisabled') ? 'Enabled' : 'Disabled'}
            </span>
          </>
        </FormItem>
        <FormItem {...formItemStyleProps}>
          <Button
            type="primary"
            htmlType="submit"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmitFunctionForWeightBridgeManualEntry}
            loading={isSaveButtonLoading === 'weightInputDisabled'}
          >
            Save
          </Button>
        </FormItem>

        <FormItem
          label="Weigh bridge Charges (in Rs.)"
          {...formItemStyleProps}
          errorText={
            errors && errors.weightBridgeCharges ? errors.weightBridgeCharges.message : undefined
          }
        >
          <InputNumber
            placeholder="Please enter weighbridge charges"
            customStyles={inputComponentCommonStyle}
            name="weightBridgeCharges"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        <FormItem {...formItemStyleProps}>
          <Button
            type="primary"
            htmlType="submit"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmitFunctionForWeighbridgeCharges}
            loading={isSaveButtonLoading === 'weighbridgeCharges'}
          >
            Save
          </Button>
        </FormItem>
        <div style={{ paddingBottom: 0, marginTop: 0 }}>
          <h2 style={{ marginTop: '19px' }}>Mill Details</h2>
        </div>
        <Divider style={{ marginTop: '5px', marginBottom: '0px' }} />

        <FormItem
          label="Mill address"
          {...formItemStyleProps}
          isRequired
          errorText={errors && errors.millAddress ? errors.millAddress.message : undefined}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter mill address"
            name="millAddress"
            rhfControllerProps={{
              control,
            }}
            isTextAreaInput={true}
            textAreaProps={{ rows: 3 }}
          />
        </FormItem>
        <FormItem
          label="Company name"
          {...formItemStyleProps}
          isRequired
          errorText={errors && errors.companyName ? errors.companyName.message : undefined}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter company name"
            name="companyName"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem {...formItemStyleProps}>
          <Button
            type="primary"
            htmlType="submit"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmitFunctionForUpdateMillAddress}
            loading={isSaveButtonLoading === 'updateMillDetails'}
          >
            Save
          </Button>
        </FormItem>
      </form>
    </div>
  );
};

export default SettingsScreen;
