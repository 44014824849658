import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import { GetGodownByIdQuery, GetGodownByIdQueryVariables } from '../../../graphql/graphql-types';
import { Spin } from 'antd';
import AddOrEditGodownForm from './AddOrEditGodownForm';

/* loading get godown by Id query */
const getGodownByIdQuery = loader('../../../graphql/queries/getGodownByIdQuery.graphql');

/* React functional component */
const EditGodownScreen = () => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* get godown by ID query */
  const {
    data: getGodownByIdData,
    loading: getGodownByIdLoading,
    error: getGodownByIdError,
  } = useQuery<GetGodownByIdQuery, GetGodownByIdQueryVariables>(getGodownByIdQuery, {
    variables: { id: Number(params.id) },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getGodownByIdLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getGodownByIdError) {
    return <div className="errorText">{getGodownByIdError.message}</div>;
  }

  return (
    <>
      {getGodownByIdData && getGodownByIdData.getGodownById ? (
        <AddOrEditGodownForm mode="edit" editGodownData={getGodownByIdData.getGodownById} />
      ) : null}
    </>
  );
};

export default EditGodownScreen;
