import React, { useState } from 'react';
import { useQuery, ApolloError, Reference, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  AllItemsQuery,
  AllItemsQueryVariables,
  Items,
  DeleteItemMutation,
  DeleteItemMutationVariables,
  ItemsEnumInfoQuery,
  ItemsEnumInfoQueryVariables,
} from '../../../graphql/graphql-types';
import { Button, message, Popconfirm, Spin, Table } from 'antd';
import { FilterFilled, PlusOutlined, SearchOutlined, WarningFilled } from '@ant-design/icons';
import colors from '../../../scss/variables.module.scss';
import { logger } from '../../../utils/helpers';
import { AddOrEditDataModalType } from '../../../utils/types';
import AddOrEditItemForm from './AddOrEditItemForm';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';

/* loading get all items query */
const getAllItemsQuery = loader('../../../graphql/queries/allItemsQuery.graphql');

/* loading get all items category query */
const getItemsEnumInfoQuery = loader('../../../graphql/queries/itemsEnumInfoQuery.graphql');

/* loading delete item mutation  */
const deleteItemMutation = loader('../../../graphql/mutations/deleteItemMutation.graphql');

/* react functional component  */
const ViewAllItemsScreen = () => {
  /* state used to store item data whose 'edit' button is clicked and to manage form modal visibility */
  const [itemDataAndIsFormVisible, setItemDataAndIsFormVisible] =
    useState<AddOrEditDataModalType>(null);

  /* state used to store id of the item whose 'delete' button is clicked which is then used to show loading indicator on delete button */
  const [itemIdToDltAndShowLoading, setItemIdToDltAndShowLoading] = useState<string | null>(null);

  /* get all items query */
  const {
    data: getAllItemsData,
    loading: getAllItemsLoading,
    error: getAllItemsError,
  } = useQuery<AllItemsQuery, AllItemsQueryVariables>(getAllItemsQuery);

  /* get all item category query */
  const {
    data: getItemEnumInfoData,
    loading: getItemEnumInfoLoading,
    error: getItemEnumInfoError,
  } = useQuery<ItemsEnumInfoQuery, ItemsEnumInfoQueryVariables>(getItemsEnumInfoQuery);

  /* delete a item mutation */
  const [deleteItem] = useMutation<DeleteItemMutation, DeleteItemMutationVariables>(
    deleteItemMutation,
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllItemsError || getItemEnumInfoError) {
    return (
      <div className="errorText">
        {getAllItemsError
          ? getAllItemsError.message
          : getItemEnumInfoError && getItemEnumInfoError.message}
      </div>
    );
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getItemEnumInfoLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* function to handle delete item */
  const deleteItemFunc = (itemId: string) => {
    setItemIdToDltAndShowLoading(itemId);
    deleteItem({
      variables: {
        id: itemId,
      },
      /* after deleting a item, the update function is used to update the cache and display an updated items array. */
      update(cache, { data: deleteItemData }) {
        /* using cache data, const to store the id of a item that was just removed. */
        const deleteItemId = deleteItemData?.delete_items_by_pk?.id;
        cache.modify({
          fields: {
            items(existingItems: Array<Reference>, { readField }) {
              if (deleteItemId) {
                return existingItems.filter((ItemRef) => deleteItemId !== readField('id', ItemRef));
              }
              return existingItems;
            },
          },
        });
      },
    })
      .then(() => {
        setItemIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Item has been deleted successfully.');
      })
      .catch((itemError: ApolloError) => {
        let errorMsg = itemError.message;
        setItemIdToDltAndShowLoading(null);
        if (
          itemError.message.includes('Foreign key violation.') &&
          itemError.message.includes('purchaseOrderItems')
        ) {
          errorMsg = 'Cannot delete since item is linked with purchase order(s).';
        } else if (itemError.message.includes('Foreign key violation.')) {
          errorMsg = 'Cannot delete since item is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(itemError);
      });
  };

  /* function used to close the visibility of modal by setting state value to null. */
  const closeAddOrEditItemForm = () => {
    setItemDataAndIsFormVisible(null);
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          /* set AddOrEditItemForm mode to 'add', to add new item and open AddOrEditItemForm. */
          setItemDataAndIsFormVisible({ mode: 'add' });
        }}
      >
        Create item
      </Button>
      <Table<Items>
        dataSource={getAllItemsData && getAllItemsData.items}
        className="tableStyle"
        bordered
        size="small"
        loading={getAllItemsLoading}
        pagination={{ showSizeChanger: true }}
        style={{ width: '80%' }}
      >
        <Table.Column<Items>
          key="name"
          title="Item name"
          dataIndex="name"
          align="center"
          onFilter={(value, record) => {
            return record.name
              ? record.name.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="item name" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<Items>
          key="category"
          title="Item Category"
          dataIndex="category"
          align="center"
          filters={
            getItemEnumInfoData &&
            getItemEnumInfoData.enum_item_category.map((item) => ({
              text: item.display_name,
              value: item.value,
            }))
          }
          onFilter={(value, record) => record.category.indexOf(value as string) === 0}
          filterIcon={(filtered) => {
            return <FilterFilled className={filtered ? 'searchIcon' : ''} />;
          }}
          render={(text, record) => {
            /* const used to store item category value  */
            const itemCategory =
              getItemEnumInfoData &&
              getItemEnumInfoData.enum_item_category.find((item) => item.value === record.category);
            return <>{itemCategory ? itemCategory.display_name : '-'}</>;
          }}
        />
        <Table.Column<Items>
          key="Actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    /* set AddOrEditItemForm mode to 'edit' and store item data which require for edit that item */
                    setItemDataAndIsFormVisible({
                      mode: 'edit',
                      dataToEdit: record,
                    });
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete Item. Are you sure?"
                  okText="Yes"
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                  onConfirm={() => {
                    deleteItemFunc(record.id);
                  }}
                >
                  <Button
                    className="deleteButton"
                    loading={record.id === itemIdToDltAndShowLoading}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
      {itemDataAndIsFormVisible && getItemEnumInfoData ? (
        <AddOrEditItemForm
          closeModal={closeAddOrEditItemForm}
          mode={itemDataAndIsFormVisible.mode}
          itemDataToEdit={itemDataAndIsFormVisible.dataToEdit as Items}
          itemCategoryOptions={getItemEnumInfoData.enum_item_category.map((item) => ({
            value: item.value,
            label: item.display_name as string,
          }))}
        />
      ) : null}
    </>
  );
};

export default ViewAllItemsScreen;
