import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import {
  GetProductBuyerByIdQuery,
  GetProductBuyerByIdQueryVariables,
} from '../../../graphql/graphql-types';
import { Spin } from 'antd';
import AddOrEditBuyerForm from './AddOrEditBuyerForm';
import { AddressType } from '../../../utils/types';

/* loading the get Product buyer by id query  with the help of loader */
const getProductBuyerByIdQuery = loader(
  '../../../graphql/queries/getAllProductBuyerByIdQuery.graphql',
);

/* React functional component */
const EditBuyerScreen = () => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* get buyer by ID query */
  const {
    data: getBuyerByIdData,
    loading: getBuyerByIdLoading,
    error: getBuyerByIdError,
  } = useQuery<GetProductBuyerByIdQuery, GetProductBuyerByIdQueryVariables>(
    getProductBuyerByIdQuery,
    {
      variables: { id: Number(params.id) },
    },
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getBuyerByIdError) {
    return <div className="errorText">{getBuyerByIdError.message}</div>;
  }

  /* if getBuyerById data loaded successfully, then destruct that data */
  if (getBuyerByIdData && getBuyerByIdData.getProductBuyerById && !getBuyerByIdLoading) {
    /* destruct buyer data */
    const { id, name, gstin, primaryContactEmail, primaryContactMobile, primaryContactName } =
      getBuyerByIdData.getProductBuyerById;

    /* getting address data from getBuyerByIdData.getProductBuyerById in order to further use it while passing the data to product buyer form */
    const addressData = getBuyerByIdData.getProductBuyerById.address as AddressType;

    return (
      <AddOrEditBuyerForm
        mode="edit"
        buyerDataToEdit={{
          id,
          name,
          gstin,
          primaryContactEmail,
          primaryContactMobile,
          primaryContactName,
          address: addressData ? addressData.address : '',
          state: addressData ? addressData.state : '',
          city: addressData ? addressData.city : '',
          pincode: addressData ? addressData.pincode : '',
        }}
      />
    );
  }
  return <Spin className="loadingSpinner" />;
};

export default EditBuyerScreen;
