import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

const MonitorShipmentTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* useLocation destructuring */
  const location = useLocation();

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('outward')) {
      return 'outward';
    }
    return 'inward';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="inward"
        onTabClick={(key: string) => {
          if (key === 'outward') {
            navigate('outward');
          } else {
            navigate('inward');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab="Inward" key="inward">
          <Outlet />
        </TabPane>
        <TabPane tab="Outward" key="outward">
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MonitorShipmentTabLayout;
