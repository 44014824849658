import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import OutwardProductInvoicingScreen from '../../outward/product/OutwardProductInvoicingScreen';

/* React functional component */
const EditCompletedOutwardShipmentScreen = () => {
  /* extracting param from useParam() */
  const param = useParams();

  /* variable id stored param id */
  const id = Number(param.id);

  return (
    <div className="tabLayout">
      <div style={{ display: 'flex' }}>
        <Link
          to="/report/shipments/outward/products"
          style={{ textAlign: 'start' }}
          className="hideInPrintBackIcon "
        >
          <ArrowLeftOutlined className="goBackButton" />
        </Link>
        <h2>Edit Completed Outward Shipment</h2>
      </div>
      <OutwardProductInvoicingScreen screenType="edit" outwardShipmentDataId={id} />
    </div>
  );
};

export default EditCompletedOutwardShipmentScreen;
