import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, message, Spin } from 'antd';
import { loader } from 'graphql.macro';
import { ApolloError, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import {
  CreateInternalTransferShipmentMutation,
  CreateInternalTransferShipmentMutationVariables,
  UpdateInternalTransferShipmentMutation,
  UpdateInternalTransferShipmentMutationVariables,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
  Enum_Item_Category_Enum,
  GetSettingByNameQuery,
  GetSettingByNameQueryVariables,
} from '../../graphql/graphql-types';
import {
  inputComponentCommonStyle,
  formItemStyleProps as AddInternalShipmentFormItemStyleProps,
} from '../../utils/globals';
import { handleGetWeightFunction, logger } from '../../utils/helpers';
import { CreateOrUpdateInternalShipmentFormType } from '../../utils/types';
import InputNumber from '../../components/InputNumber';
import FormItem from '../../components/FormItem';
import Input from '../../components/Input';

/* create or edit/update internal shipment form prop type */
type CreateOrEditInternalShipmentFormPropType = {
  /* prop type used to check whether the CreateOrEditShipment form is being called from 'edit' mode or 'add' mode */
  mode: 'add' | 'edit';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  shipmentDataToEdit?: CreateOrUpdateInternalShipmentFormType;
  /* prop type used to close CreateOrEditShipment form modal */
  closeModal?: () => void;
  /* this prop is used to know from which screen the component is called weighbridge paddy  or product */
  weighbridgeType: 'paddy' | 'product';
};

/* loading create internal shipment mutation  */
const createInternalShipmentMutation = loader(
  '../../graphql/mutations/createInternalTransferShipmentMutation.graphql',
);

/* loading update internal shipment mutation  */
const updateInternalShipmentMutation = loader(
  '../../graphql/mutations/updateInternalTransferShipmentMutation.graphql',
);

/* loading get open internal transfer shipment query */
const getInternalTransferOpenShipmentsQuery = loader(
  '../../graphql/queries/internalTransferOpenShipmentsQuery.graphql',
);

/* loading get bhagwati mill id query */
const getBhagwatiMillIdQuery = loader('../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* loading get setting by name query with the help of loader */
const getSettingByNameQuery = loader('../../graphql/queries/getSettingByNameQuery.graphql');

/* this formItem style used when CreateOrEdit shipment form is in edit mode */
const EditInternalShipmentFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 7,
  /* input column span of FormItem */
  inputColSpan: 16,
  /* formItem label style */
  labelStyle: { fontSize: 14 } as React.CSSProperties,
  /* formItem content style */
  customStyle: { paddingTop: 15, fontSize: 14 } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* validations required for useForm */
const schema = yup.object({
  vehicleNumber: yup
    .string()
    .required('Please enter vehicle number and try again')
    .matches(validationRegex.vehicleRegistrationNumber, {
      message: 'Please enter a valid vehicle number',
    }),
  personName: yup
    .string()
    .required('Please enter driver name and try and again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  firstVehicleWtKg: yup
    .number()
    .required('Please enter weight-1 (in kgs) and try again')
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
});

/* React functional component */
const CreateOrEditInternalShipmentFormComponent = ({
  mode,
  shipmentDataToEdit = undefined,
  closeModal = () => {},
  weighbridgeType,
}: CreateOrEditInternalShipmentFormPropType) => {
  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* this state used to show loading indicator on submit or update button */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* state to show loading indicator on 'get weight' button */
  const [getWeightButtonLoading, setGetWeightButtonLoading] = useState<boolean>(false);

  /* this const used to store formItem style props based on 'mode & shipmentDataToEdit' prop value */
  const formItemStyleProps =
    shipmentDataToEdit && mode === 'edit'
      ? EditInternalShipmentFormItemStyleProps
      : AddInternalShipmentFormItemStyleProps;

  /* create internal shipment mutation */
  const [createInternalShipment] = useMutation<
    CreateInternalTransferShipmentMutation,
    CreateInternalTransferShipmentMutationVariables
  >(createInternalShipmentMutation);

  /* update internal shipment mutation */
  const [updateInternalShipment] = useMutation<
    UpdateInternalTransferShipmentMutation,
    UpdateInternalTransferShipmentMutationVariables
  >(updateInternalShipmentMutation);

  /* get bhagwati mill id query used to show Mill name */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* setting by name query is used to determine whether the 'firstVehicleWtKg' field is enabled or disabled. */
  const {
    data: weightInputDisabledData,
    error: getSettingByNameError,
    loading: getSettingByNameLoader,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weightInputDisabled' },
  });

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    clearErrors,
  } = useForm<CreateOrUpdateInternalShipmentFormType>({
    defaultValues:
      mode === 'edit' && shipmentDataToEdit
        ? {
            vehicleNumber: shipmentDataToEdit.vehicleNumber,
            personName: shipmentDataToEdit.personName,
            firstVehicleImage: shipmentDataToEdit.firstVehicleImage,
            firstVehicleWtKg: shipmentDataToEdit.firstVehicleWtKg,
          }
        : {
            vehicleNumber: '',
            personName: '',
            firstVehicleImage: '',
            firstVehicleWtKg: null,
          },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getBhagwatiMillIdQueryError || getSettingByNameError) {
    return (
      <div className="errorText">
        {getBhagwatiMillIdQueryError
          ? getBhagwatiMillIdQueryError.message
          : getSettingByNameError && getSettingByNameError.message}
      </div>
    );
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getBhagwatiMillIdQueryLoader || getSettingByNameLoader) {
    <Spin className="loadingSpinner" />;
  }

  /* function to get weight of vehicle */
  const getVehicleWeight = async () => {
    try {
      setGetWeightButtonLoading(true);
      // const to store vehicle weight from weighbridge
      const vehicleWeight = await handleGetWeightFunction(apolloClient);

      // const to store vehicle image
      // const vehicleImageData = await getWeightAndCaptureCctvImg(false);

      // setting vehicle weight
      setValue('firstVehicleWtKg', vehicleWeight);

      /* if 'firstVehicleWtKg' form field has any error, then clear it. after setting it's value  */
      if (errors.firstVehicleWtKg) {
        clearErrors('firstVehicleWtKg');
      }
      /* if function return has 'first vehicle image' then set that image to 'firstVehicleImage' field */
      // if (vehicleImageData.s3Key) {
      //   setValue('firstVehicleImage', vehicleImageData.s3Key);
      // }
      setGetWeightButtonLoading(false);
    } catch (err) {
      setGetWeightButtonLoading(false);
      logger(err as ApolloError);
    }
  };

  /* function to handle form submit */
  const onSubmit = (submittedFormData: CreateOrUpdateInternalShipmentFormType) => {
    /* this const used to store mutation variables which are common in both 'create internal shipment' and 'update internal shipment' mutation */
    const commonMutationVariables = {
      personName: submittedFormData.personName as string,
      firstVehicleWtKg: submittedFormData.firstVehicleWtKg,
      vehicleNumber: submittedFormData.vehicleNumber as string,
      firstVehicleImage: submittedFormData.firstVehicleImage,
      materialType:
        weighbridgeType === 'paddy'
          ? Enum_Item_Category_Enum.RawMaterial
          : Enum_Item_Category_Enum.Product,
    };

    /* if form is in edit mode then execute update internal shipment mutation */
    if (mode === 'edit' && shipmentDataToEdit) {
      updateInternalShipment({
        variables: {
          id: shipmentDataToEdit.id,
          ...commonMutationVariables,
          firstVehicleWtTakenAt:
            shipmentDataToEdit.firstVehicleWtKg === submittedFormData.firstVehicleWtKg
              ? (shipmentDataToEdit.firstVehicleWtTakenAt as Date)
              : new Date(),
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been successfully updated.');
          reset();
          /* close modal */
          closeModal();
          setIsSubmitOrUpdateBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          setIsSubmitOrUpdateBtnLoading(false);
          logger(error);
        });
    } else {
      createInternalShipment({
        variables: {
          ...commonMutationVariables,
          firstVehicleWtTakenAt: new Date(),
        },
        /* after creating a new shipment, refetch open shipment query to get updated shipment list */
        refetchQueries: [
          {
            query: getInternalTransferOpenShipmentsQuery,
            variables: {
              materialType:
                weighbridgeType === 'paddy'
                  ? Enum_Item_Category_Enum.RawMaterial
                  : Enum_Item_Category_Enum.Product,
            },
          },
        ],
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been successfully created.');
          reset();
          setIsSubmitOrUpdateBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          setIsSubmitOrUpdateBtnLoading(false);
          logger(error);
        });
    }
  };

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormItem
        label="Vehicle Number"
        isRequired
        requiredMark="after"
        errorText={errors.vehicleNumber ? errors.vehicleNumber.message : undefined}
        {...formItemStyleProps}
      >
        <Input
          customStyles={inputComponentCommonStyle}
          name="vehicleNumber"
          placeholder="Please enter vehicle registration number"
          rhfControllerProps={{
            control,
          }}
          onChange={(rhfOnChange, value) => {
            rhfOnChange(value.toUpperCase());
          }}
        />
      </FormItem>
      <FormItem
        label="Driver Name"
        isRequired
        requiredMark="after"
        errorText={errors.personName ? errors.personName.message : undefined}
        {...formItemStyleProps}
      >
        <Input
          customStyles={inputComponentCommonStyle}
          name="personName"
          placeholder="Please enter driver name"
          rhfControllerProps={{
            control,
          }}
        />
      </FormItem>
      <FormItem label="Mill Name" {...formItemStyleProps}>
        <>
          {getBhagwatiMillIdData &&
            getBhagwatiMillIdData.mills[0] &&
            getBhagwatiMillIdData.mills[0].name}
        </>
      </FormItem>
      <FormItem
        label="Weight-1 (in kgs)"
        isRequired
        requiredMark="after"
        errorText={errors && errors.firstVehicleWtKg ? errors.firstVehicleWtKg.message : undefined}
        {...formItemStyleProps}
      >
        <div style={{ display: 'flex' }}>
          <InputNumber
            customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
            placeholder="Please enter weight-1 (in kgs)"
            name="firstVehicleWtKg"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{
              min: 0,
              precision: 2,
              disabled:
                weightInputDisabledData && !weightInputDisabledData.getSettingByName[0].intValue
                  ? true
                  : false,
            }}
          />
          <Button
            type="primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              getVehicleWeight().catch((err: ApolloError) => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                message.error(err.message);
              });
            }}
            loading={getWeightButtonLoading}
          >
            Get Weight
          </Button>
        </div>
      </FormItem>
      <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
        <div
          style={
            mode === 'edit' ? { display: 'flex', justifyContent: 'end' } : { display: 'block' }
          }
        >
          <Button
            htmlType="submit"
            type="primary"
            loading={isSubmitOrUpdateBtnLoading}
            style={{ marginRight: 10 }}
          >
            {mode === 'edit' ? 'Update' : 'Create Shipment'}
          </Button>
          {mode === 'edit' ? (
            <Button
              type="default"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
          ) : null}
        </div>
      </FormItem>
    </form>
  );
};

export default CreateOrEditInternalShipmentFormComponent;
