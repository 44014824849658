import React, { useState } from 'react';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  GetAllOutwardShipmentsForOtherItemsQueryQuery,
  GetAllOutwardShipmentsForOtherItemsQueryQueryVariables,
} from '../../../graphql/graphql-types';
import { loader } from 'graphql.macro';
import DailyReportOutwardBagOrOtherItemTable from '../dailyReports/outward/DailyReportOutwardBagOrOtherItemTable';
import FinancialYearComponent from '../../../components/FinancialYearComponent';

/* extending isBetween plugin from day js library */
dayjs.extend(isBetween);

/* loading get all outward shipment for OtherItems Query */
const getAllOutwardShipmentsForOtherItemsQuery = loader(
  '../../../graphql/queries/getAllOutwardShipmentsForOtherItemsQuery.graphql',
);

/* React functional component */
const OutwardShipmentOtherItemsScreen = () => {
  /* this state used to store start and end date, which is selected using date range picker or financial year radio option */
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  /* get All outward Shipment For OtherItems data using query with start and end date from date range field */
  const {
    data: getAllOutwardShipmentsForOtherItemsData,
    loading: getAllOutwardShipmentsForOtherItemsLoading,
    error: getAllOutwardShipmentsForOtherItemsError,
  } = useQuery<
    GetAllOutwardShipmentsForOtherItemsQueryQuery,
    GetAllOutwardShipmentsForOtherItemsQueryQueryVariables
  >(getAllOutwardShipmentsForOtherItemsQuery, {
    variables: {
      createdAt:
        selectedDate && selectedDate.startDate && selectedDate.endDate
          ? {
              _gte: selectedDate.startDate,
              _lte: selectedDate.endDate,
            }
          : {},
    },
  });

  /* outward other Items table component */
  const OutwardOtherItemsTableComponent = () => {
    /* show loading indicator on the screen until data get fetch from the server */
    if (getAllOutwardShipmentsForOtherItemsLoading) {
      return <Spin className="loadingSpinner" />;
    }

    /* show error message on the screen, if it has any error to fetch data from the server */
    if (getAllOutwardShipmentsForOtherItemsError) {
      return <div className="errorText">{getAllOutwardShipmentsForOtherItemsError.message}</div>;
    }

    if (getAllOutwardShipmentsForOtherItemsData) {
      return (
        <DailyReportOutwardBagOrOtherItemTable
          data={getAllOutwardShipmentsForOtherItemsData.outwardShipments}
          outwardShipmentType="otherItems"
          reportScreenType="outward"
        />
      );
    }
    return <div />;
  };

  return (
    <>
      <FinancialYearComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      <OutwardOtherItemsTableComponent />
    </>
  );
};

export default OutwardShipmentOtherItemsScreen;
