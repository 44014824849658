import React from 'react';
import { Col, Descriptions, Row, Space, Table } from 'antd';
import dayjs from 'dayjs';
import { InwardShipmentItemsType, InwardShipmentsReportType } from '../../utils/types';
import { ProcessGRNFormFieldType } from './ProcessGRNScreen';
import { convertKgsToQuintals } from '../../utils/helpers';
import { Mills } from '../../graphql/graphql-types';
import logo from '../../assets/images/sarda_group_logo.png';
import SignatureComponent from '../../components/SignatureComponent';

/* purchase bill preview model prop type  */
type PurchaseBillPreviewModalPropType = {
  /* prop used to get preview data for print data*/
  previewData: InwardShipmentsReportType | undefined;
  /*  prop used to get form data for print details*/
  formData: ProcessGRNFormFieldType;
  // prop used to get mill details
  millDetails: Pick<Mills, 'address' | 'company_name' | 'id' | 'name'>;
  /* prop to pass reference for print */
  printReference?: React.LegacyRef<HTMLDivElement> | undefined;
};

/* React functional component */
const PurchaseBillPreviewModal = ({
  previewData,
  formData,
  millDetails,
  printReference,
}: PurchaseBillPreviewModalPropType) => {
  /* extracting data from preview data */
  const { grn, createdAt, driverName, vehicleNumber, fullVehicleWtKg, emptyVehicleWtKg } =
    previewData as InwardShipmentsReportType;

  /* extracting form data from FormData  */
  const { weighbridgeCharges, laborChargePerBag, laborChargeType, inwardShipmentItems } = formData;

  /* if  data present in inwardShipmentItems  then stored in inwardShipmentItemsData otherwise stored [] */
  const inwardShipmentItemsData = inwardShipmentItems
    ? inwardShipmentItems.filter((data) => data !== null)
    : [];

  /* stored tableData */
  const tableData = inwardShipmentItems.filter((item) => !item.materialReturned);

  // variable used to store total number of bags of one shipment
  const totalNumberOfBags = tableData.reduce((acc, data) => {
    return acc + (data.bagsCount || 0);
  }, 0);

  /* variable for total amount */
  let totalAmount = 0;

  /* variable for gross amount */
  let grossAmount = 0;

  /* variable for labour charges */
  let labourCharges = 0;

  /* stored seller name array */
  const sellerName: string[] = [];

  /* if weighbridgeCharges is not undefined and null then subtract weighbridgeCharges from totalAmount  */
  if (weighbridgeCharges) {
    totalAmount -= weighbridgeCharges;
  }
  inwardShipmentItemsData.forEach((item) => {
    /* variable for price of total weight */
    let priceOfTotalWt = 0;
    /* variable of  price of brokerage*/
    let priceOfBrokerage = 0;

    /* if repeated seller name present in item sellerName then push that name only onces in sellerName array  */
    if (!sellerName.includes(item.sellerName)) sellerName.push(item.sellerName);

    if (
      laborChargeType &&
      laborChargePerBag !== null &&
      laborChargePerBag !== undefined &&
      !item.materialReturned
    ) {
      /* calculated total net material weight */
      const totalNetMaterialWeight = item.bagsCount * item.netMaterialWtPerBagKg;

      /* calculated total net material weight in quintal */
      const totalNetMaterialWeightInQt = parseFloat(
        convertKgsToQuintals(totalNetMaterialWeight).toFixed(2),
      );

      if (item.pricePerQuintal) {
        /* calculated price of total weight */
        priceOfTotalWt = totalNetMaterialWeightInQt * item.pricePerQuintal;
      }

      /* calculated price of total empty bag weight */
      const priceOfTotalEmptyBagWt = (item.emptyBagCost ? item.emptyBagCost : 0) * item.bagsCount;
      if (item.brokeragePricePerQuintal) {
        /* calculated price of brokerage */
        priceOfBrokerage = item.brokeragePricePerQuintal * totalNetMaterialWeightInQt;
      }

      /* calculated subtotal */
      const subtotal = parseFloat(
        (priceOfTotalWt + priceOfBrokerage - priceOfTotalEmptyBagWt).toFixed(2),
      );

      /* calculated totalAmount and grossAmount */
      totalAmount += subtotal;
      grossAmount += subtotal;
    }

    if (
      laborChargeType &&
      laborChargePerBag !== null &&
      laborChargePerBag !== undefined &&
      !item.materialReturned
    ) {
      const laborChargePerBagValue = laborChargePerBag ? laborChargePerBag : 0;

      /* if laborChargeType is add */
      if (laborChargeType === 'add') {
        totalAmount += item.bagsCount * laborChargePerBagValue;
        labourCharges += item.bagsCount * laborChargePerBagValue;
      } else {
        totalAmount -= item.bagsCount * laborChargePerBagValue;
        labourCharges -= item.bagsCount * laborChargePerBagValue;
      }
    }
  });

  const purchaseBillPrintLayout = (
    <div className="printModal">
      <Row style={{ textAlign: 'center', alignItems: 'center' }}>
        <Col span={6}>
          <img src={logo} alt="logo" width={80} height={72} />
        </Col>
        <Col span={13}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: 10 }}>|| Shri Ganeshay Namah ||</span>
            <b style={{ fontSize: 17 }} className="millNameStyle">
              {millDetails && millDetails.company_name
                ? millDetails.company_name.toUpperCase()
                : ''}
            </b>
            <span>{(millDetails && millDetails.address) || ''}</span>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <span>
                <b>GSTIN : </b> 27AAFFB7204E1ZU
              </span>
              <span>
                <b>FSSAI :</b>
                {millDetails &&
                millDetails.name &&
                millDetails.name.toLowerCase().includes('bhagwati')
                  ? 11517059000099
                  : 11521999000463}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Col
        span={24}
        style={{ fontWeight: 'bold', fontSize: 15, marginTop: 10, textAlign: 'center' }}
        className="printBillInUIHeader"
      >
        Purchase Bill
      </Col>
      <Col
        span={24}
        style={{
          fontWeight: 'bold',
          fontSize: 15,
          marginTop: 10,
          textAlign: 'center',
          display: 'none',
        }}
        className="printOfficeCopyHeader"
      >
        Purchase Bill (Office Copy)
      </Col>
      <Col
        span={24}
        style={{
          fontWeight: 'bold',
          fontSize: 15,
          marginTop: 10,
          textAlign: 'center',
          display: 'none',
        }}
        className="printSellerCopyHeader"
      >
        Purchase Bill (Seller Copy)
      </Col>
      <Row>
        <Col span={16}>
          <Descriptions column={1} className="printDescriptions">
            <Descriptions.Item label="Bill No.">{grn}</Descriptions.Item>
            <Descriptions.Item label="Billing Date">
              {dayjs().format('DD-MM-YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label="Seller Name">
              {sellerName.length === 0 ? '-' : sellerName.map((name) => `${name}`)}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Descriptions column={1} className="printDescriptions">
            <Descriptions.Item label="Invoice Date">
              {createdAt ? dayjs(createdAt as Date).format('DD-MM-YYYY') : null}
            </Descriptions.Item>
            <Descriptions.Item label="Driver Name">{driverName}</Descriptions.Item>
            <Descriptions.Item label="Vehicle No">{vehicleNumber || '-'}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Col span={24}>
        <Table<InwardShipmentItemsType>
          dataSource={tableData}
          pagination={false}
          bordered
          size="small"
          rowKey="id"
          className="tableStyle historyTable"
          style={{ marginTop: 40 }}
        >
          <Table.Column<InwardShipmentItemsType>
            title={
              <>
                Paddy type
                <span className="tableColumnNameStyle">(Grade)</span>
                <br />
                No. Of Bags
              </>
            }
            align="center"
            dataIndex="paddyTypesAndNoOfBags"
            key="paddyTypesAndNoOfBags"
            render={(text, record) => {
              return (
                <>
                  {record.rawMaterialName} (
                  {record.paddyGrade === 'rice'
                    ? 'rice'
                    : (record.paddyGrade as string).replace(/[^\d]/g, '')}
                  ) - {record.materialReturned !== true ? record.bagsCount : null}
                </>
              );
            }}
          />
          <Table.Column<InwardShipmentItemsType>
            title={
              <>
                Empty Bags Returned <br />
                Empty Bag Weight (kgs.)
              </>
            }
            align="center"
            dataIndex="emptyBagsReturnedAndWeight"
            key="emptyBagsReturnedAndWeight"
            render={(text, record) => {
              return (
                <>
                  {record.emptyBagsReturned ? 'Yes' : 'No'}
                  <br />
                  {record.emptyBagWtKg}
                  {record.bagWeightType === 'kgPerBag' ? ' per bag' : ' per quintal'}
                </>
              );
            }}
          />
          <Table.Column<InwardShipmentItemsType>
            title={
              <>
                Net Material Weight
                <div className="tableColumnNameStyle">(kgs per bag)</div>
              </>
            }
            align="center"
            dataIndex="netMaterialWeight"
            key="netMaterialWeight"
            render={(text, record) => {
              if (!record.materialReturned) {
                return (
                  <>{record.netMaterialWtPerBagKg && record.netMaterialWtPerBagKg.toFixed(2)}</>
                );
              }
              return '-';
            }}
          />
          <Table.Column<InwardShipmentItemsType>
            title={
              <>
                Total Net Material Weight
                <br />
                <span className="tableColumnNameStyle">(quintals)</span>
              </>
            }
            align="center"
            dataIndex="totalNetMaterialWeight"
            key="totalNetMaterialWeight"
            render={(text, record) => {
              const totalMaterialWeight = (record.bagsCount * record.netMaterialWtPerBagKg) / 100;

              if (!record.materialReturned) {
                return <>{totalMaterialWeight && totalMaterialWeight.toFixed(2)}</>;
              }
              return '-';
            }}
          />
          <Table.Column<InwardShipmentItemsType>
            title={
              <>
                Price Of Paddy
                <div className="tableColumnNameStyle">(Rs. per quintal)</div>
              </>
            }
            align="center"
            dataIndex="priceOfPaddy"
            key="priceOfPaddy"
            render={(text, record) => {
              if (!record.materialReturned) {
                return <> {record.pricePerQuintal ? record.pricePerQuintal.toFixed(2) : 0}</>;
              }
              return '-';
            }}
          />
          <Table.Column<InwardShipmentItemsType>
            title={
              <>
                Brokerage
                <div className="tableColumnNameStyle">(Rs. per quintal)</div>
              </>
            }
            align="center"
            dataIndex="brokerage"
            key="brokerage"
            render={(text, record) => {
              if (!record.materialReturned) {
                return (
                  <>
                    {record.brokeragePricePerQuintal
                      ? record.brokeragePricePerQuintal.toFixed(2)
                      : 0}
                  </>
                );
              }
              return '-';
            }}
          />
          <Table.Column<InwardShipmentItemsType>
            title={
              <>
                Sub-Total
                <div className="tableColumnNameStyle">(Rs.)</div>
              </>
            }
            align="center"
            dataIndex="subTotal"
            key="subTotal"
            render={(text, record) => {
              if (!record.materialReturned) {
                return <>{record.subTotal ? record.subTotal.toFixed(2) : '0'}</>;
              }
              return '-';
            }}
          />
        </Table>
      </Col>
      <Row style={{ textAlign: 'center', alignItems: 'center' }}>
        <Col span={16} className="printDescriptions">
          <Descriptions column={1} size="small">
            <Descriptions.Item label="Total No. of bags">
              {totalNumberOfBags || 0}
            </Descriptions.Item>
            <Descriptions.Item label="Gross Wt. (kgs)">
              {fullVehicleWtKg && fullVehicleWtKg.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Tare Wt. (kgs)">
              {(emptyVehicleWtKg && emptyVehicleWtKg.toFixed(2)) || '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Net Wt. (kgs)">
              {(fullVehicleWtKg &&
                emptyVehicleWtKg &&
                (fullVehicleWtKg - emptyVehicleWtKg).toFixed(2)) ||
                '-'}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={8}>
          <Descriptions column={1} size="small" className="printDescriptions">
            <Descriptions.Item label="Gross Amount (in Rs.)">
              {grossAmount === 0 ? grossAmount : grossAmount.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Weighbridge Charges (in Rs.)">
              -{weighbridgeCharges && weighbridgeCharges.toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Labor charge type">
              {laborChargeType === 'add' ? 'Add' : 'Deduct'}
            </Descriptions.Item>
            <Descriptions.Item label="Labor charges (in Rs.)">
              {laborChargeType === 'add'
                ? `+${labourCharges && labourCharges.toFixed(2)}`
                : `${labourCharges && labourCharges.toFixed(2)}`}
            </Descriptions.Item>
            <Descriptions.Item label="Net Total Amount (in Rs.)" contentStyle={{ fontWeight: 500 }}>
              {totalAmount === 0 ? totalAmount : totalAmount.toFixed(2)}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <SignatureComponent />
    </div>
  );

  return (
    <Space direction="vertical" size={70} className="inwardShipmentPrint">
      <div className="printRefDiv" ref={printReference}>
        {purchaseBillPrintLayout}
        <div className="printDuplicateBill" style={{ display: 'none' }}>
          {purchaseBillPrintLayout}
        </div>
      </div>
    </Space>
  );
};

export default PurchaseBillPreviewModal;
