import React, { useState } from 'react';
import {
  Button,
  message,
  Descriptions,
  Divider,
  Table,
  Popover,
  Modal,
  Popconfirm,
  Spin,
} from 'antd';
import { InfoCircleFilled, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { useQuery, useLazyQuery, ApolloError, useApolloClient, gql } from '@apollo/client';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import {
  GetOutwardShipmentByIdQuery,
  GetOutwardShipmentByIdQueryVariables,
  GetOutwardShipmentsForInvoicingQuery,
  GetOutwardShipmentsForInvoicingQueryVariables,
  GetAllProductBuyersQuery,
  GetAllProductBuyersQueryVariables,
} from '../../../graphql/graphql-types';
import { loader } from 'graphql.macro';
import { dateFormatFunc, inputComponentCommonStyle } from '../../../utils/globals';
import { convertKgsToQuintals, logger } from '../../../utils/helpers';
import { OutwardShipmentByIdDataType } from '../../../utils/types';
import colors from '../../../scss/variables.module.scss';
import RequiredMessage from '../../../components/RequiredMessage';
import FormItem from '../../../components/FormItem';
import Select from '../../../components/Select';
import { useOutwardShipmentOutlet } from './OutwardProductTabLayout';
import AddOrEditBuyerForm from '../../management/buyers/AddOrEditBuyerForm';
import Input from '../../../components/Input';

/* order array type */
type OrderArrayType = {
  /* order number  */
  orderNumber: string | null | undefined;
  /* order Id */
  orderId: number;
  /* broker name */
  broker: string | null;
  /* product type */
  productType: string | null;
  /* product */
  product: string | null;
  /* bag size in  kg */
  bagSizeKg: number | null;
  /* invoice number */
  invoiceNumber: string | null;
  /* buyer Id */
  buyerId: number | null;
  /* loaded bag count */
  loadedBagsCount: number | null;
};

/* outward shipment invoice form type */
type OutwardShipmentInvoicingFormType = {
  /* prop type used to store 'id' of the shipment whose vehicle number get selected from 'select vehicle' dropdown */
  shipmentIdFromVehicleNumber: number | null;
  /* prop type used to store order details in the form of order fields array */
  orderArray: Array<OrderArrayType>;
};

/* Outward product invoicing screen prop type */
type OutwardProductInvoicingScreenPropType = {
  /*  prop type used to determine whether screen is invoice or edit  */
  screenType?: 'invoice' | 'edit';
  /* prop type used to stored outward shipment data id from params. This is optional prop and will be pass when component called from edit screen */
  outwardShipmentDataId?: number;
};

/* loading get outward shipment for invoicing query with the help of loader */
const getOutwardShipmentsForInvoicingQuery = loader(
  '../../../graphql/queries/getOutwardShipmentsForInvoicingQuery.graphql',
);

/* loading get outward shipment by id query with the help of loader */
const getOutwardShipmentByIdQuery = loader(
  '../../../graphql/queries/getOutwardShipmentByIdQuery.graphql',
);

/* loading get all product buyers query with the help of loader */
const getAllProductBuyersQuery = loader(
  '../../../graphql/queries/getAllProductBuyersQuery.graphql',
);

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 5,
  /* input column span of FormItem */
  inputColSpan: 7,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* useForm validation schema  */
const schema = yup.object().shape({
  orderArray: yup.array().of(
    yup.object().shape({
      invoiceNumber: yup
        .string()
        .nullable()
        .matches(/^[A-Za-z0-9]+$/, {
          message: 'Please enter a valid invoice number',
          excludeEmptyString: true,
        })
        .default(null)
        .required('Please add invoice no.'),
      buyerId: yup.number().nullable().default(null).required('Please select buyer'),
    }),
  ),
});

/* react functional component */
const OutwardProductInvoicingScreen = ({
  screenType,
  outwardShipmentDataId,
}: OutwardProductInvoicingScreenPropType): JSX.Element => {
  /* extracting 'outwardShipmentId & setOutwardShipmentId' from outward shipment outlet */
  const { outwardShipmentId, setOutwardShipmentId } = useOutwardShipmentOutlet() || {};

  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* state used to show loading indicator on 'finish invoicing' button */
  const [finishInvoicingBtnLoading, setFinishInvoicingBtnLoading] = useState<boolean>(false);

  /* state used to show loading indicator on 'save' button */
  const [saveBtnLoading, setSaveBtnLoading] = useState<boolean>(false);

  /* this state used to manage 'AddBuyerForm' modal visibility */
  const [isAddBuyerFormModalVisible, setIsAddBuyerFormModalVisible] = useState<boolean>(false);

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
    getValues,
  } = useForm<OutwardShipmentInvoicingFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      shipmentIdFromVehicleNumber: null,
      orderArray: [],
    },
    mode: 'onChange',
  });

  /* use field array of order array */
  const { fields } = useFieldArray({
    name: 'orderArray',
    control,
  });

  /* const used to store 'invoice' form field errors, so that we can use it further */
  const orderTableFieldsErrors = errors.orderArray;

  /* get outward shipment by Id query used to display shipment & shipment order information */
  const [
    getOutwardShipmentsById,
    {
      data: getOutwardShipmentsByIdData,
      loading: getOutwardShipmentsByIdDataLoading,
      error: getOutwardShipmentsByIdDataError,
    },
  ] = useLazyQuery<GetOutwardShipmentByIdQuery, GetOutwardShipmentByIdQueryVariables>(
    getOutwardShipmentByIdQuery,
    {
      fetchPolicy: 'network-only',
      /* onCompleted of query*/
      onCompleted: (res) => {
        const outwardShipmentsData =
          res.getOutwardShipmentById &&
          Array.isArray(res.getOutwardShipmentById.orders) &&
          res.getOutwardShipmentById.orders.length > 0
            ? res.getOutwardShipmentById.orders.map((item) => {
                return {
                  orderNumber: item.number ? item.number : undefined,
                  orderId: item.id,
                  broker: item.broker && item.broker.name ? item.broker.name : null,
                  productType: item.product ? item.product.productType.name : null,
                  product: item.product ? item.product.brand : null,
                  bagSizeKg: item.bagSizeKg ? item.bagSizeKg : null,
                  invoiceNumber: item.invoiceNo ? item.invoiceNo : null,
                  buyerId: item.buyer ? item.buyer.id : null,
                  loadedBagsCount: item.loadedBagsCount ? item.loadedBagsCount : null,
                };
              })
            : [];

        /* if outward shipment data present replace with use field array as we are showing table data using useFieldArray's Field property
        so it is imp to reset/ set data  */
        if (outwardShipmentsData) {
          reset({ orderArray: outwardShipmentsData });
        }
      },
    },
  );

  /* get outward shipment for invoicing query used to display vehicle number, as a dropdown list on 'shipmentIdFromVehicleNumber' form field */
  const {
    data: getOutwardShipmentsInvoicingData,
    loading: getOutwardShipmentsInvoicingDataLoading,
    error: getOutwardShipmentsInvoicingDataError,
  } = useQuery<GetOutwardShipmentsForInvoicingQuery, GetOutwardShipmentsForInvoicingQueryVariables>(
    getOutwardShipmentsForInvoicingQuery,
    {
      onCompleted: () => {
        //  below code will execute when user open invoice screen as edit mode or directly from truck outward ie with preselected vehicle
        let outwardShipmentArgumentId;
        if (outwardShipmentId) {
          outwardShipmentArgumentId = outwardShipmentId;
        }
        if (outwardShipmentDataId) {
          outwardShipmentArgumentId = outwardShipmentDataId;
        }
        if (outwardShipmentArgumentId) {
          /* call 'getOutwardShipmentsById' function to get shipment & shipment order loading information */
          void getOutwardShipmentsById({
            variables: { outwardShipmentId: outwardShipmentArgumentId },
          });
          /* initialize 'shipmentIdFromVehicleNumber' field value to selected outward shipment id  */
          setValue('shipmentIdFromVehicleNumber', outwardShipmentArgumentId);
        }
      },
    },
  );

  /* get product's buyer query used to display list of buyers as a dropdown options on 'select buyerId' form field */
  const {
    data: getProductBuyersData,
    loading: getProductBuyersLoading,
    error: getProductBuyersError,
  } = useQuery<GetAllProductBuyersQuery, GetAllProductBuyersQueryVariables>(
    getAllProductBuyersQuery,
  );

  /* const used to store outward shipment by id data, so that we can use it further */
  const outwardShipmentsByIdData =
    getOutwardShipmentsByIdData?.getOutwardShipmentById as OutwardShipmentByIdDataType;

  /* show error text on the screen. if it has any error while loading data from the server */
  if (
    getOutwardShipmentsInvoicingDataError ||
    getOutwardShipmentsByIdDataError ||
    getProductBuyersError
  ) {
    let errorMessage;
    if (getOutwardShipmentsInvoicingDataError) {
      errorMessage = getOutwardShipmentsInvoicingDataError.message;
    } else if (getOutwardShipmentsByIdDataError) {
      errorMessage = getOutwardShipmentsByIdDataError.message;
    } else if (getProductBuyersError) {
      errorMessage = getProductBuyersError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* this variable used to store transporter vehicle's capacity */
  let transporterVehicleCapacityInQuintal = 0;

  /* this variable used to store net material weight in kg, which is calculated using 'full & empty vehicle weight' */
  let calculateNetMaterialWeightInKg = 0;

  /* this variable used to calculate loaded net material weight in kg,
  which is calculated using total number of bags loaded in orders & their bag size */
  let calculateLoadedNetWeightWeightInKg = 0;

  /* this variable used to calculate difference between 'loaded net material weight & material net weight' */
  let calculateNetDifference = 0;

  /* calculate 'net material weight','loaded net material weight','difference in net material weights' & transporter vehicle capacity */
  if (outwardShipmentsByIdData) {
    /* destructing  outward order shipment by id data */
    const { transporterVehicle, orders, emptyVehicleWtKg, fullVehicleWtKg } =
      outwardShipmentsByIdData;

    /* calculate transporter vehicle capacity in quintal */
    transporterVehicleCapacityInQuintal =
      transporterVehicle && transporterVehicle.capacityKgs
        ? convertKgsToQuintals(transporterVehicle.capacityKgs)
        : 0;

    /* const used to store full vehicle weight in kg, which is used to calculate 'net material weight' */
    const fullVehicleWeightInKg = fullVehicleWtKg ? fullVehicleWtKg : 0;

    /* const used to store empty vehicle weight in kg, which is used to calculate 'net material weight' */
    const emptyVehicleWeightInKg = emptyVehicleWtKg ? emptyVehicleWtKg : 0;

    /* calculate net material weight  */
    calculateNetMaterialWeightInKg = fullVehicleWeightInKg - emptyVehicleWeightInKg;

    /* calculate 'loaded material's net weight' using 'number of bags loaded and bag weight' in each order */
    if (orders.length > 0) {
      orders.forEach((order) => {
        /* calculate loaded material's net weight using each order's 'bagCount' & 'bagSize' */
        if (order.loadedBagsCount && order.bagSizeKg) {
          /* calculate loaded material's net weight */
          calculateLoadedNetWeightWeightInKg += order.loadedBagsCount * order.bagSizeKg;
        }
      });
    }

    /* calculate net difference in 'loaded material net weight' & 'material net weight' */
    calculateNetDifference = calculateNetMaterialWeightInKg - calculateLoadedNetWeightWeightInKg;
  }

  // function runs logic to update outwardShipmentOrders buyer and invoice no data.
  // argument is use to decide which btn is clicked as we have two btn save and finish Shipment btn. when user click save btn we dont want validation but on click of finish shipment btn we want validation
  const saveInvoiceFormDataFunc = (isSaveBtnClick: boolean) => {
    // const to get all form fields values using useForm getValues function
    const formData = getValues();

    /* destructing form data */
    const { shipmentIdFromVehicleNumber, orderArray } = formData;

    /* const id stored outward shipment id  */
    const id = outwardShipmentsByIdData ? outwardShipmentsByIdData.id : null;

    /* const shipment stored if screenType is invoice  then stored shipmentIdFromVehicleNumber otherwise stored outward shipment id */
    const shipmentId = screenType === 'invoice' ? shipmentIdFromVehicleNumber : id;

    /* if user entered all form field values, then execute finish invoicing mutation  */
    if (shipmentId && orderArray.length > 0) {
      /* this variable used to store final mutation string */
      let finalMutationString = '';
      let finalOutwardShipmentOrderMutationString = '';

      /* execute 'update outward shipment order' mutation for each shipment order with 'newly added buyer' & 'invoice number' */
      orderArray.forEach((invoice) => {
        const invoiceNo = invoice.invoiceNumber ? `"${invoice.invoiceNumber}"` : null;
        const finishOutwardShipmentOrderInvoicingMutationString = `update_outwardShipmentOrders${
          invoice.orderId
        }: update_outwardShipmentOrders_by_pk(pk_columns: {id: ${
          invoice.orderId
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        }}, _set: {invoiceNo: ${invoiceNo},productBuyerId:${invoice.buyerId as number}}) {
  id
  invoiceNo
}`;
        /* append, each 'update outward shipment order' mutation string to 'final mutation string' */
        finalOutwardShipmentOrderMutationString +=
          finishOutwardShipmentOrderInvoicingMutationString;
      });

      // const use to store current date so that we can pass current date to invoiceAddedAt field
      const currentDate = dayjs();

      if (!isSaveBtnClick) {
        /* to add the date of invoice creation, execute 'update outward shipment' mutation for the shipment (which is selected to complete invoice) */
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const updateOutwardShipmentInvoiceDateMutationString = `update_outwardShipments_by_pk(pk_columns: {id: ${shipmentId}}, _set: {invoiceAddedAt: "${currentDate}"}) {
          id
          grn
          createdAt
          transporter {
            id
            name
          }
          transporterVehicle {
            id
            number
            capacityKgs
            rcS3Key
          }
          drivers {
            driver {
              id
              mobile
              name
              panFile
              photo
            }
          }
          orders {
            id
            bagSizeKg
            plannedBagsCount
            loadedBagsCount
            invoiceNo
            product {
              id
              brand
              productType {
                id
                name
              }
            }
            buyer {
              id
              name
            }
          }
          fullVehicleWtKg
          emptyVehicleWtKg
          status
        }`;
        finalMutationString = `mutation{
          ${finalOutwardShipmentOrderMutationString}
        ${updateOutwardShipmentInvoiceDateMutationString}
      }`;
      } else {
        finalMutationString = `mutation{
          ${finalOutwardShipmentOrderMutationString}
        }`;
      }

      apolloClient
        .mutate({
          mutation: gql`
            ${finalMutationString}
          `,
          refetchQueries: [
            {
              query:
                screenType === 'invoice'
                  ? getOutwardShipmentsForInvoicingQuery
                  : getOutwardShipmentByIdQuery,
            },
          ],
        })
        .then(() => {
          // const use to store success message
          let successMsg;
          setFinishInvoicingBtnLoading(false);
          setSaveBtnLoading(false);
          if (screenType === 'edit') {
            successMsg = 'Shipment has been successfully updated.';
          } else if (!isSaveBtnClick) {
            successMsg = 'Invoicing is successfully completed.';
          } else {
            successMsg = 'Buyer and Invoice information has been successfully saved';
          }
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success(successMsg);

          /* if screenType edit navigate to these screen */
          if (screenType === 'edit') {
            navigate('/report/shipments/outward/products');
          }

          //reset
          reset({
            shipmentIdFromVehicleNumber: null,
            orderArray: [],
          });

          /* after finishing the shipment invoicing successfully, set OutwardShipmentId to 'null' */
          if (screenType === 'invoice') {
            setOutwardShipmentId(null);
          }
        })
        .catch((error: ApolloError) => {
          setFinishInvoicingBtnLoading(false);
          setSaveBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  };

  /* function used to handle form submit */
  const onSubmit = () => {
    setFinishInvoicingBtnLoading(true);
    saveInvoiceFormDataFunc(false);
  };

  /*  const stored id depend on screen type if screen type is invoice is stored shipment id from vehicle number  otherwise outward shipment id */
  const shipmentIdOrOutwardId =
    screenType === 'invoice' ? watch('shipmentIdFromVehicleNumber') : outwardShipmentDataId;

  return (
    <>
      {screenType === 'edit' ? null : (
        <>
          <RequiredMessage />
          <FormItem
            label="Shipment vehicle "
            isRequired
            errorText={
              errors && errors.shipmentIdFromVehicleNumber
                ? errors.shipmentIdFromVehicleNumber.message
                : undefined
            }
            {...formItemStyleProps}
          >
            <Select
              customStyles={{ ...inputComponentCommonStyle }}
              placeholder="Please select vehicle number"
              name="shipmentIdFromVehicleNumber"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                optionFilterProp: 'children',
                loading: getOutwardShipmentsInvoicingDataLoading,
              }}
              options={
                getOutwardShipmentsInvoicingData &&
                getOutwardShipmentsInvoicingData.getOutwardShipmentsForInvoicing
                  ? getOutwardShipmentsInvoicingData.getOutwardShipmentsForInvoicing.map(
                      (shipment) => {
                        return {
                          value: shipment.id,
                          label:
                            (shipment.transporterVehicle && shipment.transporterVehicle.number) ||
                            '-',
                        };
                      },
                    )
                  : []
              }
              onChange={(rhfOnChange, value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                rhfOnChange(value);
                /* clear form errors on selection of 'vehicle number',
            so that we always gets error free form on selection/change of vehicle number */
                clearErrors();
                /* call 'getOutwardShipmentsById' function to get selected outward shipment id information */
                void getOutwardShipmentsById({ variables: { outwardShipmentId: value } });
              }}
            />
          </FormItem>
        </>
      )}
      {getOutwardShipmentsByIdDataLoading ? <Spin className="loadingSpinner" /> : null}
      {shipmentIdOrOutwardId ? (
        <>
          {outwardShipmentsByIdData ? (
            <>
              <Descriptions
                colon={false}
                column={1}
                labelStyle={{ width: '21%' }}
                style={{ marginTop: 20 }}
                contentStyle={{ textTransform: 'capitalize' }}
              >
                {screenType === 'edit' ? (
                  <Descriptions.Item label="Vehicle No.">
                    {outwardShipmentsByIdData.transporterVehicle
                      ? outwardShipmentsByIdData.transporterVehicle.number
                      : '-'}
                  </Descriptions.Item>
                ) : null}
                <Descriptions.Item label="GRN">
                  {outwardShipmentsByIdData.grn || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Date">
                  {outwardShipmentsByIdData.createdAt
                    ? dateFormatFunc(outwardShipmentsByIdData.createdAt)
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Transporter Name">
                  {outwardShipmentsByIdData.transporter
                    ? outwardShipmentsByIdData.transporter.name
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Drivers Name - Phone No."
                  contentStyle={{ flexDirection: 'column' }}
                >
                  {outwardShipmentsByIdData.drivers.map(({ driver }) => (
                    <span style={{ paddingBottom: 5 }} key={driver.id}>
                      Name: {driver.name}, Phone: {(driver.mobile && driver.mobile) || '-'}
                    </span>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Vehicle Capacity (quintals)"
                  contentStyle={{ textTransform: 'lowercase' }}
                >
                  {transporterVehicleCapacityInQuintal}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span>
                      Net Outward Material Weight - 1<br />
                      <span style={{ fontSize: 13 }}>(in quintals, as per weighbridge)</span>
                    </span>
                  }
                >
                  {outwardShipmentsByIdData.fullVehicleWtKg === 0
                    ? '-'
                    : convertKgsToQuintals(Number(calculateNetMaterialWeightInKg.toFixed(2)))}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span>
                      Net Outward Material Weight - 2<br />
                      <span style={{ fontSize: 13 }}>
                        (in quintals, as per no. of bags and packaging size)
                      </span>
                    </span>
                  }
                >
                  {convertKgsToQuintals(Number(calculateLoadedNetWeightWeightInKg.toFixed(2)))}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span>
                      Net weight-1 - Net weight-2
                      <br />
                      <span style={{ fontSize: 13 }}>(in quintals)</span>
                    </span>
                  }
                >
                  {outwardShipmentsByIdData.fullVehicleWtKg === 0
                    ? '-'
                    : convertKgsToQuintals(Number(calculateNetDifference.toFixed(2)))}
                </Descriptions.Item>
                <Descriptions.Item label="Truck Outward done?">
                  {outwardShipmentsByIdData.truckOutAt ? (
                    'Yes'
                  ) : (
                    <Popover
                      placement="right"
                      arrowPointAtCenter
                      content="Shipment can be edited from respective tab."
                      overlayStyle={{ width: 380 }}
                    >
                      No
                      <InfoCircleFilled style={{ color: '#2b78e4', marginLeft: 10 }} />
                    </Popover>
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Divider style={{ marginTop: 10 }} />
              <h3>Shipment Orders</h3>
              {/*  eslint-disable-next-line @typescript-eslint/no-misused-promises */}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Table<OrderArrayType>
                  dataSource={fields}
                  className="tableStyle"
                  bordered
                  size="small"
                  rowKey="id"
                  pagination={{ showSizeChanger: true }}
                >
                  <Table.Column<OrderArrayType>
                    title="Order Number"
                    align="center"
                    dataIndex="number"
                    render={(text, record) => <>{record.orderNumber || '-'}</>}
                  />
                  <Table.Column<OrderArrayType>
                    title="Broker"
                    dataIndex="broker"
                    align="center"
                    render={(text, record) => (
                      <span style={{ textTransform: 'capitalize' }}>{record.broker || '-'}</span>
                    )}
                  />
                  <Table.Column<OrderArrayType>
                    title="Product Type"
                    dataIndex="productType"
                    key="productType"
                    align="center"
                    render={(text, record) => record.productType || '-'}
                  />
                  <Table.Column<OrderArrayType>
                    title={() => {
                      return (
                        <span>
                          Product
                          <br />
                          Packaging Size
                        </span>
                      );
                    }}
                    dataIndex="productPackagingSize"
                    align="center"
                    width="200px"
                    render={(text, record) => {
                      return (
                        <>
                          {record ? (
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <b>Product: </b>
                              {record.product}
                            </span>
                          ) : (
                            '-'
                          )}
                          <br />
                          {record.bagSizeKg ? (
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <b>Size: </b>
                              {record.bagSizeKg} kgs
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  />
                  <Table.Column<OrderArrayType>
                    title="No. of Bags Loaded"
                    dataIndex="loadedBagsCount"
                    key="loadedBagsCount"
                    align="center"
                    width={100}
                    render={(text, record) => record.loadedBagsCount || '-'}
                  />
                  <Table.Column<OrderArrayType>
                    title="Weight (quintals)"
                    dataIndex="weight"
                    key="weight"
                    width={100}
                    align="center"
                    render={(text, record) => {
                      if (record.bagSizeKg && record.loadedBagsCount)
                        return (record.bagSizeKg * record.loadedBagsCount) / 100;
                      return '-';
                    }}
                  />
                  <Table.Column<OrderArrayType>
                    title="Buyer"
                    align="center"
                    width={200}
                    key="buyerId"
                    render={(text, record, index) => {
                      /* set 'orderId' form field value with shipment order's id, so that we can use it further while submitting the form */
                      setValue(`orderArray.${index}.orderId`, record.orderId);
                      return (
                        <FormItem
                          errorText={
                            orderTableFieldsErrors &&
                            orderTableFieldsErrors[index] &&
                            orderTableFieldsErrors[index].buyerId
                              ? orderTableFieldsErrors[index].buyerId?.message
                              : ''
                          }
                          inputColSpan={24}
                          customStyle={{ paddingTop: 0, justifyContent: 'center' }}
                          labelStyle={{ display: 'none' }}
                        >
                          <Select
                            name={`orderArray.${index}.buyerId`}
                            placeholder="Please select buyer name"
                            rhfControllerProps={{
                              control,
                            }}
                            customStyles={{
                              ...inputComponentCommonStyle,
                              width: 180,
                              borderColor:
                                orderTableFieldsErrors &&
                                orderTableFieldsErrors[index] &&
                                orderTableFieldsErrors[index].buyerId
                                  ? colors.errorTextColor
                                  : 'black',
                            }}
                            options={
                              getProductBuyersData && getProductBuyersData.getAllProductBuyers
                                ? getProductBuyersData.getAllProductBuyers.map((buyer) => ({
                                    label: buyer.name,
                                    value: buyer.id,
                                  }))
                                : []
                            }
                            selectProps={{
                              loading: getProductBuyersLoading,
                              showSearch: true,
                              optionFilterProp: 'children',
                              dropdownRender: (menu) => {
                                return (
                                  <>
                                    {menu}
                                    <Divider style={{ marginTop: 5, marginBottom: 0 }} />
                                    <Button
                                      className="selectDropDownButton"
                                      onClick={() => {
                                        setIsAddBuyerFormModalVisible(true);
                                      }}
                                      icon={<PlusOutlined />}
                                    >
                                      Add Buyer
                                    </Button>
                                  </>
                                );
                              },
                            }}
                          />
                        </FormItem>
                      );
                    }}
                  />
                  <Table.Column<OrderArrayType>
                    title="Invoice No."
                    align="center"
                    width={200}
                    render={(text, record, index) => {
                      return (
                        <FormItem
                          errorText={
                            orderTableFieldsErrors &&
                            orderTableFieldsErrors[index] &&
                            orderTableFieldsErrors[index].invoiceNumber
                              ? orderTableFieldsErrors[index].invoiceNumber?.message
                              : ''
                          }
                          inputColSpan={24}
                          customStyle={{ paddingTop: 0, justifyContent: 'center' }}
                          labelStyle={{ display: 'none' }}
                        >
                          <Input
                            name={`orderArray.${index}.invoiceNumber`}
                            placeholder="Please enter invoice number"
                            rhfControllerProps={{
                              control,
                            }}
                            customStyles={{
                              ...inputComponentCommonStyle,
                              borderColor:
                                orderTableFieldsErrors &&
                                orderTableFieldsErrors[index] &&
                                orderTableFieldsErrors[index].invoiceNumber
                                  ? colors.errorTextColor
                                  : 'black',
                            }}
                          />
                        </FormItem>
                      );
                    }}
                  />
                </Table>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    columnGap: '40px',
                    marginBottom: '40px',
                  }}
                >
                  {screenType === 'invoice' ? (
                    <>
                      <Button
                        type="primary"
                        className="buttonContainer"
                        loading={saveBtnLoading}
                        onClick={() => {
                          setSaveBtnLoading(true);
                          saveInvoiceFormDataFunc(true);
                        }}
                      >
                        Save
                      </Button>
                      <Popconfirm
                        title="Invoicing finished. Are you sure?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => {
                          // eslint-disable-next-line @typescript-eslint/no-floating-promises
                          handleSubmit(onSubmit)();
                        }}
                      >
                        <Button
                          type="primary"
                          className="buttonContainer"
                          loading={finishInvoicingBtnLoading}
                        >
                          Finish Invoicing
                        </Button>
                      </Popconfirm>
                    </>
                  ) : (
                    <>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="buttonContainer"
                        loading={finishInvoicingBtnLoading}
                      >
                        Update Shipment
                      </Button>
                      <Button
                        type="default"
                        onClick={() => {
                          navigate('/report/shipments/outward/products');
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  )}
                </div>
              </form>
            </>
          ) : null}
          {isAddBuyerFormModalVisible ? (
            <Modal
              visible={isAddBuyerFormModalVisible}
              title="Add Buyer"
              footer={null}
              onCancel={() => {
                /* close modal */
                setIsAddBuyerFormModalVisible(false);
              }}
              destroyOnClose
            >
              <AddOrEditBuyerForm
                mode="add"
                calledFrom="outwardProductInvoice"
                closeModal={() => {
                  setIsAddBuyerFormModalVisible(false);
                }}
              />
            </Modal>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default OutwardProductInvoicingScreen;
