import React, { useState } from 'react';
import { Button, Descriptions, message, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { ApolloError, useApolloClient, useMutation, gql, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Enum_Item_Category_Enum,
  FinishInternalTransferShipmentMutation,
  FinishInternalTransferShipmentMutationVariables,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
  GetSettingByNameQuery,
  GetSettingByNameQueryVariables,
  InternalTransferShipments,
  InternalTransferShipmentsForFinishingQuery,
  InternalTransferShipmentsForFinishingQueryVariables,
} from '../../graphql/graphql-types';
import { formItemStyleProps, inputComponentCommonStyle } from '../../utils/globals';
import { loader } from 'graphql.macro';
import { handleGetWeightFunction, logger } from '../../utils/helpers';
import InputNumber from '../../components/InputNumber';
import FormItem from '../../components/FormItem';
import Select from '../../components/Select';

/* loading  internal transfer shipment for finishing query */
const internalTransferShipmentsForFinishingQuery = loader(
  '../../graphql/queries/internalTransferShipmentsForFinishingQuery.graphql',
);

/* loading finish internal transfer shipment mutation */
const finishInternalTransferShipmentMutation = loader(
  '../../graphql/mutations/finishInternalTransferShipmentMutation.graphql',
);

/* loading get bhagwati mill id query */
const getBhagwatiMillIdQuery = loader('../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* loading get setting by name query with the help of loader */
const getSettingByNameQuery = loader('../../graphql/queries/getSettingByNameQuery.graphql');

/* finish internal shipment Form type */
type FinishInternalShipmentFormType = Pick<InternalTransferShipments, 'secondVehicleImage'> & {
  /* this type used to store second vehicle weight */
  secondVehicleWtKg: number | null;
  /* prop type used to store 'id' of the shipment whose vehicle number get selected from 'select vehicle' dropdown */
  shipmentIdFromVehicleNumber: string | null;
};

/* finish internal shipment component prop type */
type FinishInternalShipmentPropType = {
  /* this prop is used to know from which screen the component is called weighbridge paddy or product */
  weighbridgeType: 'paddy' | 'product';
};

/* validations required for useForm */
const schema = yup.object({
  secondVehicleWtKg: yup
    .number()
    .required('Please enter weight-2 (in kgs) and try again')
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
});

/* React functional component */
const FinishInternalShipmentScreen = ({ weighbridgeType }: FinishInternalShipmentPropType) => {
  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* this state used to show loading indicator on submit button */
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /* state to show loading indicator on 'get weight' button */
  const [getWeightButtonLoading, setGetWeightButtonLoading] = useState<boolean>(false);

  /* get bhagwati mill id query used to show Mill name */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* get internal transfer shipment data  */
  const {
    data: getInternalTransferShipmentsData,
    loading: getInternalTransferShipmentsDataLoading,
    error: getInternalTransferShipmentsDataError,
  } = useQuery<
    InternalTransferShipmentsForFinishingQuery,
    InternalTransferShipmentsForFinishingQueryVariables
  >(internalTransferShipmentsForFinishingQuery, {
    variables: {
      materialType:
        weighbridgeType === 'paddy'
          ? Enum_Item_Category_Enum.RawMaterial
          : Enum_Item_Category_Enum.Product,
    },
  });

  /* finish internal transfer shipment mutation function */
  const [finishInternalTransferShipmentMutationFunc] = useMutation<
    FinishInternalTransferShipmentMutation,
    FinishInternalTransferShipmentMutationVariables
  >(finishInternalTransferShipmentMutation);

  /* setting by name query is used to determine whether the 'secondVehicleWtKg' field is enabled or disabled. */
  const {
    data: weightInputDisabledData,
    error: getSettingByNameError,
    loading: getSettingByNameLoader,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weightInputDisabled' },
  });

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
    clearErrors,
  } = useForm<FinishInternalShipmentFormType>({
    defaultValues: {
      shipmentIdFromVehicleNumber: null,
      secondVehicleImage: null,
      secondVehicleWtKg: null,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getSettingByNameError) {
    return <div className="errorText">{getSettingByNameError.message}</div>;
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getSettingByNameLoader) {
    <Spin className="loadingSpinner" />;
  }

  /* this const used to store shipment, which is selected to finish using it's 'vehicle number' */
  const shipmentSelectedToFinish =
    getInternalTransferShipmentsData &&
    getInternalTransferShipmentsData.internalTransferShipments.find(
      (item) => item.id === watch('shipmentIdFromVehicleNumber'),
    );

  /* function to get weight of second vehicle */
  const getVehicleWeight = async () => {
    try {
      setGetWeightButtonLoading(true);

      // const to store vehicle weight from weighbridge
      const vehicleWeight = await handleGetWeightFunction(apolloClient);

      // const to store vehicle image
      // const vehicleImageData = await getWeightAndCaptureCctvImg(false);

      // setting vehicle weight
      setValue('secondVehicleWtKg', vehicleWeight);

      /* if 'secondVehicleWtKg' form field has any error, then clear it. after setting it's value  */
      if (errors.secondVehicleWtKg) {
        clearErrors('secondVehicleWtKg');
      }
      /* if function return has value then set that image to 'secondVehicleImage' field */
      // if (vehicleImageData.s3Key) {
      //   setValue('secondVehicleImage', vehicleImageData.s3Key);
      // }
      setGetWeightButtonLoading(false);
    } catch (err) {
      logger(err as ApolloError);
      setGetWeightButtonLoading(false);
    }
  };

  /* function to handle form submit */
  const onSubmit = (formData: FinishInternalShipmentFormType) => {
    setIsSubmitBtnLoading(true);
    /* destructing form data */
    const { secondVehicleWtKg, secondVehicleImage } = formData;

    if (weighbridgeType === 'product') {
      if (shipmentSelectedToFinish) {
        finishInternalTransferShipmentMutationFunc({
          variables: {
            id: shipmentSelectedToFinish.id,
            secondVehicleImage: secondVehicleImage,
            secondVehicleWtKg: secondVehicleWtKg,
            secondVehicleWtTakenAt: new Date(),
          },
          refetchQueries: [
            {
              query: internalTransferShipmentsForFinishingQuery,
              variables: { materialType: Enum_Item_Category_Enum.Product },
            },
          ],
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Shipment has been successfully closed.');
            reset();
            setIsSubmitBtnLoading(false);
          })
          .catch((error: ApolloError) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(error.message);
            setIsSubmitBtnLoading(false);
            logger(error);
          });
      }
    } else {
      if (shipmentSelectedToFinish) {
        /* const to store the difference between firstVehicleWtKg and secondVehicleWtKg, which will be used in further calculations */
        let shipmentVehicleWtDiff: number;

        if (shipmentSelectedToFinish.firstVehicleWtKg && secondVehicleWtKg) {
          shipmentVehicleWtDiff = Math.abs(
            shipmentSelectedToFinish.firstVehicleWtKg - secondVehicleWtKg,
          );
        }

        /* variable to store total numbers  of bags for selected internal shipment. it will be sum of individual internal shipment items bagsCount */
        let totalNoOfBags = 0;

        /* const to store object which contain shipment item id and its calculated netMaterialWtPerBagKg */
        const materialArrayData: Array<{ id: string; netMaterialWtPerBagKg: number }> = [];

        shipmentSelectedToFinish.internalTransferShipmentItems.forEach((shipment) => {
          if (shipment.bagsCount) {
            /* calculating total no of bags */
            totalNoOfBags += shipment.bagsCount;
            /* calculating netMaterialWtPeKg */
            const netMaterialWt =
              Math.abs(shipmentVehicleWtDiff / totalNoOfBags - shipment.rmEmptyBagsWtKg) *
              shipment.bagsCount;
            materialArrayData.push({
              id: shipment.id,
              netMaterialWtPerBagKg: netMaterialWt,
            });
          }
        });

        /* this variable used to store all shipment items 'update' mutation string, which is then used to update all shipment items data */
        let combinedMutationStringForUpdatingInternalShipmentItems = '';

        if (materialArrayData.length > 0) {
          materialArrayData.forEach((item, index) => {
            /* update mutation string for individual internal transfer shipment item to edit netMaterialWtPerBagKg value */
            const updateInternalTransferShipmentItemMutationString = ` update_internalTransferShipmentItems_${index}: update_internalTransferShipmentItems_by_pk(pk_columns: {id: "${item.id}"}, _set: {netMaterialWtPerBagKg: ${item.netMaterialWtPerBagKg}}) {
    id
    netMaterialWtPerBagKg
  }`;
            /* combine all shipment items update mutation string into one string */
            combinedMutationStringForUpdatingInternalShipmentItems +=
              updateInternalTransferShipmentItemMutationString;
          });
        }

        /* create final mutation using combination of finishInternalShipment and combinedMutationStringForUpdatingInternalShipmentItems mutation strings */
        const finalMutation = gql`mutation finishInternalTransferShipmentForPaddy($id: uuid!, $secondVehicleImage: String, $secondVehicleWtKg: numeric!, $secondVehicleWtTakenAt: timestamptz!) {
          update_internalTransferShipments_by_pk(pk_columns: {id: $id}, _set: {secondVehicleImage: $secondVehicleImage, secondVehicleWtKg: $secondVehicleWtKg, secondVehicleWtTakenAt: $secondVehicleWtTakenAt}) {
            id
          }
          ${combinedMutationStringForUpdatingInternalShipmentItems}
        }`;

        apolloClient
          .mutate({
            mutation: finalMutation,
            variables: {
              id: shipmentSelectedToFinish.id,
              secondVehicleImage: secondVehicleImage,
              secondVehicleWtKg: secondVehicleWtKg,
              secondVehicleWtTakenAt: new Date(),
            },
            /* to get updated unloading shipments, refetch 'internalTransferShipmentsForFinishingQuery', after finishing a shipment*/
            refetchQueries: [
              {
                query: internalTransferShipmentsForFinishingQuery,
                variables: { materialType: Enum_Item_Category_Enum.RawMaterial },
              },
            ],
          })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Shipment has been successfully closed.');
            reset();
            setIsSubmitBtnLoading(false);
          })
          .catch((error: ApolloError) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(error.message);
            setIsSubmitBtnLoading(false);
            logger(error);
          });
      }
    }
  };

  /* show loading indicator on the screen until data get fetch from the server */
  if (getInternalTransferShipmentsDataLoading || getBhagwatiMillIdQueryLoader) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getInternalTransferShipmentsDataError || getBhagwatiMillIdQueryError) {
    return (
      <div className="errorText">
        {getInternalTransferShipmentsDataError
          ? getInternalTransferShipmentsDataError.message
          : getBhagwatiMillIdQueryError && getBhagwatiMillIdQueryError.message}
      </div>
    );
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormItem label="Select Vehicle" isRequired requiredMark="after" {...formItemStyleProps}>
        <Select
          customStyles={inputComponentCommonStyle}
          placeholder="Please select vehicle number"
          name="shipmentIdFromVehicleNumber"
          rhfControllerProps={{
            control,
          }}
          selectProps={{
            showSearch: true,
            optionFilterProp: 'children',
          }}
          options={
            getInternalTransferShipmentsData
              ? getInternalTransferShipmentsData.internalTransferShipments.map((item) => ({
                  value: item.id,
                  label: item.vehicleNumber as string,
                }))
              : []
          }
        />
      </FormItem>
      {watch('shipmentIdFromVehicleNumber') && shipmentSelectedToFinish ? (
        <>
          <Descriptions
            colon={false}
            column={1}
            labelStyle={{ width: '17%' }}
            style={{ marginTop: 15 }}
            contentStyle={{ textTransform: 'capitalize' }}
          >
            <Descriptions.Item label="GRN">{shipmentSelectedToFinish.grn}</Descriptions.Item>
            <Descriptions.Item label="Driver Name">
              {shipmentSelectedToFinish.personName}
            </Descriptions.Item>
            <Descriptions.Item label="Weight-1 (in kgs)">
              {shipmentSelectedToFinish.firstVehicleWtKg &&
                (shipmentSelectedToFinish.firstVehicleWtKg as number).toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Mill Name">
              {getBhagwatiMillIdData &&
                getBhagwatiMillIdData.mills[0] &&
                getBhagwatiMillIdData.mills[0].name}
            </Descriptions.Item>
          </Descriptions>
          <FormItem
            label="Weight-2 (in kgs)"
            isRequired
            requiredMark="after"
            errorText={
              errors && errors.secondVehicleWtKg ? errors.secondVehicleWtKg.message : undefined
            }
            {...formItemStyleProps}
            customStyle={{ paddingTop: 0 }}
          >
            <div style={{ display: 'flex' }}>
              <InputNumber
                customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
                placeholder="Please enter weight-2 (in kgs)"
                name="secondVehicleWtKg"
                rhfControllerProps={{
                  control,
                }}
                inputNumberProps={{
                  min: 0,
                  precision: 2,
                  disabled:
                    weightInputDisabledData && !weightInputDisabledData.getSettingByName[0].intValue
                      ? true
                      : false,
                }}
              />
              <Button
                type="primary"
                style={{ marginLeft: 5 }}
                onClick={() => {
                  getVehicleWeight().catch((err: ApolloError) => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.error(err.message);
                  });
                }}
                loading={getWeightButtonLoading}
              >
                Get Weight
              </Button>
            </div>
          </FormItem>
          <FormItem {...formItemStyleProps} customStyle={{ paddingBottom: 10 }}>
            <Button htmlType="submit" type="primary" loading={isSubmitBtnLoading}>
              Submit
            </Button>
          </FormItem>
        </>
      ) : null}
    </form>
  );
};

export default FinishInternalShipmentScreen;
