import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useLazyQuery, ApolloError, useMutation } from '@apollo/client';
import { Button, message, Divider, Spin, Descriptions, Table, Popconfirm } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InfoCircleFilled, PlusOutlined, WarningFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import {
  GetOutwardShipmentsForOrdersAndLoadingQuery,
  GetOutwardShipmentsForOrdersAndLoadingQueryVariables,
  GetOutwardShipmentByIdQuery,
  GetOutwardShipmentByIdQueryVariables,
  GetShipmentOrdersQuery,
  GetShipmentOrdersQueryVariables,
  CreateOutwardShipmentOrderMutation,
  CreateOutwardShipmentOrderMutationVariables,
  UpdateOutwardShipmentMutation,
  UpdateOutwardShipmentMutationVariables,
  DeleteOutwardShipmentMutation,
  DeleteOutwardShipmentMutationVariables,
  FinishOutwardShipmentOrdersMutation,
  FinishOutwardShipmentOrdersMutationVariables,
} from '../../../graphql/graphql-types';
import RequiredMessage from '../../../components/RequiredMessage';
import FormItem from '../../../components/FormItem';
import Select from '../../../components/Select';
import { dateFormatFunc, inputComponentCommonStyle } from '../../../utils/globals';
import { OutwardShipmentByIdDataType, OutwardShipmentOrdersTableType } from '../../../utils/types';
import InputNumber from '../../../components/InputNumber';
import { logger } from '../../../utils/helpers';
import { useOutwardShipmentOutlet } from './OutwardProductTabLayout';
import RadioGroup from '../../../components/RadioGroup';
import colors from '../../../scss/variables.module.scss';
import QuickAddBrokerForm from '../../../components/QuickAddBrokerForm';

/* create or update outward shipment order form type */
type CreateOrUpdateOutwardShipmentOrderFormType = {
  /* prop type used to store 'id' of the shipment whose vehicle number get selected from 'select vehicle' dropdown */
  shipmentIdFromVehicleNumber: number | null;
  /* prop type used broker id */
  brokerId: string | null;
  /* prop type used to store product type id */
  productTypeId: number | null;
  /* prop type used to store product id */
  productId: number | null;
  /* prop type used to store number of bags */
  bagsCount: number | null;
  /* prop type used to store size of the bag */
  bagSizeKg: number | null;
  /* prop type used to loose bag weight, when bag size is equal to 'zero' */
  looseBagWtKg: number | null;
};

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
  /* custom style of formItem component */
  customStyle: { alignItems: 'center' },
};

/* loading get outward shipment for order query with the help of loader */
const getOutwardShipmentsForOrdersAndLoadingQuery = loader(
  '../../../graphql/queries/getOutwardShipmentsForOrdersAndLoadingQuery.graphql',
);

/* loading get outward shipment by id query with the help of loader */
const getOutwardShipmentByIdQuery = loader(
  '../../../graphql/queries/getOutwardShipmentByIdQuery.graphql',
);

/* loading get shipment orders query with the help of loader */
const getShipmentOrdersQuery = loader('../../../graphql/queries/getShipmentOrdersQuery.graphql');

/* loading create outward shipment order mutation  */
const createOutwardShipmentOrderMutation = loader(
  '../../../graphql/mutations/createOutwardShipmentOrderMutation.graphql',
);

/* loading update outward shipment order mutation  */
const updateOutwardShipmentOrderMutation = loader(
  '../../../graphql/mutations/updateOutwardShipmentOrderMutation.graphql',
);

/* loading delete outward shipment order mutation  */
const deleteOutwardShipmentOrderMutation = loader(
  '../../../graphql/mutations/deleteOutwardShipmentOrderMutation.graphql',
);

/* loading finish outward shipment order mutation  */
const finishOutwardShipmentOrdersMutation = loader(
  '../../../graphql/mutations/finishOutwardShipmentOrdersMutation.graphql',
);

/* form validation schema */
const schema = yup.object({
  productTypeId: yup.number().nullable().required('Please select product type and try again.'),
  productId: yup.number().nullable().required('Please select product and try again.'),
  bagsCount: yup
    .number()
    .integer('Please enter valid integer.')
    .nullable()
    .required('Please enter number of bags and try again.'),
  bagSizeKg: yup.number().nullable().required('Please enter select size of bags and try again.'),
  looseBagWtKg: yup
    .number()
    .nullable()
    .when(['bagSizeKg'], {
      is: (bagSizeKg: number) => bagSizeKg === 0,
      then: yup
        .number()
        .nullable()
        .required('Please enter loose bag weight and try again.')
        .integer('Please enter valid integer.'),
    }),
});

/* react functional component */
const OutwardProductShipmentOrderScreen = (): JSX.Element => {
  /* extracting 'outwardShipmentId & setOutwardShipmentId' from outward shipment outlet */
  const { outwardShipmentId, setOutwardShipmentId } = useOutwardShipmentOutlet();

  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* this state used to store shipment id, whose 'edit' button has clicked. which is then used to edit that shipment order data */
  const [editShipmentOrderId, setEditShipmentOrderId] = useState<number | null>(null);

  /* state used show loading indicator on 'Create' or 'Update' button when creating a new shipment order or updating existing Shipment order */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  const [isAddBrokerModalVisible, setIsAddBrokerModalVisible] = useState<boolean>(false);

  /* state used to store id of the shipment order whose delete button is clicked which is then used to show loading indicator */
  const [shipmentOrderIdToDltAndShowLoading, setShipmentOrderIdToDltAndShowLoading] = useState<
    number | null
  >(null);

  /* this state used to store loading indicator and name of the finish button(here we have two type of finish button),
   so that we can show loading indicator on finish button using their name  */
  const [finishShipmentOrderBtnLoading, setFinishShipmentOrderBtnLoading] = useState<{
    /* prop type used to store loading */
    loading: boolean;
    /* prop type used to store finish button name */
    buttonName: 'finish' | 'finishAndLoading' | null;
  }>({ loading: false, buttonName: null });

  /* const used to store 'create or update shipment order' form default values,
  so that we can use it later while resetting the form with default values. */
  const CreateOrUpdateOutwardShipmentOrderFormDefaultValues = {
    /* shipment id */
    shipmentIdFromVehicleNumber: null,
    /* broker id */
    brokerId: null,
    /* product id */
    productId: null,
    /* product type id */
    productTypeId: null,
    /* number of bags */
    bagsCount: null,
    /* bag package size */
    bagSizeKg: null,
    /* loose bag weight */
    looseBagWtKg: null,
  };

  /* this variable used to store total weight, which is calculated using 'bagCount', 'bagSizeKg' & 'looseBagWtKg' form field values. */
  let calculatedWeightInKg = 0;

  /* this variable used to store order's total weight */
  let ordersTotalWeightInKg = 0;

  /* this variable used to store transporter vehicle's capacity */
  let transporterVehicleCapacityInKg = 0;

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<CreateOrUpdateOutwardShipmentOrderFormType>({
    resolver: yupResolver(schema),
    defaultValues: CreateOrUpdateOutwardShipmentOrderFormDefaultValues,
    mode: 'onChange',
  });

  /* get outward shipment by Id query used to display shipment & shipment order information */
  const [
    getOutwardShipmentsById,
    {
      data: getOutwardShipmentsByIdData,
      loading: getOutwardShipmentsByIdDataLoading,
      error: getOutwardShipmentsByIdDataError,
    },
  ] = useLazyQuery<GetOutwardShipmentByIdQuery, GetOutwardShipmentByIdQueryVariables>(
    getOutwardShipmentByIdQuery,
  );

  /* get outward shipment for order query used to display vehicle number, as a dropdown list on 'shipmentIdFromVehicleNumber' form field */
  const {
    data: getOutwardShipmentsForOrdersData,
    loading: getOutwardShipmentsForOrdersDataLoading,
    error: getOutwardShipmentsForOrdersDataError,
  } = useQuery<
    GetOutwardShipmentsForOrdersAndLoadingQuery,
    GetOutwardShipmentsForOrdersAndLoadingQueryVariables
  >(getOutwardShipmentsForOrdersAndLoadingQuery, {
    onCompleted: () => {
      /* if user already selected outward shipment id to order,from 'Create shipment' screen,
   then to get shipment & shipment order information for that selected shipment id  */
      if (outwardShipmentId) {
        void getOutwardShipmentsById({ variables: { outwardShipmentId: outwardShipmentId } });
        /* initialize 'shipmentIdFromVehicleNumber' field with selected outward shipment id */
        setValue('shipmentIdFromVehicleNumber', outwardShipmentId);
      }
    },
  });

  /* get outward shipment order data query used to display broker, product & product type names,
   as a dropdown list on 'brokerId','productId' & 'productTypeId' form field */
  const {
    data: getShipmentOrderData,
    loading: getShipmentOrderDataLoading,
    error: getShipmentOrderDataError,
  } = useQuery<GetShipmentOrdersQuery, GetShipmentOrdersQueryVariables>(getShipmentOrdersQuery);

  /* delete outward shipment order mutation */
  const [deleteOutwardShipmentOrder] = useMutation<
    DeleteOutwardShipmentMutation,
    DeleteOutwardShipmentMutationVariables
  >(deleteOutwardShipmentOrderMutation);

  /* create outward shipment order mutation */
  const [createOutwardShipmentOrder] = useMutation<
    CreateOutwardShipmentOrderMutation,
    CreateOutwardShipmentOrderMutationVariables
  >(createOutwardShipmentOrderMutation);

  /* update outward shipment order mutation */
  const [updateOutwardShipmentOrder] = useMutation<
    UpdateOutwardShipmentMutation,
    UpdateOutwardShipmentMutationVariables
  >(updateOutwardShipmentOrderMutation);

  /* finish outward shipment order mutation */
  const [finishShipmentOrder] = useMutation<
    FinishOutwardShipmentOrdersMutation,
    FinishOutwardShipmentOrdersMutationVariables
  >(finishOutwardShipmentOrdersMutation);

  /* show error text on the screen. if it has any error while loading data from the server */
  if (
    getOutwardShipmentsForOrdersDataError ||
    getShipmentOrderDataError ||
    getOutwardShipmentsByIdDataError
  ) {
    let errorMessage;
    if (getOutwardShipmentsForOrdersDataError) {
      errorMessage = getOutwardShipmentsForOrdersDataError.message;
    } else if (getShipmentOrderDataError) {
      errorMessage = getShipmentOrderDataError.message;
    } else if (getOutwardShipmentsByIdDataError) {
      errorMessage = getOutwardShipmentsByIdDataError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* const used to store outward shipment by id data, so that we can use it further */
  const outwardShipmentsByIdData =
    getOutwardShipmentsByIdData &&
    (getOutwardShipmentsByIdData.getOutwardShipmentById as OutwardShipmentByIdDataType);

  /* this const used to store 'bagCount' form field value, using watch */
  const watchedBagCount = watch('bagsCount');

  /* this const used to store 'bagSizeKg' form field value, using watch */
  const watchedBagSizeInKg = watch('bagSizeKg');

  /* this const used to store 'looseBagWtKg' form field value, using watch */
  const watchedLooseBagWeightInKg = watch('looseBagWtKg');

  /* this const used to store shipment id, which is selected to finish order/complete order */
  const watchedShipmentIdSelectedToFinish = watch('shipmentIdFromVehicleNumber');

  /* if user entered 'looseBagWtKg' & 'bagCount' field values then calculate 'calculatedWeightInKg' using that values */
  if (watchedLooseBagWeightInKg && watchedBagCount) {
    calculatedWeightInKg = watchedLooseBagWeightInKg * watchedBagCount;
  } else if (watchedBagCount && watchedBagSizeInKg) {
    /* if user entered 'bagSizeKg' & 'bagCount' field values then calculate 'calculatedWeightInKg' using that values */
    calculatedWeightInKg = watchedBagSizeInKg * watchedBagCount;
  }

  /* if outward shipment by Id data loaded successfully, then this condition used to calculate total order's weight & transporter vehicle capacity */
  if (outwardShipmentsByIdData) {
    /* destructing outward order shipment by id data */
    const { transporterVehicle, orders } = outwardShipmentsByIdData;

    /* calculate transporter vehicle capacity in kg */
    transporterVehicleCapacityInKg =
      transporterVehicle && transporterVehicle.capacityKgs ? transporterVehicle.capacityKgs : 0;

    /* calculate total order weight in kg */
    if (orders.length > 0) {
      orders.forEach((order) => {
        if (order.bagSizeKg && order.plannedBagsCount) {
          ordersTotalWeightInKg += order.bagSizeKg * order.plannedBagsCount;
        }
      });
    }
  }

  /* function to handle form submit */
  const onSubmit = (formData: CreateOrUpdateOutwardShipmentOrderFormType) => {
    /* if total order weight is greater than transporter vehicle capacity then show this error message */
    if (calculatedWeightInKg + ordersTotalWeightInKg > transporterVehicleCapacityInKg) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(
        `Total weight order exceeds Vehicle capacity. Cannot ${
          editShipmentOrderId ? 'update' : 'create'
        } order.`,
      );
    } else {
      setIsSubmitOrUpdateBtnLoading(true);
      /* destructing form data */
      const {
        bagSizeKg,
        bagsCount,
        brokerId,
        productId,
        looseBagWtKg,
        shipmentIdFromVehicleNumber,
      } = formData;

      /* this variable used to store number of bags(bag count), so that we can update it further  */
      let numberOfBags = bagsCount ? bagsCount : 0;

      /* this const used to store bag size in kg */
      const bagSizeInKg = bagSizeKg !== 0 && bagSizeKg ? bagSizeKg : (looseBagWtKg as number);

      /* this variable used to store 'id' of the shipment data, which is selected to 'edit'  */
      let shipmentIdToEdit = editShipmentOrderId;

      /* this const used to store mutation variables, which are common, in both 'create' & 'update' shipment order mutation */
      const commonMutationVariables = {
        productId: productId as number,
        brokerId: brokerId || null,
        bagSizeKg: bagSizeInKg,
      };

      /* if user has created/(updated with) same shipment order entry, which is already exists (which has same broker, product type, product name & package size).
    and then we are updating that existing entry */
      /* so that we not repeating same shipment order twice */
      if (outwardShipmentsByIdData) {
        /* destructing 'orders' from outward shipment by id data */
        const { orders } = outwardShipmentsByIdData;

        /* if any shipment orders created for respective shipment, then check if the order created by user is already exits or not */
        if (orders.length > 0) {
          orders.forEach((order) => {
            /* destructing order's data */
            const { broker, product, plannedBagsCount, id } = order;

            /* if user created same shipment order, which is already exist.
          then add existing order's number of bags & user entered number of bags */
            if (
              broker &&
              product &&
              brokerId &&
              productId &&
              broker.id === brokerId &&
              product.id === productId &&
              order.bagSizeKg === bagSizeInKg &&
              plannedBagsCount
            ) {
              /* update shipment order which is already exist  */
              shipmentIdToEdit = id;
              /* if user edited any shipment order, with already existed shipment order,
            then update number of bags for the existing shipment order & delete shipment order which is selected to edit */
              if (editShipmentOrderId) {
                if (id !== editShipmentOrderId) {
                  /* add existing order's number of bags & user entered number of bags */
                  numberOfBags += plannedBagsCount;
                  /* update shipment order which is already exist */
                  shipmentIdToEdit = id;
                  /* delete shipment order which is selected to 'edit' */
                  void deleteOutwardShipmentOrder({
                    variables: {
                      id: editShipmentOrderId,
                    },
                  });
                }
              } else {
                /* add existing order's number of bags & user entered number of bags */
                numberOfBags += plannedBagsCount;
              }
            }
          });
        }
      }

      /* if user has selected some shipment order to edit or created same shipment order which is already exist, then execute 'update outward shipment order' mutation,
    otherwise execute 'create outward shipment order' mutation for new shipment orders */
      if (shipmentIdToEdit) {
        updateOutwardShipmentOrder({
          variables: {
            id: shipmentIdToEdit,
            plannedBagsCount: numberOfBags,
            ...commonMutationVariables,
          },
          /* after updating shipment order refetch get outward shipment by order query so that we can get updated shipment order */
          refetchQueries: [
            {
              query: getOutwardShipmentByIdQuery,
              variables: { outwardShipmentId: shipmentIdFromVehicleNumber },
            },
          ],
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Existing order updated..');
            /* reset shipment id which is selected to edit */
            setEditShipmentOrderId(null);
            /* reset form */
            reset({
              ...CreateOrUpdateOutwardShipmentOrderFormDefaultValues,
              shipmentIdFromVehicleNumber: watch('shipmentIdFromVehicleNumber'),
            });
            setIsSubmitOrUpdateBtnLoading(false);
          })
          .catch((error: ApolloError) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(error.message);
            setIsSubmitOrUpdateBtnLoading(false);
            logger(error);
          });
      } else {
        createOutwardShipmentOrder({
          variables: {
            outwardShipmentId: shipmentIdFromVehicleNumber as number,
            plannedBagsCount: numberOfBags,
            ...commonMutationVariables,
          },
          /* after creating shipment order, refetch get outward shipment by order query so that we can get updated shipment order */
          refetchQueries: [
            {
              query: getOutwardShipmentByIdQuery,
              variables: { outwardShipmentId: shipmentIdFromVehicleNumber },
            },
          ],
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('New order created.');
            /* reset form */
            reset({
              ...CreateOrUpdateOutwardShipmentOrderFormDefaultValues,
              shipmentIdFromVehicleNumber: watch('shipmentIdFromVehicleNumber'),
            });
            setIsSubmitOrUpdateBtnLoading(false);
          })
          .catch((error: ApolloError) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(error.message);
            setIsSubmitOrUpdateBtnLoading(false);
            logger(error);
          });
      }
    }
  };

  /* function to handle delete Shipment */
  const deleteShipmentOrderFunc = (shipmentId: number) => {
    setShipmentOrderIdToDltAndShowLoading(shipmentId);
    deleteOutwardShipmentOrder({
      variables: {
        id: shipmentId,
      },
      /* after deleting a shipment order, this refetch  is used to display an updated shipments orders array. */
      refetchQueries: [
        {
          query: getOutwardShipmentByIdQuery,
          variables: { outwardShipmentId: watch('shipmentIdFromVehicleNumber') },
        },
      ],
    })
      .then(() => {
        setShipmentOrderIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment order has been deleted successfully.');
      })
      .catch((error: ApolloError) => {
        setShipmentOrderIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  /* function to handle finish Shipment */
  const finishShipmentOrderFunc = (
    /* this prop type used to store shipment id, which is selected to finish/complete */
    shipmentId: number,
    /* this prop type used to store button name */
    buttonName: 'finish' | 'finishAndLoading',
  ) => {
    /* if shipment has no orders then show this error message */
    if (outwardShipmentsByIdData && outwardShipmentsByIdData.orders.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('No orders. Cannot finish Unloading.');
    } else {
      setFinishShipmentOrderBtnLoading({ loading: true, buttonName: buttonName });
      finishShipmentOrder({
        variables: {
          id: shipmentId,
          ordersTakenAt: new Date(),
        },
        /* after finishing a shipment order, refetch 'getOutwardShipmentsForOrdersAndLoadingQuery' query to get updated shipments list  */
        refetchQueries: [{ query: getOutwardShipmentsForOrdersAndLoadingQuery }],
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Orders created. Start loading.');
          /* if user has clicked on 'Finish shipment orders & Go loading' button then navigate to 'loading' page */
          if (buttonName === 'finishAndLoading') {
            /* store selected shipment id to finish order into 'setOutwardShipmentId's state,
          so that we can get that shipment id onto 'Outward product -> loading' tab screen  */
            setOutwardShipmentId(shipmentId);

            /* navigate to 'Outward product -> loading' screen */
            navigate('../loading');
          } else {
            /* if user dont want to navigate or get shipment id onto 'Outward product -> loading' tab screen
          then set outward shipment id to null */
            setOutwardShipmentId(null);
          }
          reset({ ...CreateOrUpdateOutwardShipmentOrderFormDefaultValues });
          setFinishShipmentOrderBtnLoading({ loading: false, buttonName: null });
        })
        .catch((error: ApolloError) => {
          setFinishShipmentOrderBtnLoading({ loading: false, buttonName: null });
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  };

  return (
    <>
      {isAddBrokerModalVisible ? (
        <QuickAddBrokerForm
          isModalOpen={isAddBrokerModalVisible}
          closeModal={() => {
            setIsAddBrokerModalVisible(false);
          }}
        />
      ) : null}
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <RequiredMessage />
        <FormItem
          label="Shipment vehicle "
          isRequired
          errorText={
            errors && errors.shipmentIdFromVehicleNumber
              ? errors.shipmentIdFromVehicleNumber.message
              : undefined
          }
          {...formItemStyleProps}
        >
          <Select
            customStyles={{ ...inputComponentCommonStyle }}
            placeholder="Please select vehicle number"
            name="shipmentIdFromVehicleNumber"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getOutwardShipmentsForOrdersDataLoading,
            }}
            options={
              getOutwardShipmentsForOrdersData &&
              getOutwardShipmentsForOrdersData.getOutwardShipmentsForOrders
                ? getOutwardShipmentsForOrdersData.getOutwardShipmentsForOrders.map((shipment) => {
                    return {
                      value: shipment.id,
                      label:
                        (shipment.transporterVehicle && shipment.transporterVehicle.number) || '-',
                    };
                  })
                : []
            }
            onChange={(rhfOnChange, value: number) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              rhfOnChange(value);
              void getOutwardShipmentsById({ variables: { outwardShipmentId: value } });
            }}
          />
        </FormItem>
        {watch('shipmentIdFromVehicleNumber') ? (
          <>
            {outwardShipmentsByIdData &&
            getShipmentOrderData &&
            !getOutwardShipmentsByIdDataLoading &&
            !getShipmentOrderDataLoading ? (
              <>
                <Descriptions
                  colon={false}
                  column={1}
                  labelStyle={{ width: '17%' }}
                  style={{ marginTop: 20 }}
                  contentStyle={{ textTransform: 'capitalize', marginBottom: 10 }}
                >
                  <Descriptions.Item label="GRN">
                    {outwardShipmentsByIdData.grn || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date">
                    {(outwardShipmentsByIdData.createdAt &&
                      dateFormatFunc(outwardShipmentsByIdData.createdAt)) ||
                      '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Transporter Name">
                    {(outwardShipmentsByIdData.transporter &&
                      outwardShipmentsByIdData.transporter.name) ||
                      '-'}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Drivers Name - Phone No."
                    contentStyle={{ flexDirection: 'column' }}
                  >
                    {outwardShipmentsByIdData.drivers.map(({ driver }) => (
                      <span style={{ paddingBottom: 5 }} key={driver.id}>
                        Name: {driver.name}, Phone: {driver.mobile || '-'}
                      </span>
                    ))}
                  </Descriptions.Item>
                </Descriptions>
                <FormItem
                  label="Select Broker"
                  errorText={errors && errors.brokerId ? errors.brokerId.message : undefined}
                  {...formItemStyleProps}
                  customStyle={{ paddingTop: 5 }}
                >
                  <Select
                    customStyles={{ ...inputComponentCommonStyle }}
                    placeholder="Please select broker name"
                    name="brokerId"
                    rhfControllerProps={{
                      control,
                    }}
                    selectProps={{
                      showSearch: true,
                      optionFilterProp: 'children',
                      loading: getShipmentOrderDataLoading,
                      dropdownRender: (menu) => {
                        return (
                          <>
                            {menu}
                            <Divider style={{ marginTop: 5, marginBottom: 0 }} />
                            <Button
                              className="selectDropDownButton"
                              onClick={() => {
                                setIsAddBrokerModalVisible(true);
                              }}
                              icon={<PlusOutlined />}
                            >
                              Add Broker
                            </Button>
                          </>
                        );
                      },
                    }}
                    options={getShipmentOrderData.getAllBrokers.map((buyer) => {
                      return {
                        value: buyer.id,
                        label: buyer.name,
                      };
                    })}
                  />
                </FormItem>
                <FormItem
                  label="Select Product type"
                  isRequired
                  errorText={
                    errors && errors.productTypeId ? errors.productTypeId.message : undefined
                  }
                  {...formItemStyleProps}
                >
                  <Select
                    customStyles={{ ...inputComponentCommonStyle }}
                    placeholder="Please select product type"
                    name="productTypeId"
                    rhfControllerProps={{
                      control,
                    }}
                    selectProps={{
                      showSearch: true,
                      optionFilterProp: 'children',
                      loading: getShipmentOrderDataLoading,
                    }}
                    options={getShipmentOrderData.getAllProductTypes.map((productType) => {
                      return {
                        value: productType.id,
                        label: productType.name,
                      };
                    })}
                    onChange={(rhfOnChange, value: number) => {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                      rhfOnChange(value);
                      /* if product type changed, then user has to select new product for that newly selected product type.
                    therefore set value of 'productId' field to null */
                      setValue('productId', null);
                    }}
                  />
                </FormItem>
                <FormItem
                  label="Select Product"
                  isRequired
                  errorText={errors && errors.productId ? errors.productId.message : undefined}
                  {...formItemStyleProps}
                >
                  <Select
                    customStyles={{ ...inputComponentCommonStyle }}
                    placeholder="Please select product name"
                    name="productId"
                    rhfControllerProps={{
                      control,
                    }}
                    selectProps={{
                      showSearch: true,
                      optionFilterProp: 'children',
                      loading: getShipmentOrderDataLoading,
                    }}
                    options={getShipmentOrderData.getAllProducts
                      .filter((product) => product.productType.id === watch('productTypeId'))
                      .map((item) => ({
                        value: item.id,
                        label: item.brand,
                      }))}
                  />
                </FormItem>
                <FormItem
                  label="Select Packaging Size"
                  isRequired
                  errorText={errors && errors.bagSizeKg ? errors.bagSizeKg.message : undefined}
                  {...formItemStyleProps}
                >
                  <RadioGroup
                    name="bagSizeKg"
                    rhfControllerProps={{
                      control,
                    }}
                    className="shipmentOrderRadioBtn"
                    options={[
                      { label: '5 (kg)', value: 5 },
                      { label: '10 (kg)', value: 10 },
                      { label: '25 (kg)', value: 25 },
                      { label: '26 (kg)', value: 26 },
                      { label: '30 (kg)', value: 30 },
                      { label: '45 (kg)', value: 45 },
                      { label: '50 (kg)', value: 50 },
                      { label: '60 (kg)', value: 60 },
                      { label: 'Loose', value: 0 },
                    ]}
                    onChange={() => {
                      /* if looseBagWtKg form field is already selected then, set it as a null. (while selecting another option from bag size) */
                      if (watch('looseBagWtKg')) {
                        setValue('looseBagWtKg', null);
                      }
                    }}
                  />
                </FormItem>
                {watch('bagSizeKg') === 0 ? (
                  <FormItem
                    errorText={
                      errors && errors.looseBagWtKg ? errors.looseBagWtKg.message : undefined
                    }
                    {...formItemStyleProps}
                    customStyle={{ paddingTop: 15, marginBottom: 10 }}
                  >
                    <InputNumber
                      customStyles={inputComponentCommonStyle}
                      placeholder="Please enter loose bag weight (in kgs)"
                      name="looseBagWtKg"
                      rhfControllerProps={{
                        control,
                      }}
                      inputNumberProps={{ min: 0 }}
                    />
                  </FormItem>
                ) : null}
                <FormItem
                  label="Number of bags"
                  isRequired
                  errorText={errors && errors.bagsCount ? errors.bagsCount.message : undefined}
                  {...formItemStyleProps}
                  customStyle={{ paddingTop: 15 }}
                >
                  <InputNumber
                    customStyles={inputComponentCommonStyle}
                    placeholder="Please enter number of bags"
                    name="bagsCount"
                    rhfControllerProps={{
                      control,
                    }}
                    inputNumberProps={{ min: 0 }}
                  />
                </FormItem>
                <FormItem label="Weight (kgs)" {...formItemStyleProps}>
                  <span>{calculatedWeightInKg ? calculatedWeightInKg : '-'}</span>
                </FormItem>
                <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
                  <>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: 10 }}
                      loading={isSubmitOrUpdateBtnLoading}
                    >
                      {editShipmentOrderId ? 'Update Order' : 'Create Order'}
                    </Button>
                    {editShipmentOrderId ? (
                      <Button
                        type="default"
                        onClick={() => {
                          /* reset form fields */
                          reset({
                            ...CreateOrUpdateOutwardShipmentOrderFormDefaultValues,
                            shipmentIdFromVehicleNumber: watch('shipmentIdFromVehicleNumber'),
                          });
                          setEditShipmentOrderId(null);
                        }}
                      >
                        Cancel
                      </Button>
                    ) : null}
                  </>
                </FormItem>
                <Divider style={{ marginTop: 10 }} />
                <h3>Orders</h3>
                <Descriptions
                  colon={false}
                  column={1}
                  labelStyle={{ width: 210 }}
                  style={{ marginTop: 20 }}
                  contentStyle={{ textTransform: 'capitalize' }}
                >
                  <Descriptions.Item
                    label="Vehicle Capacity"
                    contentStyle={{ textTransform: 'lowercase' }}
                  >
                    {transporterVehicleCapacityInKg ? (
                      <>
                        {transporterVehicleCapacityInKg} kgs ({transporterVehicleCapacityInKg / 100}
                        ) quintal
                      </>
                    ) : (
                      '-'
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Total weight of orders"
                    contentStyle={{ textTransform: 'lowercase' }}
                  >
                    {ordersTotalWeightInKg} kgs
                  </Descriptions.Item>
                </Descriptions>
                <Table<OutwardShipmentOrdersTableType>
                  dataSource={outwardShipmentsByIdData.orders}
                  key="id"
                  className="tableStyle"
                  bordered
                  size="small"
                  pagination={{ showSizeChanger: true }}
                >
                  <Table.Column
                    title="Order Number"
                    align="center"
                    dataIndex="number"
                    key="number"
                  />
                  <Table.Column<OutwardShipmentOrdersTableType>
                    title="Broker"
                    dataIndex="broker"
                    align="center"
                    render={(text, record) => (
                      <span style={{ textTransform: 'capitalize' }}>
                        {(record.broker && record.broker.name) || '-'}
                      </span>
                    )}
                  />
                  <Table.Column<OutwardShipmentOrdersTableType>
                    title="Product Type"
                    dataIndex="productType"
                    key="productType"
                    align="center"
                    render={(text, record) =>
                      (record.product && record.product.productType.name) || '-'
                    }
                  />
                  <Table.Column<OutwardShipmentOrdersTableType>
                    title={() => {
                      return (
                        <span>
                          Product
                          <br />
                          Packaging Size
                        </span>
                      );
                    }}
                    dataIndex="productPackagingSize"
                    align="center"
                    width="200px"
                    render={(text, record) => {
                      return (
                        <>
                          {record.product ? (
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <b>Product: </b>
                              {record.product.brand}
                            </span>
                          ) : (
                            '-'
                          )}
                          <br />
                          {record.bagSizeKg ? (
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <b>Size: </b>
                              {record.bagSizeKg} kgs
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  />
                  <Table.Column<OutwardShipmentOrdersTableType>
                    title="No. of bags"
                    dataIndex="noOfBags"
                    key="noOfBags"
                    align="center"
                    render={(text, record) => record.plannedBagsCount || '-'}
                  />
                  <Table.Column<OutwardShipmentOrdersTableType>
                    title="Weight (kgs)"
                    dataIndex="weight"
                    key="weight"
                    align="center"
                    render={(text, record) =>
                      (record.bagSizeKg &&
                        record.plannedBagsCount &&
                        record.bagSizeKg * record.plannedBagsCount) ||
                      '-'
                    }
                  />
                  <Table.Column<OutwardShipmentOrdersTableType>
                    key="id"
                    title="Actions"
                    dataIndex="actions"
                    align="center"
                    render={(text, record) => {
                      return (
                        <div className="buttonContainer">
                          <Button
                            onClick={() => {
                              /* scroll to the top of the window, so that user can easily see edit form is opened */
                              window.scrollTo(0, 0);
                              /* destructing shipment data, which is selected to edit */
                              const { broker, bagSizeKg, product, plannedBagsCount, id } = record;

                              /* set edit shipment id state */
                              setEditShipmentOrderId(id);

                              /* this const used to store if bag size is in defined package size,
                             which is used further to assign initial values to 'bagSizeKg' & 'looseBagWtKg' form field */
                              const isBagWeightInPackageSize =
                                bagSizeKg && [5, 10, 25, 30, 45, 50, 60].includes(bagSizeKg);

                              /* clear form errors, while setting form default values using setValue */
                              clearErrors();

                              /* initialize 'brokerId' field of the form, with shipment broker id */
                              setValue('brokerId', broker ? broker.id : null);

                              /* initialize 'productId' field of the form, with shipment product id */
                              setValue('productId', product ? product.id : null);

                              /* initialize 'productTypeId' field of the form, with shipment product type id */
                              setValue('productTypeId', product ? product.productType.id : null);

                              /* initialize 'bagsCount' field of the form, with shipment bag count */
                              setValue('bagsCount', plannedBagsCount ? plannedBagsCount : null);

                              /* initialize 'bagSizeKg' field of the form, with shipment bag size if it is in defined bag package size */
                              setValue('bagSizeKg', isBagWeightInPackageSize ? bagSizeKg : 0);

                              /* initialize 'looseBagWtKg' field of the form, with shipment bag size if it is not in defined bag package size */
                              setValue(
                                'looseBagWtKg',
                                !isBagWeightInPackageSize && bagSizeKg ? bagSizeKg : null,
                              );
                            }}
                          >
                            Edit
                          </Button>
                          <Popconfirm
                            title="Delete order. Are you sure?"
                            okText="Yes"
                            okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                            cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                            cancelText="No"
                            icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                            onConfirm={() => {
                              deleteShipmentOrderFunc(record.id);
                            }}
                            disabled={record.id === editShipmentOrderId}
                          >
                            <Button
                              className="deleteButton"
                              disabled={record.id === editShipmentOrderId}
                              loading={record.id === shipmentOrderIdToDltAndShowLoading}
                            >
                              Delete
                            </Button>
                          </Popconfirm>
                        </div>
                      );
                    }}
                  />
                </Table>

                <div
                  className="buttonContainer"
                  style={{ justifyContent: 'flex-end', marginBottom: 20 }}
                >
                  {/* if user already finished the shipment order, then just navigate to 'loading' screen. */}
                  {outwardShipmentsByIdData && outwardShipmentsByIdData.ordersTakenAt ? (
                    <Button
                      type="primary"
                      onClick={() => {
                        /* navigate to 'Outward product -> loading' screen */
                        navigate('../loading');
                      }}
                    >
                      Go Loading
                    </Button>
                  ) : (
                    <>
                      <Popconfirm
                        title="Proceed?"
                        okText="Yes"
                        okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                        cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                        cancelText="No"
                        icon={<InfoCircleFilled style={{ color: colors.infoIconColor }} />}
                        onConfirm={() => {
                          /* if any shipment id selected to finish orders/Complete orders, then call 'finishShipmentOrderFunc' function*/
                          if (watchedShipmentIdSelectedToFinish) {
                            finishShipmentOrderFunc(watchedShipmentIdSelectedToFinish, 'finish');
                          }
                        }}
                      >
                        <Button
                          type="primary"
                          disabled={
                            Array.isArray(outwardShipmentsByIdData.orders) &&
                            outwardShipmentsByIdData.orders.length === 0
                          }
                          loading={
                            finishShipmentOrderBtnLoading.buttonName === 'finish'
                              ? finishShipmentOrderBtnLoading.loading
                              : false
                          }
                        >
                          Finish Shipment Orders
                        </Button>
                      </Popconfirm>
                      <Popconfirm
                        title="Proceed?"
                        okText="Yes"
                        okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                        cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                        cancelText="No"
                        icon={<InfoCircleFilled style={{ color: colors.infoIconColor }} />}
                        onConfirm={() => {
                          /* if any shipment id selected to finish orders/Complete orders, then call 'finishShipmentOrderFunc' function*/
                          if (watchedShipmentIdSelectedToFinish) {
                            finishShipmentOrderFunc(
                              watchedShipmentIdSelectedToFinish,
                              'finishAndLoading',
                            );
                          }
                        }}
                      >
                        <Button
                          type="primary"
                          disabled={
                            Array.isArray(outwardShipmentsByIdData.orders) &&
                            outwardShipmentsByIdData.orders.length === 0
                          }
                          loading={
                            finishShipmentOrderBtnLoading.buttonName === 'finishAndLoading'
                              ? finishShipmentOrderBtnLoading.loading
                              : false
                          }
                          style={{ marginLeft: 20 }}
                        >
                          Finish Shipment Orders & Go Loading
                        </Button>
                      </Popconfirm>
                    </>
                  )}
                </div>
              </>
            ) : (
              <Spin className="loadingSpinner" />
            )}
          </>
        ) : null}
      </form>
    </>
  );
};

export default OutwardProductShipmentOrderScreen;
