import React, { useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import {
  CreateBagsOthersInwardShipmentMutation,
  CreateBagsOthersInwardShipmentMutationVariables,
  CustomItemListQuery,
  CustomItemListQueryVariables,
  CustomVendorListQuery,
  CustomVendorListQueryVariables,
  GetAllGodownsMillsQuery,
  GetAllGodownsMillsQueryVariables,
  InwardShipments,
  InwardShipmentItems,
  Enum_Item_Category_Enum,
  Enum_Inward_Shipment_Status_Enum,
  Enum_InwardShipmentItem_Source_Enum,
  CreateBagsOthersOutwardShipmentMutation,
  CreateBagsOthersOutwardShipmentMutationVariables,
  Enum_Source_Destination_Enum,
  Enum_OutwardShipmentOrder_Source_Enum,
  GetAllPurchaseOrderQuery,
  GetAllPurchaseOrderQueryVariables,
} from '../graphql/graphql-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Divider, Row, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { logger } from '../utils/helpers';
import RequiredMessage from './RequiredMessage';
import FormItem from './FormItem';
import Input from './Input';
import { inputComponentCommonStyle } from '../utils/globals';
import Select from './Select';
import InputNumber from './InputNumber';
import RadioGroup from './RadioGroup';
import QuickAddVendorOrBuyerForm from './QuickAddVendorOrBuyerForm';
import { useLocation } from 'react-router-dom';

/* CreateOtherItemInwardShipment form type */
type CreateOtherItemInwardShipmentFormType = Pick<
  InwardShipments,
  'vehicleNumber' | 'driverName' | 'deliveryPersonMobile'
> &
  Pick<
    InwardShipmentItems,
    'itemId' | 'rmSellerId' | 'godownId' | 'millId' | 'remark' | 'othersItemName'
  > & {
    /* other item quantity */
    othersQuantity: number | null;
    /* other item weight */
    othersWtKg: number | null;
    /* other Destination */
    othersDestination: string;
    /* destination or source */
    destinationOrSource:
      | Enum_Source_Destination_Enum
      | Enum_OutwardShipmentOrder_Source_Enum
      | null;
    /* selected PO id*/
    selectedPOId?: number | null;
  };

/* Create Other Item Inward Or Outward Shipment Form component props type */
type CreateOtherItemInwardOrOutwardShipmentFormPropsType = {
  /* this prop is used to know from which screen the component is called inward or outward */
  shipmentType: 'inward' | 'outward';
};

/* form validation schema */
const schema = yup.object({
  driverName: yup.string().required('Please enter delivery transport/person name and try again.'),
  deliveryPersonMobile: yup
    .string()
    .matches(validationRegex.mobile, {
      message: 'Please enter a valid mobile number',
      excludeEmptyString: true,
    })
    .nullable(),
  vehicleNumber: yup.string().matches(validationRegex.vehicleRegistrationNumber, {
    message: 'Please enter a valid vehicle number',
    excludeEmptyString: true,
  }),
  destinationOrSource: yup
    .string()
    .nullable()
    .when(['itemId'], {
      is: (itemId: number) => itemId !== null,
      then: yup.string().nullable().required('Please select destination and try again.'),
    }),
  godownId: yup
    .number()
    .nullable()
    .when(['destinationOrSource', 'itemId'], {
      is: (destinationOrSource: string, itemId: number) =>
        destinationOrSource === 'godown' && itemId !== null,
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
  millId: yup
    .number()
    .nullable()
    .when(['destinationOrSource', 'itemId'], {
      is: (destinationOrSource: string, itemId: string) =>
        destinationOrSource === 'mill' && itemId !== null,
      then: yup.number().nullable().required('Please select mill and try again.'),
    }),
  othersQuantity: yup
    .number()
    .nullable()
    .integer('Please enter valid number.')
    .min(0, 'Please enter valid number'),
  othersWtKg: yup
    .number()
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
});

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* loading create paddy or product bag inward shipment mutation  */
const createBagsOthersInwardShipmentMutation = loader(
  '../graphql/mutations/createBagsOthersInwardShipmentMutation.graphql',
);

/* loading create  bag outward shipment mutation  */
const createBagsOthersOutwardShipmentMutation = loader(
  '../graphql/mutations/createBagsOthersOutwardShipmentMutation.graphql',
);

/* loading get all godowns and mills query */
const getGodownAndMillsQuery = loader('../graphql/queries/getAllGodownsMillsQuery.graphql');

/* loading get item list query */
const getItemListQuery = loader('../graphql/queries/customItemListQuery.graphql');

/* loading get Vendor list query */
const getVendorListQuery = loader('../graphql/queries/customVendorListQuery.graphql');

/* loading get all purchase order query */
const getAllPurchaseOrderQuery = loader('../graphql/queries/getAllPurchaseOrderQuery.graphql');

/* React functional component */
const CreateOtherItemInwardOrOutwardShipmentForm = ({
  shipmentType,
}: CreateOtherItemInwardOrOutwardShipmentFormPropsType) => {
  /* this state used to show loading indicator on submit button */
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /* this state used to manage Add vendor form modal visibility */
  const [isAddVendorModalVisible, setIsAddVendorModalVisible] = useState<boolean>(false);

  const location = useLocation();

  /** function to get category according to current path  */
  const itemCategory = (locationPathname: string) => {
    if (locationPathname.includes('paddy')) {
      return Enum_Item_Category_Enum.PaddyBag;
    } else if (locationPathname.includes('product')) {
      return Enum_Item_Category_Enum.ProductBag;
    } else {
      return Enum_Item_Category_Enum.Others;
    }
  };

  /* create product or paddy bag inward shipment mutation */
  const [createInwardShipment] = useMutation<
    CreateBagsOthersInwardShipmentMutation,
    CreateBagsOthersInwardShipmentMutationVariables
  >(createBagsOthersInwardShipmentMutation);

  /* create paddy bag outward shipment mutation */
  const [createOutwardShipment] = useMutation<
    CreateBagsOthersOutwardShipmentMutation,
    CreateBagsOthersOutwardShipmentMutationVariables
  >(createBagsOthersOutwardShipmentMutation);

  /* get all godown and mills data query used to show list of godowns and mills as options on 'Select godown' & 'select mill' fields */
  const {
    data: getGodownAndMillsData,
    loading: getGodownAndMillsDataLoading,
    error: getGodownAndMillsDataError,
  } = useQuery<GetAllGodownsMillsQuery, GetAllGodownsMillsQueryVariables>(getGodownAndMillsQuery);

  /* get custom item list query used to show list of items as options on 'Select item' field */
  const {
    data: getItemListData,
    loading: getItemListDataLoading,
    error: getItemListDataError,
  } = useQuery<CustomItemListQuery, CustomItemListQueryVariables>(getItemListQuery, {
    variables: { _category: Enum_Item_Category_Enum.Others },
    fetchPolicy: 'network-only',
  });

  /* get all vendor list query used to show list of vendors options on 'Select vendor' field */
  const {
    data: getVendorData,
    loading: getVendorDataLoading,
    error: getVendorDataError,
  } = useQuery<CustomVendorListQuery, CustomVendorListQueryVariables>(getVendorListQuery, {
    variables: { _item_category: Enum_Item_Category_Enum.Others },
  });

  const {
    data: getAllPurchaseOrderData,
    loading: getAllPurchaseOrderLoading,
    error: getAllPurchaseOrderError,
  } = useQuery<GetAllPurchaseOrderQuery, GetAllPurchaseOrderQueryVariables>(
    getAllPurchaseOrderQuery,
    {
      variables: {
        item_category: itemCategory(location.pathname),
      },
      fetchPolicy: 'network-only',
    },
  );

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateOtherItemInwardShipmentFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      driverName: '',
      deliveryPersonMobile: '',
      vehicleNumber: '',
      itemId: null,
      rmSellerId: null,
      godownId: null,
      millId: null,
      destinationOrSource: null,
      othersItemName: '',
      othersQuantity: null,
      othersWtKg: null,
      othersDestination: '',
      remark: '',
      selectedPOId: null,
    },
    mode: 'onChange',
  });

  /** const to store list of all items which is used in 'select Item' field */
  const allItemsDetails =
    getItemListData &&
    getItemListData.items &&
    Array.isArray(getItemListData.items) &&
    getItemListData.items.length > 0
      ? getItemListData.items
      : [];

  /** const to store all vendor details  which is used in 'select vendor' field */
  const allVendorDetails =
    getVendorData &&
    getVendorData.rmSellers &&
    Array.isArray(getVendorData.rmSellers) &&
    getVendorData.rmSellers.length > 0
      ? getVendorData.rmSellers
      : [];

  /** const to store all PO details which is used in 'select PO' field */
  const allPODetails =
    getAllPurchaseOrderData &&
    getAllPurchaseOrderData.purchaseOrders &&
    Array.isArray(getAllPurchaseOrderData.purchaseOrders) &&
    getAllPurchaseOrderData.purchaseOrders.length > 0
      ? getAllPurchaseOrderData.purchaseOrders.filter((item) => !item.delivery_completed_at)
      : [];

  const poId = watch('selectedPOId');

  /** const to check 'PO' is selected or not  */
  const isPOSelected = poId ? true : false;

  /** const to store item list based on selected PO */
  const itemListOfSelectedPO =
    isPOSelected && allPODetails && allPODetails.length > 0
      ? allPODetails.filter((item) => item.id === poId)
      : [];

  /* show error text on the screen. if it has any error while loading data from the server */
  if (
    getItemListDataError ||
    getVendorDataError ||
    getGodownAndMillsDataError ||
    getAllPurchaseOrderError
  ) {
    let errorMessage;
    if (getItemListDataError) {
      errorMessage = getItemListDataError.message;
    } else if (getGodownAndMillsDataError) {
      errorMessage = getGodownAndMillsDataError.message;
    } else if (getVendorDataError) {
      errorMessage = getVendorDataError.message;
    } else if (getAllPurchaseOrderError) {
      errorMessage = getAllPurchaseOrderError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* function to handle submitted data */
  const onSubmit = handleSubmit((data: CreateOtherItemInwardShipmentFormType) => {
    setIsSubmitBtnLoading(true);

    /* destructing form data values */
    const {
      vehicleNumber,
      driverName,
      deliveryPersonMobile,
      godownId,
      millId,
      destinationOrSource,
      rmSellerId,
      itemId,
      othersItemName,
      othersQuantity,
      othersWtKg,
      othersDestination,
      remark,
      selectedPOId,
    } = data;
    if (shipmentType === 'inward') {
      createInwardShipment({
        variables: {
          object: {
            vehicleNumber: vehicleNumber ? vehicleNumber.toUpperCase() : null,
            driverName: driverName || null,
            deliveryPersonMobile: deliveryPersonMobile || null,
            status: Enum_Inward_Shipment_Status_Enum.Completed,
            completedAt: new Date(),
            po_id: selectedPOId,
            items: {
              data: [
                {
                  godownId: godownId || null,
                  millId: millId || null,
                  destination: (destinationOrSource as Enum_Source_Destination_Enum) || null,
                  rmSellerId: rmSellerId || null,
                  itemId: itemId || null,
                  othersItemName: othersItemName || null,
                  othersQuantity: othersQuantity || null,
                  othersWtKg: othersWtKg || null,
                  source: Enum_InwardShipmentItem_Source_Enum.Vendor,
                  remark: remark || null,
                },
              ],
            },
          },
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Item shipment has been successfully created.');
          reset();
          setIsSubmitBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          let errorMessage = error.message;
          if (error.message === 'database query error') {
            errorMessage = 'Vehicle is already in use!';
          }
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorMessage);
          setIsSubmitBtnLoading(false);
          logger(error);
        });
    } else {
      // when shipment type is outward below code will execute
      createOutwardShipment({
        variables: {
          object: {
            nonProductPersonMobile: deliveryPersonMobile || null,
            nonProductPersonName: driverName || null,
            nonProductVehicleNumber: vehicleNumber ? vehicleNumber.toUpperCase() : null,
            truckOutAt: new Date(),
            orders: {
              data: [
                {
                  godownId: godownId || null,
                  millId: millId || null,
                  itemId: itemId || null,
                  remark: remark || null,
                  othersItemName: othersItemName || null,
                  othersQuantity: othersQuantity || null,
                  othersWtKg: othersWtKg || null,
                  source: (destinationOrSource as Enum_OutwardShipmentOrder_Source_Enum) || null,
                  othersDestination: othersDestination || null,
                },
              ],
            },
          },
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been successfully created.');
          reset();
          setIsSubmitBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          let errorMessage = error.message;
          if (error.message === 'database query error') {
            errorMessage = 'Vehicle is already in use!';
          }
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorMessage);

          setIsSubmitBtnLoading(false);
          logger(error);
        });
    }
  });

  /* function used to close the visibility of modal by setting state value to false */
  const closeAddVendorForm = () => {
    setIsAddVendorModalVisible(false);
  };

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={onSubmit}
      >
        {shipmentType === 'inward' ? (
          <FormItem label="Select PO" {...formItemStyleProps}>
            <Select
              name="selectedPOId"
              placeholder="Select PO"
              selectProps={{
                loading: getAllPurchaseOrderLoading,
                showSearch: true,
                optionFilterProp: 'children',
              }}
              customStyles={inputComponentCommonStyle}
              rhfControllerProps={{ control }}
              options={
                allPODetails
                  ? allPODetails.map((item) => ({
                      value: item.id,
                      label: item.poNumber as string,
                    }))
                  : []
              }
            />
          </FormItem>
        ) : null}

        <FormItem
          label="Vehicle Number"
          errorText={errors && errors.vehicleNumber ? errors.vehicleNumber.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter vehicle registration number"
            name="vehicleNumber"
            rhfControllerProps={{
              control,
            }}
            onChange={(rhfOnChange, value) => {
              rhfOnChange(value.toUpperCase());
            }}
          />
        </FormItem>
        <FormItem
          label="Delivery Transport/Person"
          isRequired
          errorText={errors && errors.driverName ? errors.driverName.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter the name of delivery transport/person"
            name="driverName"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Delivery Person Mobile"
          errorText={
            errors && errors.deliveryPersonMobile ? errors.deliveryPersonMobile.message : undefined
          }
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter mobile number of delivery person"
            name="deliveryPersonMobile"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Select Item"
          errorText={errors && errors.itemId ? errors.itemId.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select item"
            name="itemId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              loading: getItemListDataLoading,
              optionFilterProp: 'children',
            }}
            options={
              isPOSelected && itemListOfSelectedPO && itemListOfSelectedPO.length > 0
                ? itemListOfSelectedPO[0].purchaseOrderItems.map((poData) => ({
                    value: poData.item.id,
                    label: poData.item.name,
                  }))
                : allItemsDetails &&
                  allItemsDetails.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
            }
          />
        </FormItem>
        <FormItem {...formItemStyleProps}>
          <>
            <Button
              type="default"
              onClick={() => {
                setValue('itemId', null);
              }}
              style={{ marginBottom: 5 }}
            >
              Reset
            </Button>
          </>
        </FormItem>
        {!isPOSelected ? (
          <>
            <Row>
              <Col offset={4}>
                <h3>OR</h3>
              </Col>
            </Row>
            <FormItem
              label="Item Name"
              errorText={
                errors && errors.othersItemName ? errors.othersItemName.message : undefined
              }
              {...formItemStyleProps}
            >
              <Input
                customStyles={inputComponentCommonStyle}
                placeholder="Please enter name of item"
                name="othersItemName"
                rhfControllerProps={{
                  control,
                }}
              />
            </FormItem>
          </>
        ) : null}

        {shipmentType === 'inward' ? (
          <FormItem
            label="Select Vendor"
            errorText={errors && errors.rmSellerId ? errors.rmSellerId.message : undefined}
            {...formItemStyleProps}
          >
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select vendor"
              name="rmSellerId"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                loading: getVendorDataLoading,
                optionFilterProp: 'children',
                dropdownRender: (menu) => {
                  return (
                    <>
                      {menu}
                      <Divider style={{ marginTop: 5, marginBottom: 0 }} />
                      <Button
                        className="selectDropDownButton"
                        onClick={() => {
                          setIsAddVendorModalVisible(true);
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add vendor
                      </Button>
                    </>
                  );
                },
              }}
              options={
                isPOSelected && itemListOfSelectedPO && itemListOfSelectedPO.length > 0
                  ? [
                      {
                        value: itemListOfSelectedPO[0].purchaseOrderItems[0].vendor.id,
                        label: itemListOfSelectedPO[0].purchaseOrderItems[0].vendor.name,
                      },
                    ]
                  : allVendorDetails &&
                    allVendorDetails.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
              }
            />
          </FormItem>
        ) : null}
        <FormItem
          label="Quantity"
          errorText={errors && errors.othersQuantity ? errors.othersQuantity.message : undefined}
          {...formItemStyleProps}
        >
          <InputNumber
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter quantity"
            name="othersQuantity"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        <FormItem
          label="Weight (in kgs)"
          errorText={errors && errors.othersWtKg ? errors.othersWtKg.message : undefined}
          {...formItemStyleProps}
        >
          <InputNumber
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter weight (in kgs)"
            name="othersWtKg"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{ min: 0, precision: 2 }}
          />
        </FormItem>
        <FormItem
          label={shipmentType === 'inward' ? 'Destination' : 'Source'}
          isRequired={watch('itemId') ? true : false}
          errorText={
            errors && errors.destinationOrSource ? errors.destinationOrSource.message : undefined
          }
          {...formItemStyleProps}
        >
          <RadioGroup
            name="destinationOrSource"
            rhfControllerProps={{
              control,
            }}
            defaultValue={'godown'}
            options={
              shipmentType === 'inward'
                ? [
                    { label: 'Godown', value: 'godown' },
                    {
                      label: 'Mill',
                      value: 'mill',
                    },
                    {
                      label: 'Office',
                      value: 'office',
                    },
                    {
                      label: 'Store room',
                      value: 'store_room',
                    },
                  ]
                : [
                    { label: 'Godown', value: 'godown' },
                    {
                      label: 'Mill',
                      value: 'mill',
                    },
                  ]
            }
            onChange={() => {
              /* if godown id or mill id is already selected then, set 'godown id ' or 'mill id ' as a null */
              if (watch('godownId') || watch('millId')) {
                setValue('godownId', null);
                setValue('millId', null);
              }
            }}
          />
        </FormItem>
        {watch('destinationOrSource') === 'mill' ? (
          <FormItem
            label="Select Mill"
            isRequired={watch('itemId') ? true : false}
            errorText={errors && errors.millId ? errors.millId.message : undefined}
            {...formItemStyleProps}
          >
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select mill "
              name="millId"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                loading: getGodownAndMillsDataLoading,
                optionFilterProp: 'children',
              }}
              options={
                getGodownAndMillsData
                  ? getGodownAndMillsData.getAllMills.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []
              }
            />
          </FormItem>
        ) : null}
        {watch('destinationOrSource') === 'godown' ? (
          <FormItem
            label="Select Godown "
            isRequired={watch('itemId') ? true : false}
            errorText={errors && errors.godownId ? errors.godownId.message : undefined}
            {...formItemStyleProps}
          >
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select godown"
              name="godownId"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                loading: getGodownAndMillsDataLoading,
                optionFilterProp: 'children',
              }}
              options={
                getGodownAndMillsData
                  ? getGodownAndMillsData.getAllGodowns.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []
              }
            />
          </FormItem>
        ) : null}
        {shipmentType === 'outward' ? (
          <FormItem label="Destination" {...formItemStyleProps}>
            <Input
              name="othersDestination"
              placeholder="Please enter destination details"
              rhfControllerProps={{
                control,
              }}
              customStyles={inputComponentCommonStyle}
            />
          </FormItem>
        ) : null}
        <FormItem
          label="Remark"
          errorText={errors && errors.remark ? errors.remark.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            name="remark"
            isTextAreaInput={true}
            placeholder="Please enter remark"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
            textAreaProps={{ rows: 2 }}
          />
        </FormItem>
        <FormItem {...formItemStyleProps} customStyle={{ paddingBottom: 20 }}>
          <>
            <Button
              htmlType="submit"
              type="primary"
              loading={isSubmitBtnLoading}
              style={{ marginRight: 10 }}
            >
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                reset();
              }}
            >
              Cancel
            </Button>
          </>
        </FormItem>
      </form>
      {isAddVendorModalVisible ? (
        <QuickAddVendorOrBuyerForm
          isModalOpen={isAddVendorModalVisible}
          closeModal={closeAddVendorForm}
          bagType={Enum_Item_Category_Enum.Others}
          shipmentType={shipmentType}
        />
      ) : null}
    </>
  );
};

export default CreateOtherItemInwardOrOutwardShipmentForm;
