import React, { useState } from 'react';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetAllRawMaterialsQuery,
  GetAllRawMaterialsQueryVariables,
  DeleteRawMaterialMutation,
  DeleteRawMaterialMutationVariables,
} from '../../../graphql/graphql-types';
import { Button, message, Popconfirm, Table } from 'antd';
import { PlusOutlined, SearchOutlined, WarningFilled } from '@ant-design/icons';
import { logger } from '../../../utils/helpers';
import colors from '../../../scss/variables.module.scss';
import { AddOrEditDataModalType, RawMaterialType } from '../../../utils/types';
import AddOrEditRawMaterialForm from './AddOrEditRawMaterialForm';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';

/* loading get all raw material query */
const getAllRawMaterialQuery = loader('../../../graphql/queries/getAllRawMaterialsQuery.graphql');

/* loading delete raw material mutation  */
const deleteRawMaterialMutation = loader(
  '../../../graphql/mutations/deleteRawMaterialMutation.graphql',
);

/* React functional component */
const ViewAllRawMaterialsScreen = () => {
  /* state used to store id of the raw material whose 'delete' button is clicked which is then used to show loading indicator on delete button */
  const [isDeletingRawMaterialLoading, setDeletingRawMaterialLoading] = useState<number | null>(
    null,
  );

  /* state used to store raw material data whose 'edit' button clicked and to manage form modal visibility */
  const [rawMaterialDataAndIsFormVisible, setRawMaterialDataAndIsFormVisible] =
    useState<AddOrEditDataModalType>(null);

  /* get all raw material data query */
  const {
    data: getAllRawMaterialData,
    loading: getAllRawMaterialLoading,
    error: getAllRawMaterialError,
  } = useQuery<GetAllRawMaterialsQuery, GetAllRawMaterialsQueryVariables>(getAllRawMaterialQuery);

  /* delete a raw material mutation */
  const [deleteRawMaterial] = useMutation<
    DeleteRawMaterialMutation,
    DeleteRawMaterialMutationVariables
  >(deleteRawMaterialMutation);

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getAllRawMaterialError) {
    return <div className="errorText">{getAllRawMaterialError.message}</div>;
  }

  /* function to handle delete raw material */
  const deleteRawMaterialFunc = (rawMaterialId: number) => {
    setDeletingRawMaterialLoading(rawMaterialId);
    deleteRawMaterial({
      variables: {
        id: rawMaterialId,
      },
      /* after deleting a raw material, the update function is used to update the cache and display an updated raw materials array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a raw material that was just removed. */
        const deletedDataId = deleteData?.deleteRawMaterial?.id;
        cache.modify({
          fields: {
            rawMaterials(existingRawMaterials: Array<Reference>, { readField }) {
              if (deletedDataId) {
                return existingRawMaterials.filter(
                  (rawMaterialRef) => deletedDataId !== readField('id', rawMaterialRef),
                );
              }
              return existingRawMaterials;
            },
          },
        });
      },
    })
      .then(() => {
        setDeletingRawMaterialLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Raw material has been deleted successfully.');
      })
      .catch((deleteRawMaterialError: ApolloError) => {
        let errorMsg = deleteRawMaterialError.message;
        setDeletingRawMaterialLoading(null);
        if (deleteRawMaterialError.message.includes('Foreign key violation.')) {
          errorMsg = 'Cannot delete since raw material is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteRawMaterialError);
      });
  };

  /* function used to close the visibility of modal by setting state value to null. */
  const closeAddOrEditRawMaterialForm = () => {
    setRawMaterialDataAndIsFormVisible(null);
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          setRawMaterialDataAndIsFormVisible({ mode: 'add' });
        }}
      >
        Create raw material
      </Button>
      <Table<RawMaterialType>
        dataSource={getAllRawMaterialData && getAllRawMaterialData.getAllRawMaterials}
        loading={getAllRawMaterialLoading}
        className="tableStyle"
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<RawMaterialType>
          key="name"
          title="Raw Material Name"
          dataIndex="name"
          align="center"
          onFilter={(value, record) => {
            return record.name
              ? record.name.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="raw material name"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<RawMaterialType>
          key="type"
          title="Type"
          dataIndex="type"
          align="center"
          render={(rawMaterialType) => {
            return <span style={{ textTransform: 'capitalize' }}>{rawMaterialType || '-'}</span>;
          }}
        />
        <Table.Column<RawMaterialType>
          key="linkedProductTypes"
          title="Linked Product Types"
          dataIndex="linkedProductTypes"
          align="center"
          render={(text, record) => {
            if (
              Array.isArray(record.rawMaterialsProductTypes) &&
              record.rawMaterialsProductTypes.length > 0
            ) {
              const { rawMaterialsProductTypes } = record;
              return rawMaterialsProductTypes.map((product, index) => {
                return (
                  <span key={`${product.productType.id} ${product.productType.name}`}>
                    {product.productType.name}
                    <span>
                      {index !== record.rawMaterialsProductTypes.length - 1 ? '; ' : null}
                    </span>
                  </span>
                );
              });
            }
            return '-';
          }}
        />
        <Table.Column<RawMaterialType>
          key="Actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    setRawMaterialDataAndIsFormVisible({
                      mode: 'edit',
                      dataToEdit: record,
                    });
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete Raw Material. Are you sure?"
                  okText="Yes"
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                  onConfirm={() => {
                    deleteRawMaterialFunc(record.id);
                  }}
                >
                  <Button
                    className="deleteButton"
                    loading={record.id === isDeletingRawMaterialLoading}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
      {rawMaterialDataAndIsFormVisible ? (
        <AddOrEditRawMaterialForm
          mode={rawMaterialDataAndIsFormVisible.mode}
          rawMaterialDataToEdit={rawMaterialDataAndIsFormVisible.dataToEdit as RawMaterialType}
          closeModal={closeAddOrEditRawMaterialForm}
        />
      ) : null}
    </>
  );
};

export default ViewAllRawMaterialsScreen;
