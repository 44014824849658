import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Tabs } from 'antd';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

/* outlet context type */
type ContextType = {
  /* this prop type used to store outward shipment id */
  outwardShipmentId: number | null;
  /* this prop type used to assign/set outward shipment id */
  setOutwardShipmentId: (id: number | null) => void;
};

/* react function component */
const OutwardProductTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();
  /* useLocation destructuring */
  const location = useLocation();

  /* this state used to store outward shipment id, which is then used to pass this state to 'tab screens' vai "Outlet context" */
  const [outwardShipmentId, setOutwardShipmentId] = useState<number | null>(null);

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('orders')) {
      return 'shipmentOrders';
    }
    if (location.pathname.includes('loading')) {
      return 'loadingShipments';
    }
    if (location.pathname.includes('truckOut')) {
      return 'truckOutward';
    }
    if (location.pathname.includes('invoicing')) {
      return 'shipmentInvoice';
    }
    return 'createShipment';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="shipmentInvoice"
        onTabClick={(key: string) => {
          /* on tab change, set outward shipment id to null, so that we can set outward shipment id form each tab */
          if (outwardShipmentId) {
            setOutwardShipmentId(null);
          }
          if (key === 'createShipment') {
            navigate('create');
          } else if (key === 'shipmentOrders') {
            navigate('orders');
          } else if (key === 'loadingShipments') {
            navigate('loading');
          } else if (key === 'truckOutward') {
            navigate('truckOut');
          } else if (key === 'shipmentInvoice') {
            navigate('invoicing');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab=" Create Shipment" key="createShipment">
          <Outlet
            /* pass this outward shipment id's state to "CreateProductOutwardShipmentScreen" vai outlet context */
            context={{
              outwardShipmentId,
              setOutwardShipmentId: (id: number | null) => {
                setOutwardShipmentId(id);
              },
            }}
          />
        </TabPane>
        <TabPane tab="Shipment Orders" key="shipmentOrders">
          <Outlet
            /* pass this outward shipment id's state to "OutwardProductShipmentOrderScreen" vai outlet context */
            context={{
              outwardShipmentId,
              setOutwardShipmentId: (id: number | null) => {
                setOutwardShipmentId(id);
              },
            }}
          />
        </TabPane>
        <TabPane tab="Loading" key="loadingShipments">
          <Outlet
            /* pass this outward shipment id's state to "OutwardProductLoadingScreen" vai outlet context */
            context={{
              outwardShipmentId,
              setOutwardShipmentId: (id: number | null) => {
                setOutwardShipmentId(id);
              },
            }}
          />
        </TabPane>
        <TabPane tab="Truck Outward" key="truckOutward">
          <Outlet
            /* pass this outward shipment id's state to "OutwardProductTruckScreen" vai outlet context */
            context={{
              outwardShipmentId,
              setOutwardShipmentId: (id: number | null) => {
                setOutwardShipmentId(id);
              },
            }}
          />
        </TabPane>
        <TabPane tab="Invoicing" key="shipmentInvoice">
          <Outlet
            /* pass this outward shipment id's state to "OutwardProductInvoicingScreen" vai outlet context */
            context={{
              outwardShipmentId,
              setOutwardShipmentId: (id: number | null) => {
                setOutwardShipmentId(id);
              },
            }}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OutwardProductTabLayout;

/* exporting this outlet context function, to pass outward shipment id state to the screens, which are rendering on tab.
so that we can access, update(set) & use this state from tab screen */
export function useOutwardShipmentOutlet() {
  return useOutletContext<ContextType>();
}
