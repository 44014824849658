import React from 'react';
import {
  GetAllInwardShipmentForOtherItemsQuery,
  GetAllInwardShipmentForOtherItemsQueryVariables,
} from '../../../../graphql/graphql-types';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Spin } from 'antd';
import InwardShipmentBagOrOtherItemReportTable from '../../InwardShipmentBagOrOtherItemReportTable';
import { InwardShipmentsReportType } from '../../../../utils/types';

/* loading get all inward other items shipment data query */
const getAllOtherItemsQuery = loader(
  '../../../../graphql/queries/getAllInwardShipmentForOtherItemsQuery.graphql',
);

/* react functional component */
const InwardShipmentOtherItem = () => {
  /* get all other item shipment data query */
  const {
    data: getAllOtherItemsShipmentData,
    loading: getAllOtherItemsShipmentDataLoading,
    error: getAllOtherItemsShipmentDataError,
  } = useQuery<
    GetAllInwardShipmentForOtherItemsQuery,
    GetAllInwardShipmentForOtherItemsQueryVariables
  >(getAllOtherItemsQuery, {
    variables: {
      createdAt: {},
    },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getAllOtherItemsShipmentDataLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllOtherItemsShipmentDataError) {
    return <div className="errorText">{getAllOtherItemsShipmentDataError.message}</div>;
  }

  return (
    <>
      {getAllOtherItemsShipmentData ? (
        <InwardShipmentBagOrOtherItemReportTable
          data={getAllOtherItemsShipmentData.inwardShipments as InwardShipmentsReportType[]}
          inwardShipmentType="otherItems"
        />
      ) : null}
    </>
  );
};

export default InwardShipmentOtherItem;
