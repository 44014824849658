import React, { useRef } from 'react';
import { Button, Col, Descriptions, Row, Space, Spin, Table } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetInwardShipmentByIdQueryVariables,
  GetInwardShipmentByIdQuery,
} from '../../../graphql/graphql-types';
import dayjs from 'dayjs';
import {
  InwardShipmentItemsType,
  InwardShipmentsReportType,
  ItemsShipment,
} from '../../../utils/types';
import logo from '../../../assets/images/sarda_group_logo.png';
import { useReactToPrint } from 'react-to-print';
import SignatureComponent from '../../../components/SignatureComponent';

/* loading get inward shipment by Id query */
const getInwardShipmentByIdQuery = loader(
  '../../../graphql/queries/getInwardShipmentByIdQuery.graphql',
);

/* inward shipment print screen prop type */
type InwardShipmentPrintPropType = {
  /* this prop use to store inward shipment id when print button is click which further used as query variable to fetch inward Shipment data  and this is optional prop*/
  printId?: number | null;
  /* this is optional prop and When only paddy screen is available, this prop is used and it is called from the raw material in the inward shipment report.*/
  isCalledFromSummaryModal?: boolean;
  /* prop to pass reference for print */
  printReference?: React.LegacyRef<HTMLDivElement> | undefined;
};

/* type for inward shipment print table */
type InwardShipmentPrintTableType = Omit<
  InwardShipmentItemsType,
  'rawMaterialName' | 'emptyBagWtKg' | 'priceOfPaddyPerQuintal' | 'brokeragePricePerQuintal'
> & {
  rawMaterial: {
    name: string;
  };
  emptyBagsWtKg: number;
  pricePerKg: number;
  brokeragePerQuintal: number;
};

/* react functional component */
const InwardShipmentPrintScreen = ({
  printId = null,
  isCalledFromSummaryModal = false,
  printReference,
}: InwardShipmentPrintPropType): JSX.Element => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* extracting location from useLocation hook */
  const location = useLocation();

  /** const to check whether path includes 'monitor' or not */
  const isMonitorShipments = location.pathname.includes('monitor');

  /** const to store reference which is used to print content */
  const printRef = useRef(null);

  /** const used to call 'react-to-print' print method*/
  const handlePrint = useReactToPrint({
    /* content for printing */
    content: () => printRef.current,
    /* styling for page during print */
    pageStyle: () => `
      @media print {
        .inwardShipmentSummaryDiv {
          margin: 0 !important;
        }

        .pageSummaryDiv  {
         margin-top: 400px !important;
         display: block !important;
         break-inside: avoid !important;

         .printOfficeCopyHeader {
          display: none !important;
        }

        .printSellerCopyHeader {
          display: block !important;
        }
       }
      }
    `,
  });

  /* get inward shipment by ID query */
  const {
    data: getInwardShipmentByIdData,
    loading: getInwardShipmentByIdDataLoading,
    error: getInwardShipmentByIdDataError,
  } = useQuery<GetInwardShipmentByIdQuery, GetInwardShipmentByIdQueryVariables>(
    getInwardShipmentByIdQuery,
    {
      variables: { id: params.id ? Number(params.id) : Number(printId) },
    },
  );

  /* show loading indicator on the screen until data get fetch from the server */
  if (getInwardShipmentByIdDataLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getInwardShipmentByIdDataError) {
    return <div className="errorText">{getInwardShipmentByIdDataError.message}</div>;
  }

  /* destructuring inward shipment data */
  if (getInwardShipmentByIdData) {
    const {
      grn,
      createdAt,
      driverName,
      items,
      deliveryPersonMobile,
      vehicleNumber,
      weighbridgeCharges,
      laborChargePerBag,
      laborChargeType,
      fullVehicleWtKg,
      emptyVehicleWtKg,
    } = getInwardShipmentByIdData.getInwardShipmentById as InwardShipmentsReportType;

    /* totalAmount is used Stored total amount value */
    let totalAmount = 0;

    /* grossAmount is used Stored gross Amount */
    let grossAmount = 0;

    /* labourCharges is used Stored labour Charges */
    let labourCharges = 0;

    /* sellerName stored  item seller name array */
    const sellerName: Array<string> = [];

    /*  if weighbridgeCharges is defined then minus it from totalAmount and stored on totalAmount */
    if (weighbridgeCharges) {
      totalAmount -= weighbridgeCharges;
    }

    /* if types is paddy then calculated all values */
    if (isCalledFromSummaryModal || params.type === 'paddy') {
      items.forEach((item) => {
        if (item.id) {
          const {
            bagsCount,
            seller,
            netMaterialWtPerBagKg,
            emptyBagCost,
            pricePerKg,
            brokeragePerQuintal,
          } = item;

          /* push name of sellers into seller name array, which is used further to display seller names  */
          if (seller && !sellerName.includes(seller.name)) {
            sellerName.push(seller.name);
          }

          /* const used to store number of bags of shipment */
          const numberOfBags = bagsCount ? bagsCount : 0;

          /* const used to store total net material weight */
          const totalNetMaterialWeight =
            numberOfBags * (netMaterialWtPerBagKg ? netMaterialWtPerBagKg : 0);

          /* const used to store total new material weight in quintal  */
          const totalNetMaterialWeightInQt = parseFloat((totalNetMaterialWeight / 100).toFixed(2));

          /* const used to store price of total weight */
          const priceOfTotalWt = totalNetMaterialWeightInQt * ((pricePerKg ? pricePerKg : 0) * 100);

          /* const used to store total price of empty bags weight */
          const priceOfTotalEmptyBagWt = (emptyBagCost ? emptyBagCost : 0) * numberOfBags;

          /* const used to store price of brokerage */
          const priceOfBrokerage =
            (brokeragePerQuintal ? brokeragePerQuintal : 0) * totalNetMaterialWeightInQt;

          /* const used to store sub total */
          const subtotal = parseFloat(
            (priceOfTotalWt + priceOfBrokerage - priceOfTotalEmptyBagWt).toFixed(2),
          );

          /* const used to store total amount */
          totalAmount += subtotal;

          /* const used to store gross amount */
          grossAmount += subtotal;

          if (!item.materialReturned && laborChargeType) {
            /* const used o store labor per bag charge */
            const laborChargePerBagValue = laborChargePerBag ? laborChargePerBag : 0;

            if (laborChargeType === 'add') {
              /* total amount for labour charge type 'add' */
              totalAmount += numberOfBags * laborChargePerBagValue;
              /* labour charges for labour charge type 'add' */
              labourCharges += numberOfBags * laborChargePerBagValue;
            } else {
              /* total amount for labour charge type 'deduct' */
              totalAmount -= numberOfBags * laborChargePerBagValue;
              /* labour charges for labour charge type 'deduct' */
              labourCharges -= numberOfBags * laborChargePerBagValue;
            }
          }
        }
      });
    }

    /* if materialReturned is false then stored that items array in filteredItemData passed to table data */
    const filteredItemData = items.filter((item) => !item.materialReturned);

    // variable used to total number of bags in a shipment
    const totalNumberOfBags = filteredItemData.reduce((acc, data) => {
      return acc + (data.bagsCount || 0);
    }, 0);

    const millDetails =
      getInwardShipmentByIdData &&
      getInwardShipmentByIdData.mills &&
      getInwardShipmentByIdData.mills[0];

    /** const to store print layout structure in 'Inward Shipments' */
    const inwardShipmentPrintLayout = (
      <div
        style={{ margin: isCalledFromSummaryModal ? 0 : '0px 100px 0px 100px  ' }}
        className="inwardShipmentSummaryDiv"
      >
        <div className="printModal">
          <Row style={{ textAlign: 'center', alignItems: 'center' }}>
            <Col span={6}>
              <img src={logo} alt="logo" width={80} height={72} />
            </Col>
            <Col span={13}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: 10 }}>|| Shri Ganeshay Namah ||</span>
                <b style={{ fontSize: 17 }} className="millNameStyle">
                  {millDetails && millDetails.company_name
                    ? millDetails.company_name.toUpperCase()
                    : ''}
                </b>
                <span>{(millDetails && millDetails.address) || ''}</span>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  <span>
                    <b>GSTIN : </b> 27AAFFB7204E1ZU
                  </span>
                  <span>
                    <b>FSSAI :</b>
                    {millDetails &&
                    millDetails.name &&
                    millDetails.name.toLowerCase().includes('bhagwati')
                      ? 11517059000099
                      : 11521999000463}
                  </span>
                </div>
              </div>
            </Col>
            <Col
              span={24}
              style={{ fontWeight: 'bold', fontSize: 14, marginTop: 10, textAlign: 'center' }}
              className="printBillInUIHeader"
            >
              {isCalledFromSummaryModal || params.type === 'paddy'
                ? 'Inward Shipment Summary'
                : 'Purchase Bill'}
            </Col>
            <Col
              span={24}
              style={{
                fontWeight: 'bold',
                fontSize: 14,
                marginTop: 10,
                textAlign: 'center',
                display: 'none',
              }}
              className="printOfficeCopyHeader"
            >
              {isCalledFromSummaryModal || params.type === 'paddy'
                ? 'Inward Shipment Summary'
                : 'Purchase Bill'}{' '}
              (Office Copy)
            </Col>
            <Col
              span={24}
              style={{
                fontWeight: 'bold',
                fontSize: 14,
                marginTop: 10,
                textAlign: 'center',
                display: 'none',
              }}
              className="printSellerCopyHeader"
            >
              {isCalledFromSummaryModal || params.type === 'paddy'
                ? 'Inward Shipment Summary'
                : 'Purchase Bill'}{' '}
              (Seller Copy)
            </Col>
            {isCalledFromSummaryModal || params.type === 'paddy' ? (
              <Col>
                <Row>
                  <Col span={16}>
                    <Descriptions column={1} className="printDescriptions">
                      <Descriptions.Item label="Bill No.">{grn}</Descriptions.Item>
                      <Descriptions.Item label="Billing Date">
                        {dayjs().format('DD-MM-YYYY')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Seller Name">
                        {sellerName.length === 0 ? '-' : sellerName.map((name) => `${name}`)}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={8}>
                    <Descriptions column={1} className="printDescriptions">
                      <Descriptions.Item label="Invoice Date">
                        {createdAt ? dayjs(createdAt as Date).format('DD-MM-YYYY') : null}
                      </Descriptions.Item>
                      <Descriptions.Item label="Driver Name">{driverName}</Descriptions.Item>
                      <Descriptions.Item label="Vehicle No">
                        {vehicleNumber || '-'}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Col span={24}>
                  <Table
                    dataSource={filteredItemData || []}
                    pagination={false}
                    bordered
                    size="small"
                    rowKey="id"
                    className="tableStyle historyTable"
                    style={{ marginTop: 40 }}
                  >
                    <Table.Column<InwardShipmentPrintTableType>
                      title={
                        <>
                          Paddy type
                          <span className="tableColumnNameStyle">(Grade)</span>
                          <br />
                          No. Of Bags
                        </>
                      }
                      align="center"
                      dataIndex="paddyTypesAndNoOfBags"
                      key="paddyTypesAndNoOfBags"
                      render={(text, record) => {
                        return (
                          <>
                            {record.rawMaterial.name} (
                            {record.paddyGrade === 'rice'
                              ? 'rice'
                              : (record.paddyGrade as string).replace(/[^\d]/g, '')}
                            ) - {record.materialReturned !== true ? record.bagsCount : null}
                          </>
                        );
                      }}
                    />
                    <Table.Column<InwardShipmentPrintTableType>
                      title={
                        <>
                          Empty Bags Returned <br />
                          Empty Bag Weight (kgs.)
                        </>
                      }
                      align="center"
                      dataIndex="emptyBagsReturnedAndWeight"
                      key="emptyBagsReturnedAndWeight"
                      render={(text, record) => {
                        return (
                          <>
                            {record.emptyBagsReturned ? 'Yes' : 'No'}
                            <br />
                            {record.emptyBagsWtKg}
                            {record.bagWeightType === 'kgPerBag' ? ' per bag' : ' per quintal'}
                          </>
                        );
                      }}
                    />
                    <Table.Column<InwardShipmentPrintTableType>
                      title={
                        <>
                          Net Material Weight
                          <div className="tableColumnNameStyle">(kgs per bag)</div>
                        </>
                      }
                      align="center"
                      dataIndex="netMaterialWeight"
                      key="netMaterialWeight"
                      render={(text, record) => {
                        if (!record.materialReturned) {
                          return (
                            <>
                              {(record.netMaterialWtPerBagKg &&
                                record.netMaterialWtPerBagKg.toFixed(2)) ||
                                0}
                            </>
                          );
                        }
                        return '-';
                      }}
                    />
                    <Table.Column<InwardShipmentPrintTableType>
                      title={
                        <>
                          Total Net Material Weight
                          <br />
                          <span className="tableColumnNameStyle">(quintals)</span>
                        </>
                      }
                      align="center"
                      dataIndex="totalNetMaterialWeight"
                      key="totalNetMaterialWeight"
                      render={(text, record) => {
                        const totalMaterialWeight =
                          (record.bagsCount * record.netMaterialWtPerBagKg) / 100;

                        if (!record.materialReturned) {
                          return <>{totalMaterialWeight && totalMaterialWeight.toFixed(2)}</>;
                        }
                        return '-';
                      }}
                    />
                    <Table.Column<InwardShipmentPrintTableType>
                      title={
                        <>
                          Price Of Paddy
                          <div className="tableColumnNameStyle">(Rs. per quintal)</div>
                        </>
                      }
                      align="center"
                      dataIndex="priceOfPaddy"
                      key="priceOfPaddy"
                      render={(text, record) => {
                        if (!record.materialReturned) {
                          const priceOfPaddyPerQuintal = (record.pricePerKg || 0) * 100;
                          return priceOfPaddyPerQuintal.toFixed(2);
                        }
                        return '-';
                      }}
                    />
                    <Table.Column<InwardShipmentPrintTableType>
                      title={
                        <>
                          Brokerage
                          <div className="tableColumnNameStyle">(Rs. per quintal)</div>
                        </>
                      }
                      align="center"
                      dataIndex="brokerage"
                      key="brokerage"
                      render={(text, record) => {
                        if (!record.materialReturned) {
                          return (
                            <>
                              {record.brokeragePerQuintal
                                ? record.brokeragePerQuintal.toFixed(2)
                                : 0}
                            </>
                          );
                        }
                        return '-';
                      }}
                    />
                    <Table.Column<InwardShipmentPrintTableType>
                      title={
                        <>
                          Sub-Total
                          <div className="tableColumnNameStyle">(Rs.)</div>
                        </>
                      }
                      align="center"
                      dataIndex="subTotal"
                      key="subTotal"
                      render={(text, record) => {
                        /* const used to store total number of bags in shipment */
                        const numberOfBags = record.bagsCount ? record.bagsCount : 0;

                        /* const used to store total material weight in kgs */
                        const totalNetMaterialWeightInKg =
                          numberOfBags *
                          (record.netMaterialWtPerBagKg ? record.netMaterialWtPerBagKg : 0);

                        // TODO : Check toFixed(2)
                        /* calculated total net material weight in Qt */
                        const totalNetMaterialWeightInQt = parseFloat(
                          (totalNetMaterialWeightInKg / 100).toFixed(2),
                        );

                        /* calculate price of paddy per quintal  */
                        const priceOfPaddyPerQuintal =
                          (record.pricePerKg ? record.pricePerKg : 0) * 100;

                        /* calculated price of total weight */
                        const priceOfTotalWt = totalNetMaterialWeightInQt * priceOfPaddyPerQuintal;

                        /* calculated price of total empty bag weight */
                        const priceOfTotalEmptyBagWt =
                          (record.emptyBagCost ? record.emptyBagCost : 0) * numberOfBags;

                        /* calculated price of brokerage */
                        const priceOfBrokerage =
                          (record.brokeragePerQuintal ? record.brokeragePerQuintal : 0) *
                          totalNetMaterialWeightInQt;

                        /* calculated sub total */
                        const subtotal = parseFloat(
                          (priceOfTotalWt + priceOfBrokerage - priceOfTotalEmptyBagWt).toFixed(2),
                        );
                        if (!record.materialReturned) {
                          return (subtotal && subtotal.toFixed(2)) || 0;
                        }
                        return '-';
                      }}
                    />
                  </Table>
                </Col>
                <Row style={{ textAlign: 'center', alignItems: 'center' }}>
                  <Col span={16} className="printDescriptions">
                    <Descriptions column={1} size="small">
                      <Descriptions.Item label="Total No. of bags">
                        {totalNumberOfBags || 0}
                      </Descriptions.Item>
                      <Descriptions.Item label="Gross Wt. (kgs)">
                        {fullVehicleWtKg && fullVehicleWtKg.toFixed(2)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Tare Wt. (kgs)">
                        {(emptyVehicleWtKg && emptyVehicleWtKg.toFixed(2)) || '-'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Net Wt. (kgs)">
                        {(fullVehicleWtKg &&
                          emptyVehicleWtKg &&
                          (fullVehicleWtKg - emptyVehicleWtKg).toFixed(2)) ||
                          '-'}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={8}>
                    <Descriptions column={1} size="small" className="printDescriptions">
                      <Descriptions.Item label="Gross Amount (in Rs.)">
                        {grossAmount === 0 ? grossAmount : grossAmount.toFixed(2)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Weighbridge Charges (in Rs.)">
                        -{weighbridgeCharges && weighbridgeCharges.toFixed(2)}
                      </Descriptions.Item>
                      <Descriptions.Item label="Labor charge type">
                        {laborChargeType === 'add' ? 'Add' : 'Deduct'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Labor charges (in Rs.)">
                        {laborChargeType === 'add'
                          ? `+${labourCharges && labourCharges.toFixed(2)}`
                          : `${labourCharges && labourCharges.toFixed(2)}`}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label="Net Total Amount (in Rs.)"
                        contentStyle={{ fontWeight: 500 }}
                      >
                        {totalAmount === 0 ? totalAmount : totalAmount.toFixed(2)}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Col>
            ) : (
              <>
                <Row>
                  <Col span={16}>
                    <Descriptions column={1} className="printDescriptions">
                      <Descriptions.Item label="Bill No.">{grn}</Descriptions.Item>
                      <Descriptions.Item label="Billing Date">
                        {dayjs().format('DD-MM-YYYY')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Vendor Name">
                        {Array.isArray(items) && items.length > 0
                          ? items[0].seller
                            ? items[0].seller.name
                            : '-'
                          : null}
                      </Descriptions.Item>
                      <Descriptions.Item label="Vehicle No">
                        {vehicleNumber || '-'}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                  <Col span={8}>
                    <Descriptions column={1} className="printDescriptions">
                      <Descriptions.Item label="Invoice Date">
                        {createdAt ? dayjs(createdAt as Date).format('DD-MM-YYYY') : null}
                      </Descriptions.Item>
                      <Descriptions.Item label="Delivery Person Name">
                        {driverName}
                      </Descriptions.Item>
                      <Descriptions.Item label="Delivery Person Mobile">
                        {deliveryPersonMobile || '-'}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>

                  <Col
                    span={24}
                    style={{ fontWeight: 500, fontSize: 14, marginTop: 10, textAlign: 'left' }}
                    className="boldContentInPrint"
                  >
                    Item Details
                  </Col>

                  <Col span={24}>
                    <Table<ItemsShipment>
                      dataSource={items || []}
                      size="small"
                      rowKey="id"
                      className="tableStyle historyTable"
                      bordered
                      pagination={false}
                    >
                      <Table.Column<ItemsShipment>
                        title="Item Name"
                        render={(val, record) => {
                          if (record.item) {
                            return record.item.name;
                          } else if (record.othersItemName) {
                            return record.othersItemName;
                          } else {
                            return '-';
                          }
                        }}
                      />
                      <Table.Column<ItemsShipment>
                        title={
                          isCalledFromSummaryModal || params.type === 'bags' ? 'No.of Bags' : 'Qty'
                        }
                        render={(val, record) => {
                          return isCalledFromSummaryModal || params.type === 'bags'
                            ? record.bagsCount
                            : record.othersQuantity || '-';
                        }}
                      />
                      <Table.Column<ItemsShipment>
                        title="Destination"
                        render={(val, record) => {
                          return record.destination === 'godown' && record.godown
                            ? record.godown.name
                            : record.destination;
                        }}
                      />
                      <Table.Column<ItemsShipment>
                        title={
                          isCalledFromSummaryModal || params.type === 'bags'
                            ? ' Back From Maintenance?'
                            : 'Weight (in kgs)'
                        }
                        render={(val, record) => {
                          return isCalledFromSummaryModal || params.type === 'bags'
                            ? record.isBackFromMaintenance
                              ? 'Yes'
                              : 'No'
                            : record.othersWtKg || '-';
                        }}
                      />
                    </Table>
                  </Col>
                </Row>
              </>
            )}
          </Row>
          <SignatureComponent />
        </div>
      </div>
    );

    return (
      <Space
        direction="vertical"
        size={20}
        className="inwardShipmentPrint"
        style={{ marginTop: 20 }}
      >
        <div className="printRefDiv" ref={printReference || printRef}>
          {inwardShipmentPrintLayout}
          <div
            className={
              isCalledFromSummaryModal || isMonitorShipments
                ? 'printDuplicateBill'
                : 'pageSummaryDiv'
            }
            style={{ display: 'none' }}
          >
            {inwardShipmentPrintLayout}
          </div>
        </div>
        {!isCalledFromSummaryModal ? (
          <Row>
            <Col offset={20}>
              <Button
                type="primary"
                onClick={() => {
                  handlePrint();
                }}
                style={{
                  marginTop: 10,
                  marginBottom: 30,
                }}
              >
                Print
              </Button>
            </Col>
          </Row>
        ) : null}
      </Space>
    );
  }
  return <div />;
};

export default InwardShipmentPrintScreen;
