import React, { useState } from 'react';
import { ApolloError, useApolloClient, useMutation, useQuery, gql } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  Button,
  Descriptions,
  DescriptionsProps,
  Divider,
  message,
  Modal,
  Popconfirm,
  Spin,
  Table,
} from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  DeleteInwardShipmentItemMutation,
  DeleteInwardShipmentItemMutationVariables,
  DeleteInwardShipmentMutation,
  DeleteInwardShipmentMutationVariables,
  Enum_Empty_Bag_Wt_Unit_Enum,
  Enum_Source_Destination_Enum,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
  GetInwardShipmentByIdModifyGrnQuery,
  GetInwardShipmentByIdModifyGrnQueryVariables,
  InwardShipments,
  Maybe,
} from '../../../../graphql/graphql-types';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowLeftOutlined, PlusOutlined, WarningFilled } from '@ant-design/icons';
import {
  dateFormatFunc,
  defaultMillId,
  inputComponentCommonStyle,
} from '../../../../utils/globals';
import FormItem from '../../../../components/FormItem';
import InputNumber from '../../../../components/InputNumber';
import Input from '../../../../components/Input';
import RadioGroup from '../../../../components/RadioGroup';
import AddOrEditCompletedShipmentItemForm from './AddOrEditCompletedShipmentItemForm';
import colors from '../../../../scss/variables.module.scss';
import {
  CompletedShipmentItemType,
  InwardShipmentsReportWithExtraFieldType,
  ItemsShipment,
} from '../../../../utils/types';
import { logger, convertKgsToQuintals } from '../../../../utils/helpers';
import Switch from '../../../../components/Switch';
import differenceInWeightCalculation from '../../../../utils/helpers/differenceInWeightCalculation';

/* inward shipment GRN data type */
type InwardShipmentGRNDataType = Pick<
  InwardShipments,
  | 'grn'
  | 'id'
  | 'completedAt'
  | 'createdAt'
  | 'driverName'
  | 'emptyVehicleImage'
  | 'fullVehicleImage'
  | 'laborChargeType'
  | 'status'
  | 'vehicleNumber'
> & {
  /* this prop type used to store shipment items */
  items: Array<ItemsShipment>;
} & {
  /* empty vehicle weight */
  emptyVehicleWtKg: number | null;
  /* full vehicle weight */
  fullVehicleWtKg: number | null;
  /* weighbridge charges */
  weighbridgeCharges: number | null;
  /* labour charges */
  laborChargePerBag: number | null;
  /* prop type for billing details */
  billing_details?: InwardShipmentsReportWithExtraFieldType['billing_details'];
};

/* edit completed shipment form type */
type EditCompletedShipmentFormType = Pick<
  InwardShipments,
  'vehicleNumber' | 'driverName' | 'laborChargeType'
> & {
  /* full vehicle weight */
  fullVehicleWtKg?: number | null;
  /* empty vehicle weight */
  emptyVehicleWtKg?: number | null;
  /* weighbridge charges */
  weighbridgeCharges?: number | null;
  /* labour charges */
  laborChargePerBag?: number | null;
  /* prop type for bill send by party or not */
  isBillSendByParty?: boolean;
  /* prop type for bill number */
  billNumber?: string | null;
  /* prop type for net weight in quintals */
  netWeightInQuintals?: number | null;
  /* prop type for broker */
  broker?: string | null;
  /* prop type for additional information */
  additionalInformation?: string | null;
};

/* new inward shipment item type */
type NewShipmentItemType = {
  /* inward shipment id */
  inwardShipmentId: number;
  /* seller id */
  rmSellerId: number | null;
  /* raw material id */
  rawMaterialId: number | null;
  /* godown id */
  godownId: number | null;
  /* paddy grade */
  paddyGrade: Maybe<string> | undefined;
  /* prop type used to store number of bags */
  bagsCount: Maybe<number> | undefined;
  /* prop type used to store material returned or not */
  materialReturned: Maybe<boolean> | undefined;
  /* prop type used to store empty bags returned or not */
  emptyBagsReturned: Maybe<boolean> | undefined;
  /* prop type used to store empty bag cost */
  emptyBagCost: number | null | undefined;
  /* prop type used to store empty bag weight in kg */
  emptyBagsWtKg: number | null | undefined;
  /* prop type used to store price of material per kg */
  pricePerKg: number | null;
  /* prop type used to store empty bag weight unit */
  userEmptyBagWtUnit: Maybe<Enum_Empty_Bag_Wt_Unit_Enum> | undefined;
  /* prop type used to store brokerage per quintal rate */
  brokeragePerQuintal: number | null | undefined;
  /* prop type used to store material return reason */
  materialReturnedReason: Maybe<string> | undefined;
  /* prop type used to store destination of material unloading */
  destination: Maybe<Enum_Source_Destination_Enum> | undefined;
  /* prop type used to store mill id */
  millId: number | null;
  /* prop type used to store net material weight in kg */
  netMaterialWtPerBagKg: number;
};

/* loading get inward shipment by Id query */
const getInwardShipmentByIdModifyGrnQuery = loader(
  '../../../../graphql/queries/getInwardShipmentByIdModifyGrnQuery.graphql',
);

/* loading delete inward shipment item mutation  */
const deleteInwardShipmentItemMutation = loader(
  '../../../../graphql/mutations/deleteInwardShipmentItemMutation.graphql',
);

/* delete inward shipment mutation */
const deleteInwardShipmentMutation = loader(
  '../../../../graphql/mutations/deleteInwardShipmentMutation.graphql',
);

/* loading get all inward raw material shipment data query */
const getAllInwardRawMaterialQuery = loader(
  '../../../../graphql/queries/getAllInwardShipmentForRawMaterialQuery.graphql',
);

/* loading get bhagwati mill id query */
const getBhagwatiMillIdQuery = loader('../../../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* styling for Description component*/
const descriptionComponentStyleProps: DescriptionsProps = {
  /* define number of column in row */
  column: 1,
  /* set description components item colon false */
  colon: false,
  /* styling of description component labels */
  labelStyle: { width: '22%' },
  /* description style */
  style: { marginTop: 20 },
};

/* formItem component styling props */
const formItemStyleProp = {
  /* label column span of FormItem */
  labelColSpan: 5,
  /* input column span of FormItem */
  inputColSpan: 5,
  /* formItem label styling */
  labelStyle: { marginRight: 10 },
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field) */
  requiredMark: 'after' as 'after' | 'before',
};

/* useForm schema to validate form fields */
const schema = yup.object({
  vehicleNumber: yup
    .string()
    .nullable()
    .required('Please enter vehicle number and try again')
    .matches(validationRegex.vehicleRegistrationNumber, {
      message: 'Please enter a valid vehicle number',
    }),
  driverName: yup
    .string()
    .nullable()
    .required('Please enter driver name and try and again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  fullVehicleWtKg: yup
    .number()
    .nullable()
    .required('Please enter gross weight (in kgs) and try again.')
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
  emptyVehicleWtKg: yup
    .number()
    .required('Please enter tare weight (in kgs) and try again.')
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
  laborChargePerBag: yup
    .number()
    .required('Please enter labor charges (per bag) and try again.')
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
  weighbridgeCharges: yup
    .number()
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number'),
});

/* react functional component */
const EditCompletedGRNScreen = ({
  editInwardShipmentId,
  closeModal,
}: {
  editInwardShipmentId?: number;
  closeModal?: () => void;
}): JSX.Element => {
  /* extracting param from useParams hook */
  const param = useParams();

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* state used to store id of the shipment item whose delete button is clicked which is then used to show loading indicator on delete button while deleting a shipment item */
  const [shipmentItemIdToDltAndShowLoading, setShipmentItemIdToDltAndShowLoading] = useState<
    number | null
  >(null);

  /* state used to show loading indictor on 'Delete GRN' button, while deleting 'GRN' */
  const [isDeleteGRNLoading, setIsDeleteGRNLoading] = useState<boolean>(false);

  /* this state used to manage AddOrEditShipmentItem modal visibility */
  const [isAddOrEditShipmentItemModalVisible, setIsAddOrEditShipmentItemModalVisible] =
    useState<boolean>(false);

  /* this state used to store shipment item data, whose 'edit' button is clicked , which is then used to send that data to AddOrEditShipmentItem form */
  const [editShipmentItemData, setEditShipmentItemData] = useState<ItemsShipment | null>(null);

  /* this state used to store inward shipment items */
  const [inwardShipmentsItems, setInwardShipmentItems] = useState<Array<CompletedShipmentItemType>>(
    [],
  );

  /* this state used to store array of shipment item id's, which are selected to edit */
  const [editShipmentItemsIds, setEditShipmentItemsIds] = useState<Array<number>>([]);

  // state use to set loading indicator on update GRN button
  const [isUpdateGrnBtnLoading, setIsUpdateGrnBtnLoading] = useState<boolean>(false);

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<EditCompletedShipmentFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      vehicleNumber: null,
      driverName: null,
      laborChargeType: null,
      fullVehicleWtKg: null,
      emptyVehicleWtKg: null,
      weighbridgeCharges: null,
      laborChargePerBag: null,
      isBillSendByParty: false,
      additionalInformation: null,
      billNumber: null,
      broker: null,
      netWeightInQuintals: null,
    },
    mode: 'onChange',
  });

  const inwardShipmentId = editInwardShipmentId ? editInwardShipmentId : Number(param.id);

  /* get inward shipment by modify grn query used to load data */
  const {
    data: getInwardShipmentByIdModifyGrnData,
    loading: getInwardShipmentByIdModifyGrnLoading,
    error: getInwardShipmentByIdModifyGrnError,
  } = useQuery<GetInwardShipmentByIdModifyGrnQuery, GetInwardShipmentByIdModifyGrnQueryVariables>(
    getInwardShipmentByIdModifyGrnQuery,
    {
      variables: { id: inwardShipmentId },
      /* on completion of this query, store shipment items into it's state & set default values to 'edit grn form'*/
      onCompleted: (res) => {
        /* if it has some data, then assign that data as a default values to the 'edit GRN form fields' */
        if (res.getInwardShipmentByIdModifyGrn) {
          /* destructing shipment data */
          const {
            vehicleNumber,
            driverName,
            fullVehicleWtKg,
            emptyVehicleWtKg,
            laborChargePerBag,
            weighbridgeCharges,
            laborChargeType,
            items,
            billing_details: billingDetails,
          } = res.getInwardShipmentByIdModifyGrn as InwardShipmentGRNDataType;

          /* assign that data, as default form field values */
          reset({
            vehicleNumber: vehicleNumber || null,
            driverName: driverName || null,
            laborChargeType: laborChargeType || null,
            fullVehicleWtKg: fullVehicleWtKg,
            emptyVehicleWtKg: emptyVehicleWtKg,
            weighbridgeCharges: weighbridgeCharges,
            laborChargePerBag: laborChargePerBag,
            isBillSendByParty: billingDetails ? true : false,
            billNumber: billingDetails ? billingDetails.bill_number : null,
            netWeightInQuintals: billingDetails ? billingDetails.net_weight : null,
            broker: billingDetails ? billingDetails.broker_name : null,
            additionalInformation: billingDetails ? billingDetails.additional_information : null,
          });

          /* set shipment items state */
          setInwardShipmentItems(items);
        }
      },
    },
  );

  /* get bhagwati mill id query used to show Mill name */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoading,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* delete inward shipment mutation */
  const [deleteInwardShipment] = useMutation<
    DeleteInwardShipmentMutation,
    DeleteInwardShipmentMutationVariables
  >(deleteInwardShipmentMutation);

  /* delete inward shipment item mutation */
  const [deleteInwardShipmentItem] = useMutation<
    DeleteInwardShipmentItemMutation,
    DeleteInwardShipmentItemMutationVariables
  >(deleteInwardShipmentItemMutation);

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getInwardShipmentByIdModifyGrnError || getBhagwatiMillIdQueryError) {
    return (
      <div className="errorText">
        {getInwardShipmentByIdModifyGrnError
          ? getInwardShipmentByIdModifyGrnError.message
          : getBhagwatiMillIdQueryError && getBhagwatiMillIdQueryError.message}
      </div>
    );
  }

  /* const used to store full vehicle weight value, using watch */
  const fullVehicleWtKgWatchedValue = watch('fullVehicleWtKg');

  /* const used to store empty vehicle weight value, using watch */
  const emptyVehicleWtKgWatchedValue = watch('emptyVehicleWtKg');

  /* this function used to calculate net material weight for each shipment item which is newly added/updated */
  const calculateNetMaterialPerBagWtForEachShipmentItem = (
    /* this prop used to store newly added shipment item */
    newlyAddedShipmentItem: ItemsShipment | CompletedShipmentItemType,
    /* this prop used to get total number of bags for newly added item */
    totalBags: number,
    /* this prop store total material weight */
    totalMaterialWtInKg: number,
  ) => {
    /* destructing data form new shipment item */
    const { userEmptyBagWtUnit, emptyBagsWtKg, bagsCount } = newlyAddedShipmentItem;
    /* this const used to calculate weight of material per bag */
    let wtOfMaterialPerBag = 0;
    if (emptyBagsWtKg && userEmptyBagWtUnit && bagsCount) {
      /* const used to store total weight of bags in kg. Doing totalBags check as when material is return total bags count will be 0 and anything divided by 0 is NaN hence to handle NaN we did check*/

      const totalWtOfBagsInKg = totalBags ? (bagsCount * totalMaterialWtInKg) / totalBags : 0;

      /* this const used to store total material weight in quintal */
      const totalMaterialWtInQt = convertKgsToQuintals(totalWtOfBagsInKg);

      /* this const used to store weight of per bag */
      const wtOfPerBag = totalWtOfBagsInKg / bagsCount;

      /* calculate values if empty bag unit selected as 'kg per quintal' */
      if (userEmptyBagWtUnit === 'kgPerQuintal') {
        /* const used to store total weight of empty bags */
        const totalWtOfEmptyBag = emptyBagsWtKg * totalMaterialWtInQt;
        /* const used to store weight of empty bags */
        const wtOfEmptyBag = totalWtOfEmptyBag / bagsCount;
        wtOfMaterialPerBag = wtOfPerBag - wtOfEmptyBag;

        /* return weight of material per bag */
        return wtOfMaterialPerBag;
      }
      /* return weight of material per bag */
      wtOfMaterialPerBag = wtOfPerBag - emptyBagsWtKg;
    }
    /* return weight of material per bag */
    return wtOfMaterialPerBag;
  };

  /* function used to handle form submit */
  const onSubmit = (formData: EditCompletedShipmentFormType) => {
    setIsUpdateGrnBtnLoading(true);
    /* destructing form data */
    const {
      fullVehicleWtKg,
      emptyVehicleWtKg,
      vehicleNumber,
      driverName,
      weighbridgeCharges,
      laborChargePerBag,
      laborChargeType,
      additionalInformation,
      billNumber,
      broker,
      isBillSendByParty,
      netWeightInQuintals,
    } = formData;

    /** const to store billing details  */
    const billDetailsData = isBillSendByParty
      ? {
          bill_number: billNumber || null,
          net_weight: netWeightInQuintals || null,
          broker_name: broker || null,
          additional_information: additionalInformation || null,
        }
      : null;

    /* if one of value between 'full vehicle weight' & 'empty vehicle weight' is not present, then consider that value as 'zero'  */
    /* const used to store full vehicle weight in kg */
    const fullVehicleWeightInKg = fullVehicleWtKg ? fullVehicleWtKg : 0;

    /* const used to store empty vehicle weight in kg */
    const emptyVehicleWeightInKg = emptyVehicleWtKg ? emptyVehicleWtKg : 0;

    /* this const used to store calculated material weight for each shipment item which is newly added/updated. by subtracting full vehicle weight & empty vehicle weight */
    const calculatedMaterialWeightInKg = fullVehicleWeightInKg - emptyVehicleWeightInKg;

    /* this variable used to store total number of bags */
    let totalBags = 0;
    if (Array.isArray(inwardShipmentsItems) && inwardShipmentsItems.length > 0) {
      inwardShipmentsItems.forEach((item) => {
        /* calculate total number of bag, by counting each shipment item bag */
        if (item.materialReturned === false) {
          totalBags += item.bagsCount as number;
        }
      });
    }

    /* this const used to store newly added shipment items array */
    const newlyAddedShipmentItemsArray: Array<NewShipmentItemType> = [];

    /* go through all shipment items, to find which shipment items are newly added */
    inwardShipmentsItems.forEach((item) => {
      /* newly added shipment item has 'new-' string appended before id,if this id present then add/push that shipment item into array */
      if (item.id.toString().match(/new-/g)) {
        /* modify shipment item object according to mutation format, to add that object while submitting/Updating GRN */
        const object = {
          inwardShipmentId: inwardShipmentId,
          rmSellerId: item.seller ? item.seller.id : null,
          rawMaterialId: item.rawMaterial ? item.rawMaterial.id : null,
          godownId: item.godown ? item.godown.id : null,
          paddyGrade: item.paddyGrade,
          bagsCount: item.bagsCount,
          materialReturned: item.materialReturned,
          emptyBagsReturned: item.emptyBagsReturned,
          emptyBagCost: item.emptyBagCost,
          emptyBagsWtKg: item.emptyBagsWtKg,
          pricePerKg: item.pricePerKg ? item.pricePerKg : null,
          userEmptyBagWtUnit: item.userEmptyBagWtUnit,
          brokeragePerQuintal: item.brokeragePerQuintal,
          materialReturnedReason: item.materialReturnedReason,
          destination: item.destination,
          netMaterialWtPerBagKg: calculateNetMaterialPerBagWtForEachShipmentItem(
            item,
            totalBags,
            calculatedMaterialWeightInKg,
          ),
          millId: item.destination === 'mill' ? defaultMillId : null,
        };
        /* push that shipment item into array */
        newlyAddedShipmentItemsArray.push(object);
      }
    });

    /* this string used to store 'add' mutation string, which is required in final mutation */
    let insertInwardShipmentItemMutationString = '';
    /* this string used to store 'update' mutation string, which is required in final mutation */
    let updateInwardShipmentItemMutationString = '';

    /* if user added any new shipment item, then update 'insertInwardShipmentItemMutationString' with insert shipment item mutation string */
    if (newlyAddedShipmentItemsArray.length > 0) {
      /* mutation string updated with insert shipment item mutation string */
      insertInwardShipmentItemMutationString = `insert_inwardShipmentItems(objects: $objects) {
        affected_rows
      }`;
    }

    /* if user has edited some 'shipment items' then execute 'update shipment item mutation' for each of those shipment item  */
    if (editShipmentItemsIds.length > 0) {
      /* edited shipment item id's stored in 'editShipmentItemsIds' array.
       this const used to add update mutation string only for those shipment item ids, which are present in editShipmentItemsIds array */
      const editShipmentItemsArray = inwardShipmentsItems.filter((item) =>
        editShipmentItemsIds.includes(Number(item.id)),
      );

      /* if shipment item data has edited, then execute update shipment item mutation */
      if (editShipmentItemsArray.length > 0) {
        editShipmentItemsArray.forEach((inwardShipmentItem) => {
          /* const used to calculate net material per bag weight */
          const netMaterialWtPerBagKg = calculateNetMaterialPerBagWtForEachShipmentItem(
            inwardShipmentItem,
            totalBags,
            calculatedMaterialWeightInKg,
          );
          /* mutation string to edit existing inwardShipmentItem */
          const updateSingleInwardShipmentItemMutationString = `update_inwardShipmentItem_${
            inwardShipmentItem.id
            /* eslint-disable */
          }: update_inwardShipmentItems_by_pk(pk_columns: {id: ${
            inwardShipmentItem.id
          }}, _set: {rmSellerId: ${
            inwardShipmentItem.seller ? inwardShipmentItem.seller.id : null
          }, rawMaterialId: ${
            inwardShipmentItem.rawMaterial ? inwardShipmentItem.rawMaterial.id : null
          }, paddyGrade: "${inwardShipmentItem.paddyGrade as string}", bagsCount: ${
            inwardShipmentItem.bagsCount
          }, materialReturned: ${inwardShipmentItem.materialReturned}, materialReturnedReason: "${
            inwardShipmentItem.materialReturnedReason
          }", emptyBagsReturned: ${inwardShipmentItem.emptyBagsReturned}, destination: ${
            inwardShipmentItem.destination
          }, godownId: ${inwardShipmentItem.godown && inwardShipmentItem.godown.id},millId: ${
            inwardShipmentItem.mill && inwardShipmentItem.mill.id
          }, userEmptyBagWtUnit: ${inwardShipmentItem.userEmptyBagWtUnit}, brokeragePerQuintal: ${
            inwardShipmentItem.brokeragePerQuintal
          }, emptyBagCost: ${inwardShipmentItem.emptyBagCost}, emptyBagsWtKg: ${
            inwardShipmentItem.emptyBagsWtKg
          }, pricePerKg: ${
            inwardShipmentItem.pricePerKg
          }, netMaterialWtPerBagKg: ${netMaterialWtPerBagKg}}) {
            id
          }`;
          /* eslint-enable */
          /* if user edited shipment item, then update 'updateInwardShipmentItemMutationString' with 'update inward shipment item' mutation string  */
          updateInwardShipmentItemMutationString += updateSingleInwardShipmentItemMutationString;
        });
      }
    }

    /* this string used to store final mutation string */
    let finalMutationString;

    /* if user has not added any new shipment item, then final mutation does not require inserted shipment item mutation's object array,
otherwise if user added new shipment items, then it's mandatory to add insert shipment item mutation's object array in final mutation */
    if (newlyAddedShipmentItemsArray.length === 0) {
      /* this final mutation contains updateInwardShipmentMutation and updateInwardShipmentItemMutation */
      finalMutationString = gql`
    mutation updateInwardShipmentDetails($id: Int!, $vehicleNumber: String!, $driverName: String!, $fullVehicleWtKg: float8!, $emptyVehicleWtKg: float8!, $weighbridgeCharges: float8, $laborChargeType: enum_labor_charge_type_enum, $laborChargePerBag: float8, $billingDetails: jsonb) {
      update_inwardShipments_by_pk(pk_columns: {id:$id}, _set: {vehicleNumber: $vehicleNumber, driverName: $driverName, fullVehicleWtKg: $fullVehicleWtKg, emptyVehicleWtKg: $emptyVehicleWtKg, weighbridgeCharges: $weighbridgeCharges, laborChargeType: $laborChargeType, laborChargePerBag: $laborChargePerBag, billing_details :$billingDetails}) {
        id
      }
      ${updateInwardShipmentItemMutationString}
    }
  `;
    } else {
      /* this const used to store both 'insert' and 'update' inward shipment item mutation string */
      const insertAndUpdateInwardShipmentItemMutationString =
        insertInwardShipmentItemMutationString + updateInwardShipmentItemMutationString;

      /* this final mutation contains updateInwardShipmentMutation, updateInwardShipmentItemMutation and insertInwardShipmentItemsMutation */
      finalMutationString = gql`
    mutation updateAndInsertInwardShipment($id: Int!, $vehicleNumber: String!, $driverName: String!, $fullVehicleWtKg: float8!, $emptyVehicleWtKg: float8!, $weighbridgeCharges: float8, $laborChargeType: enum_labor_charge_type_enum, $laborChargePerBag: float8, $objects: [inwardShipmentItems_insert_input!]! , $billingDetails: jsonb) {
      update_inwardShipments_by_pk(pk_columns: {id:$id}, _set: {vehicleNumber: $vehicleNumber, driverName: $driverName, fullVehicleWtKg: $fullVehicleWtKg, emptyVehicleWtKg: $emptyVehicleWtKg, weighbridgeCharges: $weighbridgeCharges, laborChargeType: $laborChargeType, laborChargePerBag: $laborChargePerBag , billing_details :$billingDetails}) {
        id
      }
      ${insertAndUpdateInwardShipmentItemMutationString}
    }
  `;
    }

    /* call to mutation which we define externally with const finalMutationString */
    apolloClient
      .mutate({
        mutation: finalMutationString,
        variables: {
          id: inwardShipmentId,
          vehicleNumber: vehicleNumber,
          driverName: driverName,
          fullVehicleWtKg: fullVehicleWtKg,
          emptyVehicleWtKg: emptyVehicleWtKg,
          weighbridgeCharges: weighbridgeCharges,
          laborChargeType: laborChargeType,
          laborChargePerBag: laborChargePerBag,
          objects: newlyAddedShipmentItemsArray,
          billingDetails: billDetailsData,
        },
        refetchQueries: [
          { query: getAllInwardRawMaterialQuery },
          { query: getInwardShipmentByIdModifyGrnQuery, variables: { id: inwardShipmentId } },
        ],
      })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Inward Shipment updated.');
        setIsUpdateGrnBtnLoading(false);
        if (closeModal) {
          closeModal();
        }
      })
      .catch((error: ApolloError) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        setIsUpdateGrnBtnLoading(false);
        logger(error);
      });
  };

  /* function to handle delete inward shipment item */
  const deleteInwardShipmentItemFunc = (shipmentItemId: number) => {
    setShipmentItemIdToDltAndShowLoading(shipmentItemId);
    deleteInwardShipmentItem({
      variables: {
        id: shipmentItemId,
      },
      /* after deleting a shipment item, the update function is used to update the cache and display an updated shipment items array. */
      refetchQueries: [
        { query: getInwardShipmentByIdModifyGrnQuery, variables: { id: Number(param.id) } },
      ],
    })
      .then(() => {
        setShipmentItemIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment item has been deleted successfully.');
      })
      .catch((error: ApolloError) => {
        setShipmentItemIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  /* function to handle delete inward shipment item */
  const deleteInwardShipmentFunc = (shipmentId: number) => {
    setIsDeleteGRNLoading(true);
    deleteInwardShipment({
      variables: {
        id: shipmentId,
      },
      refetchQueries: [{ query: getAllInwardRawMaterialQuery }],
    })
      .then(() => {
        setIsDeleteGRNLoading(false);
        navigate('/report/shipments/inward/paddy');
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment has been deleted successfully.');
      })
      .catch((error: ApolloError) => {
        setIsDeleteGRNLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  /* if data loaded successfully then, show data */
  if (
    getInwardShipmentByIdModifyGrnData &&
    !getInwardShipmentByIdModifyGrnLoading &&
    !getBhagwatiMillIdQueryLoading
  ) {
    /* destructing data */
    const { getInwardShipmentByIdModifyGrn } = getInwardShipmentByIdModifyGrnData;

    /* destructing 'getInwardShipmentByIdModifyGrn' data */
    const { grn, createdAt, completedAt } =
      getInwardShipmentByIdModifyGrn as InwardShipmentGRNDataType;

    /* if one of value between 'full vehicle weight value' & 'empty vehicle weight value' is not present, then consider that value as 'zero'
    & calculate net material weight by subtracting full vehicle weight  & empty vehicle weight */
    /* const used to store full vehicle weight */
    const fullVehicleWeightInKg = fullVehicleWtKgWatchedValue ? fullVehicleWtKgWatchedValue : 0;

    /* const used to store empty vehicle weight */
    const emptyVehicleWeightInKg = emptyVehicleWtKgWatchedValue ? emptyVehicleWtKgWatchedValue : 0;

    return (
      <div className={editInwardShipmentId ? undefined : 'tabLayout'}>
        <Modal
          visible={isAddOrEditShipmentItemModalVisible}
          title={editShipmentItemData ? 'Edit GRN' : 'Add GRN'}
          footer={false}
          destroyOnClose
          centered
          onCancel={() => {
            setIsAddOrEditShipmentItemModalVisible(false);
            setEditShipmentItemData(null);
          }}
          width={600}
          className="editCompletedGRNModal"
        >
          <AddOrEditCompletedShipmentItemForm
            shipmentData={{
              data: getInwardShipmentByIdModifyGrnData,
              processGRN: Number(param.id),
            }}
            mode={editShipmentItemData ? 'edit' : 'add'}
            shipmentItemDataToEdit={editShipmentItemData}
            closeModal={() => {
              setIsAddOrEditShipmentItemModalVisible(false);
              setEditShipmentItemData(null);
            }}
            addNewShipmentItems={setInwardShipmentItems}
            setEditShipmentItemIds={setEditShipmentItemsIds}
            setShipmentItemDataToEdit={setEditShipmentItemData}
          />
        </Modal>
        {editInwardShipmentId ? null : (
          <div style={{ display: 'flex' }}>
            <Link
              to="/report/shipments/inward/paddy"
              style={{ textAlign: 'start' }}
              className="hideInPrint "
            >
              <ArrowLeftOutlined className="goBackButton" />
            </Link>
            <h2>Inward Shipment (Purchase) - Edit Completed GRN</h2>
          </div>
        )}
        <Descriptions {...descriptionComponentStyleProps}>
          <Descriptions.Item label="GRN">{grn}</Descriptions.Item>
          <Descriptions.Item label="Start Date">
            {createdAt ? dateFormatFunc(createdAt as Date) : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Completed on">
            {completedAt ? dateFormatFunc(completedAt as Date) : '-'}
          </Descriptions.Item>
        </Descriptions>
        <form>
          <FormItem
            label="Vehicle Number"
            isRequired
            errorText={errors && errors.vehicleNumber ? errors.vehicleNumber.message : undefined}
            {...formItemStyleProp}
            customStyle={{ paddingTop: 10 }}
          >
            <Input
              customStyles={inputComponentCommonStyle}
              placeholder="Please enter vehicle number"
              name="vehicleNumber"
              rhfControllerProps={{
                control,
              }}
              onChange={(rhfOnChange, value) => {
                rhfOnChange(value.toUpperCase());
              }}
            />
          </FormItem>
          <FormItem
            label="Driver name"
            isRequired
            errorText={errors && errors.driverName ? errors.driverName.message : undefined}
            {...formItemStyleProp}
          >
            <Input
              customStyles={inputComponentCommonStyle}
              placeholder="Please enter the name of driver"
              name="driverName"
              rhfControllerProps={{
                control,
              }}
            />
          </FormItem>
          <FormItem
            label="Tare weight (in kgs)"
            isRequired
            errorText={
              errors && errors.emptyVehicleWtKg ? errors.emptyVehicleWtKg.message : undefined
            }
            {...formItemStyleProp}
          >
            <InputNumber
              customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
              placeholder="Please enter tare weight (in kgs)"
              name="emptyVehicleWtKg"
              rhfControllerProps={{
                control,
              }}
              inputNumberProps={{
                min: 0,
              }}
            />
          </FormItem>
          <FormItem
            label="Gross weight (in kgs)"
            isRequired
            errorText={
              errors && errors.fullVehicleWtKg ? errors.fullVehicleWtKg.message : undefined
            }
            {...formItemStyleProp}
          >
            <InputNumber
              customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
              placeholder="Please enter gross weight (in kgs)"
              name="fullVehicleWtKg"
              rhfControllerProps={{
                control,
              }}
              inputNumberProps={{
                min: 0,
              }}
            />
          </FormItem>
          <FormItem label="Net Weight (in kgs.)" {...formItemStyleProp}>
            <span>{fullVehicleWeightInKg - emptyVehicleWeightInKg}</span>
          </FormItem>
          <FormItem
            label="Deduct weighbridge charges (in Rs.)"
            errorText={
              errors && errors.weighbridgeCharges ? errors.weighbridgeCharges.message : undefined
            }
            {...formItemStyleProp}
          >
            <InputNumber
              customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
              placeholder="Please enter weighbridge charges (in kgs)"
              name="weighbridgeCharges"
              rhfControllerProps={{
                control,
              }}
              inputNumberProps={{
                min: 0,
              }}
            />
          </FormItem>
          <FormItem
            label="Labour Charge Type "
            isRequired
            errorText={
              errors && errors.laborChargeType ? errors.laborChargeType.message : undefined
            }
            {...formItemStyleProp}
          >
            <RadioGroup
              name="laborChargeType"
              rhfControllerProps={{
                control,
              }}
              options={[
                { label: 'Add', value: 'add' },
                {
                  label: 'Deduct',
                  value: 'deduct',
                },
              ]}
            />
          </FormItem>
          <FormItem
            label="Labour charges (Rs. per bag)"
            isRequired
            errorText={
              errors && errors.laborChargePerBag ? errors.laborChargePerBag.message : undefined
            }
            {...formItemStyleProp}
          >
            <InputNumber
              customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
              name="laborChargePerBag"
              placeholder="Please enter labour charges (in kgs)"
              rhfControllerProps={{
                control,
              }}
              inputNumberProps={{
                min: 0,
              }}
            />
          </FormItem>
          <FormItem label="Is Bill send by party?" {...formItemStyleProp}>
            <Switch name="isBillSendByParty" rhfControllerProps={{ control }} />
          </FormItem>

          {watch('isBillSendByParty') ? (
            <>
              <FormItem label="Bill number" {...formItemStyleProp}>
                <Input
                  name="billNumber"
                  customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
                  placeholder="Enter bill number"
                  rhfControllerProps={{
                    control,
                  }}
                />
              </FormItem>
              <FormItem label="Net Weight (In quintals)" {...formItemStyleProp}>
                <InputNumber
                  name="netWeightInQuintals"
                  customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
                  placeholder=" Enter net weight from party bill"
                  rhfControllerProps={{
                    control,
                  }}
                  inputNumberProps={{ min: 0 }}
                />
              </FormItem>
              <FormItem label="Weight difference (in quintals)" {...formItemStyleProp}>
                <>
                  {differenceInWeightCalculation({
                    fullVehicleWtKg: fullVehicleWtKgWatchedValue || 0,
                    emptyVehicleWtKg: emptyVehicleWtKgWatchedValue || 0,
                    netWeightInQuintals: watch('netWeightInQuintals') || 0,
                  })}
                </>
              </FormItem>
              <FormItem label="Broker" {...formItemStyleProp}>
                <Input
                  name="broker"
                  customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
                  placeholder="Enter broker name"
                  rhfControllerProps={{
                    control,
                  }}
                />
              </FormItem>
              <FormItem label="Additional information" {...formItemStyleProp}>
                <Input
                  name="additionalInformation"
                  customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
                  isTextAreaInput
                  placeholder="Enter Additional information"
                  rhfControllerProps={{
                    control,
                  }}
                />
              </FormItem>
            </>
          ) : null}
        </form>
        <Divider style={{ marginBottom: 5 }} />
        <Button
          type="primary"
          icon={<PlusOutlined style={{ fontSize: 15 }} />}
          className="createAndNavigateButton"
          onClick={() => {
            setIsAddOrEditShipmentItemModalVisible(true);
          }}
          style={{ marginBottom: 20 }}
        >
          Add Entry
        </Button>
        <h3 style={{ marginBottom: 0 }}>Items</h3>
        <Table<ItemsShipment | CompletedShipmentItemType>
          dataSource={inwardShipmentsItems}
          className="tableStyle"
          bordered
          size="small"
          rowKey="id"
          pagination={{ showSizeChanger: true }}
          style={{ marginTop: 10 }}
        >
          <Table.Column<ItemsShipment>
            title="Farmer/Trader (Purchase) Name"
            dataIndex="sellerName"
            key="sellerName"
            align="center"
            render={(text, record) => {
              return (
                <span style={{ textTransform: 'capitalize' }}>
                  {(record.seller && record.seller.name) || '-'}
                </span>
              );
            }}
          />
          <Table.Column<ItemsShipment>
            title="Material Type"
            dataIndex="rawMaterial"
            key="rawMaterial"
            align="center"
            render={(text, record) => {
              return (record.rawMaterial && record.rawMaterial.name) || '-';
            }}
          />
          <Table.Column<ItemsShipment>
            title="Paddy Grade"
            dataIndex="paddyGrade"
            key="paddyGrade"
            align="center"
            render={(text, record) => record.paddyGrade || '-'}
          />
          <Table.Column<ItemsShipment>
            title="No. of Bags"
            dataIndex="bagsCount"
            key="bagsCount"
            align="center"
            render={(text, record) => record.bagsCount || '-'}
          />
          <Table.Column<ItemsShipment>
            title="Paddy Returned"
            dataIndex="materialReturned"
            key="materialReturned"
            align="center"
            render={(text, record) => (record.materialReturned ? 'Yes' : 'No')}
          />
          <Table.Column<ItemsShipment>
            title="Unloading Location"
            dataIndex="destination"
            key="destination"
            align="center"
            render={(destination, record) => {
              if (destination === null) {
                return 'NA';
              }
              return (
                <>
                  {destination === 'godown' ? (
                    <>
                      <span style={{ fontWeight: 'bold' }}>Godown: </span>
                      <span style={{ textTransform: 'capitalize' }}>
                        {(record.godown && record.godown.name) || '-'}
                      </span>
                    </>
                  ) : (
                    <span style={{ textTransform: 'capitalize' }}>
                      <span style={{ fontWeight: 'bold', textAlign: 'left' }}>Mill: </span>
                      {getBhagwatiMillIdData &&
                        getBhagwatiMillIdData.mills[0] &&
                        getBhagwatiMillIdData.mills[0].name}
                      <br />
                    </span>
                  )}
                </>
              );
            }}
          />

          <Table.Column<ItemsShipment>
            title="Actions"
            dataIndex="actions"
            key="actions"
            align="center"
            render={(text, record) => {
              return (
                <div className="buttonContainer">
                  <Button
                    onClick={() => {
                      setIsAddOrEditShipmentItemModalVisible(true);
                      setEditShipmentItemData(record);
                    }}
                  >
                    Edit
                  </Button>
                  <Popconfirm
                    title="Delete Inward Material Item. Are you sure?"
                    okText="Yes"
                    okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                    cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                    cancelText="No"
                    icon={<WarningFilled />}
                    onConfirm={() => {
                      deleteInwardShipmentItemFunc(record.id);
                    }}
                  >
                    <Button
                      className="deleteButton"
                      loading={record.id === shipmentItemIdToDltAndShowLoading}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              );
            }}
          />
        </Table>
        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20 }}>
          <Popconfirm
            title="Update GRN. Are you sure?"
            onConfirm={() => {
              void handleSubmit(onSubmit)();
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginRight: 10 }}
              loading={isUpdateGrnBtnLoading}
            >
              Update GRN
            </Button>
          </Popconfirm>
          <Popconfirm
            title="Delete GRN. Are you sure?"
            okText="Yes"
            onConfirm={() => {
              deleteInwardShipmentFunc(Number(param.id));
            }}
            okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
            cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
            cancelText="No"
            icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
          >
            <Button className="deleteButton" loading={isDeleteGRNLoading}>
              Delete GRN
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  }
  return <Spin className="loadingSpinner" />;
};

export default EditCompletedGRNScreen;
