import dayjs from 'dayjs';
import { FinancialYearArrayType } from '../types';

/**
 * Function to get current financial year start year
 * This function will return start year of current financial year
 * i.e. in the format of YYYY
 */
const getCurrentFinancialStartYear = () => {
  const today = dayjs();
  /**
   * const to store and make year start date to 1st April
   */
  const startOfFinancialYear = dayjs().startOf('year').month(3).date(1);

  /**
   * if today's date is less than 'startOfFinancialYear' i.e. 1st April
   * then start year of financial year will be 'current year - 1'
   */
  if (today.isBefore(startOfFinancialYear)) {
    return today.year() - 1;
  }

  return today.year();
};

/**
 * const to return current financial start year and previous financial start year
 * It will return year in the format of YYYY
 */
const financialYear = {
  currentFinancialYear: getCurrentFinancialStartYear(),
  prevFinancialYear: getCurrentFinancialStartYear() - 1,
};

/**
 * Function to store current and previous financial year in Array
 * presently this function will return array containing two financial year details
 * i.e. label of financial year in the format of YY-YY and
 * start date and end date of that particular financial year
 * start_date = Start date always be 1st April followed by 'financial year' which we get from 'financialYear' const
 * end_date = End date always be 31st March followed by 'financial year' which we get from 'financialYear' const
 */
const getFinancialYearsList = () => {
  const financialYearArray: FinancialYearArrayType = [];

  /**
   * const to store array of financial year with date
   * label is calculated from financialYear object by mapping its keys and label will be 'financial year start year - (financial year start year + 1)' i.e. 'YY-YY'
   * date_range object is calculated from 'financial year start year followed by April 1st' for start date and
   * '(financial year start year + 1) followed by March 31st' for end date
   */
  const financialYearWithDate = Object.keys(financialYear).map((item) => ({
    label: `${dayjs(financialYear[item as keyof typeof financialYear].toString()).format('YY')}-${
      Number(dayjs(financialYear[item as keyof typeof financialYear].toString()).format('YY')) + 1
    }`,

    date_range: {
      start_date: `${financialYear[item as keyof typeof financialYear]}-04-01`,
      end_date: `${financialYear[item as keyof typeof financialYear] + 1}-03-31`,
    },
  }));

  financialYearArray.push(...financialYearWithDate);

  return financialYearArray;
};

export default getFinancialYearsList;
