import React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  InwardShipmentItems,
  GetInwardShipmentByIdModifyGrnQuery,
} from '../../../../graphql/graphql-types';
import { findObjectForIdFromObjectArray } from '../../../../utils/helpers';
import InputNumber from '../../../../components/InputNumber';
import Select from '../../../../components/Select';
import RadioGroup from '../../../../components/RadioGroup';
import Switch from '../../../../components/Switch';
import RequiredMessage from '../../../../components/RequiredMessage';
import FormItem from '../../../../components/FormItem';
import Input from '../../../../components/Input';
import {
  CreateOrUpdateInwardShipmentItemFormType,
  ItemsShipment,
  CompletedShipmentItemType,
} from '../../../../utils/types';
import { defaultMillId, inputComponentCommonStyle } from '../../../../utils/globals';

/* Add or Edit completed shipment item form prop type */
type AddOrEditCompletedShipmentItemFormPropType = {
  /* prop type used to check whether the AddOrEditShipmentItem form is being called from 'edit' mode or 'add' mode */
  mode: 'add' | 'edit';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  shipmentItemDataToEdit?: CompletedShipmentItemType | null;
  /* this prop type used to set 'shipment item data' as null after editing */
  setShipmentItemDataToEdit: React.Dispatch<React.SetStateAction<ItemsShipment | null>>;
  /* this prop type used to store shipment data & process GRN number */
  shipmentData: { data: GetInwardShipmentByIdModifyGrnQuery; processGRN: number };
  /* this state prop used to add new shipment items into inward shipment items array */
  addNewShipmentItems: React.Dispatch<React.SetStateAction<Array<CompletedShipmentItemType>>>;
  /* this state prop used to store shipment item id's which are selected to 'edit'*/
  setEditShipmentItemIds: React.Dispatch<React.SetStateAction<Array<number>>>;
  /* prop type used to close form modal */
  closeModal: () => void;
};

/* create or update inward shipment item form type */
type CreateOrEditInwardShipmentItemFormType = Omit<
  CreateOrUpdateInwardShipmentItemFormType,
  'grn' | 'vehicleNumber'
> & {
  /* empty bag const */
  emptyBagCost?: number | null;
  /* price of material per kg */
  pricePerKg?: number | null;
  /* brokerage per quintal rate */
  brokeragePerQuintal?: number | null;
  /* empty bag weight in kg */
  emptyBagsWtKg?: number | null;
  /* shipment item id*/
  id: string | number;
} & Pick<InwardShipmentItems, 'userEmptyBagWtUnit' | 'mill' | 'millId'>;

/* validations required for useForm */
const schema = yup.object({
  rmSellerId: yup.number().nullable().required('Please select farmer/trader name and try again.'),
  rawMaterialId: yup.number().nullable().required('Please select material type and try again.'),
  paddyGrade: yup.string().nullable().required('Please select paddy grade and try again.'),
  bagsCount: yup
    .number()
    .nullable()
    .required('Please enter number of bags and try again.')
    .integer('Please enter valid number.')
    .min(0, 'Please enter valid number'),
  destination: yup
    .string()
    .nullable()
    .when(['materialReturned'], {
      is: (materialReturned: boolean) => !materialReturned,
      then: yup
        .string()
        .nullable()
        .required('Please select material unload destination and try again.'),
    }),
  godownId: yup
    .number()
    .nullable()
    .when(['destination', 'materialReturned'], {
      is: (destination: string, materialReturned: boolean) =>
        destination === 'godown' && !materialReturned,
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
  emptyBagCost: yup.number().nullable().min(0, 'Please enter valid number'),
  pricePerKg: yup.number().nullable().min(0, 'Please enter valid number'),
  brokeragePerQuintal: yup.number().nullable().min(0, 'Please enter valid number'),
});

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 11,
  /* input column span of FormItem */
  inputColSpan: 13,
  /* custom style of FormItem */
  customStyle: { fontSize: 5, paddingTop: 15 },
  /* formItem label styling */
  labelStyle: { fontSize: 14 },
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field) */
  requiredMark: 'after' as 'after' | 'before',
};

/* react functional component */
const AddOrEditCompletedShipmentItemForm = ({
  mode,
  shipmentData,
  shipmentItemDataToEdit = undefined,
  closeModal,
  addNewShipmentItems,
  setEditShipmentItemIds,
  setShipmentItemDataToEdit,
}: AddOrEditCompletedShipmentItemFormPropType): JSX.Element => {
  /* destructing shipment data */
  const { getAllGodowns, getAllRawMaterials, getAllRmSellers, getAllMills } = shipmentData.data;

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm<CreateOrEditInwardShipmentItemFormType>({
    defaultValues:
      mode === 'edit' && shipmentItemDataToEdit
        ? {
            id: shipmentItemDataToEdit.id,
            rmSellerId: shipmentItemDataToEdit.seller ? shipmentItemDataToEdit.seller.id : null,
            rawMaterialId: shipmentItemDataToEdit.rawMaterial
              ? shipmentItemDataToEdit.rawMaterial.id
              : null,
            paddyGrade: shipmentItemDataToEdit.paddyGrade,
            bagsCount: shipmentItemDataToEdit.bagsCount,
            materialReturned: shipmentItemDataToEdit.materialReturned,
            materialReturnedReason: shipmentItemDataToEdit.materialReturnedReason,
            emptyBagsReturned: shipmentItemDataToEdit.emptyBagsReturned,
            godownId: shipmentItemDataToEdit.godown ? shipmentItemDataToEdit.godown.id : null,
            destination: shipmentItemDataToEdit.destination,
            emptyBagCost: shipmentItemDataToEdit.emptyBagCost,
            pricePerKg: shipmentItemDataToEdit.pricePerKg
              ? shipmentItemDataToEdit.pricePerKg * 100
              : null,
            brokeragePerQuintal: shipmentItemDataToEdit.brokeragePerQuintal,
            userEmptyBagWtUnit: shipmentItemDataToEdit.userEmptyBagWtUnit,
            emptyBagsWtKg: shipmentItemDataToEdit.emptyBagsWtKg,
            millId: shipmentItemDataToEdit.mill ? shipmentItemDataToEdit.mill.id : null,
          }
        : {
            id: `new-${Math.floor(Math.random() * 10000000000000 + 1)}`,
            rmSellerId: null,
            rawMaterialId: null,
            paddyGrade: null,
            bagsCount: null,
            materialReturned: false,
            materialReturnedReason: null,
            emptyBagsReturned: false,
            godownId: null,
            destination: null,
            emptyBagCost: null,
            pricePerKg: null,
            brokeragePerQuintal: null,
            userEmptyBagWtUnit: null,
            emptyBagsWtKg: null,
            millId: null,
          },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* function used to handle form submit */
  const onSubmit = (formData: CreateOrEditInwardShipmentItemFormType) => {
    /* if user want to edit existing shipment item then add that shipment item id into 'setEditShipmentItemIds' state.
     which is then used to update shipment items for those shipment id's */

    /* this if only executed for existing shipment items (which are not newly added).
    because we are executing update shipment item mutation for updating existing shipment item & insert shipment item mutation for 'newly added shipment item'.
     */
    if (shipmentItemDataToEdit && !shipmentItemDataToEdit.id.toString().match(/new-/g)) {
      setEditShipmentItemIds((prev) => {
        /* this condition used to make sure that shipment id's are not repeating/duplicate */
        if (!prev.includes(shipmentItemDataToEdit.id as number)) {
          return [...prev, shipmentItemDataToEdit.id as number];
        }
        return prev;
      });
    }

    /* destructing form data */
    const {
      rmSellerId,
      rawMaterialId,
      materialReturnedReason,
      brokeragePerQuintal,
      userEmptyBagWtUnit,
      bagsCount,
      materialReturned,
      emptyBagsReturned,
      emptyBagCost,
      emptyBagsWtKg,
      paddyGrade,
      pricePerKg,
      destination,
      godownId,
    } = formData;

    /* const used to create new shipment item object */
    const newShipmentItem = {
      id: formData.id,
      inwardShipmentId: shipmentData.processGRN,
      seller: rmSellerId ? findObjectForIdFromObjectArray(rmSellerId, getAllRmSellers) : null,
      rawMaterial: rawMaterialId
        ? findObjectForIdFromObjectArray(rawMaterialId, getAllRawMaterials)
        : null,
      paddyGrade: paddyGrade ? paddyGrade : 'rice',
      bagsCount: bagsCount ? bagsCount : 0,
      materialReturned: materialReturned ? materialReturned : false,
      emptyBagsReturned: emptyBagsReturned ? emptyBagsReturned : false,
      emptyBagCost: emptyBagCost ? emptyBagCost : 0,
      emptyBagsWtKg: emptyBagsWtKg ? emptyBagsWtKg : 0,
      pricePerKg: pricePerKg ? pricePerKg / 100 : 0,
      userEmptyBagWtUnit: userEmptyBagWtUnit ? userEmptyBagWtUnit : null,
      brokeragePerQuintal: brokeragePerQuintal ? brokeragePerQuintal : 0,
      materialReturnedReason: materialReturnedReason ? materialReturnedReason : null,
      destination: destination ? destination : null,
      godown: godownId ? findObjectForIdFromObjectArray(godownId, getAllGodowns) : null,
      mill:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        destination === 'mill' ? findObjectForIdFromObjectArray(defaultMillId, getAllMills) : null,
    };

    /* if user want to edit shipment item then update that shipment item's data */
    if (shipmentItemDataToEdit) {
      /* update shipment items array */
      addNewShipmentItems((prev) => {
        /* const used to store updated shipment items array  */
        const updatedShipmentItems = prev.map((item) => {
          if (item.id === shipmentItemDataToEdit.id) {
            return newShipmentItem;
          }
          return item;
        });
        return updatedShipmentItems;
      });

      /* set shipment item data which is selected to 'edit' as null, after updating it  */
      setShipmentItemDataToEdit(null);
      /* close modal */
      closeModal();
    } else {
      /* if user added new shipment item, then add that shipment item into existing shipment items array */
      addNewShipmentItems((prev) => prev.concat(newShipmentItem));
      setShipmentItemDataToEdit(null);
      /* close modal */
      closeModal();
    }
  };

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <RequiredMessage />
        <FormItem
          label="Farmer/Trader (Purchase) Name"
          isRequired
          errorText={errors && errors.rmSellerId ? errors.rmSellerId.message : undefined}
          {...formItemStyleProps}
          customStyle={{ paddingTop: 0 }}
          displayMode="column"
          inputColSpan={24}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select farmer/trader purchase name"
            name="rmSellerId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
            }}
            options={getAllRmSellers.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </FormItem>
        <FormItem
          label="Material Type"
          isRequired
          errorText={errors && errors.rawMaterialId ? errors.rawMaterialId.message : undefined}
          {...formItemStyleProps}
          customStyle={{ paddingTop: 10 }}
          displayMode="column"
          inputColSpan={24}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select material type"
            name="rawMaterialId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{ showSearch: true, optionFilterProp: 'children' }}
            options={getAllRawMaterials.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
          />
        </FormItem>
        <FormItem
          label="Paddy Grade "
          isRequired
          errorText={errors && errors.paddyGrade ? errors.paddyGrade.message : undefined}
          {...formItemStyleProps}
          customStyle={{ paddingTop: 10 }}
        >
          <RadioGroup
            name="paddyGrade"
            rhfControllerProps={{
              control,
            }}
            options={[
              { label: '1 (One)', value: '1 (One)' },
              {
                label: '2 (Two)',
                value: '2 (Two)',
              },
              {
                label: '3 (Three)',
                value: '3 (Three)',
              },
            ]}
          />
        </FormItem>
        <FormItem
          label="Mark paddy as returned"
          isRequired
          errorText={
            errors && errors.materialReturned ? errors.materialReturned.message : undefined
          }
          {...formItemStyleProps}
          customStyle={{ paddingTop: 10 }}
        >
          <Switch
            name="materialReturned"
            rhfControllerProps={{
              control,
            }}
            onChange={(rhfOnChange, value: boolean) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              rhfOnChange(value);
              /* when a shipment item is returned to the user, it is not unloaded to a godown or mill
                    and because of this the destination, godown Id, and milling Process Id fields are setting to null. */
              if (value) {
                /* if destination has selected, then set it to null */
                if (watch('destination')) {
                  setValue('destination', null);
                }
                /* if godownId has selected, then set it to null */
                if (watch('godownId')) {
                  setValue('godownId', null);
                }
              }
            }}
          />
        </FormItem>
        {!watch('materialReturned') ? (
          <>
            <FormItem
              label="Material unloaded at"
              isRequired
              errorText={errors && errors.destination ? errors.destination.message : undefined}
              {...formItemStyleProps}
              customStyle={{ paddingTop: 10 }}
            >
              <RadioGroup
                name="destination"
                rhfControllerProps={{
                  control,
                }}
                defaultValue={null}
                options={[
                  { label: 'Godown (for storage)', value: 'godown' },
                  {
                    label: 'Mill (for processing)',
                    value: 'mill',
                  },
                ]}
                onChange={(e) => {
                  /* on selection of 'destination', set 'destination godown id ' or 'destination mill id ' as a null */
                  if (e.target.value === 'mill') {
                    /* if user selected 'destination' as 'mill then set 'godownId' to null */
                    setValue('godownId', null);
                    setValue('millId', 1);
                  } else {
                    /* if 'godown' is selected as destination then set destination milling process Id to null */
                    setValue('millId', null);
                  }
                }}
              />
            </FormItem>
            {watch('destination') === 'godown' ? (
              <FormItem
                label="Select Godown "
                isRequired
                errorText={errors && errors.godownId ? errors.godownId.message : undefined}
                {...formItemStyleProps}
              >
                <Select
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please select godown "
                  name="godownId"
                  rhfControllerProps={{
                    control,
                  }}
                  selectProps={{
                    showSearch: true,
                    optionFilterProp: 'children',
                  }}
                  options={getAllGodowns.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))}
                />
              </FormItem>
            ) : null}
          </>
        ) : null}
        <FormItem
          label="No. of Bags"
          isRequired
          errorText={errors && errors.bagsCount ? errors.bagsCount.message : undefined}
          {...formItemStyleProps}
        >
          <InputNumber
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter number of bags"
            name="bagsCount"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        {watch('materialReturned') ? (
          <FormItem
            label="Reason why paddy is returned"
            errorText={
              errors && errors.materialReturnedReason
                ? errors.materialReturnedReason.message
                : undefined
            }
            {...formItemStyleProps}
          >
            <Input
              name="materialReturnedReason"
              isTextAreaInput={true}
              placeholder="Please enter the reason of paddy return"
              rhfControllerProps={{
                control,
              }}
              customStyles={inputComponentCommonStyle}
              textAreaProps={{ rows: 2 }}
            />
          </FormItem>
        ) : (
          <>
            <FormItem
              label="Empty bags returned"
              isRequired
              errorText={
                errors && errors.emptyBagsReturned ? errors.emptyBagsReturned.message : undefined
              }
              {...formItemStyleProps}
              customStyle={{ paddingTop: 10 }}
            >
              <Switch
                name="emptyBagsReturned"
                rhfControllerProps={{
                  control,
                }}
              />
            </FormItem>
            <FormItem
              label="Cost of Empty Bag (Rs./unit)"
              errorText={errors && errors.emptyBagCost ? errors.emptyBagCost.message : undefined}
              {...formItemStyleProps}
            >
              <InputNumber
                customStyles={inputComponentCommonStyle}
                placeholder="Please enter cost of empty bags "
                name="emptyBagCost"
                rhfControllerProps={{
                  control,
                }}
                inputNumberProps={{ min: 0 }}
              />
            </FormItem>
            <FormItem
              label="Empty Bag Weight (in kgs.)"
              errorText={
                errors && errors.userEmptyBagWtUnit ? errors.userEmptyBagWtUnit.message : undefined
              }
              {...formItemStyleProps}
            >
              <RadioGroup
                name="userEmptyBagWtUnit"
                rhfControllerProps={{
                  control,
                }}
                options={[
                  { label: 'Per Bag', value: 'kgPerBag' },
                  {
                    label: 'Per Quintal',
                    value: 'kgPerQuintal',
                  },
                ]}
              />
            </FormItem>
            {watch('userEmptyBagWtUnit') ? (
              <FormItem
                errorText={
                  errors && errors.emptyBagsWtKg ? errors.emptyBagsWtKg.message : undefined
                }
                {...formItemStyleProps}
                customStyle={{ paddingTop: 10 }}
              >
                <InputNumber
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please enter empty bag weight "
                  name="emptyBagsWtKg"
                  rhfControllerProps={{
                    control,
                  }}
                  inputNumberProps={{ min: 0 }}
                />
              </FormItem>
            ) : null}
            <FormItem
              label="Price of Paddy (Rs./quintal)"
              errorText={errors && errors.pricePerKg ? errors.pricePerKg.message : undefined}
              {...formItemStyleProps}
            >
              <InputNumber
                customStyles={inputComponentCommonStyle}
                placeholder="Please enter price of paddy in quintal "
                name="pricePerKg"
                rhfControllerProps={{
                  control,
                }}
                inputNumberProps={{ min: 0 }}
              />
            </FormItem>
            <FormItem
              label="Brokerage (Rs./quintal)"
              errorText={
                errors && errors.brokeragePerQuintal
                  ? errors.brokeragePerQuintal.message
                  : undefined
              }
              {...formItemStyleProps}
            >
              <InputNumber
                customStyles={inputComponentCommonStyle}
                placeholder="Please enter brokerage cost per quintal "
                name="brokeragePerQuintal"
                rhfControllerProps={{
                  control,
                }}
                inputNumberProps={{ min: 0 }}
              />
            </FormItem>
          </>
        )}

        <FormItem {...formItemStyleProps} customStyle={{ paddingBottom: 10 }}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button htmlType="submit" type="primary">
              {shipmentItemDataToEdit && mode === 'edit' ? 'Update' : 'Submit'}
            </Button>

            <Button
              type="default"
              style={{ marginLeft: 10 }}
              onClick={() => {
                closeModal();
                /* and reset useForm */
                reset();
              }}
            >
              Cancel
            </Button>
          </div>
        </FormItem>
      </form>
    </div>
  );
};

export default AddOrEditCompletedShipmentItemForm;
