import React from 'react';
import { Table } from 'antd';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetAllInwardShipmentForRawMaterialByStartAndEndDateQuery,
  GetAllInwardShipmentForRawMaterialByStartAndEndDateQueryVariables,
} from '../../../../graphql/graphql-types';
import { DailyReportsPropType, InwardShipmentsReportType } from '../../../../utils/types';
import ColumnSearchComponent from '../../../../components/ColumnSearchComponent';
import { dateFormatFunc } from '../../../../utils/globals';

/* loading get all inward shipment for RawMaterial Query */
const getAllInwardShipmentForRawMaterialByStartAndEndDateQuery = loader(
  '../../../../graphql/queries/getAllInwardShipmentForRawMaterialByStartAndEndDateQuery.graphql',
);

/* React functional component */
const DailyReportInwardPaddyScreen = ({ startDate, endDate }: DailyReportsPropType) => {
  /* get All Inward Shipment For RawMaterial data using query with start and end date from date range field */
  const {
    data: getAllInwardShipmentForRawMaterialData,
    loading: getAllInwardShipmentForRawMaterialLoading,
    error: getAllInwardShipmentForRawMaterialError,
  } = useQuery<
    GetAllInwardShipmentForRawMaterialByStartAndEndDateQuery,
    GetAllInwardShipmentForRawMaterialByStartAndEndDateQueryVariables
  >(getAllInwardShipmentForRawMaterialByStartAndEndDateQuery, {
    variables: {
      createdAt:
        startDate && endDate
          ? {
              _gte: startDate,
              _lte: endDate,
            }
          : {},
    },
  });

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getAllInwardShipmentForRawMaterialError) {
    return <div className="errorText">{getAllInwardShipmentForRawMaterialError.message}</div>;
  }

  return (
    <>
      <div className="reportTableContainer">
        <div className="reportTable">
          <Table<InwardShipmentsReportType>
            dataSource={
              getAllInwardShipmentForRawMaterialData
                ? getAllInwardShipmentForRawMaterialData.inwardShipments
                : []
            }
            bordered
            size="small"
            className="tableStyle"
            pagination={{ showSizeChanger: true }}
            rowKey="id"
            loading={getAllInwardShipmentForRawMaterialLoading}
          >
            <Table.Column<InwardShipmentsReportType>
              title="GRN/Date"
              dataIndex="grn"
              align="center"
              width={150}
              onFilter={(value, record) => {
                return record.grn
                  ? record.grn.toLowerCase().includes(value.toString().toLowerCase())
                  : false;
              }}
              filterDropdown={(filterDropDownProp) => (
                <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
              )}
              filterIcon={(filtered) => {
                return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
              }}
              render={(grnNumber, record) => {
                return (
                  <>
                    {grnNumber}
                    <br />
                    {record.createdAt ? dateFormatFunc(record.createdAt) : '-'}
                  </>
                );
              }}
            />
            <Table.Column<InwardShipmentsReportType>
              title="Status"
              dataIndex="status"
              key="status"
              align="center"
              filters={[
                {
                  text: 'Open',
                  value: 'open',
                },
                {
                  text: 'Unloaded',
                  value: 'unloaded',
                },
                {
                  text: 'Admin Open',
                  value: 'adminOpen',
                },
                { text: 'Completed', value: 'completed' },
              ]}
              render={(text, record) => {
                if (record.status === 'open') {
                  return 'Open';
                }
                if (record.status === 'unloaded') {
                  return 'Unloaded';
                }
                if (record.status === 'adminOpen') {
                  return 'Admin Open';
                }
                if (record.status === 'completed') {
                  return 'Completed';
                }
                return 'Loading...';
              }}
              onFilter={(value, record) => record.status === value}
              filterIcon={(filtered) => {
                return <FilterFilled className={filtered ? 'searchIcon' : ''} />;
              }}
              width={120}
            />

            <Table.Column<InwardShipmentsReportType>
              title="Vehicle No."
              key="vehicleNumber"
              dataIndex="vehicleNumber"
              align="center"
              render={(text, record) => (record.vehicleNumber ? record.vehicleNumber : '-')}
              width={120}
            />

            <Table.Column<InwardShipmentsReportType>
              title="Farmer/Trader (Purchase)"
              key="seller"
              dataIndex="seller"
              align="center"
              render={(text, { items }) => {
                if (Array.isArray(items) && items.length > 0) {
                  return items.map((ele) => {
                    return <div key={ele.id}>{ele.seller ? ele.seller.name : '-'}</div>;
                  });
                }
                return '-';
              }}
            />
            <Table.Column<InwardShipmentsReportType>
              title="Paddy Name (Grade) - No. Of Bags"
              key="paddyName"
              dataIndex="paddyName"
              align="center"
              render={(text, { items }) => {
                if (Array.isArray(items) && items.length > 0) {
                  return items.map((item) => {
                    return (
                      <div key={item.id}>
                        {item.rawMaterial && item.rawMaterial.name}
                        {item.paddyGrade !== 'rice' &&
                          item.paddyGrade &&
                          `(${item.paddyGrade.replace(/[^\d]/g, '')})`}
                        {item && item.bagsCount ? ` - ${item.bagsCount} Bags` : null}
                      </div>
                    );
                  });
                }
                return '-';
              }}
              width={250}
            />
            <Table.Column<InwardShipmentsReportType>
              title="Unloading Location"
              key="unloadingLocation"
              dataIndex="unloadingLocation"
              align="center"
              render={(text, { items }) => {
                if (Array.isArray(items) && items.length > 0) {
                  return items.map((item) => {
                    if (item.destination === 'mill') {
                      return (
                        <div key={item.id}>
                          <span style={{ fontWeight: 'bold' }}>Mill: </span>
                          {item.mill ? item.mill.name : '-'}
                        </div>
                      );
                    }
                    return (
                      <div key={item.id}>
                        <span style={{ fontWeight: 'bold' }}>Godown: </span>
                        {item.godown ? item.godown.name : '-'}
                      </div>
                    );
                  });
                }
                return '-';
              }}
            />
            <Table.Column<InwardShipmentsReportType>
              title="Net Truck Weight (quintals)"
              key="netWeight"
              dataIndex="netWeight"
              align="center"
              render={(text, record) =>
                ((record.fullVehicleWtKg ? record.fullVehicleWtKg : 0) -
                  (record.emptyVehicleWtKg ? record.emptyVehicleWtKg : 0)) /
                100
              }
              width={150}
            />
          </Table>
        </div>
      </div>
    </>
  );
};

export default DailyReportInwardPaddyScreen;
