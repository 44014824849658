import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import { GetUserByIdQuery, GetUserByIdQueryVariables } from '../../../graphql/graphql-types';
import { Spin } from 'antd';
import AddOrEditUserForm from './AddOrEditUserForm';
import { AddressType } from '../../../utils/types';

/* loading the get User by id query  with the help of loader */
const getUserByIdQuery = loader('../../../graphql/queries/getUserByIdQuery.graphql');

/* React functional component */
const EditUserScreen = () => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* get user by ID query */
  const {
    data: getUserByIdData,
    loading: getUserByIdLoading,
    error: getUserByIdError,
  } = useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(getUserByIdQuery, {
    variables: { id: Number(params.id) },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getUserByIdLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getUserByIdError) {
    return <div className="errorText">{getUserByIdError.message}</div>;
  }

  /* getting address data from getUserByIdData.getUserById in order to further use it while passing the data to use form */
  let addressData;
  if (getUserByIdData && getUserByIdData.getUserById) {
    addressData = getUserByIdData.getUserById.address as AddressType;
  }

  return (
    <>
      {getUserByIdData && getUserByIdData.getUserById ? (
        <AddOrEditUserForm
          mode="edit"
          userDataToEdit={{
            name: getUserByIdData.getUserById.name as string,
            email: getUserByIdData.getUserById.email as string,
            mobile: getUserByIdData.getUserById.mobile as string,
            dateOfBirth: getUserByIdData.getUserById.dob
              ? (getUserByIdData.getUserById.dob as Date)
              : null,
            role: getUserByIdData.getUserById.role,
            address: addressData ? addressData.address : '',
            city: addressData ? addressData.city : '',
            state: addressData ? addressData.state : '',
            pincode: addressData ? addressData.pincode : '',
          }}
        />
      ) : null}
    </>
  );
};

export default EditUserScreen;
