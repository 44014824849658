import React, { useMemo, useState } from 'react';
import {
  GetOutwardShipmentOrdersByBuyerQuery,
  GetOutwardShipmentOrdersByBuyerQueryVariables,
  GetAllProductBuyersQuery,
  GetAllProductBuyersQueryVariables,
  OutwardShipments,
  OutwardShipmentOrders,
  ProductBuyers,
  Products,
  ProductTypes,
  GetProductBuyerByIdQuery,
  GetProductBuyerByIdQueryVariables,
} from '../../../graphql/graphql-types';
import { useForm } from 'react-hook-form';
import { useLazyQuery, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Table, Descriptions, Button, Divider, Modal, Row, Col } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import FormItem from '../../../components/FormItem';
import Select from '../../../components/Select';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import { SearchOutlined } from '@ant-design/icons';
import { dateFormatFunc, inputComponentCommonStyle } from '../../../utils/globals';
import { AddOrEditBuyerFormType, AddressType } from '../../../utils/types';
import AddOrEditBuyerForm from '../../management/buyers/AddOrEditBuyerForm';
import FinancialYearComponent from '../../../components/FinancialYearComponent';

/* extending isBetween plugin from day js library */
dayjs.extend(isBetween);

/* loading get all outward shipment orders by buyers query */
const getOutwardShipmentOrdersByBuyerQuery = loader(
  '../../../graphql/queries/getOutwardShipmentOrdersByBuyerQuery.graphql',
);

/* loading get all buyers query with the help of loader */
const getAllBuyersQuery = loader('../../../graphql/queries/getAllProductBuyersQuery.graphql');

/* loading the get Product buyer by id query  */
const getProductBuyerByIdQuery = loader(
  '../../../graphql/queries/getAllProductBuyerByIdQuery.graphql',
);

/* buyer history report form type */
type BuyerHistoryReportFormType = {
  /* buyer id */
  buyerId: number | null;
};

/* buyer history report table type */
type BuyerHistoryReportTableType = Pick<
  OutwardShipmentOrders,
  'bagSizeKg' | 'loadedBagsCount' | 'invoiceNo' | 'id'
> & { outwardShipment: Pick<OutwardShipments, 'id' | 'grn' | 'truckOutAt' | 'createdAt'> } & {
  product?:
    | (Pick<Products, 'id' | 'brand'> & { productType: Pick<ProductTypes, 'id' | 'name'> })
    | null
    | undefined;
};

/* buyer history details type */
type BuyerHistoryDetailsType = Pick<
  ProductBuyers,
  'gstin' | 'id' | 'name' | 'primaryContactMobile' | 'primaryContactName' | 'primaryContactEmail'
> & { address?: AddressType | null };

/* react functional component */
const BuyerHistoryReportScreen = () => {
  /* this state used to store start and end date, which is selected using financial year radio option */
  const [selectedDate, setSelectedDate] = useState<{
    // prop definition for start date which is set when financial year from select box is present
    startDate: string | null;
    // prop definition for end date which is set when financial year from select box is present
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  /* state used to store buyer data on 'edit profile' button click and to manage form modal visibility */
  const [buyerDataAndIsModalVisible, setBuyerDataAndIsModalVisible] =
    useState<AddOrEditBuyerFormType | null>(null);

  /* useForm declaration */
  const { control, watch } = useForm<BuyerHistoryReportFormType>({
    defaultValues: { buyerId: null },
    mode: 'onChange',
  });

  /* get all buyers query used to show buyers list as options for select buyer field */
  const {
    data: getAllProductBuyerData,
    error: getAllProductBuyerDataError,
    loading: getAllProductBuyerDataLoading,
  } = useQuery<GetAllProductBuyersQuery, GetAllProductBuyersQueryVariables>(getAllBuyersQuery);

  /* get outward shipment orders by buyer query used to display outward shipment information into the table */
  const [
    getOutwardShipmentOrdersByBuyer,
    {
      data: getOutwardShipmentByBuyerData,
      loading: getOutwardShipmentByBuyerDataLoading,
      error: getOutwardShipmentByBuyerDataError,
    },
  ] = useLazyQuery<
    GetOutwardShipmentOrdersByBuyerQuery,
    GetOutwardShipmentOrdersByBuyerQueryVariables
  >(getOutwardShipmentOrdersByBuyerQuery);

  const outwardShipmentDataByBuyerArray =
    getOutwardShipmentByBuyerData && getOutwardShipmentByBuyerData.getOutwardShipmentOrdersByBuyer;

  /**
   * If user selects financial year filter to show data for particular financial year then
   * 'outwardShipmentDataByBuyerArray' is filtered based on financial year
   * otherwise 'outwardShipmentDataByBuyerArray' data is displayed
   */
  const outwardShipmentDataByBuyer = useMemo(() => {
    if (
      selectedDate &&
      selectedDate.startDate &&
      selectedDate.endDate &&
      outwardShipmentDataByBuyerArray
    ) {
      return outwardShipmentDataByBuyerArray.filter((item) => {
        return (
          (item.outwardShipment.createdAt as string) >= (selectedDate.startDate as string) &&
          (item.outwardShipment.createdAt as string) <= (selectedDate.endDate as string)
        );
      });
    }

    return outwardShipmentDataByBuyerArray;
  }, [outwardShipmentDataByBuyerArray, selectedDate]);

  /* get buyer by Id query used to show buyer information  */
  const [
    getBuyerById,
    { data: getBuyerByIdData, loading: getBuyerByIDDataLoading, error: getBuyerByIDDataError },
  ] = useLazyQuery<GetProductBuyerByIdQuery, GetProductBuyerByIdQueryVariables>(
    getProductBuyerByIdQuery,
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllProductBuyerDataError || getOutwardShipmentByBuyerDataError || getBuyerByIDDataError) {
    let errorMessage;
    if (getAllProductBuyerDataError) {
      errorMessage = getAllProductBuyerDataError.message;
    } else if (getOutwardShipmentByBuyerDataError) {
      errorMessage = getOutwardShipmentByBuyerDataError.message;
    } else if (getBuyerByIDDataError) {
      errorMessage = getBuyerByIDDataError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* this function used to close form modal */
  const closeModal = () => {
    setBuyerDataAndIsModalVisible(null);
  };

  /* this react functional component used to show buyer details */
  const BuyerDetails = (): JSX.Element => {
    /* if buyer data loaded , then destruct the data */
    if (getBuyerByIdData) {
      const {
        primaryContactMobile,
        primaryContactName,
        gstin,
        address,
        id,
        name,
        primaryContactEmail,
      } = getBuyerByIdData.getProductBuyerById as BuyerHistoryDetailsType;

      return (
        <Descriptions
          column={5}
          labelStyle={{ fontWeight: 'bold' }}
          contentStyle={{
            color: 'purple',
            fontWeight: '500',
            textTransform: 'capitalize',
            alignItems: 'center',
          }}
          style={{ marginTop: 30 }}
        >
          <Descriptions.Item style={{ width: '12.5%' }}>
            <Button
              type="primary"
              loading={getBuyerByIDDataLoading}
              onClick={() => {
                if (getBuyerByIdData && getBuyerByIdData.getProductBuyerById) {
                  setBuyerDataAndIsModalVisible({
                    id,
                    name,
                    gstin,
                    primaryContactEmail,
                    primaryContactMobile,
                    primaryContactName,
                    address: address ? address.address : '',
                    state: address ? address.state : '',
                    city: address ? address.city : '',
                    pincode: address ? address.pincode : '',
                  });
                }
              }}
            >
              Edit Profile
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label="GST">{gstin || '-'}</Descriptions.Item>
          <Descriptions.Item label="Contact name">{primaryContactName || '-'}</Descriptions.Item>
          <Descriptions.Item label="Contact mobile">
            {primaryContactMobile || '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Address" span={2}>
            {address ? (
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {address.address || '-'}
                <br />
                {address.city} {address.city ? ',' : null} {address.state}
                {address.state ? '-' : null} {address.pincode}
              </div>
            ) : (
              '-'
            )}
          </Descriptions.Item>
        </Descriptions>
      );
    }
    return <div />;
  };

  return (
    <div className="tabLayout">
      {buyerDataAndIsModalVisible ? (
        <Modal
          visible={buyerDataAndIsModalVisible ? true : false}
          title="Edit buyer"
          footer={null}
          onCancel={() => {
            closeModal();
          }}
          className="buyerOrFarmerTraderHistoryModal"
          destroyOnClose
        >
          <div style={{ paddingLeft: 20 }}>
            <AddOrEditBuyerForm
              mode="edit"
              buyerDataToEdit={buyerDataAndIsModalVisible}
              editBuyerCalledFrom="buyerHistory"
              closeModal={() => {
                closeModal();
              }}
            />
          </div>
        </Modal>
      ) : null}
      <form>
        <Row>
          <Col span={12}>
            <FormItem
              label="Select Buyer :"
              isRequired
              inputColSpan={18}
              labelColSpan={6}
              requiredMark="after"
            >
              <Select
                customStyles={inputComponentCommonStyle}
                placeholder="Please select buyer"
                name="buyerId"
                rhfControllerProps={{
                  control,
                }}
                selectProps={{
                  showSearch: true,
                  loading: getAllProductBuyerDataLoading,
                  optionFilterProp: 'children',
                }}
                options={
                  getAllProductBuyerData
                    ? getAllProductBuyerData.getAllProductBuyers.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    : []
                }
                onChange={(rhfOnChange, value: number) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  rhfOnChange(value);
                  if (value) {
                    /* get outward shipment orders */
                    void getOutwardShipmentOrdersByBuyer({
                      variables: {
                        productBuyerId: value,
                      },
                    });

                    /* get buyer details */
                    void getBuyerById({ variables: { id: value } });
                  }
                }}
              />
            </FormItem>
          </Col>
        </Row>
      </form>
      {watch('buyerId') ? (
        <>
          <FinancialYearComponent setSelectedDate={setSelectedDate} isDateRangeRequired={false} />
          <BuyerDetails />
          <Divider style={{ marginTop: 0 }} />
          <Table<BuyerHistoryReportTableType>
            dataSource={outwardShipmentDataByBuyer}
            rowKey="id"
            loading={getOutwardShipmentByBuyerDataLoading}
            className="tableStyle"
            bordered
            size="small"
            pagination={{ showSizeChanger: true }}
          >
            <Table.Column<BuyerHistoryReportTableType>
              title="Shipment Grn "
              dataIndex="outwardShipment"
              key="grn"
              align="center"
              render={(text, record) => {
                return <span>{record.outwardShipment.grn}</span>;
              }}
            />
            <Table.Column<BuyerHistoryReportTableType>
              title="Date"
              dataIndex="truckOutAt"
              key="truckOutAt"
              align="center"
              render={(text, record) => {
                if (record.outwardShipment && record.outwardShipment.createdAt) {
                  return dateFormatFunc(record.outwardShipment.createdAt);
                }
                return null;
              }}
              filterMultiple={false}
              filters={[
                {
                  text: 'Past Day',
                  value: ' day',
                },
                {
                  text: 'Past Week',
                  value: 'week',
                },
                {
                  text: 'Past Month',
                  value: 'month',
                },
                {
                  text: 'Past Year',
                  value: 'year',
                },
              ]}
              onFilter={(value, record) => {
                if (value === 'day') {
                  return dayjs(dayjs(record.outwardShipment.truckOutAt)).isBetween(
                    dayjs().subtract(1, 'days'),
                    dayjs(),
                  );
                }
                if (value === 'week') {
                  return dayjs(dayjs(record.outwardShipment.truckOutAt)).isBetween(
                    dayjs().subtract(7, 'days'),
                    dayjs(),
                  );
                }
                if (value === 'month') {
                  return dayjs(dayjs(record.outwardShipment.truckOutAt)).isBetween(
                    dayjs().subtract(1, 'months'),
                    dayjs(),
                  );
                }
                if (value === 'year') {
                  return dayjs(dayjs(record.outwardShipment.truckOutAt)).isBetween(
                    dayjs().subtract(1, 'years'),
                    dayjs(),
                  );
                }

                return false;
              }}
            />
            <Table.Column<BuyerHistoryReportTableType>
              title="Item"
              dataIndex="product"
              key="item"
              align="center"
              width={200}
              render={(text, record) => {
                return <span>{(record.product && record.product.productType.name) || '-'}</span>;
              }}
              onFilter={(value, record) => {
                return record.product && record.product.productType.name
                  ? record.product.productType.name
                      .toLowerCase()
                      .includes(value.toString().toLowerCase())
                  : false;
              }}
              filterDropdown={(filterDropDownProp) => (
                <ColumnSearchComponent
                  filterBy="item name"
                  filterDropDownProp={filterDropDownProp}
                />
              )}
              filterIcon={(filtered) => {
                return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
              }}
            />
            <Table.Column<BuyerHistoryReportTableType>
              title="Brand"
              dataIndex="product"
              key="brand"
              align="center"
              width={200}
              render={(text, record) => {
                return <span> {(record.product && record.product.brand) || '-'}</span>;
              }}
              onFilter={(value, record) => {
                return record.product && record.product.brand
                  ? record.product.brand.toLowerCase().includes(value.toString().toLowerCase())
                  : false;
              }}
              filterDropdown={(filterDropDownProp) => (
                <ColumnSearchComponent
                  filterBy="brand name"
                  filterDropDownProp={filterDropDownProp}
                />
              )}
              filterIcon={(filtered) => {
                return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
              }}
            />
            <Table.Column<BuyerHistoryReportTableType>
              title="No. of bags"
              dataIndex="loadedBagsCount"
              key="loadedBagsCount"
              align="center"
              width={130}
              render={(noOfBags) => <span>{noOfBags || '-'}</span>}
            />
            <Table.Column<BuyerHistoryReportTableType>
              title="Bag packing wt. (kg)"
              dataIndex="bagSizeKg"
              key="bagSizeKg"
              align="center"
              width={130}
              render={(bagSizeKg) => <span>{bagSizeKg || '-'}</span>}
            />
            <Table.Column<BuyerHistoryReportTableType>
              title="Net Material weight (quintals) "
              dataIndex="shipment"
              key="shipment"
              align="center"
              width={130}
              render={(text, record) => {
                if (record.bagSizeKg && record.loadedBagsCount) {
                  const netWeight = (record.bagSizeKg * record.loadedBagsCount) / 100;
                  return <span>{netWeight.toFixed(2)}</span>;
                }
                return '-';
              }}
            />
            <Table.Column<BuyerHistoryReportTableType>
              title="Invoice"
              dataIndex="invoiceNo"
              key="invoiceNo"
              align="center"
              render={(invoice) => <span>{invoice || '-'}</span>}
            />
          </Table>
        </>
      ) : null}
    </div>
  );
};

export default BuyerHistoryReportScreen;
