import React from 'react';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetAllInwardShipmentForOtherItemsQuery,
  GetAllInwardShipmentForOtherItemsQueryVariables,
} from '../../../../graphql/graphql-types';
import { DailyReportsPropType, InwardShipmentsReportType } from '../../../../utils/types';
import InwardShipmentBagOrOtherItemReportTable from '../../InwardShipmentBagOrOtherItemReportTable';

/* loading get all inward shipment for OtherItems data query */
const getAllInwardShipmentForOtherItemsQuery = loader(
  '../../../../graphql/queries/getAllInwardShipmentForOtherItemsQuery.graphql',
);

/* React functional component */
const DailyReportsInwardOtherItemsScreen = ({ startDate, endDate }: DailyReportsPropType) => {
  /* get All Inward Shipment For OtherItems data using query with start and end date from date range field */
  const {
    data: getAllInwardShipmentForOtherItemsData,
    loading: getAllInwardShipmentForOtherItemsLoading,
    error: getAllInwardShipmentForOtherItemsError,
  } = useQuery<
    GetAllInwardShipmentForOtherItemsQuery,
    GetAllInwardShipmentForOtherItemsQueryVariables
  >(getAllInwardShipmentForOtherItemsQuery, {
    variables: {
      createdAt:
        startDate && endDate
          ? {
              _gte: startDate,
              _lte: endDate,
            }
          : {},
    },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getAllInwardShipmentForOtherItemsLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllInwardShipmentForOtherItemsError) {
    return <div className="errorText">{getAllInwardShipmentForOtherItemsError.message}</div>;
  }

  return (
    <>
      {getAllInwardShipmentForOtherItemsData ? (
        <InwardShipmentBagOrOtherItemReportTable
          data={
            getAllInwardShipmentForOtherItemsData.inwardShipments as InwardShipmentsReportType[]
          }
          inwardShipmentType="otherItems"
          reportScreenType="daily"
        />
      ) : null}
    </>
  );
};

export default DailyReportsInwardOtherItemsScreen;
