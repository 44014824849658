import React, { useState } from 'react';
import { Button, message } from 'antd';
import { useForm } from 'react-hook-form';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { loader } from 'graphql.macro';
import {
  CreateProductInternalShipmentItemMutation,
  CreateProductInternalShipmentItemMutationVariables,
  Enum_InternalTransfer_Source_Destination_Enum,
  GetAllGodownsMillsQuery,
  GetAllGodownsMillsQueryVariables,
  GetAllProductsQuery,
  GetAllProductsQueryVariables,
  UpdateProductInternalShipmentItemMutation,
  UpdateProductInternalShipmentItemMutationVariables,
} from '../../../graphql/graphql-types';
import {
  CreateOrEditMaterialLoadingFormType,
  InternalTransferShipmentItemTableType,
} from '../../../utils/types';
import {
  formItemStyleProps as AddMaterialLoadingFormItemStyleProps,
  inputComponentCommonStyle,
} from '../../../utils/globals';
import { logger } from '../../../utils/helpers';
import FormItem from '../../../components/FormItem';
import InputNumber from '../../../components/InputNumber';
import RadioGroup from '../../../components/RadioGroup';
import Select from '../../../components/Select';

/* loading get all products query */
const getAllProductsQuery = loader('../../../graphql/queries/getAllProductsQuery.graphql');

/* get all godown and mills query */
const getGodownAndMillsQuery = loader('../../../graphql/queries/getAllGodownsMillsQuery.graphql');

/* loading get product inward shipment items query */
const getProductInwardShipmentItemsQuery = loader(
  '../../../graphql/queries/getProductInwardShipmentItemsQuery.graphql',
);

/* loading create product internal shipment item mutation  */
const createProductInternalShipmentItemMutation = loader(
  '../../../graphql/mutations/createProductInternalShipmentItemMutation.graphql',
);

/* loading update product internal shipment item mutation */
const updateProductInternalShipmentItemMutation = loader(
  '../../../graphql/mutations/updateProductInternalShipmentItemMutation.graphql',
);

/* create or edit material loading form prop type */
type CreateOrEditMaterialLoadingFormPropType = {
  /* prop type used to check whether form is being called from 'edit' mode or 'add' mode */
  mode: 'add' | 'edit';
  /* this prop type used to store id of the shipment which is selected to unload */
  shipmentIdSelectedToUnload: string;
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  shipmentItemDataToEdit?: CreateOrEditMaterialLoadingFormType;
  /* prop type used to close CreateOrEditShipmentItem form modal */
  closeModal?: () => void;
  /* prop type used to stored internal transfer shipment items data */
  internalTransferShipmentItemsData?: Array<InternalTransferShipmentItemTableType>;
};

/* this formItem style used when material loading form is in edit mode */
const EditMaterialLoadingFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 9,
  /* input column span of FormItem */
  inputColSpan: 14,
  /* formItem label style */
  labelStyle: { fontSize: 14 } as React.CSSProperties,
  /* formItem content style */
  customStyle: { paddingTop: 12, fontSize: 13 } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* validations required for useForm */
const schema = yup.object({
  productTypeId: yup.number().nullable().required('Please select product type and try again.'),
  productId: yup
    .number()
    .nullable()
    .when(['productTypeId'], {
      is: (productTypeId: number | null) => productTypeId !== null,
      then: yup.number().nullable().required('Please select product and try again.'),
    }),
  bagsCount: yup
    .number()
    .required('Please enter number of bags and try again.')
    .integer('Please enter valid number.')
    .min(0, 'Please enter valid number')
    .nullable(),
  sourceMillId: yup
    .number()
    .nullable()
    .when(['source'], {
      is: (source: string) => source === 'mill',
      then: yup.number().nullable().required('Please select mill and try again.'),
    }),
  sourceGodownId: yup
    .number()
    .nullable()
    .when(['source'], {
      is: (source: string) => source === 'godown',
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
  destinationGodownId: yup
    .number()
    .nullable()
    .when(['destination'], {
      is: (destination: string) => destination === 'godown',
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
  destinationMillId: yup
    .number()
    .nullable()
    .when(['destination'], {
      is: (destination: string) => destination === 'mill',
      then: yup.number().nullable().required('Please select mill and try again.'),
    }),
  singleBagWeight: yup
    .number()
    .nullable()
    .positive('Please enter valid number')
    .min(0, 'Please enter valid number')
    .required('Please enter single bag weight (in kgs) and try again.'),
});

/* React functional component */
const CreateOrEditMaterialLoadingForm = ({
  mode,
  shipmentItemDataToEdit = undefined,
  shipmentIdSelectedToUnload,
  closeModal = () => {},
  internalTransferShipmentItemsData = undefined,
}: CreateOrEditMaterialLoadingFormPropType) => {
  /*  state used to show loading indicator on submit or update button */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* formItemStyleProps used to store formItem style props based on 'mode & shipmentItemDataToEdit' prop value */
  const formItemStyleProps =
    shipmentItemDataToEdit && mode === 'edit'
      ? EditMaterialLoadingFormItemStyleProps
      : AddMaterialLoadingFormItemStyleProps;

  /* get all product data query */
  const {
    data: getAllProductsData,
    loading: getAllProductsLoading,
    error: getAllProductsError,
  } = useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(getAllProductsQuery);

  /* get all godown and mills data query  */
  const {
    data: getGodownAndMillsData,
    loading: getGodownAndMillsDataLoading,
    error: getGodownAndMillsDataError,
  } = useQuery<GetAllGodownsMillsQuery, GetAllGodownsMillsQueryVariables>(getGodownAndMillsQuery);

  /* create product internal shipment item mutation */
  const [createProductInternalShipmentItem] = useMutation<
    CreateProductInternalShipmentItemMutation,
    CreateProductInternalShipmentItemMutationVariables
  >(createProductInternalShipmentItemMutation);

  /* update product internal shipment item mutation */
  const [updateProductInternalShipmentItem] = useMutation<
    UpdateProductInternalShipmentItemMutation,
    UpdateProductInternalShipmentItemMutationVariables
  >(updateProductInternalShipmentItemMutation);

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<CreateOrEditMaterialLoadingFormType>({
    defaultValues:
      mode === 'edit' && shipmentItemDataToEdit
        ? {
            productTypeId: shipmentItemDataToEdit.productTypeId,
            productId: shipmentItemDataToEdit.productId,
            bagsCount: shipmentItemDataToEdit.bagsCount,
            singleBagWeight: shipmentItemDataToEdit.singleBagWeight,
            source: shipmentItemDataToEdit.source,
            sourceGodownId: shipmentItemDataToEdit.sourceGodownId,
            sourceMillId: shipmentItemDataToEdit.sourceMillId,
            destination: shipmentItemDataToEdit.destination,
            destinationGodownId: shipmentItemDataToEdit.destinationGodownId,
            destinationMillId: shipmentItemDataToEdit.destinationMillId,
          }
        : {
            productTypeId: null,
            productId: null,
            singleBagWeight: null,
            bagsCount: null,
            source: Enum_InternalTransfer_Source_Destination_Enum.Godown,
            sourceGodownId: null,
            sourceMillId: null,
            destination: Enum_InternalTransfer_Source_Destination_Enum.Godown,
            destinationGodownId: null,
          },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* productDataOptions  is a stored array of product data for a specific product type id. */
  const productDataOptions =
    getAllProductsData &&
    getAllProductsData.getAllProducts &&
    getAllProductsData.getAllProducts.length > 0
      ? getAllProductsData.getAllProducts.filter((item) => {
          return item.productType.id === watch('productTypeId');
        })
      : [];

  /* function to handle form submit */
  const onSubmit = (submittedFormData: CreateOrEditMaterialLoadingFormType) => {
    setIsSubmitOrUpdateBtnLoading(true);
    /* destructing form data */
    const {
      productId,
      destination,
      singleBagWeight,
      bagsCount,
      source,
      destinationGodownId,
      destinationMillId,
      sourceGodownId,
      sourceMillId,
    } = submittedFormData;

    /* this const used to store mutation variables which are common in both 'create product internal shipment item' and 'update product internal shipment item' mutation */
    const commonMutationVariables = {
      productId: productId as number,
      bagWtKg: singleBagWeight,
      source: source as Enum_InternalTransfer_Source_Destination_Enum,
      destination: destination as Enum_InternalTransfer_Source_Destination_Enum,
      destinationGodownId: destinationGodownId,
      destinationMillId: destinationMillId,
      sourceGodownId: sourceGodownId,
      sourceMillId: sourceMillId,
      internalTransferShipmentId: shipmentIdSelectedToUnload,
    };

    /* stored shipment items data array after true all conditions  */
    const shipmentItemsData =
      internalTransferShipmentItemsData &&
      internalTransferShipmentItemsData.find(
        (item) =>
          item.product &&
          item.product.id === productId &&
          item.bagWtKg === singleBagWeight &&
          ((item.destinationGodown && item.destinationGodown.id === destinationGodownId) ||
            (item.destinationMill && item.destinationMill.id === destinationMillId)) &&
          ((item.sourceGodown && item.sourceGodown.id === sourceGodownId) ||
            (item.sourceMill && item.sourceMill.id === sourceMillId)),
      );

    /* if  shipmentItemDataToEdit or shipmentItemsData and shipmentIdSelectedToUnload present then execute update product internal shipment item mutation otherwise execute create product internal shipment item mutation   */
    if ((shipmentItemDataToEdit || shipmentItemsData) && shipmentIdSelectedToUnload) {
      /* update product internal shipment item mutation */
      updateProductInternalShipmentItem({
        variables: {
          id: shipmentItemDataToEdit
            ? shipmentItemDataToEdit.id
            : String(shipmentItemsData && shipmentItemsData.id),
          bagsCount: shipmentItemDataToEdit
            ? (bagsCount as number)
            : (shipmentItemsData && shipmentItemsData.bagsCount ? shipmentItemsData.bagsCount : 0) +
              (bagsCount ? bagsCount : 0),
          ...commonMutationVariables,
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment item has been successfully updated.');
          reset();
          /* close modal */
          closeModal();
          setIsSubmitOrUpdateBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          setIsSubmitOrUpdateBtnLoading(false);
          logger(error);
        });
    } else {
      /* create product internal shipment item mutation */
      createProductInternalShipmentItem({
        variables: {
          bagsCount: bagsCount as number,
          ...commonMutationVariables,
        },
        refetchQueries: [
          {
            query: getProductInwardShipmentItemsQuery,
            variables: { _eq: shipmentIdSelectedToUnload },
          },
        ],
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment item has been successfully created.');
          reset();
          setIsSubmitOrUpdateBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          setIsSubmitOrUpdateBtnLoading(false);
          logger(error);
        });
    }
  };

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getGodownAndMillsDataError || getAllProductsError) {
    return (
      <div className="errorText">
        {(getGodownAndMillsDataError && getGodownAndMillsDataError.message) ||
          (getAllProductsError && getAllProductsError.message)}
      </div>
    );
  }

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormItem
        label="Product type"
        isRequired
        requiredMark="after"
        {...formItemStyleProps}
        errorText={errors && errors.productTypeId ? errors.productTypeId.message : undefined}
      >
        <Select
          customStyles={inputComponentCommonStyle}
          placeholder="Please select product type"
          name="productTypeId"
          rhfControllerProps={{
            control,
          }}
          selectProps={{
            showSearch: true,
            loading: getAllProductsLoading,
            optionFilterProp: 'children',
          }}
          options={
            getAllProductsData
              ? getAllProductsData.getAllProductTypes.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))
              : []
          }
        />
      </FormItem>
      {watch('productTypeId') ? (
        <FormItem
          label="Product"
          isRequired
          requiredMark="after"
          {...formItemStyleProps}
          errorText={errors && errors.productId ? errors.productId.message : undefined}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select product"
            name="productId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              loading: getAllProductsLoading,
              optionFilterProp: 'children',
            }}
            options={
              productDataOptions && productDataOptions.length > 0
                ? productDataOptions.map((item) => ({
                    value: item.id,
                    label: item.brand,
                  }))
                : []
            }
          />
        </FormItem>
      ) : null}
      <FormItem
        label="Single Bag Weight (in kgs)"
        {...formItemStyleProps}
        isRequired
        requiredMark="after"
        errorText={errors && errors.singleBagWeight ? errors.singleBagWeight.message : undefined}
      >
        <InputNumber
          customStyles={inputComponentCommonStyle}
          placeholder="Please enter single bag weight (in kgs)"
          name="singleBagWeight"
          rhfControllerProps={{
            control,
          }}
          inputNumberProps={{ min: 0, precision: 2 }}
        />
      </FormItem>
      <FormItem
        label="No. Of Bags"
        isRequired
        requiredMark="after"
        {...formItemStyleProps}
        errorText={errors && errors.bagsCount ? errors.bagsCount.message : undefined}
      >
        <InputNumber
          customStyles={inputComponentCommonStyle}
          placeholder="Please enter number of bags"
          name="bagsCount"
          rhfControllerProps={{
            control,
          }}
          inputNumberProps={{ min: 0 }}
        />
      </FormItem>
      <FormItem label="Source" isRequired requiredMark="after" {...formItemStyleProps}>
        <RadioGroup
          name="source"
          rhfControllerProps={{
            control,
          }}
          defaultValue={null}
          options={[
            { label: 'Godown', value: 'godown' },
            {
              label: 'Mill',
              value: 'mill',
            },
          ]}
          onChange={(e) => {
            /* on selection of 'source', set 'source godown id ' or 'source mill id ' as a null */
            if (e.target.value === 'mill') {
              setValue('sourceGodownId', null);
            } else {
              setValue('sourceMillId', null);
            }
          }}
        />
      </FormItem>
      {watch('source') === 'mill' ? (
        <FormItem
          label="Select Source Mill ID"
          isRequired
          requiredMark="after"
          errorText={errors && errors.sourceMillId ? errors.sourceMillId.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select mill ID"
            name="sourceMillId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getGodownAndMillsDataLoading,
            }}
            options={
              getGodownAndMillsData
                ? getGodownAndMillsData.getAllMills.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                : []
            }
          />
        </FormItem>
      ) : (
        <FormItem
          label="Select Source Godown ID"
          isRequired
          requiredMark="after"
          errorText={errors && errors.sourceGodownId ? errors.sourceGodownId.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select godown ID"
            name="sourceGodownId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getGodownAndMillsDataLoading,
            }}
            options={
              getGodownAndMillsData
                ? getGodownAndMillsData.getAllGodowns.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                : []
            }
          />
        </FormItem>
      )}

      <FormItem label="Destination" isRequired requiredMark="after" {...formItemStyleProps}>
        <RadioGroup
          name="destination"
          rhfControllerProps={{
            control,
          }}
          defaultValue={null}
          options={[
            { label: 'Godown', value: 'godown' },
            {
              label: 'Mill',
              value: 'mill',
            },
          ]}
          onChange={(e) => {
            /* on selection of 'destination', set 'destination godown id ' or 'destination mill id ' as a null */
            if (e.target.value === 'mill') {
              setValue('destinationGodownId', null);
            } else {
              setValue('destinationMillId', null);
            }
          }}
        />
      </FormItem>
      {watch('destination') === 'mill' ? (
        <FormItem
          label="Select Destination Mill ID"
          isRequired
          requiredMark="after"
          {...formItemStyleProps}
          errorText={
            errors && errors.destinationMillId ? errors.destinationMillId.message : undefined
          }
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select mill ID"
            name="destinationMillId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getGodownAndMillsDataLoading,
            }}
            options={
              getGodownAndMillsData
                ? getGodownAndMillsData.getAllMills.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                : []
            }
          />
        </FormItem>
      ) : (
        <FormItem
          label="Select Destination Godown ID"
          isRequired
          requiredMark="after"
          {...formItemStyleProps}
          errorText={
            errors && errors.destinationGodownId ? errors.destinationGodownId.message : undefined
          }
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select godown ID"
            name="destinationGodownId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getGodownAndMillsDataLoading,
            }}
            options={
              getGodownAndMillsData
                ? getGodownAndMillsData.getAllGodowns.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
                : []
            }
          />
        </FormItem>
      )}

      <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
        <div
          style={
            mode === 'edit' ? { display: 'flex', justifyContent: 'end' } : { display: 'block' }
          }
        >
          <Button
            htmlType="submit"
            type="primary"
            loading={isSubmitOrUpdateBtnLoading}
            style={{ marginRight: 10 }}
          >
            {mode === 'edit' ? 'Update' : 'Submit'}
          </Button>
          {mode === 'edit' ? (
            <Button
              type="default"
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
          ) : null}
        </div>
      </FormItem>
    </form>
  );
};

export default CreateOrEditMaterialLoadingForm;
