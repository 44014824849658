import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import {
  useQuery,
  useLazyQuery,
  ApolloError,
  useMutation,
  gql,
  useApolloClient,
} from '@apollo/client';
import { Button, message, Divider, Spin, Modal } from 'antd';
import { FieldError, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  GetAllTransportersQuery,
  GetAllTransportersQueryVariables,
  GetAllTransporterDriversQuery,
  GetAllTransporterDriversQueryVariables,
  GetOutwardShipmentsForOrdersQuery,
  GetOutwardShipmentsForOrdersQueryVariables,
  CreateOutwardShipmentMutation,
  CreateOutwardShipmentMutationVariables,
  GetAllVehiclesOfTransporterQuery,
  GetAllVehiclesOfTransporterQueryVariables,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
  GetSettingByNameQuery,
  GetSettingByNameQueryVariables,
} from '../../../graphql/graphql-types';
import RequiredMessage from '../../../components/RequiredMessage';
import FormItem from '../../../components/FormItem';
import Select from '../../../components/Select';
import { inputComponentCommonStyle } from '../../../utils/globals';
import InputNumber from '../../../components/InputNumber';
import OpenOutwardShipmentsTable from './OpenOutwardShipmentsTable';
import AddOrEditTransporterForm from '../../management/transporters/AddOrEditTransporterForm';
import AddOrEditTransporterVehicleForm from '../../management/transporterVehicles/AddOrEditTransporterVehicleForm';
import AddAndEditTransporterDriverForm from '../../management/drivers/AddAndEditTransporterDriverForm';
import {
  CreateProductOutwardShipmentFormType,
  ProductOpenOutwardShipmentType,
} from '../../../utils/types';
import { handleGetWeightFunction, logger } from '../../../utils/helpers';

/* attach driver to outward shipment mutation object type */
type AttachDriverWithOutwardObjectType = {
  /* prop type used to store shipment id */
  outwardShipmentId: number;
  /* prop type used to store driver id */
  transporterDriverId: number;
};

/* modal's form name key type */
type FormNameKeyType =
  | 'createTransporter'
  | 'createTransporterVehicle'
  | 'createTransporterDriver'
  | null;

/* loading get all transporter query with the help of loader */
const getAllTransporterQuery = loader('../../../graphql/queries/getAllTransportersQuery.graphql');

/* loading get all vehicles of transporter query with the help of loader */
const getAllVehiclesOfTransporterQuery = loader(
  '../../../graphql/queries/getAllVehiclesOfTransporterQuery.graphql',
);

/* loading get all open outward shipment query with the help of loader */
const getAllOpenOutwardShipmentsForOrdersQuery = loader(
  '../../../graphql/queries/getOutwardShipmentsForOrdersQuery.graphql',
);

/* loading get all transporter's drivers query with the help of loader */
const getAllTransporterDriversQuery = loader(
  '../../../graphql/queries/getAllTransporterDriversQuery.graphql',
);

/* loading create outward shipment mutation  */
const createOutwardShipmentMutation = loader(
  '../../../graphql/mutations/createOutwardShipmentMutation.graphql',
);

/* loading attach driver to outward shipment mutation  */
const attachDriverToOutwardShipmentMutation = loader(
  '../../../graphql/mutations/attachDriversToOutwardShipmentMutation.graphql',
);

/* loading create outward shipment mutation  */
const deleteOutwardShipmentMutation = loader(
  '../../../graphql/mutations/deleteOutwardShipmentMutation.graphql',
);

/* loading get bhagwati mill id query with the help of loader */
const getBhagwatiMillIdQuery = loader('../../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* loading get setting by name query with the help of loader */
const getSettingByNameQuery = loader('../../../graphql/queries/getSettingByNameQuery.graphql');

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 13,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
};

/* form validation schema */
const schema = yup.object({
  transporterId: yup.number().nullable().required('Please select transporter name and try again.'),
  vehicleId: yup
    .number()
    .nullable()
    .when(['transporterId'], {
      is: (transporterId: number) => transporterId !== null,
      then: yup.number().nullable().required('Please select vehicle number and try again.'),
    }),
  driverIds: yup
    .array()
    .nullable()
    .when(['transporterId'], {
      is: (transporterId: number) => transporterId !== null,
      then: yup
        .array()
        .nullable()
        .min(1, 'Please select driver and try again.')
        .required('Please select driver and try again.'),
    }),
  tareWeightInKg: yup
    .number()
    .nullable()
    .when(['transporterId'], {
      is: (transporterId: number) => transporterId !== null,
      then: yup
        .number()
        .nullable()
        .required('Please enter tare weight and try again.')
        .moreThan(0, 'Tare Weight cannot be 0 or less then 0.'),
    }),
});

/* this variable used to store title of the modal */
let modalTitle: string;

/* react functional component */
const CreateProductOutwardShipmentScreen = (): JSX.Element => {
  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* state used show loading indicator on 'Create' or 'Update' button when creating a new shipment or updating existing Shipment */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* state to show loading indicator on get weight button */
  const [getWeightButtonLoading, setGetWeightButtonLoading] = useState<boolean>(false);

  /* this state used to store shipment data, whose 'edit' button has clicked. which is then used to edit that shipment data */
  const [editShipmentData, setEditShipmentData] = useState<ProductOpenOutwardShipmentType | null>(
    null,
  );

  /* this state used to manage modal visibility & to determine which form get display in modal content, using 'formName' */
  const [isModalVisibleAndFormName, setIsModalVisibleAndFormName] = useState<{
    /* determine modal visibility */
    isModalVisible: boolean;
    /* this prop used to store key for the form name, which is going to display in modal */
    formNameKey: FormNameKeyType;
  }>({ isModalVisible: false, formNameKey: null });

  /* get All transporters query used to display name of transporters, as a dropdown list on 'transporterId' form field */
  const {
    data: getAllTransporters,
    loading: getAllTransportersLoading,
    error: getAllTransportersError,
  } = useQuery<GetAllTransportersQuery, GetAllTransportersQueryVariables>(getAllTransporterQuery, {
    fetchPolicy: 'cache-and-network',
  });

  /* get All vehicles of transporter query used to get list of vehicles for selected 'transporterId' in the form.
  and display that vehicles as a dropdown list on 'vehicleId' form field */
  const [
    getVehiclesOfTransporter,
    {
      data: getAllVehiclesOfTransporterData,
      loading: getAllVehiclesOfTransporterDataLoading,
      error: getAllVehiclesOfTransporterDataError,
    },
  ] = useLazyQuery<GetAllVehiclesOfTransporterQuery, GetAllVehiclesOfTransporterQueryVariables>(
    getAllVehiclesOfTransporterQuery,
  );

  /* get All open outward shipment query used to display open outward shipments into table */
  const {
    data: getAllOpenOutwardShipmentsData,
    loading: getAllOpenOutwardShipmentsDataLoading,
    error: getAllOpenOutwardShipmentsDataError,
  } = useQuery<GetOutwardShipmentsForOrdersQuery, GetOutwardShipmentsForOrdersQueryVariables>(
    getAllOpenOutwardShipmentsForOrdersQuery,
  );

  /* get all transporter driver query used to display, 'list of drivers with their mobile number' as a dropdown list on 'driverId' form field */
  const {
    data: getAllTransporterDriverData,
    error: getAllTransporterDriverDataError,
    loading: getAllTransporterDriverDataLoading,
  } = useQuery<GetAllTransporterDriversQuery, GetAllTransporterDriversQueryVariables>(
    getAllTransporterDriversQuery,
  );

  /* get bhagwati mill id query used to display mill name in the form */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* setting by name query is used to determine whether the 'emptyVehicleWtKg' field is enabled or disabled. */
  const {
    data: weightInputDisabledData,
    error: getSettingByNameError,
    loading: getSettingByNameLoader,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weightInputDisabled' },
  });

  /* create outward shipment mutation */
  const [createOutwardShipment] = useMutation<
    CreateOutwardShipmentMutation,
    CreateOutwardShipmentMutationVariables
  >(createOutwardShipmentMutation);

  /* show loading indicator on the screen until data get fetch from the server */
  if (getSettingByNameLoader || getBhagwatiMillIdQueryLoader) {
    <Spin className="loadingSpinner" />;
  }

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<CreateProductOutwardShipmentFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      vehicleId: null,
      driverIds: [],
      tareWeightInKg: null,
      transporterId: null,
    },
    mode: 'onChange',
  });

  /* show error text on the screen. if it has any error while loading data from the server */
  if (
    getAllTransportersError ||
    getAllVehiclesOfTransporterDataError ||
    getAllOpenOutwardShipmentsDataError ||
    getAllTransporterDriverDataError ||
    getBhagwatiMillIdQueryError ||
    getSettingByNameError
  ) {
    let errorMessage;
    if (getAllTransportersError) {
      errorMessage = getAllTransportersError.message;
    } else if (getAllVehiclesOfTransporterDataError) {
      errorMessage = getAllVehiclesOfTransporterDataError.message;
    } else if (getAllOpenOutwardShipmentsDataError) {
      errorMessage = getAllOpenOutwardShipmentsDataError.message;
    } else if (getAllTransporterDriverDataError) {
      errorMessage = getAllTransporterDriverDataError.message;
    } else if (getBhagwatiMillIdQueryError) {
      errorMessage = getBhagwatiMillIdQueryError.message;
    } else if (getSettingByNameError) {
      errorMessage = getSettingByNameError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* function to get weight of vehicle */
  const getVehicleWeight = () => {
    setGetWeightButtonLoading(true);
    // const to store vehicle weight from weighbridge
    handleGetWeightFunction(apolloClient)
      .then((vehicleWeight) => {
        // setting tare weight of the vehicle
        setValue('tareWeightInKg', vehicleWeight);

        // if 'tareWeightInKg' field has any error then clear it, after setting it's value
        if (errors.tareWeightInKg) {
          clearErrors('tareWeightInKg');
        }
        setGetWeightButtonLoading(false);
      })
      .catch((err: ApolloError) => {
        logger(err);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(err.message);
        setGetWeightButtonLoading(false);
      });
  };

  /* function to handle form submit */
  const onSubmit = (formData: CreateProductOutwardShipmentFormType) => {
    setIsSubmitOrUpdateBtnLoading(true);
    /* destructing form data */
    const { tareWeightInKg, transporterId, vehicleId, driverIds } = formData;

    /* this const used to store mutation variables, which are common in both 'create shipment' & 'update shipment' mutation */
    const commonMutationVariables = {
      transporterId: transporterId as number,
      transporterVehicleId: vehicleId as number,
      emptyVehicleWtKg: tareWeightInKg as number,
    };

    /* if user has selected any shipment to 'edit' then execute 'update shipment mutation' */
    if (editShipmentData) {
      /* const used to store update outward shipment mutation string */
      const updateOutwardShipmentMutationString = `update_outwardShipments_by_pk(pk_columns: {id: $outwardShipmentId}, _set: {transporterId: $transporterId, transporterVehicleId: $transporterVehicleId, emptyVehicleWtKg: $emptyVehicleWtKg}) {
        id
        createdAt
        grn
        transporter {
          id
          name
        }
        transporterVehicle {
          id
          number
          capacityKgs
          rcS3Key
        }
        drivers {
          driver {
            id
            mobile
            name
            panFile
            photo
          }
        }
        orders {
          id
          number
          bagSizeKg
          plannedBagsCount
          loadedBagsCount
          invoiceNo
          product {
            id
            brand
            productType {
              id
              name
            }
          }
          buyer {
            id
            name
          }
        }
        fullVehicleWtKg
        emptyVehicleWtKg
        status
      }`;

      /* const used to store attach driver to outward shipment mutation string */
      const attachDrivertoOutwardShipmentMutationString = ` insert_outwardShipmentsDrivers(objects: $objects) {
        returning {
          driver {
            id
            mobile
            name
            panFile
            photo
          }
        }
      }`;

      /* const used to store delete deriver mutation string */
      const deleteDriversMutationString = `delete_outwardShipmentsDrivers(where: {_and: {transporterDriverId: {_in: $driverIds}, outwardShipmentId: {_eq: $outwardShipmentId}}}) {
        returning {
          transporterDriverId
        }
      }`;

      /* variable used to store final mutation string */
      let finalMutationString = updateOutwardShipmentMutationString;

      /* this const object array used to store initial driver data whose edit button is clicked */
      const newlyAddedDriversObjectArray: Array<AttachDriverWithOutwardObjectType> = [];

      /* this const used to store initial driver id's, whose edit button is clicked*/
      const initialDataOfDriver: number[] = [];

      /* assign initial shipment driver id's to initialDataOfDriver array */
      editShipmentData.drivers.forEach(({ driver }) => {
        initialDataOfDriver.push(driver.id);
      });

      /* if user has added any new driver, then create object for that driver id with shipment id, so that we can pass this object array to mutation */
      driverIds
        .filter((elementId) => !initialDataOfDriver.includes(elementId))
        .forEach((ele) => {
          newlyAddedDriversObjectArray.push({
            outwardShipmentId: editShipmentData.id,
            transporterDriverId: ele,
          });
        });

      /* if user has deleted any driver from existing driver list then store those ids into array,
      so that we can later use this array in mutation */
      const deletedDriverIdArray = initialDataOfDriver.filter((item) => !driverIds.includes(item));

      /* this variable used to store, final mutation string according to condition that we have to check for executing final mutation  */
      let newFinalMutation: string;

      /* this variable used to store variables for update outward shipment mutation.  */
      let updateMutationVariables;

      /* if user deleted any driver & also added new driver then execute mutation for this */
      if (deletedDriverIdArray.length > 0 && newlyAddedDriversObjectArray.length > 0) {
        /* append delete driver mutation string to final mutation string */
        finalMutationString += deleteDriversMutationString;

        /* append attach driver to outward mutation string to final mutation string */
        finalMutationString += attachDrivertoOutwardShipmentMutationString;

        /* create new mutation string */
        newFinalMutation = `mutation( $driverIds: [Int!]!, $outwardShipmentId: Int!, $transporterId: Int!, $transporterVehicleId: Int!, $emptyVehicleWtKg: float8!,$objects: [outwardShipmentsDrivers_insert_input!]!) {
          ${finalMutationString}
       }`;

        /* create mutation variable for update outward shipment mutation */
        updateMutationVariables = {
          driverIds: deletedDriverIdArray,
          outwardShipmentId: editShipmentData.id,
          ...commonMutationVariables,
          objects: newlyAddedDriversObjectArray,
        };
      } else if (deletedDriverIdArray.length > 0 && newlyAddedDriversObjectArray.length === 0) {
        /* if user deleted any driver but not added any new driver then execute this mutation */

        /* append delete driver mutation string to final mutation string */
        finalMutationString += deleteDriversMutationString;

        /* create new mutation string */
        newFinalMutation = `mutation( $driverIds: [Int!]!, $outwardShipmentId: Int!, $transporterId: Int!, $transporterVehicleId: Int!, $emptyVehicleWtKg: float8!) {
          ${finalMutationString}
       }`;

        /* create mutation variable for update outward shipment mutation */
        updateMutationVariables = {
          driverIds: deletedDriverIdArray,
          outwardShipmentId: editShipmentData.id,
          ...commonMutationVariables,
        };
      } else if (newlyAddedDriversObjectArray.length > 0 && deletedDriverIdArray.length === 0) {
        /* and if user just added new driver, and not deleted any driver then execute this mutation */

        /* append attach driver to outward mutation string to final mutation string */
        finalMutationString += attachDrivertoOutwardShipmentMutationString;

        /* create new mutation string */
        newFinalMutation = `mutation($outwardShipmentId: Int!, $transporterId: Int!, $transporterVehicleId: Int!, $emptyVehicleWtKg: float8!,$objects: [outwardShipmentsDrivers_insert_input!]!) {
          ${finalMutationString}
       }`;

        /* create mutation variable for update outward shipment mutation */
        updateMutationVariables = {
          outwardShipmentId: editShipmentData.id,
          ...commonMutationVariables,
          objects: newlyAddedDriversObjectArray,
        };
      } else {
        /* and if user not edited drivers, then execute this mutation */
        newFinalMutation = `mutation($outwardShipmentId: Int!, $transporterId: Int!, $transporterVehicleId: Int!, $emptyVehicleWtKg: float8!) {
          ${finalMutationString}
       }`;

        /* create mutation variable for update outward shipment mutation */
        updateMutationVariables = {
          outwardShipmentId: editShipmentData.id,
          ...commonMutationVariables,
        };
      }

      // call to mutation according to conditions
      apolloClient
        .mutate({
          mutation: gql`
            ${newFinalMutation}
          `,
          variables: {
            ...updateMutationVariables,
          },
          /* refetch get all open outward shipment query, to get updated list if shipment after updating any shipment data */
          refetchQueries: [{ query: getAllOpenOutwardShipmentsForOrdersQuery }],
        })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Outward shipment updated.');
          setIsSubmitOrUpdateBtnLoading(false);
          setEditShipmentData(null);

          reset();
        })
        .catch((error: ApolloError) => {
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    } else {
      createOutwardShipment({
        variables: {
          ...commonMutationVariables,
        },
      })
        .then((res) => {
          /* this const used to store array of newly added driver id with shipment id by creating a object,
           so that we can pass this object array to attach driver to outward mutation */
          const driverObjectArray: Array<AttachDriverWithOutwardObjectType> = [];

          /* create driver id with shipment id object array, after creating a new shipment */
          driverIds.forEach((element) => {
            if (
              res &&
              res.data &&
              res.data.createOutwardShipment &&
              res.data.createOutwardShipment.id
            ) {
              driverObjectArray.push({
                outwardShipmentId: res.data.createOutwardShipment.id,
                transporterDriverId: element,
              });
            }
          });

          /* execute attach drivers to outward shipment mutation to link newly added drivers to outward shipment */
          apolloClient
            .mutate({
              mutation: attachDriverToOutwardShipmentMutation,
              variables: {
                objects: driverObjectArray,
              },
              /* after adding/attaching newly added driver to outward shipment, refetch get all open outward shipment query to get all updated shipments */
              refetchQueries: [{ query: getAllOpenOutwardShipmentsForOrdersQuery }],
            })
            .then(() => {
              setIsSubmitOrUpdateBtnLoading(false);
              setEditShipmentData(null);
              reset();
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success('Outward shipment created.');
            })
            .catch(() => {
              if (
                res &&
                res.data &&
                res.data.createOutwardShipment &&
                res.data.createOutwardShipment.id
              ) {
                /* if attaching driver mutation fails then that createdOutwardShipment should be deleted */
                apolloClient
                  .mutate({
                    mutation: deleteOutwardShipmentMutation,
                    variables: {
                      id: res.data.createOutwardShipment.id,
                    },
                  })
                  .catch((error: ApolloError) => {
                    setIsSubmitOrUpdateBtnLoading(false);
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.error(error.message);
                    logger(error);
                  });
              }
            });
        })
        .catch((error: ApolloError) => {
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  };

  /* function used to close modal */
  const closeModal = () => {
    setIsModalVisibleAndFormName({ isModalVisible: false, formNameKey: null });
  };

  /* this function used to get content of the modal (or form component), using form name key  */
  const getModalContent = (formNameKey: FormNameKeyType): JSX.Element => {
    /* const used to store 'transporterId' field watch value, which is required further */
    const transporterIdValue = watch('transporterId');

    /* if user want to create new transporter then call 'AddOrEditTransporterForm' form component */
    if (formNameKey === 'createTransporter') {
      modalTitle = 'Create New Transporter';
      return <AddOrEditTransporterForm mode="add" calledFrom="outward" closeModal={closeModal} />;
    } else if (formNameKey === 'createTransporterVehicle' && transporterIdValue) {
      modalTitle = 'Create New Transporter Vehicle';
      return (
        <AddOrEditTransporterVehicleForm
          mode="add"
          calledFrom="outward"
          closeModal={closeModal}
          initialTransporterIdValue={transporterIdValue}
        />
      );
    } else {
      modalTitle = 'Create New Transporter Driver';
      return (
        <AddAndEditTransporterDriverForm mode="add" calledFrom="outward" closeModal={closeModal} />
      );
    }
  };

  return (
    <>
      {isModalVisibleAndFormName.isModalVisible ? (
        <Modal
          title={modalTitle}
          visible={isModalVisibleAndFormName.isModalVisible}
          footer={false}
          onCancel={closeModal}
          destroyOnClose
        >
          {getModalContent(isModalVisibleAndFormName.formNameKey)}
        </Modal>
      ) : null}
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <RequiredMessage />
        <FormItem
          label=" Transporter "
          isRequired
          errorText={errors && errors.transporterId ? errors.transporterId.message : undefined}
          requiredMark="after"
          {...formItemStyleProps}
        >
          <div style={{ display: 'flex' }}>
            <Select
              customStyles={{ ...inputComponentCommonStyle, width: 423 }}
              placeholder="Please select name of transporter"
              name="transporterId"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                optionFilterProp: 'children',
                loading: getAllTransportersLoading,
              }}
              options={
                getAllTransporters
                  ? getAllTransporters.getAllTransporters.map((transporter) => {
                      return { value: transporter.id, label: transporter.name.toUpperCase() };
                    })
                  : []
              }
              onChange={(rhfOnChange, value: number) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                rhfOnChange(value);

                /* if user has selected any transporter then call 'getVehiclesOfTransporter' function to get list of vehicles for selected transporter id  */
                if (value) {
                  void getVehiclesOfTransporter({ variables: { transporterId: value } });
                }

                /* after selection of 'transporterId' if 'vehicleId' is already selected then, set it's value to 'null'.
                so that user can select vehicle from updated vehicle list */
                if (watch('vehicleId')) {
                  setValue('vehicleId', null);
                }
              }}
            />
            <Button
              type="primary"
              style={{ marginLeft: 20 }}
              onClick={() => {
                setIsModalVisibleAndFormName({
                  isModalVisible: true,
                  formNameKey: 'createTransporter',
                });
              }}
            >
              Create New Transporter
            </Button>
          </div>
        </FormItem>
        {watch('transporterId') ? (
          <>
            <FormItem
              label=" Vehicle "
              isRequired
              errorText={errors && errors.vehicleId ? errors.vehicleId.message : undefined}
              requiredMark="after"
              {...formItemStyleProps}
            >
              <div style={{ display: 'flex' }}>
                <Select
                  customStyles={{ ...inputComponentCommonStyle, width: 423 }}
                  placeholder="Please select vehicle number"
                  name="vehicleId"
                  rhfControllerProps={{
                    control,
                  }}
                  selectProps={{
                    showSearch: true,
                    loading: getAllVehiclesOfTransporterDataLoading,
                    optionFilterProp: 'children',
                  }}
                  options={
                    getAllVehiclesOfTransporterData
                      ? getAllVehiclesOfTransporterData.getAllVehiclesOfTransporter.map(
                          (transporter) => {
                            return {
                              value: transporter.id,
                              label: transporter.number as string,
                            };
                          },
                        )
                      : []
                  }
                />
                <Button
                  type="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    setIsModalVisibleAndFormName({
                      isModalVisible: true,
                      formNameKey: 'createTransporterVehicle',
                    });
                  }}
                >
                  Create New Transporter Vehicle
                </Button>
              </div>
            </FormItem>
            <FormItem
              label=" Drivers "
              isRequired
              errorText={
                errors.driverIds ? (errors.driverIds as unknown as FieldError).message : undefined
              }
              requiredMark="after"
              {...formItemStyleProps}
            >
              <div style={{ display: 'flex' }}>
                <Select
                  customStyles={{ ...inputComponentCommonStyle, width: 423 }}
                  placeholder="Please select driver name"
                  name="driverIds"
                  rhfControllerProps={{
                    control,
                  }}
                  selectProps={{
                    showSearch: true,
                    loading: getAllTransporterDriverDataLoading,
                    optionFilterProp: 'children',
                  }}
                  mode="multiple"
                  options={
                    getAllTransporterDriverData
                      ? getAllTransporterDriverData.getAllTransporterDrivers.map((transporter) => {
                          return {
                            value: transporter.id,
                            label: transporter.name.toUpperCase() + ' - ' + transporter.mobile,
                          };
                        })
                      : []
                  }
                />
                <Button
                  type="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    setIsModalVisibleAndFormName({
                      isModalVisible: true,
                      formNameKey: 'createTransporterDriver',
                    });
                  }}
                >
                  Create New Transporter Driver
                </Button>
              </div>
            </FormItem>
            <FormItem label="Mill Name" {...formItemStyleProps}>
              <>
                {getBhagwatiMillIdData &&
                  getBhagwatiMillIdData.mills[0] &&
                  getBhagwatiMillIdData.mills[0].name}
              </>
            </FormItem>
            <FormItem
              label="Tare Weight (in kgs)"
              isRequired
              requiredMark="after"
              errorText={errors.tareWeightInKg ? errors.tareWeightInKg.message : undefined}
              {...formItemStyleProps}
            >
              <div style={{ display: 'flex' }}>
                <InputNumber
                  customStyles={{ ...inputComponentCommonStyle, width: 423 }}
                  name="tareWeightInKg"
                  placeholder="Please enter tare weight (in kgs)"
                  rhfControllerProps={{
                    control,
                  }}
                  inputNumberProps={{
                    disabled:
                      weightInputDisabledData &&
                      !weightInputDisabledData.getSettingByName[0].intValue
                        ? true
                        : false,
                    precision: 2,
                  }}
                />
                <Button
                  type="primary"
                  style={{ marginLeft: 20 }}
                  onClick={() => {
                    getVehicleWeight();
                  }}
                  loading={getWeightButtonLoading}
                >
                  Get Weight
                </Button>
              </div>
            </FormItem>
          </>
        ) : null}
        <FormItem {...formItemStyleProps}>
          <>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10 }}
              loading={isSubmitOrUpdateBtnLoading}
            >
              {editShipmentData ? 'Update Shipment' : 'Create Shipment'}
            </Button>
            {editShipmentData ? (
              <Button
                type="default"
                onClick={() => {
                  reset();
                  setEditShipmentData(null);
                }}
              >
                Cancel
              </Button>
            ) : null}
          </>
        </FormItem>
      </form>
      <Divider style={{ marginTop: 10 }} />
      <h3>Open Outward Shipments</h3>
      {getAllOpenOutwardShipmentsData && !getAllOpenOutwardShipmentsDataLoading ? (
        <OpenOutwardShipmentsTable
          data={getAllOpenOutwardShipmentsData.getOutwardShipmentsForOrders}
          assignEditShipmentData={setEditShipmentData}
          setValue={setValue}
          getVehiclesOfTransporterOnEditShipment={getVehiclesOfTransporter}
        />
      ) : (
        <Spin className="loadingSpinner" />
      )}
    </>
  );
};

export default CreateProductOutwardShipmentScreen;
