import React, { useState } from 'react';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  GetAllOutwardShipmentsForBagsQuery,
  GetAllOutwardShipmentsForBagsQueryVariables,
} from '../../../graphql/graphql-types';
import { loader } from 'graphql.macro';
import DailyReportOutwardBagOrOtherItemTable from '../dailyReports/outward/DailyReportOutwardBagOrOtherItemTable';
import FinancialYearComponent from '../../../components/FinancialYearComponent';

/* extending isBetween plugin from day js library */
dayjs.extend(isBetween);

/* loading get all outward shipment for bags Query */
const getAllOutwardShipmentsForBagsQuery = loader(
  '../../../graphql/queries/getAllOutwardShipmentsForBagsQuery.graphql',
);

/* React functional component */
const OutwardShipmentBagScreen = () => {
  /* this state used to store start and end date, which is selected using date range picker */
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  /* get All outward Shipment For bag data using query with start and end date from date range field */
  const {
    data: getAllOutwardShipmentsForBagsData,
    loading: getAllOutwardShipmentsForBagsLoading,
    error: getAllOutwardShipmentsForBagsError,
  } = useQuery<GetAllOutwardShipmentsForBagsQuery, GetAllOutwardShipmentsForBagsQueryVariables>(
    getAllOutwardShipmentsForBagsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        createdAt:
          selectedDate && selectedDate.startDate && selectedDate.endDate
            ? {
                _gte: selectedDate.startDate,
                _lte: selectedDate.endDate,
              }
            : {},
      },
    },
  );

  /* outward bag table component */
  const OutwardBagTableComponent = () => {
    /* show error text on the screen. if it has any error while loading data from the server */
    if (getAllOutwardShipmentsForBagsError) {
      return <div className="errorText">{getAllOutwardShipmentsForBagsError.message}</div>;
    }

    /* show loading indicator on the screen until data get fetch from the server */
    if (getAllOutwardShipmentsForBagsLoading) {
      return <Spin className="loadingSpinner" />;
    }

    if (getAllOutwardShipmentsForBagsData) {
      return (
        <DailyReportOutwardBagOrOtherItemTable
          data={getAllOutwardShipmentsForBagsData.outwardShipments}
          outwardShipmentType="bags"
          reportScreenType="outward"
        />
      );
    }
    return <div />;
  };

  return (
    <>
      <FinancialYearComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      <OutwardBagTableComponent />
    </>
  );
};

export default OutwardShipmentBagScreen;
