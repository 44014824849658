import React from 'react';
import { UseControllerProps, useController } from 'react-hook-form';
import { Select as AntdSelect, SelectProps as AntdSelectPropType } from 'antd';
import { SelectModeType, OptionsDataType } from '../utils/types';
import styles from './Input.module.scss';

// type for props of selectComponent
type SelectComponentPropType = {
  // Name is the name of form field
  name: string;
  // This is the default value of our select field
  defaultValue?: string | undefined;
  // This is the placeholder for our select field
  placeholder: string;
  // This is the controller props of react-hook-form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rhfControllerProps: Omit<UseControllerProps<any>, 'name' | 'defaultValue'>;
  // This is the custom style for select
  customStyles?: React.CSSProperties;
  // This will contain actual prop of antd select
  selectProps?: Omit<AntdSelectPropType<any>, 'mode' | 'onChange' | 'value'>;
  //  this will contain array of data we will pass to option so that we will see its label in select dropdown
  options: Array<OptionsDataType>;
  // This function is called when functionality is different from the regular onChange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any, option: any) => void;
  // prop to set the mode of the select, it can be multiple or tags
  mode?: SelectModeType;
};

const { Option } = AntdSelect;

// react functional component
const Select: React.FC<SelectComponentPropType> = ({
  name,
  defaultValue = undefined,
  rhfControllerProps,
  placeholder,
  customStyles = {},
  onChange,
  options,
  selectProps,
  mode,
}) => {
  // destructuring fields from useController hook
  const { field } = useController({
    name,
    defaultValue,
    ...rhfControllerProps,
  });

  // destructuring onChange function & rhfFields, and then will pass it to input field
  const { onChange: rhfOnChange, ...rhfFields } = field;

  // This is a common props which is used in antd select
  const commonSelectProps = {
    placeholder,
    onChange: (value: any) => {
      if (onChange) {
        onChange(rhfOnChange, value);
      } else {
        rhfOnChange(value);
      }
    },
    style: { ...customStyles },
    mode,
    ...rhfFields,
    ...selectProps,
  };

  return (
    <AntdSelect {...commonSelectProps} className={styles.textInput}>
      {options.map((option) => {
        return (
          <Option value={option.value} key={option.value}>
            {option.label}
          </Option>
        );
      })}
    </AntdSelect>
  );
};

export default Select;
