import React from 'react';
import { Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { dateFormatFunc } from '../../../../utils/globals';
import { OutwardShipmentsReportsType } from '../../../../utils/types';
import ColumnSearchComponent from '../../../../components/ColumnSearchComponent';

/* Daily Report Outward Table Prop Type */
type DailyReportOutwardTablePropType = {
  /* prop type used to get data for the table */
  data: OutwardShipmentsReportsType[];
  /* prop type used to determine whether user want to make shipment for bags or other items */
  outwardShipmentType: 'bags' | 'otherItems';
  /* prop type used to determine whether screen is Daily Report or outward Report */
  reportScreenType?: 'daily' | 'outward';
};

/* React functional component */
const DailyReportOutwardBagOrOtherItemTable = ({
  data,
  outwardShipmentType,
  reportScreenType,
}: DailyReportOutwardTablePropType) => {
  return (
    <div className={reportScreenType === 'daily' ? 'reportTableContainer' : undefined}>
      <div className={reportScreenType === 'daily' ? 'reportTable' : undefined}>
        <Table<OutwardShipmentsReportsType>
          dataSource={data}
          className="tableStyle"
          bordered
          size="small"
          pagination={{ showSizeChanger: true }}
          rowKey="id"
        >
          <Table.Column<OutwardShipmentsReportsType>
            title="GRN/Date"
            dataIndex="grn"
            align="center"
            onFilter={(value, record) => {
              return record.grn
                ? record.grn.toLowerCase().includes(value.toString().toLowerCase())
                : false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(text, record) => {
              return (
                <>
                  {record.grn}
                  <br />
                  {dateFormatFunc(record.createdAt as string)}
                </>
              );
            }}
            width={160}
          />
          <Table.Column<OutwardShipmentsReportsType>
            title="Item name"
            dataIndex="itemName"
            align="center"
            render={(text, record) => {
              if (outwardShipmentType === 'bags' && record.orders && record.orders.length > 0) {
                return record.orders.map((order) => {
                  return order.item ? order.item.name : '-';
                });
              }
              if (record.orders && record.orders.length > 0) {
                return record.orders.map((order) => {
                  return order.item ? order.item.name || '-' : order.othersItemName || '-';
                });
              }
              return null;
            }}
          />
          <Table.Column<OutwardShipmentsReportsType>
            title="Delivery Transport/ Person & Mobile"
            dataIndex="nonProductPerson"
            width={160}
            align="center"
            render={(text, record) => {
              return (
                <span style={{ textTransform: 'capitalize' }}>
                  {record.nonProductPersonName || '-'}
                  <br /> {record.nonProductPersonMobile}
                </span>
              );
            }}
          />
          <Table.Column<OutwardShipmentsReportsType>
            title="Vehicle Number"
            dataIndex="nonProductVehicleNumber"
            align="center"
            render={(text, record) =>
              record.nonProductVehicleNumber ? record.nonProductVehicleNumber : '-'
            }
          />
          {outwardShipmentType === 'bags' ? (
            <>
              <Table.Column<OutwardShipmentsReportsType>
                title="Buyer Name"
                dataIndex="buyerName"
                align="center"
                render={(text, record) => {
                  if (Array.isArray(record.orders) && record.orders.length > 0) {
                    return record.orders.map((order) => {
                      return order.paddyBagBuyer ? order.paddyBagBuyer.name : '-';
                    });
                  }
                  return '-';
                }}
              />
              <Table.Column<OutwardShipmentsReportsType>
                title="No. of Bags"
                dataIndex="bagsCount"
                align="center"
                render={(text, record) => {
                  if (Array.isArray(record.orders) && record.orders.length > 0) {
                    return record.orders.map((order) => {
                      return order.loadedBagsCount ? order.loadedBagsCount : '-';
                    });
                  }
                  return '-';
                }}
              />
            </>
          ) : null}
          {outwardShipmentType === 'otherItems' ? (
            <>
              <Table.Column<OutwardShipmentsReportsType>
                title="Quantity"
                dataIndex="quantity"
                align="center"
                render={(text, record) => {
                  if (Array.isArray(record.orders) && record.orders.length > 0) {
                    return record.orders.map((order) => {
                      return order.othersQuantity ? order.othersQuantity : '-';
                    });
                  }
                  return '-';
                }}
              />
              <Table.Column<OutwardShipmentsReportsType>
                title="Weight (in kgs)"
                dataIndex="othersWtKg"
                align="center"
                render={(text, record) => {
                  if (Array.isArray(record.orders) && record.orders.length > 0) {
                    return record.orders.map((order) => {
                      return order.othersWtKg ? order.othersWtKg : '-';
                    });
                  }
                  return '-';
                }}
              />
            </>
          ) : null}
          <Table.Column<OutwardShipmentsReportsType>
            title="Source"
            dataIndex="source"
            align="center"
            render={(text, record) => {
              if (Array.isArray(record.orders) && record.orders.length > 0) {
                return record.orders.map((order) => {
                  if (order.source === 'godown') {
                    return (
                      <div key={order.id} style={{ textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Godown: </span>
                        {order.godown && order.godown.name}
                      </div>
                    );
                  }
                  if (order.source === 'mill') {
                    return (
                      <div key={order.id} style={{ textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Mill: </span>
                        {order.mill && order.mill.name}
                      </div>
                    );
                  }
                  return '-';
                });
              }
              return '-';
            }}
          />
          {outwardShipmentType === 'otherItems' ? (
            <Table.Column<OutwardShipmentsReportsType>
              title="Destination"
              dataIndex="destination"
              align="center"
              render={(text, record) => {
                if (Array.isArray(record.orders) && record.orders.length > 0) {
                  return record.orders.map((order) => (
                    <p key={order.id} style={{ marginBottom: 0 }}>
                      {order.othersDestination ? order.othersDestination : '-'}
                    </p>
                  ));
                }
                return '-';
              }}
            />
          ) : null}
          {outwardShipmentType === 'bags' ? (
            <Table.Column<OutwardShipmentsReportsType>
              title="Gone for maintenance?"
              dataIndex="maintenance"
              align="center"
              render={(text, record) => {
                if (record.orders && record.orders.length > 0) {
                  return record.orders.map((order) => {
                    if (order.isOutForMaintenance === true) {
                      return 'Yes';
                    }
                    if (order.isOutForMaintenance === false) {
                      return 'No';
                    }
                    return '-';
                  });
                }
                return '-';
              }}
            />
          ) : null}
          <Table.Column<OutwardShipmentsReportsType>
            title="Remark"
            dataIndex="remark"
            align="center"
            render={(text, record) => {
              if (Array.isArray(record.orders) && record.orders.length > 0) {
                return record.orders.map((order) => {
                  return order.remark ? order.remark : '-';
                });
              }
              return '-';
            }}
            width={150}
          />
        </Table>
      </div>
    </div>
  );
};

export default DailyReportOutwardBagOrOtherItemTable;
