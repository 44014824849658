import React, { useState } from 'react';
import { Button, Modal, Spin, Table } from 'antd';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { ApolloError, useApolloClient, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { loader } from 'graphql.macro';
import {
  GetAllOutwardShipmentsForProductsQuery,
  GetAllOutwardShipmentsForProductsQueryVariables,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
} from '../../../graphql/graphql-types';
import {
  OutwardShipmentsReportsTableType,
  UploadedFileKeyAndUrlType,
  UploadedFileNameAndUrlType,
} from '../../../utils/types';
import { logger } from '../../../utils/helpers';
import { dateFormatFunc } from '../../../utils/globals';
import LightBoxComponent from '../../../components/LightboxComponent';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import FinancialYearComponent from '../../../components/FinancialYearComponent';
import colors from '../../../scss/variables.module.scss';

/* extending isBetween plugin from day js library */
dayjs.extend(isBetween);

/* loading get all outward shipment for products data Query */
const getAllOutwardShipmentsForProductsQuery = loader(
  '../../../graphql/queries/getAllOutwardShipmentsForProductsQuery.graphql',
);

/* loading get files url query */
const getFilesUrlQuery = loader('../../../graphql/queries/getFilesURLQuery.graphql');

/* React functional component */
const OutwardShipmentProductsScreen = () => {
  /* extracting apollo Client from use use apollo client*/
  const apolloClient = useApolloClient();

  /* this state used to store start and end date, which is selected using date range picker or financial year radio option */
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  /* state used to store object of image which contain image key and its respective url */
  const [imageUrlObjectArray, setImageUrlObjectArray] = useState<Array<UploadedFileKeyAndUrlType>>(
    [],
  );

  // state to set the array of object for image whose view document column view button is clicked. the object has url, name property
  const [lightBoxDataArray, setLightBoxDataArray] = useState<Array<UploadedFileNameAndUrlType>>([]);

  /* state used to set the visibility of lightBox for image display */
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* get All outward Shipment For Products data using query with start and end date from date range field */
  const {
    data: getAllOutwardShipmentsForProductsData,
    loading: getAllOutwardShipmentsForProductsLoading,
    error: getAllOutwardShipmentsForProductsError,
  } = useQuery<
    GetAllOutwardShipmentsForProductsQuery,
    GetAllOutwardShipmentsForProductsQueryVariables
  >(getAllOutwardShipmentsForProductsQuery, {
    variables: {
      createdAt:
        selectedDate && selectedDate.startDate && selectedDate.endDate
          ? {
              _gte: selectedDate.startDate,
              _lte: selectedDate.endDate,
            }
          : {},
    },
    onCompleted: (res) => {
      // array will contain the keys whose url we have to find which will then pass as argument to getFilesUrl query
      const documentArray: string[] = [];

      if (Array.isArray(res.outwardShipments) && res.outwardShipments.length > 0) {
        res.outwardShipments.forEach((shipment) => {
          if (shipment && shipment.transporterVehicle && shipment.transporterVehicle.rcS3Key) {
            /* push transport vehicle rcs3 kay into document array */
            documentArray.push(shipment.transporterVehicle.rcS3Key);
          }
          if (Array.isArray(shipment.drivers) && shipment.drivers.length > 0) {
            shipment.drivers.forEach((item) => {
              if (item.driver.photo) {
                /* push driver photo key into document array */
                documentArray.push(item.driver.photo);
              }
            });
          }
        });
      }

      /* if images added for the respective shipment, then get that image urls using image key  */
      if (documentArray)
        /* get files url query used to get urls & key of the images */
        apolloClient
          .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
            query: getFilesUrlQuery,
            variables: {
              keys: documentArray,
            },
          })
          .then((imageUrlsData) => {
            /* destructing images file url and key */
            const { getFilesUrl } = imageUrlsData.data;

            /* const array used to store key and url of image */
            const fileUrlAndKeyArray: Array<UploadedFileKeyAndUrlType> = [];

            /* if url and key present for image then push it into file url array for further operation */
            if (getFilesUrl) {
              getFilesUrl.forEach((item) => {
                if (item) {
                  /* create key and url object from resultant data to simplify further data handling */
                  fileUrlAndKeyArray.push({
                    key: item.key,
                    url: item.url,
                  });
                }
              });
            }
            /* update url and key of images array into state , so that we can use it to display image */
            setImageUrlObjectArray(fileUrlAndKeyArray);
          })
          .catch((getFilesUrlError: ApolloError) => {
            logger(getFilesUrlError);
          });
    },
  });

  /* outward products table component */
  const OutwardProductsTableComponent = () => {
    /* show loading indicator on the screen until data get fetch from the server */
    if (getAllOutwardShipmentsForProductsLoading) {
      return <Spin className="loadingSpinner" />;
    }

    /* show error message on the screen, if it has any error to fetch data from the server */
    if (getAllOutwardShipmentsForProductsError) {
      return <div className="errorText">{getAllOutwardShipmentsForProductsError.message}</div>;
    }

    if (getAllOutwardShipmentsForProductsData) {
      return (
        <>
          <Table<OutwardShipmentsReportsTableType>
            dataSource={getAllOutwardShipmentsForProductsData.outwardShipments}
            className="tableStyle"
            bordered
            size="small"
            rowKey="id"
            pagination={{ showSizeChanger: true }}
          >
            <Table.Column<OutwardShipmentsReportsTableType>
              title="GRN"
              dataIndex="grn"
              key="grn"
              align="center"
              onFilter={(value, record) => {
                return record.grn
                  ? record.grn.toLowerCase().includes(value.toString().toLowerCase())
                  : false;
              }}
              filterDropdown={(filterDropDownProp) => (
                <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
              )}
              filterIcon={(filtered) => {
                return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
              }}
              render={(grnNumber, record) => {
                return (
                  <span>
                    {grnNumber ? (
                      <Link to={`/shipments/outward/${record.id}`}>{grnNumber}</Link>
                    ) : (
                      '-'
                    )}
                  </span>
                );
              }}
            />
            <Table.Column<OutwardShipmentsReportsTableType>
              title="Started At"
              dataIndex="createdAt"
              key="createdAt"
              align="center"
              render={(text, record) => dateFormatFunc(record.createdAt as string)}
            />
            <Table.Column<OutwardShipmentsReportsTableType>
              title="Status"
              dataIndex="status"
              key="status"
              align="center"
              filters={[
                {
                  text: 'Completed',
                  value: 'Completed',
                },
                {
                  text: 'Invoice not added',
                  value: 'Invoice not added',
                },
                {
                  text: 'Loading done',
                  value: 'Loading done',
                },
                {
                  text: 'Loading...',
                  value: 'Loading',
                },
              ]}
              onFilter={(value, record) => record.status === value}
              filterIcon={(filtered) => {
                return <FilterFilled className={filtered ? 'searchIcon' : ''} />;
              }}
            />
            <Table.Column<OutwardShipmentsReportsTableType>
              title="Transporter Name"
              key="transporterName"
              dataIndex="transporterName"
              align="center"
              onFilter={(value, record) => {
                return record.transporter && record.transporter.name
                  ? record.transporter.name.toLowerCase().includes(value.toString().toLowerCase())
                  : false;
              }}
              filterDropdown={(filterDropDownProp) => (
                <ColumnSearchComponent
                  filterBy="transporter name"
                  filterDropDownProp={filterDropDownProp}
                />
              )}
              filterIcon={(filtered) => {
                return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
              }}
              render={(text, record) => (record.transporter ? record.transporter.name : '-')}
            />
            <Table.Column<OutwardShipmentsReportsTableType>
              title="Vehicle Number"
              key="vehicleNumber"
              dataIndex="vehicleNumber"
              align="center"
              onFilter={(value, record) => {
                return record.transporterVehicle && record.transporterVehicle.number
                  ? record.transporterVehicle.number
                      .toLowerCase()
                      .includes(value.toString().toLowerCase())
                  : false;
              }}
              filterDropdown={(filterDropDownProp) => (
                <ColumnSearchComponent
                  filterBy="vehicle number"
                  filterDropDownProp={filterDropDownProp}
                />
              )}
              filterIcon={(filtered) => {
                return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
              }}
              render={(text, record) =>
                record.transporterVehicle ? record.transporterVehicle.number : '-'
              }
            />
            <Table.Column<OutwardShipmentsReportsTableType>
              title="Net Weight (quintals)"
              key="netWeight"
              dataIndex="netWeight"
              align="center"
              render={(text, record) =>
                Math.abs(record.fullVehicleWtKg - record.emptyVehicleWtKg) / 100
              }
            />
            <Table.Column<OutwardShipmentsReportsTableType>
              title="Documents"
              key="documents"
              dataIndex="documents"
              render={(text, record) => {
                return (
                  <>
                    <Button
                      onClick={() => {
                        /* file data  for stored array of upload file and url type */
                        const filesData: Array<UploadedFileNameAndUrlType> = [];
                        if (
                          record &&
                          record.transporterVehicle &&
                          record.transporterVehicle.rcS3Key
                        ) {
                          /* const to store transporter's rcS3key document's name and url object.*/
                          const transporterFileData: UploadedFileNameAndUrlType = {
                            name: undefined,
                            url: undefined,
                          };

                          /* const to stored rcs3Key Data if data present in transport vehicle */
                          const rcS3KeyData =
                            record.transporterVehicle && record.transporterVehicle.rcS3Key
                              ? record.transporterVehicle.rcS3Key
                              : null;

                          /* filtered imaged   url object data for stored data if  imageUrlObjectArray key  is equal to rcs3key Data  */
                          let filteredImageUrlObjectData: UploadedFileKeyAndUrlType | undefined;
                          if (rcS3KeyData) {
                            filteredImageUrlObjectData = imageUrlObjectArray.find(
                              (item) => item.key === rcS3KeyData,
                            );
                          }

                          /* stored transport vehicle image and name  */
                          transporterFileData.name = 'Vehicle Registration Certificate (RC)';
                          if (filteredImageUrlObjectData) {
                            transporterFileData.url = filteredImageUrlObjectData.url;
                          }

                          /* transporter file data pushed into files data */
                          filesData.push(transporterFileData);
                        }

                        if (
                          record &&
                          record.drivers &&
                          Array.isArray(record.drivers) &&
                          record.drivers.length > 0
                        ) {
                          record.drivers.forEach((entry) => {
                            if (entry.driver.photo) {
                              // const to store name and url object of driver photo.
                              const driverPhotoData: UploadedFileNameAndUrlType = {
                                name: undefined,
                                url: undefined,
                              };
                              driverPhotoData.name = entry.driver.name as string;
                              if (imageUrlObjectArray) {
                                driverPhotoData.url = imageUrlObjectArray.find(
                                  (item) => item.key === entry.driver.photo,
                                )?.url as string;
                              }
                              /* driver photo data pushed into files data */
                              filesData.push(driverPhotoData);
                            }
                          });
                        }
                        setIsImageOpen(true);
                        if (filesData) {
                          setLightBoxDataArray(filesData);
                        }
                      }}
                    >
                      View
                    </Button>
                    {record.status === 'Completed' ? (
                      <Button
                        style={{
                          marginLeft: 10,
                          backgroundColor: colors.colorGreen,
                          color: 'white',
                        }}
                        onClick={() => {
                          navigate(`/shipment/completed/admin/outward/edit/${record.id}`);
                        }}
                      >
                        Edit
                      </Button>
                    ) : null}
                  </>
                );
              }}
            />
          </Table>
          {lightBoxDataArray.length === 0 ? (
            <Modal
              title="Documents"
              visible={isImageOpen}
              footer={false}
              onCancel={() => {
                setIsImageOpen(false);
              }}
            >
              <div>
                No document has been uploaded for this GRN
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingTop: 30,
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsImageOpen(false);
                    }}
                    type="primary"
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </Modal>
          ) : (
            <LightBoxComponent
              imageUrlObjectArray={imageUrlObjectArray}
              setIsImageOpen={setIsImageOpen}
              screenType="outwardShipment"
              lightBoxData={lightBoxDataArray}
              setLightBoxData={setLightBoxDataArray}
            />
          )}
        </>
      );
    }
    return <div />;
  };
  return (
    <>
      <FinancialYearComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      <OutwardProductsTableComponent />
    </>
  );
};

export default OutwardShipmentProductsScreen;
