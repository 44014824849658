import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import {
  GetTransporterByIdQuery,
  GetTransporterByIdQueryVariables,
} from '../../../graphql/graphql-types';
import { Spin } from 'antd';
import AddOrEditTransporterForm from './AddOrEditTransporterForm';
import { AddressType } from '../../../utils/types';

/* loading the get transporter by id query  with the help of loader */
const getTransporterByIdQuery = loader('../../../graphql/queries/getTransporterByIdQuery.graphql');

/* React functional component */
const EditTransporterScreen = () => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* get transporter by ID query */
  const {
    data: getTransporterByIdData,
    loading: getTransporterByIdLoading,
    error: getTransporterByIdError,
  } = useQuery<GetTransporterByIdQuery, GetTransporterByIdQueryVariables>(getTransporterByIdQuery, {
    variables: { id: Number(params.id) },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getTransporterByIdLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getTransporterByIdError) {
    return <div className="errorText">{getTransporterByIdError.message}</div>;
  }

  /* getting address data from getTransporterByIdData.getTransporterById in order to further use it while passing the data to use form */
  let addressData;
  if (getTransporterByIdData && getTransporterByIdData.getTransporterById) {
    addressData = getTransporterByIdData.getTransporterById.address as AddressType;
  }

  return (
    <>
      {getTransporterByIdData && getTransporterByIdData.getTransporterById ? (
        <AddOrEditTransporterForm
          mode="edit"
          transporterDataToEdit={{
            name: getTransporterByIdData.getTransporterById.name,
            gstin: getTransporterByIdData.getTransporterById.gstin,
            ownersEmail: getTransporterByIdData.getTransporterById.primaryContactEmail as string,
            mobile: getTransporterByIdData.getTransporterById.primaryContactMobile as string,
            ownersName: getTransporterByIdData.getTransporterById.primaryContactName as string,
            address: addressData ? addressData.address : '',
            state: addressData ? addressData.state : '',
            city: addressData ? addressData.city : '',
            pincode: addressData ? addressData.pincode : '',
          }}
        />
      ) : null}
    </>
  );
};

export default EditTransporterScreen;
