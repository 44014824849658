import React from 'react';
import { Tabs } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const { TabPane } = Tabs;

/* React functional component */
const OtherItemsOrdersTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* useLocation destructuring */
  const location = useLocation();

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('create')) {
      return 'create';
    }
    if (location.pathname.includes('approve')) {
      return 'approve';
    }
    if (location.pathname.includes('deliver')) {
      return 'deliver';
    }
    return 'viewAll';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="viewAll"
        onTabClick={(key: string) => {
          if (key === 'create') {
            navigate('create');
          } else {
            navigate('');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab=" View All POs" key="viewAll">
          <Outlet />
        </TabPane>
        <TabPane tab="Create PO" key="create">
          <Outlet />
        </TabPane>
        <TabPane tab="Approve PO" key="approve" disabled>
          <Outlet />
        </TabPane>
        <TabPane tab="Deliver PO" key="deliver" disabled>
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OtherItemsOrdersTabLayout;
