import React, { useState } from 'react';
import { Button, message, Popconfirm, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import {
  DeleteInwardShipmentMutation,
  DeleteInwardShipmentMutationVariables,
  GetAdminOpenInwardShipmentsQuery,
  GetAdminOpenInwardShipmentsQueryVariables,
  GetAdminReviewInwardShipmentsQuery,
  GetAdminReviewInwardShipmentsQueryVariables,
} from '../../graphql/graphql-types';
import { logger } from '../../utils/helpers';
import { InwardShipmentsReportType } from '../../utils/types';
import { dateFormatFunc } from '../../utils/globals';
import ColumnSearchComponent from '../../components/ColumnSearchComponent';
import { convertKgsToQuintals } from '../../utils/helpers';
import { useApp } from '../../context/AppContext';

/* loading get admin open inward shipments Query */
const getAdminOpenInwardShipmentsQuery = loader(
  '../../graphql/queries/getAdminOpenInwardShipmentsQuery.graphql',
);

/* loading get admin review inward shipments Query */
const getAdminReviewInwardShipmentsQuery = loader(
  '../../graphql/queries/getAdminReviewInwardShipmentsQuery.graphql',
);

/* delete inward shipment mutation */
const deleteInwardShipmentMutation = loader(
  '../../graphql/mutations/deleteInwardShipmentMutation.graphql',
);

/* open grn screen prop type */
type OpenGRNScreenPropType = {
  screenType: 'open' | 'adminReview';
};

/* React functional component */
const OpenGRNScreen = ({ screenType }: OpenGRNScreenPropType) => {
  /* state used to store id of the shipment or item whose delete button is clicked  */
  const [shipmentOrItemIdToDelete, setShipmentOrItemIdToDelete] = useState<number | null>(null);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* extracting user from useApp context */
  const { user } = useApp();

  /* delete inward shipment  mutation */
  const [deleteInwardShipment] = useMutation<
    DeleteInwardShipmentMutation,
    DeleteInwardShipmentMutationVariables
  >(deleteInwardShipmentMutation);

  /* get admin Open inward shipments query data */
  const { data, error, loading } = useQuery<
    GetAdminOpenInwardShipmentsQuery,
    GetAdminOpenInwardShipmentsQueryVariables
  >(getAdminOpenInwardShipmentsQuery, { fetchPolicy: 'network-only' });

  /* get admin review inward shipments query data  */
  const {
    data: getAdminReviewInwardShipmentsData,
    error: getAdminReviewInwardShipmentsError,
    loading: getAdminReviewInwardShipmentsLoading,
  } = useQuery<GetAdminReviewInwardShipmentsQuery, GetAdminReviewInwardShipmentsQueryVariables>(
    getAdminReviewInwardShipmentsQuery,
    { fetchPolicy: 'network-only' },
  );

  /* function for delete inward shipment function */
  const deleteInwardShipmentFunc = (shipmentId: number) => {
    /* set id of the shipment whose delete button and used to show loading indicator on delete button while deleting a shipment */
    setShipmentOrItemIdToDelete(shipmentId);
    /* delete inward shipment */
    deleteInwardShipment({
      variables: {
        id: shipmentId,
      },
      /* after deleting a  inward shipment, the update function is used to update the cache. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a inward shipment that was just removed. */
        const deletedDataId = deleteData?.deleteInwardShipment?.id;
        cache.modify({
          fields: {
            inwardShipments(existingInwardShipments: Array<Reference>, { readField }) {
              if (deletedDataId) {
                /* return data after deleted item using deletedDataId  */
                return existingInwardShipments.filter(
                  (shipmentRef) => deletedDataId !== readField('id', shipmentRef),
                );
              }
              return existingInwardShipments;
            },
          },
        });
      },
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Inward shipment has been successfully deleted');
        setShipmentOrItemIdToDelete(null);
      })
      .catch((Error: ApolloError) => {
        const errorMsg = Error.message;
        setShipmentOrItemIdToDelete(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(Error);
      });
  };

  /* if user role is admin and tab is adminReview then show admin review inward shipment data otherwise show admin open inward shipment data  */
  let tableData;
  if (user) {
    if (user.role === 'admin' && screenType === 'adminReview') {
      tableData = getAdminReviewInwardShipmentsData
        ? getAdminReviewInwardShipmentsData.getAdminReviewInwardShipments
        : [];
    } else {
      tableData = data ? data.getAdminOpenInwardShipments : [];
    }
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (error || getAdminReviewInwardShipmentsError) {
    let errorMessage;
    if (error) {
      errorMessage = error.message;
    } else if (getAdminReviewInwardShipmentsError) {
      errorMessage = getAdminReviewInwardShipmentsError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  return (
    <Table<InwardShipmentsReportType>
      dataSource={tableData}
      loading={loading || getAdminReviewInwardShipmentsLoading}
      className="tableStyle"
      bordered
      size="small"
      rowKey="id"
      pagination={{ showSizeChanger: true }}
    >
      <Table.Column<InwardShipmentsReportType>
        title="GRN / Date"
        dataIndex="grn"
        key="grn"
        width="160px"
        align="center"
        onFilter={(value, record) => {
          return record.grn
            ? record.grn.toLowerCase().includes(value.toString().toLowerCase())
            : false;
        }}
        filterDropdown={(filterDropDownProp) => (
          <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
        )}
        filterIcon={(filtered) => {
          return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
        }}
        render={(text, record) => {
          return (
            <>
              {record.grn}
              <br />
              {record.createdAt ? dateFormatFunc(record.createdAt) : '-'}
            </>
          );
        }}
      />
      <Table.Column<InwardShipmentsReportType>
        title={
          <>
            Vehicle No. /
            <br />
            Driver Name
          </>
        }
        dataIndex="vehicleNumber"
        key="vehicleNumber"
        width="160px"
        align="center"
        onFilter={(value, record) => {
          return record.vehicleNumber
            ? record.vehicleNumber.toLowerCase().includes(value.toString().toLowerCase())
            : false;
        }}
        filterDropdown={(filterDropDownProp) => (
          <ColumnSearchComponent
            filterBy="Vehicle Number"
            filterDropDownProp={filterDropDownProp}
          />
        )}
        filterIcon={(filtered) => {
          return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
        }}
        render={(text, record) => {
          return (
            <>
              {record.vehicleNumber}
              <br />
              {record.driverName}
            </>
          );
        }}
      />
      <Table.Column<InwardShipmentsReportType>
        title="Farmer/Trader (Purchase)"
        key="seller"
        dataIndex="seller"
        width="192px"
        align="center"
        render={(text, { items }) => {
          if (items === null) return 'NA';
          if (Array.isArray(items) && items.length > 0) {
            return items.map((ele) => {
              return <div key={ele.id}>{ele.seller && ele.seller.name}</div>;
            });
          }
          return 'NA';
        }}
        onFilter={(value, { items }) => {
          if (items && Array.isArray(items) && items.length > 0) {
            /* variable for storing exist value */
            let exist = false;
            items.forEach((item) => {
              if (
                item.seller &&
                item.seller.name.toLowerCase().includes(value.toString().toLowerCase())
              ) {
                exist = true;
              }
            });
            return exist;
          }
          return false;
        }}
        filterDropdown={(filterDropDownProp) => (
          <ColumnSearchComponent
            filterBy="farmer/trader purchase"
            filterDropDownProp={filterDropDownProp}
          />
        )}
        filterIcon={(filtered) => {
          return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
        }}
      />
      <Table.Column<InwardShipmentsReportType>
        title={
          <>
            Paddy Name (Grade) -
            <br />
            No. of Bags
          </>
        }
        key="paddyName"
        dataIndex="paddyName"
        width="230px"
        align="center"
        render={(text, { items }) => {
          if (items === null) return 'NA';
          if (items === null) return 'NA';
          if (Array.isArray(items) && items.length > 0) {
            return items.map((seller) => {
              return (
                <div key={seller.id}>
                  {seller.rawMaterial && seller.rawMaterial.name}
                  {seller.paddyGrade !== 'rice' &&
                    seller.paddyGrade &&
                    `(${seller.paddyGrade.replace(/[^\d]/g, '')})`}{' '}
                  - {seller.bagsCount} bags
                </div>
              );
            });
          }
          return 'NA';
        }}
      />
      <Table.Column
        title="Gross truck weight (in kgs)"
        dataIndex="fullVehicleWtKg"
        key="fullVehicleWtKg"
        width="150px"
        align="center"
      />
      <Table.Column
        title="Empty truck weight (in kgs)"
        dataIndex="emptyVehicleWtKg"
        key="emptyVehicleWtKg"
        width="160px"
        align="center"
      />
      <Table.Column<InwardShipmentsReportType>
        title="Net truck weight (in quintals)"
        dataIndex="netVehicleWtKg"
        key="netVehicleWtKg"
        width="140px"
        align="center"
        render={(text, record) => {
          /* calculation of net vehicle weight in kg */
          const netVehicleWtKg =
            (record.fullVehicleWtKg ? record.fullVehicleWtKg : 0) -
            (record.emptyVehicleWtKg ? record.emptyVehicleWtKg : 0);
          return convertKgsToQuintals(netVehicleWtKg);
        }}
      />
      <Table.Column<InwardShipmentsReportType>
        title="Actions"
        dataIndex="actions"
        key="actions"
        width="180px"
        align="center"
        render={(text, record) => {
          let isModifyBtnVisible = true;

          if (user && (user.role === 'staff' || user.role === 'manager')) {
            isModifyBtnVisible = false;
          }

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Button
                style={{ margin: 5 }}
                onClick={() => {
                  navigate(`/shipment/admin/inward/process/${record.id}`);
                }}
              >
                {screenType === 'adminReview' ? 'Complete GRN' : 'Process'}
              </Button>
              <Button
                style={{ margin: 5, display: isModifyBtnVisible ? 'block' : 'none' }}
                onClick={() => {
                  navigate(`/shipment/admin/inward/edit/${record.id}`);
                }}
              >
                Modify
              </Button>
              <Popconfirm
                title="Are you sure, you want to delete this shipment?"
                okText="Yes"
                onConfirm={() => {
                  deleteInwardShipmentFunc(record.id);
                }}
                okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                cancelText="No"
              >
                <Button
                  style={{ margin: 5 }}
                  className="deleteButton"
                  loading={shipmentOrItemIdToDelete === record.id}
                >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          );
        }}
      />
    </Table>
  );
};

export default OpenGRNScreen;
