import React from 'react';
import { UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { useController, UseControllerProps } from 'react-hook-form';
import { Upload as AntdUpload } from 'antd';

// This is the type of props coming from parent component
type UploadComponentPropsType = {
  // Name is the name of upload field in the form
  name: string;
  // prop use to make upload button dynamic
  children: JSX.Element;
  // This is the controller props of react-hook-form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rhfControllerProps: Omit<UseControllerProps<any>, 'name'>;
  // This is the props for upload field
  uploadProps?: Omit<
    UploadProps,
    'onChange' | 'onRemove' | 'beforeUpload' | 'fileList' | 'maxCount'
  >;
  // This function is called when functionality is different from the regular onChange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (rhfOnChange: (...event: any[]) => void, info: UploadChangeParam) => void;
};

// upload functional component
const Upload: React.FC<UploadComponentPropsType> = ({
  name,
  rhfControllerProps,
  uploadProps,
  children,
  onChange,
}) => {
  const { field } = useController({ name, ...rhfControllerProps });

  // destructuring react hook form onChange function from field
  const { onChange: rhfOnChange } = field;

  return (
    <AntdUpload
      onChange={(info) => {
        if (onChange) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          onChange(rhfOnChange, info);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          rhfOnChange({ file: info.file, fileList: info.fileList });
        }
      }}
      beforeUpload={() => {
        // returning false as we don't want to execute axios call on change of the file
        // will do axios call using(handleSingleFileUploadFunc) on submit of form.
        return false;
      }}
      onRemove={() => {
        rhfOnChange({ file: null, fileList: [] });
        return false;
      }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      fileList={field.value ? field.value.fileList : []}
      // maxCount to upload only one file and replace existing incase use try to upload other
      maxCount={1}
      {...uploadProps}
    >
      {children}
    </AntdUpload>
  );
};

export default Upload;
