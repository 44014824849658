import React, { useState } from 'react';
import { Button, Descriptions, DescriptionsProps, message, Spin, Table } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  ApprovePurchaseOrderMutationMutation,
  ApprovePurchaseOrderMutationMutationVariables,
  GetPurchaseOrderByIdQuery,
  GetPurchaseOrderByIdQueryVariables,
} from '../../graphql/graphql-types';
import dayjs from 'dayjs';
import { addressFormatFunction, logger, renderAmount } from '../../utils/helpers';
import { AddressType, PurchaseDataFormType } from '../../utils/types';
import colors from '../../scss/variables.module.scss';
import getItemCategoryData from '../../utils/helpers/getItemCategoryData';

/* loading get Purchase Order By Id  query */
const getPurchaseOrderByIdQuery = loader('../../graphql/queries/getPurchaseOrderByIdQuery.graphql');

/* loading approve Purchase Order mutation  */
const approvePurchaseOrderMutation = loader(
  '../../graphql/mutations/approvePurchaseOrderMutation.graphql',
);

/* styling for Description component*/
const descriptionComponentStyleProps: DescriptionsProps = {
  /* define number of column in row */
  column: 1,
  /* set description components item colon false */
  colon: false,
  /* styling of description component labels */
  labelStyle: { width: '250px' },
};

/* React functional component */
const PurchaseOrderApproveScreen = (): JSX.Element => {
  /* useNavigate destructuring */
  const navigate = useNavigate();

  /* useLocation destructuring */
  const location = useLocation();

  // extracting id from params
  const { id } = useParams();

  /** calling `getItemCategoryData` function to get itemCategory data  */
  const itemCategory = getItemCategoryData({
    locationPathname: location.pathname,
  });

  /* this state used to show loading indicator on 'Approve po'  when approving  order  */
  const [isApproveBtnLoading, setIsApproveBtnLoading] = useState<boolean>(false);

  const orderId = Number(id);

  /* fetching purchase order data using id */
  const { data, loading, error } = useQuery<
    GetPurchaseOrderByIdQuery,
    GetPurchaseOrderByIdQueryVariables
  >(getPurchaseOrderByIdQuery, { variables: { id: orderId } });

  /* approve purchase order mutation */
  const [approvePurchaseOrder] = useMutation<
    ApprovePurchaseOrderMutationMutation,
    ApprovePurchaseOrderMutationMutationVariables
  >(approvePurchaseOrderMutation);

  /* variable to store the total amount of all items. its sum of individual items amount */
  let amount = 0;

  /* logic to sum the individual items amount */
  if (
    data &&
    data.purchaseOrders_by_pk &&
    Array.isArray(data.purchaseOrders_by_pk.purchaseOrderItems) &&
    data.purchaseOrders_by_pk.purchaseOrderItems.length > 0
  ) {
    (data.purchaseOrders_by_pk.purchaseOrderItems as PurchaseDataFormType[]).forEach((item) => {
      amount += renderAmount(
        itemCategory.key === 'otherItems' ? item.quantity || 0 : item.orderedItemsCount || 0,
        item.unitPrice || 0,
        Number(item.gstPercent),
        item.discount || 0,
      ).amountAfterDiscount;
    });
  }

  const vendorData =
    data && data.purchaseOrders_by_pk && data.purchaseOrders_by_pk.purchaseOrderItems[0].vendor;

  /* function to be called when user click approve button */
  const approveFunction = (purchaseId: number) => {
    setIsApproveBtnLoading(true);
    // mutation to  approve the purchase order
    approvePurchaseOrder({
      variables: {
        id: purchaseId,
      },
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Purchase order has been successfully approved.');
        navigate(`/purchaseOrders/${itemCategory.key}`);
        setIsApproveBtnLoading(false);
      })
      .catch((err: ApolloError) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(err);
        logger(err);
        setIsApproveBtnLoading(false);
      });
  };

  /** const to store discount applicable on total amount  */
  const purchaseOrderDiscount =
    data && data.purchaseOrders_by_pk && (data.purchaseOrders_by_pk.discount as number);

  const discountCalculation = (amount * ((purchaseOrderDiscount || 0) / 100)).toFixed(2);

  /* show loading indicator on the screen until data get fetch from the server */
  if (loading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (error) {
    return <div className="errorText">{error.message}</div>;
  }

  return (
    <>
      <Descriptions {...descriptionComponentStyleProps}>
        <Descriptions.Item label="Material">{itemCategory.materialName}</Descriptions.Item>
        <Descriptions.Item label="PO No.">
          {data && data.purchaseOrders_by_pk && data.purchaseOrders_by_pk.poNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Vendor">
          {(vendorData && vendorData.name) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="GST">
          {(vendorData && vendorData.gstin) || 'Not Added'}
        </Descriptions.Item>
        <Descriptions.Item label="Contact Name">
          {(vendorData && vendorData.primaryContactName) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Mobile">
          {(vendorData && vendorData.primaryContactMobile) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {(vendorData && vendorData.primaryContactEmail) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label=" Address">
          {vendorData && vendorData.address
            ? addressFormatFunction(vendorData.address as AddressType, 'singleLine')
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Total Discount Applied">
          {purchaseOrderDiscount ? <>{purchaseOrderDiscount}%</> : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Purchase contract validity">
          {data && data.purchaseOrders_by_pk && data.purchaseOrders_by_pk.contract_validity
            ? dayjs(data.purchaseOrders_by_pk.contract_validity as Date).format('MMM DD, YYYY')
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Payment terms">
          {data && data.purchaseOrders_by_pk && data.purchaseOrders_by_pk.payment_terms
            ? data.purchaseOrders_by_pk.payment_terms
            : '-'}
        </Descriptions.Item>
      </Descriptions>
      <h2 style={{ marginTop: '8px', marginBottom: '0px' }}>Item List</h2>
      <Table<PurchaseDataFormType>
        dataSource={
          data &&
          data.purchaseOrders_by_pk &&
          Array.isArray(data.purchaseOrders_by_pk.purchaseOrderItems) &&
          data.purchaseOrders_by_pk.purchaseOrderItems.length > 0
            ? (data.purchaseOrders_by_pk.purchaseOrderItems as PurchaseDataFormType[])
            : undefined
        }
        bordered
        size="small"
        rowKey={(record) => record.id}
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<PurchaseDataFormType>
          title="Vendor"
          dataIndex="vendorName"
          key="vendorName"
          render={(text, record) => {
            return <>{record.vendor.name}</>;
          }}
        />
        <Table.Column<PurchaseDataFormType>
          title="Item Description"
          dataIndex="itemName"
          key="itemName"
          render={(text, record) => {
            return record.item.name;
          }}
        />
        {itemCategory.key === 'otherItems' ? (
          <>
            <Table.Column<PurchaseDataFormType>
              title="Broker"
              dataIndex="broker"
              width={200}
              key="broker"
              render={(text, record) => {
                return record.broker ? record.broker.name : '-';
              }}
            />
            <Table.Column<PurchaseDataFormType>
              title="Quantity"
              dataIndex="quantity"
              key="quantity"
              render={(val, record) => record.quantity || '-'}
            />
          </>
        ) : (
          <Table.Column title="No. of Bags" dataIndex="orderedItemsCount" key="orderedItemsCount" />
        )}
        <Table.Column<PurchaseDataFormType>
          title="Unit Price (in Rs.)"
          dataIndex="unitPrice"
          key="unitPrice"
        />
        <Table.Column<PurchaseDataFormType>
          title="GST (%)"
          dataIndex="gstPercent"
          key="gstPercent"
          render={(value, record) => (record.gstPercent as string) || '-'}
        />
        <Table.Column<PurchaseDataFormType>
          title="Discount (%)"
          dataIndex="discount"
          key="discount"
          render={(val, record) => record.discount || '-'}
        />
        <Table.Column<PurchaseDataFormType>
          title="Amount (in Rs.)"
          dataIndex="amount"
          key="amount"
          render={(text, record) => {
            /** const to store discounted amount and final amount after discount */
            const totalAmountAndDiscount = renderAmount(
              itemCategory.key === 'otherItems'
                ? record.quantity || 0
                : record.orderedItemsCount || 0,
              record.unitPrice || 0,
              Number(record.gstPercent),
              record.discount || 0,
            );

            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {totalAmountAndDiscount.amountAfterDiscount.toFixed(2) || '-'}
                {totalAmountAndDiscount.discountedAmount > 0 ? (
                  <i style={{ fontSize: 12, color: colors.placeHolderTextColor }}>
                    discount applied (Rs.) :
                    <span style={{ fontWeight: 500 }}>
                      {totalAmountAndDiscount.discountedAmount.toFixed(2) || '-'}
                    </span>
                  </i>
                ) : null}
              </div>
            );
          }}
        />
        <Table.Column<PurchaseDataFormType>
          title="Remarks"
          dataIndex="remarks"
          key="remarks"
          render={(text, record) => record.remarks || '-'}
        />
      </Table>
      <div style={{ marginTop: 10, marginBottom: 10, fontSize: 16 }}>
        <b>Sub Total : </b>
        {amount > 0 ? amount.toFixed(2) : <> -</>}
      </div>
      <div style={{ marginTop: 10, marginBottom: 10, fontSize: 16 }}>
        <b>
          Total Discount{' '}
          {purchaseOrderDiscount && purchaseOrderDiscount > 0 ? (
            <>({purchaseOrderDiscount}%) </>
          ) : null}
          <>: </>
        </b>
        {purchaseOrderDiscount && purchaseOrderDiscount > 0 ? discountCalculation : <> -</>}
      </div>
      <div style={{ marginTop: 10, marginBottom: 10, fontSize: 16 }}>
        <b>Total amount (in Rs.) : </b>
        {amount > 0 ? <>{(amount - Number(discountCalculation)).toFixed(2)} Estimated</> : <> -</>}
      </div>
      <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          style={{ marginRight: 30 }}
          onClick={() => {
            approveFunction(orderId);
          }}
          loading={isApproveBtnLoading}
        >
          Approve PO
        </Button>
        <Button
          onClick={() => {
            navigate(`/purchaseOrders/${itemCategory.key}`);
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default PurchaseOrderApproveScreen;
