import React from 'react';
import CreateOtherItemInwardOrOutwardShipmentForm from '../../../components/CreateOtherItemInwardOrOutwardShipmentForm';

/* React functional component */
const OutwardOtherItemsScreen: React.FC = () => {
  return (
    <div className="tabLayout">
      <CreateOtherItemInwardOrOutwardShipmentForm shipmentType="outward" />
    </div>
  );
};

export default OutwardOtherItemsScreen;
