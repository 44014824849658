import React from 'react';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetAllOutwardShipmentsForProductsQuery,
  GetAllOutwardShipmentsForProductsQueryVariables,
} from '../../../../graphql/graphql-types';
import { DailyReportsPropType } from '../../../../utils/types';
import DailyReportsOutwardProductTable from './DailyReportsOutwardProductTable';

/* loading get all outward shipment for Products Data Query */
const getAllOutwardShipmentsForProductsQuery = loader(
  '../../../../graphql/queries/getAllOutwardShipmentsForProductsQuery.graphql',
);

/* React functional component */
const DailyReportsOutwardProductsScreen = ({ startDate, endDate }: DailyReportsPropType) => {
  /* get All outward Shipment For Products data using query with start and end date from date range field */
  const {
    data: getAllOutwardShipmentsForProductsData,
    loading: getAllOutwardShipmentsForProductsLoading,
    error: getAllOutwardShipmentsForProductsError,
  } = useQuery<
    GetAllOutwardShipmentsForProductsQuery,
    GetAllOutwardShipmentsForProductsQueryVariables
  >(getAllOutwardShipmentsForProductsQuery, {
    variables: {
      createdAt:
        startDate && endDate
          ? {
              _gte: startDate,
              _lte: endDate,
            }
          : {},
    },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getAllOutwardShipmentsForProductsLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getAllOutwardShipmentsForProductsError) {
    return <div className="errorText">{getAllOutwardShipmentsForProductsError.message}</div>;
  }

  return (
    <>
      {getAllOutwardShipmentsForProductsData ? (
        <DailyReportsOutwardProductTable
          data={getAllOutwardShipmentsForProductsData.outwardShipments}
        />
      ) : null}
    </>
  );
};

export default DailyReportsOutwardProductsScreen;
