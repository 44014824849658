import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

/* React functional component */
const BuyersTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();
  /* useLocation destructuring */
  const location = useLocation();

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('create')) {
      return '2';
    }
    if (location.pathname.includes('edit')) {
      return '3';
    }
    return '1';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="1"
        onTabClick={(key: string) => {
          if (key === '2') {
            navigate('create');
          } else {
            navigate('');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab="View All Buyers" key="1">
          <Outlet />
        </TabPane>
        <TabPane tab="Create New Buyer" key="2">
          <Outlet />
        </TabPane>
        <TabPane tab="Edit Buyer" key="3" disabled>
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default BuyersTabLayout;
