import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, ApolloError, useMutation, Reference } from '@apollo/client';
import { Table, Button, Popconfirm, message } from 'antd';
import { PlusOutlined, WarningFilled, SearchOutlined, FilterFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../../utils/helpers';
import {
  DeleteProductBuyerMutation,
  DeleteProductBuyerMutationVariables,
  GetAllProductBuyersQuery,
  GetAllProductBuyersQueryVariables,
  ProductBuyers,
} from '../../../graphql/graphql-types';
import { AddressType } from '../../../utils/types';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import colors from '../../../scss/variables.module.scss';

/* product buyer data type */
type BuyersDataType = Pick<
  ProductBuyers,
  | 'id'
  | 'name'
  | 'gstin'
  | 'profileCompleted'
  | 'primaryContactName'
  | 'primaryContactEmail'
  | 'primaryContactMobile'
> & { address?: AddressType | null };

/* loading get all buyers query with the help of loader */
const getAllBuyersQuery = loader('../../../graphql/queries/getAllProductBuyersQuery.graphql');

/* loading delete product buyer mutation with the help of loader */
const deleteProductBuyerMutation = loader(
  '../../../graphql/mutations/deleteProductBuyerMutation.graphql',
);

/* React functional component */
const ViewAllBuyersScreen = () => {
  /* useState to store selected buyers id when user hits delete button which will then use for loading indication */
  const [deleteBuyerId, setDeleteBuyerId] = useState<number | null>(null);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* get all product buyers query */
  const { data, error, loading } = useQuery<
    GetAllProductBuyersQuery,
    GetAllProductBuyersQueryVariables
  >(getAllBuyersQuery);

  /* delete product buyer mutation */
  const [deleteBuyer] = useMutation<
    DeleteProductBuyerMutation,
    DeleteProductBuyerMutationVariables
  >(deleteProductBuyerMutation);

  if (error) {
    return <div className="errorText">{error.message}</div>;
  }

  /* function to handle delete Buyer */
  const handleDeleteBuyer = (buyerId: number) => {
    deleteBuyer({
      variables: {
        id: buyerId,
      },
      update(cache, { data: deleteData }) {
        /* to get the id of the data to be deleted */
        const idToRemove = deleteData?.deleteProductBuyer?.id;
        cache.modify({
          fields: {
            productBuyers(existingBuyers: Array<Reference>, { readField }) {
              if (idToRemove) {
                return existingBuyers.filter(
                  (buyersData) => idToRemove !== readField('id', buyersData),
                );
              }
              return existingBuyers;
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteBuyerId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Buyer has been successfully deleted.');
      })
      .catch((deleteBuyerError: ApolloError) => {
        let errorMsg = deleteBuyerError.message;
        setDeleteBuyerId(null);
        if (deleteBuyerError.message.includes('Foreign key violation.')) {
          errorMsg = 'Cannot delete since buyer is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteBuyerError);
      });
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          navigate('create');
        }}
      >
        Create buyer
      </Button>
      <Table<BuyersDataType>
        dataSource={data ? data.getAllProductBuyers : []}
        loading={loading}
        className="tableStyle"
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<BuyersDataType>
          key="name"
          title="Buyer Name"
          dataIndex="name"
          align="center"
          render={(buyersName) => {
            return <span style={{ textTransform: 'capitalize' }}>{buyersName || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.name
              ? record.name.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="buyer name" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<BuyersDataType>
          key="primaryContactName"
          title="Contact Name"
          dataIndex="primaryContactName"
          align="center"
          render={(contactName) => {
            return <span style={{ textTransform: 'capitalize' }}>{contactName || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.primaryContactName
              ? record.primaryContactName.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="contact name"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<BuyersDataType>
          key="primaryContactMobile"
          title="Mobile"
          dataIndex="primaryContactMobile"
          align="center"
          render={(mobileNumber: string) => mobileNumber || '-'}
          onFilter={(value, record) => {
            return record.primaryContactMobile
              ? record.primaryContactMobile.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="mobile number"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<BuyersDataType>
          key="gstin"
          title="GST"
          dataIndex="gstin"
          align="center"
          render={(gstin: string) => gstin || '-'}
          onFilter={(value, record) => {
            return record.gstin
              ? record.gstin.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="GST" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<BuyersDataType>
          key="profileCompleted"
          title="Profile Completed"
          dataIndex="profileCompleted"
          align="center"
          render={(profileCompleted: boolean) => {
            return profileCompleted ? 'Complete' : 'Incomplete';
          }}
          onFilter={(value, record) => record.profileCompleted === value}
          filterIcon={(filtered) => {
            return <FilterFilled className={filtered ? 'searchIcon' : ''} />;
          }}
          filters={[
            {
              text: 'Complete',
              value: true,
            },
            {
              text: 'Incomplete',
              value: false,
            },
          ]}
        />
        <Table.Column<BuyersDataType>
          key="actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    navigate(`${record.id}/edit`);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete Buyer. Are you sure?"
                  okText="Yes"
                  onConfirm={() => {
                    handleDeleteBuyer(record.id);
                    setDeleteBuyerId(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                >
                  <Button className="deleteButton" loading={record.id === deleteBuyerId}>
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};
export default ViewAllBuyersScreen;
