import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, ApolloError, Reference, useApolloClient } from '@apollo/client';
import { loader } from 'graphql.macro';
import { logger } from '../../../utils/helpers';
import {
  CreateTransporterDriverMutation,
  CreateTransporterDriverMutationVariables,
  UpdateTransporterDriverMutation,
  UpdateTransporterDriverMutationVariables,
  TransporterDrivers,
  DeleteS3FileMutation,
  DeleteS3FileMutationVariables,
} from '../../../graphql/graphql-types';
import { statesOptionsForSelect } from '../../../utils/globals';
import Select from '../../../components/Select';
import FormItem from '../../../components/FormItem';
import { handleSingleFileUploadFunc } from '../../../utils/helpers';
import Input from '../../../components/Input';
import Upload from '../../../components/Upload';
import {
  inputComponentCommonStyle,
  formItemStyleProps as formItemProps,
} from '../../../utils/globals';
import RequiredMessage from '../../../components/RequiredMessage';
import { UploadFileDetailsType, AddressType } from '../../../utils/types';

/* add and edit transporter driver form type */
type AddAndEditTransporterDriverFormType = Pick<TransporterDrivers, 'name' | 'mobile'> & {
  /* id of driver */
  id: number;
  /* driving licence */
  drivingLicence: string;
  /* driver photo */
  photo: UploadFileDetailsType | null;
  /* driver's pan photo copy */
  panPhoto: UploadFileDetailsType | null;
} & AddressType;

// urls's and keys's are required as we want to show two buttons- one with download(which will require url) and other button Delete and Upload(which will require key to delete the file)
type EditTransporterDriverDataType = Omit<
  AddAndEditTransporterDriverFormType,
  'photo' | 'panPhoto'
> & {
  /* photo url which helps the user to download the photo */
  photoUrl: string | null;
  /* panFile url which will help the user to download pan file */
  panFileUrl: string | null;
  /* photo copy key which will be used to delete the photo by passing key to delete file mutation */
  photoKey: string | null;
  /* pan key which will be used to delete the pan photo by passing pan key to delete file mutation */
  panKey: string | null;
};

/* AddAndEditTransporterDriver form component props type */
type AddAndEditTransporterDriverFormPropsType = {
  /* to check whether the AddOrEditTransporterDriver form is being called from edit mode or add mode */
  mode: 'edit' | 'add';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  editTransporterDriverData?: EditTransporterDriverDataType;
  /* this prop type used to manage CSS styling, when this form will be called from 'Outward -> Create Shipment' section */
  calledFrom?: 'outward' | 'management';
  /* this prop type used to close modal (because In 'Outward section' we used modal to display this form), when when this form will be called from 'Outward -> Create Shipment' section */
  closeModal?: () => void;
};

/* formItem component styling props */
const defaultTransporterFormItemStyleProps = {
  ...formItemProps,
  /* label column span of FormItem */
  labelColSpan: 3,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* this formItem style used when 'AddOrEditTransporter' form is called from 'Outward -> create shipment' section */
const outwardTransporterFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 7,
  /* input column span of FormItem */
  inputColSpan: 16,
  /* formItem label style */
  labelStyle: { fontSize: 14 } as React.CSSProperties,
  /* formItem content style */
  customStyle: { paddingTop: 15, fontSize: 14 } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* add or edit transporter driver form validation */
const schema = yup.object({
  name: yup
    .string()
    .required(`Please enter name and try again`)
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  drivingLicence: yup
    .string()
    .required('Please enter driving licence number and try again')
    .matches(validationRegex.drivingLicence, {
      message: 'Please enter a valid driving licence',
    }),
  mobile: yup
    .string()
    .required(`Please enter mobile number and try again`)
    .matches(validationRegex.mobile, {
      message: 'Please enter a valid mobile number',
    })
    .nullable(),
  city: yup
    .string()
    .typeError('Please enter valid city name')
    .matches(/^[aA-zZ\s]+$/, {
      message: 'Please enter a valid name with alphabets only',
      excludeEmptyString: true,
    })
    .nullable(),
  pincode: yup
    .string()
    .matches(validationRegex.pincode, {
      message: 'Please enter a valid 6-digit pincode',
      excludeEmptyString: true,
    })
    .nullable(),
});

/* loading delete S3 file mutation with the help of loader */
const deleteS3FileMutation = loader('../../../graphql/mutations/deleteS3FileMutation.graphql');

/* loading create transporter driver mutation  */
const createTransporterDriverMutation = loader(
  '../../../graphql/mutations/createTransporterDriverMutation.graphql',
);

/* loading update transporter driver mutation */
const updateTransporterDriverMutation = loader(
  '../../../graphql/mutations/updateTransporterDriverMutation.graphql',
);

/* React functional component */
const AddAndEditTransporterDriverForm = ({
  mode,
  editTransporterDriverData = undefined,
  calledFrom = 'management',
  closeModal = () => {},
}: AddAndEditTransporterDriverFormPropsType) => {
  /* state to set the loading condition of button */
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* this const used to store formItem style props based on 'calledFrom' prop value */
  const formItemStyleProps =
    calledFrom === 'outward'
      ? outwardTransporterFormItemStyleProps
      : defaultTransporterFormItemStyleProps;

  /* create transporter driver mutation */
  const [createTransporterDriver] = useMutation<
    CreateTransporterDriverMutation,
    CreateTransporterDriverMutationVariables
  >(createTransporterDriverMutation);

  /* update transporter driver mutation */
  const [updateTransporterDriver] = useMutation<
    UpdateTransporterDriverMutation,
    UpdateTransporterDriverMutationVariables
  >(updateTransporterDriverMutation);

  /* delete S3 file mutation */
  const [deleteS3File] = useMutation<DeleteS3FileMutation, DeleteS3FileMutationVariables>(
    deleteS3FileMutation,
  );

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<AddAndEditTransporterDriverFormType>({
    resolver: yupResolver(schema),
    defaultValues:
      mode === 'edit' && editTransporterDriverData
        ? {
            name: editTransporterDriverData.name,
            drivingLicence: editTransporterDriverData.drivingLicence,
            mobile: editTransporterDriverData.mobile,
            address: editTransporterDriverData.address,
            state: editTransporterDriverData.state || null,
            city: editTransporterDriverData.city,
            pincode: editTransporterDriverData.pincode,
            panPhoto: null,
            photo: null,
          }
        : {
            name: '',
            drivingLicence: '',
            mobile: '',
            address: '',
            state: null,
            city: '',
            pincode: '',
            panPhoto: null,
            photo: null,
          },
    mode: 'onChange',
  });

  /* create and update error handling function */
  const createAndUpdateMutationErrorHandlingFunc = (errorMsg: ApolloError) => {
    let createOrUpdateErrorMsg = errorMsg.message;
    if (
      errorMsg.message ===
      'Uniqueness violation. duplicate key value violates unique constraint "transporterDrivers_drivingLicence_key"'
    ) {
      createOrUpdateErrorMsg = 'Driving Licence number is already registered';
    }
    if (
      errorMsg.message ===
      'Uniqueness violation. duplicate key value violates unique constraint "transporterDrivers_mobile_key"'
    ) {
      createOrUpdateErrorMsg = 'Mobile number is already registered';
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error(createOrUpdateErrorMsg);
  };

  /* function to handle file deletion by passing key to deleteS3 file mutation */
  const handleFileDelete = (key: string) => {
    deleteS3File({
      variables: {
        s3Key: key,
      },
    })
      .then(() => {})
      .catch((deleteFileError: ApolloError) => {
        logger(deleteFileError);
      });
  };

  /* function to handle submitted data */
  // working of onSubmit function - first we run the create or update mutation without passing the panKey and photoKey(this is done because
  // we want to avoid file upload to server before checking for all the other fields). After running the mutations we check for
  // fileData's(of photo and pan)- so, if file data is empty which means the user has not done anything w.r.t the file input field so we dont do anything,
  // and if user upload's either pan or photo or both files in either create and edit mode then we will run one more update mutation
  // just to update pan and photo field values.
  const onSubmit = handleSubmit(async (submittedFormData) => {
    /* this is used to store the result returned by create transporter driver mutation */
    let createTransporterDriverMutationResult;
    /* this is used to store the result returned by update transporter driver mutation */
    let updateTransporterDriverMutationResult;
    try {
      /* this variable is used to store address , which is then used to manage profile status of driver from address fields  */
      let driverAddress;
      if (
        submittedFormData.address ||
        submittedFormData.city ||
        submittedFormData.pincode ||
        submittedFormData.state
      ) {
        driverAddress = {
          address: submittedFormData.address || null,
          state: submittedFormData.state || null,
          city: submittedFormData.city || null,
          pincode: submittedFormData.pincode || null,
        };
      } else {
        driverAddress = null;
      }

      /* common mutation variables which are required for create and update transporter driver mutation */
      const mutationVariables = {
        mobile: submittedFormData.mobile,
        drivingLicence: submittedFormData.drivingLicence,
        name: submittedFormData.name,
        address: driverAddress,
      };

      setIsSubmitBtnLoading(true);
      if (mode === 'edit' && editTransporterDriverData) {
        updateTransporterDriverMutationResult = await updateTransporterDriver({
          variables: {
            id: editTransporterDriverData.id,
            _set: {
              ...mutationVariables,
            },
          },
          update(cache, { data: updateData }) {
            /* data to update */
            const dataToUpdate = updateData?.updateTransporterDriver;
            cache.modify({
              fields: {
                transporterDrivers(existingDrivers: Array<Reference>, { readField }) {
                  if (dataToUpdate) {
                    return existingDrivers.map((transporterDriverData) => {
                      if (dataToUpdate.id === readField('id', transporterDriverData)) {
                        return dataToUpdate;
                      }
                      return transporterDriverData;
                    });
                  }
                  return existingDrivers;
                },
              },
            });
          },
        });
      } else {
        createTransporterDriverMutationResult = await createTransporterDriver({
          variables: {
            object: {
              ...mutationVariables,
            },
          },
          update(cache, { data: addedTransporterDriver }) {
            /* extracting the new data added by user */
            const dataToAdd = addedTransporterDriver?.createTransporterDriver;
            cache.modify({
              fields: {
                transporterDrivers(existingTransporterDrivers: Array<Reference>) {
                  return [...existingTransporterDrivers, dataToAdd];
                },
              },
            });
          },
        });
      }

      // It is important to pass fileData as file(see uploadFileDetailsType) to the handleSingleUpload function as passing fileListData gives a bug.
      // File is downloaded correctly but the content is not loaded
      /* file list data for photo */
      const fileListDataForPhoto = submittedFormData.photo ? submittedFormData.photo.file : null;

      /* file list data for pan photo */
      const fileListDataForPanPhoto = submittedFormData.panPhoto
        ? submittedFormData.panPhoto.file
        : null;

      /* stores the key returned by handleSingleUpload function( this is the key which we get after uploading file list data for photo on server) */
      let photoKey;

      /* stores the key returned by handleSingleUpload function( this is the key which we get after uploading file list data for pan photo on server) */
      let panKey;

      /* when on edit page , this let is used to store the id of the driver being edited */
      let driverId;

      if (fileListDataForPhoto || fileListDataForPanPhoto) {
        if (fileListDataForPhoto !== null) {
          photoKey = await handleSingleFileUploadFunc(apolloClient, fileListDataForPhoto);
        }
        if (fileListDataForPanPhoto !== null) {
          panKey = await handleSingleFileUploadFunc(apolloClient, fileListDataForPanPhoto);
        }
        if (
          createTransporterDriverMutationResult &&
          createTransporterDriverMutationResult.data &&
          createTransporterDriverMutationResult.data.createTransporterDriver
        ) {
          driverId = createTransporterDriverMutationResult.data.createTransporterDriver.id;
        }
        if (editTransporterDriverData && editTransporterDriverData.id) {
          driverId = editTransporterDriverData.id;
        }
        // depending upon the conditions that whether user has uploaded both the files(pan and photo) or a single file( it might be any of the two)
        // we decide which keys to pass depending upon these variables
        let photoKeyToPass;
        let panKeyToPass;

        // when the user clicks on re-upload and upload's a file in that case we have to delete the previous uploaded file
        // to handle that case we use the below condition's
        if (editTransporterDriverData && editTransporterDriverData.photoKey && photoKey) {
          handleFileDelete(editTransporterDriverData.photoKey);
        }
        if (editTransporterDriverData && editTransporterDriverData.panKey && panKey) {
          handleFileDelete(editTransporterDriverData.panKey);
        }

        // below condition's are to check for the keys to be passed in the update mutation- so for eg. if we have both the
        // keys(pan and photo) then we will pass both of them. If we have either of the keys then for the missing key
        // we will check its editTransporterDriverData.(key) and depending upon if its present or not we will pass the key or null.
        if (photoKey && panKey) {
          photoKeyToPass = photoKey;
          panKeyToPass = panKey;
        }
        if (photoKey && !panKey) {
          photoKeyToPass = photoKey;
          panKeyToPass = editTransporterDriverData ? editTransporterDriverData.panKey : null;
        }
        if (!photoKey && panKey) {
          photoKeyToPass = editTransporterDriverData ? editTransporterDriverData.photoKey : null;
          panKeyToPass = panKey;
        }
        await updateTransporterDriver({
          variables: {
            _set: {
              ...mutationVariables,
              photo: photoKeyToPass,
              panFile: panKeyToPass,
            },
            id: driverId as number,
          },
          update(cache, { data: updateData }) {
            /* data to update */
            const dataToUpdate = updateData?.updateTransporterDriver;
            cache.modify({
              fields: {
                transporterDrivers(existingDrivers: Array<Reference>, { readField }) {
                  if (dataToUpdate) {
                    return existingDrivers.map((transporterDriverData) => {
                      if (dataToUpdate.id === readField('id', transporterDriverData)) {
                        return dataToUpdate;
                      }
                      return transporterDriverData;
                    });
                  }
                  return existingDrivers;
                },
              },
            });
          },
        });
      }
      /* this const used to display message after adding driver successfully, based on 'calledFrom' prop value  */
      const successMessage =
        calledFrom === 'outward'
          ? 'New Driver has been added.'
          : 'Driver has been successfully added.';

      if (mode === 'add' && createTransporterDriverMutationResult) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(successMessage);
      }
      if (mode === 'edit' && updateTransporterDriverMutationResult) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Driver has been updated successfully.');
      }

      reset();
      /* if this form is called from 'management -> Driver section' then navigate to view all driver screen.
          other wise close modal */
      if (calledFrom === 'management') {
        /* navigate to view all drivers screen */
        navigate('/management/drivers');
      } else {
        /* if this form is called from 'Outward -> create shipment' section then close modal */
        closeModal();
      }
      setIsSubmitBtnLoading(false);
    } catch (addAndUpdateErrors) {
      const addAndUpdateMutationError = addAndUpdateErrors as ApolloError;
      createAndUpdateMutationErrorHandlingFunc(addAndUpdateMutationError);
      logger(addAndUpdateErrors as ApolloError);
      setIsSubmitBtnLoading(false);
    }
  });

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={onSubmit}
      >
        <FormItem
          label="Name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter name of the driver"
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Mobile"
          isRequired
          {...formItemStyleProps}
          errorText={errors && errors.mobile ? errors.mobile.message : undefined}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter mobile number of driver"
            name="mobile"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Driving Licence No"
          isRequired
          errorText={errors && errors.drivingLicence ? errors.drivingLicence.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter driving licence number of driver"
            name="drivingLicence"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Address"
          errorText={errors && errors.address ? errors.address.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            name="address"
            isTextAreaInput={true}
            placeholder="Please enter address of driver"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
            textAreaProps={{ rows: 4 }}
          />
        </FormItem>
        <FormItem
          label="City"
          errorText={errors && errors.city ? errors.city.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter city of driver"
            name="city"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="State"
          errorText={errors && errors.state ? errors.state.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select state of driver"
            name="state"
            rhfControllerProps={{
              control,
            }}
            selectProps={{ showSearch: true }}
            options={statesOptionsForSelect}
          />
        </FormItem>
        <FormItem
          label="Pincode"
          errorText={errors && errors.pincode ? errors.pincode.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter pincode of driver"
            name="pincode"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Upload Driver Photo"
          {...formItemStyleProps}
          customStyle={{ alignItems: 'center' }}
        >
          <>
            {mode === 'edit' && editTransporterDriverData && editTransporterDriverData.photoUrl ? (
              <Button
                style={{
                  border: '1px solid black',
                  display: watch('photo') ? 'none' : 'inline-block',
                  marginRight: 20,
                }}
                href={editTransporterDriverData.photoUrl}
              >
                Download
              </Button>
            ) : null}
            <Upload
              name="photo"
              rhfControllerProps={{
                control,
              }}
              children={
                <Button
                  icon={<UploadOutlined />}
                  name="photo"
                  style={{ border: '1px solid black', borderRadius: 5 }}
                >
                  {editTransporterDriverData && editTransporterDriverData.photoUrl
                    ? 'Re-upload'
                    : 'Click to upload'}
                </Button>
              }
            />
          </>
        </FormItem>
        <FormItem
          label={
            <div>
              Upload Driver's <br /> PAN card photo
            </div>
          }
          {...formItemStyleProps}
          customStyle={{ alignItems: 'center' }}
        >
          <>
            {mode === 'edit' &&
            editTransporterDriverData &&
            editTransporterDriverData.panFileUrl ? (
              <Button
                style={{
                  border: '1px solid black',
                  display: watch('panPhoto') ? 'none' : 'inline-block',
                  marginRight: 20,
                }}
                href={editTransporterDriverData.panFileUrl}
              >
                Download
              </Button>
            ) : null}
            <Upload
              name="panPhoto"
              rhfControllerProps={{
                control,
              }}
              children={
                <Button
                  icon={<UploadOutlined />}
                  name="panPhoto"
                  style={{ border: '1px solid black', borderRadius: 5 }}
                >
                  {editTransporterDriverData && editTransporterDriverData.photoUrl
                    ? 'Re-upload'
                    : 'Click to upload'}
                </Button>
              }
            />
          </>
        </FormItem>
        <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
          <div
            style={
              calledFrom === 'outward'
                ? { display: 'flex', justifyContent: 'end' }
                : { display: 'block' }
            }
          >
            <Button
              htmlType="submit"
              type="primary"
              style={{ marginRight: 10 }}
              loading={isSubmitBtnLoading}
            >
              {mode === 'edit' ? 'Update' : 'Create Driver'}
            </Button>
            <Button
              type="default"
              onClick={() => {
                reset();
                /* if this form is called from 'management -> Drivers section' then navigate to view all drivers screen.
                other wise close modal */
                if (calledFrom === 'management') {
                  /* navigate to view all drivers screen */
                  navigate('/management/drivers');
                } else {
                  /* close modal */
                  closeModal();
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </FormItem>
      </form>
    </>
  );
};

export default AddAndEditTransporterDriverForm;
