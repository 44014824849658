import React, { useState } from 'react';
import { Button, message, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { ApolloError, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import RequiredMessage from '../../components/RequiredMessage';
import Select from '../../components/Select';
import FormItem from '../../components/FormItem';
import {
  GetOpenWeighbridgeReceiptsQuery,
  GetOpenWeighbridgeReceiptsQueryVariables,
  GetSettingByNameQuery,
  GetSettingByNameQueryVariables,
  WeighbridgeReceipts,
  UpdateWeighbridgeReceiptMutation,
  UpdateWeighbridgeReceiptMutationVariables,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
} from '../../graphql/graphql-types';
import { inputComponentCommonStyle } from '../../utils/globals';
import InputNumber from '../../components/InputNumber';
import Input from '../../components/Input';
import { handleGetWeightFunction, logger } from '../../utils/helpers';
import { OptionsDataType } from '../../utils/types';

/* loading get open weighbridge receipts query with the help of loader */
const getOpenWeighbridgeReceiptsQuery = loader(
  '../../graphql/queries/getOpenWeighbridgeReceiptsQuery.graphql',
);

/* loading get setting by name query with the help of loader */
const getSettingByNameQuery = loader('../../graphql/queries/getSettingByNameQuery.graphql');

/* loading update weighbridge receipt mutation with the help of loader */
const updateWeighbridgeReceiptMutation = loader(
  '../../graphql/mutations/updateWeighbridgeReceiptMutation.graphql',
);

/* loading get bhagwati mill id query with the help of loader */
const getBhagwatiMillIdQuery = loader('../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* weighbridge receipt type */
type WeighbridgeReceiptType = Omit<
  WeighbridgeReceipts,
  'emptyVehicleWtKg' | 'fullVehicleWtKg' | 'netWeight' | 'amount' | 'id' | 'vehicleNumber'
> & {
  /* vehicle number */
  vehicleNumber: string | null;
  /* selected vehicle number id */
  id: number;
  /* empty vehicle weight */
  emptyVehicleWtKg: number | null;
  /* full vehicle weight */
  fullVehicleWtKg: number | null;
  /* net weight */
  netWeight: number | null;
  /* total amount */
  amount: number | null;
};

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
};

/* React functional component */
const EditWeighbridgeReceipts = () => {
  /* extracting navigate from use Navigate hook */
  const navigate = useNavigate();

  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* state to handle the loading condition of get weight button */
  const [getWeightButtonLoading, setGetWeightButtonLoading] = useState<boolean>(false);

  /* state used to store data of the vehicle whose number is get selected from 'Vehicle No' dropdown */
  const [selectedVehicleNumberData, setSelectedVehicleNumberData] =
    useState<WeighbridgeReceiptType | null>(null);

  /* get Open weighbridge query */
  const {
    data: weighbridgeReceiptData,
    error: weighbridgeReceiptDataError,
    loading: weighbridgeReceiptDataLoading,
  } = useQuery<GetOpenWeighbridgeReceiptsQuery, GetOpenWeighbridgeReceiptsQueryVariables>(
    getOpenWeighbridgeReceiptsQuery,
  );

  /* get bhagwati mill id query */
  const {
    data: bhagwatiMillData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* get 'amount' field value, using setting by name query */
  const {
    data: getSettingByNameData,
    error: getSettingByNameDataError,
    loading: getSettingByNameDataLoader,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weighbridgeCharges' },
  });

  /* setting by name query is used to determine whether the 'fullVehicleWtKg' field is enabled or disabled. */
  const {
    data: weighbridgeInputDisabledData,
    error: weighbridgeInputDisabledDataError,
    loading: weighbridgeInputDisabledDataLoader,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weightInputDisabled' },
  });

  /* update weighbridge mutation */
  const [updateWeighbridge] = useMutation<
    UpdateWeighbridgeReceiptMutation,
    UpdateWeighbridgeReceiptMutationVariables
  >(updateWeighbridgeReceiptMutation);

  /* destructuring use form hook */
  const { control, handleSubmit, setValue } = useForm<WeighbridgeReceiptType>({
    mode: 'onChange',
    defaultValues: {
      vehicleNumber: null,
      driverName: '',
      customerName: '',
      material: '',
      amount: null,
      fullVehicleWtKg: null,
      emptyVehicleWtKg: 0,
      netWeight: 0,
    },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (weighbridgeInputDisabledDataLoader) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (
    weighbridgeReceiptDataError ||
    getSettingByNameDataError ||
    getBhagwatiMillIdQueryError ||
    weighbridgeInputDisabledDataError
  ) {
    let errorMessage;
    if (weighbridgeReceiptDataError) {
      errorMessage = weighbridgeReceiptDataError.message;
    } else if (getSettingByNameDataError) {
      errorMessage = getSettingByNameDataError.message;
    } else if (getBhagwatiMillIdQueryError) {
      errorMessage = getBhagwatiMillIdQueryError.message;
    } else if (weighbridgeInputDisabledDataError) {
      errorMessage = weighbridgeInputDisabledDataError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* variable to store array of vehicle numbers which then pass as options to select vehicle number field */
  let vehicleNumbersOptions: OptionsDataType[] = [];

  /* show option list on vehicle number select component */
  if (weighbridgeReceiptData) {
    /* store vehicle number options */
    vehicleNumbersOptions = weighbridgeReceiptData.getOpenWeighbridgeReceipts.map((item) => ({
      value: item.id,
      label: item.vehicleNumber,
    }));
  }

  /* function to handle submitted data */
  const onSubmit = (submittedData: WeighbridgeReceiptType) => {
    if (selectedVehicleNumberData) {
      updateWeighbridge({
        variables: {
          id: selectedVehicleNumberData.id,
          fullVehicleWtKg: submittedData.fullVehicleWtKg ? submittedData.fullVehicleWtKg : 0,
          amountTaken: submittedData.amount,
          remark: submittedData.remark,
        },
        refetchQueries: [{ query: getOpenWeighbridgeReceiptsQuery }],
      })
        .then(() => {
          navigate(`/viewReceipt/${selectedVehicleNumberData.id}`);
          setSelectedVehicleNumberData(null);
        })
        .catch((errors: ApolloError) => {
          setSelectedVehicleNumberData(null);
          logger(errors);
        });
    }
  };

  /* function to get vehicle weight of vehicle */
  const getVehicleWeight = () => {
    setGetWeightButtonLoading(true);

    // calculating  vehicle weight from weighbridge
    handleGetWeightFunction(apolloClient)
      .then((vehicleWeight) => {
        // setting full vehicle weight
        setValue('fullVehicleWtKg', vehicleWeight);
        if (
          vehicleWeight &&
          selectedVehicleNumberData &&
          selectedVehicleNumberData.emptyVehicleWtKg
        ) {
          // setting net weight
          setValue(
            'netWeight',
            Math.abs(vehicleWeight - selectedVehicleNumberData.emptyVehicleWtKg),
          );
        }
        setGetWeightButtonLoading(false);
      })
      .catch((err: ApolloError) => {
        logger(err);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(err.message);
        setGetWeightButtonLoading(false);
      });
  };

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormItem label="Vehicle No" isRequired requiredMark="after" {...formItemStyleProps}>
          <Select
            placeholder="Please select vehicle number"
            customStyles={inputComponentCommonStyle}
            name="vehicleNumber"
            rhfControllerProps={{
              control,
            }}
            options={vehicleNumbersOptions}
            onChange={(rhfOnChange, value) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              rhfOnChange(value);
              if (weighbridgeReceiptData) {
                weighbridgeReceiptData.getOpenWeighbridgeReceipts.forEach((item) => {
                  if (item.id === value) {
                    /* store selected vehicle number's data */
                    setSelectedVehicleNumberData(item as WeighbridgeReceiptType);
                    if (item && getSettingByNameData) {
                      setValue('emptyVehicleWtKg', item.emptyVehicleWtKg as number);
                      setValue('netWeight', Math.abs(item.fullVehicleWtKg - item.emptyVehicleWtKg));
                      setValue(
                        'amount',
                        getSettingByNameData.getSettingByName[0].floatValue as number,
                      );
                    }
                  }
                });
              }
            }}
            selectProps={{
              loading: weighbridgeReceiptDataLoading || getSettingByNameDataLoader,
              filterOption: (inputValue, option) => {
                let value;
                if (weighbridgeReceiptData && option) {
                  value = weighbridgeReceiptData.getOpenWeighbridgeReceipts.find(
                    (item) => item.id === option.value,
                  );
                }
                return value
                  ? value.vehicleNumber.toLowerCase().includes(inputValue.toLocaleLowerCase())
                  : false;
              },
              showSearch: true,
            }}
          />
        </FormItem>
        {selectedVehicleNumberData ? (
          <>
            <FormItem label="Driver name" {...formItemStyleProps}>
              <span>{selectedVehicleNumberData.driverName}</span>
            </FormItem>
            <FormItem label="Customer name" {...formItemStyleProps}>
              <span>{selectedVehicleNumberData.customerName}</span>
            </FormItem>
            <FormItem label="Material" {...formItemStyleProps}>
              <span>{selectedVehicleNumberData.material}</span>
            </FormItem>
            <FormItem label="Weight-1 (in kgs)" {...formItemStyleProps}>
              <InputNumber
                rhfControllerProps={{ control }}
                name="emptyVehicleWtKg"
                customStyles={inputComponentCommonStyle}
                inputNumberProps={{
                  disabled: true,
                }}
              />
            </FormItem>
            <FormItem label="Mill Name" {...formItemStyleProps}>
              <>
                {getBhagwatiMillIdQueryLoader ? (
                  <Spin className="loadingSpinner" />
                ) : (
                  bhagwatiMillData && bhagwatiMillData.mills[0] && bhagwatiMillData.mills[0].name
                )}
              </>
            </FormItem>
            <FormItem
              label="Weight-2 (in kgs)"
              labelColSpan={4}
              inputColSpan={10}
              labelStyle={{ textAlign: 'start' }}
            >
              <div style={{ display: 'flex' }}>
                <InputNumber
                  rhfControllerProps={{ control }}
                  name="fullVehicleWtKg"
                  placeholder="Please enter weight-2 (in kgs)"
                  customStyles={{ ...inputComponentCommonStyle, flex: 1, marginRight: 10 }}
                  inputNumberProps={{
                    disabled:
                      weighbridgeInputDisabledData &&
                      !weighbridgeInputDisabledData.getSettingByName[0].intValue
                        ? true
                        : false,
                  }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    getVehicleWeight();
                  }}
                  loading={getWeightButtonLoading}
                >
                  Get Weight
                </Button>
              </div>
            </FormItem>
            <FormItem label="Net Weight (in kgs)" {...formItemStyleProps}>
              <InputNumber
                rhfControllerProps={{ control }}
                name="netWeight"
                customStyles={inputComponentCommonStyle}
                inputNumberProps={{
                  disabled: true,
                }}
              />
            </FormItem>
            <FormItem label="Remark" {...formItemStyleProps}>
              <Input
                name="remark"
                isTextAreaInput={true}
                placeholder="Please enter remark"
                rhfControllerProps={{
                  control,
                }}
                textAreaProps={{ rows: 4 }}
                customStyles={inputComponentCommonStyle}
              />
            </FormItem>
            <FormItem
              label="Amount (in Rs.)"
              isRequired
              requiredMark="after"
              {...formItemStyleProps}
            >
              <InputNumber
                rhfControllerProps={{ control }}
                name="amount"
                customStyles={inputComponentCommonStyle}
                inputNumberProps={{
                  disabled:
                    getSettingByNameData && !getSettingByNameData.getSettingByName[0].intValue
                      ? true
                      : false,
                }}
              />
            </FormItem>
            <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
              <Button type="primary" htmlType="submit">
                Generate receipt
              </Button>
            </FormItem>
          </>
        ) : null}
      </form>
    </>
  );
};

export default EditWeighbridgeReceipts;
