import React, { useState } from 'react';
import {
  Enum_Item_Category,
  GetAllInternalTransferShipmentsQuery,
  GetAllInternalTransferShipmentsQueryVariables,
  InternalTransferShipmentItems,
  InternalTransferShipments,
} from '../../../graphql/graphql-types';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Table } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Link } from 'react-router-dom';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import { SearchOutlined } from '@ant-design/icons';
import { dateFormatFunc } from '../../../utils/globals';
import FinancialYearComponent from '../../../components/FinancialYearComponent';

/* extending isBetween plugin from day js library */
dayjs.extend(isBetween);

/* loading get all internal transfer shipment data query */
const getAllInternalTransferShipmentsQuery = loader(
  '../../../graphql/queries/getAllInternalTransferShipmentQuery.graphql',
);

/* get all internal shipments table prop type */
type GetAllInternalShipmentsTableType = Pick<
  InternalTransferShipments,
  'created_at' | 'grn' | 'id' | 'personMobile' | 'personName' | 'vehicleNumber'
> & {
  internalTransferShipmentItems: Array<Pick<InternalTransferShipmentItems, 'remark'>>;
} & {
  materialTypeValue?: Pick<Enum_Item_Category, 'display_name'> | null | undefined;
};

/* react functional component */
const InternalShipmentsReportScreen = () => {
  /* this state used to store start and end date, which is selected using date range picker or financial year option */
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  /* get all internal transfer shipment data query */
  const {
    data: getAllInternalTransferShipmentData,
    loading: getAllInternalTransferShipmentDataLoading,
    error: getAllInternalTransferShipmentDataError,
  } = useQuery<GetAllInternalTransferShipmentsQuery, GetAllInternalTransferShipmentsQueryVariables>(
    getAllInternalTransferShipmentsQuery,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllInternalTransferShipmentDataError) {
    return <div className="errorText">{getAllInternalTransferShipmentDataError.message}</div>;
  }

  return (
    <div className="tabLayout">
      <FinancialYearComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

      <Table<GetAllInternalShipmentsTableType>
        dataSource={
          getAllInternalTransferShipmentData && selectedDate.startDate && selectedDate.endDate
            ? getAllInternalTransferShipmentData.internalTransferShipments.filter((shipment) => {
                return dayjs(shipment.created_at).isBetween(
                  selectedDate.startDate,
                  selectedDate.endDate,
                  'date',
                  '[]',
                );
              })
            : getAllInternalTransferShipmentData &&
              getAllInternalTransferShipmentData.internalTransferShipments
        }
        loading={getAllInternalTransferShipmentDataLoading}
        className="tableStyle"
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
        style={{ marginTop: 30 }}
      >
        <Table.Column<GetAllInternalShipmentsTableType>
          title="GRN"
          dataIndex="grn"
          align="center"
          width={150}
          onFilter={(value, record) => {
            return record.grn
              ? record.grn.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
          render={(grnNumber, record) => {
            return <span>{grnNumber ? <Link to={`${record.id}`}>{grnNumber}</Link> : '-'}</span>;
          }}
        />
        <Table.Column<GetAllInternalShipmentsTableType>
          title="Created At"
          dataIndex="created_at"
          align="center"
          width={150}
          render={(text, record) => {
            return <span>{record.created_at ? dateFormatFunc(record.created_at) : '-'}</span>;
          }}
        />
        <Table.Column<GetAllInternalShipmentsTableType>
          title="Material Type"
          align="center"
          dataIndex="materialType"
          width={140}
          render={(text, record) => {
            return (
              <span style={{ textTransform: 'capitalize' }}>
                {record.materialTypeValue ? record.materialTypeValue.display_name : '-'}
              </span>
            );
          }}
        />
        <Table.Column<GetAllInternalShipmentsTableType>
          title="Delivery Person Name"
          dataIndex="personName"
          align="center"
          render={(personName) => {
            return <span style={{ textTransform: 'capitalize' }}>{personName || '-'}</span>;
          }}
          width={200}
        />
        <Table.Column<GetAllInternalShipmentsTableType>
          title="Delivery Person Mobile"
          dataIndex="personMobile"
          align="center"
          width={160}
          render={(personMobile) => {
            return <span style={{ textTransform: 'capitalize' }}>{personMobile || '-'}</span>;
          }}
        />
        <Table.Column<GetAllInternalShipmentsTableType>
          title="Vehicle Number"
          dataIndex="vehicleNumber"
          width={130}
          align="center"
          render={(vehicleNumber) => {
            return <span>{vehicleNumber || '-'}</span>;
          }}
        />
        <Table.Column<GetAllInternalShipmentsTableType>
          title="Remark"
          dataIndex="remark"
          align="center"
          render={(text, record) => {
            if (
              Array.isArray(record.internalTransferShipmentItems) &&
              record.internalTransferShipmentItems.length > 0
            ) {
              return record.internalTransferShipmentItems.map((item) => {
                return item.remark || '-';
              });
            }
            return '-';
          }}
        />
      </Table>
    </div>
  );
};

export default InternalShipmentsReportScreen;
