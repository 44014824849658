import React, { useEffect, useMemo, useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  AddPaymentDetailsMutation,
  AddPaymentDetailsMutationVariables,
  GetPaymentDetailsByGrnQuery,
  GetPaymentDetailsByGrnQueryVariables,
  InwardShipments,
  UpdatePaymentDetailsMutation,
  UpdatePaymentDetailsMutationVariables,
} from '../../../../graphql/graphql-types';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  Col,
  Descriptions,
  DescriptionsProps,
  Divider,
  Row,
  Table,
  message,
} from 'antd';
import { inputComponentCommonStyle } from '../../../../utils/globals';
import FormItem from '../../../../components/FormItem';
import Input from '../../../../components/Input';
import { RawMaterialInwardShipmentReportType } from '../../../../utils/types';
import RadioGroup from '../../../../components/RadioGroup';
import InputNumber from '../../../../components/InputNumber';
import DatePicker from '../../../../components/DatePicker';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { logger } from '../../../../utils/helpers';
import dayjs from 'dayjs';
import colors from '../../../../scss/variables.module.scss';

const getPaymentDetailsByGRNQuery = loader(
  '../../../../graphql/queries/getPaymentDetailsByGRNQuery.graphql',
);

const addPaymentDetailsMutation = loader(
  '../../../../graphql/mutations/addPaymentDetailsMutation.graphql',
);

const updatePaymentDetailsMutation = loader(
  '../../../../graphql/mutations/updatePaymentDetailsMutation.graphql',
);

/* raw material payment form prop type */
type RawMaterialPaymentFormPropType = {
  /* this prop used to store shipment data, which is selected to update payment */
  inwardShipmentData: RawMaterialInwardShipmentReportType;
};

/* type for payment deduction */
type PaymentDeductionType = {
  deductions: {
    reasons: string | null;
    amount: number | null;
  }[];
};

/* type for raw material payment form */
type RawMaterialPaymentFormType = Pick<InwardShipments, 'grn'> &
  Pick<
    AddPaymentDetailsMutationVariables['object'],
    'payment_done_at' | 'mode' | 'payment_number'
  > &
  PaymentDeductionType & {
    farmerOrTraderName: string;
    paymentDone: boolean;
    amount: number | null;
  };

/* raw material payment table type */
type RawMaterialPaymentTableType = Omit<
  AddPaymentDetailsMutationVariables['object'],
  'deductions' | 'amount'
> &
  PaymentDeductionType & {
    amount: number | null;
  };

/* form validation schema */
const schema = yup.object({
  payment_done_at: yup.string().nullable().required('Please select invoice date and try again'),
  amount: yup
    .number()
    .nullable()
    .positive('Please enter an amount greater than 0')
    .required('Please enter an amount and try again')
    .typeError('Please enter a valid number'),
  mode: yup.string().nullable().required('Please select payment mode and try again'),
  payment_number: yup.string().nullable().required('Please enter valid number and try again'),
  deductions: yup.array().of(
    yup.object().shape(
      {
        reasons: yup
          .string()
          .nullable()
          .when('amount', {
            is: (desc: number) => !!desc,
            then: yup.string().nullable().required('Both amount and reasons are required'),
          }),
        amount: yup
          .number()
          .nullable()
          .when('reasons', {
            is: (text: string) => !!text,
            then: yup.number().nullable().required('Both reason and amount are required'),
          }),
      },
      [
        ['amount', 'reasons'],
        ['reasons', 'amount'],
      ],
    ),
  ),
});

/* styling for Description component*/
const descriptionComponentStyleProps: DescriptionsProps = {
  /* define number of column in row */
  column: 1,
  /* set description components item colon false */
  colon: true,
  /* styling of description component labels */
  labelStyle: { width: '215px' },
};

/* react functional component */
const RawMaterialPaymentForm = ({
  inwardShipmentData,
}: RawMaterialPaymentFormPropType): JSX.Element => {
  /* this state used to show loading indicator on Add or Update button */
  const [isAddOrUpdateBtnLoading, setIsAddOrUpdateBtnLoading] = useState<boolean>(false);

  // state to store id for which user has to edit payment details
  const [paymentDetailsIdToEdit, setPaymentDetailsIdToEdit] = useState<string | null>(null);

  const [addPaymentDetails] = useMutation<
    AddPaymentDetailsMutation,
    AddPaymentDetailsMutationVariables
  >(addPaymentDetailsMutation);

  const [updatePaymentDetails] = useMutation<
    UpdatePaymentDetailsMutation,
    UpdatePaymentDetailsMutationVariables
  >(updatePaymentDetailsMutation);

  /** const to store GRN number */
  const grnValue = inwardShipmentData.grn;

  /** const to store farmer/Trader name and rmSeller Id */
  const rmSellerIdAndName = inwardShipmentData.items[0].seller
    ? {
        farmerOrTraderName: inwardShipmentData.items[0].seller.name,
        rmSellerId: inwardShipmentData.items[0].seller.id,
      }
    : null;

  /** logic starts from here for calculating `Net Total Amount (in Rs.)` */
  let totalAmount = 0;

  if (inwardShipmentData.weighbridgeCharges) {
    totalAmount -= inwardShipmentData.weighbridgeCharges;
  }

  inwardShipmentData.items.forEach((item) => {
    if (item.id) {
      /* destructing inward shipment item data */
      const { bagsCount, netMaterialWtPerBagKg, emptyBagCost, pricePerKg, brokeragePerQuintal } =
        item;

      /* const used to store number of bags in shipment */
      const numberOfBags = bagsCount ? bagsCount : 0;

      if (!item.materialReturned) {
        /* const used to store total net material weight */
        const totalNetMaterialWeight =
          numberOfBags * (netMaterialWtPerBagKg ? netMaterialWtPerBagKg : 0);

        /* const used to store total new material weight in quintal  */
        const totalNetMaterialWeightInQt = parseFloat((totalNetMaterialWeight / 100).toFixed(2));

        /* const used to store price of total weight */
        const priceOfTotalWt = totalNetMaterialWeightInQt * ((pricePerKg ? pricePerKg : 0) * 100);

        /* const used to store total price of empty bags weight */
        const priceOfTotalEmptyBagWt = (emptyBagCost ? emptyBagCost : 0) * numberOfBags;

        /* const used to store price of brokerage */
        const priceOfBrokerage =
          (brokeragePerQuintal ? brokeragePerQuintal : 0) * totalNetMaterialWeightInQt;

        /* const used to store sub total */
        const subtotal = parseFloat(
          (priceOfTotalWt + priceOfBrokerage - priceOfTotalEmptyBagWt).toFixed(2),
        );

        /* const used to store total amount */
        totalAmount += subtotal;

        if (
          inwardShipmentData.laborChargeType &&
          inwardShipmentData.laborChargePerBag !== null &&
          inwardShipmentData.laborChargePerBag !== undefined
        ) {
          if (inwardShipmentData.laborChargeType === 'add') {
            /* total amount for labour charge type 'add' */
            totalAmount += numberOfBags * inwardShipmentData.laborChargePerBag;
          } else {
            /* total amount for labour charge type 'deduct' */
            totalAmount -= numberOfBags * inwardShipmentData.laborChargePerBag;
          }
        }
      }
    }
  });
  /** logic ends here for calculating `Net Total Amount (in Rs.)` */

  const {
    data,
    error: getPaymentDetailsByGRNQueryError,
    loading,
    refetch,
  } = useQuery<GetPaymentDetailsByGrnQuery, GetPaymentDetailsByGrnQueryVariables>(
    getPaymentDetailsByGRNQuery,
    {
      variables: {
        grn: grnValue as string,
      },
    },
  );

  /** const to store payment details from query  */
  const paymentDetails =
    data &&
    Array.isArray(data.payment) &&
    data.payment.length > 0 &&
    (data.payment as RawMaterialPaymentTableType[]);

  /* function to calculate `Paid Amount (in Rs.)`, `Total deducted Amount (in Rs.)` and `Remaining amount (in Rs.)`
  various amounts based on payment data details */
  const amountCalculation = useMemo(() => {
    const paidAmount =
      paymentDetails &&
      paymentDetails.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.amount || 0);
      }, 0);

    /** const to store total deduction amount */
    const totalDeductedAmount =
      paymentDetails &&
      paymentDetails
        .map((payment) => payment.deductions.map((deduction) => deduction.amount || 0))
        .flat()
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const remainingAmount = totalAmount - ((paidAmount || 0) + (totalDeductedAmount || 0));

    return {
      paidAmount,
      totalDeductedAmount,
      remainingAmount,
    };
  }, [paymentDetails, totalAmount]);

  /** const to store initial default values which is used for default values and during reset */
  const initialDefaultValues = {
    amount: null,
    payment_number: '',
    deductions: [{ amount: null, reasons: null }],
    payment_done_at: null,
    mode: '',
  };

  const {
    control,
    watch,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm<RawMaterialPaymentFormType>({
    defaultValues: {
      grn: grnValue,
      farmerOrTraderName:
        rmSellerIdAndName && rmSellerIdAndName.farmerOrTraderName
          ? rmSellerIdAndName.farmerOrTraderName
          : '-',
      paymentDone: false,
      ...initialDefaultValues,
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const isPaymentDetailsArray = Array.isArray(paymentDetails) && paymentDetails.length > 0;

  /** useEffect to setValue for `paymentDone` if `paymentDetails` contain payment details  */
  useEffect(() => {
    if (isPaymentDetailsArray) {
      setValue('paymentDone', true);
    }
  }, [isPaymentDetailsArray, setValue]);

  const { fields, append, remove } = useFieldArray({
    name: 'deductions',
    control,
  });

  const isPaymentDone = watch('paymentDone');

  /* extracting `paidAmount`, `remainingAmount`, and `totalDeductedAmount` from amountCalculation */
  const { paidAmount, remainingAmount, totalDeductedAmount } = amountCalculation;

  /** const to store payment mode options  */
  const paymentModes = [
    { label: 'Cash', value: 'cash' },
    {
      label: 'UPI',
      value: 'upi',
    },
    {
      label: 'Check',
      value: 'check',
    },
    {
      label: 'Other',
      value: 'other',
    },
  ];

  /** const to store edit payment details  */
  const editPaymentDetails = paymentDetails
    ? paymentDetails.find((item) => item.id === paymentDetailsIdToEdit)
    : null;

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getPaymentDetailsByGRNQueryError) {
    return <div className="errorText">{getPaymentDetailsByGRNQueryError.message}</div>;
  }

  return (
    <Row>
      <Col>
        <Descriptions {...descriptionComponentStyleProps}>
          <Descriptions.Item label="GRN">{grnValue}</Descriptions.Item>
          <Descriptions.Item label="Farmer/Trader Name">
            {rmSellerIdAndName && rmSellerIdAndName.farmerOrTraderName}
          </Descriptions.Item>
        </Descriptions>
        <form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(async (formData: RawMaterialPaymentFormType) => {
            setIsAddOrUpdateBtnLoading(true);

            try {
              /** const to calculate entered deduction amount  */
              const deductionAmount =
                formData.deductions &&
                Array.isArray(formData.deductions) &&
                formData.deductions.length > 0
                  ? formData.deductions.reduce(
                      (accumulator, currentValue) => accumulator + (currentValue.amount || 0),
                      0,
                    )
                  : 0;

              /** if user is editing payment details then `newly entered amount is compared with (previous entered amount + remaining amount)`
               * if its difference is 0 then payment complete status is true, otherwise false
               */
              if (paymentDetailsIdToEdit && editPaymentDetails) {
                /** const to store previous deduction amount which is entered before edit  */
                const previousDeductionAmount =
                  editPaymentDetails.deductions &&
                  Array.isArray(editPaymentDetails.deductions) &&
                  editPaymentDetails.deductions.length > 0
                    ? editPaymentDetails.deductions.reduce(
                        (accumulator, currentValue) => accumulator + (currentValue.amount || 0),
                        0,
                      )
                    : 0;

                /** when user is editing payment details currently entered amount is subtracted from addition of remaining
                 * amount and previously entered amount. If the difference is 0 then remaining amount is 0 i.e. payment is
                 * completed otherwise payment is remaining
                 */
                const amountRemaining =
                  Number(remainingAmount.toFixed(2)) +
                  Number(editPaymentDetails.amount) +
                  previousDeductionAmount -
                  ((formData.amount || 0) + deductionAmount);

                await updatePaymentDetails({
                  variables: {
                    amount: formData.amount || null,
                    mode: formData.mode || null,
                    payment_done_at: formData.payment_done_at || null,
                    payment_number: formData.payment_number || null,
                    deductions: formData.deductions || null,
                    id: paymentDetailsIdToEdit,
                    inwardShipmentId: inwardShipmentData.id,
                    isPaymentDone: amountRemaining === 0 ? true : false,
                  },
                });
              } else {
                /** if user is adding new payment details then newly entered amount is compared with remaining amount and
                 * if its difference is 0 then payment complete status is true, otherwise false
                 */

                /** const to calculate remaining amount and if it is zero which means payment is completed  */
                const amountRemaining =
                  Number(remainingAmount.toFixed(2)) - ((formData.amount || 0) + deductionAmount);

                await addPaymentDetails({
                  variables: {
                    object: {
                      amount: formData.amount || null,
                      deductions: formData.deductions || null,
                      mode: formData.mode || null,
                      payment_number: formData.payment_number || null,
                      inward_shipment_id: inwardShipmentData.id || null,
                      payment_done_at: formData.payment_done_at || null,
                      rmSeller_id: (rmSellerIdAndName && rmSellerIdAndName.rmSellerId) || null,
                    },
                    inwardShipmentId: inwardShipmentData.id,
                    isPaymentDone: amountRemaining === 0 ? true : false,
                  },
                });
              }

              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.success(
                `Payment details have been ${
                  paymentDetailsIdToEdit ? 'updated' : 'added'
                } successfully`,
              );
              reset({
                amount: null,
                payment_number: null,
                deductions: [{ amount: null, reasons: null }],
                payment_done_at: null,
                mode: null,
              });

              // refetching `getPaymentDetailsByGRN` query
              await refetch();

              setPaymentDetailsIdToEdit(null);
              setIsAddOrUpdateBtnLoading(false);
            } catch (error) {
              const errorObj = error as ApolloError;
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              message.error(errorObj.message);
              logger(errorObj);
              setPaymentDetailsIdToEdit(null);

              setIsAddOrUpdateBtnLoading(false);
            }
          })}
        >
          {!paymentDetailsIdToEdit && remainingAmount === 0 ? (
            <Alert
              message="Remaining balance is zero. Payment cannot be added for this GRN."
              type="warning"
              showIcon
              style={{ width: 500, marginTop: 20 }}
            />
          ) : (
            <>
              <FormItem
                labelColSpan={6}
                inputColSpan={18}
                requiredMark="after"
                isRequired
                label="Payment done:"
                customStyle={{ paddingTop: 0 }}
              >
                <RadioGroup
                  name="paymentDone"
                  disabled={isPaymentDetailsArray}
                  rhfControllerProps={{
                    control,
                  }}
                  options={[
                    { label: 'Yes', value: true },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                />
              </FormItem>
              {isPaymentDone ? (
                <>
                  <FormItem
                    labelColSpan={6}
                    inputColSpan={18}
                    requiredMark="after"
                    isRequired
                    label="Invoice date:"
                    customStyle={{ paddingTop: 10 }}
                    errorText={
                      errors && errors.payment_done_at ? errors.payment_done_at.message : undefined
                    }
                  >
                    <DatePicker
                      name="payment_done_at"
                      rhfControllerProps={{
                        control,
                      }}
                      customStyles={{ ...inputComponentCommonStyle, width: 355 }}
                    />
                  </FormItem>
                  <FormItem
                    labelColSpan={6}
                    inputColSpan={10}
                    requiredMark="after"
                    isRequired
                    label="Amount:"
                    customStyle={{ paddingTop: 15 }}
                    errorText={errors && errors.amount ? errors.amount.message : undefined}
                  >
                    <InputNumber
                      name="amount"
                      placeholder="Please enter amount"
                      rhfControllerProps={{
                        control,
                      }}
                      customStyles={inputComponentCommonStyle}
                    />
                  </FormItem>
                  <FormItem
                    labelColSpan={6}
                    inputColSpan={18}
                    requiredMark="after"
                    isRequired
                    label="Mode:"
                    customStyle={{ paddingTop: 15 }}
                    errorText={errors && errors.mode ? errors.mode.message : undefined}
                  >
                    <RadioGroup
                      name="mode"
                      rhfControllerProps={{
                        control,
                      }}
                      options={paymentModes}
                    />
                  </FormItem>
                  <FormItem
                    labelColSpan={6}
                    inputColSpan={10}
                    requiredMark="after"
                    isRequired
                    label="Check/Reference no:"
                    customStyle={{ paddingTop: 15 }}
                    errorText={
                      errors && errors.payment_number ? errors.payment_number.message : undefined
                    }
                  >
                    <Input
                      name="payment_number"
                      placeholder="Please enter check or reference number"
                      rhfControllerProps={{
                        control,
                      }}
                      customStyles={inputComponentCommonStyle}
                    />
                  </FormItem>
                  <Row style={{ display: 'flex' }}>
                    <Col span={16}>
                      <FormItem
                        labelColSpan={9}
                        inputColSpan={15}
                        label="Deductions:"
                        customStyle={{ paddingTop: 15 }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 10,
                          }}
                        >
                          {fields.map((field, index) => {
                            return (
                              <>
                                <div
                                  style={{ display: 'flex', columnGap: 20, alignItems: 'center' }}
                                >
                                  <Input
                                    name={`deductions.${index}.reasons`}
                                    placeholder="Enter reason"
                                    rhfControllerProps={{
                                      control,
                                    }}
                                    customStyles={inputComponentCommonStyle}
                                  />
                                  <InputNumber
                                    key={field.id}
                                    name={`deductions.${index}.amount`}
                                    placeholder="Enter amount"
                                    rhfControllerProps={{
                                      control,
                                    }}
                                    customStyles={inputComponentCommonStyle}
                                  />
                                  {Array.isArray(fields) && fields.length > 1 ? (
                                    <MinusCircleOutlined
                                      style={{
                                        fontSize: 15,
                                        color: colors.labelColor,
                                      }}
                                      onClick={() => {
                                        remove(index);
                                      }}
                                    />
                                  ) : null}
                                </div>

                                {errors && errors.deductions && errors.deductions[index] ? (
                                  <div style={{ color: colors.errorTextColor, marginTop: 0 }}>
                                    {errors.deductions[index].amount?.message ||
                                      errors.deductions[index].reasons?.message}
                                  </div>
                                ) : null}
                              </>
                            );
                          })}
                        </div>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 20 }}>
                    <Col
                      offset={6}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center',
                      }}
                    >
                      <Button
                        type="default"
                        onClick={() =>
                          append({
                            amount: null,
                            reasons: null,
                          })
                        }
                        style={inputComponentCommonStyle}
                        className="addDeductionBtn"
                      >
                        <PlusOutlined
                          style={{
                            fontSize: 15,
                            color: colors.labelColor,
                          }}
                        />
                        Add Deduction
                      </Button>
                    </Col>
                  </Row>
                  <FormItem labelColSpan={6} inputColSpan={10}>
                    <div style={{ display: 'flex', columnGap: 20 }}>
                      <Button type="primary" htmlType="submit" loading={isAddOrUpdateBtnLoading}>
                        {paymentDetailsIdToEdit ? 'Update' : 'Submit'}
                      </Button>

                      <Button
                        type="default"
                        onClick={() => {
                          if (isPaymentDetailsArray) {
                            reset({
                              ...initialDefaultValues,
                              paymentDone: true,
                            });
                            setPaymentDetailsIdToEdit(null);
                          } else {
                            reset();
                            setPaymentDetailsIdToEdit(null);
                          }
                        }}
                      >
                        {paymentDetailsIdToEdit ? 'Cancel' : 'Reset'}
                      </Button>
                    </div>
                  </FormItem>
                </>
              ) : null}
            </>
          )}
        </form>

        <Descriptions {...descriptionComponentStyleProps} style={{ marginTop: 30 }} column={2}>
          <Descriptions.Item label="Net Total Amount (in Rs.)">
            {totalAmount.toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Paid Amount (in Rs.)">
            {Number(paidAmount).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Total deducted Amount (in Rs.)">
            {Number(totalDeductedAmount).toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Remaining amount (in Rs.)">
            {Number(remainingAmount).toFixed(2)}
          </Descriptions.Item>
        </Descriptions>
      </Col>

      {isPaymentDone ? (
        <>
          <Divider style={{ marginBottom: 5, marginTop: 5 }} />
          <h3>Payment Details</h3>

          <Table<RawMaterialPaymentTableType>
            dataSource={(paymentDetails as RawMaterialPaymentTableType[]) || []}
            className="tableStyle"
            bordered
            size="small"
            loading={loading}
            pagination={{ showSizeChanger: true }}
            style={{ marginTop: 5 }}
            rowKey="id"
          >
            <Table.Column<RawMaterialPaymentTableType>
              title="Invoice Date"
              dataIndex="payment_done_at"
              align="center"
              width={200}
              render={(value, record) => {
                return dayjs(record.payment_done_at).format('DD MMM YYYY');
              }}
            />
            <Table.Column title="Amount" dataIndex="amount" align="center" width={200} />
            <Table.Column<RawMaterialPaymentTableType>
              title="Mode"
              dataIndex="mode"
              align="center"
              width={200}
              render={(value, record) => {
                /** const to store selected payment mode label and value */
                const paymentMode = paymentModes.find((item) => item.value === record.mode);
                return paymentMode ? paymentMode.label : '-';
              }}
            />
            <Table.Column<RawMaterialPaymentTableType>
              title="Check/Reference"
              dataIndex="payment_number"
              align="center"
              width={200}
            />
            <Table.Column<RawMaterialPaymentTableType>
              title="Deductions"
              dataIndex="deductions"
              align="center"
              width={400}
              render={(_, record) => {
                /** const to filter out and store null deductions */
                const validDeductions =
                  Array.isArray(record.deductions) &&
                  record.deductions.length > 0 &&
                  record.deductions.filter((item) => item.reasons && item.amount);

                return Array.isArray(validDeductions) && validDeductions.length > 0
                  ? validDeductions.map((item, index) => (
                      <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          <b>{item.reasons}:</b> {item.amount}
                        </span>
                      </div>
                    ))
                  : '-';
              }}
            />
            <Table.Column<RawMaterialPaymentTableType>
              title="Actions"
              align="center"
              dataIndex="actions"
              width={200}
              render={(value, record) => {
                return (
                  <Button
                    type="default"
                    onClick={() => {
                      setPaymentDetailsIdToEdit(record.id || '');
                      reset({
                        amount: record.amount,
                        payment_number: record.payment_number,
                        payment_done_at: dayjs(record.payment_done_at).format('YYYY-MM-DD'),
                        mode: record.mode,
                        deductions: record.deductions,
                        paymentDone: true,
                      });
                    }}
                    disabled={record.id === paymentDetailsIdToEdit}
                  >
                    Edit
                  </Button>
                );
              }}
            />
          </Table>
        </>
      ) : null}
    </Row>
  );
};

export default RawMaterialPaymentForm;
