import React, { useRef } from 'react';
import { Row, Col, Divider, Button, Spin, Statistic } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams, Link } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import {
  GetWeighbridgeReceiptByIdQuery,
  GetWeighbridgeReceiptByIdQueryVariables,
  WeighbridgeReceipts,
} from '../graphql/graphql-types';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';

/* loading get weigh bridge receipt by Id query */
const getWeighbridgeReceiptByIdQuery = loader(
  '../graphql/queries/getWeighbridgeReceiptByIdQuery.graphql',
);

/* weighbridge receipt component prop type */
type WeighbridgeReceiptComponentPropType = {
  /* this prop used to determine whether screen is weighbridge tab or weighbridge Report */
  screenType: 'weighbridgeTab' | 'weighbridgeReport';
};

/* React functional component */
const WeighbridgeReceiptComponent = ({ screenType }: WeighbridgeReceiptComponentPropType) => {
  /* extracting params from use params hook */
  const params = useParams();

  /** const to store reference which is used to print content */
  const printRef = useRef(null);

  /** const used to call 'react-to-print' print method*/
  const handlePrint = useReactToPrint({
    /* content for printing */
    content: () => printRef.current,
    /* styling for page during print */
    pageStyle: () => `
    @media print {
      .weighbridgeDiv {
        margin : 0 !important
      }
    }
  `,
  });

  /* weighbridge receipt by Id query  */
  const {
    data: getWeighbridgeReceiptByIdData,
    loading: getWeighbridgeReceiptByIdDataLoading,
    error: getWeighbridgeReceiptByIdDataError,
  } = useQuery<GetWeighbridgeReceiptByIdQuery, GetWeighbridgeReceiptByIdQueryVariables>(
    getWeighbridgeReceiptByIdQuery,
    {
      variables: { id: Number(params.id) },
    },
  );

  /* show loading indicator on the screen until data get fetch from the server */
  if (getWeighbridgeReceiptByIdDataLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getWeighbridgeReceiptByIdDataError) {
    return <div className="errorText">{getWeighbridgeReceiptByIdDataError.message}</div>;
  }
  /* destructuring weighbridge receipt data */
  if (getWeighbridgeReceiptByIdData) {
    const {
      createdAt,
      receiptNumber,
      vehicleNumber,
      driverName,
      customerName,
      material,
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */
      emptyVehicleWtKg,
      fullVehicleWtKg,
      amountTaken,
      /* eslint-enable @typescript-eslint/no-unsafe-assignment */
      remark,
    } = getWeighbridgeReceiptByIdData.getWeighbridgeReceiptById as WeighbridgeReceipts;

    return (
      <div style={{ padding: 20 }}>
        <Link
          to={screenType === 'weighbridgeTab' ? '/weighbridgeReceipts/edit' : '/report/weighbridge'}
          style={{ textAlign: 'start' }}
        >
          <ArrowLeftOutlined className="goBackButton" />
        </Link>

        <div className="weighbridgeDiv" style={{ margin: '10px 100px 40px 100px' }} ref={printRef}>
          <h2 style={{ textAlign: 'center', marginBottom: 20 }}>Weighbridge Receipt</h2>
          <Row className="inwardShipmentPrintDescription">
            <Col span={8}>
              <Statistic
                title={screenType === 'weighbridgeTab' ? 'Gross Wt. (tonnes)' : 'Weight 1 (tonnes)'}
                value={
                  fullVehicleWtKg > emptyVehicleWtKg
                    ? fullVehicleWtKg / 1000 || '-'
                    : emptyVehicleWtKg / 1000 || '-'
                }
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={screenType === 'weighbridgeTab' ? 'Tare Wt. (tonnes)' : 'Weight 2 (tonnes)'}
                value={
                  emptyVehicleWtKg < fullVehicleWtKg
                    ? emptyVehicleWtKg / 1000 || '-'
                    : fullVehicleWtKg / 1000 || '-'
                }
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Net Wt (tonnes)"
                value={
                  fullVehicleWtKg || emptyVehicleWtKg
                    ? Math.abs((fullVehicleWtKg - emptyVehicleWtKg) / 1000).toFixed(2)
                    : '-'
                }
              />
            </Col>
            <Divider />
          </Row>
          <Row style={{ rowGap: 30 }}>
            <Col span={8}>
              <Statistic
                title="Date"
                value={
                  screenType === 'weighbridgeTab'
                    ? dayjs().format('MMM DD, YYYY')
                    : dayjs(createdAt).format('DD.MM.YYYY')
                }
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Time"
                value={
                  screenType === 'weighbridgeTab'
                    ? dayjs().format('hh:mm A')
                    : dayjs(createdAt).format('h:mm A')
                }
              />
            </Col>
            <Col span={8}>
              <Statistic title="Receipt No." value={receiptNumber ? receiptNumber : '-'} />
            </Col>

            <Col span={8}>
              <Statistic title="Vehicle No" value={vehicleNumber ? vehicleNumber : '-'} />
            </Col>
            <Col span={8}>
              <Statistic title="Driver Name" value={driverName ? driverName : '-'} />
            </Col>
            <Col span={8}>
              <Statistic title="Customer Name" value={customerName ? customerName : '-'} />
            </Col>

            <Col span={8}>
              <Statistic title="Material" value={material ? material : '-'} />
            </Col>
            <Col span={8}>
              <Statistic title="Remark" value={remark ? remark : '-'} />
            </Col>
            <Col span={8}>
              <Statistic
                title="Amount (in Rs.)"
                value={amountTaken ? (amountTaken as number) : '-'}
              />
            </Col>
          </Row>
        </div>

        <Button
          type="primary"
          onClick={() => handlePrint()}
          style={{ marginTop: 20, marginRight: 100, float: 'right' }}
        >
          Print Receipt
        </Button>
      </div>
    );
  }
  return <div />;
};

export default WeighbridgeReceiptComponent;
