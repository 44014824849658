import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

/* React functional component */
const RawMaterialTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();
  /* useLocation destructuring */
  const location = useLocation();

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('unload')) {
      return 'materialUnloadKey';
    } else if (location.pathname.includes('truckOut')) {
      return 'finishShipmentKey';
    }
    return 'createShipmentKey';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="paddyBags"
        onTabClick={(key: string) => {
          if (key === 'materialUnloadKey') {
            navigate('unload');
          } else if (key === 'finishShipmentKey') {
            navigate('truckOut');
          } else {
            navigate('create');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab="Create Shipment" key="createShipmentKey">
          <Outlet />
        </TabPane>
        <TabPane tab="Material Unloading" key="materialUnloadKey">
          <Outlet />
        </TabPane>
        <TabPane tab="Finish Shipment" key="finishShipmentKey">
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default RawMaterialTabLayout;
