import React from 'react';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetAllOutwardShipmentsForBagsQuery,
  GetAllOutwardShipmentsForBagsQueryVariables,
} from '../../../../graphql/graphql-types';
import { DailyReportsPropType } from '../../../../utils/types';
import DailyReportOutwardBagOrOtherItemTable from './DailyReportOutwardBagOrOtherItemTable';

/* loading get all outward shipment for bags Query */
const getAllOutwardShipmentsForBagsQuery = loader(
  '../../../../graphql/queries/getAllOutwardShipmentsForBagsQuery.graphql',
);

/* React functional component */
const DailyReportsOutwardBagsScreen = ({ startDate, endDate }: DailyReportsPropType) => {
  /* get All outward Shipment For bag data using query with start and end date from date range field */
  const {
    data: getAllOutwardShipmentsForBagsData,
    loading: getAllOutwardShipmentsForBagsLoading,
    error: getAllOutwardShipmentsForBagsError,
  } = useQuery<GetAllOutwardShipmentsForBagsQuery, GetAllOutwardShipmentsForBagsQueryVariables>(
    getAllOutwardShipmentsForBagsQuery,
    {
      variables: {
        createdAt:
          startDate && endDate
            ? {
                _gte: startDate,
                _lte: endDate,
              }
            : {},
      },
    },
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllOutwardShipmentsForBagsError) {
    return <div className="errorText">{getAllOutwardShipmentsForBagsError.message}</div>;
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getAllOutwardShipmentsForBagsLoading) {
    return <Spin className="loadingSpinner" />;
  }

  return (
    <>
      {getAllOutwardShipmentsForBagsData ? (
        <DailyReportOutwardBagOrOtherItemTable
          data={getAllOutwardShipmentsForBagsData.outwardShipments}
          outwardShipmentType="bags"
          reportScreenType="daily"
        />
      ) : null}
    </>
  );
};

export default DailyReportsOutwardBagsScreen;
