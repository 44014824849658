import React from 'react';
import { Descriptions, Divider, Spin, Table } from 'antd';
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetOutwardShipmentByIdQuery,
  GetOutwardShipmentByIdQueryVariables,
  OutwardShipments,
} from '../../../../graphql/graphql-types';
import { dateFormatFunc, toQuintals } from '../../../../utils/globals';
import { OutwardShipmentOrdersType } from '../../../../utils/types';
import ColumnSearchComponent from '../../../../components/ColumnSearchComponent';

/* loading get outward shipment by id query */
const getOutwardShipmentByIdQuery = loader(
  '../../../../graphql/queries/getOutwardShipmentByIdQuery.graphql',
);

/* React functional component */
const OutwardShipmentSummaryScreen = () => {
  /* destructing id from useParam */
  const { id } = useParams();

  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /*  get Outward shipment by id data query */
  const {
    data: getOutwardShipmentByIdData,
    loading: getOutwardShipmentByIdLoading,
    error: getOutwardShipmentByIdError,
  } = useQuery<GetOutwardShipmentByIdQuery, GetOutwardShipmentByIdQueryVariables>(
    getOutwardShipmentByIdQuery,
    { variables: { outwardShipmentId: Number(id) } },
  );

  const DescriptionDetails = () => {
    if (getOutwardShipmentByIdData) {
      const {
        grn,
        status,
        createdAt,
        transporter,
        transporterVehicle,
        orders,
        drivers,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        fullVehicleWtKg,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        emptyVehicleWtKg,
      } = getOutwardShipmentByIdData.getOutwardShipmentById as OutwardShipments;

      /* calculated net weight */
      const netWeight = fullVehicleWtKg - emptyVehicleWtKg;

      /* Stored calculated Actual Weight */
      let actualWt = 0;
      /* calculated Actual Weight */
      if (Array.isArray(orders)) {
        if (orders) {
          for (let index = 0; index < orders.length; index++) {
            actualWt +=
              (orders[index].bagSizeKg as number) * (orders[index].loadedBagsCount as number);
          }
        }
      }

      return (
        <>
          <Descriptions
            colon={false}
            column={1}
            labelStyle={{ width: 260 }}
            style={{ marginTop: 15 }}
            contentStyle={{ textTransform: 'capitalize' }}
          >
            <Descriptions.Item label="GRN">{grn}</Descriptions.Item>
            <Descriptions.Item label="Status">{status ? status : '-'}</Descriptions.Item>
            <Descriptions.Item label="Start Date">
              {dateFormatFunc(createdAt ? (createdAt as string) : '')}
            </Descriptions.Item>
            <Descriptions.Item label="Transporter Name">
              {transporter ? transporter.name : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Vehicle Name">
              {transporterVehicle ? transporterVehicle.number : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Drivers">
              {Array.isArray(drivers) && drivers.length > 0
                ? drivers.map((driverData) => {
                    return (
                      <>
                        {driverData.driver.name} - {driverData.driver.mobile}
                        <br />
                      </>
                    );
                  })
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Vehicle Capacity (in quintals)">
              {transporterVehicle
                ? toQuintals(transporterVehicle.capacityKgs as number).toFixed(2)
                : '-'}
            </Descriptions.Item>
            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
              <h3>Actual</h3>
            </div>
            <Descriptions.Item label="Gross Weight (in quintals)">
              {toQuintals(fullVehicleWtKg as number).toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Tare Weight (in quintals)">
              {toQuintals(emptyVehicleWtKg as number).toFixed(2)}
            </Descriptions.Item>
            <Descriptions.Item label="Net Weight (in quintals)">
              {toQuintals(netWeight).toFixed(2)}
            </Descriptions.Item>
            <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
              <h3>Calculated</h3>
            </div>
            <Descriptions.Item label="Net Weight (in quintals)">
              {toQuintals(actualWt).toFixed(2)}
            </Descriptions.Item>
          </Descriptions>
        </>
      );
    }
    return <div />;
  };
  /* show loading indicator on the screen until data get fetch from the server */
  if (getOutwardShipmentByIdLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getOutwardShipmentByIdError) {
    return <div className="errorText">{getOutwardShipmentByIdError.message}</div>;
  }

  return (
    <div className="tabLayout">
      <div style={{ display: 'flex' }}>
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftOutlined className="goBackButton" />
        </div>
        <h2>Outward Shipment Summary</h2>
      </div>
      <DescriptionDetails />
      <Divider style={{ marginTop: '1px' }} />
      <div style={{ paddingTop: '10px' }}>
        <h3>Orders</h3>
      </div>
      <Table<OutwardShipmentOrdersType>
        dataSource={
          getOutwardShipmentByIdData && getOutwardShipmentByIdData.getOutwardShipmentById
            ? getOutwardShipmentByIdData.getOutwardShipmentById.orders
            : []
        }
        bordered
        size="small"
        rowKey="id"
        className="tableStyle"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<OutwardShipmentOrdersType>
          title="Order #"
          dataIndex="id"
          key="id"
          width="150px"
          align="center"
          onFilter={(value, record) => {
            return record.number
              ? record.number.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="order number"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
          render={(text, record) => record.number}
        />
        <Table.Column<OutwardShipmentOrdersType>
          title="Buyer name"
          dataIndex="buyerName"
          key="buyerName"
          width="150px"
          align="center"
          onFilter={(value, record) => {
            return record.buyer && record.buyer.name
              ? record.buyer.name.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="buyer name" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
          render={(text, record) => {
            return record.buyer ? record.buyer.name : '-  ';
          }}
        />
        <Table.Column<OutwardShipmentOrdersType>
          title="Broker"
          dataIndex="broker"
          align="center"
          render={(text, record) => (record.broker ? record.broker.name : '-')}
        />
        <Table.Column<OutwardShipmentOrdersType>
          title={() => {
            return (
              <>
                Brand
                <br />
                Packaging Size
              </>
            );
          }}
          dataIndex="brandPackagingSize"
          align="center"
          width="230px"
          render={(text, record) => {
            return (
              <>
                {record.product ? (
                  <>
                    <span style={{ fontWeight: 'bold' }}>Brand: </span>
                    {record.product.brand}
                  </>
                ) : (
                  '-'
                )}
                <br />
                {record.bagSizeKg ? (
                  <>
                    <span style={{ fontWeight: 'bold' }}>Size: </span>
                    {record.bagSizeKg} kgs
                  </>
                ) : null}
              </>
            );
          }}
        />
        <Table.Column<OutwardShipmentOrdersType>
          title="Invoice No."
          dataIndex="invoiceNo"
          key="invoiceNo"
          width="180px"
          align="center"
          render={(text, record) => {
            return record.invoiceNo || '-';
          }}
        />
        <Table.Column<OutwardShipmentOrdersType>
          title="Planned No. of Bags"
          dataIndex="plannedBagsCount"
          key="plannedBagsCount"
          align="center"
          render={(text, record) => {
            return record.plannedBagsCount || '-';
          }}
        />
        <Table.Column<OutwardShipmentOrdersType>
          title="Loaded No. of Bags"
          dataIndex="loadedBagsCount"
          key="loadedBagsCount"
          align="center"
          render={(text, record) => {
            return record.loadedBagsCount || 0;
          }}
        />
        <Table.Column<OutwardShipmentOrdersType>
          title="Net Weight (quintals)"
          dataIndex="netWeight"
          key="netWeight"
          align="center"
          render={(text, record) => {
            return toQuintals(
              (record.loadedBagsCount as number) * (record.bagSizeKg as number),
            ).toFixed(2);
          }}
        />
      </Table>
    </div>
  );
};

export default OutwardShipmentSummaryScreen;
