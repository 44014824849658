import React, { useState } from 'react';
import { ApolloError, Reference, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import {
  CreateProductTypeMutation,
  CreateProductTypeMutationVariables,
  UpdateProductTypeMutation,
  UpdateProductTypeMutationVariables,
} from '../../../graphql/graphql-types';
import { Button, message, Modal } from 'antd';
import { logger } from '../../../utils/helpers';
import { inputComponentCommonStyle } from '../../../utils/globals';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import RequiredMessage from '../../../components/RequiredMessage';
import { ProductType } from '../../../utils/types';

/* add or edit product type form prop type */
type AddOrEditProductTypeFormPropType = {
  /* prop type used to check whether the AddOrEditProductType form is being called from 'edit' mode or 'add' mode */
  mode: 'add' | 'edit';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  dataToEdit?: ProductType;
  /* prop type used to close AddOrEditProductTypeForm form modal */
  closeModal: () => void;
};

/* load add product type mutation */
const addProductTypeMutation = loader(
  '../../../graphql/mutations/createProductTypeMutation.graphql',
);

/* load edit product type mutation */
const editProductTypeMutation = loader(
  '../../../graphql/mutations/updateProductTypeMutation.graphql',
);

/* React functional component */
const AddOrEditProductTypeForm = ({
  mode,
  dataToEdit = undefined,
  closeModal,
}: AddOrEditProductTypeFormPropType) => {
  /* state used show loading indicator on 'Create' or 'Update' button */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* Mutation used to add new product type */
  const [createProductType] = useMutation<
    CreateProductTypeMutation,
    CreateProductTypeMutationVariables
  >(addProductTypeMutation);

  /* Mutation used to update existing product type */
  const [updateProductType] = useMutation<
    UpdateProductTypeMutation,
    UpdateProductTypeMutationVariables
  >(editProductTypeMutation);

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ProductType>({
    defaultValues: mode === 'edit' && dataToEdit ? { name: dataToEdit.name } : { name: '' },
    mode: 'onChange',
  });

  /* function used to handle add or edit Product type form submit */
  const onSubmit = handleSubmit((data) => {
    setIsSubmitOrUpdateBtnLoading(true);
    if (mode === 'edit' && dataToEdit) {
      updateProductType({
        variables: {
          id: dataToEdit.id,
          name: data.name,
        },
      })
        .then(() => {
          setIsSubmitOrUpdateBtnLoading(false);
          /* close Modal */
          closeModal();
          /* reset useForm */
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Product type has been updated successfully.');
        })
        .catch((error: ApolloError) => {
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    } else {
      createProductType({
        variables: {
          name: data.name,
        },
        /* after adding a new product type, the update function is used to update the cache and display an updated product types array. */
        update(cache, { data: addedProductType }) {
          cache.modify({
            fields: {
              productTypes(existingProductTypes: Array<Reference>) {
                return [...existingProductTypes, addedProductType];
              },
            },
          });
        },
      })
        .then(() => {
          setIsSubmitOrUpdateBtnLoading(false);
          /* close Modal */
          closeModal();
          /* reset useForm */
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Product type has been created successfully.');
        })
        .catch((error: ApolloError) => {
          let errorMsg = error.message;
          if (
            error.message ===
            'Uniqueness violation. duplicate key value violates unique constraint "productTypes_name_key"'
          ) {
            errorMsg =
              'This product type already exits. Please enter another product type and try again.';
          }
          setIsSubmitOrUpdateBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorMsg);
          logger(error);
        });
    }
  });

  return (
    <form>
      <Modal
        title={mode === 'add' ? 'Create product type' : 'Edit product type'}
        visible={mode === 'add' || mode === 'edit'}
        centered
        onCancel={() => {
          /* close modal */
          closeModal();
        }}
        footer={[
          <Button
            type="primary"
            key="add"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
            loading={isSubmitOrUpdateBtnLoading}
          >
            {mode === 'add' ? ' Create product type' : 'Update'}
          </Button>,
          <Button
            type="default"
            key="cancel"
            onClick={() => {
              /* close modal */
              closeModal();
              /* reset useForm */
              reset();
            }}
          >
            Cancel
          </Button>,
        ]}
        destroyOnClose
      >
        <RequiredMessage />
        <FormItem
          label={mode === 'add' ? 'Add product type' : 'Product type'}
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          labelColSpan={8}
          inputColSpan={15}
          requiredMark="after"
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter product type. "
            name="name"
            rhfControllerProps={{
              control,
              rules: {
                required: { value: true, message: 'Please enter product type and try again.' },
              },
            }}
          />
        </FormItem>
      </Modal>
    </form>
  );
};

export default AddOrEditProductTypeForm;
