/**
 * This function generates random codes of a specified length using uppercase letters.
 * @param {number} lengthOfGenerateCodes - The `lengthOfGenerateCodes` parameter in the
 * `generateRandomCodes` function specifies the length of the random code that will be generated. This
 * parameter determines how many characters the generated code will have.
 * @returns A randomly generated code consisting of uppercase letters with a length specified by the
 * `lengthOfGenerateCodes` parameter.
 */
const generateRandomCodes = (lengthOfGenerateCodes: number) => {
  let result = '';
  /**
   * const to store characters which we want to use for generating codes
   */
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = 0; i < lengthOfGenerateCodes; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
};

export default generateRandomCodes;
