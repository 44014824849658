import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

/* React functional component */
const TransporterDriverTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();
  /* useLocation destructuring */
  const location = useLocation();

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('create')) {
      return 'createDriversScreen';
    }
    if (location.pathname.includes('edit')) {
      return 'editDriversScreen';
    }
    return 'viewAllDriversScreen';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="viewAllDriversScreen"
        onTabClick={(key: string) => {
          if (key === 'createDriversScreen') {
            navigate('create');
          } else {
            navigate('');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab="View All Drivers" key="viewAllDriversScreen">
          <Outlet />
        </TabPane>
        <TabPane tab="Create New Driver" key="createDriversScreen">
          <Outlet />
        </TabPane>
        <TabPane tab="Edit Driver" key="editDriversScreen" disabled>
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TransporterDriverTabLayout;
