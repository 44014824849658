import React, { useState } from 'react';
import { Button, Table } from 'antd';
import ColumnSearchComponent from '../../components/ColumnSearchComponent';
import { InwardShipmentsReportType } from '../../utils/types';
import { dateFormatFunc } from '../../utils/globals';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useNavigate } from 'react-router-dom';
import styles from '../reports/inwardShipments/InwardShipmentReportTable.module.scss';
import FinancialYearComponent from '../../components/FinancialYearComponent';

/* extending isBetween plugin from day js library */
dayjs.extend(isBetween);

/* inward shipment report prop type */
type InwardShipmentReportTablePropType = {
  /* prop type used to get data for the table */
  data: InwardShipmentsReportType[];
  /* prop type used to determine whether user want to make shipment for bags or other items */
  inwardShipmentType: 'bags' | 'otherItems';
  /* prop type used to determine whether screen is Daily Report or inward Report */
  reportScreenType?: 'daily' | 'inward';
};

/* react functional component  */
const InwardShipmentBagOrOtherItemReportTable = ({
  data,
  inwardShipmentType,
  reportScreenType,
}: InwardShipmentReportTablePropType): JSX.Element => {
  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* this state used to store start and end date, which is selected using date range picker or financial year radio option */
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  return (
    <div className={styles.reportTableContainer}>
      {reportScreenType === 'daily' ? null : (
        <>
          <FinancialYearComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          <></>
        </>
      )}

      <div className="reportTable">
        <Table<InwardShipmentsReportType>
          dataSource={
            selectedDate.startDate && selectedDate.endDate
              ? data.filter((shipment) => {
                  return dayjs(shipment.createdAt).isBetween(
                    selectedDate.startDate,
                    selectedDate.endDate,
                    'date',
                    '[]',
                  );
                })
              : data
          }
          className="tableStyle"
          bordered
          rowKey="id"
          size="small"
          pagination={{ showSizeChanger: true }}
        >
          <Table.Column<InwardShipmentsReportType>
            title="GRN/Date"
            dataIndex="grn"
            align="center"
            width={150}
            onFilter={(value, record) => {
              return record.grn
                ? record.grn.toLowerCase().includes(value.toString().toLowerCase())
                : false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(grnNumber, record) => {
              return (
                <>
                  {grnNumber}
                  <br />
                  {record.createdAt ? dateFormatFunc(record.createdAt) : '-'}
                </>
              );
            }}
          />
          <Table.Column<InwardShipmentsReportType>
            title="PO No."
            dataIndex="poNo"
            width={150}
            align="center"
            render={(text, record) => {
              const poNumber = record.purchaseOrder ? record.purchaseOrder.poNumber : '-';
              return poNumber;
            }}
            onFilter={(value, record) => {
              return record.purchaseOrder && record.purchaseOrder.poNumber
                ? record.purchaseOrder.poNumber
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
                : false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent filterBy="poNumber" filterDropDownProp={filterDropDownProp} />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
          />
          <Table.Column<InwardShipmentsReportType>
            title="Item name"
            dataIndex="itemName"
            width={150}
            align="center"
            render={(text, record) => {
              if (record.items && record.items.length > 0) {
                return record.items.map((recordItem) => {
                  return recordItem.item
                    ? recordItem.item.name || '-'
                    : recordItem.othersItemName || '-';
                });
              }
              return null;
            }}
          />
          <Table.Column<InwardShipmentsReportType>
            title="Delivery Transport/ Person & Mobile"
            dataIndex="driverName"
            width={200}
            align="center"
            render={(brokersName, record) => {
              return (
                <span style={{ textTransform: 'capitalize' }}>
                  {brokersName || '-'}
                  <br /> {record.deliveryPersonMobile}
                </span>
              );
            }}
          />
          <Table.Column<InwardShipmentsReportType>
            title="Vehicle Number"
            dataIndex="vehicleNumber"
            width={150}
            align="center"
            render={(vehicleNumber: string) => vehicleNumber || '-'}
          />
          <Table.Column<InwardShipmentsReportType>
            title="Vendor Name"
            dataIndex="vendorName"
            width={150}
            align="center"
            render={(text, record) => {
              if (Array.isArray(record.items) && record.items.length > 0) {
                return record.items.map((item) => {
                  return (
                    <div key={item.id} style={{ textTransform: 'capitalize' }}>
                      {(item.seller && item.seller.name) || '-'}
                    </div>
                  );
                });
              }
              return '-';
            }}
          />
          {inwardShipmentType === 'bags' ? (
            <>
              <Table.Column<InwardShipmentsReportType>
                title="Invoice"
                dataIndex="invoice"
                align="center"
                width={150}
                render={(text, record) => {
                  if (Array.isArray(record.items) && record.items.length > 0) {
                    return record.items.map((item) => {
                      return item.invoice || '-';
                    });
                  }
                  return '-';
                }}
              />
              <Table.Column<InwardShipmentsReportType>
                title="No. of Bags"
                dataIndex="noOfBags"
                align="center"
                width={150}
                render={(text, record) => {
                  if (Array.isArray(record.items) && record.items.length > 0) {
                    return record.items.map((item) => {
                      return item.bagsCount || '-';
                    });
                  }
                  return '-';
                }}
              />
            </>
          ) : null}

          {inwardShipmentType === 'otherItems' ? (
            <>
              <Table.Column<InwardShipmentsReportType>
                title="Quantity"
                dataIndex="quantity"
                width={150}
                align="center"
                render={(text, record) => {
                  if (Array.isArray(record.items) && record.items.length > 0) {
                    return record.items.map((item) => {
                      return item.othersQuantity || '-';
                    });
                  }
                  return '-';
                }}
              />
              <Table.Column<InwardShipmentsReportType>
                title="Weight (in kgs)"
                dataIndex="othersWtKg"
                width={150}
                align="center"
                render={(text, record) => {
                  if (Array.isArray(record.items) && record.items.length > 0) {
                    return record.items.map((item) => {
                      return item.othersWtKg || '-';
                    });
                  }
                  return '-';
                }}
              />
            </>
          ) : null}

          <Table.Column<InwardShipmentsReportType>
            title="Destination"
            dataIndex="destination"
            width={200}
            align="center"
            render={(text, record) => {
              if (Array.isArray(record.items) && record.items.length > 0) {
                return record.items.map((item) => {
                  if (item.destination === 'godown') {
                    return (
                      <div key={item.id} style={{ textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Godown: </span>
                        {item.godown && item.godown.name}
                      </div>
                    );
                  }
                  if (item.destination === 'mill') {
                    return (
                      <div key={item.id} style={{ textTransform: 'capitalize' }}>
                        <span style={{ fontWeight: 'bold' }}>Mill: </span>
                        {item.mill && item.mill.name}
                      </div>
                    );
                  }
                  if (item.destination === 'office' || item.destination === 'store_room') {
                    return item.destination === 'office' ? 'Office' : 'Store room';
                  }
                  return '-';
                });
              }
              return '-';
            }}
          />
          {inwardShipmentType === 'bags' ? (
            <Table.Column<InwardShipmentsReportType>
              title="Back from maintenance?"
              dataIndex="maintenance"
              align="center"
              width={150}
              render={(text, record) => {
                if (record.items && record.items.length > 0) {
                  return record.items.map((item) => {
                    if (
                      item.isBackFromMaintenance === null ||
                      item.isBackFromMaintenance === undefined
                    ) {
                      return '-';
                    }
                    return item.isBackFromMaintenance ? 'Yes' : 'No';
                  });
                }
                return '-';
              }}
            />
          ) : null}
          <Table.Column<InwardShipmentsReportType>
            title="Remark"
            dataIndex="remark"
            width={200}
            align="center"
            render={(text, record) => {
              if (Array.isArray(record.items) && record.items.length > 0) {
                return record.items.map((item) => {
                  return item.remark || '-';
                });
              }
              return '-';
            }}
          />
          {reportScreenType === 'daily' ? null : (
            <Table.Column<InwardShipmentsReportType>
              title="Actions"
              dataIndex="action"
              align="center"
              width={150}
              render={(text, record) => (
                <Button
                  type="primary"
                  onClick={() => {
                    navigate(`${record.id}/print`);
                  }}
                  style={{ fontSize: 12 }}
                >
                  Print
                </Button>
              )}
            />
          )}
        </Table>
      </div>
    </div>
  );
};

export default InwardShipmentBagOrOtherItemReportTable;
