import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { message, Button } from 'antd';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, ApolloError, Reference } from '@apollo/client';
import { loader } from 'graphql.macro';
import { logger } from '../../../utils/helpers';
import {
  UpdateProductBuyerMutation,
  UpdateProductBuyerMutationVariables,
  CreateProductBuyerMutation,
  CreateProductBuyerMutationVariables,
} from '../../../graphql/graphql-types';
import {
  statesOptionsForSelect,
  vendorOrBuyerHistoryFormItemStyleProps,
} from '../../../utils/globals';
import Select from '../../../components/Select';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import {
  inputComponentCommonStyle,
  formItemStyleProps as defaultBuyerFormItemStyleProps,
} from '../../../utils/globals';
import RequiredMessage from '../../../components/RequiredMessage';
import { AddOrEditBuyerFormType } from '../../../utils/types';

/* add or edit buyer form props type */
type AddOrEditBuyerFormPropsType = {
  /* to check whether the product buyer form is being called from  edit mode  or add mode */
  mode: 'edit' | 'add';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  buyerDataToEdit?: AddOrEditBuyerFormType;
  /* this prop type used to manage CSS & UI of the form, when it called from 'Outward -> Product -> Invoice' screen/section */
  calledFrom?: 'management' | 'outwardProductInvoice';
  /* this prop type used to close modal (because In 'Outward section' we used modal to display this form), when when this form will be called from 'Outward -> Product -> invoice' section */
  closeModal?: () => void;
  /* this prop used to determine working of form buttons and UI */
  editBuyerCalledFrom?: 'buyer' | 'buyerHistory';
};

/* loading create production buyer mutation with the help of loader */
const createProductBuyerMutation = loader(
  '../../../graphql/mutations/createProductBuyerMutation.graphql',
);

/* loading update production buyer mutation with the help of loader */
const updateProductBuyerMutation = loader(
  '../../../graphql/mutations/updateProductBuyerMutation.graphql',
);

/* loading get all buyers query with the help of loader */
const getAllBuyersQuery = loader('../../../graphql/queries/getAllProductBuyersQuery.graphql');

/* validations required for each input field */
const schema = yup.object({
  name: yup.string().required(`Please enter name and try again`),
  primaryContactEmail: yup.string().email('Please enter a valid email id').nullable(),
  primaryContactName: yup.string().required(`Please enter contact name and try again`),
  gstin: yup
    .string()
    .matches(validationRegex.gstin, {
      message: 'Please enter a valid GSTIN',
      excludeEmptyString: true,
    })
    .nullable(),
  primaryContactMobile: yup
    .string()
    .required(`Please enter mobile number and try again`)
    .matches(validationRegex.mobile, {
      message: 'Please enter a valid mobile number',
    }),
  city: yup
    .string()
    .typeError('Please enter valid city name')
    .matches(/^[aA-zZ\s]+$/, {
      message: 'Please enter a valid name with alphabets only',
      excludeEmptyString: true,
    })
    .nullable(),
  pincode: yup
    .string()
    .matches(validationRegex.pincode, {
      message: 'Please enter a valid 6-digit pincode',
      excludeEmptyString: true,
    })
    .nullable(),
});

/* function to handle error for add and edit product buyer mutation */
const errorHandlingForProductBuyerForm = (addAndEditProductBuyerError: ApolloError) => {
  if (
    addAndEditProductBuyerError.message ===
    'Uniqueness violation. duplicate key value violates unique constraint "productBuyers_gstin_key"'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error('GST number is already registered');
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error(addAndEditProductBuyerError.message);
  }
};

/* this formItem style used when 'AddOrEditBuyer' form is called from 'Outward -> Product -> invoice' section */
const outwardBuyerFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 7,
  /* input column span of FormItem */
  inputColSpan: 16,
  /* formItem label style */
  labelStyle: { fontSize: 14 } as React.CSSProperties,
  /* formItem content style */
  customStyle: { paddingTop: 15, fontSize: 14 } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* this variable used to store label for the submit button */
let submitButtonLabel: string;

/* React functional component */
const AddOrEditBuyerForm = ({
  mode,
  buyerDataToEdit = undefined,
  calledFrom = 'management',
  editBuyerCalledFrom = 'buyer',
  closeModal = () => {},
}: AddOrEditBuyerFormPropsType) => {
  /* create product buyer mutation */
  const [createProductBuyer] = useMutation<
    CreateProductBuyerMutation,
    CreateProductBuyerMutationVariables
  >(createProductBuyerMutation);

  /* update product buyer mutation */
  const [updateProductBuyer] = useMutation<
    UpdateProductBuyerMutation,
    UpdateProductBuyerMutationVariables
  >(updateProductBuyerMutation);

  /* state to set the loading condition of button */
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* this const used to store formItem style props based on 'calledFrom' prop value */
  const formItemStyleProps =
    calledFrom === 'outwardProductInvoice'
      ? outwardBuyerFormItemStyleProps
      : defaultBuyerFormItemStyleProps;

  /* if create or edit buyer form called from 'Outward -> product -> invoice' section/screen in 'add' mode, then store submit button label as 'Save' */
  if (calledFrom === 'outwardProductInvoice' && mode === 'add') {
    submitButtonLabel = 'Save';
  } else if (mode === 'add') {
    /* if create or edit buyer form called from 'Management -> Buyer' section in 'add' mode, then store submit button label as 'Create buyer' */
    submitButtonLabel = 'Create Buyer';
  } else {
    /* if create or edit buyer form called from 'Management -> Buyer' section in 'edit' mode, then store submit button label as 'Update' */
    submitButtonLabel = 'Update';
  }
  /* this const used to store formItem style props based on 'editBuyerCalledFrom' prop value */
  const formItemStyle =
    editBuyerCalledFrom === 'buyer' ? formItemStyleProps : vendorOrBuyerHistoryFormItemStyleProps;

  /* extracting required content from use form hook */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddOrEditBuyerFormType>({
    defaultValues:
      mode === 'edit' && buyerDataToEdit
        ? {
            name: buyerDataToEdit.name,
            gstin: buyerDataToEdit.gstin,
            primaryContactEmail: buyerDataToEdit.primaryContactEmail,
            primaryContactName: buyerDataToEdit.primaryContactName,
            primaryContactMobile: buyerDataToEdit.primaryContactMobile,
            address: buyerDataToEdit.address,
            state: buyerDataToEdit.state || null,
            city: buyerDataToEdit.city,
            pincode: buyerDataToEdit.pincode,
          }
        : {
            name: '',
            gstin: '',
            primaryContactEmail: '',
            primaryContactMobile: '',
            primaryContactName: '',
            address: '',
            state: null,
            city: '',
            pincode: '',
          },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* this function used to navigate or close modal, when form is in 'edit' mode */
  const navigateOrCloseModal = () => {
    /* if this form is called from buyer screen (management section) then navigate */
    if (editBuyerCalledFrom === 'buyer') {
      navigate('/management/buyers');
    } else {
      /* if this form is called from buyer history (report section) then close modal */
      closeModal();
    }
  };

  /* function to handle submitted data */
  const onSubmit = (data: AddOrEditBuyerFormType) => {
    setBtnLoader(true);

    /* this variable is used to store address , which is then used to manage profile status of buyer from address fields  */
    let buyerAddress;
    if (data.address || data.city || data.pincode || data.state) {
      buyerAddress = {
        address: data.address || null,
        state: data.state || null,
        city: data.city || null,
        pincode: data.pincode || null,
      };
    } else {
      buyerAddress = null;
    }

    /* const used to store mutation variables which are common in both updateProductBuyer and createProductBuyer mutation */
    const commonMutationVariables = {
      name: data.name,
      gstin: data.gstin || null,
      address: buyerAddress,
      primaryContactEmail: data.primaryContactEmail || null,
    };

    if (mode === 'edit' && buyerDataToEdit) {
      updateProductBuyer({
        variables: {
          id: buyerDataToEdit.id,
          ...commonMutationVariables,
          primaryContactName: data.primaryContactName as string,
          primaryContactMobile: data.primaryContactMobile as string,
        },
        update(cache, { data: updatedData }) {
          /* data to update */
          const dataToUpdate = updatedData?.updateProductBuyer;
          cache.modify({
            fields: {
              productBuyers(existingBuyers: Array<Reference>, { readField }) {
                if (dataToUpdate) {
                  return existingBuyers.map((buyerData) => {
                    if (dataToUpdate.id === readField('id', buyerData)) {
                      return dataToUpdate;
                    }
                    return buyerData;
                  });
                }
                return existingBuyers;
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Buyer has been updated successfully.');
          /* if this form is called from buyer screen (management section) then navigate otherwise close modal */
          navigateOrCloseModal();
          reset();
          setBtnLoader(false);
        })
        .catch((error: ApolloError) => {
          errorHandlingForProductBuyerForm(error);
          logger(error);
          setBtnLoader(false);
        });
    } else {
      createProductBuyer({
        variables: {
          ...commonMutationVariables,
          primaryContactMobile: data.primaryContactMobile,
          primaryContactName: data.primaryContactName,
        },
        /* refetching get all product's buyers query,so that we can get updated buyers list after adding new buyer */
        refetchQueries: [{ query: getAllBuyersQuery }],
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Buyer has been added successfully.');

          /* if this form is called from 'management -> Buyer section' then navigate to view all buyer screen.
          other wise close modal */
          if (calledFrom === 'management') {
            /* navigate to view all buyer screen */
            navigate('/management/buyers');
          } else {
            /* if this form is called from 'Outward -> Product -> invoice' section then close modal */
            closeModal();
          }

          /* reset form */
          reset();
          setBtnLoader(false);
        })
        .catch((error: ApolloError) => {
          errorHandlingForProductBuyerForm(error);
          setBtnLoader(false);
          logger(error);
        });
    }
  };

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormItem
          label="Name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          requiredMark="after"
          {...formItemStyle}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter name of buyer"
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="GST"
          errorText={errors && errors.gstin ? errors.gstin.message : undefined}
          {...formItemStyle}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter GST of buyer"
            name="gstin"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Contact Name"
          isRequired
          requiredMark="after"
          {...formItemStyle}
          errorText={
            errors && errors.primaryContactName ? errors.primaryContactName.message : undefined
          }
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter contact name for buyer"
            name="primaryContactName"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Contact Mobile"
          isRequired
          requiredMark="after"
          {...formItemStyle}
          errorText={
            errors && errors.primaryContactMobile ? errors.primaryContactMobile.message : undefined
          }
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter contact mobile number"
            name="primaryContactMobile"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>

        <div
          style={
            calledFrom === 'outwardProductInvoice' ? { display: 'none' } : { display: 'block' }
          }
        >
          <FormItem
            label="Contact Email"
            {...formItemStyle}
            errorText={
              errors && errors.primaryContactEmail ? errors.primaryContactEmail.message : undefined
            }
          >
            <Input
              customStyles={inputComponentCommonStyle}
              placeholder="Please enter contact email ID"
              name="primaryContactEmail"
              rhfControllerProps={{
                control,
              }}
            />
          </FormItem>
          <FormItem
            label="Address"
            errorText={errors && errors.address ? errors.address.message : undefined}
            {...formItemStyle}
          >
            <Input
              name="address"
              isTextAreaInput={true}
              placeholder="Please enter address of buyer"
              rhfControllerProps={{
                control,
              }}
              customStyles={inputComponentCommonStyle}
              textAreaProps={{ rows: 4 }}
            />
          </FormItem>
          <FormItem
            label="City"
            errorText={errors && errors.city ? errors.city.message : undefined}
            {...formItemStyle}
          >
            <Input
              customStyles={inputComponentCommonStyle}
              placeholder="Please enter city of buyer"
              name="city"
              rhfControllerProps={{
                control,
              }}
            />
          </FormItem>
          <FormItem
            label="State"
            errorText={errors && errors.state ? errors.state.message : undefined}
            {...formItemStyle}
          >
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select state of buyer"
              name="state"
              rhfControllerProps={{
                control,
              }}
              selectProps={{ showSearch: true }}
              options={statesOptionsForSelect}
            />
          </FormItem>
          <FormItem
            label="Pincode"
            errorText={errors && errors.pincode ? errors.pincode.message : undefined}
            {...formItemStyle}
          >
            <Input
              customStyles={inputComponentCommonStyle}
              placeholder="Please enter pincode of buyer"
              name="pincode"
              rhfControllerProps={{
                control,
              }}
            />
          </FormItem>
        </div>

        <FormItem {...formItemStyle} customStyle={{ marginBottom: 20 }}>
          <>
            <div
              style={
                calledFrom === 'outwardProductInvoice' || editBuyerCalledFrom === 'buyerHistory'
                  ? { display: 'flex', justifyContent: 'end' }
                  : { display: 'block' }
              }
            >
              <Button
                htmlType="submit"
                type="primary"
                loading={btnLoader}
                style={{ marginRight: 10 }}
              >
                {submitButtonLabel}
              </Button>
              <Button
                type="default"
                onClick={() => {
                  navigateOrCloseModal();
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        </FormItem>
      </form>
    </>
  );
};

export default AddOrEditBuyerForm;
