import React from 'react';
import { Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  DailyInternalTransferShipmentsQuery,
  DailyInternalTransferShipmentsQueryVariables,
} from '../../../graphql/graphql-types';
import { dateFormatFunc } from '../../../utils/globals';
import { DailyReportsInternalShipmentsTableType } from '../../../utils/types';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';

/* loading daily internal transfer shipment query */
const dailyInternalTransferShipmentsQuery = loader(
  '../../../graphql/queries/dailyInternalTransferShipmentsQuery.graphql',
);

/*  type for select date range in daily reports of internal shipment screen */
export type DailyReportsInternalShipmentsPropType = {
  /* prop type used to store  start date range */
  startDate: string;
  /* prop type used to store end date range */
  endDate: string;
};

/* React functional component */
const DailyReportsInternalShipmentsScreen = ({
  startDate,
  endDate,
}: DailyReportsInternalShipmentsPropType) => {
  /* get internal  transfer shipment using query with start and end date from date range field */
  const {
    data: dailyInternalTransferShipmentsData,
    loading: dailyInternalTransferShipmentsLoading,
    error: dailyInternalTransferShipmentsError,
  } = useQuery<DailyInternalTransferShipmentsQuery, DailyInternalTransferShipmentsQueryVariables>(
    dailyInternalTransferShipmentsQuery,
    {
      variables: {
        createdAt:
          startDate && endDate
            ? {
                _gte: startDate,
                _lte: endDate,
              }
            : {},
      },
    },
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (dailyInternalTransferShipmentsError) {
    return <div className="errorText">{dailyInternalTransferShipmentsError.message}</div>;
  }

  return (
    <div className="reportTableContainer">
      <div className="reportTable">
        <Table<DailyReportsInternalShipmentsTableType>
          dataSource={
            dailyInternalTransferShipmentsData
              ? dailyInternalTransferShipmentsData.internalTransferShipments
              : []
          }
          loading={dailyInternalTransferShipmentsLoading}
          className="tableStyle"
          bordered
          size="small"
          pagination={{ showSizeChanger: true }}
        >
          <Table.Column<DailyReportsInternalShipmentsTableType>
            title="GRN"
            dataIndex="grn"
            align="center"
            width={150}
            onFilter={(value, record) => {
              return record.grn
                ? record.grn.toLowerCase().includes(value.toString().toLowerCase())
                : false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(text, record) => (record.grn ? record.grn : '-')}
          />
          <Table.Column<DailyReportsInternalShipmentsTableType>
            title="Created At"
            dataIndex="created_at"
            align="center"
            width={150}
            render={(text, record) => (record.created_at ? dateFormatFunc(record.created_at) : '-')}
          />
          <Table.Column<DailyReportsInternalShipmentsTableType>
            title="Material Type"
            align="center"
            dataIndex="materialType"
            width={140}
            render={(text, record) => {
              return (
                <span style={{ textTransform: 'capitalize' }}>
                  {record.materialTypeValue ? record.materialTypeValue.display_name : '-'}
                </span>
              );
            }}
          />
          <Table.Column<DailyReportsInternalShipmentsTableType>
            title="Delivery Person Name"
            dataIndex="personName"
            align="center"
            render={(text, record) => {
              return (
                <span style={{ textTransform: 'capitalize' }}>
                  {record.personName ? record.personName : '-'}
                </span>
              );
            }}
            width={200}
          />
          <Table.Column<DailyReportsInternalShipmentsTableType>
            title="Delivery Person Mobile"
            dataIndex="personMobile"
            align="center"
            width={160}
            render={(text, record) => {
              return (
                <span style={{ textTransform: 'capitalize' }}>
                  {record.personMobile ? record.personMobile : '-'}
                </span>
              );
            }}
          />
          <Table.Column<DailyReportsInternalShipmentsTableType>
            title="Vehicle Number"
            dataIndex="vehicleNumber"
            width={130}
            align="center"
            render={(text, record) => (record.vehicleNumber ? record.vehicleNumber : '-')}
          />
          <Table.Column<DailyReportsInternalShipmentsTableType>
            title="Remark"
            dataIndex="remark"
            align="center"
            render={(text, record) => {
              if (
                Array.isArray(record.internalTransferShipmentItems) &&
                record.internalTransferShipmentItems.length > 0
              ) {
                return record.internalTransferShipmentItems.map((item) => {
                  return item.remark || '-';
                });
              }
              return '-';
            }}
          />
        </Table>
      </div>
    </div>
  );
};

export default DailyReportsInternalShipmentsScreen;
