import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

/* React functional component */
const VendorsTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();
  /* useLocation destructuring */
  const location = useLocation();

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('create')) {
      return 'createNewVendor';
    }
    if (location.pathname.includes('edit')) {
      return 'editVendor';
    }
    return 'viewAllVendors';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="viewAllVendors"
        onTabClick={(key: string) => {
          if (key === 'createNewVendor') {
            navigate('create');
          } else {
            navigate('');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab=" View All Vendors" key="viewAllVendors">
          <Outlet />
        </TabPane>
        <TabPane tab="Create New Vendor" key="createNewVendor">
          <Outlet />
        </TabPane>
        <TabPane tab="Edit Vendor" key="editVendor" disabled>
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default VendorsTabLayout;
