import React from 'react';

/* type definition for difference in weight calculation */
type DifferenceInWeightPropType = {
  /* prop type for full vehicle weight in kg */
  fullVehicleWtKg: number;
  /* prop type for empty vehicle weight in kg */
  emptyVehicleWtKg: number;
  /* prop type for net weight in quintal */
  netWeightInQuintals: number;
};

/**
 * The function calculates the difference in weight in quintals between a full vehicle weight, empty
 * vehicle weight, and net weight.This calculated difference is used to calculate 'net weight'in 'ProcessGRN' and 'EditCompletedGRN'
 */
const differenceInWeightCalculation = ({
  fullVehicleWtKg,
  emptyVehicleWtKg,
  netWeightInQuintals,
}: DifferenceInWeightPropType) => {
  /** const to calculate mill weight which includes in the vehicle  */
  const millVehicleWeightInQuintal = (fullVehicleWtKg - emptyVehicleWtKg) / 100;

  /** const to store weight difference in quintals */
  const weightDifferenceInQuintal = millVehicleWeightInQuintal - netWeightInQuintals;

  return weightDifferenceInQuintal.toFixed(2);
};

export default differenceInWeightCalculation;
