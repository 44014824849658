import React, { useState } from 'react';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import {
  CreateBagsOthersInwardShipmentMutation,
  CreateBagsOthersInwardShipmentMutationVariables,
  CreateBagsOthersOutwardShipmentMutation,
  CreateBagsOthersOutwardShipmentMutationVariables,
  CustomItemListQuery,
  CustomItemListQueryVariables,
  CustomVendorListQuery,
  CustomVendorListQueryVariables,
  GetAllGodownsMillsQuery,
  GetAllGodownsMillsQueryVariables,
  InwardShipments,
  InwardShipmentItems,
  Enum_Item_Category_Enum,
  Enum_Source_Destination_Enum,
  Enum_Inward_Shipment_Status_Enum,
  Enum_InwardShipmentItem_Source_Enum,
  Enum_OutwardShipmentOrder_Source_Enum,
  GetAllPurchaseOrderQuery,
  GetAllPurchaseOrderQueryVariables,
} from '../graphql/graphql-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { logger } from '../utils/helpers';
import FormItem from './FormItem';
import Input from './Input';
import { inputComponentCommonStyle } from '../utils/globals';
import RequiredMessage from './RequiredMessage';
import Select from './Select';
import InputNumber from './InputNumber';
import RadioGroup from './RadioGroup';
import QuickAddVendorOrBuyerForm from './QuickAddVendorOrBuyerForm';
import { useLocation } from 'react-router-dom';

/* CreateBagInwardShipment form type */
type CreateBagInwardShipmentFormType = Pick<
  InwardShipments,
  'vehicleNumber' | 'driverName' | 'deliveryPersonMobile'
> &
  Pick<
    InwardShipmentItems,
    | 'itemId'
    | 'rmSellerId'
    | 'bagsCount'
    | 'godownId'
    | 'millId'
    | 'invoice'
    | 'isBackFromMaintenance'
    | 'remark'
  > & {
    /* destination or source  */
    destinationOrSource: Enum_Source_Destination_Enum | Enum_OutwardShipmentOrder_Source_Enum;
    /* selected PO id*/
    selectedPOId?: number | null;
  };

/* CreateBagInwardShipment Form component props type */
type CreateBagInwardShipmentFormPropsType = {
  /* prop type used to determine, whether user want to create inward shipment for 'product bag' or 'paddy bag' */
  bagType: Enum_Item_Category_Enum;
  /* this prop is used to know from which screen the component is called inward or outward */
  shipmentType: 'inward' | 'outward';
};

/* form validation schema */
const schema = yup.object({
  driverName: yup.string().required('Please enter delivery transport/person name and try again.'),
  deliveryPersonMobile: yup
    .string()
    .matches(validationRegex.mobile, {
      message: 'Please enter a valid mobile number',
      excludeEmptyString: true,
    })
    .nullable(),
  vehicleNumber: yup.string().matches(validationRegex.vehicleRegistrationNumber, {
    message: 'Please enter a valid vehicle number',
    excludeEmptyString: true,
  }),
  itemId: yup.string().required('Please select item and try again.').nullable(),
  rmSellerId: yup
    .number()
    .nullable()
    .when('$shipmentType', {
      is: (shipmentType: string) => shipmentType === 'inward',
      then: yup.number().nullable().required('Please select vendor and try again.'),
    }),
  destination: yup.string().nullable(),
  bagsCount: yup
    .number()
    .required('Please enter number of bags and try again.')
    .integer('Please enter valid number.')
    .min(0, 'Please enter valid number')
    .nullable(),
  godownId: yup
    .number()
    .nullable()
    .when(['destinationOrSource'], {
      is: (destinationOrSource: string) => destinationOrSource === 'godown',
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
  millId: yup
    .number()
    .nullable()
    .when(['destinationOrSource'], {
      is: (destinationOrSource: string) => destinationOrSource === 'mill',
      then: yup.number().nullable().required('Please select mill and try again.'),
    }),
  isBackFromMaintenance: yup
    .boolean()
    .nullable()
    .when('$bagType', {
      is: (bagType: string) => bagType === 'paddy_bag',
      then: yup.boolean().nullable().required('Please select maintenance and try again.'),
    }),
});

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* loading create paddy or product bag inward shipment mutation  */
const createBagInwardShipmentMutation = loader(
  '../graphql/mutations/createBagsOthersInwardShipmentMutation.graphql',
);

/* loading create paddy bag outward shipment mutation  */
const createBagOutwardShipmentMutation = loader(
  '../graphql/mutations/createBagsOthersOutwardShipmentMutation.graphql',
);

/* loading get all godowns and mills query */
const getGodownAndMillsQuery = loader('../graphql/queries/getAllGodownsMillsQuery.graphql');

/* loading get item list query */
const getItemListQuery = loader('../graphql/queries/customItemListQuery.graphql');

/* loading get Vendor list query */
const getVendorListQuery = loader('../graphql/queries/customVendorListQuery.graphql');

/* loading get all purchase order query */
const getAllPurchaseOrderQuery = loader('../graphql/queries/getAllPurchaseOrderQuery.graphql');

const CreateBagInwardOrOutwardShipmentForm = ({
  bagType,
  shipmentType,
}: CreateBagInwardShipmentFormPropsType) => {
  /* this state used to show loading indicator on submit button */
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /* this state used to manage Add vendor form modal visibility */
  const [isAddVendorModalVisible, setIsAddVendorModalVisible] = useState<boolean>(false);

  const location = useLocation();

  /** function to get category according to current path  */
  const itemCategory = (locationPathname: string) => {
    if (locationPathname.includes('paddy')) {
      return Enum_Item_Category_Enum.PaddyBag;
    } else if (locationPathname.includes('product')) {
      return Enum_Item_Category_Enum.ProductBag;
    } else {
      return Enum_Item_Category_Enum.Others;
    }
  };

  /* create product or paddy bag inward shipment mutation */
  const [createInwardShipment] = useMutation<
    CreateBagsOthersInwardShipmentMutation,
    CreateBagsOthersInwardShipmentMutationVariables
  >(createBagInwardShipmentMutation);

  /* create paddy bag outward shipment mutation */
  const [createOutwardShipment] = useMutation<
    CreateBagsOthersOutwardShipmentMutation,
    CreateBagsOthersOutwardShipmentMutationVariables
  >(createBagOutwardShipmentMutation);

  /* get all godown and mills data query used to show list of godowns and mills as options on 'Select godown' & 'select mill' fields */
  const {
    data: getGodownAndMillsData,
    loading: getGodownAndMillsDataLoading,
    error: getGodownAndMillsDataError,
  } = useQuery<GetAllGodownsMillsQuery, GetAllGodownsMillsQueryVariables>(getGodownAndMillsQuery);

  /* get custom item list query used to show list of items as options on 'Select item' field */
  const {
    data: getItemListData,
    loading: getItemListDataLoading,
    error: getItemListDataError,
  } = useQuery<CustomItemListQuery, CustomItemListQueryVariables>(getItemListQuery, {
    variables: { _category: bagType },
    fetchPolicy: 'network-only',
  });

  /* get all vendor list query used to show list of vendors options on 'Select vendor' field */
  const {
    data: getVendorData,
    loading: getVendorDataLoading,
    error: getVendorDataError,
  } = useQuery<CustomVendorListQuery, CustomVendorListQueryVariables>(getVendorListQuery, {
    variables: {
      _item_category: shipmentType === 'inward' ? bagType : Enum_Item_Category_Enum.RawMaterial,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: getAllPurchaseOrderData,
    loading: getAllPurchaseOrderLoading,
    error: getAllPurchaseOrderError,
  } = useQuery<GetAllPurchaseOrderQuery, GetAllPurchaseOrderQueryVariables>(
    getAllPurchaseOrderQuery,
    {
      variables: {
        item_category: itemCategory(location.pathname),
      },
      fetchPolicy: 'network-only',
    },
  );

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CreateBagInwardShipmentFormType>({
    resolver: yupResolver(schema),
    /* yup context used to validate maintenance form field, based on value of bagType and shipmentType prop */
    context: { bagType: bagType, shipmentType: shipmentType },
    defaultValues: {
      driverName: '',
      deliveryPersonMobile: '',
      vehicleNumber: '',
      itemId: null,
      rmSellerId: null,
      bagsCount: null,
      destinationOrSource:
        Enum_Source_Destination_Enum.Godown || Enum_OutwardShipmentOrder_Source_Enum.Godown,
      godownId: null,
      millId: null,
      invoice: '',
      isBackFromMaintenance: null,
      remark: '',
      selectedPOId: null,
    },
    mode: 'onChange',
  });

  /** const to store list of all items which is used in 'select Item' field */
  const allItemsDetails =
    getItemListData &&
    getItemListData.items &&
    Array.isArray(getItemListData.items) &&
    getItemListData.items.length > 0
      ? getItemListData.items
      : [];

  /** const to store all vendor details  which is used in 'select vendor' field */
  const allVendorDetails =
    getVendorData &&
    getVendorData.rmSellers &&
    Array.isArray(getVendorData.rmSellers) &&
    getVendorData.rmSellers.length > 0
      ? getVendorData.rmSellers
      : [];

  /** const to store all PO details which are not mark as completed and then used in 'select PO' field */
  const allPODetails =
    getAllPurchaseOrderData &&
    getAllPurchaseOrderData.purchaseOrders &&
    Array.isArray(getAllPurchaseOrderData.purchaseOrders) &&
    getAllPurchaseOrderData.purchaseOrders.length > 0
      ? getAllPurchaseOrderData.purchaseOrders.filter((item) => !item.delivery_completed_at)
      : [];

  const poId = watch('selectedPOId');

  /** const to check 'PO' is selected or not  */
  const isPOSelected = poId ? true : false;

  /** const to store item list based on selected PO */
  const itemListOfSelectedPO =
    isPOSelected && allPODetails && allPODetails.length > 0
      ? allPODetails.filter((item) => item.id === poId)
      : [];

  /* show error text on the screen. if it has any error while loading data from the server */
  if (
    getItemListDataError ||
    getVendorDataError ||
    getGodownAndMillsDataError ||
    getAllPurchaseOrderError
  ) {
    let errorMessage;
    if (getItemListDataError) {
      errorMessage = getItemListDataError.message;
    } else if (getGodownAndMillsDataError) {
      errorMessage = getGodownAndMillsDataError.message;
    } else if (getVendorDataError) {
      errorMessage = getVendorDataError.message;
    } else if (getAllPurchaseOrderError) {
      errorMessage = getAllPurchaseOrderError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* function to handle submitted data */
  const onSubmit = (data: CreateBagInwardShipmentFormType) => {
    setIsSubmitBtnLoading(true);

    /* destructing form data values */
    const {
      vehicleNumber,
      driverName,
      deliveryPersonMobile,
      godownId,
      millId,
      destinationOrSource,
      bagsCount,
      isBackFromMaintenance,
      rmSellerId,
      itemId,
      invoice,
      remark,
      selectedPOId,
    } = data;

    if (shipmentType === 'inward') {
      // call to create Inward Shipment Mutation
      createInwardShipment({
        variables: {
          object: {
            vehicleNumber: vehicleNumber ? vehicleNumber.toUpperCase() : null,
            driverName: driverName || null,
            deliveryPersonMobile: deliveryPersonMobile || null,
            status: Enum_Inward_Shipment_Status_Enum.Completed,
            completedAt: new Date(),
            po_id: selectedPOId,
            items: {
              data: [
                {
                  godownId: godownId || null,
                  millId: millId || null,
                  destination: (destinationOrSource as Enum_Source_Destination_Enum) || null,
                  bagsCount: bagsCount || null,
                  isBackFromMaintenance: isBackFromMaintenance,
                  rmSellerId: rmSellerId || null,
                  itemId: itemId || null,
                  source: Enum_InwardShipmentItem_Source_Enum.Vendor,
                  invoice: invoice || null,
                  remark: remark || null,
                },
              ],
            },
          },
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been successfully created.');
          reset();
          setIsSubmitBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          let errorMessage = error.message;
          if (error.message === 'database query error') {
            errorMessage = 'Vehicle is already in use!';
          }
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorMessage);
          setIsSubmitBtnLoading(false);
          logger(error);
        });
    } else {
      // when shipment type is outward below code will execute
      createOutwardShipment({
        variables: {
          object: {
            nonProductPersonMobile: deliveryPersonMobile || null,
            nonProductPersonName: driverName || null,
            nonProductVehicleNumber: vehicleNumber ? vehicleNumber.toUpperCase() : null,
            invoiceAddedAt: invoice ? new Date() : null,
            truckOutAt: new Date(),
            orders: {
              data: [
                {
                  godownId: godownId || null,
                  millId: millId || null,
                  invoiceNo: invoice || null,
                  itemId: itemId || null,
                  remark: remark || null,
                  loadedBagsCount: bagsCount || null,
                  isOutForMaintenance: isBackFromMaintenance,
                  paddyBagBuyerId: rmSellerId || null,
                  source: (destinationOrSource as Enum_OutwardShipmentOrder_Source_Enum) || null,
                },
              ],
            },
          },
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been successfully created.');
          reset();
          setIsSubmitBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          let errorMessage = error.message;
          if (error.message === 'database query error') {
            errorMessage = 'Vehicle is already in use!';
          }
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorMessage);
          setIsSubmitBtnLoading(false);
          logger(error);
        });
    }
  };

  /* function used to close the visibility of modal by setting state value to false */
  const closeAddVendorForm = () => {
    setIsAddVendorModalVisible(false);
  };

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        {shipmentType === 'inward' ? (
          <FormItem label="Select PO" {...formItemStyleProps}>
            <Select
              name="selectedPOId"
              placeholder="Select PO"
              selectProps={{
                loading: getAllPurchaseOrderLoading,
                showSearch: true,
                optionFilterProp: 'children',
                disabled: !!watch('itemId'),
              }}
              customStyles={inputComponentCommonStyle}
              rhfControllerProps={{ control }}
              options={
                allPODetails
                  ? allPODetails.map((item) => ({
                      value: item.id,
                      label: item.poNumber as string,
                    }))
                  : []
              }
            />
          </FormItem>
        ) : null}

        <FormItem
          label="Vehicle Number"
          errorText={errors && errors.vehicleNumber ? errors.vehicleNumber.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter vehicle number"
            name="vehicleNumber"
            rhfControllerProps={{
              control,
            }}
            onChange={(rhfOnChange, value) => {
              rhfOnChange(value.toUpperCase());
            }}
          />
        </FormItem>
        <FormItem
          label="Delivery Transport/Person"
          isRequired
          errorText={errors && errors.driverName ? errors.driverName.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter the name of delivery person/transport"
            name="driverName"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Delivery Person Mobile"
          errorText={
            errors && errors.deliveryPersonMobile ? errors.deliveryPersonMobile.message : undefined
          }
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter mobile number of delivery person"
            name="deliveryPersonMobile"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>

        <FormItem
          label="Select Item"
          isRequired
          errorText={errors && errors.itemId ? errors.itemId.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select item"
            name="itemId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              loading: getItemListDataLoading,
              optionFilterProp: 'children',
              allowClear: true,
            }}
            options={
              isPOSelected && itemListOfSelectedPO && itemListOfSelectedPO.length > 0
                ? itemListOfSelectedPO[0].purchaseOrderItems.map((poData) => ({
                    value: poData.item.id,
                    label: poData.item.name,
                  }))
                : allItemsDetails &&
                  allItemsDetails.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
            }
          />
        </FormItem>
        <FormItem
          label={shipmentType === 'inward' ? 'Select Vendor' : 'Select Buyer'}
          isRequired={shipmentType === 'inward' ? true : false}
          errorText={errors && errors.rmSellerId ? errors.rmSellerId.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder={shipmentType === 'inward' ? 'Please select vendor' : 'Please select buyer'}
            name="rmSellerId"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              loading: getVendorDataLoading,
              optionFilterProp: 'children',
              dropdownRender: (menu) => {
                return (
                  <>
                    {menu}
                    <Divider style={{ marginTop: 5, marginBottom: 0 }} />
                    <Button
                      className="selectDropDownButton"
                      onClick={() => {
                        setIsAddVendorModalVisible(true);
                      }}
                      icon={<PlusOutlined />}
                    >
                      {shipmentType === 'inward' ? 'Add vendor' : 'Add Buyer'}
                    </Button>
                  </>
                );
              },
            }}
            options={
              isPOSelected && itemListOfSelectedPO && itemListOfSelectedPO.length > 0
                ? [
                    {
                      value: itemListOfSelectedPO[0].purchaseOrderItems[0].vendor.id,
                      label: itemListOfSelectedPO[0].purchaseOrderItems[0].vendor.name,
                    },
                  ]
                : allVendorDetails &&
                  allVendorDetails.map((item) => ({
                    value: item.id,
                    label: item.name,
                  }))
            }
          />
        </FormItem>
        <FormItem
          label="Number of bags"
          isRequired
          errorText={errors && errors.bagsCount ? errors.bagsCount.message : undefined}
          {...formItemStyleProps}
        >
          <InputNumber
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter number of bags"
            name="bagsCount"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        <FormItem
          label={shipmentType === 'inward' ? 'Destination' : 'Source'}
          isRequired
          errorText={
            errors && errors.destinationOrSource ? errors.destinationOrSource.message : undefined
          }
          {...formItemStyleProps}
        >
          <RadioGroup
            name="destinationOrSource"
            rhfControllerProps={{
              control,
            }}
            defaultValue={'godown'}
            options={[
              { label: 'Godown', value: 'godown' },
              {
                label: 'Mill',
                value: 'mill',
              },
            ]}
            onChange={(e) => {
              /* on selection of 'destination', set 'godown id ' or 'mill id ' as a null */
              if (e.target.value === 'mill') {
                setValue('godownId', null);
              } else {
                setValue('millId', null);
              }
            }}
          />
        </FormItem>

        {watch('destinationOrSource') === 'mill' ? (
          <FormItem
            label="Select Mill"
            isRequired
            errorText={errors && errors.millId ? errors.millId.message : undefined}
            {...formItemStyleProps}
          >
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select mill"
              name="millId"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                loading: getGodownAndMillsDataLoading,
                optionFilterProp: 'children',
              }}
              options={
                getGodownAndMillsData
                  ? getGodownAndMillsData.getAllMills.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []
              }
            />
          </FormItem>
        ) : (
          <FormItem
            label="Select Godown"
            isRequired
            errorText={errors && errors.godownId ? errors.godownId.message : undefined}
            {...formItemStyleProps}
          >
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select godown"
              name="godownId"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                loading: getGodownAndMillsDataLoading,
                optionFilterProp: 'children',
              }}
              options={
                getGodownAndMillsData
                  ? getGodownAndMillsData.getAllGodowns.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []
              }
            />
          </FormItem>
        )}
        {bagType === Enum_Item_Category_Enum.PaddyBag ? (
          <FormItem
            label={
              shipmentType === 'inward'
                ? 'Are bags back from maintenance (Rafoo)?'
                : 'Are bags going for maintenance (Rafoo)?'
            }
            isRequired
            errorText={
              errors && errors.isBackFromMaintenance
                ? errors.isBackFromMaintenance.message
                : undefined
            }
            {...formItemStyleProps}
          >
            <RadioGroup
              name="isBackFromMaintenance"
              rhfControllerProps={{
                control,
              }}
              options={[
                { label: 'Yes', value: true },
                {
                  label: 'No',
                  value: false,
                },
              ]}
            />
          </FormItem>
        ) : null}
        {shipmentType === 'inward' ||
        (shipmentType === 'outward' && watch('isBackFromMaintenance') === false) ? (
          <FormItem
            label={shipmentType === 'inward' ? 'Invoice' : 'Invoice No.'}
            errorText={errors && errors.invoice ? errors.invoice.message : undefined}
            {...formItemStyleProps}
          >
            <Input
              customStyles={inputComponentCommonStyle}
              placeholder="Please enter invoice no."
              name="invoice"
              rhfControllerProps={{
                control,
              }}
            />
          </FormItem>
        ) : null}
        <FormItem
          label="Remark"
          errorText={errors && errors.remark ? errors.remark.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            name="remark"
            isTextAreaInput={true}
            placeholder="Please enter remark"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
            textAreaProps={{ rows: 2 }}
          />
        </FormItem>
        <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
          <>
            <Button
              htmlType="submit"
              type="primary"
              loading={isSubmitBtnLoading}
              style={{ marginRight: 10 }}
            >
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                reset();
              }}
            >
              Cancel
            </Button>
          </>
        </FormItem>
      </form>
      {isAddVendorModalVisible ? (
        <QuickAddVendorOrBuyerForm
          isModalOpen={isAddVendorModalVisible}
          closeModal={closeAddVendorForm}
          bagType={bagType}
          shipmentType={shipmentType}
        />
      ) : null}
    </>
  );
};

export default CreateBagInwardOrOutwardShipmentForm;
