import { ApolloError, useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  GetPoDeliveryDataQuery,
  GetPoDeliveryDataQueryVariables,
  MarkDeliveryCompletedForPoMutation,
  MarkDeliveryCompletedForPoMutationVariables,
} from '../../graphql/graphql-types';
import getItemCategoryData from '../../utils/helpers/getItemCategoryData';
import { loader } from 'graphql.macro';
import { Button, Descriptions, Table, message } from 'antd';
import { logger } from '../../utils/helpers';
import dayjs from 'dayjs';

const getPODeliveryDataQuery = loader('../../graphql/queries/getPODeliveryDataQuery.graphql');

const markDeliveryCompletedForPOMutation = loader(
  '../../graphql/mutations/markDeliveryCompletedForPOMutation.graphql',
);

/* type for purchase order deliver table */
type PurchaseOrderDeliverTableType = {
  item: {
    id: string | null;
    name: string | null;
  };
  quantity: number | null;
  orderedItemsCount: number | null;
};

const PurchaseOrderDeliverScreen = () => {
  /* useNavigate destructuring */
  const navigate = useNavigate();

  /* useLocation destructuring */
  const location = useLocation();

  // extracting id from params
  const { id } = useParams();

  const orderId = Number(id);

  const [isCompletedBtnLoading, setIsCompletedBtnLoading] = useState<boolean>(false);

  /** calling `getItemCategoryData` function to get itemCategory data  */
  const itemCategory = getItemCategoryData({
    locationPathname: location.pathname,
  });

  const isPathIncludesOtherItems = itemCategory.key === 'otherItems';

  /** fetching `GetPoDeliveryDataQuery` for selected PO details */
  const {
    data: GetPoDeliveryDetails,
    loading: GetPoDeliveryDetailsLoading,
    error: GetPoDeliveryDetailsError,
  } = useQuery<GetPoDeliveryDataQuery, GetPoDeliveryDataQueryVariables>(getPODeliveryDataQuery, {
    variables: {
      id: orderId,
    },
    fetchPolicy: 'network-only',
  });

  /** const to store po delivery details  */
  const poDeliveryDetails = GetPoDeliveryDetails && GetPoDeliveryDetails.purchaseOrders_by_pk;

  /** Mutation to mark delivery as completed/ */
  const [markDeliveryCompletedForPO] = useMutation<
    MarkDeliveryCompletedForPoMutation,
    MarkDeliveryCompletedForPoMutationVariables
  >(markDeliveryCompletedForPOMutation);

  /** const to store PO items details which are selected during creation of PO */
  const purchaseOrderItemsData =
    poDeliveryDetails &&
    Array.isArray(poDeliveryDetails.purchaseOrderItems) &&
    poDeliveryDetails.purchaseOrderItems.length > 0
      ? poDeliveryDetails.purchaseOrderItems
      : [];

  /** const to store items details which are selected during creation of inward shipment */
  const inwardShipmentItemsArray =
    poDeliveryDetails &&
    poDeliveryDetails.inwardShipments &&
    Array.isArray(poDeliveryDetails.inwardShipments) &&
    poDeliveryDetails.inwardShipments.length > 0
      ? poDeliveryDetails.inwardShipments
      : [];

  /** const to store items which are included in inward shipment */
  const inwardShipmentItems = inwardShipmentItemsArray.map(
    (inwardShipment) => inwardShipment.items,
  );

  /** const to check PO is already mark as completed or not which is use to hide 'Completed' button */
  const isPoMarkAsCompleted =
    poDeliveryDetails && poDeliveryDetails.delivery_completed_at ? true : false;

  /** const to store vendor data */
  const vendorData = poDeliveryDetails && poDeliveryDetails.purchaseOrderItems[0].vendor;

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (GetPoDeliveryDetailsError) {
    return <div className="errorText">{GetPoDeliveryDetailsError.message}</div>;
  }

  return (
    <>
      <Descriptions column={1} labelStyle={{ width: '250px' }}>
        <Descriptions.Item label="Material">{itemCategory.materialName}</Descriptions.Item>
        <Descriptions.Item label="PO No.">
          {poDeliveryDetails ? poDeliveryDetails.poNumber : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Vendor">
          {(vendorData && vendorData.name) || '-'}
        </Descriptions.Item>
        <Descriptions.Item label="GST">
          {(vendorData && vendorData.gstin) || 'Not Added'}
        </Descriptions.Item>
        <Descriptions.Item label="Created by name">
          {poDeliveryDetails ? poDeliveryDetails.created_by_name : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Created by mobile no">
          {poDeliveryDetails ? poDeliveryDetails.created_by_contact_no : '-'}
        </Descriptions.Item>
        <Descriptions.Item label=" Purchase contract validity">
          {poDeliveryDetails && poDeliveryDetails.contract_validity
            ? dayjs(poDeliveryDetails.contract_validity as string).format('MMM DD, YYYY')
            : '-'}
        </Descriptions.Item>
      </Descriptions>
      <h2 style={{ marginTop: '8px', marginBottom: '0px' }}>Item List</h2>
      <form>
        <Table<PurchaseOrderDeliverTableType>
          dataSource={purchaseOrderItemsData as PurchaseOrderDeliverTableType[]}
          bordered
          size="small"
          rowKey="id"
          pagination={{ showSizeChanger: true }}
          loading={GetPoDeliveryDetailsLoading}
        >
          <Table.Column<PurchaseOrderDeliverTableType>
            title="Item"
            dataIndex="item"
            width={300}
            render={(text, record) => {
              return <>{record.item.name}</>;
            }}
          />
          <Table.Column<PurchaseOrderDeliverTableType>
            title={`Ordered ${isPathIncludesOtherItems ? 'Quantity' : 'No. of Bags'}`}
            dataIndex="orderedQuantity"
            width={200}
            render={(text, record) => {
              return isPathIncludesOtherItems ? record.quantity : record.orderedItemsCount;
            }}
          />
          <Table.Column<PurchaseOrderDeliverTableType>
            title="Delivered Items"
            dataIndex="deliveredItems"
            width={200}
            render={(text, record) => {
              const deliveredItems = inwardShipmentItems.find((shipmentItem) => {
                return shipmentItem[0].itemId === record.item.id;
              });

              if (deliveredItems) {
                if (isPathIncludesOtherItems) {
                  return deliveredItems[0].othersQuantity as number;
                } else {
                  return deliveredItems[0].bagsCount as number;
                }
              }
              return '-';
            }}
          />
          <Table.Column<PurchaseOrderDeliverTableType>
            title="GRN"
            dataIndex="grn"
            width={200}
            render={(text, record) => {
              const inwardShipmentDetails = inwardShipmentItemsArray.find((inwardShipmentData) => {
                return inwardShipmentData.items[0].itemId === record.item.id;
              });

              return inwardShipmentDetails ? inwardShipmentDetails.grn_v2 : '-';
            }}
          />
        </Table>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20, marginTop: 10 }}
        >
          {!isPoMarkAsCompleted ? (
            <Button
              type="primary"
              htmlType="submit"
              loading={isCompletedBtnLoading}
              onClick={(e) => {
                e.preventDefault();
                setIsCompletedBtnLoading(true);

                markDeliveryCompletedForPO({
                  variables: {
                    id: orderId,
                    delivery_completed_at: new Date(),
                  },
                })
                  .then(() => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.success('Delivery details have been added successfully');
                    setIsCompletedBtnLoading(false);
                    navigate(`/purchaseOrders/${itemCategory.key}`);
                  })
                  .catch((err: ApolloError) => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    message.error(err.message);
                    setIsCompletedBtnLoading(false);
                    logger(err);
                  });
              }}
            >
              Complete
            </Button>
          ) : null}

          <Button
            style={{ marginLeft: 20 }}
            onClick={() => {
              navigate(`/purchaseOrders/${itemCategory.key}`);
            }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

export default PurchaseOrderDeliverScreen;
