import React, { useState } from 'react';
import { Button, message, Modal, Popconfirm, Table } from 'antd';
import { ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { ApolloError, Reference, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  DeleteInternalTransferShipmentItemMutation,
  DeleteInternalTransferShipmentItemMutationVariables,
  Enum_Item_Category_Enum,
  GetProductInwardShipmentItemsQuery,
  GetProductInwardShipmentItemsQueryVariables,
  InternalTransferOpenShipmentsQuery,
  InternalTransferOpenShipmentsQueryVariables,
  UpdateInternalTransferShipmentMutation,
  UpdateInternalTransferShipmentMutationVariables,
} from '../../../graphql/graphql-types';
import {
  CreateOrEditMaterialLoadingFormType,
  InternalShipmentItemMaterialUnloadingFormType,
  InternalTransferShipmentItemTableType,
  PaddyOrProductInternalTransferShipmentType,
} from '../../../utils/types';
import { loader } from 'graphql.macro';
import { formItemStyleProps, inputComponentCommonStyle } from '../../../utils/globals';
import { logger } from '../../../utils/helpers';
import CreateOrEditMaterialLoadingForm from './CreateOrEditMaterialLoadingForm';
import colors from '../../../scss/variables.module.scss';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import Select from '../../../components/Select';

/* loading get internal transfer open shipment query */
const getInternalTransferOpenShipmentsQuery = loader(
  '../../../graphql/queries/internalTransferOpenShipmentsQuery.graphql',
);

/* loading get product inward shipment items query */
const getProductInwardShipmentItemsQuery = loader(
  '../../../graphql/queries/getProductInwardShipmentItemsQuery.graphql',
);

/* loading delete internal shipment item mutation */
const deleteInternalShipmentItemMutation = loader(
  '../../../graphql/mutations/deleteInternalTransferShipmentItemMutation.graphql',
);

/* loading update internal transfer shipment  mutation  */
const updateInternalTransferShipmentMutation = loader(
  '../../../graphql/mutations/updateInternalTransferShipmentMutation.graphql',
);

/* loading finish internal transfer shipment query */
const finishInternalTransferShipmentQuery = loader(
  '../../../graphql/queries/internalTransferShipmentsForFinishingQuery.graphql',
);

/* React functional component */
const MaterialLoadingForWeighbridgeProductScreen = () => {
  /* This state is used to retain the data of the shipment item whose edit button has been pressed, which is then used to update that shipment item's data and maintain modal visibility */
  const [shipmentItemDataAndModalVisible, setShipmentItemDataAndModalVisible] =
    useState<CreateOrEditMaterialLoadingFormType | null>(null);

  /* state used to store id of the shipment item whose delete button is clicked which is then used to show loading indicator on delete button while deleting a shipment  item*/
  const [shipmentItemIdToDeleteBtnLoading, setShipmentItemIdToDeleteBtnLoading] = useState<
    string | null
  >(null);

  /* this state used to show loading indicator on finish shipment button */
  const [isShipmentFinishBtnLoading, setIsShipmentFinishBtnLoading] = useState<boolean>(false);

  /* useForm declaration */
  const { control, setValue, watch, reset } =
    useForm<InternalShipmentItemMaterialUnloadingFormType>({
      defaultValues: {
        grn: null,
        shipmentIdFromVehicleNumber: null,
      },
      mode: 'onChange',
    });

  /* get data from an open internal transfer shipment whose material type is product */
  const {
    data: internalTransferOpenShipmentsData,
    loading: internalTransferOpenShipmentsLoading,
    error: internalTransferOpenShipmentsError,
  } = useQuery<InternalTransferOpenShipmentsQuery, InternalTransferOpenShipmentsQueryVariables>(
    getInternalTransferOpenShipmentsQuery,
    { variables: { materialType: Enum_Item_Category_Enum.Product } },
  );

  /* get product inward shipment items data which is equal of selected vehicle number */
  const [
    getProductInwardShipmentItems,
    {
      data: getProductInwardShipmentItemsData,
      loading: getProductInwardShipmentItemsLoading,
      error: getProductInwardShipmentItemsError,
    },
  ] = useLazyQuery<GetProductInwardShipmentItemsQuery, GetProductInwardShipmentItemsQueryVariables>(
    getProductInwardShipmentItemsQuery,
  );

  /* delete internal shipment item mutation */
  const [deleteInternalShipmentItem] = useMutation<
    DeleteInternalTransferShipmentItemMutation,
    DeleteInternalTransferShipmentItemMutationVariables
  >(deleteInternalShipmentItemMutation);

  /* update internal transfer shipment */
  const [updateInternalTransferShipment] = useMutation<
    UpdateInternalTransferShipmentMutation,
    UpdateInternalTransferShipmentMutationVariables
  >(updateInternalTransferShipmentMutation);

  /* this const used to store shipment, which is selected to unload using it's 'vehicle number' */
  const shipmentSelectedToUnload =
    internalTransferOpenShipmentsData &&
    internalTransferOpenShipmentsData.internalTransferShipments.find(
      (item) => item.id === watch('shipmentIdFromVehicleNumber'),
    );

  /* function to delete internal shipment item */
  const deleteInternalShipmentItemFunc = (shipmentId: string) => {
    setShipmentItemIdToDeleteBtnLoading(shipmentId);
    /* delete internal shipment item */
    deleteInternalShipmentItem({
      variables: {
        id: shipmentId,
      },
      /* after deleting a shipment item, the update function is used to update the cache and display an updated shipment items array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a shipment item that was just removed. */
        const deletedDataId = deleteData?.delete_internalTransferShipmentItems_by_pk?.id;
        cache.modify({
          fields: {
            internalTransferShipmentItems(
              existingInternalShipmentItems: Array<Reference>,
              { readField },
            ) {
              if (deletedDataId) {
                return existingInternalShipmentItems.filter(
                  (shipmentRef) => deletedDataId !== readField('id', shipmentRef),
                );
              }
              return existingInternalShipmentItems;
            },
          },
        });
      },
    })
      .then(() => {
        setShipmentItemIdToDeleteBtnLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment item has been successfully deleted');
      })
      .catch((error: ApolloError) => {
        setShipmentItemIdToDeleteBtnLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  /* function to  finish internal shipment loading */
  const finishInternalTransferShipmentFunc = () => {
    setIsShipmentFinishBtnLoading(true);
    /* destructing shipment data */
    if (shipmentSelectedToUnload) {
      const {
        id,
        personName,
        firstVehicleWtKg,
        vehicleNumber,
        firstVehicleImage,
        firstVehicleWtTakenAt,
      } = shipmentSelectedToUnload as PaddyOrProductInternalTransferShipmentType;

      /* update internal transfer shipment mutation */
      updateInternalTransferShipment({
        variables: {
          id,
          personName: personName as string,
          firstVehicleWtKg,
          vehicleNumber: vehicleNumber as string,
          firstVehicleImage,
          detailsEnteredAt: new Date(),
          materialType: Enum_Item_Category_Enum.Product,
          firstVehicleWtTakenAt: firstVehicleWtTakenAt as Date,
        },
        refetchQueries: [
          getInternalTransferOpenShipmentsQuery,
          finishInternalTransferShipmentQuery,
        ].map((query) => ({
          query: query,
          variables: { materialType: 'product' },
        })),
      })
        .then(() => {
          setIsShipmentFinishBtnLoading(false);
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been successfully loaded.');
        })
        .catch((error: ApolloError) => {
          setIsShipmentFinishBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  };

  /* show error text on the screen. if it has any error while loading data from the server */
  if (internalTransferOpenShipmentsError || getProductInwardShipmentItemsError) {
    return (
      <div className="errorText">
        {(internalTransferOpenShipmentsError && internalTransferOpenShipmentsError.message) ||
          (getProductInwardShipmentItemsError && getProductInwardShipmentItemsError.message)}
      </div>
    );
  }

  return (
    <>
      {shipmentItemDataAndModalVisible && shipmentSelectedToUnload ? (
        <Modal
          visible={shipmentItemDataAndModalVisible ? true : false}
          title="Edit Shipment Item"
          footer={null}
          className="editInternalShipmentModal"
          onCancel={() => {
            setShipmentItemDataAndModalVisible(null);
          }}
          width={670}
          destroyOnClose
        >
          <CreateOrEditMaterialLoadingForm
            mode="edit"
            shipmentItemDataToEdit={shipmentItemDataAndModalVisible}
            shipmentIdSelectedToUnload={shipmentSelectedToUnload.id}
            closeModal={() => {
              setShipmentItemDataAndModalVisible(null);
            }}
          />
        </Modal>
      ) : null}
      <form>
        <FormItem label="Select Vehicle" isRequired requiredMark="after" {...formItemStyleProps}>
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select vehicle number"
            name="shipmentIdFromVehicleNumber"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              loading: internalTransferOpenShipmentsLoading,
              optionFilterProp: 'children',
            }}
            options={
              internalTransferOpenShipmentsData
                ? internalTransferOpenShipmentsData.internalTransferShipments.map((item) => ({
                    value: item.id,
                    label: item.vehicleNumber as string,
                  }))
                : []
            }
            onChange={(rhfOnChange, value: string) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              rhfOnChange(value);
              if (value && internalTransferOpenShipmentsData) {
                /* this const used to store shipment, which is selected to unload using it's 'vehicle number' */
                const selectedShipment =
                  internalTransferOpenShipmentsData.internalTransferShipments.find(
                    (item) => item.id === value,
                  );
                /* if user has selected any shipment to unload, then get shipment items of that respective shipment */
                if (selectedShipment) {
                  setValue('grn', selectedShipment.grn);
                  /* call 'getProductInwardShipmentItems' to get product inward shipment items */
                  void getProductInwardShipmentItems({
                    variables: { _eq: selectedShipment.id },
                  });
                }
              }
            }}
          />
        </FormItem>
        {watch('shipmentIdFromVehicleNumber') ? (
          <FormItem label="GRN" {...formItemStyleProps}>
            <Input
              customStyles={inputComponentCommonStyle}
              name="grn"
              placeholder="Please enter grn number"
              rhfControllerProps={{
                control,
              }}
              inputProps={{ disabled: true }}
            />
          </FormItem>
        ) : null}
      </form>
      {watch('shipmentIdFromVehicleNumber') &&
      shipmentSelectedToUnload &&
      getProductInwardShipmentItemsData &&
      getProductInwardShipmentItemsData.internalTransferShipmentItems ? (
        <>
          <CreateOrEditMaterialLoadingForm
            mode="add"
            shipmentIdSelectedToUnload={shipmentSelectedToUnload.id}
            internalTransferShipmentItemsData={
              getProductInwardShipmentItemsData &&
              getProductInwardShipmentItemsData.internalTransferShipmentItems
            }
          />
          <h2>Shipments Items</h2>
          <Table<InternalTransferShipmentItemTableType>
            dataSource={
              getProductInwardShipmentItemsData &&
              getProductInwardShipmentItemsData.internalTransferShipmentItems
            }
            loading={getProductInwardShipmentItemsLoading}
            className="tableStyle"
            bordered
            size="small"
            pagination={{ showSizeChanger: true }}
          >
            <Table.Column<InternalTransferShipmentItemTableType>
              title="Product"
              dataIndex="product"
              key="product"
              align="center"
              width={200}
              render={(text, record) =>
                record.product && record.product.brand ? record.product.brand : 'NA'
              }
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              title="Source"
              dataIndex="source"
              key="source"
              align="center"
              width={270}
              render={(text, record) => {
                return (
                  <>
                    <span style={{ fontWeight: 'bold' }}>
                      {record.source === 'godown' ? 'Godown: ' : 'Mill: '}
                    </span>
                    {record.source === 'godown'
                      ? record.sourceGodown && record.sourceGodown.name
                      : record.sourceMill && record.sourceMill.name}
                  </>
                );
              }}
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              title="Destination"
              dataIndex="destination"
              align="center"
              width={270}
              render={(text, record) => {
                return (
                  <>
                    <span style={{ fontWeight: 'bold' }}>
                      {record.destination === 'godown' ? 'Godown: ' : 'Mill: '}
                    </span>
                    {record.destination === 'godown'
                      ? record.destinationGodown && record.destinationGodown.name
                      : record.destinationMill && record.destinationMill.name}
                  </>
                );
              }}
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              title="No. Of Bags"
              dataIndex="bagsCount"
              width={120}
              align="center"
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              title="Single Bag Weight (in kgs)"
              dataIndex="bagWtKg"
              align="center"
              width={120}
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              key="Actions"
              title="Actions"
              dataIndex="actions"
              align="center"
              width={200}
              render={(text, record) => {
                return (
                  <div className="buttonContainer">
                    <Button
                      onClick={() => {
                        /* destructing record data */
                        const {
                          id,
                          bagsCount,
                          sourceGodown,
                          sourceMill,
                          destinationGodown,
                          destinationMill,
                          destination,
                          product,
                          source,
                          bagWtKg,
                          internalTransferShipmentId,
                        } = record;
                        setShipmentItemDataAndModalVisible({
                          internalTransferShipmentId,
                          id,
                          bagsCount,
                          singleBagWeight: bagWtKg ? bagWtKg : null,
                          sourceGodownId: sourceGodown ? sourceGodown.id : null,
                          sourceMillId: sourceMill ? sourceMill.id : null,
                          destinationGodownId: destinationGodown ? destinationGodown.id : null,
                          destinationMillId: destinationMill ? destinationMill.id : null,
                          destination: destination ? destination : null,
                          source: source ? source : null,
                          productTypeId: product ? product.productType.id : null,
                          productId: product ? product.id : null,
                        });
                      }}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      title="Delete shipment item. Are you sure?"
                      okText="Yes"
                      onConfirm={() => {
                        deleteInternalShipmentItemFunc(record.id);
                      }}
                      okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                      cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                      cancelText="No"
                      icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                    >
                      <Button
                        className="deleteButton"
                        loading={record.id === shipmentItemIdToDeleteBtnLoading ? true : false}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                );
              }}
            />
          </Table>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
            <Popconfirm
              title="Finish loading, Are you sure?"
              placement="topLeft"
              okText="Yes"
              onConfirm={() => {
                finishInternalTransferShipmentFunc();
              }}
              okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
              cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
              cancelText="No"
              icon={<ExclamationCircleFilled style={{ color: '#faad14' }} />}
            >
              <Button type="primary" loading={isShipmentFinishBtnLoading}>
                Finish loading
              </Button>
            </Popconfirm>
          </div>
        </>
      ) : null}
    </>
  );
};

export default MaterialLoadingForWeighbridgeProductScreen;
