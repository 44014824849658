import React from 'react';
import { Tabs } from 'antd';
import { Outlet } from 'react-router-dom';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

/* React functional component */
const OutwardBagsTabLayout = () => {
  return (
    <div className="tabLayout">
      <Tabs defaultActiveKey="paddyBags" tabBarGutter={50} destroyInactiveTabPane>
        <TabPane tab="Paddy Bags" key="paddyBags">
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OutwardBagsTabLayout;
