import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, ApolloError, useMutation, Reference } from '@apollo/client';
import { Table, Button, Popconfirm, message } from 'antd';
import { PlusOutlined, WarningFilled, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../../utils/helpers';
import {
  GetAllRmSellersQuery,
  GetAllRmSellersQueryVariables,
  RmSellers,
  Enum_Item_Category,
  DeleteRmSellerMutation,
  DeleteRmSellerMutationVariables,
} from '../../../graphql/graphql-types';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import colors from '../../../scss/variables.module.scss';

/* view all vendors table data type */
type ViewAllVendorsTableType = Pick<
  RmSellers,
  'gstin' | 'id' | 'name' | 'primaryContactMobile' | 'primaryContactName'
> & {
  vendorsItemCategories: {
    /* vendor ID */
    vendorId: number;
    /* item category */
    item_category: Pick<Enum_Item_Category, 'display_name' | 'value'>;
  }[];
};

/* loading get all vendors query */
const getAllVendorsQuery = loader('../../../graphql/queries/getAllRmSellersQuery.graphql');

/* loading delete vendor mutation  */
const deleteVendorMutation = loader('../../../graphql/mutations/deleteRmSellerMutation.graphql');

/* React functional component */
const ViewAllVendorsScreen = () => {
  /* state used to store id of the vendor whose delete vendor button is clicked which is then used to show loading indicator on delete button while deleting a vendor */
  const [deleteVendorId, setDeleteVendorId] = useState<number | null>(null);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* get all vendors query */
  const {
    data: getAllVendorsData,
    loading: getAllVendorsLoading,
    error: getAllVendorsError,
  } = useQuery<GetAllRmSellersQuery, GetAllRmSellersQueryVariables>(getAllVendorsQuery);

  /* delete a vendor mutation */
  const [deleteVendor] = useMutation<DeleteRmSellerMutation, DeleteRmSellerMutationVariables>(
    deleteVendorMutation,
  );

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getAllVendorsError) {
    return <div className="errorText">{getAllVendorsError.message}</div>;
  }

  /* function to handle delete vendor */
  const deleteVendorFunc = (vendorId: number) => {
    deleteVendor({
      variables: {
        id: vendorId,
      },
      /* after deleting a vendor, the update function is used to update the cache and display an updated vendors array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a vendor that was just removed. */
        const deletedDataId = deleteData?.deleteRmSeller?.id;
        cache.modify({
          fields: {
            rmSellers(existingVendors: Array<Reference>, { readField }) {
              if (deletedDataId) {
                return existingVendors.filter(
                  (vendorRef) => deletedDataId !== readField('id', vendorRef),
                );
              }
              return existingVendors;
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteVendorId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Vendor has been successfully deleted.');
      })
      .catch((deleteVendorError: ApolloError) => {
        /* this variable used to store delete vendor mutation apolloError */
        let errorMsg = deleteVendorError.message;
        setDeleteVendorId(null);
        if (
          deleteVendorError.message.includes('Foreign key violation.') &&
          deleteVendorError.message.includes('purchaseOrderItems')
        ) {
          errorMsg = 'Cannot delete since vendor is linked with purchase order(s).';
        } else if (deleteVendorError.message.includes('Foreign key violation.')) {
          errorMsg = 'Cannot delete since vendor is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteVendorError);
      });
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          navigate('create');
        }}
      >
        Create vendor
      </Button>
      <Table<ViewAllVendorsTableType>
        dataSource={getAllVendorsData && getAllVendorsData.getAllRmSellers}
        loading={getAllVendorsLoading}
        className="tableStyle"
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<ViewAllVendorsTableType>
          key="name"
          title="Vendor Name"
          dataIndex="name"
          align="center"
          render={(VenderName) => {
            return <span style={{ textTransform: 'capitalize' }}>{VenderName || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.name
              ? record.name.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="vendor name" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<ViewAllVendorsTableType>
          key="itemCategory"
          title="Item Category"
          dataIndex="itemCategory"
          align="center"
          width={230}
          render={(text, record) => {
            if (
              Array.isArray(record.vendorsItemCategories) &&
              record.vendorsItemCategories.length > 0
            ) {
              const { vendorsItemCategories } = record;
              return vendorsItemCategories.map((vendor, index) => {
                return (
                  <span key={`${vendor.vendorId} ${vendor.item_category.value}`}>
                    {vendor.item_category.display_name}
                    <span>{index !== record.vendorsItemCategories.length - 1 ? '; ' : null}</span>
                  </span>
                );
              });
            }
            return '-';
          }}
        />
        <Table.Column<ViewAllVendorsTableType>
          key="primaryContactName"
          title="Contact Name"
          dataIndex="primaryContactName"
          align="center"
          render={(contactName) => {
            return <span style={{ textTransform: 'capitalize' }}>{contactName || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.primaryContactName
              ? record.primaryContactName
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="contact name"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<ViewAllVendorsTableType>
          key="primaryContactMobile"
          title="Mobile"
          dataIndex="primaryContactMobile"
          align="center"
          render={(mobileNumber: string) => mobileNumber || '-'}
          onFilter={(value, record) => {
            return record.primaryContactMobile
              ? record.primaryContactMobile
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="mobile number"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<ViewAllVendorsTableType>
          key="gstin"
          title="GST"
          dataIndex="gstin"
          align="center"
          render={(gstin: string) => gstin || '-'}
          onFilter={(value, record) => {
            return record.gstin
              ? record.gstin.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="GST" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />

        <Table.Column<ViewAllVendorsTableType>
          key="Actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    navigate(`${record.id}/edit`);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete Vendor. Are you sure?"
                  okText="Yes"
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                  onConfirm={() => {
                    deleteVendorFunc(record.id);
                    setDeleteVendorId(record.id);
                  }}
                >
                  <Button className="deleteButton" loading={record.id === deleteVendorId}>
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};

export default ViewAllVendorsScreen;
