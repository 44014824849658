import React, { useContext } from 'react';
import { ContextUserDataType } from '../utils/types';

// context type
type AppContextType = {
  /* logged in user data */
  user: ContextUserDataType | undefined;
  /* Function to update or add user */
  setUser: (user: ContextUserDataType | undefined) => void;
};

/* context that will be used to store data,which can be accessed from any where in the app */
export const AppContext = React.createContext<AppContextType>({
  user: undefined,
  setUser: () => {},
});

/* hook to call context wherever needed */
export const useApp = (): AppContextType => useContext<AppContextType>(AppContext);
