import React, { useState } from 'react';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  DeleteProductMutation,
  DeleteProductMutationVariables,
  GetAllProductsAndProductTypesQuery,
  GetAllProductsAndProductTypesQueryVariables,
  Products,
  ProductTypes,
} from '../../../graphql/graphql-types';
import { Button, message, Popconfirm, Table } from 'antd';
import { FilterFilled, PlusOutlined, SearchOutlined, WarningFilled } from '@ant-design/icons';
import { logger } from '../../../utils/helpers';
import colors from '../../../scss/variables.module.scss';
import AddOrEditProductForm from './AddOrEditProductForm';
import { AddOrEditDataModalType, OptionsDataType, Product } from '../../../utils/types';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';

/* view all products table data type */
type ViewProductsTableType = Pick<Products, 'id' | 'brand'> & {
  /* product type */
  productType: Pick<ProductTypes, 'id' | 'name'>;
};

/* loading delete product mutation  */
const deleteProductMutation = loader('../../../graphql/mutations/deleteProductMutation.graphql');

/* loading get all products and product types query */
const getAllProductsAndProductTypeQuery = loader(
  '../../../graphql/queries/getAllProductsAndProductTypesQuery.graphql',
);

/* react functional component */
const ViewAllProductsScreen = () => {
  /* state used to store id of the product whose 'delete' button is clicked which is then used to show loading indicator on delete button while deleting a product */
  const [productIdToDltAndShowLoading, setProductIdToDltAndShowLoading] = useState<number | null>(
    null,
  );

  /* state used to store product data whose 'edit' button is clicked and to manage form modal visibility */
  const [productDataAndIsFormVisible, setProductDataAndIsFormVisible] =
    useState<AddOrEditDataModalType>(null);

  /* delete a product mutation */
  const [deleteProduct] = useMutation<DeleteProductMutation, DeleteProductMutationVariables>(
    deleteProductMutation,
  );

  /* get all products and product types data query */
  const {
    data: getAllProductsAndProductTypesData,
    loading: getAllProductsAndProductTypesLoading,
    error: getAllProductsAndProductTypesError,
  } = useQuery<GetAllProductsAndProductTypesQuery, GetAllProductsAndProductTypesQueryVariables>(
    getAllProductsAndProductTypeQuery,
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllProductsAndProductTypesError) {
    return <div className="errorText">{getAllProductsAndProductTypesError.message}</div>;
  }

  /* this variable used to store product type options list. Which is then pass to AddOrEditProductForm */
  let productTypeOptions: OptionsDataType[] = [];

  /* if getAllProductsAndProductTypesData loaded successfully , then pass it to the AddOrEditProductForm */
  if (getAllProductsAndProductTypesData) {
    /* store product type options */
    productTypeOptions = getAllProductsAndProductTypesData.getAllProductTypes.map((product) => ({
      value: product.id,
      label: product.name,
    }));
  }

  /* function to handle delete product */
  const deleteProductFunc = (productId: number) => {
    setProductIdToDltAndShowLoading(productId);
    deleteProduct({
      variables: {
        id: productId,
      },
      /* after deleting a product, the update function is used to update the cache and display an updated products array. */
      update(cache, { data: deletedProduct }) {
        /* using cache data, const to store the id of a product that was just removed. */
        const deletedProductId = deletedProduct?.deleteProduct?.id;
        cache.modify({
          fields: {
            products(existingProducts: Array<Reference>, { readField }) {
              if (deletedProductId) {
                return existingProducts.filter(
                  (productRef) => deletedProductId !== readField('id', productRef),
                );
              }
              return existingProducts;
            },
          },
        });
      },
    })
      .then(() => {
        setProductIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Product has been deleted successfully.');
      })
      .catch((deleteProductError: ApolloError) => {
        let errorMsg = deleteProductError.message;
        setProductIdToDltAndShowLoading(null);
        if (deleteProductError.message.includes('Foreign key violation.')) {
          errorMsg = 'Can not delete since product is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteProductError);
      });
  };

  /* function used to close the visibility of modal by setting state value to null. */
  const closeAddOrEditProductForm = () => {
    setProductDataAndIsFormVisible(null);
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          /* set AddOrEditProductForm mode to 'add', to add product and open AddOrEditProductForm. */
          setProductDataAndIsFormVisible({ mode: 'add' });
        }}
      >
        Create product
      </Button>
      <form>
        <Table<ViewProductsTableType>
          dataSource={
            getAllProductsAndProductTypesData && getAllProductsAndProductTypesData.getAllProducts
          }
          loading={getAllProductsAndProductTypesLoading}
          className="tableStyle"
          bordered
          size="small"
          style={{ width: '80%' }}
          pagination={{ showSizeChanger: true }}
        >
          <Table.Column<ViewProductsTableType>
            key="productType"
            title="Product Types"
            dataIndex="productType"
            align="center"
            filters={productTypeOptions.map((productType) => ({
              text: productType.label,
              value: productType.label,
            }))}
            onFilter={(value, record) => record.productType.name.indexOf(value as string) === 0}
            filterIcon={(filtered) => {
              return <FilterFilled className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(text, record) => {
              return <span>{record.productType.name || '-'}</span>;
            }}
          />
          <Table.Column<ViewProductsTableType>
            key="brand"
            title="Product Name"
            dataIndex="brand"
            align="center"
            onFilter={(value, record) => {
              return record.brand
                ? record.brand.toString().toLowerCase().includes(value.toString().toLowerCase())
                : false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent
                filterBy="product name"
                filterDropDownProp={filterDropDownProp}
              />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(text, record) => {
              return <span>{record.brand || '-'}</span>;
            }}
          />
          <Table.Column<ViewProductsTableType>
            key="Actions"
            title="Actions"
            dataIndex="actions"
            align="center"
            width={300}
            render={(text, record) => {
              return (
                <div className="buttonContainer">
                  <Button
                    onClick={() => {
                      /* set AddOrEditProductForm mode to 'edit' and store product data */
                      setProductDataAndIsFormVisible({
                        mode: 'edit',
                        dataToEdit: {
                          id: record.id,
                          brand: record.brand,
                          productTypeId: record.productType.id,
                        },
                      });
                    }}
                    type="default"
                  >
                    Edit
                  </Button>

                  <Popconfirm
                    title="Delete Product. Are you sure?"
                    okText="Yes"
                    okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                    cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                    cancelText="No"
                    icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                    onConfirm={() => {
                      deleteProductFunc(record.id);
                    }}
                  >
                    <Button
                      className="deleteButton"
                      loading={record.id === productIdToDltAndShowLoading}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              );
            }}
          />
        </Table>
      </form>
      {productDataAndIsFormVisible ? (
        <AddOrEditProductForm
          mode={productDataAndIsFormVisible.mode}
          productDataToEdit={productDataAndIsFormVisible.dataToEdit as Product}
          productTypesList={productTypeOptions}
          closeModal={closeAddOrEditProductForm}
        />
      ) : null}
    </>
  );
};

export default ViewAllProductsScreen;
// import React from 'react';

// const ViewAllProductsScreen = () => {
//   return <div>ViewAllProductsScreen</div>;
// };

// export default ViewAllProductsScreen;
