import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { message, Button } from 'antd';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, ApolloError, Reference } from '@apollo/client';
import { loader } from 'graphql.macro';
import { logger } from '../../../utils/helpers';
import {
  CreateBrokerMutation,
  CreateBrokerMutationVariables,
  UpdateBrokerByIdMutation,
  UpdateBrokerByIdMutationVariables,
  Brokers,
} from '../../../graphql/graphql-types';
import { statesOptionsForSelect } from '../../../utils/globals';
import Select from '../../../components/Select';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import { inputComponentCommonStyle, formItemStyleProps } from '../../../utils/globals';
import RequiredMessage from '../../../components/RequiredMessage';
import { AddressType } from '../../../utils/types';

/* loading create broker mutation with the help of loader */
const createBrokerMutation = loader('../../../graphql/mutations/createBrokerMutation.graphql');

/* loading update broker by Id mutation with the help of loader */
const updateBrokerByIdMutation = loader(
  '../../../graphql/mutations/updateBrokerByIdMutation.graphql',
);

/* validations required for each input field */
const schema = yup.object({
  name: yup
    .string()
    .required('Please enter broker name and try again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  email: yup.string().email('Please enter a valid email id'),
  gst: yup.string().matches(validationRegex.gstin, {
    message: 'Please enter a valid GSTIN',
    excludeEmptyString: true,
  }),
  mobile: yup.string().matches(validationRegex.mobile, {
    message: 'Please enter a valid mobile number',
    excludeEmptyString: true,
  }),
  address: yup.string(),
  city: yup.string().matches(/^[aA-zZ\s]+$/, {
    message: 'Please enter a valid name with alphabets only',
    excludeEmptyString: true,
  }),
  state: yup.string().nullable(),
  pincode: yup
    .string()
    .matches(validationRegex.pincode, {
      message: 'Please enter a valid pincode',
      excludeEmptyString: true,
    })
    .nullable(),
});

/* Broker form type */
type BrokerFormType = Pick<Brokers, 'email' | 'gst' | 'mobile' | 'name'> & AddressType;

// User form props type
type TransporterFormPropsType = {
  /* to check whether the broker form is being called from  edit mode  or add mode */
  mode: 'edit' | 'add';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  brokerDataToEdit?: BrokerFormType;
};

/* function to handle error while creating or updating brokers */
const errorHandlingForBrokerForm = (createAndUpdateBrokerError: ApolloError) => {
  if (
    createAndUpdateBrokerError.message ===
    'Uniqueness violation. duplicate key value violates unique constraint "brokers_gst_key"'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error('GST number is already registered');
  } else if (
    createAndUpdateBrokerError.message ===
    'Uniqueness violation. duplicate key value violates unique constraint "brokers_mobile_key"'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error('Mobile number is already registered');
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error(createAndUpdateBrokerError.message);
  }
};

/* React functional component */
const AddOrEditBrokerForm = ({ mode, brokerDataToEdit = undefined }: TransporterFormPropsType) => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* create broker mutation */
  const [createBroker] = useMutation<CreateBrokerMutation, CreateBrokerMutationVariables>(
    createBrokerMutation,
  );

  /* update broker mutation */
  const [updateBrokerById] = useMutation<
    UpdateBrokerByIdMutation,
    UpdateBrokerByIdMutationVariables
  >(updateBrokerByIdMutation);

  /* state to set the loading condition of button */
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* extracting required content from use form hook */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<BrokerFormType>({
    defaultValues:
      mode === 'edit' && brokerDataToEdit
        ? {
            name: brokerDataToEdit.name,
            gst: brokerDataToEdit.gst || '',
            email: brokerDataToEdit.email || '',
            mobile: brokerDataToEdit.mobile || '',
            address: brokerDataToEdit.address || '',
            state: brokerDataToEdit.state || null,
            city: brokerDataToEdit.city || '',
            pincode: brokerDataToEdit.pincode || '',
          }
        : {
            name: '',
            gst: '',
            email: '',
            mobile: '',
            address: '',
            state: null,
            city: '',
            pincode: '',
          },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* function to handle submitted data */
  const onSubmit = (data: BrokerFormType) => {
    setBtnLoader(true);

    /* const used to store mutation variables which are common in both updateBrokerById and createBroker mutation */
    const commonMutationVariables = {
      name: data.name,
      address: {
        address: data.address || null,
        state: data.state,
        city: data.city || null,
        pincode: data.pincode || null,
      },
      gst: data.gst || null,
      mobile: data.mobile || null,
      email: data.email || null,
    };

    if (mode === 'add') {
      createBroker({
        variables: {
          ...commonMutationVariables,
        },
        update(cache, { data: addedBroker }) {
          /* extracting the new data added by user */
          const dataToAdd = addedBroker?.insert_brokers_one;
          cache.modify({
            fields: {
              brokers(existingBrokers: Array<Reference>) {
                return [...existingBrokers, dataToAdd];
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Broker has been added successfully.');
          navigate('/management/brokers');
          reset();
          setBtnLoader(false);
        })
        .catch((error: ApolloError) => {
          errorHandlingForBrokerForm(error);
          setBtnLoader(false);
          logger(error);
        });
    } else {
      updateBrokerById({
        variables: {
          id: params.id || '',
          ...commonMutationVariables,
        },
        update(cache, { data: updatedData }) {
          /* data to update */
          const dataToUpdate = updatedData?.update_brokers_by_pk;
          cache.modify({
            fields: {
              brokers(existingBrokers: Array<Reference>, { readField }) {
                if (dataToUpdate) {
                  return existingBrokers.map((brokersData) => {
                    if (dataToUpdate.id === readField('id', brokersData)) {
                      return dataToUpdate;
                    }
                    return brokersData;
                  });
                }
                return existingBrokers;
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Broker has been updated successfully.');
          navigate('/management/brokers');
          reset();
          setBtnLoader(false);
        })
        .catch((error: ApolloError) => {
          errorHandlingForBrokerForm(error);
          logger(error);
          setBtnLoader(false);
        });
    }
  };

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormItem
          label="Broker Name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          requiredMark="after"
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter name of broker"
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="GST"
          {...formItemStyleProps}
          errorText={errors && errors.gst ? errors.gst.message : undefined}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter GST of broker"
            name="gst"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Mobile"
          errorText={errors && errors.mobile ? errors.mobile.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter mobile number of broker"
            name="mobile"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Email"
          errorText={errors && errors.email ? errors.email.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter email id of broker"
            name="email"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Address"
          errorText={errors && errors.address ? errors.address.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            name="address"
            isTextAreaInput={true}
            placeholder="Please enter address of broker"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
            textAreaProps={{ rows: 4 }}
          />
        </FormItem>
        <FormItem
          label="City"
          errorText={errors && errors.city ? errors.city.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter city of broker"
            name="city"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="State"
          errorText={errors && errors.state ? errors.state.message : undefined}
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select state of broker"
            name="state"
            rhfControllerProps={{
              control,
            }}
            selectProps={{ showSearch: true }}
            options={statesOptionsForSelect}
          />
        </FormItem>
        <FormItem
          label="Pincode"
          errorText={errors && errors.pincode ? errors.pincode.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter pincode of broker"
            name="pincode"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
          <>
            <Button
              htmlType="submit"
              type="primary"
              loading={btnLoader}
              style={{ marginRight: 10 }}
            >
              {mode === 'edit' ? 'Update' : 'Create Broker'}
            </Button>
            <Button
              type="default"
              onClick={() => {
                navigate('/management/brokers');
              }}
            >
              Cancel
            </Button>
          </>
        </FormItem>
      </form>
    </>
  );
};

export default AddOrEditBrokerForm;
