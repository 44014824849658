import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useLazyQuery, ApolloError, useMutation, useApolloClient } from '@apollo/client';
import { Button, message, Spin, Descriptions } from 'antd';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import {
  GetOutwardShipmentsForTruckOutQuery,
  GetOutwardShipmentsForTruckOutQueryVariables,
  GetOutwardShipmentByIdQuery,
  GetOutwardShipmentByIdQueryVariables,
  FinishOutwardShipmentTruckOutMutation,
  FinishOutwardShipmentTruckOutMutationVariables,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
  GetSettingByNameQuery,
  GetSettingByNameQueryVariables,
} from '../../../graphql/graphql-types';
import RequiredMessage from '../../../components/RequiredMessage';
import FormItem from '../../../components/FormItem';
import Select from '../../../components/Select';
import { dateFormatFunc, inputComponentCommonStyle } from '../../../utils/globals';
import InputNumber from '../../../components/InputNumber';
import { handleGetWeightFunction, logger } from '../../../utils/helpers';
import { useOutwardShipmentOutlet } from './OutwardProductTabLayout';
import { OutwardShipmentByIdDataType } from '../../../utils/types';

/* outward shipment truck outward form type */
type OutwardShipmentTruckOutwardFormType = {
  /* prop type used to store 'id' of the shipment whose vehicle number get selected from 'select vehicle' dropdown */
  shipmentIdFromVehicleNumber: number | null;
  /* prop type used to store gross weight in kg */
  grossWeightInKg: number | null;
};

/* loading get outward shipment for truck out query with the help of loader */
const getOutwardShipmentsForTruckOutQuery = loader(
  '../../../graphql/queries/getOutwardShipmentsForTruckOutQuery.graphql',
);

/* loading get outward shipment by id query with the help of loader */
const getOutwardShipmentByIdQuery = loader(
  '../../../graphql/queries/getOutwardShipmentByIdQuery.graphql',
);

/* loading get bhagwati mill id query with the help of loader */
const getBhagwatiMillIdQuery = loader('../../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* loading finish outward shipment truck out mutation  */
const finishOutwardShipmentTruckOutMutation = loader(
  '../../../graphql/mutations/finishOutwardShipmentTruckOutMutation.graphql',
);

/* loading get setting by name query with the help of loader */
const getSettingByNameQuery = loader('../../../graphql/queries/getSettingByNameQuery.graphql');

/* loading get outward shipment for order & loading query with the help of loader */
const getOutwardShipmentsForOrdersAndLoadingQuery = loader(
  '../../../graphql/queries/getOutwardShipmentsForOrdersAndLoadingQuery.graphql',
);

/* loading get outward shipment for invoicing query with the help of loader */
const getOutwardShipmentsForInvoicingQuery = loader(
  '../../../graphql/queries/getOutwardShipmentsForInvoicingQuery.graphql',
);

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 5,
  /* input column span of FormItem */
  inputColSpan: 7,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* form validation schema */
const schema = yup.object({
  grossWeightInKg: yup
    .number()
    .nullable()
    .required('Please enter gross weight(in kg) and try again.'),
});

/* react functional component */
const OutwardProductTruckOutwardScreen = (): JSX.Element => {
  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* extracting 'outwardShipmentId & setOutwardShipmentId' from outward shipment outlet */
  const { outwardShipmentId, setOutwardShipmentId } = useOutwardShipmentOutlet();

  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* state used to show loading indicator on 'finish truck outward' button */
  const [finishTruckOutwardBtnLoading, setFinishTruckOutwardBtnLoading] = useState<boolean>(false);

  /* state to show loading indicator on get weight button */
  const [getWeightButtonLoading, setGetWeightButtonLoading] = useState<boolean>(false);

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<OutwardShipmentTruckOutwardFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      shipmentIdFromVehicleNumber: null,
      grossWeightInKg: null,
    },
    mode: 'onChange',
  });

  /* get outward shipment by Id query used to display shipment & shipment order information */
  const [
    getOutwardShipmentsById,
    {
      data: getOutwardShipmentsByIdData,
      loading: getOutwardShipmentsByIdDataLoading,
      error: getOutwardShipmentsByIdDataError,
    },
  ] = useLazyQuery<GetOutwardShipmentByIdQuery, GetOutwardShipmentByIdQueryVariables>(
    getOutwardShipmentByIdQuery,
  );

  /* get outward shipment for truck out query used to display vehicle number, as a dropdown list on 'shipmentIdFromVehicleNumber' form field */
  const {
    data: getOutwardShipmentsTruckOutData,
    loading: getOutwardShipmentsTruckOutDataLoading,
    error: getOutwardShipmentsTruckOutDataError,
  } = useQuery<GetOutwardShipmentsForTruckOutQuery, GetOutwardShipmentsForTruckOutQueryVariables>(
    getOutwardShipmentsForTruckOutQuery,
    {
      onCompleted: () => {
        /* if user already selected outward shipment id to loading,from 'Loading' screen,
   then get shipment & shipment order loading information for that selected shipment id  */
        if (outwardShipmentId) {
          /* call 'getOutwardShipmentsById' function to get shipment & shipment order loading information */
          void getOutwardShipmentsById({ variables: { outwardShipmentId: outwardShipmentId } });
          /* initialize 'shipmentIdFromVehicleNumber' field value to selected outward shipment id  */
          setValue('shipmentIdFromVehicleNumber', outwardShipmentId);
        }
      },
    },
  );

  /* finish outward shipment truck out mutation */
  const [finishShipmentTruckOut] = useMutation<
    FinishOutwardShipmentTruckOutMutation,
    FinishOutwardShipmentTruckOutMutationVariables
  >(finishOutwardShipmentTruckOutMutation);

  /* get bhagwati mill id query used to display mill name in the form */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* setting by name query is used to determine whether the 'grossWeightInKg' field is enabled or disabled. */
  const {
    data: weightInputDisabledData,
    error: getSettingByNameError,
    loading: getSettingByNameLoader,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weightInputDisabled' },
  });

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getOutwardShipmentsTruckOutDataError || getOutwardShipmentsByIdDataError) {
    let errorMessage;
    if (getOutwardShipmentsTruckOutDataError) {
      errorMessage = getOutwardShipmentsTruckOutDataError.message;
    } else if (getOutwardShipmentsByIdDataError) {
      errorMessage = getOutwardShipmentsByIdDataError.message;
    } else if (getBhagwatiMillIdQueryError) {
      errorMessage = getBhagwatiMillIdQueryError.message;
    } else if (getSettingByNameError) {
      errorMessage = getSettingByNameError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getSettingByNameLoader) {
    <Spin className="loadingSpinner" />;
  }

  /* const used to store outward shipment by id data, so that we can use it further */
  const outwardShipmentsByIdData =
    getOutwardShipmentsByIdData &&
    (getOutwardShipmentsByIdData.getOutwardShipmentById as OutwardShipmentByIdDataType);

  /* this variable used to store transporter vehicle's capacity */
  let transporterVehicleCapacityInKg = 0;

  /* this variable used to store net material weight in kg, which is calculated using 'gross & empty vehicle weight' */
  let calculateNetMaterialWeightInKg = 0;

  /* this variable used to calculate loaded net material weight in kg,
  which is calculated using total number of bags loaded in orders & their bag size */
  let calculateLoadedNetWeightWeightInKg = 0;

  /* this variable used to calculate difference between 'loaded net material weight & material net weight' */
  let calculateNetDifference = 0;

  /* calculate 'net material weight','loaded net material weight','difference in net material weight' & transporter vehicle capacity */
  if (outwardShipmentsByIdData) {
    /* destructing  outward order shipment by id data */
    const { transporterVehicle, orders, emptyVehicleWtKg } = outwardShipmentsByIdData;

    /* this const used to store gross weight in kg using watch */
    const watchedGrossWeightInKg = watch('grossWeightInKg');

    /* calculate transporter vehicle capacity in kg */
    transporterVehicleCapacityInKg =
      transporterVehicle && transporterVehicle.capacityKgs ? transporterVehicle.capacityKgs : 0;

    /* if user entered gross weight & selected shipment contain empty vehicle weight, then calculate net material weight using this values */
    if (watchedGrossWeightInKg && emptyVehicleWtKg) {
      /* calculate net material weight  */
      calculateNetMaterialWeightInKg =
        watchedGrossWeightInKg === 0 ? 0 : watchedGrossWeightInKg - emptyVehicleWtKg;
    }

    /* calculate 'loaded material's net weight' using 'number of bags loaded and bag weight' in each order */
    if (orders.length > 0) {
      orders.forEach((order) => {
        /* calculate loaded material's net weight using each order's 'bagCount' & 'bagSize' */
        if (order.loadedBagsCount && order.bagSizeKg) {
          /* calculate loaded material's net weight */
          calculateLoadedNetWeightWeightInKg += order.loadedBagsCount * order.bagSizeKg;
        }
      });
    }

    /* calculate net difference in 'loaded material net weight' & 'material net weight' */
    calculateNetDifference =
      watchedGrossWeightInKg && watchedGrossWeightInKg !== 0
        ? calculateNetMaterialWeightInKg - calculateLoadedNetWeightWeightInKg
        : 0;
  }

  /* function to get weight of vehicle */
  const getVehicleWeight = () => {
    setGetWeightButtonLoading(true);
    handleGetWeightFunction(apolloClient)
      .then((vehicleWeight) => {
        /* if function return has gross vehicle weight then set that weight to 'grossWeightInKg' field */
        setValue('grossWeightInKg', vehicleWeight);
        /* if 'grossWeightInKg' field has any error then clear it, after setting it's value */
        if (errors.grossWeightInKg) {
          clearErrors('grossWeightInKg');
        }
        setGetWeightButtonLoading(false);
      })
      .catch((err: ApolloError) => {
        logger(err);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(err.message);
        setGetWeightButtonLoading(false);
      });
  };

  /* this function used to handle form submit */
  const onSubmit = (formData: OutwardShipmentTruckOutwardFormType) => {
    /* destructing form's data */
    const { shipmentIdFromVehicleNumber, grossWeightInKg } = formData;

    /* if user entered form values then execute finish truck out mutation */
    if (shipmentIdFromVehicleNumber && grossWeightInKg) {
      setFinishTruckOutwardBtnLoading(true);

      finishShipmentTruckOut({
        variables: {
          id: shipmentIdFromVehicleNumber,
          fullVehicleWtKg: grossWeightInKg,
          fullWtTakenAt: new Date(),
          truckOutAt: new Date(),
        },
        /* refetch get outward shipment truck out & loading query to get updated shipments list after finishing truck out */
        refetchQueries: [
          getOutwardShipmentsForInvoicingQuery,
          getOutwardShipmentsForTruckOutQuery,
          getOutwardShipmentsForOrdersAndLoadingQuery,
        ].map((refetchQuery) => ({ query: refetchQuery })),
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Finished');
          /* store selected shipment id to finish truck out into 'setOutwardShipmentId's state,
            so that we can get that shipment id onto 'Outward product -> Invoicing' tab screen  */
          setOutwardShipmentId(shipmentIdFromVehicleNumber);
          /* reset form */
          reset();
          setFinishTruckOutwardBtnLoading(false);
          /* navigate to 'Outward product -> Invoicing' screen */
          navigate('../invoicing');
        })
        .catch((error: ApolloError) => {
          setFinishTruckOutwardBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  };

  return (
    <>
      <RequiredMessage />
      <FormItem
        label="Shipment vehicle "
        isRequired
        errorText={
          errors && errors.shipmentIdFromVehicleNumber
            ? errors.shipmentIdFromVehicleNumber.message
            : undefined
        }
        {...formItemStyleProps}
      >
        <Select
          customStyles={{ ...inputComponentCommonStyle }}
          placeholder="Please select vehicle number"
          name="shipmentIdFromVehicleNumber"
          rhfControllerProps={{
            control,
          }}
          selectProps={{
            showSearch: true,
            optionFilterProp: 'children',
            loading: getOutwardShipmentsTruckOutDataLoading,
          }}
          options={
            getOutwardShipmentsTruckOutData &&
            getOutwardShipmentsTruckOutData.getOutwardShipmentsForTruckOut
              ? getOutwardShipmentsTruckOutData.getOutwardShipmentsForTruckOut.map((shipment) => {
                  return {
                    value: shipment.id,
                    label:
                      (shipment.transporterVehicle && shipment.transporterVehicle.number) || '-',
                  };
                })
              : []
          }
          onChange={(rhfOnChange, value: number) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            rhfOnChange(value);
            /* on selection of 'shipmentIdFromVehicleNumber' field set 'grossWeightInKg' to null,
        so that we can reset 'grossWeightInKg' field's value */
            setValue('grossWeightInKg', null);
            /* call 'getOutwardShipmentsById' function to get selected outward shipment id information */
            void getOutwardShipmentsById({ variables: { outwardShipmentId: value } });
          }}
        />
      </FormItem>
      {watch('shipmentIdFromVehicleNumber') ? (
        <>
          {outwardShipmentsByIdData &&
          getBhagwatiMillIdData &&
          !getOutwardShipmentsByIdDataLoading &&
          !getBhagwatiMillIdQueryLoader ? (
            <form
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit(onSubmit)}
            >
              <Descriptions
                colon={false}
                column={1}
                labelStyle={{ width: '21%' }}
                style={{ marginTop: 20 }}
                contentStyle={{ textTransform: 'capitalize' }}
              >
                <Descriptions.Item label="GRN">
                  {outwardShipmentsByIdData.grn || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Date">
                  {(outwardShipmentsByIdData.createdAt &&
                    dateFormatFunc(outwardShipmentsByIdData.createdAt)) ||
                    '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Transporter Name">
                  {(outwardShipmentsByIdData.transporter &&
                    outwardShipmentsByIdData.transporter.name) ||
                    '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Drivers Name - Phone No."
                  contentStyle={{ flexDirection: 'column' }}
                >
                  {outwardShipmentsByIdData.drivers.map(({ driver }) => (
                    <span style={{ paddingBottom: 5 }} key={driver.id}>
                      Name: {driver.name}, Phone: {driver.mobile}
                    </span>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Vehicle Capacity"
                  contentStyle={{ textTransform: 'lowercase' }}
                >
                  {`${transporterVehicleCapacityInKg} kgs (${
                    transporterVehicleCapacityInKg / 100
                  } quintals)`}
                </Descriptions.Item>
                <Descriptions.Item
                  label=" TARE Weight (in kgs)"
                  contentStyle={{ textTransform: 'lowercase' }}
                >
                  {outwardShipmentsByIdData.emptyVehicleWtKg
                    ? outwardShipmentsByIdData.emptyVehicleWtKg
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Mill Name">
                  {getBhagwatiMillIdData &&
                    getBhagwatiMillIdData.mills[0] &&
                    getBhagwatiMillIdData.mills[0].name}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Gross Weight (in kgs)"
                  contentStyle={{ textTransform: 'lowercase' }}
                >
                  <FormItem
                    isRequired
                    errorText={errors.grossWeightInKg ? errors.grossWeightInKg.message : undefined}
                    customStyle={{ paddingTop: 0 }}
                    displayMode="column"
                  >
                    <div style={{ display: 'flex' }}>
                      <InputNumber
                        customStyles={{ ...inputComponentCommonStyle, width: 280 }}
                        name="grossWeightInKg"
                        placeholder="Please enter gross weight (in kgs)"
                        rhfControllerProps={{
                          control,
                        }}
                        inputNumberProps={{
                          disabled:
                            weightInputDisabledData &&
                            !weightInputDisabledData.getSettingByName[0].intValue
                              ? true
                              : false,
                          precision: 2,
                        }}
                      />
                      <Button
                        type="primary"
                        style={{ marginLeft: 20 }}
                        onClick={() => {
                          getVehicleWeight();
                        }}
                        loading={getWeightButtonLoading}
                      >
                        Get Weight
                      </Button>
                    </div>
                  </FormItem>
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span>
                      Net Outward Material Weight - 1<br />
                      <span style={{ fontSize: 13 }}>(in kgs, as per weighbridge)</span>
                    </span>
                  }
                >
                  {watch('grossWeightInKg') ? calculateNetMaterialWeightInKg.toFixed(2) : '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span>
                      Net Outward Material Weight - 2<br />
                      <span style={{ fontSize: 13 }}>
                        (in kgs, as per no. of bags and packaging size)
                      </span>
                    </span>
                  }
                >
                  {calculateLoadedNetWeightWeightInKg
                    ? calculateLoadedNetWeightWeightInKg.toFixed(2)
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label={
                    <span>
                      Net weight-1 - Net weight-2
                      <br />
                      <span style={{ fontSize: 13 }}>(in kgs)</span>
                    </span>
                  }
                >
                  {watch('grossWeightInKg') ? calculateNetDifference.toFixed(2) : '-'}
                </Descriptions.Item>
              </Descriptions>
              <FormItem {...formItemStyleProps} customStyle={{ paddingTop: 0, marginBottom: 20 }}>
                <Button type="primary" htmlType="submit" loading={finishTruckOutwardBtnLoading}>
                  Finish Truck Outward
                </Button>
              </FormItem>
            </form>
          ) : (
            <Spin className="loadingSpinner" />
          )}
        </>
      ) : null}
    </>
  );
};

export default OutwardProductTruckOutwardScreen;
