import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { UploadedFileKeyAndUrlType, UploadedFileNameAndUrlType } from '../utils/types';

/* type for props of LightBoxComponent */
type LightBoxComponentPropType = {
  /* prop used to store object of image which contain image key and its respective url */
  imageUrlObjectArray: Array<UploadedFileKeyAndUrlType>;
  /* prop used to set the visibility of lightBox for image display window */
  setIsImageOpen: React.Dispatch<React.SetStateAction<boolean>>;
  /* prop used to store object of image which contain image name and its respective url */
  lightBoxData?: Array<UploadedFileNameAndUrlType>;
  /* prop use to set object of image which contain image name and its url */
  setLightBoxData?: React.Dispatch<React.SetStateAction<Array<UploadedFileNameAndUrlType>>>;
  /* prop type used to determine whether screen is outward shipment report or other report screen  */
  screenType?: 'outwardShipment' | 'others';
};

/* React functional component */
const LightBoxComponent = ({
  imageUrlObjectArray,
  setIsImageOpen,
  screenType,
  lightBoxData,
  setLightBoxData,
}: LightBoxComponentPropType) => {
  /* state to used store the index of imageUrlObjectArray to manage pre, next while displaying image */
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  /* stored light box data array */
  const lightBoxDataArray = lightBoxData ? lightBoxData : [];

  return (
    <>
      <Lightbox
        mainSrc={
          screenType === 'others'
            ? imageUrlObjectArray[photoIndex] && imageUrlObjectArray[photoIndex].url
            : lightBoxDataArray &&
              lightBoxDataArray[photoIndex] &&
              (lightBoxDataArray[photoIndex].url as string)
        }
        nextSrc={
          screenType === 'others'
            ? imageUrlObjectArray[(photoIndex + 1) % imageUrlObjectArray.length] &&
              imageUrlObjectArray[(photoIndex + 1) % imageUrlObjectArray.length].url
            : lightBoxDataArray[(photoIndex + 1) % lightBoxDataArray.length] &&
              lightBoxDataArray[(photoIndex + 1) % lightBoxDataArray.length].url
        }
        prevSrc={
          screenType === 'others'
            ? imageUrlObjectArray[
                (photoIndex + imageUrlObjectArray.length - 1) % imageUrlObjectArray.length
              ] &&
              imageUrlObjectArray[
                (photoIndex + imageUrlObjectArray.length - 1) % imageUrlObjectArray.length
              ].url
            : lightBoxDataArray[
                (photoIndex + lightBoxDataArray.length - 1) % lightBoxDataArray.length
              ] &&
              lightBoxDataArray[
                (photoIndex + lightBoxDataArray.length - 1) % lightBoxDataArray.length
              ].url
        }
        onCloseRequest={() => {
          setIsImageOpen(false);
          if (screenType === 'outwardShipment' && setLightBoxData) {
            setLightBoxData([]);
          }
        }}
        onMovePrevRequest={() => {
          if (
            screenType === 'others' ? imageUrlObjectArray.length > 1 : lightBoxDataArray.length > 1
          ) {
            setPhotoIndex(
              screenType === 'others'
                ? (photoIndex + imageUrlObjectArray.length - 1) % imageUrlObjectArray.length
                : (photoIndex + lightBoxDataArray.length - 1) % lightBoxDataArray.length,
            );
          }
        }}
        onMoveNextRequest={() => {
          if (
            screenType === 'others' ? imageUrlObjectArray.length > 1 : lightBoxDataArray.length > 1
          ) {
            setPhotoIndex(
              screenType === 'others'
                ? (photoIndex + 1) % imageUrlObjectArray.length
                : (photoIndex + 1) % lightBoxDataArray.length,
            );
          }
        }}
        imageCaption={
          screenType === 'others' ? (
            <div
              style={{
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              {(photoIndex === 0 && <span>Gross Weight Vehicle Photo</span>) || (
                <span>Tare Weight Vehicle Photo</span>
              )}
            </div>
          ) : (
            <div
              style={{
                fontWeight: 'bold',
                fontSize: 18,
              }}
            >
              <span>
                {lightBoxDataArray &&
                  lightBoxDataArray[photoIndex] &&
                  lightBoxDataArray[photoIndex].name}
              </span>
            </div>
          )
        }
      />
    </>
  );
};

export default LightBoxComponent;
