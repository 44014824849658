import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import AddOrEditVendorForm from './AddOrEditVendorForm';
import {
  GetRmSellerByIdQuery,
  GetRmSellerByIdQueryVariables,
} from '../../../graphql/graphql-types';
import { Spin } from 'antd';

/* loading get vendor by Id query */
const getVendorByIdQuery = loader('../../../graphql/queries/getRmSellerByIdQuery.graphql');

/* React functional component */
const EditVendorScreen = () => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* get vendor by ID query */
  const {
    data: getVendorByIdData,
    loading: getVendorByIdLoading,
    error: getVendorByIdError,
  } = useQuery<GetRmSellerByIdQuery, GetRmSellerByIdQueryVariables>(getVendorByIdQuery, {
    variables: { id: Number(params.id) },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getVendorByIdLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getVendorByIdError) {
    return <div className="errorText">{getVendorByIdError.message}</div>;
  }

  return (
    <>
      {getVendorByIdData && getVendorByIdData.getRmSellerById ? (
        <AddOrEditVendorForm mode="edit" editVendorData={getVendorByIdData.getRmSellerById} />
      ) : null}
    </>
  );
};

export default EditVendorScreen;
