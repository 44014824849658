import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../../context/AppContext';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

const InwardAdminTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* extracting user from useApp context */
  const { user } = useApp();

  /* useLocation destructuring */
  const location = useLocation();

  /* useParams destructing */
  const param = useParams();

  /* variable for stored param id */
  const paramId = Number(param.id);

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('process')) {
      return 'process';
    }
    if (location.pathname.includes('edit')) {
      return 'edit';
    }
    if (location.pathname.includes('review')) {
      return 'review';
    }
    return 'create';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="create"
        onTabClick={(key: string) => {
          if (key === 'process') {
            navigate(`process/${paramId}`);
          } else if (key === 'edit') {
            navigate(`edit/${paramId}`);
          } else if (key === 'review') {
            navigate('review');
          } else {
            navigate('create');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab="Open GRN" key="create">
          <Outlet />
        </TabPane>
        {user && user.role === 'admin' ? (
          <TabPane tab="Admin Review GRN" key="review">
            <Outlet />
          </TabPane>
        ) : null}

        {param.id ? (
          <TabPane
            tab="Process GRN"
            disabled={location.pathname.includes('process') ? false : true}
            key="process"
          >
            <Outlet />
          </TabPane>
        ) : null}
        {paramId && user && user.role === 'admin' ? (
          <TabPane
            tab="Modify GRN"
            key="edit"
            disabled={location.pathname.includes('edit') ? false : true}
          >
            <Outlet />
          </TabPane>
        ) : null}
      </Tabs>
    </div>
  );
};

export default InwardAdminTabLayout;
