import React, { useState } from 'react';
import { ApolloError, Reference, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import {
  UpdateGodownMutation,
  UpdateGodownMutationVariables,
  CreateGodownMutation,
  CreateGodownMutationVariables,
  Godowns,
} from '../../../graphql/graphql-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, message } from 'antd';
import { logger } from '../../../utils/helpers';
import { useNavigate } from 'react-router-dom';
import { inputComponentCommonStyle } from '../../../utils/globals';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import InputNumber from '../../../components/InputNumber';
import RequiredMessage from '../../../components/RequiredMessage';

/* add or edit godown form type */
type AddOrEditGodownFormType = Pick<Godowns, 'name' | 'capacityBags' | 'location' | 'id'>;

/* AddOrEditGodown form component props type */
type AddOrEditGodownFormComponentPropsType = {
  /* to check whether the AddOrEditGodown form is being called from edit mode or add mode */
  mode: 'edit' | 'add';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  editGodownData?: AddOrEditGodownFormType;
};

/* add or edit godown form validation schema */
const schema = yup.object({
  name: yup.string().required(`Please enter godown name and try again.`).nullable(),
  capacityBags: yup
    .number()
    .required(`Please enter godown capacity (no. of bags) and try again.`)
    .nullable()
    .positive('Please enter a valid number'),
});

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* loading create Godown mutation  */
const createGodownMutation = loader('../../../graphql/mutations/createGodownMutation.graphql');

/* loading update Godown mutation  */
const updateGodownMutation = loader('../../../graphql/mutations/updateGodownMutation.graphql');

const AddOrEditGodownForm = ({
  mode,
  editGodownData = undefined,
}: AddOrEditGodownFormComponentPropsType) => {
  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* this state used to show loading indicator on 'create Godown' or 'Update' button, when creating new godown or editing existing godown */
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /* create new godown mutation */
  const [createGodown] = useMutation<CreateGodownMutation, CreateGodownMutationVariables>(
    createGodownMutation,
  );

  /* update existing godown mutation */
  const [updateGodown] = useMutation<UpdateGodownMutation, UpdateGodownMutationVariables>(
    updateGodownMutation,
  );

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddOrEditGodownFormType>({
    resolver: yupResolver(schema),
    defaultValues:
      mode === 'edit' && editGodownData
        ? {
            name: editGodownData.name,
            capacityBags: editGodownData.capacityBags,
            location: editGodownData.location,
          }
        : {
            name: '',
            capacityBags: null,
            location: '',
          },
    mode: 'onChange',
  });

  /* create and update error handling function */
  const createAndUpdateMutationErrorHandlingFunc = (errorMsg: ApolloError) => {
    let createOrUpdateErrorMsg = errorMsg.message;
    if (
      errorMsg.message ===
      'Uniqueness violation. duplicate key value violates unique constraint "godowns_name_key"'
    ) {
      createOrUpdateErrorMsg = 'Godown name already exists.';
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.error(createOrUpdateErrorMsg);
  };

  /* function to handle submitted data */
  const onSubmit = (data: AddOrEditGodownFormType) => {
    setIsSubmitBtnLoading(true);
    /* const used to store mutation variables which are common in updateGodown mutation and createGodown mutation */
    const commonMutationVariables = {
      name: data.name,
      location: data.location || null,
      capacityBags: data.capacityBags as number,
    };

    /* if AddOrEditGodownForm is in edit mode execute updateGodown mutation */
    if (mode === 'edit' && editGodownData) {
      updateGodown({
        variables: {
          id: editGodownData.id,
          ...commonMutationVariables,
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Godown has been updated successfully.');
          navigate('/management/godowns');
          reset();
          setIsSubmitBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          createAndUpdateMutationErrorHandlingFunc(error);
          setIsSubmitBtnLoading(false);
          logger(error);
        });
    } else {
      createGodown({
        variables: {
          ...commonMutationVariables,
        },
        update(cache, { data: addedGodown }) {
          /* extracting the new data added by user */
          const dataToAdd = addedGodown?.createGodown;
          cache.modify({
            fields: {
              godowns(existingGodowns: Array<Reference>) {
                return [...existingGodowns, dataToAdd];
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Godown has been created successfully.');
          navigate('/management/godowns');
          reset();
          setIsSubmitBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          createAndUpdateMutationErrorHandlingFunc(error);
          setIsSubmitBtnLoading(false);
          logger(error);
        });
    }
  };

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormItem
          label="Godown Name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter name of the godown"
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Godown Location"
          errorText={errors && errors.location ? errors.location.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter location of godown"
            name="location"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Godown Capacity (no.of bags)"
          isRequired
          errorText={errors && errors.capacityBags ? errors.capacityBags.message : undefined}
          {...formItemStyleProps}
        >
          <InputNumber
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter godown capacity (no. of bags) "
            name="capacityBags"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
          <>
            <Button
              htmlType="submit"
              type="primary"
              loading={isSubmitBtnLoading}
              style={{ marginRight: 10 }}
            >
              {mode === 'edit' ? 'Update' : 'Create Godown'}
            </Button>
            <Button
              type="default"
              onClick={() => {
                navigate('/management/godowns');
              }}
            >
              Cancel
            </Button>
          </>
        </FormItem>
      </form>
    </>
  );
};

export default AddOrEditGodownForm;
