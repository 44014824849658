import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import authData from '../utils/authData';
import { onError } from '@apollo/client/link/error';
import localforage from 'localforage';
import { logger } from '../utils/helpers';
import { GraphQLError } from 'graphql';

const authLink = setContext((_, { headers }) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const newHeaders = { ...headers };

  const token = authData.getToken();

  if (token) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    newHeaders.authorization = `Bearer ${token}`;
  }

  // return the headers to the context so httpLink can read them
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    headers: newHeaders,
  };
});

// const to store error link object of the apollo client
const errorLink = onError(({ graphQLErrors }) => {
  if (
    Array.isArray(graphQLErrors) &&
    graphQLErrors.length > 0 &&
    graphQLErrors.find((ele: GraphQLError) => ele.extensions.code === 'access-denied')
  ) {
    // logging off user
    authData.removeToken();
    localforage.clear().catch((err: Error) => {
      logger(err);
    });
    // redirecting user to login screen which reloads the app too.
    window.location.reload();
  }
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  // combining all apollo link. Make sure http link should be at the end as its the terminating link.
  link: from([errorLink, authLink, httpLink]),
});
export default client;
