import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormItem from './FormItem';
import dayjs from 'dayjs';
import { Col, Row } from 'antd';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import getFinancialYearsList from '../utils/helpers/getFinancialYearsList';
import RadioGroup from './RadioGroup';

/**
 * This const is used to replace moment.js with Day.js library which is used to pass dayjs values in Range Picker props
 */
const RangePickerWithDayjs = generatePicker<Dayjs>(dayjsGenerateConfig);

type FinancialYearFormType = {
  financialYear: string | null;
};

type FinancialYearFilterComponentPropType = {
  // prop definition for 'selectedDate' state which stores start date and end date
  selectedDate?: {
    startDate: string | null;
    endDate: string | null;
  };
  // prop definition for 'setSelectedDate' state which sets start date and end date which is selected by user using range picker or Financial year select box
  setSelectedDate: React.Dispatch<
    React.SetStateAction<{
      startDate: string | null;
      endDate: string | null;
    }>
  >;
  // prop definition for is data range required
  isDateRangeRequired?: boolean;
};

const FinancialYearComponent = ({
  selectedDate,
  setSelectedDate,
  isDateRangeRequired = true,
}: FinancialYearFilterComponentPropType) => {
  const { RangePicker } = RangePickerWithDayjs;

  const financialYearArray = getFinancialYearsList();

  const financialYearArrayDefaultYear =
    financialYearArray && Array.isArray(financialYearArray) && financialYearArray[0]
      ? financialYearArray[0].label
      : null;

  const { control, setValue, watch } = useForm<FinancialYearFormType>({
    defaultValues: {
      financialYear: financialYearArrayDefaultYear,
    },
  });

  /**
   * useEffect to call query and render data for initial default value according to financial year
   */
  useEffect(() => {
    const currentFinancialYear = watch('financialYear');

    if (currentFinancialYear) {
      const selectedFinancialYear = financialYearArray.find(
        (item) => item.label === currentFinancialYear,
      );

      if (selectedFinancialYear) {
        setSelectedDate({
          startDate: selectedFinancialYear.date_range.start_date,
          endDate: selectedFinancialYear.date_range.end_date,
        });
      }
    }
    // eslint disable as if dependencies (setState in dependencies) provided then setState changes on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isDateRangeRequired ? (
        <>
          <Row>
            <Col span={12}>
              <div
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
              >
                <FormItem
                  label="Date Range :"
                  labelColSpan={6}
                  inputColSpan={18}
                  customStyle={{ paddingTop: 10 }}
                >
                  <>
                    <RangePicker
                      format="YYYY-MM-DD"
                      onChange={(date, dateStrings) => {
                        if (Array.isArray(dateStrings) && dateStrings.length > 0) {
                          setSelectedDate({
                            startDate: dateStrings[0],
                            endDate: dateStrings[1],
                          });

                          if (watch('financialYear')) {
                            setValue('financialYear', null);
                          } else if (date === null) {
                            setValue('financialYear', financialYearArrayDefaultYear);
                          }
                        } else {
                          setSelectedDate({ startDate: null, endDate: null });
                        }
                      }}
                      value={(() => {
                        /** condition A : if 'financialYear' is selected then 'dateRange' should be null */
                        if (watch('financialYear')) {
                          return null;
                        } else if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
                          /** condition B : if 'dateRange' is selected then display selected date range */
                          return [dayjs(selectedDate.startDate), dayjs(selectedDate.endDate)];
                        } else {
                          /** condition C : page is rendering first time then 'dateRange' should be null */
                          return null;
                        }
                      })()}
                    />
                  </>
                </FormItem>
              </div>
            </Col>
          </Row>
          <Row>
            <Col offset={2} style={{ textAlign: 'end', marginTop: 10 }}>
              <h3>OR</h3>
            </Col>
          </Row>
        </>
      ) : null}

      <Row>
        <Col span={12}>
          <FormItem
            label="Financial Year :"
            labelColSpan={6}
            inputColSpan={18}
            customStyle={{
              paddingTop: isDateRangeRequired ? 0 : 10,
              marginTop: isDateRangeRequired ? 0 : 10,
            }}
          >
            <>
              <RadioGroup
                name="financialYear"
                rhfControllerProps={{ control }}
                options={
                  financialYearArray &&
                  Array.isArray(financialYearArray) &&
                  financialYearArray.length > 0
                    ? financialYearArray.map((item) => ({
                        value: item.label,
                        label: item.label,
                      }))
                    : []
                }
                onChange={(value) => {
                  /**
                   * const to store selected financial year data from financial year radio box
                   * which is used to set start date and end date for selected financial year
                   */
                  const selectedFinancialYear = financialYearArray.find(
                    (item) => item.label === value.target.value,
                  );

                  if (selectedFinancialYear) {
                    setSelectedDate({
                      startDate: selectedFinancialYear.date_range.start_date,
                      endDate: selectedFinancialYear.date_range.end_date,
                    });
                  }
                }}
                style={{
                  width: 200,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              />
            </>
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default FinancialYearComponent;
