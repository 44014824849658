import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, message, Spin } from 'antd';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation, useQuery, Reference, useApolloClient } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import RequiredMessage from '../../components/RequiredMessage';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import FormItem from '../../components/FormItem';
import Input from '../../components/Input';
import { inputComponentCommonStyle } from '../../utils/globals';
import {
  GetSettingByNameQuery,
  GetSettingByNameQueryVariables,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
  CreateWeighbridgeReceiptMutation,
  CreateWeighbridgeReceiptMutationVariables,
} from '../../graphql/graphql-types';
import InputNumber from '../../components/InputNumber';
import { handleGetWeightFunction, logger } from '../../utils/helpers';

/* create weighbridge receipt form type */
type CreateWeighBridgeReceiptFormType = Pick<
  CreateWeighbridgeReceiptMutationVariables,
  'customerName' | 'driverName' | 'vehicleNumber' | 'material'
> & {
  /* empty weight of vehicle */
  emptyVehicleWtKg: number | null;
};

/* loading create weighbridge receipt mutation with the help of loader */
const createWeighBridgeReceiptMutation = loader(
  '../../graphql/mutations/createWeighbridgeReceiptMutation.graphql',
);

/* loading get setting by name query with the help of loader */
const getSettingByNameQuery = loader('../../graphql/queries/getSettingByNameQuery.graphql');

/* loading get bhagwati mill id query with the help of loader */
const getBhagwatiMillIdQuery = loader('../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
};

/* validations required for each input field */
const schema = yup.object({
  vehicleNumber: yup
    .string()
    .required('Please enter vehicle number and try again')
    .matches(validationRegex.vehicleRegistrationNumber, {
      message: 'Please enter a valid vehicle number',
    }),
  driverName: yup
    .string()
    .required('Please enter driver name and try and again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  customerName: yup
    .string()
    .required('Please enter customer name and try and again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
});

/* React functional component */
const CreateWeighBridgeReceipt = () => {
  /* extracting apollo client from useApolloClient */
  const apolloClient = useApolloClient();

  /* state to show loading indicator on weight button */
  const [getWeightButtonLoading, setGetWeightButtonLoading] = useState<boolean>(false);

  /* state to show loading indicator on save button */
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);

  /* create weighbridge receipt mutation */
  const [createWeighbridgeReceipt] = useMutation<
    CreateWeighbridgeReceiptMutation,
    CreateWeighbridgeReceiptMutationVariables
  >(createWeighBridgeReceiptMutation);

  /* setting by name query is used to determine whether the 'emptyVehicleWtKg' field is enabled or disabled. */
  const {
    data: weightInputDisabledData,
    error: getSettingByNameError,
    loading: getSettingByNameLoader,
  } = useQuery<GetSettingByNameQuery, GetSettingByNameQueryVariables>(getSettingByNameQuery, {
    variables: { name: 'weightInputDisabled' },
  });

  /* get bhagwati mill id query */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<CreateWeighBridgeReceiptFormType>({
    mode: 'onChange',
    defaultValues: {
      vehicleNumber: '',
      driverName: '',
      customerName: '',
      material: '',
      emptyVehicleWtKg: null,
    },
    resolver: yupResolver(schema),
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getBhagwatiMillIdQueryLoader || getSettingByNameLoader) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getSettingByNameError || getBhagwatiMillIdQueryError) {
    return (
      <div className="errorText">
        {getSettingByNameError
          ? getSettingByNameError.message
          : getBhagwatiMillIdQueryError && getBhagwatiMillIdQueryError.message}
      </div>
    );
  }

  /* function to handle on save data */
  const onSubmit = (submittedFormData: CreateWeighBridgeReceiptFormType) => {
    setSaveButtonLoading(true);
    createWeighbridgeReceipt({
      variables: {
        vehicleNumber: submittedFormData.vehicleNumber,
        driverName: submittedFormData.driverName,
        customerName: submittedFormData.customerName,
        material: submittedFormData.material,
        emptyVehicleWtKg: submittedFormData.emptyVehicleWtKg
          ? submittedFormData.emptyVehicleWtKg
          : 0,
      },
      update(cache, { data: addedVehicle }) {
        /* extracting the new data added by user */
        const dataToAdd = addedVehicle?.createWeighbridgeReceipt;
        cache.modify({
          fields: {
            weighbridgeReceipts(existingVehicles: Array<Reference>) {
              return [...existingVehicles, dataToAdd];
            },
          },
        });
      },
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success(
          `First weight created for Vehicle No. ${submittedFormData.vehicleNumber.toUpperCase()}.`,
        );
        reset();
        setSaveButtonLoading(false);
      })
      .catch((error: ApolloError) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error);
        logger(error);
        setSaveButtonLoading(false);
      });
  };

  /* function to get weight of vehicle */
  const getVehicleWeight = () => {
    setGetWeightButtonLoading(true);
    handleGetWeightFunction(apolloClient)
      .then((res) => {
        setValue('emptyVehicleWtKg', res);
        setGetWeightButtonLoading(false);
      })
      .catch((weightError: ApolloError) => {
        logger(weightError);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(weightError);
        setGetWeightButtonLoading(false);
      });
  };

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
    >
      <RequiredMessage />
      <FormItem
        label="Vehicle No"
        isRequired
        requiredMark="after"
        errorText={errors.vehicleNumber ? errors.vehicleNumber.message : undefined}
        {...formItemStyleProps}
      >
        <Input
          customStyles={inputComponentCommonStyle}
          name="vehicleNumber"
          placeholder="Please enter Vehicle number"
          rhfControllerProps={{
            control,
          }}
          onChange={(rhfOnChange, value) => {
            rhfOnChange(value.toUpperCase());
          }}
        />
      </FormItem>
      <FormItem
        label="Driver Name"
        isRequired
        requiredMark="after"
        errorText={errors.driverName ? errors.driverName.message : undefined}
        {...formItemStyleProps}
      >
        <Input
          customStyles={inputComponentCommonStyle}
          name="driverName"
          placeholder="Please enter driver name"
          rhfControllerProps={{
            control,
          }}
        />
      </FormItem>
      <FormItem
        label="Customer Name"
        isRequired
        requiredMark="after"
        errorText={errors.customerName ? errors.customerName.message : undefined}
        {...formItemStyleProps}
      >
        <Input
          customStyles={inputComponentCommonStyle}
          name="customerName"
          placeholder="Please enter customer name"
          rhfControllerProps={{
            control,
          }}
        />
      </FormItem>
      <FormItem
        label="Material"
        errorText={errors.material ? errors.material.message : undefined}
        {...formItemStyleProps}
      >
        <Input
          customStyles={inputComponentCommonStyle}
          name="material"
          placeholder="Please enter material"
          rhfControllerProps={{
            control,
          }}
        />
      </FormItem>
      <FormItem label="Mill Name" {...formItemStyleProps}>
        <>
          {getBhagwatiMillIdData &&
            getBhagwatiMillIdData.mills[0] &&
            getBhagwatiMillIdData.mills[0].name}
        </>
      </FormItem>
      <FormItem
        label="Weight-1 (in Kgs)"
        errorText={errors.emptyVehicleWtKg ? errors.emptyVehicleWtKg.message : undefined}
        {...formItemStyleProps}
        inputColSpan={7}
      >
        <div style={{ display: 'flex' }}>
          <InputNumber
            customStyles={{ ...inputComponentCommonStyle, flex: 1 }}
            name="emptyVehicleWtKg"
            placeholder="Please enter weight-1 (in kgs)"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{
              disabled:
                weightInputDisabledData && !weightInputDisabledData.getSettingByName[0].intValue
                  ? true
                  : false,
            }}
          />
          <Button
            type="primary"
            style={{ marginLeft: 5 }}
            onClick={() => {
              getVehicleWeight();
            }}
            loading={getWeightButtonLoading}
          >
            Get Weight
          </Button>
        </div>
      </FormItem>
      <FormItem {...formItemStyleProps}>
        <>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginRight: 10 }}
            loading={saveButtonLoading}
          >
            Save
          </Button>
          <Button
            type="default"
            onClick={() => {
              reset();
            }}
          >
            Reset
          </Button>
        </>
      </FormItem>
    </form>
  );
};

export default CreateWeighBridgeReceipt;
