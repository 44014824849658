import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Tabs } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import FormItem from '../../../components/FormItem';
import RadioGroup from '../../../components/RadioGroup';
import DailyReportInwardPaddyScreen from './inward/DailyReportInwardPaddyScreen';
import DailyReportsInwardBagsScreen from './inward/DailyReportsInwardBagsScreen';
import DailyReportsInwardOtherItemsScreen from './inward/DailyReportsInwardOtherItemsScreen';
import DailyReportsOutwardBagsScreen from './outward/DailyReportsOutwardBagsScreen';
import DailyReportsOutwardOtherItemsScreen from './outward/DailyReportsOutwardOtherItemsScreen';
import DailyReportsOutwardProductsScreen from './outward/DailyReportsOutwardProductsScreen';
import DailyReportsInternalShipmentsScreen from './DailyReportsInternalShipmentsScreen';
import FinancialYearComponent from '../../../components/FinancialYearComponent';

/* extending isBetween plugin from day js library */
dayjs.extend(isBetween);

const { TabPane } = Tabs;

/* Daily Report Form Field Type */
type DailyReportFormType = {
  /* Shipment Type */
  shipmentType: string;
};

/* React functional component */
const DailyReportsTabLayout = () => {
  /* this state used to store start and end date, which is selected using date range picker or financial year radio option */
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  /* useForm declaration */
  const { watch, control } = useForm<DailyReportFormType>({
    defaultValues: {
      shipmentType: 'inward',
    },
  });

  /* variable used for stored shipment type value */
  const shipmentTypeValue = watch('shipmentType');

  return (
    <div className="tabLayout">
      <form>
        <Col span={12}>
          <FormItem
            label="Shipment Type :"
            customStyle={{ paddingTop: '5px', paddingBottom: 20 }}
            labelColSpan={6}
            inputColSpan={18}
          >
            <RadioGroup
              name="shipmentType"
              defaultValue="inward"
              rhfControllerProps={{
                control,
              }}
              options={[
                { label: 'Inward', value: 'inward' },
                {
                  label: 'Outward',
                  value: 'outward',
                },
                {
                  label: 'Internal',
                  value: 'internal',
                },
              ]}
            />
          </FormItem>
        </Col>

        <FinancialYearComponent selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
      </form>
      {shipmentTypeValue === 'inward' || shipmentTypeValue === 'outward' ? (
        <Tabs
          defaultActiveKey="PaddyOrProducts"
          tabBarGutter={50}
          destroyInactiveTabPane
          style={{ marginTop: '15px' }}
        >
          <TabPane
            tab={shipmentTypeValue === 'inward' ? 'Raw Material (paddy)' : 'Products'}
            key="PaddyOrProducts"
          >
            {shipmentTypeValue === 'inward' ? (
              <DailyReportInwardPaddyScreen
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
              />
            ) : (
              <DailyReportsOutwardProductsScreen
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
              />
            )}
          </TabPane>
          <TabPane tab="Bags" key="bagsKey">
            {shipmentTypeValue === 'inward' ? (
              <DailyReportsInwardBagsScreen
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
              />
            ) : (
              <DailyReportsOutwardBagsScreen
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
              />
            )}
          </TabPane>
          <TabPane tab="Other Items" key="otherItemsKey">
            {shipmentTypeValue === 'inward' ? (
              <DailyReportsInwardOtherItemsScreen
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
              />
            ) : (
              <DailyReportsOutwardOtherItemsScreen
                startDate={selectedDate.startDate}
                endDate={selectedDate.endDate}
              />
            )}
          </TabPane>
        </Tabs>
      ) : (
        <Tabs
          defaultActiveKey="allShipments"
          tabBarGutter={50}
          destroyInactiveTabPane
          style={{ marginTop: '15px' }}
        >
          <TabPane tab="All Shipments" key="allShipments">
            <DailyReportsInternalShipmentsScreen
              startDate={selectedDate.startDate as string}
              endDate={selectedDate.endDate as string}
            />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default DailyReportsTabLayout;
