import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { useApp } from '../../context/AppContext';
import colors from '../../scss/variables.module.scss';

/* management admin sub menu data */
const managementAdminSubMenu = [
  { name: 'Daily Reports', path: '/report/daily' },
  { name: 'Buyer History', path: '/report/buyer/history' },
  { name: 'Farmer/Trader History', path: '/report/seller/history' },
  { name: 'Inward Shipments', path: '/report/shipments/inward/paddy' },
  { name: 'Outward Shipments', path: '/report/shipments/outward/products' },
  { name: 'Internal Shipments', path: '/report/shipment/internal' },
  { name: 'Monitor Shipments', path: '/report/admin/monitor/shipments/inward' },
  // { name: 'Milling', path: '/report/milling' },
  // { name: 'Milling Yield', path: '/milling/yield' },
  // { name: 'Milling Process', path: '/milling/summary' },
  { name: 'Weighbridge', path: '/report/weighbridge' },
];

/* management manager sub menu data */
const managementManagerSubMenu = [
  { name: 'Inward Shipments', path: '/report/shipments/inward/paddy' },
];

/* management staff sub menu data */
const managementStaffSubMenu = [
  { name: 'Inward Shipments', path: '/report/shipments/inward/paddy' },
];

/* React functional component */
const ReportsTabLayout = () => {
  /* extracting location from useLocation hook */
  const location = useLocation();
  /* extracting data from context */
  const { user } = useApp();

  /* depending upon the user role sub menu data to be rendered - initially set to admin role */
  let managementSubMenu = managementAdminSubMenu;
  if (user && user.role === 'manager') {
    managementSubMenu = managementManagerSubMenu;
  }
  if (user && user.role === 'staff') {
    managementSubMenu = managementStaffSubMenu;
  }

  return (
    <div className="subMenu">
      <Menu
        style={{ backgroundColor: colors.colorBlack, display: 'flex' }}
        selectedKeys={[location.pathname]}
      >
        {managementSubMenu.map((item) => {
          return (
            <Menu.Item key={item.path}>
              <Link
                to={item.path}
                style={{ fontWeight: '500', fontSize: 14 }}
                className="hideInPrint"
              >
                {item.name}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
      <Outlet />
    </div>
  );
};

export default ReportsTabLayout;
