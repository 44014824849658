import React, { useRef } from 'react';
import { Button, Col, Descriptions, Divider, Row, Spin, Table } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  GetPurchaseOrderByIdQuery,
  GetPurchaseOrderByIdQueryVariables,
} from '../../graphql/graphql-types';
import { loader } from 'graphql.macro';
import dayjs from 'dayjs';
import { AddressType, PurchaseDataFormType } from '../../utils/types';
import { renderAmount } from '../../utils/helpers';
import colors from '../../scss/variables.module.scss';
import logo from '../../assets/images/sarda_group_logo.png';
import { useReactToPrint } from 'react-to-print';
import SignatureComponent from '../../components/SignatureComponent';

/* loading get Purchase Order By Id  query */
const getPurchaseOrderByIdQuery = loader('../../graphql/queries/getPurchaseOrderByIdQuery.graphql');

/* React functional component */
const PurchaseOrderPrintScreen = () => {
  /* useNavigation destructuring */
  const navigate = useNavigate();

  /* useLocation destructuring */
  const location = useLocation();

  /** const to store state value which we get from useLocation */
  const state = location.state as { poCreated: string };

  // extracting  id from params
  const { id } = useParams();

  /** const to store reference which is used to print content */
  const printRef = useRef(null);

  /** const used to call 'react-to-print' print method*/
  const handlePrint = useReactToPrint({
    /* content for printing */
    content: () => printRef.current,
    /* styling for page during print */
    pageStyle: () => `
    @media print {
      .printRefDiv {
        margin : 0 !important
      }
    }
    `,
  });

  /* fetching purchase order data using id */
  const { data, loading, error } = useQuery<
    GetPurchaseOrderByIdQuery,
    GetPurchaseOrderByIdQueryVariables
  >(getPurchaseOrderByIdQuery, { variables: { id: Number(id) } });

  const purchaseOrderDetails = data && data.purchaseOrders_by_pk;

  /* variable to store the total amount of all items. its sum of individual items amount */
  let amount = 0;

  /* logic to sum the individual items amount */
  if (
    purchaseOrderDetails &&
    Array.isArray(purchaseOrderDetails.purchaseOrderItems) &&
    purchaseOrderDetails.purchaseOrderItems.length > 0
  ) {
    (purchaseOrderDetails.purchaseOrderItems as PurchaseDataFormType[]).forEach((item) => {
      amount += renderAmount(
        state.poCreated === 'otherItems' ? item.quantity || 0 : item.orderedItemsCount || 0,
        item.unitPrice || 0,
        Number(item.gstPercent),
        item.discount || 0,
      ).amountAfterDiscount;
    });
  }

  /** const to store discount applicable on total amount  */
  const purchaseOrderDiscount = purchaseOrderDetails && (purchaseOrderDetails.discount as number);

  const discountCalculation = (amount * ((purchaseOrderDiscount || 0) / 100)).toFixed(2);

  /** const to store vendor data */
  const vendorData = purchaseOrderDetails && purchaseOrderDetails.purchaseOrderItems[0].vendor;

  /* address data object of particular vendorData  */
  const addressData = vendorData && (vendorData.address as AddressType);

  /** const to store mill details */
  const millDetails = data && data.mills && data.mills[0];

  /* show loading indicator on the screen until data get fetch from the server */
  if (loading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (error) {
    return <div className="errorText">{error.message}</div>;
  }

  return (
    <>
      <Col span={1} style={{ marginLeft: 20, marginTop: 10 }}>
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeftOutlined className="goBackButton hideInPrint" />
        </div>
      </Col>
      <div style={{ margin: '30px 125px 40px 125px' }} ref={printRef} className="printRefDiv">
        <Row>
          <Col span={6} style={{ textAlign: 'center', alignSelf: 'center' }}>
            <img src={logo} alt="logo" width={80} height={72} />
          </Col>
          <Col span={13} style={{ textAlign: 'center', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ fontSize: 10 }}>|| Shri Ganeshay Namah ||</span>
              <b style={{ fontSize: 17 }} className="millNameStyle">
                {millDetails && millDetails.company_name
                  ? millDetails.company_name.toUpperCase()
                  : ''}
              </b>
              <span>{(millDetails && millDetails.address) || ''}</span>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <span>
                  <b>GSTIN : </b> 27AAFFB7204E1ZU
                </span>
                <span>
                  <b>FSSAI :</b>
                  {millDetails &&
                  millDetails.name &&
                  millDetails.name.toLowerCase().includes('bhagwati')
                    ? 11517059000099
                    : 11521999000463}
                </span>
              </div>
            </div>
          </Col>
          <Col
            span={24}
            style={{ fontWeight: 'bold', fontSize: 17, marginTop: 25, textAlign: 'center' }}
          >
            Purchase Order
          </Col>
          <Row className="printDescriptions" style={{ marginTop: 30 }}>
            <Col span={14}>
              <Descriptions column={1} size="small">
                <Descriptions.Item label="PO NO.">
                  {purchaseOrderDetails && (purchaseOrderDetails.poNumber as string)}
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={10}>
              <Descriptions column={1} size="small">
                <Descriptions.Item label="Date:">
                  {purchaseOrderDetails &&
                    dayjs(purchaseOrderDetails.created_at).format('DD-MM-YYYY')}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
          <Divider style={{ margin: 5 }} />
          <Col span={14}>
            <Row style={{ fontWeight: 500 }} className="boldContentInPrint">
              Vendor Details
            </Row>
            <Row style={{ marginTop: 5 }}> {vendorData && vendorData.name}</Row>
            <Row>
              {(addressData && addressData.address) ||
              (addressData && addressData.city) ||
              (addressData && addressData.pincode) ||
              (addressData && addressData.state) ? (
                <>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {addressData && addressData.address ? `${addressData.address},` : '-'}
                  </Col>
                  <Col span={24} style={{ marginBottom: 5 }}>
                    {addressData && addressData.city ? `${addressData.city}, ` : '-'}
                    {addressData && addressData.state ? `${addressData.state}, ` : '-'}
                  </Col>
                  <Col span={24} style={{ marginBottom: 10 }}>
                    {addressData && addressData.pincode ? `${addressData.pincode} ` : '-'}
                  </Col>
                </>
              ) : (
                '-'
              )}
            </Row>
            <Descriptions column={1} size="small" className="printDescriptions">
              <Descriptions.Item label="Mobile">
                {vendorData && vendorData.primaryContactMobile}
              </Descriptions.Item>
              <Descriptions.Item label="GST">
                {(vendorData && vendorData.gstin) || 'Not Added'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col span={10}>
            <Row style={{ fontWeight: 500 }} className="boldContentInPrint">
              Ship To
            </Row>
            <Row style={{ marginTop: 5 }}>{(millDetails && millDetails.address) || ''}</Row>
            <Descriptions
              column={1}
              size="small"
              className="printDescriptions"
              style={{ marginTop: 5 }}
            >
              <Descriptions.Item label="Contact Name">
                {purchaseOrderDetails && purchaseOrderDetails.created_by_name}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">
                {purchaseOrderDetails && purchaseOrderDetails.created_by_contact_no}
              </Descriptions.Item>
            </Descriptions>
          </Col>

          <Divider style={{ margin: 10 }} />
          <Col span={24} style={{ fontWeight: 500, marginTop: 10 }} className="boldContentInPrint">
            Item List
          </Col>

          <Col span={24}>
            <Table<PurchaseDataFormType>
              dataSource={
                purchaseOrderDetails &&
                Array.isArray(purchaseOrderDetails.purchaseOrderItems) &&
                purchaseOrderDetails.purchaseOrderItems.length > 0
                  ? (purchaseOrderDetails.purchaseOrderItems as PurchaseDataFormType[])
                  : undefined
              }
              bordered
              pagination={false}
              className="tableStyle historyTable"
              rowKey={(record) => record.id}
              size="small"
            >
              <Table.Column<PurchaseDataFormType>
                title="Item Description"
                dataIndex="itemName"
                key="itemName"
                render={(text, record) => {
                  return record.item.name;
                }}
              />
              {state.poCreated === 'otherItems' ? (
                <>
                  <Table.Column<PurchaseDataFormType>
                    title="Broker"
                    dataIndex="broker"
                    width={200}
                    key="broker"
                    render={(text, record) => {
                      return record.broker ? record.broker.name : '-';
                    }}
                  />
                  <Table.Column<PurchaseDataFormType>
                    title="Quantity"
                    dataIndex="quantity"
                    key="quantity"
                    render={(val, record) => record.quantity || '-'}
                  />
                </>
              ) : (
                <Table.Column
                  title="No. of Bags"
                  dataIndex="orderedItemsCount"
                  key="orderedItemsCount"
                />
              )}
              <Table.Column<PurchaseDataFormType>
                title="Unit Price (in Rs.)"
                dataIndex="unitPrice"
                key="unitPrice"
              />
              <Table.Column<PurchaseDataFormType>
                title="GST (%)"
                dataIndex="gstPercent"
                key="gstPercent"
                render={(value, record) => (record.gstPercent as string) || '-'}
              />
              <Table.Column<PurchaseDataFormType>
                title="Discount (%)"
                dataIndex="discount"
                key="discount"
                render={(val, record) => record.discount || '-'}
              />
              <Table.Column<PurchaseDataFormType>
                title="Amount (in Rs.)"
                dataIndex="amount"
                key="amount"
                render={(text, record) => {
                  /** const to store discounted amount and final amount after discount */
                  const totalAmountAndDiscount = renderAmount(
                    state.poCreated === 'otherItems'
                      ? record.quantity || 0
                      : record.orderedItemsCount || 0,
                    record.unitPrice || 0,
                    Number(record.gstPercent),
                    record.discount || 0,
                  );

                  return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      {totalAmountAndDiscount.amountAfterDiscount.toFixed(2) || '-'}
                      {totalAmountAndDiscount.discountedAmount > 0 ? (
                        <i style={{ fontSize: 12, color: colors.placeHolderTextColor }}>
                          discount applied (Rs.) :
                          <span style={{ fontWeight: 500 }}>
                            {totalAmountAndDiscount.discountedAmount.toFixed(2) || '-'}
                          </span>
                        </i>
                      ) : null}
                    </div>
                  );
                }}
              />
              <Table.Column<PurchaseDataFormType>
                title="Remarks"
                dataIndex="remarks"
                key="remarks"
                render={(text, record) => record.remarks || '-'}
              />
            </Table>
          </Col>
          <Col offset={14} span={10}>
            <Descriptions column={1} size="small" className="printDescriptions" bordered>
              <Descriptions.Item label="Sub Total (in Rs.)">
                {amount > 0 ? amount.toFixed(2) : <>-</>}
              </Descriptions.Item>
              <Descriptions.Item
                label={`Total Discount ${
                  purchaseOrderDiscount && purchaseOrderDiscount > 0
                    ? `(${purchaseOrderDiscount}%)`
                    : ''
                }`}
              >
                {purchaseOrderDiscount && purchaseOrderDiscount > 0 ? discountCalculation : <> -</>}
              </Descriptions.Item>
              <Descriptions.Item label={`Total Amount (in Rs.)`}>
                {amount > 0 ? (
                  <>{(amount - Number(discountCalculation)).toFixed(2)} Estimated</>
                ) : (
                  <> -</>
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <SignatureComponent
          millName={millDetails && millDetails.name ? millDetails.name.toUpperCase() : ''}
        />
      </div>
      <Row>
        <Col offset={22}>
          <Button style={{ marginBottom: 20 }} type="primary" onClick={() => handlePrint()}>
            Print
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default PurchaseOrderPrintScreen;
