import React, { useState } from 'react';
import { Table } from 'antd';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { FilterValue } from 'antd/lib/table/interface';
import { ItemsShipment } from '../../../../utils/types';
import FormItem from '../../../../components/FormItem';
import RadioGroup from '../../../../components/RadioGroup';
import ColumnSearchComponent from '../../../../components/ColumnSearchComponent';
import styles from './../../inwardShipments/InwardShipmentReportTable.module.scss';

/* inward form field type*/
type InwardFormFieldType = {
  /* status */
  status: string;
};

/* inward shipment summary table prop type */
type InwardShipmentSummaryTablePropType = {
  /* prop type used to get data for the table */
  tableData: Array<ItemsShipment>;
};

/* React functional component */
const InwardShipmentSummaryTable = ({
  tableData,
}: InwardShipmentSummaryTablePropType): JSX.Element => {
  /* filterType state used to stored filter name that used for filter table data */
  const [filterType, setFilterType] = useState<string>('rawMaterial');

  /* filterData state used stored filtered data of table */
  const [filteredData, setFilteredData] = useState<Record<string, FilterValue | null> | null>(null);

  /* UseForm Declaration */
  const { control } = useForm<InwardFormFieldType>({
    defaultValues: {
      status: 'rawMaterial',
    },
  });

  /*  function used to get loading location  */
  const getLoadingLocation = (record: ItemsShipment): string => {
    if (record.materialReturned === true) return 'Returned';
    if (record.destination === 'mill') return 'Mill';
    return record.godown ? record.godown.name : '-';
  };

  /* Function is used to filter data dependents on filter by status radio buttons */
  const filterFunction = () => {
    if (filterType === 'rawMaterial') {
      return tableData
        .filter((item) => item.rawMaterial !== null)
        .map((shipment) => (shipment.rawMaterial ? shipment.rawMaterial.name : ''))
        .filter((value, index, self) => value && self.indexOf(value) === index)
        .map((item) => ({ text: item, value: item }));
    }

    if (filterType === 'materialReturned') {
      return [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ];
    }

    if (filterType === 'destination') {
      const filterData = tableData
        .filter((item) => item.destination === 'godown')
        .map((ele) => {
          return {
            text: ele.godown ? ele.godown.name : '',
            value: ele.godown ? ele.godown.name : '',
          };
        });
      /*  check if mill present or not in destination  */
      let isMillPresent = false;
      tableData.filter((item) => {
        if (item.destination === 'mill') {
          isMillPresent = true;
        }
        return undefined;
      });
      return isMillPresent ? [...filterData, { text: 'Mill', value: 'mill' }] : [...filterData];
    }

    return undefined;
  };

  return (
    <>
      <h3 style={{ marginTop: '10px', marginBottom: '0px' }}> Orders</h3>
      <FormItem label="Filter based on" labelColSpan={21} customStyle={{ paddingTop: 10 }}>
        <RadioGroup
          name="status"
          rhfControllerProps={{
            control,
          }}
          defaultValue={'rawMaterial'}
          options={[
            { label: 'Raw Material', value: 'rawMaterial' },
            {
              label: 'Material Returned',
              value: 'materialReturned',
            },
            {
              label: 'Destination',
              value: 'destination',
            },
            {
              label: 'Farmer/Trader (Purchase) Name',
              value: 'sellerName',
            },
          ]}
          onChange={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setFilterType(e.target.value);
            /* when filter is not apply on table then set null to state data */
            setFilteredData(null);
          }}
        />
      </FormItem>
      <Table<ItemsShipment>
        dataSource={tableData}
        onChange={(pagination, filters) => {
          /* when filter apply on table then set filters to setFilteredData state */
          setFilteredData(filters);
        }}
        rowKey="id"
        className="tableStyle"
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
      >
        {filterType === 'sellerName' ? (
          <Table.Column<ItemsShipment>
            title="Details"
            dataIndex="details"
            key="details"
            onFilter={(value, record) => {
              return record.seller && record.seller.name
                ? record.seller.name
                    .toString()
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
                : false;
            }}
            filteredValue={(filteredData && filteredData.details) || null}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent
                filterBy="farmer/trader (Purchase) name"
                filterDropDownProp={filterDropDownProp}
              />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(text, record) => {
              return (
                <>
                  <p>Raw Material:{record.rawMaterial && record.rawMaterial.name}</p>
                  <p>Paddy Grade: {record.paddyGrade}</p>
                  <p>Farmer/Trader (Purchase): {record.seller && record.seller.name}</p>
                  <p>Unloading location: {getLoadingLocation(record)}</p>
                </>
              );
            }}
          />
        ) : (
          <Table.Column<ItemsShipment>
            title="Details"
            dataIndex="details"
            key="details"
            render={(text, record) => {
              const { rawMaterial, paddyGrade, bagsCount, seller } = record;
              return (
                <>
                  <div className={styles.labelStyle}>Raw Material</div>
                  <>{rawMaterial ? rawMaterial.name : '-'}</>
                  <div className={styles.labelStyle}>Paddy Grade</div>
                  <>{paddyGrade ? paddyGrade : '-'}</>
                  <div className={styles.labelStyle}>Farmer/Trader (Purchase)</div>
                  <>{seller ? seller.name : '-'}</>
                  <div className={styles.labelStyle}>Unloading location</div>
                  <>{getLoadingLocation(record)}</>
                  <div className={styles.labelStyle}>No. Of Bags</div>
                  <>{bagsCount ? bagsCount : '-'}</>
                </>
              );
            }}
            filterIcon={(filtered) => {
              return <FilterFilled className={filtered ? 'searchIcon' : ''} />;
            }}
            filteredValue={(filteredData && filteredData.details) || null}
            filters={filterFunction() || []}
            onFilter={(value, record) => {
              if (
                filterType === 'rawMaterial' &&
                record.rawMaterial &&
                record.rawMaterial.name === value
              ) {
                return true;
              }
              if (filterType === 'materialReturned' && record.materialReturned === value) {
                return true;
              }
              if (
                record.destination === 'godown' &&
                record.godown &&
                record.godown.name === value &&
                filterType === 'destination'
              ) {
                return true;
              } else if (record.destination === value) {
                return true;
              }
              return false;
            }}
          ></Table.Column>
        )}
        <Table.Column<ItemsShipment>
          title={
            <>
              Cost Of Empty Bag <br />
              (per unit)
            </>
          }
          dataIndex="emptyBagCost"
          key="emptyBagCost"
          align="center"
          render={(text, record) => {
            if (record.materialReturned) return '-';
            if (record.emptyBagsReturned) {
              return (
                <>
                  Empty bags returned <br /> {record.emptyBagCost}
                </>
              );
            }
            return 'Empty bags not returned.';
          }}
        ></Table.Column>
        <Table.Column<ItemsShipment>
          title={
            <>
              Empty Bag Weight
              <br />
              (in kgs)
            </>
          }
          dataIndex="emptyBagsWtKg"
          key="emptyBagsWtKg"
          align="center"
          render={(text, record) => {
            if (record.materialReturned) return '-';
            if ((record.emptyBagsWtKg && record.userEmptyBagWtUnit) || record.emptyBagsWtKg === 0) {
              return (
                <>
                  <>{record.emptyBagsWtKg} </>
                  {record.userEmptyBagWtUnit === 'kgPerQuintal' ? 'per Quintal' : 'per Bag'}
                </>
              );
            }
            return '-';
          }}
        ></Table.Column>
        <Table.Column<ItemsShipment>
          title={
            <>
              Net Material Weight
              <br />
              (kg/bag)
            </>
          }
          align="center"
          dataIndex="netMaterialWtPerBagKg"
          key="netMaterialWtPerBagKg"
          render={(text, record) => {
            if (record.materialReturned) return '-';
            if (record.netMaterialWtPerBagKg || record.netMaterialWtPerBagKg === 0) {
              return record.netMaterialWtPerBagKg.toFixed(2);
            }
            return '-';
          }}
        ></Table.Column>
        <Table.Column<ItemsShipment>
          title={
            <>
              Total Net Material Weight
              <br />
              (in quintals)
            </>
          }
          dataIndex="totalNetMaterialWtPerBagKg"
          key="totalNetMaterialWtPerBagKg"
          align="center"
          render={(text, record) => {
            if (record.materialReturned) return '-';
            if (record.netMaterialWtPerBagKg && record.bagsCount) {
              return ((record.bagsCount * record.netMaterialWtPerBagKg) / 100).toFixed(2);
            }
            return '-';
          }}
        ></Table.Column>
        <Table.Column<ItemsShipment>
          title={
            <>
              Price Of Paddy
              <br />
              (Rs/quintal)
            </>
          }
          align="center"
          dataIndex="pricePerKg"
          key="pricePerKg"
          render={(text, record) => {
            if (record.materialReturned) return '-';
            if (record.pricePerKg || record.pricePerKg === 0) {
              const perQuintal = record.pricePerKg * 100;
              return perQuintal.toFixed(2);
            }
            return '-';
          }}
        ></Table.Column>
        <Table.Column<ItemsShipment>
          title={
            <>
              Brokerage
              <br />
              (Rs/quintal)
            </>
          }
          align="center"
          dataIndex="brokeragePricePerQuintal"
          key="brokeragePricePerQuintal"
          render={(text, record) => {
            if (record.materialReturned) return '-';
            return record.brokeragePerQuintal || record.brokeragePerQuintal === 0
              ? record.brokeragePerQuintal
              : '-';
          }}
        ></Table.Column>
        <Table.Column<ItemsShipment>
          title="Sub Total (Rs.)"
          dataIndex="subtotal"
          key="subtotal"
          align="center"
          render={(text, record) => {
            if (record.materialReturned) return '-';
            if (record.materialReturned === false) {
              const {
                bagsCount,
                netMaterialWtPerBagKg,
                emptyBagCost,
                pricePerKg,
                brokeragePerQuintal,
              } = record;

              /* this const used to store number og bags in shipment */
              const numberOfBags = bagsCount ? bagsCount : 0;

              /* calculated total net material weight */
              const totalNetMaterialWeight =
                numberOfBags * (netMaterialWtPerBagKg ? netMaterialWtPerBagKg : 0);

              /* calculated total net material weight  in Qt */
              const totalNetMaterialWeightInQt = parseFloat(
                (totalNetMaterialWeight / 100).toFixed(2),
              );

              /* calculated price of total weight */
              const priceOfTotalWt =
                totalNetMaterialWeightInQt * ((pricePerKg ? pricePerKg : 0) * 100);

              /* calculated price of total empty bag weight */
              const priceOfTotalEmptyBagWt = (emptyBagCost ? emptyBagCost : 0) * numberOfBags;

              /* calculated price of brokerage */
              const priceOfBrokerage =
                (brokeragePerQuintal ? brokeragePerQuintal : 0) * totalNetMaterialWeightInQt;

              /* calculated sub total */
              const subtotal = parseFloat(
                (priceOfTotalWt + priceOfBrokerage - priceOfTotalEmptyBagWt).toFixed(2),
              );

              return subtotal.toFixed(2);
            }
            return '-';
          }}
        ></Table.Column>
      </Table>
    </>
  );
};

export default InwardShipmentSummaryTable;
