import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Divider, message, Popconfirm, Table } from 'antd';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation, useQuery, Reference, useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import RequiredMessage from '../../../components/RequiredMessage';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import { formItemStyleProps, inputComponentCommonStyle } from '../../../utils/globals';
import {
  GetInwardShipmentItemsQuery,
  GetInwardShipmentItemsQueryVariables,
  GetBhagwatiMillIdQuery,
  GetBhagwatiMillIdQueryVariables,
  GetAllMaterialUnloadingQuery,
  GetAllMaterialUnloadingQueryVariables,
  UpdateInwardShipmentItemMutation,
  UpdateInwardShipmentItemMutationVariables,
  CreateInwardShipmentItemMutation,
  CreateInwardShipmentItemMutationVariables,
  DeleteInwardShipmentItemMutation,
  DeleteInwardShipmentItemMutationVariables,
  FinishInwardShipmentUnloadingMutation,
  FinishInwardShipmentUnloadingMutationVariables,
  Enum_Item_Category_Enum,
  InwardShipments,
  InwardShipmentItems,
} from '../../../graphql/graphql-types';
import InputNumber from '../../../components/InputNumber';
import { logger } from '../../../utils/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { ExclamationCircleFilled, PlusOutlined, WarningFilled } from '@ant-design/icons';
import colors from '../../../scss/variables.module.scss';
import Select from '../../../components/Select';
import RadioGroup from '../../../components/RadioGroup';
import Switch from '../../../components/Switch';
import QuickAddVendorOrBuyerForm from '../../../components/QuickAddVendorOrBuyerForm';
import MaterialUnloadingPrintScreen from './MaterialUnloadingPrintScreen';
import { InwardShipmentItemsTableType } from '../../../utils/types';

/* create or update inward shipment item form type */
type CreateOrUpdateInwardShipmentItemFormType = Pick<InwardShipments, 'grn'> &
  Pick<
    InwardShipmentItems,
    | 'rmSellerId'
    | 'rawMaterialId'
    | 'paddyGrade'
    | 'bagsCount'
    | 'materialReturnedReason'
    | 'godownId'
    | 'destination'
    | 'isBackFromMaintenance'
  > & {
    /* prop type used to store material return */
    materialReturned: boolean | null;
    /* prop type used to store empty bag returned */
    emptyBagsReturned: boolean | null;
    /* prop type used to store 'id' of the shipment whose vehicle number get selected from 'select vehicle' dropdown */
    shipmentIdFromVehicleNumber: number | null;
  };

/* loading get bhagwati mill id query */
const getBhagwatiMillIdQuery = loader('../../../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* loading get inward shipment items query */
const getInwardShipmentItemsQuery = loader(
  '../../../graphql/queries/getInwardShipmentItemsQuery.graphql',
);

/* loading get all material unloading query */
const getAllMaterialUnloadingQuery = loader(
  '../../../graphql/queries/getAllMaterialUnloadingQuery.graphql',
);

/* loading create inward shipment item mutation  */
const createInwardShipmentItemMutation = loader(
  '../../../graphql/mutations/createInwardShipmentItemMutation.graphql',
);

/* loading update inward shipment item mutation  */
const updateInwardShipmentItemMutation = loader(
  '../../../graphql/mutations/updateInwardShipmentItemMutation.graphql',
);

/* loading delete inward shipment item mutation  */
const deleteInwardShipmentItemMutation = loader(
  '../../../graphql/mutations/deleteInwardShipmentItemMutation.graphql',
);

/* loading finish inward shipment item mutation  */
const finishInwardShipmentItemMutation = loader(
  '../../../graphql/mutations/finishInwardShipmentUnloadingMutation.graphql',
);

/* loading get unloaded inward shipment query */
const getUnloadedInwardShipmentQuery = loader(
  '../../../graphql/queries/getUnloadedInwardShipmentsQuery.graphql',
);

/* validations required for useForm */
const schema = yup.object({
  rmSellerId: yup.number().nullable().required('Please select farmer/trader name and try again.'),
  rawMaterialId: yup.number().nullable().required('Please select material type and try again.'),
  paddyGrade: yup.string().nullable().required('Please select paddy grade and try again.'),
  bagsCount: yup
    .number()
    .nullable()
    .required('Please enter number of bags and try again.')
    .integer('Please enter valid number.')
    .min(0, 'Please enter valid number'),
  destination: yup
    .string()
    .nullable()
    .when(['materialReturned'], {
      is: (materialReturned: boolean) => !materialReturned,
      then: yup
        .string()
        .nullable()
        .required('Please select material unload destination and try again.'),
    }),
  godownId: yup
    .number()
    .nullable()
    .when(['destination', 'materialReturned'], {
      is: (destination: string, materialReturned: boolean) =>
        destination === 'godown' && !materialReturned,
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
});

/* const used to store useForm's default values, so that we can use this to assign initial default values & to reset form using this default values */
const formDefaultValues = {
  shipmentIdFromVehicleNumber: null,
  rmSellerId: null,
  rawMaterialId: null,
  paddyGrade: null,
  bagsCount: null,
  materialReturned: false,
  materialReturnedReason: null,
  emptyBagsReturned: false,
  godownId: null,
  destination: null,
};

/* React functional component */
const MaterialUnloadingScreen: React.FC = () => {
  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* extracting params from useParams hook */
  const params = useParams();

  /* this state used to manage Add farmer/trader form modal visibility */
  const [isAddFarmerOrTraderModalVisible, setIsAddFarmerOrTraderModalVisible] =
    useState<boolean>(false);

  /* this state used to show loading indicator on submit or update button */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* state used to store id of the shipment item whose edit button is clicked which is then used to update that shipment item data */
  const [shipmentItemIdToEdit, setShipmentItemIdToEdit] = useState<number | null>(null);

  /* state used to store id of the shipment whose delete button is clicked which is then used to show loading indicator on delete button while deleting a shipment item */
  const [shipmentItemIdToDltAndShowLoading, setShipmentItemIdToDltAndShowLoading] = useState<
    number | null
  >(null);

  /* this state used to show loading indicator on finish shipment button */
  const [isShipmentFinishBtnLoading, setIsShipmentFinishBtnLoading] = useState<boolean>(false);

  /* this state used to manage material unloading's print modal visibility */
  const [isPrintModalVisible, setIsPrintModalVisible] = useState<boolean>(false);

  /* create inward shipment item mutation */
  const [createInwardShipmentItem] = useMutation<
    CreateInwardShipmentItemMutation,
    CreateInwardShipmentItemMutationVariables
  >(createInwardShipmentItemMutation);

  /* update inward shipment item mutation */
  const [updateInwardShipmentItem] = useMutation<
    UpdateInwardShipmentItemMutation,
    UpdateInwardShipmentItemMutationVariables
  >(updateInwardShipmentItemMutation);

  /* delete inward shipment mutation */
  const [deleteInwardShipmentItem] = useMutation<
    DeleteInwardShipmentItemMutation,
    DeleteInwardShipmentItemMutationVariables
  >(deleteInwardShipmentItemMutation);

  /* finish inward shipment mutation */
  const [finishInwardShipment] = useMutation<
    FinishInwardShipmentUnloadingMutation,
    FinishInwardShipmentUnloadingMutationVariables
  >(finishInwardShipmentItemMutation);

  /* get bhagwati mill id query used to show Mill name */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* get all material unloading query used to get all open inward shipments, vendors , raw materials and godowns data.
  so that we can show them as list on respective select component's dropdown */
  const {
    data: getAllMaterialUnloadingData,
    error: getAllMaterialUnloadingDataError,
    loading: getAllMaterialUnloadingDataLoader,
    refetch: refetchingGetAllMaterialUnloadingData,
  } = useQuery<GetAllMaterialUnloadingQuery, GetAllMaterialUnloadingQueryVariables>(
    getAllMaterialUnloadingQuery,
  );

  /* get all material unloading query used to show inward shipment items in table */
  const [
    getInwardShipmentItems,
    {
      data: getInwardShipmentItemsData,
      error: getInwardShipmentItemsDataError,
      loading: getInwardShipmentItemsDataLoader,
    },
  ] = useLazyQuery<GetInwardShipmentItemsQuery, GetInwardShipmentItemsQueryVariables>(
    getInwardShipmentItemsQuery,
  );

  /** const to check inwardShipmentItemsData present or not  */
  const isInswardShipmentItemsData =
    getInwardShipmentItemsData &&
    getInwardShipmentItemsData.getInwardShipmentItems &&
    Array.isArray(getInwardShipmentItemsData.getInwardShipmentItems) &&
    getInwardShipmentItemsData.getInwardShipmentItems.length > 0;

  /** const to check whether user added shipments items or not */
  const isShipmentItemsPresent = useMemo(() => {
    if (isInswardShipmentItemsData) {
      return true;
    }
    return false;
  }, [isInswardShipmentItemsData]);

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm<CreateOrUpdateInwardShipmentItemFormType>({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* this useEffect used to assign initial values to 'vehicle number' and 'grn' field */
  useEffect(() => {
    if (params.id && getAllMaterialUnloadingData) {
      /* this const used to store the shipment, which is selected to unload from 'create shipment screen' or by 'selecting vehicle number from 'material unloading' screen' */
      const selectedShipmentDataToUnload = getAllMaterialUnloadingData.getOpenInwardShipments.find(
        (item) => item.id === Number(params.id),
      );

      /* assign selected shipment's 'id' and 'grn' as a default values to 'shipmentIdFromVehicleNumber' & 'grn' fields */
      if (selectedShipmentDataToUnload) {
        /* assign shipment id as a default value to field 'shipmentIdFromVehicleNumber' */
        setValue('shipmentIdFromVehicleNumber', selectedShipmentDataToUnload.id);

        /* assign selected shipment's 'grn' number as a default grn number to 'grn' form field */
        setValue('grn', selectedShipmentDataToUnload.grn);

        /* condition to check whether seller is present or not. If seller is present then setValue of seller */
        if (
          isInswardShipmentItemsData &&
          getInwardShipmentItemsData.getInwardShipmentItems[0].seller
        ) {
          setValue('rmSellerId', getInwardShipmentItemsData.getInwardShipmentItems[0].seller.id);
        }

        /* get shipment items for selected shipment id */
        getInwardShipmentItems({
          variables: { inwardShipmentId: selectedShipmentDataToUnload.id },
        }).catch((err: ApolloError) => {
          logger(err);
        });
      }
    }

    /* condition to check shipments items present or not, if shipments are not present then setValue of 'rmSellerId' to null */
    if (!isShipmentItemsPresent) {
      setValue('rmSellerId', null);
    }
  }, [
    getAllMaterialUnloadingData,
    getInwardShipmentItems,
    getInwardShipmentItemsData,
    isInswardShipmentItemsData,
    isShipmentItemsPresent,
    params.id,
    setValue,
  ]);

  /* show error text on the screen. if it has any error while loading data from the server */
  if (
    getBhagwatiMillIdQueryError ||
    getAllMaterialUnloadingDataError ||
    getInwardShipmentItemsDataError
  ) {
    let errorMessage;
    if (getBhagwatiMillIdQueryError) {
      errorMessage = getBhagwatiMillIdQueryError.message + 'bhag';
    } else if (getAllMaterialUnloadingDataError) {
      errorMessage = getAllMaterialUnloadingDataError.message + 'getAllMaterial';
    } else if (getInwardShipmentItemsDataError) {
      errorMessage = getInwardShipmentItemsDataError.message + 'getInwardShipmentItems';
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* function to handle delete inward shipment item */
  const deleteInwardShipmentItemFunc = (shipmentItemId: number) => {
    setShipmentItemIdToDltAndShowLoading(shipmentItemId);
    deleteInwardShipmentItem({
      variables: {
        id: shipmentItemId,
      },
      /* after deleting a shipment item, the update function is used to update the cache and display an updated shipment items array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a shipment item that was just removed. */
        const deletedDataId = deleteData?.deleteInwardShipmentItem?.id;

        cache.modify({
          fields: {
            inwardShipmentItems(existingInwardShipmentItems: Array<Reference>, { readField }) {
              if (deletedDataId) {
                return existingInwardShipmentItems.filter(
                  (shipmentItemRef) => deletedDataId !== readField('id', shipmentItemRef),
                );
              }
              return existingInwardShipmentItems;
            },
          },
        });
      },
    })
      .then(() => {
        setShipmentItemIdToDltAndShowLoading(null);

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment item has been deleted successfully.');
      })
      .catch((error: ApolloError) => {
        setShipmentItemIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  /* function to handle finish inward shipment item */
  const finishInwardShipmentFunc = () => {
    /* this const used to store id of the shipment which is selected to unload */
    const shipmentIdSelectedToFinishUnload = watch('shipmentIdFromVehicleNumber');

    if (shipmentIdSelectedToFinishUnload) {
      setIsPrintModalVisible(true);
      setIsShipmentFinishBtnLoading(true);
      finishInwardShipment({
        variables: {
          id: shipmentIdSelectedToFinishUnload,
        },
        /* refetching get all material unloading & get unloaded shipment queries to get updated shipment data, after successfully unloading */
        refetchQueries: [{ query: getUnloadedInwardShipmentQuery }],
      })
        .then(() => {
          setIsShipmentFinishBtnLoading(false);
          reset({ ...formDefaultValues });
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been successfully unloaded.');
        })
        .catch((error: ApolloError) => {
          setIsShipmentFinishBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  };

  /* function used to handle form submit */
  const onSubmit = (data: CreateOrUpdateInwardShipmentItemFormType) => {
    setIsSubmitOrUpdateBtnLoading(true);
    /* destructing form data */
    const {
      rmSellerId,
      rawMaterialId,
      paddyGrade,
      bagsCount,
      materialReturned,
      materialReturnedReason,
      godownId,
      destination,
      emptyBagsReturned,
    } = data;
    if (params.id && getBhagwatiMillIdData && getBhagwatiMillIdData.mills.length > 0) {
      /* this const used to store mutation variables which are common in both 'create inward shipment item' and 'update inward shipment item' mutation */
      const commonMutationVariables = {
        inwardShipmentId: Number(params.id),
        rmSellerId: rmSellerId as number,
        rawMaterialId: rawMaterialId as number,
        paddyGrade: paddyGrade || null,
        bagsCount: bagsCount as number,
        materialReturned: materialReturned !== null ? materialReturned : false,
        materialReturnedReason: materialReturnedReason || null,
        godownId: godownId || null,
        destination: destination || null,
        millId: destination === 'mill' ? getBhagwatiMillIdData.mills[0].id : null,
        emptyBagsReturned: emptyBagsReturned !== null ? emptyBagsReturned : false,
      };

      /* if user has clicked on 'edit' button then execute update inward shipment item mutation  */
      if (shipmentItemIdToEdit) {
        updateInwardShipmentItem({
          variables: {
            id: shipmentItemIdToEdit,
            ...commonMutationVariables,
          },
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Shipment item has been updated successfully.');

            setValue('rawMaterialId', null);
            setValue('paddyGrade', null);
            setValue('bagsCount', null);
            setValue('materialReturned', false);
            setValue('materialReturnedReason', null);
            setValue('emptyBagsReturned', false);
            setValue('godownId', null);
            setValue('destination', null);

            setShipmentItemIdToEdit(null);
            setIsSubmitOrUpdateBtnLoading(false);
          })
          .catch((error: ApolloError) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(error.message);
            setIsSubmitOrUpdateBtnLoading(false);
            logger(error);
          });
      } else {
        createInwardShipmentItem({
          variables: commonMutationVariables,
          /* refetch get inward shipment items query to get updated shipment items array after adding new shipment item */
          refetchQueries: [
            {
              query: getInwardShipmentItemsQuery,
              variables: { inwardShipmentId: Number(params.id) },
            },
          ],
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success('Shipment item has been successfully created.');
            /* keep 'shipmentIdFromVehicleNumber' & 'grn' form field values as it is.
           and reset remaining form field with its default form values. */

            reset({
              ...formDefaultValues,
              shipmentIdFromVehicleNumber: watch('shipmentIdFromVehicleNumber'),
              grn: watch('grn'),
              rmSellerId: watch('rmSellerId'),
            });
            setShipmentItemIdToEdit(null);
            setIsSubmitOrUpdateBtnLoading(false);
          })
          .catch((error: ApolloError) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(error.message);
            setIsSubmitOrUpdateBtnLoading(false);
            logger(error);
          });
      }
    }
  };

  return (
    <>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <RequiredMessage />
        <FormItem label="Select Vehicle" isRequired requiredMark="after" {...formItemStyleProps}>
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select vehicle number"
            name="shipmentIdFromVehicleNumber"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              loading: getAllMaterialUnloadingDataLoader,
              optionFilterProp: 'children',
            }}
            options={
              getAllMaterialUnloadingData
                ? getAllMaterialUnloadingData.getOpenInwardShipments.map((item) => ({
                    value: item.id,
                    label: item.vehicleNumber as string,
                  }))
                : []
            }
            onChange={(rhfOnChange, value: number) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              rhfOnChange(value);
              if (value && getAllMaterialUnloadingData) {
                /* this const used to store shipment, which is selected to unload using it's 'vehicle number' */
                const selectedShipment = getAllMaterialUnloadingData.getOpenInwardShipments.find(
                  (item) => item.id === value,
                );

                /* navigate to that shipment id, which is selected to unload */
                navigate(`../unload/${value}`);

                if (selectedShipment) {
                  /* call 'getInwardShipmentItems'function, to get shipment items for selected shipment id,
                  which then used to display this items into table */
                  void getInwardShipmentItems({
                    variables: { inwardShipmentId: selectedShipment.id },
                  });

                  /* set grn number to shipment's grn number */
                  setValue('grn', selectedShipment.grn);
                }

                /* if user has clicked on 'edit' button, then form fields has values which is required to 'edit'.
                but then user selected another shipment to unload then form get reset to default values */
                if (shipmentItemIdToEdit) {
                  /* reset form */
                  reset();
                  /* shipment item, which is selected to 'edit' set to 'null', because user has selected another shipment to unload */
                  setShipmentItemIdToEdit(null);
                }
              }
            }}
          />
        </FormItem>

        {watch('shipmentIdFromVehicleNumber') ? (
          <>
            <FormItem label="GRN" {...formItemStyleProps}>
              <Input
                customStyles={inputComponentCommonStyle}
                name="grn"
                placeholder="Please enter grn number"
                rhfControllerProps={{
                  control,
                }}
                inputProps={{ disabled: true }}
              />
            </FormItem>
            <FormItem
              label="Farmer/Trader (Purchase) Name"
              isRequired
              requiredMark="after"
              errorText={errors && errors.rmSellerId ? errors.rmSellerId.message : undefined}
              {...formItemStyleProps}
            >
              <Select
                customStyles={inputComponentCommonStyle}
                placeholder="Please select farmer/trader purchase name"
                name="rmSellerId"
                rhfControllerProps={{
                  control,
                }}
                selectProps={{
                  showSearch: true,
                  optionFilterProp: 'children',
                  disabled: isShipmentItemsPresent ? true : false,
                  dropdownRender: (menu) => {
                    return (
                      <>
                        {menu}
                        <Divider style={{ marginTop: 5, marginBottom: 0 }} />
                        <Button
                          className="selectDropDownButton"
                          onClick={() => {
                            setIsAddFarmerOrTraderModalVisible(true);
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add farmer/trader
                        </Button>
                      </>
                    );
                  },
                }}
                options={
                  getAllMaterialUnloadingData
                    ? getAllMaterialUnloadingData.getAllRmSellers.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    : []
                }
              />
            </FormItem>
            <FormItem
              label="Material type"
              isRequired
              requiredMark="after"
              errorText={errors && errors.rawMaterialId ? errors.rawMaterialId.message : undefined}
              {...formItemStyleProps}
            >
              <Select
                customStyles={inputComponentCommonStyle}
                placeholder="Please select material type"
                name="rawMaterialId"
                rhfControllerProps={{
                  control,
                }}
                selectProps={{ showSearch: true, optionFilterProp: 'children' }}
                options={
                  getAllMaterialUnloadingData
                    ? getAllMaterialUnloadingData.getAllRawMaterials.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    : []
                }
              />
            </FormItem>
            <FormItem
              label="Paddy grade"
              isRequired
              requiredMark="after"
              errorText={errors && errors.paddyGrade ? errors.paddyGrade.message : undefined}
              {...formItemStyleProps}
            >
              <RadioGroup
                name="paddyGrade"
                rhfControllerProps={{
                  control,
                }}
                options={[
                  { label: '1 (One)', value: '1 (One)' },
                  {
                    label: '2 (Two)',
                    value: '2 (Two)',
                  },
                  {
                    label: '3 (Three)',
                    value: '3 (Three)',
                  },
                ]}
              />
            </FormItem>
            <FormItem
              label="Mark paddy as returned"
              errorText={
                errors && errors.materialReturned ? errors.materialReturned.message : undefined
              }
              {...formItemStyleProps}
            >
              <>
                <Switch
                  name="materialReturned"
                  rhfControllerProps={{
                    control,
                  }}
                  onChange={(rhfOnChange, value: boolean) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    rhfOnChange(value);
                    /* when a shipment item is returned to the user, it is not unloaded to a godown or mill
                    and because of this the destination, godown Id, and milling Process Id fields are setting to null. */
                    if (value) {
                      /* if destination has selected, then set it to null */
                      if (watch('destination')) {
                        setValue('destination', null);
                      }
                      /* if godownId has selected, then set it to null */
                      if (watch('godownId')) {
                        setValue('godownId', null);
                      }
                    }
                  }}
                />
              </>
            </FormItem>
            {!watch('materialReturned') ? (
              <>
                <FormItem
                  label="Material unloaded at"
                  isRequired
                  requiredMark="after"
                  errorText={errors && errors.destination ? errors.destination.message : undefined}
                  {...formItemStyleProps}
                >
                  <RadioGroup
                    name="destination"
                    rhfControllerProps={{
                      control,
                    }}
                    defaultValue={null}
                    options={[
                      { label: 'Godown', value: 'godown' },
                      {
                        label: 'Mill',
                        value: 'mill',
                      },
                    ]}
                    onChange={(e) => {
                      /* on selection of 'destination', set 'destination godown id ' or 'destination mill id ' as a null */
                      if (e.target.value === 'mill' && getBhagwatiMillIdData) {
                        /* if user selected 'destination' as 'mill then set 'godownId' to null */
                        setValue('godownId', null);
                      }
                    }}
                  />
                </FormItem>
                {watch('destination') === 'godown' ? (
                  <FormItem
                    label="Select Godown"
                    isRequired
                    requiredMark="after"
                    errorText={errors && errors.godownId ? errors.godownId.message : undefined}
                    {...formItemStyleProps}
                  >
                    <Select
                      customStyles={inputComponentCommonStyle}
                      placeholder="Please select godown "
                      name="godownId"
                      rhfControllerProps={{
                        control,
                      }}
                      selectProps={{
                        showSearch: true,
                        optionFilterProp: 'children',
                      }}
                      options={
                        getAllMaterialUnloadingData
                          ? getAllMaterialUnloadingData.getAllGodowns.map((item) => ({
                              value: item.id,
                              label: item.name,
                            }))
                          : []
                      }
                    />
                  </FormItem>
                ) : null}
              </>
            ) : null}
            <FormItem
              label="Number of bags"
              requiredMark="after"
              isRequired
              errorText={errors && errors.bagsCount ? errors.bagsCount.message : undefined}
              {...formItemStyleProps}
            >
              <InputNumber
                customStyles={inputComponentCommonStyle}
                placeholder="Please enter number of bags"
                name="bagsCount"
                rhfControllerProps={{
                  control,
                }}
                inputNumberProps={{ min: 0 }}
              />
            </FormItem>
            {!watch('materialReturned') ? (
              <FormItem
                label="Empty bags returned"
                requiredMark="after"
                errorText={
                  errors && errors.emptyBagsReturned ? errors.emptyBagsReturned.message : undefined
                }
                {...formItemStyleProps}
              >
                <>
                  <Switch
                    name="emptyBagsReturned"
                    rhfControllerProps={{
                      control,
                    }}
                  />
                </>
              </FormItem>
            ) : null}

            {watch('materialReturned') ? (
              <FormItem
                label="Reason why paddy is returned"
                errorText={
                  errors && errors.materialReturnedReason
                    ? errors.materialReturnedReason.message
                    : undefined
                }
                {...formItemStyleProps}
              >
                <Input
                  name="materialReturnedReason"
                  isTextAreaInput={true}
                  placeholder="Please enter the reason of paddy return"
                  rhfControllerProps={{
                    control,
                  }}
                  customStyles={inputComponentCommonStyle}
                  textAreaProps={{ rows: 2 }}
                />
              </FormItem>
            ) : null}
            <FormItem {...formItemStyleProps} customStyle={{ paddingBottom: 10 }}>
              <div style={{ display: 'flex' }}>
                <Button htmlType="submit" type="primary" loading={isSubmitOrUpdateBtnLoading}>
                  {shipmentItemIdToEdit ? 'Update' : 'Submit'}
                </Button>
                {shipmentItemIdToEdit ? (
                  <Button
                    type="default"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      /* set shipment edit id to 'null' */
                      setShipmentItemIdToEdit(null);
                      /* after click on 'cancel', keep 'shipmentIdFromVehicleNumber' & 'grn' form field values as it is.
                       and reset remaining form field with its default form values.  */
                      reset({
                        ...formDefaultValues,
                        shipmentIdFromVehicleNumber: watch('shipmentIdFromVehicleNumber'),
                        grn: watch('grn'),
                        rmSellerId: watch('rmSellerId'),
                      });
                    }}
                  >
                    Cancel
                  </Button>
                ) : null}
              </div>
            </FormItem>
          </>
        ) : null}
      </form>
      {watch('shipmentIdFromVehicleNumber') ? (
        <>
          <Divider style={{ marginTop: 5 }} />
          <h2>Shipment Items</h2>
          {getBhagwatiMillIdData && (
            <>
              <Table<InwardShipmentItemsTableType>
                dataSource={
                  getInwardShipmentItemsData && getInwardShipmentItemsData.getInwardShipmentItems
                }
                loading={getInwardShipmentItemsDataLoader || getBhagwatiMillIdQueryLoader}
                className="tableStyle"
                bordered
                size="small"
                rowKey="id"
                pagination={{ showSizeChanger: true }}
                style={{ marginBottom: 10 }}
              >
                <Table.Column<InwardShipmentItemsTableType>
                  title="Farmer/Trader (Purchase) Name"
                  dataIndex="sellerName"
                  align="center"
                  key="sellerName"
                  render={(text, record) => {
                    return (
                      <span style={{ textTransform: 'capitalize' }}>
                        {(record.seller && record.seller.name) || '-'}
                      </span>
                    );
                  }}
                />
                <Table.Column<InwardShipmentItemsTableType>
                  title="Material Type"
                  dataIndex="materialType"
                  key="materialType"
                  align="center"
                  render={(text, record) => {
                    return (
                      <span style={{ textTransform: 'capitalize' }}>
                        {(record.rawMaterial && record.rawMaterial.name) || '-'}
                      </span>
                    );
                  }}
                />
                <Table.Column<InwardShipmentItemsTableType>
                  title="Paddy Grade"
                  dataIndex="paddyGrade"
                  key="paddyGrade"
                  align="center"
                  render={(paddyGrade: string | undefined) => {
                    return paddyGrade || '-';
                  }}
                />

                <Table.Column<InwardShipmentItemsTableType>
                  title="Unloading Location"
                  dataIndex="destination"
                  key="destination"
                  align="center"
                  render={(destination, record) => {
                    if (destination === null) {
                      return <>Returned</>;
                    }
                    return (
                      <>
                        {destination === 'godown' ? (
                          <div>
                            <span style={{ fontWeight: 'bold' }}>Godown: </span>
                            <span style={{ textTransform: 'capitalize' }}>
                              {(record.godown && record.godown.name) || '-'}
                            </span>
                          </div>
                        ) : (
                          <span style={{ textTransform: 'capitalize' }}>
                            <span style={{ fontWeight: 'bold', textAlign: 'left' }}>Mill: </span>
                            {(getBhagwatiMillIdData &&
                              getBhagwatiMillIdData.mills[0] &&
                              getBhagwatiMillIdData.mills[0].name) ||
                              '-'}
                          </span>
                        )}
                      </>
                    );
                  }}
                />

                <Table.Column<InwardShipmentItemsTableType>
                  title="No. Of Bags"
                  dataIndex="bagsCount"
                  key="bagsCount"
                  align="center"
                  render={(noOfBags: number | undefined) => {
                    return noOfBags || '-';
                  }}
                />
                <Table.Column<InwardShipmentItemsTableType>
                  title="Empty Bags Returned"
                  dataIndex="emptyBagsReturned"
                  key="emptyBagsReturned"
                  align="center"
                  render={(emptyBagsReturned, record) => {
                    if (record.materialReturned) return '-';
                    return <span>{emptyBagsReturned ? 'Yes' : 'No'}</span>;
                  }}
                />

                <Table.Column<InwardShipmentItemsTableType>
                  key="Actions"
                  title="Actions"
                  dataIndex="actions"
                  align="center"
                  render={(text, record) => {
                    return (
                      <div className="buttonContainer">
                        <Button
                          onClick={() => {
                            /* scroll window top of the screen, so that user can easily see opened form */
                            window.scroll(0, 0);
                            /* set shipment id whose 'edit' button has clicked */
                            setShipmentItemIdToEdit(record.id);

                            /* destructing table record data */
                            const {
                              rawMaterial,
                              paddyGrade,
                              materialReturned,
                              materialReturnedReason,
                              emptyBagsReturned,
                              bagsCount,
                              godown,
                              destination,
                            } = record;

                            /* set value of 'rawMaterialId' field to edit material type */
                            setValue('rawMaterialId', rawMaterial ? rawMaterial.id : null);
                            /* set value of 'paddyGrade' field to edit paddy grade */
                            setValue('paddyGrade', paddyGrade ? paddyGrade : null);
                            /* set value of 'emptyBagsReturned' field to empty bag return */
                            setValue(
                              'emptyBagsReturned',
                              emptyBagsReturned !== undefined ? emptyBagsReturned : null,
                            );
                            /* set value of 'godownId' field to edit godown name */
                            setValue('godownId', godown ? godown.id : null);
                            /* set value of 'destination' field to edit material unloading destination */
                            setValue('destination', destination);
                            /* set value of 'bagsCount' field to edit number of bags */
                            setValue('bagsCount', bagsCount);
                            /* set value of 'materialReturned' field to edit material returned  */
                            setValue(
                              'materialReturned',
                              materialReturned !== undefined ? materialReturned : null,
                            );
                            /* set value of 'materialReturnedReason' field to edit reason of material return */
                            setValue('materialReturnedReason', materialReturnedReason);
                          }}
                          disabled={record.id === shipmentItemIdToEdit ? true : false}
                        >
                          Edit
                        </Button>
                        <Popconfirm
                          title="Delete shipment item. Are you sure?"
                          okText="Yes"
                          onConfirm={() => {
                            deleteInwardShipmentItemFunc(record.id);
                          }}
                          okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                          cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                          cancelText="No"
                          icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                          disabled={record.id === shipmentItemIdToEdit}
                        >
                          <Button
                            className="deleteButton"
                            disabled={record.id === shipmentItemIdToEdit}
                            loading={record.id === shipmentItemIdToDltAndShowLoading ? true : false}
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                      </div>
                    );
                  }}
                />
              </Table>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
                <Popconfirm
                  title="Material unloading finished, Are you sure?"
                  placement="topLeft"
                  okText="Yes"
                  onConfirm={() => {
                    finishInwardShipmentFunc();
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<ExclamationCircleFilled style={{ color: '#faad14' }} />}
                >
                  <Button
                    type="primary"
                    loading={isShipmentFinishBtnLoading}
                    disabled={
                      !!(
                        getInwardShipmentItemsData &&
                        Array.isArray(getInwardShipmentItemsData.getInwardShipmentItems) &&
                        (
                          getInwardShipmentItemsData.getInwardShipmentItems as InwardShipmentItemsTableType[]
                        ).length === 0
                      )
                    }
                  >
                    Finish Unloading
                  </Button>
                </Popconfirm>
              </div>
            </>
          )}
        </>
      ) : null}
      {isAddFarmerOrTraderModalVisible ? (
        <QuickAddVendorOrBuyerForm
          isModalOpen={isAddFarmerOrTraderModalVisible}
          closeModal={() => {
            setIsAddFarmerOrTraderModalVisible(false);
          }}
          bagType={Enum_Item_Category_Enum.RawMaterial}
          shipmentType="inward"
          calledFromMaterialUnloading
        />
      ) : null}
      {isPrintModalVisible &&
      params.id &&
      getAllMaterialUnloadingData &&
      getInwardShipmentItemsData ? (
        <MaterialUnloadingPrintScreen
          isModalOpen={isPrintModalVisible}
          closeModal={() => {
            setIsPrintModalVisible(false);
          }}
          shipmentSelectedToUnload={getAllMaterialUnloadingData.getOpenInwardShipments.find(
            (item) => item.id === Number(params.id),
          )}
          refetchingGetAllMaterialUnloadingData={() => {
            void refetchingGetAllMaterialUnloadingData();
          }}
          inwardShipmentsItems={getInwardShipmentItemsData.getInwardShipmentItems}
          millDetails={
            getBhagwatiMillIdData && getBhagwatiMillIdData.mills && getBhagwatiMillIdData.mills[0]
              ? getBhagwatiMillIdData.mills[0]
              : null
          }
        />
      ) : null}
    </>
  );
};

export default MaterialUnloadingScreen;
