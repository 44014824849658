import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useLazyQuery, ApolloError, useMutation } from '@apollo/client';
import { Button, message, Divider, Spin, Descriptions, Table, Popconfirm } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  GetOutwardShipmentsForOrdersAndLoadingQuery,
  GetOutwardShipmentsForOrdersAndLoadingQueryVariables,
  GetOutwardShipmentByIdQuery,
  GetOutwardShipmentByIdQueryVariables,
  FinishOutwardShipmentLoadingMutation,
  FinishOutwardShipmentLoadingMutationVariables,
  AddOrRemoveBagsToOutwardShipmentOrderMutation,
  AddOrRemoveBagsToOutwardShipmentOrderMutationVariables,
} from '../../../graphql/graphql-types';
import RequiredMessage from '../../../components/RequiredMessage';
import FormItem from '../../../components/FormItem';
import Select from '../../../components/Select';
import { dateFormatFunc, inputComponentCommonStyle } from '../../../utils/globals';
import InputNumber from '../../../components/InputNumber';
import { logger } from '../../../utils/helpers';
import { useOutwardShipmentOutlet } from './OutwardProductTabLayout';
import { InfoCircleFilled } from '@ant-design/icons';
import colors from '../../../scss/variables.module.scss';
import { OutwardShipmentByIdDataType, OutwardShipmentOrdersTableType } from '../../../utils/types';

/* outward shipment loading form type */
type OutwardShipmentLoadingFormType = {
  /* prop type used to store 'id' of the shipment whose vehicle number get selected from 'select vehicle' dropdown */
  shipmentIdFromVehicleNumber: number | null;
  /* prop type used store number of bags entered to load or unload */
  numberOfBagsToLoadOrUnload: number | null;
} & Pick<OutwardShipmentOrdersTableType, 'loadedBagsCount' | 'plannedBagsCount'>;

/* loading get outward shipment for order & loading query with the help of loader */
const getOutwardShipmentsForOrdersAndLoadingQuery = loader(
  '../../../graphql/queries/getOutwardShipmentsForOrdersAndLoadingQuery.graphql',
);

/* loading get outward shipment by id query with the help of loader */
const getOutwardShipmentByIdQuery = loader(
  '../../../graphql/queries/getOutwardShipmentByIdQuery.graphql',
);

/* loading add or remove outward shipment order bags mutation  */
const addOrRemoveBagsToOutwardShipmentOrderMutation = loader(
  '../../../graphql/mutations/addOrRemoveBagsToOutwardShipmentOrderMutation.graphql',
);

/* loading finish outward shipment loading mutation  */
const finishOutwardShipmentLoadingMutation = loader(
  '../../../graphql/mutations/finishOutwardShipmentLoadingMutation.graphql',
);

/* loading get outward shipment for truck out query with the help of loader */
const getOutwardShipmentsForTruckOutQuery = loader(
  '../../../graphql/queries/getOutwardShipmentsForTruckOutQuery.graphql',
);

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/** react functional component */
const OutwardProductLoadingScreen = (): JSX.Element => {
  /* extracting 'outwardShipmentId & setOutwardShipmentId' from outward shipment outlet */
  const { outwardShipmentId, setOutwardShipmentId } = useOutwardShipmentOutlet();

  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* this state used to store loading indicator and name of the finish button(here we have two type of finish button),
   so that we can show loading indicator on finish button using their name  */
  const [finishShipmentLoadingBtnLoading, setFinishShipmentLoadingBtnLoading] = useState<{
    /* prop type used to store loading */
    loading: boolean;
    /* prop type used to store finish button name */
    buttonName: 'finish' | 'finishAndGoTruckOut' | null;
  }>({ loading: false, buttonName: null });

  /* this state used to store 'id' of the order, whose shipment(material) bags selected to load/unload
  & to store mode of the button according to action performed by user i.e loading or unloading bags  */
  const [orderIdToLoadOrUnloadAndButtonMode, setOrderIdToLoadOrUnloadAndButtonMode] = useState<{
    /* this prop type used to store id of the order */
    orderId: number | null;
    /* prop type used to store mode of the button */
    mode: 'load' | 'unload' | null;
  }>({ orderId: null, mode: null });

  /* state used to store id of the shipment order whose load/unload button is clicked which is then used to show loading indicator */
  const [orderIdToLoadOrUnloadAndShowLoading, setOrderIdToLoadOrUnloadAndShowLoading] = useState<
    number | null
  >(null);

  /* const used to store outward shipment loading form default values, so that we can use it further while resetting the form  */
  const outwardShipmentLoadingFormDefaultValues = {
    /* shipment id */
    shipmentIdFromVehicleNumber: null,
    /* number of bags loaded or unloaded */
    numberOfBagsToLoadOrUnload: null,
    /* planned bag count */
    plannedBagsCount: null,
    /* loaded bag count */
    loadedBagsCount: null,
  };

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<OutwardShipmentLoadingFormType>({
    defaultValues: outwardShipmentLoadingFormDefaultValues,
    mode: 'onChange',
  });

  /* get outward shipment by Id query used to display shipment & shipment order information */
  const [
    getOutwardShipmentsById,
    {
      data: getOutwardShipmentsByIdData,
      loading: getOutwardShipmentsByIdDataLoading,
      error: getOutwardShipmentsByIdDataError,
    },
  ] = useLazyQuery<GetOutwardShipmentByIdQuery, GetOutwardShipmentByIdQueryVariables>(
    getOutwardShipmentByIdQuery,
  );

  /* get outward shipment for order query used to display vehicle number, as a dropdown list on 'shipmentIdFromVehicleNumber' form field */
  const {
    data: getOutwardShipmentsForOrdersData,
    loading: getOutwardShipmentsForOrdersDataLoading,
    error: getOutwardShipmentsForOrdersDataError,
  } = useQuery<
    GetOutwardShipmentsForOrdersAndLoadingQuery,
    GetOutwardShipmentsForOrdersAndLoadingQueryVariables
  >(getOutwardShipmentsForOrdersAndLoadingQuery, {
    onCompleted: () => {
      /* if user already selected outward shipment id to loading,from 'Shipment order' screen,
   then get shipment & shipment order loading information for that selected shipment id  */
      if (outwardShipmentId) {
        /* call 'getOutwardShipmentsById' function to get shipment & shipment order loading information */
        void getOutwardShipmentsById({ variables: { outwardShipmentId: outwardShipmentId } });
        /* initialize 'shipmentIdFromVehicleNumber' field value to selected outward shipment id  */
        setValue('shipmentIdFromVehicleNumber', outwardShipmentId);
      }
    },
  });

  /* add or remove bags from outward shipment order mutation */
  const [addOrRemoveBagsToOutwardShipmentOrder] = useMutation<
    AddOrRemoveBagsToOutwardShipmentOrderMutation,
    AddOrRemoveBagsToOutwardShipmentOrderMutationVariables
  >(addOrRemoveBagsToOutwardShipmentOrderMutation);

  /* finish outward shipment loading mutation */
  const [finishShipmentLoading] = useMutation<
    FinishOutwardShipmentLoadingMutation,
    FinishOutwardShipmentLoadingMutationVariables
  >(finishOutwardShipmentLoadingMutation);

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getOutwardShipmentsForOrdersDataError || getOutwardShipmentsByIdDataError) {
    let errorMessage;
    if (getOutwardShipmentsForOrdersDataError) {
      errorMessage = getOutwardShipmentsForOrdersDataError.message;
    } else if (getOutwardShipmentsByIdDataError) {
      errorMessage = getOutwardShipmentsByIdDataError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* const used to store outward shipment by id data, so that we can use it further */
  const outwardShipmentsByIdData =
    getOutwardShipmentsByIdData &&
    (getOutwardShipmentsByIdData.getOutwardShipmentById as OutwardShipmentByIdDataType);

  /* this variable used to store total planned bag count, which is calculated using order's planned bag count */
  let calcTotalPlannedBagsCount = 0;

  /* this variable used to total loaded bag count, which is calculated using order's loaded bag count  */
  let calculateLoadedBagsCount = 0;

  /* this variable used to store transporter vehicle's capacity */
  let transporterVehicleCapacityInKg = 0;

  /* this variable used to store total weight of remaining bags */
  let calcTotalWeightOfRemainingBagsInKg = 0;

  /* this const used to store shipment id, which is selected to finish loading/complete loading */
  const watchedShipmentIdSelectedToFinish = watch('shipmentIdFromVehicleNumber');

  /* calculate 'plannedBagCount','loadedBagsCount','NoOfBagsRemaining' & transporter vehicle capacity */
  if (outwardShipmentsByIdData) {
    /* destructing 'transporterVehicle & orders' from outward order shipment by id data */
    const { transporterVehicle, orders } = outwardShipmentsByIdData;

    /* calculate transporter vehicle capacity in kg */
    transporterVehicleCapacityInKg =
      transporterVehicle && transporterVehicle.capacityKgs ? transporterVehicle.capacityKgs : 0;

    /* calculate 'loaded bag count' & 'planned bag count' */
    if (orders.length > 0) {
      orders.forEach((order) => {
        /* calculate loaded bag count & total loaded bags weight */
        if (order.loadedBagsCount) {
          /* calculate total number of loaded bags */
          calculateLoadedBagsCount += order.loadedBagsCount;
        }

        /* calculate planned bag count & total planned bag weight */
        if (order.plannedBagsCount) {
          /* calculate total number of planned bags */
          calcTotalPlannedBagsCount += order.plannedBagsCount;
        }
      });
    }

    /* calculate total remaining bag weight to 'load' using 'total planned bag weight' & 'total loaded bag weight' */
    calcTotalWeightOfRemainingBagsInKg = calcTotalPlannedBagsCount - calculateLoadedBagsCount;
  }

  /* function used to handle form submit */
  const onSubmit = (formData: OutwardShipmentLoadingFormType) => {
    /* destructing form data */
    const { loadedBagsCount, numberOfBagsToLoadOrUnload, plannedBagsCount } = formData;

    /* destructing 'orderIdToLoadOrUnloadAndButtonMode' state data */
    const { orderId, mode } = orderIdToLoadOrUnloadAndButtonMode;

    /* this variable used to store error message */
    let errorMessage: string | null = null;

    /* if user entered number of bags to load or unload then execute mutation */
    if ((numberOfBagsToLoadOrUnload || numberOfBagsToLoadOrUnload === 0) && orderId && mode) {
      /* if user entered '0' or less than '0' bags to load or unload then show error message */
      if (numberOfBagsToLoadOrUnload <= 0) {
        errorMessage = `Cannot ${mode} 0 or less then 0 bags.`;
      } else if (
        mode === 'load' &&
        plannedBagsCount &&
        !loadedBagsCount &&
        numberOfBagsToLoadOrUnload > plannedBagsCount
      ) {
        /* if user entered more than planned bags to load, then show error message */
        errorMessage = `Cannot add more than ${plannedBagsCount} bags for this order.`;
      } else if (
        mode === 'load' &&
        loadedBagsCount &&
        plannedBagsCount &&
        loadedBagsCount + numberOfBagsToLoadOrUnload > plannedBagsCount
      ) {
        /* if user entered more than planned bags to load, then show error message */
        errorMessage = `Cannot add more than ${
          plannedBagsCount - loadedBagsCount
        } bags for this order.`;
      } else if (mode === 'unload' && (!loadedBagsCount || loadedBagsCount === 0)) {
        /* if there is no bags loaded & user trying to unload bags then show this error message */
        errorMessage = 'Cannot unload bags. No bags added for this order.';
      } else if (
        mode === 'unload' &&
        loadedBagsCount &&
        plannedBagsCount &&
        loadedBagsCount - numberOfBagsToLoadOrUnload < 0
      ) {
        /* if user user trying unload more than loaded bags then show this error message */
        errorMessage = `Cannot unload bags more than ${loadedBagsCount} for this order.`;
      } else {
        setOrderIdToLoadOrUnloadAndShowLoading(orderId);
        /* this const used to store total number of bags loaded or unloaded */
        let totalNumberOfBagsLoadedOrUnloaded = 0;

        /* if user 'loaded' the bags, then calculate total number of bags going to load,
         otherwise calculate total number of bags to unload */
        if (mode === 'load') {
          /* if user already loaded some bags, then total number of bags to load will be 'previously loaded bags' + 'user entered number of bags to load'.  */
          totalNumberOfBagsLoadedOrUnloaded = loadedBagsCount
            ? loadedBagsCount + numberOfBagsToLoadOrUnload
            : numberOfBagsToLoadOrUnload;
        } else {
          /* if user already unloaded some bags, then total number of bags to unload will be 'previously unloaded bags' - 'user entered number of bags to unload'.  */
          totalNumberOfBagsLoadedOrUnloaded = loadedBagsCount
            ? loadedBagsCount - numberOfBagsToLoadOrUnload
            : numberOfBagsToLoadOrUnload;
        }

        /* execute mutation to 'load' or 'unload' bags */
        addOrRemoveBagsToOutwardShipmentOrder({
          variables: {
            outwardShipmentOrderId: orderId,
            loadedBagsCount: totalNumberOfBagsLoadedOrUnloaded,
          },
        })
          .then(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.success(mode === 'load' ? 'Bags added.' : 'Bags unloaded.');
            /* reset form */
            reset({
              ...outwardShipmentLoadingFormDefaultValues,
              shipmentIdFromVehicleNumber: watch('shipmentIdFromVehicleNumber'),
            });
            setOrderIdToLoadOrUnloadAndButtonMode({ orderId: null, mode: null });
            setOrderIdToLoadOrUnloadAndShowLoading(null);
          })
          .catch((error: ApolloError) => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            message.error(error);
            setOrderIdToLoadOrUnloadAndButtonMode({ orderId: null, mode: null });
            setOrderIdToLoadOrUnloadAndShowLoading(null);
            logger(error);
          });
      }
    } else {
      /* if user not entered any bags to 'load' or 'unload' then show this error message */
      errorMessage =
        mode === 'load'
          ? 'Please enter a value for the number of bags you want to load and try again.'
          : 'Please enter a value for the number of bags you want to unload and try again.';
    }

    /* if this form has any error then show that error message through message pop up */
    if (errorMessage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(errorMessage);
    }
  };

  /* function to handle finish Shipment loading */
  const finishShipmentLoadingFunc = (
    /* this prop type used to store shipment id, which is selected to finish/complete */
    shipmentId: number,
    /* this prop type used to store button name */
    buttonName: 'finish' | 'finishAndGoTruckOut',
  ) => {
    setFinishShipmentLoadingBtnLoading({ loading: true, buttonName: buttonName });
    finishShipmentLoading({
      variables: {
        id: shipmentId,
        loadedAt: new Date(),
      },
      /* after finishing the shipment refetch 'getOutwardShipmentsForTruckOutQuery' & 'getOutwardShipmentsForOrdersAndLoadingQuery' query to get updated shipments list */
      refetchQueries: [
        getOutwardShipmentsForTruckOutQuery,
        getOutwardShipmentsForOrdersAndLoadingQuery,
      ].map((refetchQuery) => ({ query: refetchQuery })),
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Finished');

        /* if user has clicked on 'Finish shipment orders & Go Truck Outward' button then navigate to 'Truck outward' page */
        if (buttonName === 'finishAndGoTruckOut') {
          /* store selected shipment id to finish loading into 'setOutwardShipmentId's state,
          so that we can get that shipment id onto 'Outward product -> Truck outward' tab screen  */
          setOutwardShipmentId(shipmentId);

          /* navigate to 'Outward product -> Truck outward' screen */
          navigate('../truckOut');
        } else {
          /* if user dont want to navigate or get shipment id onto 'Outward product Truck outward' tab screen
          then  set outward shipment id to null */
          setOutwardShipmentId(null);
        }
        /* reset form */
        reset({ ...outwardShipmentLoadingFormDefaultValues });
        setFinishShipmentLoadingBtnLoading({ loading: false, buttonName: null });
      })
      .catch((error: ApolloError) => {
        setFinishShipmentLoadingBtnLoading({ loading: false, buttonName: null });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  return (
    <>
      <RequiredMessage />
      <FormItem
        label="Shipment vehicle "
        isRequired
        errorText={
          errors && errors.shipmentIdFromVehicleNumber
            ? errors.shipmentIdFromVehicleNumber.message
            : undefined
        }
        {...formItemStyleProps}
      >
        <Select
          customStyles={{ ...inputComponentCommonStyle }}
          placeholder="Please select vehicle number"
          name="shipmentIdFromVehicleNumber"
          rhfControllerProps={{
            control,
          }}
          selectProps={{
            showSearch: true,
            optionFilterProp: 'children',
            loading: getOutwardShipmentsForOrdersDataLoading,
          }}
          options={
            getOutwardShipmentsForOrdersData &&
            getOutwardShipmentsForOrdersData.getOutwardShipmentsForOrders
              ? getOutwardShipmentsForOrdersData.getOutwardShipmentsForOrders.map((shipment) => {
                  return {
                    value: shipment.id,
                    label:
                      (shipment.transporterVehicle && shipment.transporterVehicle.number) || '-',
                  };
                })
              : []
          }
          onChange={(rhfOnChange, value: number) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            rhfOnChange(value);
            /* on selection of 'shipmentIdFromVehicleNumber' field set 'numberOfBagsToLoadOrUnload' to null,
            so that we can reset 'numberOfBagsToLoadOrUnload' field's value */
            setValue('numberOfBagsToLoadOrUnload', null);
            /* call 'getOutwardShipmentsById' function to get selected outward shipment id information */
            void getOutwardShipmentsById({ variables: { outwardShipmentId: value } });
          }}
        />
      </FormItem>
      {watch('shipmentIdFromVehicleNumber') ? (
        <>
          {outwardShipmentsByIdData && !getOutwardShipmentsByIdDataLoading ? (
            <>
              <Descriptions
                colon={false}
                column={1}
                labelStyle={{ width: '17%' }}
                style={{ marginTop: 20 }}
                contentStyle={{ textTransform: 'capitalize' }}
              >
                <Descriptions.Item label="GRN">
                  {outwardShipmentsByIdData.grn || '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Date">
                  {(outwardShipmentsByIdData.createdAt &&
                    dateFormatFunc(outwardShipmentsByIdData.createdAt)) ||
                    '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Transporter Name">
                  {(outwardShipmentsByIdData.transporter &&
                    outwardShipmentsByIdData.transporter.name) ||
                    '-'}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Drivers Name - Phone No."
                  contentStyle={{ flexDirection: 'column' }}
                >
                  {outwardShipmentsByIdData.drivers.map(({ driver }) => (
                    <span style={{ paddingBottom: 5 }} key={driver.id}>
                      Name: {driver.name || '-'}, Phone: {driver.mobile || '-'}
                    </span>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Vehicle Capacity"
                  contentStyle={{ textTransform: 'lowercase' }}
                >
                  {`${transporterVehicleCapacityInKg} kgs (${
                    transporterVehicleCapacityInKg / 100
                  } quintals)`}
                </Descriptions.Item>
              </Descriptions>
              <Divider style={{ marginTop: 10 }} />
              <h3>Orders</h3>
              <Descriptions
                colon={false}
                column={1}
                labelStyle={{ width: '17%' }}
                style={{ marginTop: 20 }}
                contentStyle={{ textTransform: 'capitalize' }}
              >
                <Descriptions.Item
                  label="Total bags to load"
                  contentStyle={{ textTransform: 'lowercase' }}
                >
                  {`${calcTotalPlannedBagsCount} kgs (${calcTotalPlannedBagsCount / 100} quintals)`}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Total bags loaded"
                  contentStyle={{ textTransform: 'lowercase' }}
                >
                  {`${calculateLoadedBagsCount} kgs (${calculateLoadedBagsCount / 100} quintals)`}
                </Descriptions.Item>
                <Descriptions.Item
                  label="Total bags remaining"
                  contentStyle={{ textTransform: 'lowercase' }}
                >
                  {`${calcTotalWeightOfRemainingBagsInKg} kgs (${
                    calcTotalWeightOfRemainingBagsInKg / 100
                  } quintals)`}
                </Descriptions.Item>
              </Descriptions>
              <Table<OutwardShipmentOrdersTableType>
                dataSource={outwardShipmentsByIdData.orders}
                key="id"
                className="tableStyle"
                bordered
                size="small"
                pagination={{ showSizeChanger: true }}
              >
                <Table.Column title="Order Number" align="center" dataIndex="number" key="number" />
                <Table.Column<OutwardShipmentOrdersTableType>
                  title="Broker"
                  dataIndex="broker"
                  align="center"
                  render={(text, record) => (
                    <span style={{ textTransform: 'capitalize' }}>
                      {(record.broker && record.broker.name) || '-'}
                    </span>
                  )}
                />
                <Table.Column<OutwardShipmentOrdersTableType>
                  title="Product Type"
                  dataIndex="productType"
                  key="productType"
                  align="center"
                  render={(text, record) =>
                    (record.product && record.product.productType.name) || '-'
                  }
                />
                <Table.Column<OutwardShipmentOrdersTableType>
                  title={() => {
                    return (
                      <span>
                        Product
                        <br />
                        Packaging Size
                      </span>
                    );
                  }}
                  dataIndex="productPackagingSize"
                  align="center"
                  width="200px"
                  render={(text, record) => {
                    return (
                      <>
                        {record.product ? (
                          <span style={{ whiteSpace: 'nowrap' }}>
                            <b>Product: </b>
                            {record.product.brand}
                          </span>
                        ) : (
                          '-'
                        )}
                        <br />
                        {record.bagSizeKg ? (
                          <span style={{ whiteSpace: 'nowrap' }}>
                            <b>Size: </b>
                            {record.bagSizeKg} kgs
                          </span>
                        ) : null}
                      </>
                    );
                  }}
                />
                <Table.Column<OutwardShipmentOrdersTableType>
                  title="Bags To Load"
                  dataIndex="plannedBagsCount"
                  key="plannedBagsCount"
                  align="center"
                  render={(text, record) => record.plannedBagsCount || '-'}
                />
                <Table.Column<OutwardShipmentOrdersTableType>
                  title="Bags Loaded"
                  dataIndex="loadedBagsCount"
                  key="loadedBagsCount"
                  align="center"
                  render={(text, record) => record.loadedBagsCount || '-'}
                />
                <Table.Column<OutwardShipmentOrdersTableType>
                  title="Bags Remaining"
                  dataIndex="remainingBagCounts"
                  key="remainingBagCounts"
                  align="center"
                  render={(text, record) => {
                    const { plannedBagsCount, loadedBagsCount } = record;
                    const plannedBagsCountValue = plannedBagsCount ? plannedBagsCount : 0;
                    const loadedBagsCountValue = loadedBagsCount ? loadedBagsCount : 0;
                    return plannedBagsCountValue - loadedBagsCountValue;
                  }}
                />

                <Table.Column<OutwardShipmentOrdersTableType>
                  key="id"
                  title="Actions"
                  dataIndex="actions"
                  align="center"
                  width={250}
                  render={(text, record) => {
                    if (calcTotalWeightOfRemainingBagsInKg !== 0) {
                      return (
                        <form
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onSubmit={handleSubmit(onSubmit)}
                        >
                          {orderIdToLoadOrUnloadAndButtonMode &&
                          orderIdToLoadOrUnloadAndButtonMode.orderId === record.id ? (
                            <>
                              <InputNumber
                                customStyles={{
                                  ...inputComponentCommonStyle,
                                  ...{ marginBottom: 10 },
                                }}
                                placeholder={`Please enter number of bags to ${
                                  orderIdToLoadOrUnloadAndButtonMode.mode === 'load'
                                    ? 'load'
                                    : 'unload'
                                }`}
                                name="numberOfBagsToLoadOrUnload"
                                rhfControllerProps={{
                                  control,
                                }}
                                inputNumberProps={{ min: 0 }}
                              />

                              <div className="buttonContainer">
                                <Button
                                  type={
                                    orderIdToLoadOrUnloadAndButtonMode.mode === 'load'
                                      ? 'primary'
                                      : undefined
                                  }
                                  className={
                                    orderIdToLoadOrUnloadAndButtonMode.mode === 'unload'
                                      ? 'deleteButton'
                                      : undefined
                                  }
                                  htmlType="submit"
                                  style={{ textTransform: 'capitalize' }}
                                  loading={
                                    orderIdToLoadOrUnloadAndShowLoading === record.id ? true : false
                                  }
                                >
                                  {orderIdToLoadOrUnloadAndButtonMode.mode === 'load'
                                    ? 'Done'
                                    : 'Unload'}
                                </Button>
                                <Button
                                  onClick={() => {
                                    /* set order id & mode to null */
                                    setOrderIdToLoadOrUnloadAndButtonMode({
                                      orderId: null,
                                      mode: null,
                                    });
                                    /* reset form */
                                    reset({
                                      ...outwardShipmentLoadingFormDefaultValues,
                                      shipmentIdFromVehicleNumber: watch(
                                        'shipmentIdFromVehicleNumber',
                                      ),
                                    });
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </>
                          ) : (
                            <div className="buttonContainer">
                              <Button
                                style={{ marginRight: 10 }}
                                type="primary"
                                onClick={() => {
                                  /* destructing record data */
                                  const { id, loadedBagsCount, plannedBagsCount } = record;

                                  /* set 'order id' state to order id which is selected to load, & set 'mode' to 'load' */
                                  setOrderIdToLoadOrUnloadAndButtonMode({
                                    orderId: id,
                                    mode: 'load',
                                  });

                                  /* set 'loadedBagsCount' field value to shipment order's 'loadedBagCount' , so that we can use it further */
                                  setValue('loadedBagsCount', loadedBagsCount);

                                  /* set 'plannedBagsCount' field value to shipment order's 'plannedBagsCount', so that we can use it further */
                                  setValue('plannedBagsCount', plannedBagsCount);
                                }}
                              >
                                Load
                              </Button>
                              <Button
                                onClick={() => {
                                  /* destructing record data */
                                  const { id, loadedBagsCount, plannedBagsCount } = record;

                                  /* set 'order id' state to order id which is selected to load, & set 'mode' to 'load' */
                                  setOrderIdToLoadOrUnloadAndButtonMode({
                                    orderId: id,
                                    mode: 'unload',
                                  });

                                  /* set 'loadedBagsCount' field value to shipment order's 'loadedBagCount' , so that we can use it further */
                                  setValue('loadedBagsCount', loadedBagsCount);

                                  /* set 'plannedBagsCount' field value to shipment order's 'plannedBagsCount', so that we can use it further */
                                  setValue('plannedBagsCount', plannedBagsCount);
                                }}
                              >
                                Unload
                              </Button>
                            </div>
                          )}
                        </form>
                      );
                    }
                    return null;
                  }}
                />
              </Table>
              <div
                className="buttonContainer"
                style={{ justifyContent: 'flex-end', marginBottom: 20 }}
              >
                {/* if user already finished loading, then just navigate to 'Truck outward' screen */}
                {outwardShipmentsByIdData && outwardShipmentsByIdData.loadedAt ? (
                  <>
                    {/* if user has loaded all bags, then don't show pop confirm */}
                    {calcTotalPlannedBagsCount - calculateLoadedBagsCount === 0 ? (
                      <Button
                        type="primary"
                        onClick={() => {
                          /* navigate to 'Outward product -> Truck outward' screen */
                          navigate('../truckOut');
                        }}
                      >
                        Go Truck Outward
                      </Button>
                    ) : (
                      <Popconfirm
                        title={`${
                          calcTotalPlannedBagsCount - calculateLoadedBagsCount
                        } bags not loaded yet. Do you still want to finish loading?`}
                        okText="Yes"
                        okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                        cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                        cancelText="No"
                        icon={<InfoCircleFilled style={{ color: colors.infoIconColor }} />}
                        onConfirm={() => {
                          /* navigate to 'Outward product -> Truck outward' screen */
                          navigate('../truckOut');
                        }}
                      >
                        <Button type="primary">Go Truck Outward</Button>
                      </Popconfirm>
                    )}
                  </>
                ) : (
                  <>
                    <Popconfirm
                      title={`${
                        calcTotalPlannedBagsCount - calculateLoadedBagsCount === 0
                          ? 'Finish loading?'
                          : `${
                              calcTotalPlannedBagsCount - calculateLoadedBagsCount
                            } bags not loaded yet. Do you still want to finish loading?`
                      }`}
                      okText="Yes"
                      okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                      cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                      cancelText="No"
                      icon={<InfoCircleFilled style={{ color: colors.infoIconColor }} />}
                      onConfirm={() => {
                        /* if any shipment id selected to finish loading/Complete loading, then call 'finishShipmentLoadingFunc' function*/
                        if (watchedShipmentIdSelectedToFinish) {
                          finishShipmentLoadingFunc(watchedShipmentIdSelectedToFinish, 'finish');
                        }
                      }}
                    >
                      <Button
                        type="primary"
                        loading={
                          finishShipmentLoadingBtnLoading.buttonName === 'finish'
                            ? finishShipmentLoadingBtnLoading.loading
                            : false
                        }
                      >
                        Finish Shipment Loading
                      </Button>
                    </Popconfirm>
                    <Popconfirm
                      title={`${
                        calcTotalPlannedBagsCount - calculateLoadedBagsCount === 0
                          ? 'Finish loading?'
                          : `${
                              calcTotalPlannedBagsCount - calculateLoadedBagsCount
                            } bags not loaded yet. Do you still want to finish loading?`
                      }`}
                      okText="Yes"
                      okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                      cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                      cancelText="No"
                      icon={<InfoCircleFilled style={{ color: colors.infoIconColor }} />}
                      onConfirm={() => {
                        /* if any shipment id selected to finish loading/Complete loading, then call 'finishShipmentLoadingFunc' function*/
                        if (watchedShipmentIdSelectedToFinish) {
                          finishShipmentLoadingFunc(
                            watchedShipmentIdSelectedToFinish,
                            'finishAndGoTruckOut',
                          );
                        }
                      }}
                    >
                      <Button
                        type="primary"
                        style={{ marginLeft: 20 }}
                        loading={
                          finishShipmentLoadingBtnLoading.buttonName === 'finishAndGoTruckOut'
                            ? finishShipmentLoadingBtnLoading.loading
                            : false
                        }
                      >
                        Finish Shipment Loading & Go Truck Outward
                      </Button>
                    </Popconfirm>
                  </>
                )}
              </div>
            </>
          ) : (
            <Spin className="loadingSpinner" />
          )}
        </>
      ) : null}
    </>
  );
};

export default OutwardProductLoadingScreen;
