import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { message, Button } from 'antd';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, ApolloError, Reference } from '@apollo/client';
import { loader } from 'graphql.macro';
import { logger } from '../../../utils/helpers';
import {
  CreateTransporterMutation,
  CreateTransporterMutationVariables,
  UpdateTransporterMutation,
  UpdateTransporterMutationVariables,
  Transporters,
} from '../../../graphql/graphql-types';
import { statesOptionsForSelect } from '../../../utils/globals';
import Select from '../../../components/Select';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import {
  inputComponentCommonStyle,
  formItemStyleProps as defaultTransporterFormItemStyleProps,
} from '../../../utils/globals';
import RequiredMessage from '../../../components/RequiredMessage';
import { AddressType } from '../../../utils/types';

/* add or edit transporter form type */
type AddOrEditTransporterFormType = Pick<Transporters, 'name' | 'gstin'> & {
  /* owner's name */
  ownersName: string;
  /* owner's email */
  ownersEmail: string;
  /* owner's mobile */
  mobile: string;
} & AddressType;

/* add or edit transporter form prop types */
type AddOrEditTransporterFormPropTypes = {
  /* to check whether the transporter form is being called from  edit mode  or add mode */
  mode: 'edit' | 'add';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  transporterDataToEdit?: AddOrEditTransporterFormType;
  /* this prop type used to manage CSS styling, when this form will be called from 'Outward -> Create Shipment' section */
  calledFrom?: 'outward' | 'management';
  /* this prop type used to close modal (because In 'Outward section' we used modal to display this form), when when this form will be called from 'Outward -> Create Shipment' section */
  closeModal?: () => void;
};

/* this formItem style used when 'AddOrEditTransporter' form is called from 'Outward -> create shipment' section */
const outwardTransporterFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 7,
  /* input column span of FormItem */
  inputColSpan: 16,
  /* formItem label style */
  labelStyle: { fontSize: 14 } as React.CSSProperties,
  /* formItem content style */
  customStyle: { paddingTop: 15, fontSize: 14 } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* validations required for each input field */
const schema = yup.object({
  name: yup
    .string()
    .required('Please enter transport name and try again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  ownersEmail: yup.string().email('Please enter a valid email id').nullable(),
  ownersName: yup.string().required(`Please enter owner's name and try again`),
  gstin: yup
    .string()
    .matches(validationRegex.gstin, {
      message: 'Please enter a valid GSTIN',
      excludeEmptyString: true,
    })
    .nullable(),
  mobile: yup
    .string()
    .required(`Please enter mobile number and try again`)
    .matches(validationRegex.mobile, {
      message: 'Please enter a valid mobile number',
    }),
  address: yup.string().required(`Please enter address and try again`),
  city: yup
    .string()
    .required(`Please enter city and try again`)
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  state: yup.string().required(`Please select state and try again`).nullable(),
  pincode: yup
    .string()
    .required(`Please enter pincode and try again`)
    .matches(validationRegex.pincode, {
      message: 'Please enter a valid pincode',
    }),
});

/* loading create transporter mutation with the help of loader */
const createTransporterMutation = loader(
  '../../../graphql/mutations/createTransporterMutation.graphql',
);

/* loading update transporter mutation with the help of loader */
const updateTransporterMutation = loader(
  '../../../graphql/mutations/updateTransporterMutation.graphql',
);

/* this function used to handle AddOrEditTransporterForm errors */
const errorHandlerForAddOrEditTransporterForm = (error: ApolloError): string => {
  if (
    error.message ===
    'Uniqueness violation. duplicate key value violates unique constraint "transporters_gstin_key"'
  ) {
    return 'GST number is already registered';
  }
  return error.message;
};

/* React functional component */
const AddOrEditTransporterForm = ({
  mode,
  transporterDataToEdit = undefined,
  calledFrom = 'management',
  closeModal = () => {},
}: AddOrEditTransporterFormPropTypes) => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* state to set the loading condition of button */
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* this const used to store formItem style props based on 'calledFrom' prop value */
  const formItemStyleProps =
    calledFrom === 'outward'
      ? outwardTransporterFormItemStyleProps
      : defaultTransporterFormItemStyleProps;

  /* create transporter mutation */
  const [createTransporter] = useMutation<
    CreateTransporterMutation,
    CreateTransporterMutationVariables
  >(createTransporterMutation);

  /* update transporter mutation */
  const [updateTransporter] = useMutation<
    UpdateTransporterMutation,
    UpdateTransporterMutationVariables
  >(updateTransporterMutation);

  /* extracting required content from use form hook */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddOrEditTransporterFormType>({
    defaultValues:
      mode === 'edit' && transporterDataToEdit
        ? {
            name: transporterDataToEdit.name,
            gstin: transporterDataToEdit.gstin,
            ownersEmail: transporterDataToEdit.ownersEmail,
            ownersName: transporterDataToEdit.ownersName,
            mobile: transporterDataToEdit.mobile,
            address: transporterDataToEdit.address,
            state: transporterDataToEdit.state || null,
            city: transporterDataToEdit.city,
            pincode: transporterDataToEdit.pincode,
          }
        : {
            name: '',
            gstin: '',
            ownersEmail: '',
            mobile: '',
            ownersName: '',
            address: '',
            state: null,
            city: '',
            pincode: '',
          },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* function to handle submitted data */
  const onSubmit = (data: AddOrEditTransporterFormType) => {
    setBtnLoader(true);

    /* const used to store mutation variables which are common in both updateTransporter and createTransporter mutation */
    const commonMutationVariables = {
      name: data.name,
      gstin: data.gstin || null,
      address: {
        address: data.address,
        state: data.state,
        city: data.city,
        pincode: data.pincode,
      },
      primaryContactName: data.ownersName,
      primaryContactMobile: data.mobile,
      primaryContactEmail: data.ownersEmail || null,
    };
    if (mode === 'add') {
      createTransporter({
        variables: {
          ...commonMutationVariables,
        },
        update(cache, { data: addedTransporter }) {
          /* extracting the new data added by user */
          const dataToAdd = addedTransporter?.createTransporter;
          cache.modify({
            fields: {
              transporters(existingTransporters: Array<Reference>) {
                return [...existingTransporters, dataToAdd];
              },
            },
          });
        },
      })
        .then(() => {
          /* this const used to display message after adding transporter successfully, based on 'calledFrom' prop value  */
          const successMessage =
            calledFrom === 'outward'
              ? 'Transporter profile created.'
              : 'Transporter has been added successfully';

          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success(successMessage);
          /* if this form is called from 'management -> Transporter section' then navigate to view all transporter screen.
          other wise close modal */
          if (calledFrom === 'management') {
            /* navigate to view all transporter screen */
            navigate('/management/transporters');
          } else {
            /* if this form is called from 'Outward -> create shipment' section then close modal */
            closeModal();
          }
          reset();
          setBtnLoader(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorHandlerForAddOrEditTransporterForm(error));
          setBtnLoader(false);
          logger(error);
        });
    } else {
      updateTransporter({
        variables: {
          id: Number(params.id),
          ...commonMutationVariables,
        },
        update(cache, { data: updateData }) {
          /* data to update */
          const dataToUpdate = updateData?.updateTransporter;
          cache.modify({
            fields: {
              transporters(existingTransporters: Array<Reference>, { readField }) {
                if (dataToUpdate) {
                  return existingTransporters.map((transporterData) => {
                    if (dataToUpdate.id === readField('id', transporterData)) {
                      return dataToUpdate;
                    }
                    return transporterData;
                  });
                }
                return existingTransporters;
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Transporter has been updated successfully.');
          navigate('/management/transporters');
          reset();
          setBtnLoader(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorHandlerForAddOrEditTransporterForm(error));
          logger(error);
          setBtnLoader(false);
        });
    }
  };

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormItem
          label="Transporter Name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          requiredMark="after"
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter name of transporter"
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="GST"
          errorText={errors && errors.gstin ? errors.gstin.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter GST of transporter"
            name="gstin"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Owner's Name"
          isRequired
          requiredMark="after"
          errorText={errors && errors.ownersName ? errors.ownersName.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter name of owner"
            name="ownersName"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Owner's Email"
          errorText={errors && errors.ownersEmail ? errors.ownersEmail.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter email id of owner"
            name="ownersEmail"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Owner's Mobile"
          isRequired
          requiredMark="after"
          errorText={errors && errors.mobile ? errors.mobile.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter mobile number of owner"
            name="mobile"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Address"
          isRequired
          requiredMark="after"
          errorText={errors && errors.address ? errors.address.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            name="address"
            isTextAreaInput={true}
            placeholder="Please enter address of transporter"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
            textAreaProps={{ rows: 4 }}
          />
        </FormItem>
        <FormItem
          label="City"
          isRequired
          requiredMark="after"
          errorText={errors && errors.city ? errors.city.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter city of transporter"
            name="city"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="State"
          errorText={errors && errors.state ? errors.state.message : undefined}
          requiredMark="after"
          isRequired
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select state of transporter"
            name="state"
            rhfControllerProps={{
              control,
            }}
            selectProps={{ showSearch: true }}
            options={statesOptionsForSelect}
          />
        </FormItem>
        <FormItem
          label="Pincode"
          isRequired
          requiredMark="after"
          errorText={errors && errors.pincode ? errors.pincode.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter pincode of transporter"
            name="pincode"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
          <div
            style={
              calledFrom === 'outward'
                ? { display: 'flex', justifyContent: 'end' }
                : { display: 'block' }
            }
          >
            <Button
              htmlType="submit"
              type="primary"
              loading={btnLoader}
              style={{ marginRight: 10 }}
            >
              {mode === 'edit' ? 'Update' : 'Create Transporter'}
            </Button>
            <Button
              type="default"
              onClick={() => {
                /* if this form is called from 'management -> Transporter section' then navigate to view all transporter screen.
                other wise close modal */
                if (calledFrom === 'management') {
                  /* navigate to view all transporter */
                  navigate('/management/transporters');
                } else {
                  /* close modal */
                  closeModal();
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </FormItem>
      </form>
    </>
  );
};

export default AddOrEditTransporterForm;
