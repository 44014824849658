import axios from 'axios';
import dayjs from 'dayjs';
import { GetWeightResponseDataType } from './types';

/* array of states in India */
const states = [
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chhattisgarh',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
  'Andaman and Nicobar Islands',
  'Chandigarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Lakshadweep',
  'Puducherry',
];

/* common input component style */
export const inputComponentCommonStyle = {
  /* border radius of input component */
  borderRadius: 5,
  /* border to input component */
  border: '1px solid black',
  /* width of input component */
  width: '100%',
};

/* formItem component styling props */
export const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 8,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
};

/* states having value and label as their name, which will be rendered in select option dropdown while choosing users state */
export const statesOptionsForSelect = states.map((state) => ({ value: state, label: state }));

/* function used to execute onClick of 'Get Weight' button of 'Create weight bridge screen'  */
export const getWeightAndCaptureCctvImg = async (tareWeight = false) => {
  if (process.env.NODE_ENV === 'production') {
    /* make a HTTP GET request */
    const response = await axios.get('http://localhost:3000');

    /* const used to store 'response.data' which holds the object and body return from above request */
    const responseData = response.data as GetWeightResponseDataType;

    // const returnObj = await axios.get('http://192.168.1.10:3000');
    if (responseData && responseData.weightKg && responseData.s3Key) {
      return responseData;
    }

    return {
      // weightKg: null,
      s3Key: null,
    };
  }

  if (tareWeight === true) {
    return {
      // weightKg: Math.floor(Math.random() * (6000 - 2000 + 1) + 2000),
      s3Key: 'emptyTruck.png',
      // s3Key: null,
    };
  }

  return {
    // weightKg: Math.floor(Math.random() * (20000 - 15000 + 1) + 15000),
    s3Key: 'fullTruck.jpg',
    // s3Key: null,
  };
};

/* function is used to covert data into quintals */
export const toQuintals = (val: number) => {
  return val / 100;
};

// function is used to format the date .
export const dateFormatFunc = (date: Date | string) => {
  return dayjs(date).format('MMM DD, YYYY');
};

/* this const used to store default mill id */
export const defaultMillId = 1;

/* formItem style for edit vendor from 'farmer/Trader purchase history (report section)' screen  or
 edit buyer from 'buyer history (report section)' screen */
export const vendorOrBuyerHistoryFormItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 7,
  /* input column span of FormItem */
  inputColSpan: 16,
  /* formItem label style */
  labelStyle: { fontSize: 14 } as React.CSSProperties,
  /* formItem content style */
  customStyle: { paddingTop: 15, fontSize: 14 } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};
