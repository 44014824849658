import React, { useState } from 'react';
import { useForm, FieldError } from 'react-hook-form';
import { message, Button, Modal } from 'antd';
import * as yup from 'yup';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, ApolloError, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { errorHandlerForAddOrEditVendorForm, logger } from '../utils/helpers';
import {
  CreateVendorMutation,
  CreateVendorMutationVariables,
  GetAllItemCategoriesQuery,
  GetAllItemCategoriesQueryVariables,
  Enum_Item_Category_Enum,
  RmSellers,
} from '../graphql/graphql-types';
import Select from './Select';
import FormItem from './FormItem';
import Input from './Input';
import { inputComponentCommonStyle } from '../utils/globals';
import RequiredMessage from './RequiredMessage';

/* QuickAddVendorForm prop type */
type QuickAddVendorFormPropType = {
  /* this prop type used to handle modal visibility */
  isModalOpen: boolean;
  /* prop used to assign initial value of 'item category' field */
  bagType: Enum_Item_Category_Enum;
  /* prop type used to close form modal */
  closeModal: () => void;
  /* this prop is used to know from which screen the component is called inward or outward and this is optional prop */
  shipmentType?: 'inward' | 'outward';
  /* prop use to know whether component is called from material unloading screen, so that we can manage UI and other form conditions accordingly */
  calledFromMaterialUnloading?: boolean;
};

/* QuickAddVendorForm useForm type */
type QuickAddVendorFormFormType = Pick<
  RmSellers,
  'name' | 'primaryContactMobile' | 'primaryContactName' | 'gstin'
> & {
  /* item category */
  itemCategory: Enum_Item_Category_Enum[];
};

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 7,
  /* input column span of FormItem */
  inputColSpan: 17,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* form validation */
const schema = yup.object({
  name: yup.string().required(`Please enter name and try again.`),
  itemCategory: yup
    .array()
    .min(1, 'Please select item category and try again.')
    .when('$calledFromMaterialUnloading', {
      is: (calledFromMaterialUnloading: boolean) => !calledFromMaterialUnloading,
      then: yup.array().required('Please select item category and try again'),
    }),

  primaryContactName: yup
    .string()
    .nullable()
    .when('$calledFromMaterialUnloading', {
      is: (calledFromMaterialUnloading: boolean) => !calledFromMaterialUnloading,
      then: yup.string().nullable().required('Please enter contact name and try again.'),
    }),
  primaryContactMobile: yup
    .string()
    .nullable()
    .when('$calledFromMaterialUnloading', {
      is: (calledFromMaterialUnloading: boolean) => !calledFromMaterialUnloading,
      then: yup
        .string()
        .nullable()
        .matches(validationRegex.mobile, {
          message: 'Please enter a valid mobile number',
        })
        .required('Please enter mobile number and try again.'),
    }),
  gstin: yup
    .string()
    .nullable()
    .when('$calledFromMaterialUnloading', {
      is: (calledFromMaterialUnloading: boolean) => calledFromMaterialUnloading,
      then: yup.string().nullable().matches(validationRegex.gstin, {
        message: 'Please enter a valid GSTIN ',
        excludeEmptyString: true,
      }),
    }),
});

/* loading create vendor mutation  */
const createVendorMutation = loader('../graphql/mutations/createVendorMutation.graphql');

/* loading get all Item Categories vendors query */
const getItemCategoriesQuery = loader('../graphql/queries/getAllItemCategoriesQuery.graphql');

/* loading get Vendor list query */
const getVendorListQuery = loader('../graphql/queries/customVendorListQuery.graphql');

/* variable used to store placeholder for 'name' field */
let nameFieldPlaceholder: string;

/* this variable used to store title of the modal */
let modalTitle: string;

/* variable used to store label for the 'submit' button */
let buttonLabel: string;

/* React functional component */
const QuickAddVendorOrBuyerForm = ({
  isModalOpen,
  closeModal,
  bagType,
  shipmentType,
  calledFromMaterialUnloading = false,
}: QuickAddVendorFormPropType) => {
  /* this state used to show loading indicator submit button */
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /* create new vendor mutation */
  const [createVendor] = useMutation<CreateVendorMutation, CreateVendorMutationVariables>(
    createVendorMutation,
  );

  /* get all Item Categories query used to show item categories as options on 'item category' field */
  const {
    data: getItemCategoriesData,
    loading: getItemCategoriesDataLoading,
    error: getItemCategoriesDataError,
  } = useQuery<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>(
    getItemCategoriesQuery,
  );

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<QuickAddVendorFormFormType>({
    resolver: yupResolver(schema),
    /* yup context used to validate useForm based on 'calledFromMaterialUnloading' prop value */
    context: { calledFromMaterialUnloading: calledFromMaterialUnloading },
    defaultValues: {
      name: '',
      itemCategory: [shipmentType === 'inward' ? bagType : Enum_Item_Category_Enum.RawMaterial],
      primaryContactName: null,
      primaryContactMobile: null,
      gstin: null,
    },
    mode: 'onChange',
  });

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getItemCategoriesDataError) {
    return <div className="errorText">{getItemCategoriesDataError.message}</div>;
  }

  /* function to handle submitted data */
  const onSubmit = handleSubmit((data) => {
    /* when user entered valid form input, set isSubmitBtnLoading state to true */
    setIsSubmitBtnLoading(true);

    /* destructing form data values */
    const { name, primaryContactMobile, primaryContactName, itemCategory, gstin } = data;

    createVendor({
      variables: {
        object: {
          name: name,
          gstin: calledFromMaterialUnloading && gstin ? gstin : null,
          primaryContactMobile: primaryContactMobile,
          primaryContactName: primaryContactName,
          vendorsItemCategories: {
            data: itemCategory.map((item) => ({ itemCategory: item })),
          },
        },
      },

      /*refetch get vendor list query so that, after adding a new vendor ,we get updated list */
      refetchQueries: [
        {
          query: getVendorListQuery,
          variables: {
            _item_category:
              shipmentType === 'inward' ? bagType : Enum_Item_Category_Enum.RawMaterial,
          },
        },
      ],
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Vendor has been successfully created.');
        /* close modal */
        closeModal();
        reset();
        setIsSubmitBtnLoading(false);
      })
      .catch((error: ApolloError) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorHandlerForAddOrEditVendorForm(error));
        setIsSubmitBtnLoading(false);
        logger(error);
      });
  });

  /* this is used to get label for 'name' field , modal's 'submit button' and to get 'modal title', from conditioning prop values */
  if (shipmentType === 'inward' && calledFromMaterialUnloading) {
    nameFieldPlaceholder = 'Farmer/Trader (purchase) name';
    modalTitle = 'Add New Farmer/Trader (purchase)';
    buttonLabel = 'Submit';
  } else if (shipmentType === 'inward') {
    nameFieldPlaceholder = 'Please enter name of the vendor';
    modalTitle = 'Add Vendor';
    buttonLabel = 'Create Vendor';
  } else {
    nameFieldPlaceholder = 'Please enter name of the buyer';
    modalTitle = 'Add Buyer';
    buttonLabel = 'Create Buyer';
  }

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={onSubmit}
    >
      <Modal
        visible={isModalOpen}
        title={modalTitle}
        footer={[
          <Button
            type="primary"
            key="submit"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={onSubmit}
            loading={isSubmitBtnLoading}
          >
            {buttonLabel}
          </Button>,
          <Button
            type="default"
            key="cancel"
            onClick={() => {
              /* close modal */
              closeModal();
              /* reset useForm */
              reset();
            }}
          >
            Cancel
          </Button>,
        ]}
        onCancel={() => {
          /* close modal */
          closeModal();
        }}
        destroyOnClose
      >
        <RequiredMessage />
        <FormItem
          label="Name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder={nameFieldPlaceholder}
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>

        <FormItem
          label="Item Category"
          isRequired
          errorText={
            errors && errors.itemCategory
              ? (errors.itemCategory as unknown as FieldError).message
              : undefined
          }
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select item category"
            name="itemCategory"
            rhfControllerProps={{
              control,
            }}
            selectProps={{ showSearch: true, loading: getItemCategoriesDataLoading }}
            mode="multiple"
            options={
              getItemCategoriesData
                ? getItemCategoriesData.enum_item_category.map((item) => ({
                    value: item.value,
                    label: item.display_name as string,
                  }))
                : []
            }
          />
        </FormItem>
        {!calledFromMaterialUnloading ? (
          <>
            <FormItem
              label="Contact Name"
              isRequired
              errorText={
                errors && errors.primaryContactName ? errors.primaryContactName.message : undefined
              }
              {...formItemStyleProps}
            >
              <Input
                customStyles={inputComponentCommonStyle}
                placeholder={
                  shipmentType === 'inward'
                    ? 'Please enter contact name for vendor'
                    : 'Please enter contact name for buyer'
                }
                name="primaryContactName"
                rhfControllerProps={{
                  control,
                }}
              />
            </FormItem>
            <FormItem
              label="Contact Mobile"
              isRequired
              errorText={
                errors && errors.primaryContactMobile
                  ? errors.primaryContactMobile.message
                  : undefined
              }
              {...formItemStyleProps}
            >
              <Input
                customStyles={inputComponentCommonStyle}
                placeholder="Please enter contact mobile number"
                name="primaryContactMobile"
                rhfControllerProps={{
                  control,
                }}
              />
            </FormItem>
          </>
        ) : (
          <>
            <FormItem
              label="GST "
              errorText={errors && errors.gstin ? errors.gstin.message : undefined}
              {...formItemStyleProps}
            >
              <Input
                customStyles={inputComponentCommonStyle}
                placeholder="Please enter gst number"
                name="gstin"
                rhfControllerProps={{
                  control,
                }}
              />
            </FormItem>
          </>
        )}
      </Modal>
    </form>
  );
};

export default QuickAddVendorOrBuyerForm;
