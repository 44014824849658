import React, { useState } from 'react';
import { Button, Divider, message, Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { ApolloError, Reference, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  CreateInwardShipmentItemMutation,
  CreateInwardShipmentItemMutationVariables,
  InwardShipmentItems,
  UpdateInwardShipmentItemMutation,
  UpdateInwardShipmentItemMutationVariables,
} from '../../graphql/graphql-types';
import {
  GodownType,
  InwardShipmentsReportType,
  ItemsShipment,
  RawMaterialsType,
  RmSellersType,
} from '../../utils/types';
import { logger } from '../../utils/helpers';
import { defaultMillId } from '../../utils/globals';
import FormItem from '../../components/FormItem';
import Input from '../../components/Input';
import InputNumber from '../../components/InputNumber';
import RadioGroup from '../../components/RadioGroup';
import Select from '../../components/Select';
import Switch from '../../components/Switch';

/*  loading update inward shipment item mutation */
const updateInwardShipmentItemMutation = loader(
  '../../graphql/mutations/updateInwardShipmentItemMutation.graphql',
);

/*  loading create inward shipment item mutation */
const createInwardShipmentItemMutation = loader(
  '../../graphql/mutations/createInwardShipmentItemMutation.graphql',
);

/* form data  object type */
type FormDataObjectType = {
  /*  array of godown data */
  godownData: Array<GodownType>;
  /* array of raw material data */
  rawMaterialsData: Array<RawMaterialsType>;
  /* array of rmSellers data */
  RmSellersData: Array<RmSellersType>;
  /* process id */
  processId: number;
};

/* add or edit grn form prop type */
type AddOrEditGrnFormPropType = {
  /* prop type used to check whether the AddOrEditGrnModal form is being called from 'edit' mode or 'add' mode */
  mode: 'add' | 'edit';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  rawDataToEdit: ItemsShipment | undefined;
  /* prop type used to object of form data type */
  data: FormDataObjectType;
  /* prop type used to close AddOrEditGrnModal form modal */
  closeModal: () => void;
  /* prop type used to get inward shipment data  */
  inwardShipmentData: InwardShipmentsReportType;
};

/* add or edit grn from type */
type AddOrdEditGrnFormType = Pick<
  InwardShipmentItems,
  | 'rmSellerId'
  | 'rawMaterialId'
  | 'paddyGrade'
  | 'bagsCount'
  | 'materialReturnedReason'
  | 'godownId'
  | 'destination'
  | 'millId'
> & { materialReturned: boolean | null; emptyBagsReturned: boolean | null };

/* formItem component styling props */
export const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 24,
  /* input column span of FormItem */
  inputColSpan: 24,
  /* formItem custom styling */
  customStyle: { paddingTop: '16px' } as React.CSSProperties,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
};

/* common input component style */
export const inputComponentCommonStyle = {
  /* border radius of input component */
  borderRadius: 5,
  /* border to input component */
  border: '1px solid black',
  /* width of input component */
  width: '100%',
  /* margin top to input component */
  marginTop: '10px',
};

/* validations required for useForm */
const schema = yup.object({
  rmSellerId: yup.number().nullable().required('Please select farmer/trader name and try again.'),
  rawMaterialId: yup.number().nullable().required('Please select material type and try again.'),
  paddyGrade: yup.string().nullable().required('Please select paddy grade and try again.'),
  bagsCount: yup
    .number()
    .nullable()
    .required('Please enter number of bags and try again.')
    .integer('Please enter valid number.')
    .min(0, 'Please enter valid number'),
  destination: yup
    .string()
    .nullable()
    .when(['materialReturned'], {
      is: (materialReturned: boolean) => !materialReturned,
      then: yup.string().nullable().required('Please select unloading location and try again.'),
    }),
  godownId: yup
    .number()
    .nullable()
    .when(['destination', 'materialReturned'], {
      is: (destination: string, materialReturned: boolean) =>
        destination === 'godown' && !materialReturned,
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
});

/* React functional component */
const AddOrEditGrnModal = ({
  mode,
  closeModal,
  rawDataToEdit = undefined,
  data,
  inwardShipmentData,
}: AddOrEditGrnFormPropType) => {
  /* this state used to show loading indicator on submit or update button */
  const [isSubmitOrUpdateBtnLoading, setIsSubmitOrUpdateBtnLoading] = useState<boolean>(false);

  /* update inward shipment item mutation */
  const [updateInwardShipmentItem] = useMutation<
    UpdateInwardShipmentItemMutation,
    UpdateInwardShipmentItemMutationVariables
  >(updateInwardShipmentItemMutation);

  /* create inward shipment item mutation */
  const [createInwardShipmentItem] = useMutation<
    CreateInwardShipmentItemMutation,
    CreateInwardShipmentItemMutationVariables
  >(createInwardShipmentItemMutation);

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    setValue,
  } = useForm<AddOrdEditGrnFormType>({
    defaultValues:
      rawDataToEdit && mode === 'edit'
        ? {
            rmSellerId: rawDataToEdit.seller && rawDataToEdit.seller.id,
            rawMaterialId: rawDataToEdit.rawMaterial && rawDataToEdit.rawMaterial.id,
            paddyGrade: rawDataToEdit.paddyGrade,
            materialReturned: rawDataToEdit.materialReturned,
            destination: rawDataToEdit.destination,
            godownId: rawDataToEdit.godown && rawDataToEdit.godown.id,
            emptyBagsReturned: rawDataToEdit.emptyBagsReturned,
            materialReturnedReason: rawDataToEdit.materialReturnedReason,
            bagsCount: rawDataToEdit.bagsCount,
            millId: rawDataToEdit.mill && rawDataToEdit.mill.id,
          }
        : {
            rmSellerId: null,
            rawMaterialId: null,
            paddyGrade: '',
            materialReturned: false,
            destination: null,
            godownId: null,
            emptyBagsReturned: false,
            materialReturnedReason: '',
            bagsCount: null,
          },

    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /*  onSubmit function */
  const onSubmit = handleSubmit((formData: AddOrdEditGrnFormType) => {
    setIsSubmitOrUpdateBtnLoading(true);
    /* this const used to store mutation variables which are common in both create and update inward  item shipment */
    const commonMutationVariables = {
      inwardShipmentId: data.processId,
      rmSellerId: formData.rmSellerId as number,
      rawMaterialId: formData.rawMaterialId as number,
      paddyGrade: formData.paddyGrade ? formData.paddyGrade : null,
      bagsCount: formData.bagsCount as number,
      materialReturned: formData.materialReturned as boolean,
      emptyBagsReturned: formData.emptyBagsReturned as boolean,
      godownId:
        formData.materialReturned !== true && formData.destination === 'godown'
          ? formData.godownId
          : null,
      millId:
        formData.materialReturned !== true && formData.destination === 'mill'
          ? defaultMillId
          : null,
      materialReturnedReason:
        formData.materialReturned === true ? formData.materialReturnedReason : null,
      destination: formData.materialReturned !== true ? formData.destination : null,
    };

    /* if user has clicked on 'edit' then execute update inward shipment  item mutation  */
    if (mode === 'edit' && rawDataToEdit) {
      updateInwardShipmentItem({
        variables: {
          id: rawDataToEdit.id,
          ...commonMutationVariables,
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Inward shipment item has been successfully updated');
          setIsSubmitOrUpdateBtnLoading(false);
          closeModal();
          reset();
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
          setIsSubmitOrUpdateBtnLoading(false);
        });
    } else {
      createInwardShipmentItem({
        variables: {
          ...commonMutationVariables,
        },
        update(cache, { data: addedItems }) {
          if (inwardShipmentData) {
            cache.modify({
              id: cache.identify(inwardShipmentData),
              fields: {
                items(existingItems: Array<Reference>) {
                  return [...existingItems, addedItems];
                },
              },
            });
          }
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Inward shipment item has been successfully created.');
          reset();
          closeModal();
          setIsSubmitOrUpdateBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
          setIsSubmitOrUpdateBtnLoading(false);
        });
    }
  });

  return (
    <form>
      <Modal
        centered
        title={mode === 'edit' ? 'Edit Shipment Item' : 'Add Shipment Item'}
        visible={mode === 'add' || mode === 'edit'}
        onCancel={() => {
          closeModal();
        }}
        bodyStyle={{ marginTop: '0px', paddingTop: '0px', paddingBottom: '0px' }}
        footer={[
          <>
            <Divider style={{ marginTop: '10px' }} />
            <Button
              type="primary"
              key="submit"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={onSubmit}
              loading={isSubmitOrUpdateBtnLoading}
            >
              {mode === 'edit' ? 'Update' : 'Submit'}
            </Button>
          </>,
          <Button
            type="default"
            key="cancel"
            onClick={() => {
              /* close Modal */
              closeModal();
              /* reset useForm */
              reset();
            }}
          >
            Cancel
          </Button>,
        ]}
        destroyOnClose
      >
        <>
          <FormItem
            label="Farmer/Trader (Purchase) Name"
            isRequired
            requiredMark="after"
            {...formItemStyleProps}
            errorText={errors && errors.rmSellerId ? errors.rmSellerId.message : undefined}
          >
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select farmer/trader purchase name"
              name="rmSellerId"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                optionFilterProp: 'children',
              }}
              options={
                data.RmSellersData
                  ? data.RmSellersData.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []
              }
            />
          </FormItem>
          <FormItem
            label="Material Type"
            isRequired
            requiredMark="after"
            errorText={errors && errors.rawMaterialId ? errors.rawMaterialId.message : undefined}
            {...formItemStyleProps}
          >
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select material type"
              name="rawMaterialId"
              rhfControllerProps={{
                control,
              }}
              selectProps={{ showSearch: true, optionFilterProp: 'children' }}
              options={
                data.rawMaterialsData
                  ? data.rawMaterialsData.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []
              }
            />
          </FormItem>
          <FormItem
            label="Paddy Grade "
            isRequired
            requiredMark="after"
            errorText={errors && errors.paddyGrade ? errors.paddyGrade.message : undefined}
            {...formItemStyleProps}
          >
            <RadioGroup
              name="paddyGrade"
              style={{ marginTop: '10px' }}
              rhfControllerProps={{
                control,
              }}
              options={[
                { label: '1 (One)', value: '1 (One)' },
                {
                  label: '2 (Two)',
                  value: '2 (Two)',
                },
                {
                  label: '3 (Three)',
                  value: '3 (Three)',
                },
              ]}
            />
          </FormItem>
          <FormItem
            label="Mark paddy as returned"
            labelColSpan={12}
            inputColSpan={12}
            customStyle={{ paddingTop: '16px' }}
          >
            <Switch
              name="materialReturned"
              rhfControllerProps={{
                control,
              }}
            />
          </FormItem>
          {!watch('materialReturned') ? (
            <>
              <FormItem
                label="Material unloaded at"
                {...formItemStyleProps}
                isRequired
                requiredMark="after"
                errorText={errors && errors.destination ? errors.destination.message : undefined}
              >
                <RadioGroup
                  name="destination"
                  style={{ marginTop: '10px' }}
                  rhfControllerProps={{
                    control,
                  }}
                  defaultValue={null}
                  options={[
                    { label: 'Godown (for storage)', value: 'godown' },
                    {
                      label: 'Mill (for processing)',
                      value: 'mill',
                    },
                  ]}
                  onChange={(e) => {
                    /* on selection of 'destination', set 'destination godown id ' or 'destination mill id ' as a null */
                    if (e.target.value === 'mill') {
                      /* if user selected 'destination' as 'mill then set 'godownId' to null */
                      setValue('godownId', null);
                    } else {
                      /* if 'godown' is selected as destination then set destination mill Id to null */
                      setValue('millId', null);
                    }
                  }}
                />
              </FormItem>
              {watch('destination') === 'godown' ? (
                <FormItem
                  label="Select Godown "
                  isRequired
                  requiredMark="after"
                  errorText={errors && errors.godownId ? errors.godownId.message : undefined}
                  {...formItemStyleProps}
                >
                  <Select
                    customStyles={inputComponentCommonStyle}
                    placeholder="Please select godown "
                    name="godownId"
                    rhfControllerProps={{
                      control,
                    }}
                    selectProps={{
                      showSearch: true,
                      optionFilterProp: 'children',
                    }}
                    options={
                      data.godownData
                        ? data.godownData.map((item) => ({
                            value: item.id,
                            label: item.name,
                          }))
                        : []
                    }
                  />
                </FormItem>
              ) : null}

              {!watch('materialReturned') ? (
                <FormItem
                  label="Empty bags returned"
                  labelColSpan={12}
                  inputColSpan={12}
                  customStyle={{ paddingTop: '16px' }}
                >
                  <Switch
                    name="emptyBagsReturned"
                    rhfControllerProps={{
                      control,
                    }}
                  />
                </FormItem>
              ) : null}
            </>
          ) : null}
          {watch('materialReturned') ? (
            <FormItem label="Reason why paddy is returned" {...formItemStyleProps}>
              <Input
                name="materialReturnedReason"
                isTextAreaInput={true}
                placeholder="Please enter the reason of paddy return"
                rhfControllerProps={{
                  control,
                }}
                customStyles={inputComponentCommonStyle}
                textAreaProps={{ rows: 2 }}
              />
            </FormItem>
          ) : null}
          <FormItem
            label="Number of bags"
            requiredMark="after"
            isRequired
            errorText={errors && errors.bagsCount ? errors.bagsCount.message : undefined}
            labelColSpan={12}
            inputColSpan={12}
            customStyle={{ paddingTop: '16px' }}
          >
            <InputNumber
              customStyles={{
                borderRadius: 5,
                border: '1px solid black',
                width: '100%',
              }}
              placeholder="Please enter number of bags"
              name="bagsCount"
              rhfControllerProps={{
                control,
              }}
              inputNumberProps={{ min: 0 }}
            />
          </FormItem>
        </>
      </Modal>
    </form>
  );
};

export default AddOrEditGrnModal;
