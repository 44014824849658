class AuthData {
  token = '';

  getToken = (): string => this.token;

  setToken = (token: string): void => {
    this.token = token;
  };

  removeToken = (): void => {
    this.token = '';
  };
}

const authData = new AuthData();

export default authData;
