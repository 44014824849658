import React, { useState } from 'react';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useNavigate } from 'react-router-dom';
import {
  GetAllGodownsQuery,
  GetAllGodownsQueryVariables,
  DeleteGodownMutation,
  DeleteGodownMutationVariables,
  Godowns,
} from '../../../graphql/graphql-types';
import { Button, message, Popconfirm, Table } from 'antd';
import { PlusOutlined, SearchOutlined, WarningFilled } from '@ant-design/icons';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import { logger } from '../../../utils/helpers';
import colors from '../../../scss/variables.module.scss';

/* View all godown table data type */
type ViewAllGodownsTableType = Pick<Godowns, 'id' | 'capacityBags' | 'name' | 'location'>;

/* loading get all godown data query */
const getAllGodownQuery = loader('../../../graphql/queries/getAllGodownsQuery.graphql');

/* loading delete godown mutation  */
const deleteGodownMutation = loader('../../../graphql/mutations/deleteGodownMutation.graphql');

/* React functional component */
const ViewAllGodownsScreen = () => {
  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();
  /* state used to store id of the godown whose delete godown button is clicked which is then used to show loading indicator on delete button while deleting a godown */
  const [godownIdToDltAndShowLoading, setGodownIdToDltAndShowLoading] = useState<number | null>(
    null,
  );

  /* get all godown data query */
  const {
    data: getAllGodownData,
    loading: getAllGodownsLoading,
    error: getAllGodownsError,
  } = useQuery<GetAllGodownsQuery, GetAllGodownsQueryVariables>(getAllGodownQuery);

  /* delete a godown mutation */
  const [deleteGodown] = useMutation<DeleteGodownMutation, DeleteGodownMutationVariables>(
    deleteGodownMutation,
  );

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getAllGodownsError) {
    return <div className="errorText">{getAllGodownsError.message}</div>;
  }

  /* function to handle delete godown */
  const deleteGodownFunc = (godownId: number) => {
    deleteGodown({
      variables: {
        id: godownId,
      },
      /* after deleting a godown, the update function is used to update the cache and display an updated godowns array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a godown that was just removed. */
        const deletedDataId = deleteData?.deleteGodown?.id;
        cache.modify({
          fields: {
            godowns(existingGodowns: Array<Reference>, { readField }) {
              if (deletedDataId) {
                return existingGodowns.filter(
                  (godownRef) => deletedDataId !== readField('id', godownRef),
                );
              }
              return existingGodowns;
            },
          },
        });
      },
    })
      .then(() => {
        setGodownIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Godown has been deleted successfully.');
      })
      .catch((deleteGodownError: ApolloError) => {
        let errorMsg = deleteGodownError.message;
        setGodownIdToDltAndShowLoading(null);
        if (deleteGodownError.message.includes('Foreign key violation.')) {
          errorMsg = 'Cannot delete since godown is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteGodownError);
      });
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          navigate('create');
        }}
      >
        Create godown
      </Button>
      <Table<ViewAllGodownsTableType>
        dataSource={getAllGodownData && getAllGodownData.getAllGodowns}
        loading={getAllGodownsLoading}
        className="tableStyle"
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<ViewAllGodownsTableType>
          key="id"
          title="Godown ID"
          dataIndex="id"
          align="center"
        />
        <Table.Column<ViewAllGodownsTableType>
          key="name"
          title="Godown Name"
          dataIndex="name"
          align="center"
          render={(GodownName) => {
            return <span style={{ textTransform: 'capitalize' }}>{GodownName || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.name
              ? record.name.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="godown name" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<ViewAllGodownsTableType>
          key="location"
          title="Godown Location"
          dataIndex="location"
          align="center"
          render={(LocationName) => {
            return <span style={{ textTransform: 'capitalize' }}>{LocationName || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.name
              ? record.name.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="godown location"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<ViewAllGodownsTableType>
          key="capacityBags"
          title="Godown Capacity (no. of Bags)"
          dataIndex="capacityBags"
          align="center"
        />
        <Table.Column<ViewAllGodownsTableType>
          key="Actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    navigate(`${record.id}/edit`);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete Godown. Are you sure?"
                  okText="Yes"
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                  onConfirm={() => {
                    deleteGodownFunc(record.id);
                    setGodownIdToDltAndShowLoading(record.id);
                  }}
                >
                  <Button
                    className="deleteButton"
                    loading={record.id === godownIdToDltAndShowLoading}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};

export default ViewAllGodownsScreen;
