import React from 'react';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import { GetBrokerByIdQuery, GetBrokerByIdQueryVariables } from '../../../graphql/graphql-types';
import { Spin } from 'antd';
import AddOrEditBrokerForm from './AddOrEditBrokerForm';
import { AddressType } from '../../../utils/types';

/* loading the get broker by id query  with the help of loader */
const getBrokerByIdQuery = loader('../../../graphql/queries/getBrokerByIdQuery.graphql');

/* React functional component */
const EditBrokerScreen = () => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* get broker by ID query */
  const {
    data: getBrokerByIdData,
    loading: getBrokerByIdLoading,
    error: getBrokerByIdError,
  } = useQuery<GetBrokerByIdQuery, GetBrokerByIdQueryVariables>(getBrokerByIdQuery, {
    variables: { id: params.id as string },
  });

  /* show loading indicator on the screen until data get fetch from the server */
  if (getBrokerByIdLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getBrokerByIdError) {
    return <div className="errorText">{getBrokerByIdError.message}</div>;
  }

  /* getting address data from getBrokerByIdData.brokers_by_pk in order to further use it while passing the data to broker form */
  let addressData;
  if (getBrokerByIdData && getBrokerByIdData.brokers_by_pk) {
    addressData = getBrokerByIdData.brokers_by_pk.address as AddressType;
  }

  return (
    <>
      {getBrokerByIdData && getBrokerByIdData.brokers_by_pk ? (
        <AddOrEditBrokerForm
          mode="edit"
          brokerDataToEdit={{
            name: getBrokerByIdData.brokers_by_pk.name,
            gst: getBrokerByIdData.brokers_by_pk.gst,
            email: getBrokerByIdData.brokers_by_pk.email,
            mobile: getBrokerByIdData.brokers_by_pk.mobile,
            address: addressData ? addressData.address : '',
            state: addressData ? addressData.state : '',
            city: addressData ? addressData.city : '',
            pincode: addressData ? addressData.pincode : '',
          }}
        />
      ) : null}
    </>
  );
};

export default EditBrokerScreen;
