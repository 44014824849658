import React from 'react';
import { Menu } from 'antd';
import { Link, Outlet, useLocation } from 'react-router-dom';
import colors from '../../scss/variables.module.scss';

/* outward user sub menu data */
const outwardUserSubMenu = [
  { name: 'Product', path: '/shipment/outward/product/invoicing' },
  { name: 'Bags', path: '/shipment/outward/bags/paddy' },
  { name: 'Other Items', path: '/shipment/outward/otherItems' },
];

/* React functional component */
const OutwardUserTabLayout = () => {
  /* extracting location from useLocation hook */
  const location = useLocation();
  return (
    <div className="subMenu">
      <Menu
        style={{ backgroundColor: colors.colorBlack, display: 'flex' }}
        selectedKeys={[location.pathname]}
      >
        {outwardUserSubMenu.map((item) => {
          return (
            <Menu.Item key={item.path}>
              <Link to={item.path} style={{ fontWeight: '500', fontSize: 14 }}>
                {item.name}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
      <Outlet />
    </div>
  );
};

export default OutwardUserTabLayout;
