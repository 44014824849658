import React, { useState } from 'react';
import { Card, Col, Divider, Row, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import dayjs from 'dayjs';
import {
  GetOutwardShipmentProductsForMonitoringQueryQuery,
  GetOutwardShipmentProductsForMonitoringQueryQueryVariables,
} from '../../../../graphql/graphql-types';
import FormItem from '../../../../components/FormItem';
import colors from '../../../../scss/variables.module.scss';
import RadioGroup from '../../../../components/RadioGroup';

/* loading get outward shipments product for monitoring query */
const getOutwardShipmentProductsForMonitoringQuery = loader(
  '../../../../graphql/queries/getOutwardShipmentProductsForMonitoringQuery.graphql',
);

/* outward select form field type*/
type OutwardSelectFormFieldType = {
  /*  filter status  */
  status: string;
};

/* React functional component */
const MonitorShipmentsOutward = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* status state used to stored filter status value  */
  const [status, setStatus] = useState<
    'all' | 'Open' | 'Loading done' | 'Loading' | 'Invoice not added'
  >('all');

  /*  get outward shipments product for monitoring query */
  const {
    data: getOutwardShipmentProductsForMonitoringData,
    loading: getOutwardShipmentProductsForMonitoringLoading,
    error: getOutwardShipmentProductsForMonitoringError,
  } = useQuery<
    GetOutwardShipmentProductsForMonitoringQueryQuery,
    GetOutwardShipmentProductsForMonitoringQueryQueryVariables
  >(getOutwardShipmentProductsForMonitoringQuery);

  /* useForm Declration */
  const { control } = useForm<OutwardSelectFormFieldType>({
    defaultValues: {
      status: 'all',
    },
  });

  /* shipments used to stored filter data  */
  let shipments;
  if (getOutwardShipmentProductsForMonitoringData) {
    shipments =
      getOutwardShipmentProductsForMonitoringData.getOutwardShipmentProductsForMonitoring.filter(
        (shipment) => {
          if (status === 'all') {
            return shipment;
          }
          return shipment.status === status;
        },
      );
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getOutwardShipmentProductsForMonitoringLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getOutwardShipmentProductsForMonitoringError) {
    return <div className="errorText">{getOutwardShipmentProductsForMonitoringError.message}</div>;
  }

  return (
    <>
      <FormItem label="Filter by Status : " labelColSpan={2}>
        <RadioGroup
          name="status"
          rhfControllerProps={{
            control,
          }}
          defaultValue={'all'}
          options={[
            { label: 'All', value: 'all' },
            {
              label: 'Open',
              value: 'Open',
            },
            {
              label: 'Loading',
              value: 'Loading',
            },
            {
              label: 'Loading done',
              value: 'Loading done',
            },
            {
              label: 'Invoice not added',
              value: 'Invoice not added',
            },
          ]}
          onChange={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setStatus(e.target.value);
          }}
        />
      </FormItem>
      <div style={{ marginTop: '30px' }}>
        <Row gutter={[16, 16]}>
          {shipments &&
            shipments.map((shipment) => {
              const drivers = shipment.drivers.map((data) => {
                return (
                  <>
                    {data.driver.name} - {data.driver.mobile}
                    <br />
                  </>
                );
              });
              return (
                <Col span={8} key={shipment.id} style={{ marginBottom: 20 }}>
                  <Card
                    bordered
                    style={{ width: '90%', height: '100%' }}
                    type="inner"
                    hoverable
                    onClick={() => {
                      navigate(`/shipments/outward/${shipment.id}`);
                    }}
                  >
                    <span style={{ fontWeight: 'bold', color: colors.grnTextColor }}>
                      {shipment.grn}
                    </span>
                    <span style={{ float: 'right' }}>
                      {dayjs(shipment.createdAt).format('MMM DD, YYYY [at] h:mm A')}
                    </span>
                    <br />
                    {shipment.transporter && shipment.transporter.name}
                    <span style={{ float: 'right' }}>
                      {shipment.transporterVehicle && shipment.transporterVehicle.number}
                    </span>
                    <br />
                    {drivers}
                    <Divider />
                    <div style={{ textAlign: 'center' }}>{shipment.status}</div>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
};

export default MonitorShipmentsOutward;
