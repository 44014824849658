import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, ApolloError, useQuery, useLazyQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { logger } from '../../../utils/helpers';
import {
  GetAllRawMaterialsQuery,
  GetAllRawMaterialsQueryVariables,
  GetAllGodownsMillsQuery,
  GetAllGodownsMillsQueryVariables,
  CreateInternalTransferManualShipmentMutation,
  CreateInternalTransferManualShipmentMutationVariables,
  CustomItemListQuery,
  CustomItemListQueryVariables,
  GetAllProductsAndProductTypesQuery,
  GetAllProductsAndProductTypesQueryVariables,
  GetAllItemCategoriesQuery,
  GetAllItemCategoriesQueryVariables,
  InternalTransferShipments,
  InternalTransferShipmentItems,
  Enum_Item_Category_Enum,
  Enum_InternalTransfer_Source_Destination_Enum,
} from '../../../graphql/graphql-types';
import Select from '../../../components/Select';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import { inputComponentCommonStyle } from '../../../utils/globals';
import RadioGroup from '../../../components/RadioGroup';
import InputNumber from '../../../components/InputNumber';
import { Button, message } from 'antd';

/* loading get all items category query */
const getAllItemsCategoriesQuery = loader(
  '../../../graphql/queries/getAllItemCategoriesQuery.graphql',
);

/* loading get all raw material query */
const getAllRawMaterialQuery = loader('../../../graphql/queries/getAllRawMaterialsQuery.graphql');

/* loading get all godowns and mills query */
const getGodownAndMillsQuery = loader('../../../graphql/queries/getAllGodownsMillsQuery.graphql');

/* loading get item list query */
const getItemListQuery = loader('../../../graphql/queries/customItemListQuery.graphql');

/* loading get all products and product types query */
const getAllProductsAndProductTypesQuery = loader(
  '../../../graphql/queries/getAllProductsAndProductTypesQuery.graphql',
);

/* loading create internal transfer mutation  */
const createInternalTransferMutation = loader(
  '../../../graphql/mutations/createInternalTransferManualShipmentMutation.graphql',
);

/* manual transfer form type */
type ManualInternalTransferFormType = Pick<
  InternalTransferShipments,
  'personMobile' | 'personName' | 'vehicleNumber'
> & {
  materialTypeId: Enum_Item_Category_Enum | null;
  bagWtKg: number | null;
  rmEmptyBagsWtKg: number | null;
  netMaterialWtPerBagKg: number | null;
  productTypeId: number | null;
  othersQuantity: number | null;
  othersWtKg: number | null;
  destination: Enum_InternalTransfer_Source_Destination_Enum | null;
  source: Enum_InternalTransfer_Source_Destination_Enum | null;
} & Pick<
    InternalTransferShipmentItems,
    | 'sourceGodownId'
    | 'sourceMillId'
    | 'destinationGodownId'
    | 'destinationMillId'
    | 'rawMaterialId'
    | 'rmPaddyGrade'
    | 'bagsCount'
    | 'destinationMill'
    | 'itemId'
    | 'productId'
    | 'othersItemName'
    | 'remark'
  >;

/* form validation schema */
const schema = yup.object({
  materialTypeId: yup.string().required('Please select material type and try again.').nullable(),
  personName: yup.string().required('Please enter delivery person name and try again.'),
  personMobile: yup
    .string()
    .matches(validationRegex.mobile, {
      message: 'Please enter a valid mobile number',
      excludeEmptyString: true,
    })
    .nullable(),
  vehicleNumber: yup.string().matches(validationRegex.vehicleRegistrationNumber, {
    message: 'Please enter a valid vehicle number',
    excludeEmptyString: true,
  }),
  rawMaterialId: yup
    .number()
    .nullable()
    .when(['materialTypeId'], {
      is: (materialTypeId: string) => materialTypeId === 'raw_material',
      then: yup.number().required('Please select raw material and try again.').nullable(),
    }),
  rmPaddyGrade: yup
    .string()
    .nullable()
    .when(['materialTypeId'], {
      is: (materialTypeId: string) => materialTypeId === 'raw_material',
      then: yup.string().required('Please select paddy grade and try again').nullable(),
    }),
  bagWtKg: yup
    .number()
    .nullable()
    .when(['materialTypeId'], {
      is: (materialTypeId: string) =>
        materialTypeId === 'raw_material' || materialTypeId === 'product',
      then: yup
        .number()
        .required('Please enter single bag weight and try again.')
        .positive('Please enter valid number')
        .nullable(),
    }),
  bagsCount: yup
    .number()
    .nullable()
    .when(['materialTypeId'], {
      is: (materialTypeId: string) => materialTypeId !== 'others',
      then: yup
        .number()
        .required('Please enter number of bags and try again.')
        .integer('Please enter valid number.')
        .min(0, 'Please enter valid number')
        .nullable(),
    }),
  sourceMillId: yup
    .number()
    .nullable()
    .when(['source', 'materialTypeId', 'itemId'], {
      is: (source: string, materialTypeId: string, itemId: number) =>
        (source === 'mill' && materialTypeId !== 'others') ||
        (itemId !== null && source !== 'godown'),
      then: yup.number().nullable().required('Please select mill and try again.'),
    }),
  sourceGodownId: yup
    .number()
    .nullable()
    .when(['source', 'materialTypeId', 'itemId'], {
      is: (source: string, materialTypeId: string, itemId: number) =>
        (itemId !== null && source !== 'mill') ||
        (source === 'godown' && materialTypeId !== 'others'),
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
  destinationGodownId: yup
    .number()
    .nullable()
    .when(['destination', 'materialTypeId', 'itemId'], {
      is: (destination: string, materialTypeId: string, itemId: number) =>
        (destination === 'godown' && materialTypeId !== 'others') ||
        (itemId !== null && destination !== 'mill'),
      then: yup.number().nullable().required('Please select godown and try again.'),
    }),
  destinationMillId: yup
    .number()
    .nullable()
    .when(['destination', 'materialTypeId', 'itemId'], {
      is: (destination: string, materialTypeId: string, itemId: number) =>
        (destination === 'mill' && materialTypeId !== 'others') ||
        (itemId !== null && destination !== 'godown'),
      then: yup.number().nullable().required('Please select mill and try again.'),
    }),
  rmEmptyBagsWtKg: yup.number().nullable().positive('Please enter valid number'),
  itemId: yup
    .string()
    .nullable()
    .when(['materialTypeId'], {
      is: (materialTypeId: string) =>
        materialTypeId === 'paddy_bag' || materialTypeId === 'product_bag',
      then: yup.string().nullable().required('Please select item and try again.'),
    }),
  productTypeId: yup
    .number()
    .nullable()
    .when(['materialTypeId'], {
      is: (materialTypeId: string) => materialTypeId === 'product',
      then: yup.number().nullable().required('Please select product type and try again.'),
    }),
  productId: yup
    .number()
    .nullable()
    .when(['materialTypeId'], {
      is: (materialTypeId: string) => materialTypeId === 'product',
      then: yup.number().nullable().required('Please select product and try again.'),
    }),
  othersQuantity: yup
    .number()
    .nullable()
    .when(['materialTypeId'], {
      is: (materialTypeId: string) => materialTypeId === 'others',
      then: yup
        .number()
        .integer('Please enter valid number.')
        .min(0, 'Please enter valid number')
        .nullable(),
    }),
  othersWtKg: yup
    .number()
    .nullable()
    .when(['materialTypeId'], {
      is: (materialTypeId: string) => materialTypeId === 'others',
      then: yup.number().nullable().positive('Please enter valid number'),
    }),
});

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 5,
  /* input column span of FormItem */
  inputColSpan: 7,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* react component */
const ManualInternalTransferScreen = () => {
  /* this used to show loading  indicator on submit button */
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState<boolean>(false);

  /* create new manual internal transfer mutation */
  const [createInternalTransfer] = useMutation<
    CreateInternalTransferManualShipmentMutation,
    CreateInternalTransferManualShipmentMutationVariables
  >(createInternalTransferMutation);

  /* get custom item list query used to show list of items as options on 'Select item' field */
  const [
    getItemList,
    { data: getItemListData, loading: getItemListDataLoading, error: getItemListDataError },
  ] = useLazyQuery<CustomItemListQuery, CustomItemListQueryVariables>(getItemListQuery);

  /* get all products and product type data query used to show list of products & product types as options on 'Product Type' & 'Product' Select field */
  const {
    data: getAllProductsAndProductTypeData,
    loading: getAllProductsAndProductTypeDataLoading,
    error: getAllProductsAndProductTypeDataError,
  } = useQuery<GetAllProductsAndProductTypesQuery, GetAllProductsAndProductTypesQueryVariables>(
    getAllProductsAndProductTypesQuery,
  );

  /* get all item categories data query used to show list of items as material types options on 'Material type' field */
  const {
    data: getAllItemsCategoriesData,
    loading: getAllItemsCategoriesLoading,
    error: getAllItemsCategoriesError,
  } = useQuery<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>(
    getAllItemsCategoriesQuery,
  );

  /* get all godown and mills data query used to show list of godowns and mills as options on 'Select source or destination godown' & 'select source or destination mill' fields */
  const {
    data: getGodownAndMillsData,
    loading: getGodownAndMillsDataLoading,
    error: getGodownAndMillsDataError,
  } = useQuery<GetAllGodownsMillsQuery, GetAllGodownsMillsQueryVariables>(getGodownAndMillsQuery);

  /* get all raw material data query used to show list of raw material as options on 'Raw material' field */
  const {
    data: getAllRawMaterialData,
    loading: getAllRawMaterialLoading,
    error: getAllRawMaterialError,
  } = useQuery<GetAllRawMaterialsQuery, GetAllRawMaterialsQueryVariables>(getAllRawMaterialQuery);

  /* show error text on the screen. if it has any error while loading data from the server */
  if (
    getItemListDataError ||
    getAllProductsAndProductTypeDataError ||
    getAllItemsCategoriesError ||
    getGodownAndMillsDataError ||
    getAllRawMaterialError
  ) {
    let errorMessage;
    if (getItemListDataError) {
      errorMessage = getItemListDataError.message;
    } else if (getAllProductsAndProductTypeDataError) {
      errorMessage = getAllProductsAndProductTypeDataError.message;
    } else if (getAllItemsCategoriesError) {
      errorMessage = getAllItemsCategoriesError.message;
    } else if (getGodownAndMillsDataError) {
      errorMessage = getGodownAndMillsDataError.message;
    } else if (getAllRawMaterialError) {
      errorMessage = getAllRawMaterialError.message;
    }

    return <div className="errorText">{errorMessage}</div>;
  }

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ManualInternalTransferFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      personMobile: '',
      vehicleNumber: '',
      personName: '',
      materialTypeId: null,
      rawMaterialId: null,
      bagWtKg: null,
      rmPaddyGrade: '',
      bagsCount: null,
      source: null,
      sourceGodownId: null,
      sourceMillId: null,
      destination: null,
      destinationGodownId: null,
      destinationMillId: null,
      rmEmptyBagsWtKg: null,
      netMaterialWtPerBagKg: null,
      itemId: null,
      othersQuantity: null,
      othersWtKg: null,
      productId: null,
      othersItemName: '',
      remark: '',
      productTypeId: null,
    },
    mode: 'onChange',
  });

  /* this variable used to determine 'paddy grade' field's visibility, based on selected raw material type */
  let isPaddyGradeFieldVisible = false;
  if (getAllRawMaterialData) {
    if (watch('rawMaterialId')) {
      /* const used to store selected raw material data from dropdown */
      const selectedRawMaterial = getAllRawMaterialData.getAllRawMaterials.find(
        (item) => item.id === watch('rawMaterialId'),
      );
      /* set 'paddy grade' field visible if selected raw material type is 'paddy' */
      isPaddyGradeFieldVisible =
        selectedRawMaterial && selectedRawMaterial.type === 'paddy' ? true : false;
    }
  }

  /* function to handle submitted data */
  const onSubmit = (data: ManualInternalTransferFormType) => {
    setIsSubmitBtnLoading(true);

    /* destructing form data values */
    const {
      personMobile,
      personName,
      vehicleNumber,
      materialTypeId,
      bagWtKg,
      bagsCount,
      destination,
      destinationGodownId,
      destinationMillId,
      rawMaterialId,
      rmEmptyBagsWtKg,
      source,
      sourceGodownId,
      sourceMillId,
      rmPaddyGrade,
      netMaterialWtPerBagKg,
      productId,
      itemId,
      othersQuantity,
      othersWtKg,
      othersItemName,
      remark,
    } = data;

    createInternalTransfer({
      variables: {
        object: {
          personName: personName || null,
          personMobile: personMobile || null,
          vehicleNumber: vehicleNumber || null,
          materialType: materialTypeId || null,
          internalTransferShipmentItems: {
            data: [
              {
                bagWtKg: bagWtKg || null,
                bagsCount: bagsCount || null,
                destination: destination || null,
                destinationGodownId: destinationGodownId || null,
                destinationMillId: destinationMillId || null,
                rawMaterialId: rawMaterialId || null,
                rmEmptyBagsWtKg: rmEmptyBagsWtKg || null,
                source: source || null,
                sourceGodownId: sourceGodownId || null,
                sourceMillId: sourceMillId || null,
                rmPaddyGrade: rmPaddyGrade || null,
                netMaterialWtPerBagKg: netMaterialWtPerBagKg || null,
                itemId: itemId || null,
                othersQuantity: othersQuantity || null,
                othersWtKg: othersWtKg || null,
                productId: productId || null,
                othersItemName: othersItemName || null,
                remark: remark || null,
              },
            ],
          },
        },
      },
    })
      .then(() => {
        setIsSubmitBtnLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment has been successfully created.');
        reset();
      })
      .catch((error: ApolloError) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        setIsSubmitBtnLoading(false);
        logger(error);
      });
  };

  return (
    <form
      className="tabLayout"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      style={{ marginBottom: 20 }}
    >
      <FormItem
        label="Material Type"
        isRequired
        errorText={errors && errors.materialTypeId ? errors.materialTypeId.message : undefined}
        {...formItemStyleProps}
      >
        <Select
          customStyles={inputComponentCommonStyle}
          placeholder="Please select material type"
          name="materialTypeId"
          rhfControllerProps={{
            control,
          }}
          selectProps={{ showSearch: true, loading: getAllItemsCategoriesLoading }}
          options={
            getAllItemsCategoriesData
              ? getAllItemsCategoriesData.enum_item_category.map((item) => ({
                  value: item.value,
                  label: item.display_name as string,
                }))
              : []
          }
          onChange={(rhfOnChange, value) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            rhfOnChange(value);
            /* when material type is 'other', then set source and destination as 'null' */
            if (value === 'others') {
              setValue('source', null);
              setValue('destination', null);
            } else {
              /* initially set 'source' field as a 'godown' */
              setValue('source', Enum_InternalTransfer_Source_Destination_Enum.Godown);
              /* initially set 'destination' field as a 'godown' */
              setValue('destination', Enum_InternalTransfer_Source_Destination_Enum.Godown);
            }
            /* set 'itemId' field as a null on selection of material type */
            setValue('itemId', null);
            if (value === 'paddy_bag' || value === 'product_bag' || value === 'others') {
              /* get Item list for paddy bag,product bag or other items */
              void getItemList({ variables: { _category: value as Enum_Item_Category_Enum } });
            }
          }}
        />
      </FormItem>
      {watch('materialTypeId') ? (
        <>
          <FormItem
            label="Delivery Person Name"
            isRequired
            errorText={errors && errors.personName ? errors.personName.message : undefined}
            {...formItemStyleProps}
          >
            <Input
              customStyles={inputComponentCommonStyle}
              placeholder="Please enter the name of delivery person"
              name="personName"
              rhfControllerProps={{
                control,
              }}
            />
          </FormItem>
          <FormItem
            label="Delivery Person Mobile"
            errorText={errors && errors.personMobile ? errors.personMobile.message : undefined}
            {...formItemStyleProps}
          >
            <Input
              customStyles={inputComponentCommonStyle}
              placeholder="Please enter mobile number of delivery person"
              name="personMobile"
              rhfControllerProps={{
                control,
              }}
            />
          </FormItem>
          <FormItem
            label="Vehicle No."
            errorText={errors && errors.vehicleNumber ? errors.vehicleNumber.message : undefined}
            {...formItemStyleProps}
          >
            <Input
              customStyles={inputComponentCommonStyle}
              placeholder="Please enter vehicle number"
              name="vehicleNumber"
              rhfControllerProps={{
                control,
              }}
              onChange={(rhfOnChange, value) => {
                rhfOnChange(value.toUpperCase());
              }}
            />
          </FormItem>
          {watch('materialTypeId') === 'raw_material' ? (
            <>
              <FormItem
                label="Raw Material"
                isRequired
                errorText={
                  errors && errors.rawMaterialId ? errors.rawMaterialId.message : undefined
                }
                {...formItemStyleProps}
              >
                <Select
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please select raw material"
                  name="rawMaterialId"
                  rhfControllerProps={{
                    control,
                  }}
                  selectProps={{
                    showSearch: true,
                    loading: getAllRawMaterialLoading,
                    optionFilterProp: 'children',
                  }}
                  options={
                    getAllRawMaterialData
                      ? getAllRawMaterialData.getAllRawMaterials.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                      : []
                  }
                />
              </FormItem>
              {isPaddyGradeFieldVisible ? (
                <FormItem
                  label="Paddy Grade "
                  isRequired
                  errorText={
                    errors && errors.rmPaddyGrade ? errors.rmPaddyGrade.message : undefined
                  }
                  {...formItemStyleProps}
                >
                  <RadioGroup
                    name="rmPaddyGrade"
                    rhfControllerProps={{
                      control,
                    }}
                    options={[
                      { label: '1 (One)', value: '1 (One)' },
                      {
                        label: '2 (Two)',
                        value: '2 (Two)',
                      },
                      {
                        label: '3 (Three)',
                        value: '3 (Three)',
                      },
                    ]}
                  />
                </FormItem>
              ) : null}
            </>
          ) : null}
          {watch('materialTypeId') === 'product' ? (
            <>
              <FormItem
                label="Product Type"
                isRequired
                errorText={
                  errors && errors.productTypeId ? errors.productTypeId.message : undefined
                }
                {...formItemStyleProps}
              >
                <Select
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please select product type"
                  name="productTypeId"
                  rhfControllerProps={{
                    control,
                  }}
                  selectProps={{
                    showSearch: true,
                    optionFilterProp: 'children',
                    loading: getAllProductsAndProductTypeDataLoading,
                  }}
                  options={
                    getAllProductsAndProductTypeData
                      ? getAllProductsAndProductTypeData.getAllProductTypes.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                      : []
                  }
                  onChange={(rhfOnChange, value) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    rhfOnChange(value);
                    /* on change of 'product type', set 'product' field value as a null  */
                    setValue('productId', null);
                  }}
                />
              </FormItem>
              <FormItem
                label="Product"
                isRequired
                errorText={errors && errors.productId ? errors.productId.message : undefined}
                {...formItemStyleProps}
              >
                <Select
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please select product"
                  name="productId"
                  rhfControllerProps={{
                    control,
                  }}
                  selectProps={{
                    showSearch: true,
                    optionFilterProp: 'children',
                    loading: getAllProductsAndProductTypeDataLoading,
                  }}
                  options={
                    getAllProductsAndProductTypeData
                      ? getAllProductsAndProductTypeData.getAllProducts
                          .filter((product) => product.productType.id === watch('productTypeId'))
                          .map((item) => ({
                            value: item.id,
                            label: item.brand,
                          }))
                      : []
                  }
                />
              </FormItem>
            </>
          ) : null}
          {watch('materialTypeId') === 'raw_material' || watch('materialTypeId') === 'product' ? (
            <FormItem
              label="Single Bag Weight (in kgs) "
              isRequired
              errorText={errors && errors.bagWtKg ? errors.bagWtKg.message : undefined}
              {...formItemStyleProps}
            >
              <InputNumber
                customStyles={inputComponentCommonStyle}
                placeholder="Please enter single bag weight (in kgs)"
                name="bagWtKg"
                rhfControllerProps={{
                  control,
                }}
                inputNumberProps={{ min: 0, precision: 2 }}
                onChange={(value) => {
                  /* const used to store empty bag weight */
                  const emptyBagValue = watch('rmEmptyBagsWtKg')
                    ? (watch('rmEmptyBagsWtKg') as number)
                    : 0;

                  /* set 'netMaterialWtPerBagKg' field's value, which is calculated from 'empty bag' & 'single bag' weight */
                  setValue('netMaterialWtPerBagKg', Math.abs(emptyBagValue - value));
                }}
              />
            </FormItem>
          ) : null}
          {watch('materialTypeId') === 'paddy_bag' ||
          watch('materialTypeId') === 'product_bag' ||
          watch('materialTypeId') === 'others' ? (
            <FormItem
              label="Select Item"
              isRequired={watch('materialTypeId') === 'others' ? false : true}
              errorText={errors && errors.itemId ? errors.itemId.message : undefined}
              {...formItemStyleProps}
            >
              <Select
                customStyles={inputComponentCommonStyle}
                placeholder="Please select item"
                name="itemId"
                rhfControllerProps={{
                  control,
                }}
                onChange={(rhfOnChange, value) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  rhfOnChange(value);
                  /* when material type is 'other' and user selected item then set source and destination as 'godown' */
                  if (watch('itemId') && watch('materialTypeId') === 'others') {
                    /* set 'source' field as a 'godown' */
                    setValue('source', Enum_InternalTransfer_Source_Destination_Enum.Godown);
                    /*  set 'destination' field as a 'godown' */
                    setValue('destination', Enum_InternalTransfer_Source_Destination_Enum.Godown);
                  }
                }}
                selectProps={{
                  showSearch: true,
                  optionFilterProp: 'children',
                  loading: getItemListDataLoading,
                }}
                options={
                  getItemListData
                    ? getItemListData.items.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    : []
                }
              />
            </FormItem>
          ) : null}

          {watch('materialTypeId') !== 'others' ? (
            <FormItem
              label="No. Of Bags"
              isRequired
              errorText={errors && errors.bagsCount ? errors.bagsCount.message : undefined}
              {...formItemStyleProps}
            >
              <InputNumber
                customStyles={inputComponentCommonStyle}
                placeholder="Please enter number of bags"
                name="bagsCount"
                rhfControllerProps={{
                  control,
                }}
                inputNumberProps={{ min: 0 }}
              />
            </FormItem>
          ) : null}
          {watch('materialTypeId') === 'others' ? (
            <>
              <FormItem {...formItemStyleProps}>
                <>
                  <Button
                    type="default"
                    onClick={() => {
                      /* on reset of itemId , set source and destination to null */
                      if (watch('itemId')) {
                        setValue('source', null);
                        setValue('destination', null);
                      }
                      setValue('itemId', null);
                    }}
                    style={{ marginBottom: 5 }}
                  >
                    Reset
                  </Button>
                  <h3>OR</h3>
                </>
              </FormItem>
              <FormItem
                label="Item Name"
                errorText={
                  errors && errors.othersItemName ? errors.othersItemName.message : undefined
                }
                {...formItemStyleProps}
              >
                <Input
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please enter name of item"
                  name="othersItemName"
                  rhfControllerProps={{
                    control,
                  }}
                />
              </FormItem>
              <FormItem
                label="Quantity"
                errorText={
                  errors && errors.othersQuantity ? errors.othersQuantity.message : undefined
                }
                {...formItemStyleProps}
              >
                <InputNumber
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please enter quantity"
                  name="othersQuantity"
                  rhfControllerProps={{
                    control,
                  }}
                  inputNumberProps={{ min: 0 }}
                />
              </FormItem>
              <FormItem
                label="Weight (in kgs)"
                errorText={errors && errors.othersWtKg ? errors.othersWtKg.message : undefined}
                {...formItemStyleProps}
              >
                <InputNumber
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please enter weight (in kgs)"
                  name="othersWtKg"
                  rhfControllerProps={{
                    control,
                  }}
                  inputNumberProps={{ min: 0, precision: 2 }}
                />
              </FormItem>
            </>
          ) : null}
          <FormItem
            label="Source"
            isRequired={
              watch('itemId') === null && watch('materialTypeId') === 'others' ? false : true
            }
            errorText={errors && errors.source ? errors.source.message : undefined}
            {...formItemStyleProps}
          >
            <RadioGroup
              name="source"
              rhfControllerProps={{
                control,
              }}
              defaultValue={null}
              options={[
                { label: 'Godown', value: 'godown' },
                {
                  label: 'Mill',
                  value: 'mill',
                },
              ]}
              onChange={(e) => {
                /* on selection of 'source', set 'source godown id ' or 'source mill id ' as a null */
                if (e.target.value === 'mill') {
                  setValue('sourceGodownId', null);
                } else {
                  setValue('sourceMillId', null);
                }
              }}
            />
          </FormItem>
          {watch('source') === 'mill' ? (
            <FormItem
              label="Select Source Mill ID"
              isRequired={
                watch('itemId') === null && watch('materialTypeId') === 'others' ? false : true
              }
              errorText={errors && errors.sourceMillId ? errors.sourceMillId.message : undefined}
              {...formItemStyleProps}
            >
              <Select
                customStyles={inputComponentCommonStyle}
                placeholder="Please select mill ID"
                name="sourceMillId"
                rhfControllerProps={{
                  control,
                }}
                selectProps={{
                  showSearch: true,
                  optionFilterProp: 'children',
                  loading: getGodownAndMillsDataLoading,
                }}
                options={
                  getGodownAndMillsData
                    ? getGodownAndMillsData.getAllMills.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    : []
                }
              />
            </FormItem>
          ) : null}
          {watch('source') === 'godown' ? (
            <FormItem
              label="Select Source Godown ID"
              isRequired={
                watch('itemId') === null && watch('materialTypeId') === 'others' ? false : true
              }
              errorText={
                errors && errors.sourceGodownId ? errors.sourceGodownId.message : undefined
              }
              {...formItemStyleProps}
            >
              <Select
                customStyles={inputComponentCommonStyle}
                placeholder="Please select godown ID"
                name="sourceGodownId"
                rhfControllerProps={{
                  control,
                }}
                selectProps={{
                  showSearch: true,
                  optionFilterProp: 'children',
                  loading: getGodownAndMillsDataLoading,
                }}
                options={
                  getGodownAndMillsData
                    ? getGodownAndMillsData.getAllGodowns.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    : []
                }
              />
            </FormItem>
          ) : null}

          <FormItem
            label="Destination"
            isRequired={
              watch('itemId') === null && watch('materialTypeId') === 'others' ? false : true
            }
            errorText={errors && errors.destination ? errors.destination.message : undefined}
            {...formItemStyleProps}
          >
            <RadioGroup
              name="destination"
              rhfControllerProps={{
                control,
              }}
              defaultValue={null}
              options={[
                { label: 'Godown', value: 'godown' },
                {
                  label: 'Mill',
                  value: 'mill',
                },
              ]}
              onChange={(e) => {
                /* on selection of 'destination', set 'destination godown id ' or 'destination mill id ' as a null */
                if (e.target.value === 'mill') {
                  setValue('destinationGodownId', null);
                } else {
                  setValue('destinationMillId', null);
                }
              }}
            />
          </FormItem>
          {watch('destination') === 'mill' ? (
            <FormItem
              label="Select Destination Mill ID"
              isRequired={
                watch('itemId') === null && watch('materialTypeId') === 'others' ? false : true
              }
              errorText={
                errors && errors.destinationMillId ? errors.destinationMillId.message : undefined
              }
              {...formItemStyleProps}
            >
              <Select
                customStyles={inputComponentCommonStyle}
                placeholder="Please select mill ID"
                name="destinationMillId"
                rhfControllerProps={{
                  control,
                }}
                selectProps={{
                  showSearch: true,
                  optionFilterProp: 'children',
                  loading: getGodownAndMillsDataLoading,
                }}
                options={
                  getGodownAndMillsData
                    ? getGodownAndMillsData.getAllMills.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    : []
                }
              />
            </FormItem>
          ) : null}
          {watch('destination') === 'godown' ? (
            <FormItem
              label="Select Destination Godown ID"
              isRequired={
                watch('itemId') === null && watch('materialTypeId') === 'others' ? false : true
              }
              errorText={
                errors && errors.destinationGodownId
                  ? errors.destinationGodownId.message
                  : undefined
              }
              {...formItemStyleProps}
            >
              <Select
                customStyles={inputComponentCommonStyle}
                placeholder="Please select godown ID"
                name="destinationGodownId"
                rhfControllerProps={{
                  control,
                }}
                selectProps={{
                  showSearch: true,
                  optionFilterProp: 'children',
                  loading: getGodownAndMillsDataLoading,
                }}
                options={
                  getGodownAndMillsData
                    ? getGodownAndMillsData.getAllGodowns.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))
                    : []
                }
              />
            </FormItem>
          ) : null}

          {watch('materialTypeId') === 'raw_material' ? (
            <>
              <FormItem
                label="Empty Bag Weight (in kgs)"
                errorText={
                  errors && errors.rmEmptyBagsWtKg ? errors.rmEmptyBagsWtKg.message : undefined
                }
                {...formItemStyleProps}
              >
                <InputNumber
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please enter empty bag weight(in kgs)"
                  name="rmEmptyBagsWtKg"
                  rhfControllerProps={{
                    control,
                  }}
                  inputNumberProps={{ min: 0, precision: 2 }}
                  onChange={(value) => {
                    /* const used to store single bag weight */
                    const singleBagValue = watch('bagWtKg') ? (watch('bagWtKg') as number) : 0;

                    /* set 'netMaterialWtPerBagKg' field's value, which is calculated from 'empty bag' & 'single bag' weight */
                    setValue('netMaterialWtPerBagKg', Math.abs(singleBagValue - value));
                  }}
                />
              </FormItem>
              <FormItem
                label="Net Material Weight Per Bags (in kgs)"
                errorText={
                  errors && errors.netMaterialWtPerBagKg
                    ? errors.netMaterialWtPerBagKg.message
                    : undefined
                }
                {...formItemStyleProps}
              >
                <InputNumber
                  customStyles={inputComponentCommonStyle}
                  placeholder="Please enter net material weight per bags (in kgs)"
                  name="netMaterialWtPerBagKg"
                  rhfControllerProps={{
                    control,
                  }}
                  inputNumberProps={{ min: 0, disabled: true, precision: 2 }}
                />
              </FormItem>
            </>
          ) : null}
          <FormItem
            label="Remark"
            errorText={errors && errors.remark ? errors.remark.message : undefined}
            {...formItemStyleProps}
          >
            <Input
              name="remark"
              isTextAreaInput={true}
              placeholder="Please enter remark"
              rhfControllerProps={{
                control,
              }}
              customStyles={inputComponentCommonStyle}
              textAreaProps={{ rows: 2 }}
            />
          </FormItem>
          <FormItem {...formItemStyleProps}>
            <>
              <Button
                htmlType="submit"
                type="primary"
                loading={isSubmitBtnLoading}
                style={{ marginRight: 10 }}
              >
                Submit
              </Button>
              <Button
                type="default"
                onClick={() => {
                  reset();
                  setValue('materialTypeId', null);
                }}
              >
                Cancel
              </Button>
            </>
          </FormItem>
        </>
      ) : null}
    </form>
  );
};

export default ManualInternalTransferScreen;
