import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import {
  ApolloError,
  useMutation,
  Reference,
  QueryLazyOptions,
  LazyQueryResult,
} from '@apollo/client';
import { Table, Button, Popconfirm, message } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../../utils/helpers';
import {
  DeleteOutwardShipmentMutation,
  DeleteOutwardShipmentMutationVariables,
  Exact,
  GetAllVehiclesOfTransporterQuery,
  GetAllVehiclesOfTransporterQueryVariables,
} from '../../../graphql/graphql-types';
import colors from '../../../scss/variables.module.scss';
import { dateFormatFunc } from '../../../utils/globals';
import { useOutwardShipmentOutlet } from './OutwardProductTabLayout';
import {
  CreateProductOutwardShipmentFormType,
  ProductOpenOutwardShipmentType,
} from '../../../utils/types';
import { UseFormSetValue } from 'react-hook-form';

/* open outward shipment table prop type */
type OpenOutwardShipmentsTablePropType = {
  /* this prop used to store table data */
  data: Array<ProductOpenOutwardShipmentType>;
  /* this prop type used to assign initial data to the 'createOutwardShipment' form, on click of 'edit' button */
  assignEditShipmentData: React.Dispatch<
    React.SetStateAction<ProductOpenOutwardShipmentType | null>
  >;
  /* this prop type used to assign default values to CreateProductOutwardShipment form on 'Edit' button click  */
  setValue: UseFormSetValue<CreateProductOutwardShipmentFormType>;
  /* this prop type used to get list of vehicles for selected transporter id on edit button click. */
  getVehiclesOfTransporterOnEditShipment: (
    options?:
      | QueryLazyOptions<
          Exact<{
            transporterId: number;
          }>
        >
      | undefined,
  ) => Promise<
    LazyQueryResult<GetAllVehiclesOfTransporterQuery, GetAllVehiclesOfTransporterQueryVariables>
  >;
};

/* loading delete outward shipment mutation  */
const deleteOutwardShipmentMutation = loader(
  '../../../graphql/mutations/deleteOutwardShipmentMutation.graphql',
);

/* react functional component */
const OpenOutwardShipmentsTable = ({
  data,
  assignEditShipmentData,
  setValue,
  getVehiclesOfTransporterOnEditShipment,
}: OpenOutwardShipmentsTablePropType) => {
  /* extracting 'setOutwardShipmentId' from outward shipment outlet */
  const { setOutwardShipmentId } = useOutwardShipmentOutlet();

  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* state used to store id of the shipment whose delete button is clicked which is then used to show loading indicator on delete button while deleting a shipment */
  const [shipmentIdToDltAndShowLoading, setShipmentIdToDltAndShowLoading] = useState<number | null>(
    null,
  );

  /* delete outward shipment mutation */
  const [deleteOutwardShipment] = useMutation<
    DeleteOutwardShipmentMutation,
    DeleteOutwardShipmentMutationVariables
  >(deleteOutwardShipmentMutation);

  /* function to handle delete Shipment */
  const deleteShipmentFunc = (shipmentId: number) => {
    setShipmentIdToDltAndShowLoading(shipmentId);
    deleteOutwardShipment({
      variables: {
        id: shipmentId,
      },
      /* after deleting a shipment, the update function is used to update the cache and display an updated shipments array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a shipment that was just removed. */
        const deletedDataId = deleteData?.deleteOutwardShipment?.id;
        cache.modify({
          fields: {
            outwardShipments(existingShipments: Array<Reference>, { readField }) {
              if (deletedDataId) {
                return existingShipments.filter(
                  (shipmentRef) => deletedDataId !== readField('id', shipmentRef),
                );
              }
              return existingShipments;
            },
          },
        });
      },
    })
      .then(() => {
        setShipmentIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment has been deleted successfully.');
      })
      .catch((error: ApolloError) => {
        setShipmentIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  return (
    <Table<ProductOpenOutwardShipmentType>
      dataSource={data}
      key="id"
      className="tableStyle"
      bordered
      size="small"
      pagination={{ showSizeChanger: true }}
    >
      <Table.Column<ProductOpenOutwardShipmentType>
        title="GRN"
        dataIndex="grn"
        key="grn"
        align="center"
        render={(text, record) => record.grn || '-'}
      />

      <Table.Column<ProductOpenOutwardShipmentType>
        title="Created At Date"
        dataIndex="createdAt"
        key="createdAt"
        align="center"
        render={(text, record) =>
          record.createdAt ? dateFormatFunc(record.createdAt as Date) : null
        }
      />

      <Table.Column<ProductOpenOutwardShipmentType>
        title="Transporter"
        dataIndex="transporter"
        key="transporter"
        align="center"
        render={(text, render) => {
          return (
            <span style={{ textTransform: 'capitalize' }}>
              {render.transporter ? render.transporter.name : null}
            </span>
          );
        }}
      />
      <Table.Column<ProductOpenOutwardShipmentType>
        title="Vehicle No."
        dataIndex="vehicle"
        key="vehicle"
        align="center"
        render={(text, record) => {
          return record.transporterVehicle ? record.transporterVehicle.number : null;
        }}
      />
      <Table.Column<ProductOpenOutwardShipmentType>
        title="Drivers"
        dataIndex="drivers"
        key="drivers"
        align="center"
        render={(text, render) => {
          return render.drivers.map((driverInfo) => {
            return (
              <span style={{ textTransform: 'capitalize' }} key={driverInfo.driver.id}>
                {driverInfo.driver.name}
                <br />
              </span>
            );
          });
        }}
      />
      <Table.Column<ProductOpenOutwardShipmentType>
        title="Tare Weight (in kgs)"
        dataIndex="emptyVehicleWtKg"
        key="emptyVehicleWtKg"
        width={150}
        align="center"
        render={(text, record) =>
          record.emptyVehicleWtKg ? record.emptyVehicleWtKg.toFixed(2) : null
        }
      />
      <Table.Column<ProductOpenOutwardShipmentType>
        key="id"
        title="Actions"
        dataIndex="actions"
        align="center"
        render={(text, record) => {
          return (
            <div className="buttonContainer">
              <Button
                onClick={() => {
                  /* store selected shipment id to order into 'setOutwardShipmentId's state,
                  so that we can get that shipment id onto 'Outward product Shipment order' tab screen */
                  setOutwardShipmentId(record.id);
                  /* navigate to shipment order screen */
                  navigate('/shipment/outward/product/orders');
                }}
              >
                Orders
              </Button>
              <Button
                onClick={() => {
                  /* store shipment data which is selected to 'edit'  */
                  assignEditShipmentData(record);

                  /* destructing shipment data, which is selected to edit */
                  const { transporterVehicle, drivers, emptyVehicleWtKg, transporter } = record;

                  /* initialize 'driverIds' field of the form, with shipment driver ids */
                  setValue(
                    'driverIds',
                    drivers.length > 0 ? drivers.map((item) => item.driver.id) : [],
                  );

                  /* initialize 'tareWeightInKg' field of the form, with shipment empty vehicle weight */
                  setValue('tareWeightInKg', emptyVehicleWtKg ? emptyVehicleWtKg : null);

                  /* initialize 'transporterId' field of the form, with shipment transporter id */
                  setValue('transporterId', transporter ? transporter.id : null);

                  /* to display vehicles list for selected transporter id, call getVehiclesOfTransporter function  */
                  if (transporter) {
                    /* get list of vehicles for selected transporter */
                    getVehiclesOfTransporterOnEditShipment({
                      variables: { transporterId: transporter.id },
                    })
                      .then(() => {
                        /* initialize 'vehicleId' field of the form, with shipment transporter vehicle id */
                        setValue('vehicleId', transporterVehicle ? transporterVehicle.id : null);
                      })
                      .catch((error: ApolloError) => {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        message.error(error.message);
                        logger(error);
                      });
                  }
                }}
              >
                Edit
              </Button>
              <Popconfirm
                title="Delete outward shipment. Are you sure?"
                okText="Yes"
                okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                cancelText="No"
                icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                onConfirm={() => {
                  deleteShipmentFunc(record.id);
                }}
              >
                <Button
                  className="deleteButton"
                  loading={record.id === shipmentIdToDltAndShowLoading}
                >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          );
        }}
      />
    </Table>
  );
};

export default OpenOutwardShipmentsTable;
