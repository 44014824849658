import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Divider, message, Popconfirm, Table } from 'antd';
import { PlusOutlined, WarningFilled } from '@ant-design/icons';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { yupResolver } from '@hookform/resolvers/yup';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import * as yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  PurchaseOrderItems,
  GetAllVendorsAndItemsBasedOnItemCategoryQuery,
  GetAllVendorsAndItemsBasedOnItemCategoryQueryVariables,
  GetAllBrokersQuery,
  GetAllBrokersQueryVariables,
  CreatePurchaseOrderMutationMutation,
  CreatePurchaseOrderMutationMutationVariables,
} from '../../graphql/graphql-types';
import { logger, renderAmount } from '../../utils/helpers';
import FormItem from '../../components/FormItem';
import Select from '../../components/Select';
import { OptionsDataType } from '../../utils/types';
import InputNumber from '../../components/InputNumber';
import RadioGroup from '../../components/RadioGroup';
import RequiredMessage from '../../components/RequiredMessage';
import Input from '../../components/Input';
import { inputComponentCommonStyle } from '../../utils/globals';
import colors from '../../scss/variables.module.scss';
import DatePicker from '../../components/DatePicker';
import QuickAddVendorOrBuyerForm from '../../components/QuickAddVendorOrBuyerForm';
import QuickAddBrokerForm from '../../components/QuickAddBrokerForm';
import getItemCategoryData from '../../utils/helpers/getItemCategoryData';

/* loading create Purchase Order mutation  */
const createPurchaseOrderMutation = loader(
  '../../graphql/mutations/createPurchaseOrderMutation.graphql',
);

/* loading get All Vendors And Items Based On Item Category Query  */
const getAllVendorsAndItemsBasedOnItemCategory = loader(
  '../../graphql/queries/getAllVendorsAndItemsBasedOnItemCategoryQuery.graphql',
);

/* loading get All brokers Query  */
const getAllBrokersQuery = loader('../../graphql/queries/getAllBrokersQuery.graphql');

/* Create Po Form Type */
type CreatePoFormType = Pick<PurchaseOrderItems, 'orderedItemsCount' | 'remarks'> & {
  /* item Id */
  itemId: string | null;
  /*vendor Id */
  vendorId: number | null;
  /* vendor Name */
  vendorName: string | undefined;
  /* item Name */
  itemName: string | undefined;
  /* total amount */
  amount: number;
  /* remark */
  remarks: string | null;
  /* unit Price  of product*/
  unitPrice?: number | null;
  /* gst Percent of product */
  gstPercent?: string | null;
  /* broker name */
  brokerName: string | null;
  broker_id: string | null;
  /* quantity */
  quantity: number | null;
  paymentTerms: string | null;
  createdBy: {
    name: string;
    contact_no: string;
  };
  totalDiscountApplicable: number | null;
  purchaseContractValidity: string | null;
  /* discount applicable on item */
  itemDiscount: number | null;
};

/* Purchase Order Items List Type */
type PurchaseOrderItemsListType = CreatePoFormType & {
  /* id */
  id: number;
};

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 4,
  /* input column span of FormItem */
  inputColSpan: 7,
  /* formItem label styling */
  labelStyle: { textAlign: 'start' } as React.CSSProperties,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

/* create po form validation common schema for all three tab */
const schema = yup.object({
  /* validation for vendor Id  */
  vendorId: yup.number().nullable().required('Please select vendor and try again.'),
  /* validation for item Id  */
  itemId: yup.string().nullable().required('Please enter item description and try again.'),
  createdBy: yup.object().shape({
    name: yup
      .string()
      .required('Please enter name and try again')
      .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
    contact_no: yup
      .string()
      .required('Please enter mobile number and try again')
      .matches(validationRegex.mobile, {
        message: 'Please enter a valid mobile number',
      }),
  }),
});

/* create po form validation schema for otherItems tab */
const otherItemsSchema = schema.concat(
  yup.object({
    /* validation for quantity */
    quantity: yup
      .number()
      .min(1, 'minimum quantity should be at least one')
      .nullable()
      .integer()
      .required('Please enter quantity and try again'),
  }),
);

/* create po form validation schema for product bag and paddy bag tab */
const paddyOrProductBagsSchema = schema.concat(
  yup.object({
    /* validation for ordered Items Count  */
    orderedItemsCount: yup
      .number()
      .min(1, 'Please enter a valid number')
      .integer('Please enter a valid number')
      .nullable()
      .required('Please enter number of bags and try again.'),
  }),
);

/* gstAmount is used to stored option data which we will pass to radio Component */
const gstAmount = ['0', '5', '12', '18', '28'].map((item) => ({
  label: item,
  value: item,
}));

/* React functional component */
const CreatePurchaseOrderScreen = (): JSX.Element => {
  /* When the user clicks the add item button, this state sets an array of order item information that will be used as the dataSource for the items list table*/
  const [orderedItems, setOrderedItems] = useState<Array<PurchaseOrderItemsListType>>([]);

  /* this state used to show loading indicator on 'create po' when creating new order */
  const [isCreateBtnLoading, setIsCreateBtnLoading] = useState<boolean>(false);

  const [isAddVendorModalVisible, setIsAddVendorModalVisible] = useState<boolean>(false);

  const [isAddBrokerModalVisible, setIsAddBrokerModalVisible] = useState<boolean>(false);

  /* useNavigation destructuring */
  const navigate = useNavigate();

  /* useLocation destructuring */
  const location = useLocation();

  const isPathIncludesOtherItems = location.pathname.includes('otherItems');

  /** calling `getItemCategoryData` function to get itemCategory data  */
  const itemCategory = getItemCategoryData({
    locationPathname: location.pathname,
  });

  /* get All Vendors And Items Based On Item Category List  query */
  const {
    data: getAllVendorsAndItemsBasedOnItemCategoryData,
    loading: getAllVendorsAndItemsBasedOnItemCategoryLoading,
    error: getAllVendorsAndItemsBasedOnItemCategoryError,
  } = useQuery<
    GetAllVendorsAndItemsBasedOnItemCategoryQuery,
    GetAllVendorsAndItemsBasedOnItemCategoryQueryVariables
  >(getAllVendorsAndItemsBasedOnItemCategory, {
    variables: { item_category: itemCategory.value },
    fetchPolicy: 'network-only',
  });

  /* fetch all brokers data from 'GetAllBrokersQuery' */
  const {
    data: getAllBrokersData,
    loading: getAllBrokersLoading,
    error: getAllBrokersError,
  } = useQuery<GetAllBrokersQuery, GetAllBrokersQueryVariables>(getAllBrokersQuery);

  /* create purchase order mutation */
  const [createPurchaseOrder] = useMutation<
    CreatePurchaseOrderMutationMutation,
    CreatePurchaseOrderMutationMutationVariables
  >(createPurchaseOrderMutation);

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreatePoFormType>({
    resolver: isPathIncludesOtherItems
      ? yupResolver(otherItemsSchema)
      : yupResolver(paddyOrProductBagsSchema),
    defaultValues: {
      vendorId: null,
      itemId: null,
      itemName: '',
      orderedItemsCount: null,
      unitPrice: null,
      gstPercent: null,
      vendorName: '',
      remarks: null,
      brokerName: '',
      broker_id: null,
      quantity: null,
      paymentTerms: null,
      createdBy: {},
      totalDiscountApplicable: null,
      purchaseContractValidity: null,
      itemDiscount: null,
    },
    mode: 'onChange',
  });

  /* selectedVendorData stores rmSellers data from the chosen vendor id, which is used to show the gstin value in the gstin form filed */
  let selectedVendorData;

  /* vendorData is used as options data which we will pass to option prop of select on Vendor field */
  let vendorData: OptionsDataType[] = [];

  /* itemData is used as options data which we will pass to option prop of select on item description field */
  let itemData: OptionsDataType[] = [];

  /* brokerData is used as options data which we will pass to option prop of select on broker field  */
  let brokerData: OptionsDataType[] = [];

  if (getAllVendorsAndItemsBasedOnItemCategoryData) {
    /* selectedVendorData stores rmSellers data from the chosen vendor id, which is used to show the gstin value in the gstin form filed */
    selectedVendorData = getAllVendorsAndItemsBasedOnItemCategoryData.rmSellers.find(
      (vendorId) => vendorId.id === watch('vendorId'),
    );

    /* vendorData is used as options data which we will pass to option prop of select on Vendor field */
    vendorData = getAllVendorsAndItemsBasedOnItemCategoryData.rmSellers.map((item) => ({
      value: item.id,
      label: item.name,
    }));

    /* itemData is used as options data which we will pass to option prop of select on item description field */
    itemData = getAllVendorsAndItemsBasedOnItemCategoryData.items.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }

  if (getAllBrokersData) {
    brokerData = getAllBrokersData.brokers.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }

  /* function to be called when user click add items button */
  const addItemsFunc = (formData: CreatePoFormType) => {
    reset({
      /* resetting all values after submitting form except createdBy, paymentTerms, totalDiscountApplicable,
      purchaseContractValidity, vendorId and vendorName for vendor field */
      createdBy: {
        contact_no: formData.createdBy.contact_no,
        name: formData.createdBy.name,
      },
      paymentTerms: formData.paymentTerms,
      totalDiscountApplicable: formData.totalDiscountApplicable,
      purchaseContractValidity: formData.purchaseContractValidity,
      vendorId: formData.vendorId,
      vendorName: formData.vendorName,
      itemId: undefined,
      orderedItemsCount: undefined,
      unitPrice: undefined,
      gstPercent: undefined,
      remarks: undefined,
      brokerName: null,
      quantity: null,
    });
    /* setting orderItems array */
    setOrderedItems([
      ...orderedItems,
      {
        ...formData,
        id: Math.floor(Math.random() * 100 + 1),
      },
    ]);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.success('Item has been successfully added');
  };

  /* function will be called when user clicks create PO button */
  const handleCreatePurchaseOrderBtnFunc = () => {
    setIsCreateBtnLoading(true);
    if (orderedItems.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('Please add Item to PO.');
      setIsCreateBtnLoading(false);
    } else {
      /* mutationData stored orderedItem array and passed to createPurchaseOrder as data  */
      const mutationData = orderedItems.map((item) => {
        /* extracting `id`, `vendorName`, `itemName`, `brokerName`, `createdBy`, `purchaseContractValidity`,
       `totalDiscountApplicable`, `paymentTerms` from orderedItems  */
        const {
          id,
          vendorName,
          itemName,
          brokerName,
          createdBy,
          purchaseContractValidity,
          totalDiscountApplicable,
          paymentTerms,
          amount,
          ...data
        } = item;

        return data;
      });

      // call to mutation to create purchaseOrder
      createPurchaseOrder({
        variables: {
          purchaseOrderItems: {
            data: mutationData.map((item) => ({
              orderedItemsCount: item.orderedItemsCount,
              remarks: item.remarks,
              itemId: item.itemId,
              vendorId: item.vendorId,
              unitPrice: item.unitPrice,
              gstPercent: item.gstPercent,
              quantity: item.quantity,
              discount: item.itemDiscount,
              broker_id: item.broker_id,
            })),
          },
          discount: watch('totalDiscountApplicable'),
          payment_terms: watch('paymentTerms'),
          created_by_name: watch('createdBy.name'),
          created_by_contact_no: watch('createdBy.contact_no'),
          contract_validity: watch('purchaseContractValidity'),
        },
        update(cache, { data: createdData }) {
          /* extracting the new purchase order data added */
          const dataToCreate = createdData?.insert_purchaseOrders_one;
          cache.modify({
            fields: {
              purchaseOrders(existingPurchaseRef: Array<Reference>) {
                return [...existingPurchaseRef, dataToCreate];
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Purchase order has been successfully created.');
          navigate(`/purchaseOrders/${itemCategory.key}`);
          setOrderedItems([]);
          setIsCreateBtnLoading(false);
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error);
          logger(error);
          setIsCreateBtnLoading(false);
        });
    }
  };

  const totalDiscountApplicable = watch('totalDiscountApplicable');

  /* totalAmount used stored the calculated total amount of all purchase order */
  let totalAmount = 0;

  orderedItems.forEach((item) => {
    totalAmount += renderAmount(
      isPathIncludesOtherItems ? item.quantity || 0 : item.orderedItemsCount || 0,
      item.unitPrice || 0,
      Number(item.gstPercent),
      item.itemDiscount || 0,
    ).amountAfterDiscount;
  });

  const discountCalculation = (totalAmount * ((totalDiscountApplicable || 0) / 100)).toFixed(2);

  /* show error message on the screen, if it has any error to fetch data from the server */
  if (getAllVendorsAndItemsBasedOnItemCategoryError || getAllBrokersError) {
    return (
      <div className="errorText">
        {(getAllVendorsAndItemsBasedOnItemCategoryError &&
          getAllVendorsAndItemsBasedOnItemCategoryError.message) ||
          (getAllBrokersError && getAllBrokersError.message)}
      </div>
    );
  }
  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(addItemsFunc)}
      >
        <FormItem label="Material" {...formItemStyleProps}>
          <> {itemCategory.materialName}</>
        </FormItem>
        <FormItem
          label="Created by"
          {...formItemStyleProps}
          isRequired
          errorText={errors && errors.createdBy ? errors.createdBy.name?.message : undefined}
        >
          <Input
            name="createdBy.name"
            placeholder="Enter name"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
          />
        </FormItem>
        <FormItem
          {...formItemStyleProps}
          errorText={errors && errors.createdBy ? errors.createdBy.contact_no?.message : undefined}
        >
          <Input
            name="createdBy.contact_no"
            placeholder="Enter contact number"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
          />
        </FormItem>
        <FormItem label="Payment Terms" {...formItemStyleProps}>
          <Input
            name="paymentTerms"
            placeholder="Enter payment terms"
            isTextAreaInput={true}
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
          />
        </FormItem>

        <FormItem label=" Total Discount Applicable (in %)" {...formItemStyleProps}>
          <InputNumber
            name="totalDiscountApplicable"
            placeholder="Discount applicable"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
          />
        </FormItem>
        <FormItem label="Purchase contract validity" {...formItemStyleProps}>
          <DatePicker
            name="purchaseContractValidity"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
            placeholder="Contract validity"
          />
        </FormItem>
        <Divider />
        <h3> Add Items</h3>
        <FormItem
          label="Vendor"
          {...formItemStyleProps}
          isRequired
          errorText={errors && errors.vendorId ? errors.vendorId.message : undefined}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Select vendor"
            name="vendorId"
            rhfControllerProps={{
              control,
            }}
            onChange={(rhfOnChange, value) => {
              /* selectedVendor stores rmSellers data from the chosen vendor id, which is used to show the vendor name  in the item list table*/
              if (getAllVendorsAndItemsBasedOnItemCategoryData) {
                const selectedVendor = getAllVendorsAndItemsBasedOnItemCategoryData.rmSellers.find(
                  (element) => element.id === value,
                );
                /* set value of vendor name */
                if (selectedVendor) {
                  setValue('vendorName', selectedVendor.name);
                }
              }
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              rhfOnChange(value);
            }}
            selectProps={{
              showSearch: true,
              disabled: orderedItems.length > 0,
              loading: getAllVendorsAndItemsBasedOnItemCategoryLoading,
              optionFilterProp: 'children',
              dropdownRender: (menu) => {
                return (
                  <>
                    {menu}
                    <Divider style={{ marginTop: 5, marginBottom: 0 }} />
                    <Button
                      className="selectDropDownButton"
                      onClick={() => {
                        setIsAddVendorModalVisible(true);
                      }}
                      icon={<PlusOutlined />}
                    >
                      Add vendor
                    </Button>
                  </>
                );
              },
            }}
            options={vendorData}
          />
        </FormItem>
        <FormItem label="GST" {...formItemStyleProps}>
          <>
            {selectedVendorData ? (
              <span>{selectedVendorData.gstin ? selectedVendorData.gstin : 'Not added'}</span>
            ) : (
              '-'
            )}
          </>
        </FormItem>
        <FormItem
          label="Item Description"
          errorText={errors && errors.itemId ? errors.itemId.message : undefined}
          {...formItemStyleProps}
          isRequired
        >
          <Select
            placeholder="Select item description"
            name="itemId"
            customStyles={inputComponentCommonStyle}
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              optionFilterProp: 'children',
              loading: getAllVendorsAndItemsBasedOnItemCategoryLoading,
            }}
            options={itemData}
            onChange={(rhfOnChange, value) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              rhfOnChange(value);
              /* logic to just store itemName from selected item id which is use to display value of vendor name column in items table */
              if (getAllVendorsAndItemsBasedOnItemCategoryData) {
                const selectedItemName = getAllVendorsAndItemsBasedOnItemCategoryData.items.find(
                  (element) => element.id === watch('itemId'),
                );
                /* set value of item name */
                if (selectedItemName) {
                  setValue('itemName', selectedItemName.name);
                }
              }
            }}
          />
        </FormItem>

        {isPathIncludesOtherItems ? (
          <>
            <FormItem
              label="Broker"
              errorText={errors && errors.brokerName ? errors.brokerName.message : undefined}
              {...formItemStyleProps}
            >
              <Select
                name="broker_id"
                placeholder="Select broker"
                customStyles={inputComponentCommonStyle}
                rhfControllerProps={{
                  control,
                }}
                selectProps={{
                  showSearch: true,
                  optionFilterProp: 'children',
                  loading: getAllBrokersLoading,
                  dropdownRender: (menu) => {
                    return (
                      <>
                        {menu}
                        <Divider style={{ marginTop: 5, marginBottom: 0 }} />
                        <Button
                          className="selectDropDownButton"
                          onClick={() => {
                            setIsAddBrokerModalVisible(true);
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add Broker
                        </Button>
                      </>
                    );
                  },
                }}
                options={brokerData}
                onChange={(rhfOnChange, value) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  rhfOnChange(value);

                  /* logic to just store broker name from selected item id which is use to display value of broker name column in items table */
                  if (getAllBrokersData) {
                    const selectedItemName = getAllBrokersData.brokers.find(
                      (element) => element.id === watch('broker_id'),
                    );

                    /* set value of item name */
                    if (selectedItemName) {
                      setValue('brokerName', selectedItemName.name);
                    }
                  }
                }}
              />
            </FormItem>
            <FormItem
              label="Quantity"
              errorText={errors && errors.quantity ? errors.quantity.message : undefined}
              {...formItemStyleProps}
              isRequired
            >
              <InputNumber
                name="quantity"
                placeholder="Enter quantity"
                customStyles={inputComponentCommonStyle}
                rhfControllerProps={{ control }}
              />
            </FormItem>
          </>
        ) : (
          <FormItem
            label="No. of Bags (Quintals)"
            errorText={
              errors && errors.orderedItemsCount ? errors.orderedItemsCount.message : undefined
            }
            {...formItemStyleProps}
            isRequired
          >
            <InputNumber
              name="orderedItemsCount"
              placeholder="Enter no of bags"
              customStyles={inputComponentCommonStyle}
              rhfControllerProps={{ control }}
              inputNumberProps={{ min: 0 }}
            />
          </FormItem>
        )}

        <FormItem
          label="Unit Price (in Rs.)"
          errorText={errors && errors.unitPrice ? errors.unitPrice.message : undefined}
          {...formItemStyleProps}
        >
          <InputNumber
            name="unitPrice"
            placeholder="Enter price for item"
            customStyles={inputComponentCommonStyle}
            rhfControllerProps={{ control }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        <FormItem
          label="GST Amount (in %)"
          errorText={errors && errors.gstPercent ? errors.gstPercent.message : undefined}
          {...formItemStyleProps}
        >
          <RadioGroup name="gstPercent" rhfControllerProps={{ control }} options={gstAmount} />
        </FormItem>
        <FormItem label="Item discount (in %)" {...formItemStyleProps}>
          <InputNumber
            name="itemDiscount"
            placeholder="Enter discount for item"
            customStyles={inputComponentCommonStyle}
            rhfControllerProps={{ control }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        <FormItem label="Amount (in Rs.)" {...formItemStyleProps}>
          <>
            {renderAmount(
              isPathIncludesOtherItems ? watch('quantity') || 0 : watch('orderedItemsCount') || 0,
              watch('unitPrice') || 0,
              Number(watch('gstPercent')),
              watch('itemDiscount') || 0,
            ).amountAfterDiscount.toFixed(2)}
          </>
        </FormItem>

        <FormItem label="Remarks" {...formItemStyleProps}>
          <Input
            name="remarks"
            isTextAreaInput={true}
            placeholder="Enter remark"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
            textAreaProps={{ rows: 2 }}
          />
        </FormItem>
        <FormItem {...formItemStyleProps}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </FormItem>
      </form>
      <h2 style={{ marginTop: '10px', marginBottom: '0px' }}>Item List</h2>
      <Table<PurchaseOrderItemsListType>
        dataSource={orderedItems}
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
        rowKey={(record) => record.id}
      >
        <Table.Column title="Vendor" dataIndex="vendorName" key="vendorName" />
        <Table.Column title="Item Description" dataIndex="itemName" key="itemName" />
        {isPathIncludesOtherItems ? (
          <>
            <Table.Column<PurchaseOrderItemsListType>
              title="Broker"
              dataIndex="broker"
              width={150}
              key="broker"
              render={(value, record) => record.brokerName || '-'}
            />
            <Table.Column<PurchaseOrderItemsListType>
              title="Quantity"
              dataIndex="quantity"
              key="quantity"
              render={(value, record) => record.quantity || '-'}
            />
          </>
        ) : (
          <Table.Column title="No. of Bags" dataIndex="orderedItemsCount" key="orderedItemsCount" />
        )}
        <Table.Column<PurchaseOrderItemsListType>
          title="Unit Price (in Rs.)"
          dataIndex="unitPrice"
          key="unitPrice"
          width={150}
          render={(value, record) => record.unitPrice || '-'}
        />
        <Table.Column<PurchaseOrderItemsListType>
          title="GST (%)"
          dataIndex="gstPercent"
          key="gstPercent"
          render={(value, record) => record.gstPercent || '-'}
        />
        <Table.Column<PurchaseOrderItemsListType>
          title="Discount (%)"
          dataIndex="itemDiscount"
          key="itemDiscount"
          render={(value, record) => record.itemDiscount || '-'}
        />
        <Table.Column<PurchaseOrderItemsListType>
          title="Amount (in Rs.)"
          dataIndex="amount"
          key="amount"
          render={(text, record) => {
            /** const to store discounted amount and final amount after discount */
            const totalAmountAndDiscount = renderAmount(
              isPathIncludesOtherItems ? record.quantity || 0 : record.orderedItemsCount || 0,
              record.unitPrice || 0,
              Number(record.gstPercent),
              record.itemDiscount || 0,
            );
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {totalAmountAndDiscount.amountAfterDiscount.toFixed(2) || '-'}
                {totalAmountAndDiscount.discountedAmount > 0 ? (
                  <i style={{ fontSize: 12, color: colors.placeHolderTextColor }}>
                    discount applied (Rs.) :
                    <span style={{ fontWeight: 500 }}>
                      {totalAmountAndDiscount.discountedAmount.toFixed(2) || '-'}
                    </span>
                  </i>
                ) : null}
              </div>
            );
          }}
        />
        <Table.Column<PurchaseOrderItemsListType>
          title="Remarks"
          dataIndex="remarks"
          key="remarks"
          render={(text, record) => record.remarks || '-'}
        />
        <Table.Column<PurchaseOrderItemsListType>
          title="Actions"
          dataIndex="actions"
          key="actions"
          render={(text, record) => {
            return (
              <Popconfirm
                title="Remove item. Are you sure?"
                icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  /* newOrderItems stored array after filtering which data wants to delete using id  */
                  const newOrderItems = orderedItems.filter((item) => item.id !== record.id);
                  setOrderedItems(newOrderItems);
                }}
              >
                <Button type="primary" danger>
                  Remove
                </Button>
              </Popconfirm>
            );
          }}
        />
      </Table>
      <div style={{ marginTop: 10, marginBottom: 10, fontSize: 16 }}>
        <b>Sub Total : </b>
        {totalAmount > 0 ? totalAmount.toFixed(2) : <> -</>}
      </div>
      <div style={{ marginTop: 10, marginBottom: 10, fontSize: 16 }}>
        <b>
          Total Discount
          {totalDiscountApplicable && totalDiscountApplicable > 0 ? (
            <> ({totalDiscountApplicable}%) </>
          ) : null}
          <>: </>
        </b>
        {totalDiscountApplicable && totalDiscountApplicable > 0 ? discountCalculation : <> -</>}
      </div>

      <div
        style={{
          marginTop: 10,
          marginBottom: 10,
          fontSize: 16,
        }}
      >
        <b>Total amount (in Rs.) : </b>
        {totalAmount > 0 ? (
          <>{(totalAmount - Number(discountCalculation)).toFixed(2)} Estimated</>
        ) : (
          <> -</>
        )}
      </div>
      <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="primary"
          style={{ marginRight: 10 }}
          onClick={() => {
            handleCreatePurchaseOrderBtnFunc();
          }}
          loading={isCreateBtnLoading}
        >
          Create PO
        </Button>
        <Button
          onClick={() => {
            reset();
            setOrderedItems([]);
            navigate(`/purchaseOrders/${itemCategory.key}`);
          }}
        >
          Cancel
        </Button>
      </div>
      {isAddVendorModalVisible ? (
        <QuickAddVendorOrBuyerForm
          isModalOpen={isAddVendorModalVisible}
          closeModal={() => {
            setIsAddVendorModalVisible(false);
          }}
          bagType={itemCategory.value}
          shipmentType="inward"
        />
      ) : null}
      {isAddBrokerModalVisible ? (
        <QuickAddBrokerForm
          isModalOpen={isAddBrokerModalVisible}
          closeModal={() => {
            setIsAddBrokerModalVisible(false);
          }}
        />
      ) : null}
    </>
  );
};

export default CreatePurchaseOrderScreen;
