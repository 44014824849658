import React, { useState } from 'react';
import { Button, Table, Popconfirm, message } from 'antd';
import { WarningFilled, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Reference, useQuery, ApolloError, useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import {
  GetAllUsersQuery,
  GetAllUsersQueryVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  Users,
  AdminSetUserPasswordMutation,
  AdminSetUserPasswordMutationVariables,
} from '../../../graphql/graphql-types';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import { logger } from '../../../utils/helpers';
import { AddressType } from '../../../utils/types';
import colors from '../../../scss/variables.module.scss';
import InputComponent from '../../../components/Input';
import { useForm } from 'react-hook-form';

/* get all users query data type */
type GetAllUsersType = Pick<
  Users,
  'id' | 'name' | 'mobile' | 'email' | 'updatePassword' | 'dob'
> & {
  /* address of user */
  address?: AddressType | null;
};

/* loading delete user mutation with the help of loader */
const deleteUserMutation = loader('../../../graphql/mutations/deleteUserMutation.graphql');
/* loading the create User query with the help of loader */
const getAllUsersQuery = loader('../../../graphql/queries/getAllUsersQuery.graphql');

const adminSetUserPasswordMutation = loader(
  '../../../graphql/mutations/adminSetUserPassword.graphql',
);

/* React functional component */
const ViewAllUsersScreen = () => {
  /* useQuery to fetch All users data using allUserQuery */
  const { data, error, loading } = useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    getAllUsersQuery,
  );

  /* delete user mutation */
  const [deleteUser] = useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    deleteUserMutation,
  );

  const [adminSetUserPassword] = useMutation<
    AdminSetUserPasswordMutation,
    AdminSetUserPasswordMutationVariables
  >(adminSetUserPasswordMutation);

  /* useState to store selected user id when user hits delete button which will then use for loading indication */
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null);

  const [isResetPasswordLoadingAndUserId, setIsResetPasswordLoadingAndUserId] = useState<
    number | null
  >(null);

  const [isPopConfirmVisibleForUserId, setIsPopConfirmVisibleForUserId] = useState<number | null>(
    null,
  );

  /* useForm declaration */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ updatedPassword: string | null }>({
    defaultValues: {
      updatedPassword: null,
    },
  });

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* function to handle delete user */
  const handleDeleteUser = (userId: number) => {
    deleteUser({
      variables: {
        id: Number(userId),
      },
      update(cache, { data: deleteData }) {
        /* to get the id of the data to be deleted */
        const idToRemove = deleteData?.deleteUser?.id;
        cache.modify({
          fields: {
            users(existingUsers: Array<Reference>, { readField }) {
              if (idToRemove) {
                return existingUsers.filter((userData) => idToRemove !== readField('id', userData));
              }
              return existingUsers;
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteUserId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('User has been successfully deleted');
      })
      .catch((deleteUserError: ApolloError) => {
        setDeleteUserId(null);
        logger(deleteUserError);
      });
  };

  if (error) {
    return <div className="errorText">{error.message}</div>;
  }

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          navigate('create');
        }}
      >
        Create user
      </Button>
      <Table<GetAllUsersType>
        dataSource={data ? data.getAllUsers : []}
        pagination={{ showSizeChanger: true }}
        bordered
        rowKey="id"
        size="small"
        loading={loading}
        className="tableStyle"
      >
        <Table.Column<GetAllUsersType>
          key="name"
          title="User Name"
          dataIndex="name"
          align="center"
          render={(name) => {
            return <span style={{ textTransform: 'capitalize' }}>{name || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.name
              ? record.name.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="user name" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<GetAllUsersType>
          key="address"
          title="Address"
          dataIndex="address"
          width="30%"
          align="center"
          render={(text, record) => {
            if (record.address) {
              const address = record.address;
              return (
                <span>
                  {address.address},{address.city}
                  <br />
                  {address.state} - {address.pincode}
                </span>
              );
            }
            return '-';
          }}
          onFilter={(value, record) => {
            if (record.address) {
              const address = record.address;
              const addressData = `${address.address} ${address.city} ${
                address.state ? address.state : ''
              } ${address.pincode}`;
              return addressData
                ? addressData.toString().toLowerCase().includes(value.toString().toLowerCase())
                : false;
            }
            return false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="address" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<GetAllUsersType>
          key="mobile"
          title="Mobile"
          dataIndex="mobile"
          align="center"
          render={(mobileNumber: string) => mobileNumber || '-'}
          onFilter={(value, record) => {
            return record.mobile
              ? record.mobile.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="mobile number"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column
          key="dob"
          title="DOB"
          dataIndex="dob"
          align="center"
          render={(dateOfBirth: string) =>
            dateOfBirth ? dayjs(dateOfBirth).format('DD MMM, YYYY') : '-'
          }
        />
        <Table.Column<GetAllUsersType>
          key="Actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    navigate(`${record.id}/edit`);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete User. Are you sure?"
                  okText="Yes"
                  onConfirm={() => {
                    handleDeleteUser(record.id);
                    setDeleteUserId(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                >
                  <Button
                    className="deleteButton"
                    loading={record.id === deleteUserId ? true : false}
                  >
                    Delete
                  </Button>
                </Popconfirm>
                {/* <Popconfirm
                  title="Reset User's Password. Are you sure?"
                  okText="Yes"
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                >
                  <Button type="primary">Reset Password</Button>
                </Popconfirm> */}
                <Popconfirm
                  icon={<WarningFilled />}
                  title={
                    <div>
                      <p>Enter user's new password:</p>
                      <InputComponent
                        name="updatedPassword"
                        placeholder="Please enter new password"
                        rhfControllerProps={{
                          control,
                          rules: {
                            required: {
                              value: true,
                              message: 'Please enter user new password and try again',
                            },
                          },
                        }}
                        customStyles={{
                          borderColor: errors.updatedPassword ? colors.errorTextColor : undefined,
                          width: 300,
                        }}
                      />
                      {errors.updatedPassword ? (
                        <p style={{ color: colors.errorTextColor }}>
                          {errors.updatedPassword.message}
                        </p>
                      ) : null}
                    </div>
                  }
                  okText="Yes"
                  visible={record.id === isPopConfirmVisibleForUserId}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  onCancel={() => {
                    reset();
                    setIsPopConfirmVisibleForUserId(null);
                  }}
                  onConfirm={() => {
                    // logic to keep the popConfirm open when error is present
                    if (errors) {
                      setIsPopConfirmVisibleForUserId(record.id);
                    }

                    // calling mutation to update user password with new one
                    handleSubmit((formData) => {
                      setIsResetPasswordLoadingAndUserId(record.id);
                      if (formData.updatedPassword) {
                        adminSetUserPassword({
                          variables: { password: formData.updatedPassword, userId: record.id },
                        })
                          .then(() => {
                            setIsResetPasswordLoadingAndUserId(null);
                            setIsPopConfirmVisibleForUserId(null);
                            reset();
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            message.success('User password has been successfully updated.');
                          })
                          .catch((err: ApolloError) => {
                            setIsResetPasswordLoadingAndUserId(null);
                            // eslint-disable-next-line @typescript-eslint/no-floating-promises
                            message.error(err.message);
                            setIsPopConfirmVisibleForUserId(null);
                          });
                      }
                    })().catch((err) => {
                      console.log(err);
                    });
                  }}
                >
                  <Button
                    type="primary"
                    loading={isResetPasswordLoadingAndUserId === record.id}
                    onClick={() => {
                      setIsPopConfirmVisibleForUserId(record.id);
                    }}
                  >
                    Reset Password
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};

export default ViewAllUsersScreen;
