import React, { useState } from 'react';
import { Button, message, Popconfirm, Table } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import {
  DeleteWeighbridgeReceiptByPkMutation,
  DeleteWeighbridgeReceiptByPkMutationVariables,
  GetAllWeighbridgeEntriesQuery,
  GetAllWeighbridgeEntriesQueryVariables,
} from '../../../graphql/graphql-types';
import { WeighbridgeReportTableType } from '../../../utils/types';
import { dateFormatFunc } from '../../../utils/globals';
import { logger } from '../../../utils/helpers';
import colors from '../../../scss/variables.module.scss';

/* loading get all weighbridge entries query */
const getAllWeighbridgeEntriesQuery = loader(
  '../../../graphql/queries/getAllWeighbridgeEntriesQuery.graphql',
);

/* loading delete weighbridge receipt By Pk mutation  */
const deleteWeighbridgeReceiptByPkMutation = loader(
  '../../../graphql/mutations/deleteWeighbridgeReceiptByPkMutation.graphql',
);

/* React functional component */
const WeighBridgeReportScreen = () => {
  /* extracting navigate from use Navigate hook */
  const navigate = useNavigate();

  /* state used to store id of the weighbridge  whose delete button is clicked which is then used to show loading indicator on delete button while deleting a weighbridge data */
  const [weighBridgeIdToDelete, setWeighBridgeIdToDelete] = useState<number | null>(null);

  /* get all weighbridge entries data query */
  const {
    data: getAllWeighbridgeEntriesData,
    loading: getAllWeighbridgeEntriesLoading,
    error: getAllWeighbridgeEntriesError,
  } = useQuery<GetAllWeighbridgeEntriesQuery, GetAllWeighbridgeEntriesQueryVariables>(
    getAllWeighbridgeEntriesQuery,
  );

  /* delete weighbridge receipt by pk mutation */
  const [deleteWeighbridgeReceiptByPk] = useMutation<
    DeleteWeighbridgeReceiptByPkMutation,
    DeleteWeighbridgeReceiptByPkMutationVariables
  >(deleteWeighbridgeReceiptByPkMutation);

  /* variable to stored amount value */
  let amount = 0;
  if (
    getAllWeighbridgeEntriesData &&
    getAllWeighbridgeEntriesData.weighbridgeReceipts_aggregate.aggregate &&
    getAllWeighbridgeEntriesData.weighbridgeReceipts_aggregate.aggregate.sum
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    amount = getAllWeighbridgeEntriesData.weighbridgeReceipts_aggregate.aggregate.sum.amountTaken;
  }

  /* function to handle delete  weighbridge */
  const handleDeleteWeighbridge = (weighbridgeId: number) => {
    setWeighBridgeIdToDelete(weighbridgeId);
    deleteWeighbridgeReceiptByPk({
      variables: {
        id: weighbridgeId,
      },
      /* Updating the cache of the weighbridgeReceipts after deleting a specific data from it */
      update(cache, { data: deleteData }) {
        /* to get the id of the data to be deleted */
        const idToRemove = deleteData?.delete_weighbridgeReceipts_by_pk?.id;
        cache.modify({
          fields: {
            weighbridgeReceipts(existingWeighbridgeReceipts: Array<Reference>, { readField }) {
              if (idToRemove) {
                /* return data after deleted item using idToRemove  */
                return existingWeighbridgeReceipts.filter(
                  (weighbridgeReceiptsData) =>
                    idToRemove !== readField('id', weighbridgeReceiptsData),
                );
              }
              return existingWeighbridgeReceipts;
            },
          },
        });
      },
      refetchQueries: [getAllWeighbridgeEntriesQuery],
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Weighbridge entry has been successfully deleted.');
        setWeighBridgeIdToDelete(null);
      })
      .catch((error: ApolloError) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error);
        logger(error);
        setWeighBridgeIdToDelete(null);
      });
  };

  if (getAllWeighbridgeEntriesError) {
    /* show error message on the screen, if it has any error to fetch data from the server */
    return <div className="errorText">{getAllWeighbridgeEntriesError.message}</div>;
  }

  return (
    <div className="tabLayout">
      <p style={{ fontSize: '17px' }}>
        Total Amount Collected (Rs.) {amount ? amount.toFixed(2) : '-'}
      </p>
      <Table<WeighbridgeReportTableType>
        bordered
        style={{
          marginTop: '40px',
          marginBottom: '20px',
          width: '100%',
        }}
        size="small"
        rowKey="id"
        loading={getAllWeighbridgeEntriesLoading}
        pagination={{ defaultPageSize: 20, showSizeChanger: true }}
        dataSource={
          getAllWeighbridgeEntriesData && getAllWeighbridgeEntriesData.weighbridgeReceipts
        }
      >
        <Table.Column
          title="Receipt No"
          dataIndex="receiptNumber"
          key="receiptNumber"
          align="center"
        />
        <Table.Column<WeighbridgeReportTableType>
          title="Date"
          dataIndex="createdAt"
          key="createdAt"
          align="center"
          render={(text, record) => (record.createdAt ? dateFormatFunc(record.createdAt) : '-')}
        />
        <Table.Column<WeighbridgeReportTableType>
          title="Customer Details"
          dataIndex="customerDetails"
          width="220px"
          render={(text, record) => {
            return (
              <div style={{ textAlign: 'start' }}>
                <b>Vehicle No:</b>
                {record.vehicleNumber ? record.vehicleNumber : '-'}
                <br />
                <b>Driver name:</b>
                {record.driverName ? record.driverName : '-'}
                <br />
                <b>Customer name:</b>
                {record.customerName ? record.customerName : '-'}
              </div>
            );
          }}
        />
        <Table.Column<WeighbridgeReportTableType>
          title={
            <>
              Weight 1 <br />
              (Tonnes)
            </>
          }
          dataIndex="weight1"
          align="center"
          width="130px"
          render={(text, record) =>
            record.emptyVehicleWtKg ? record.emptyVehicleWtKg / 1000 : '-'
          }
        />
        <Table.Column<WeighbridgeReportTableType>
          title={
            <>
              Weight 2 <br />
              (Tonnes)
            </>
          }
          dataIndex="weight2"
          align="center"
          width="130px"
          render={(text, record) => (record.fullVehicleWtKg ? record.fullVehicleWtKg / 1000 : '-')}
        />
        <Table.Column<WeighbridgeReportTableType>
          title={
            <>
              Net wt.
              <br />
              (Tonnes)
            </>
          }
          dataIndex="netWt"
          align="center"
          render={(text, record) => {
            /* calculation of net weight */
            const netWt = Math.abs(
              (record.fullVehicleWtKg ? record.fullVehicleWtKg : 0) -
                (record.emptyVehicleWtKg ? record.emptyVehicleWtKg : 0),
            );

            return <>{netWt ? (netWt / 1000).toFixed(2) : '-'}</>;
          }}
        />
        <Table.Column<WeighbridgeReportTableType>
          title="Amount (Rs.)"
          dataIndex="amountRs"
          align="center"
          render={(text, record) => (record.amountTaken ? record.amountTaken : '-')}
        />
        <Table.Column<WeighbridgeReportTableType>
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    navigate(`print/${record.id}`);
                  }}
                >
                  Print
                </Button>
                <Popconfirm
                  title="Delete entry. Are you sure?"
                  okText="Yes"
                  onConfirm={() => {
                    handleDeleteWeighbridge(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                >
                  <Button className="deleteButton" loading={record.id === weighBridgeIdToDelete}>
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default WeighBridgeReportScreen;
