import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { useApp } from '../../context/AppContext';
import colors from '../../scss/variables.module.scss';

/* management admin sub menu data */
const managementAdminSubMenu = [
  { name: 'Users', path: '/management/users' },
  { name: 'Transporters', path: '/management/transporters' },
  { name: 'Transporter Vehicles', path: '/management/transporterVehicles' },
  { name: 'Buyers', path: '/management/buyers' },
  { name: 'Vendors', path: '/management/sellers' },
  { name: 'Brokers', path: '/management/brokers' },
  { name: 'Drivers', path: '/management/drivers' },
  { name: 'Godowns', path: '/management/godowns' },
  { name: 'Materials', path: '/management/materials/rawMaterials' },
  { name: 'Settings', path: '/management/settings' },
  { name: 'Keshar Kali Codes', path: '/management/kesharKaliCodes' },
];

/* management manager sub menu data */
const managementManagerSubMenu = [
  { name: 'Transporters', path: '/management/transporters' },
  { name: 'Transporter Vehicles', path: '/management/transporterVehicles' },
  { name: 'Buyers', path: '/management/buyers' },
  { name: 'Vendors', path: '/management/sellers' },
  { name: 'Brokers', path: '/management/brokers' },
  { name: 'Drivers', path: '/management/drivers' },
  { name: 'Godowns', path: '/management/godowns' },
  { name: 'Materials', path: '/management/materials/rawMaterials' },
  // { name: 'Settings', path: '/management/settings' },
];

/* React functional component */
const ManagementTabLayout = () => {
  /* extracting location from useLocation hook */
  const location = useLocation();
  /* extracting data from context */
  const { user } = useApp();

  /* depending upon the user role sub menu data to be rendered - initially set to admin role */
  let managementSubMenu = managementAdminSubMenu;
  if (user && user.role === 'manager') {
    managementSubMenu = managementManagerSubMenu;
  }

  return (
    <div className="subMenu">
      <Menu
        style={{ backgroundColor: colors.colorBlack, display: 'flex' }}
        selectedKeys={[location.pathname]}
      >
        {managementSubMenu.map((item) => {
          return (
            <Menu.Item key={item.path}>
              <Link
                to={item.path}
                style={{ fontWeight: '500', fontSize: 14 }}
                className="hideInPrint"
              >
                {item.name}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
      <Outlet />
    </div>
  );
};

export default ManagementTabLayout;
