import React, { useState } from 'react';
import { ApolloError, useApolloClient, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Link, useParams } from 'react-router-dom';
import {
  GetInternalTransferShipmentByPkQuery,
  GetInternalTransferShipmentByPkQueryVariables,
  InternalTransferShipments,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
} from '../../../graphql/graphql-types';
import { Button, Descriptions, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { logger } from '../../../utils/helpers';
import 'react-image-lightbox/style.css';
import { dateFormatFunc } from '../../../utils/globals';
import InternalShipmentSummaryTable from './InternalShipmentSummaryTable';
import { UploadedFileKeyAndUrlType } from '../../../utils/types';
import LightBoxComponent from '../../../components/LightboxComponent';

/* loading get internal transfer shipment By Pk query */
const getInternalTransferShipmentByPkQuery = loader(
  '../../../graphql/queries/getInternalTransferShipmentByPkQuery.graphql',
);

/* loading get files url query */
const getFilesUrlQuery = loader('../../../graphql/queries/getFilesURLQuery.graphql');

/* React functional component */
const InternalShipmentSummaryScreen = () => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* extracting apollo Client from use use apollo client*/
  const apolloClient = useApolloClient();

  /* state used to store object of vehicle image which contain image key and its respective url */
  const [imageUrlObjectArray, setImageUrlObjectArray] = useState<Array<UploadedFileKeyAndUrlType>>(
    [],
  );

  /* state used to set the visibility of lightBox for image display */
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

  /* get internal transfer shipment By Pk query */
  const {
    data: internalTransferByPkData,
    loading: internalTransferByPkDataLoading,
    error: internalTransferByPkDataError,
  } = useQuery<GetInternalTransferShipmentByPkQuery, GetInternalTransferShipmentByPkQueryVariables>(
    getInternalTransferShipmentByPkQuery,
    {
      variables: { id: params.id as string },
      /* after execution of internal transfer shipment query check if vehicle images present or not */
      onCompleted: (res) => {
        /* destructing vehicle images key from query returned object */
        const { firstVehicleImage, secondVehicleImage } =
          res.internalTransferShipments_by_pk as InternalTransferShipments;

        /* const array used to store vehicle images keys */
        const vehicleImageKeyArray: string[] = [];

        /* if first vehicle image present then push that image key into vehicle image array */
        if (firstVehicleImage) {
          vehicleImageKeyArray.push(firstVehicleImage);
        }

        /* if second vehicle image present then push that image key into vehicle image array */
        if (secondVehicleImage) {
          vehicleImageKeyArray.push(secondVehicleImage);
        }

        /* if vehicle images added for the respective shipment, then get that image urls using image key  */
        if (vehicleImageKeyArray)
          /* get files url query used to get urls & key of the images */
          apolloClient
            .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
              query: getFilesUrlQuery,
              variables: {
                keys: vehicleImageKeyArray,
              },
            })
            .then((imageUrlsData) => {
              /* destructing images file url and key */
              const { getFilesUrl } = imageUrlsData.data;

              /* const array used to store key and url of image */
              const fileUrlAndKeyArray: Array<UploadedFileKeyAndUrlType> = [];

              /* if url and key present for image then push it into file url array for further operation */
              if (getFilesUrl) {
                getFilesUrl.forEach((item) => {
                  if (item) {
                    /* create key and url object from resultant data to simplify further data handling */
                    fileUrlAndKeyArray.push({
                      key: item.key,
                      url: item.url,
                    });
                  }
                });
              }

              /* update url and key of images array into state , so that we can use it to display image */
              setImageUrlObjectArray(fileUrlAndKeyArray);
            })
            .catch((getFilesUrlError: ApolloError) => {
              logger(getFilesUrlError);
            });
      },
    },
  );

  /* show loading indicator on the screen until data get fetch from the server */
  if (internalTransferByPkDataLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (internalTransferByPkDataError) {
    return <div className="errorText">{internalTransferByPkDataError.message}</div>;
  }

  /* if internal transfer data loaded successfully then destruct internal transfer data  */
  if (internalTransferByPkData) {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      firstVehicleWtKg,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      secondVehicleWtKg,
      materialTypeValue,
      vehicleNumber,
      grn,
      personMobile,
      personName,
      internalTransferShipmentItems,
    } = internalTransferByPkData.internalTransferShipments_by_pk as InternalTransferShipments;

    return (
      <div className="tabLayout">
        <div style={{ display: 'flex' }}>
          <Link
            to="/report/shipment/internal"
            style={{ textAlign: 'start' }}
            className="hideInPrint "
          >
            <ArrowLeftOutlined className="goBackButton" />
          </Link>
          <h2>Internal Shipment Summary</h2>
        </div>
        <Descriptions
          colon={false}
          column={1}
          labelStyle={{ width: 260 }}
          style={{ marginTop: 40 }}
          contentStyle={{ textTransform: 'capitalize' }}
        >
          <Descriptions.Item label="GRN">{grn}</Descriptions.Item>
          <Descriptions.Item label="Created At">
            {internalTransferByPkData.internalTransferShipments_by_pk
              ? dateFormatFunc(internalTransferByPkData.internalTransferShipments_by_pk.created_at)
              : null}
          </Descriptions.Item>
          <Descriptions.Item label="Material Type">
            {materialTypeValue ? materialTypeValue.display_name : null}
          </Descriptions.Item>
          <Descriptions.Item label="Vehicle Number">{vehicleNumber || '-'}</Descriptions.Item>
          <Descriptions.Item label="Delivery Person Name">{personName}</Descriptions.Item>
          <Descriptions.Item label="Delivery Person Mobile">
            {personMobile || '-'}
          </Descriptions.Item>

          {materialTypeValue &&
          (materialTypeValue.value === 'raw_material' || materialTypeValue.value === 'product') ? (
            <>
              <Descriptions.Item label="Vehicle Weight-1 (in kgs)">
                {firstVehicleWtKg || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle Weight-2 (in kgs)">
                {secondVehicleWtKg || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Net Total Material Weight (in kgs)">
                {Math.abs(firstVehicleWtKg - secondVehicleWtKg)}
              </Descriptions.Item>
              <Descriptions.Item contentStyle={{ marginTop: 10 }}>
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={() => {
                    /* show vehicle photos */
                    setIsImageOpen(true);
                  }}
                  disabled={imageUrlObjectArray.length === 0}
                >
                  View Vehicle photos
                </Button>
                {isImageOpen && (
                  <LightBoxComponent
                    imageUrlObjectArray={imageUrlObjectArray}
                    setIsImageOpen={setIsImageOpen}
                    screenType="others"
                  />
                )}
              </Descriptions.Item>
            </>
          ) : null}

          {materialTypeValue &&
          (materialTypeValue.value === 'others' ||
            materialTypeValue.value === 'paddy_bag' ||
            materialTypeValue.value === 'product_bag') ? (
            <Descriptions.Item label="Item Name">
              {/* here we are sure that, we will get only one shipment item in array so, we can directly check and access that shipment item data */}
              {internalTransferShipmentItems[0] && internalTransferShipmentItems[0].item
                ? internalTransferShipmentItems[0].item.name || '-'
                : internalTransferShipmentItems[0].othersItemName || '-'}
            </Descriptions.Item>
          ) : null}

          {materialTypeValue &&
          (materialTypeValue.value === 'paddy_bag' || materialTypeValue.value === 'product_bag') ? (
            <Descriptions.Item label="No. of Bags">
              {(internalTransferShipmentItems[0] && internalTransferShipmentItems[0].bagsCount) ||
                '-'}
            </Descriptions.Item>
          ) : null}

          {materialTypeValue && materialTypeValue.value === 'others' ? (
            <>
              <Descriptions.Item label="Quantity">
                {(internalTransferShipmentItems[0] &&
                  internalTransferShipmentItems[0].othersQuantity) ||
                  '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Weight (in kgs)">
                {(internalTransferShipmentItems[0] &&
                  internalTransferShipmentItems[0].othersWtKg) ||
                  '-'}
              </Descriptions.Item>
            </>
          ) : null}

          {materialTypeValue &&
          (materialTypeValue.value === 'others' ||
            materialTypeValue.value === 'paddy_bag' ||
            materialTypeValue.value === 'product_bag') ? (
            <Descriptions.Item label="Remark">
              {(internalTransferShipmentItems[0] && internalTransferShipmentItems[0].remark) || '-'}
            </Descriptions.Item>
          ) : null}
        </Descriptions>
        {materialTypeValue &&
        (materialTypeValue.value === 'raw_material' || materialTypeValue.value === 'product') ? (
          <InternalShipmentSummaryTable
            data={internalTransferShipmentItems}
            shipmentMaterialType={materialTypeValue}
          />
        ) : null}
      </div>
    );
  }
  return <div />;
};

export default InternalShipmentSummaryScreen;
