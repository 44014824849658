import React from 'react';
import { Switch as AntdSwitch, SwitchProps as AntdSwitchPropType } from 'antd';
import { UseControllerProps, useController } from 'react-hook-form';

// type for props of SwitchComponent
type SwitchComponentType = {
  // Name is the name of form field
  name: string;
  // controller props of react-hook-form
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rhfControllerProps: Omit<UseControllerProps<any>, 'name'>;
  // This is the custom style for switch
  customStyles?: React.CSSProperties;
  // This function is called when functionality is different from the regular onChange
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (rhfOnChange: (...event: any[]) => void, val: boolean) => void;
  // This will contain actual prop of antd switch
  SwitchProps?: Omit<AntdSwitchPropType, 'onChange'>;
};

// react functional component
const Switch: React.FC<SwitchComponentType> = ({
  name,
  rhfControllerProps,
  customStyles = {},
  onChange,
  SwitchProps,
}) => {
  // destructuring fields from useController hook
  const { field } = useController({
    name,
    ...rhfControllerProps,
  });

  // destructuring onChange from fields and using remaining props as rhfFields
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { onChange: rhfOnChange, value, ...rhfFields } = field;

  return (
    <AntdSwitch
      onChange={(switchValue) => {
        if (onChange) {
          onChange(rhfOnChange, switchValue);
        } else {
          rhfOnChange(switchValue);
        }
      }}
      {...rhfFields}
      style={customStyles}
      {...SwitchProps}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      checked={value}
    />
  );
};

export default Switch;
