import React, { useState } from 'react';
import { useQuery, useApolloClient, ApolloError } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useParams } from 'react-router-dom';
import AddAndEditTransporterDriverForm from './AddAndEditTransporterDriverForm';
import {
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
  GetTransporterDriverByIdQuery,
  GetTransporterDriverByIdQueryVariables,
} from '../../../graphql/graphql-types';
import { logger } from '../../../utils/helpers';
import { AddressType } from '../../../utils/types';
import { Spin } from 'antd';

/* photo and pan url object type */
type PhotoAndPanUrlType = {
  /* photo url */
  photoUrl: string | null;
  /* pan url */
  panUrl: string | null;
};

/* loading get transporter driver by Id query with the help of loader */
const getTransporterDriverByIdQuery = loader(
  '../../../graphql/queries/getTransporterDriverByIdQuery.graphql',
);

/* loading get Files URL query with the help of loader */
const getFilesUrlQuery = loader('../../../graphql/queries/getFilesUrlQuery.graphql');

/* React functional component */
const EditTransporterDriverScreen = () => {
  // this state is defined in order to set respective urls when array of keys is passed to getFilesUrlQuery,if both keys are returned
  // then both will be set or else the url which is returned(photo or pan) will be set accordingly
  const [panAndPhotoUrl, setPanAndPhotoUrl] = useState<PhotoAndPanUrlType>({
    photoUrl: null,
    panUrl: null,
  });

  /* extracting params from useParams hook */
  const params = useParams();

  /* extracting apollo client from useApolloClient() */
  const apolloClient = useApolloClient();

  /* get transporter driver by Id query */
  const { data, loading, error } = useQuery<
    GetTransporterDriverByIdQuery,
    GetTransporterDriverByIdQueryVariables
  >(getTransporterDriverByIdQuery, {
    variables: { id: Number(params.id) },
    onCompleted: ({ getTransporterDriverById }) => {
      // array to store the key's of photo and panFile and even check for null and empty string as if passed created a response time out
      const fileKeyArray = [];
      if (
        getTransporterDriverById &&
        getTransporterDriverById.panFile &&
        getTransporterDriverById.panFile !== ''
      ) {
        fileKeyArray.push(getTransporterDriverById.panFile);
      }
      if (
        getTransporterDriverById &&
        getTransporterDriverById.photo &&
        getTransporterDriverById.photo !== ''
      ) {
        fileKeyArray.push(getTransporterDriverById.photo);
      }
      if (fileKeyArray) {
        apolloClient
          .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
            query: getFilesUrlQuery,
            variables: {
              keys: fileKeyArray,
            },
          })
          .then((filesUrlsData) => {
            const { getFilesUrl } = filesUrlsData.data;
            let photoUrlAndKey;
            let panUrlAndKey;
            if (Array.isArray(getFilesUrl) && getFilesUrl.length > 0 && getTransporterDriverById) {
              photoUrlAndKey = getFilesUrl.find(
                (item) => item && item.key === getTransporterDriverById.photo,
              );
              panUrlAndKey = getFilesUrl.find(
                (item) => item && item.key === getTransporterDriverById.panFile,
              );
              setPanAndPhotoUrl({
                photoUrl: photoUrlAndKey ? photoUrlAndKey.url : null,
                panUrl: panUrlAndKey ? panUrlAndKey.url : null,
              });
            }
          })
          .catch((getFilesUrlError: ApolloError) => {
            logger(getFilesUrlError);
          });
      }
    },
  });

  /* show loading indicator on the screen until data gets fetched from the server */
  if (loading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen if it has any error while loading data from the server */
  if (error) {
    return <div className="errorText">{error.message}</div>;
  }

  /* getting address data from data.getTransporterById in order to further use it while passing the data to transporter form */
  let addressData;
  if (data && data.getTransporterDriverById) {
    addressData = data.getTransporterDriverById.address as AddressType;
  }

  return (
    <>
      {data && data.getTransporterDriverById ? (
        <AddAndEditTransporterDriverForm
          mode="edit"
          editTransporterDriverData={{
            id: data.getTransporterDriverById.id,
            name: data.getTransporterDriverById.name,
            drivingLicence: data.getTransporterDriverById.drivingLicence || '',
            mobile: data.getTransporterDriverById.mobile,
            address: addressData ? addressData.address : '',
            city: addressData ? addressData.city : '',
            state: addressData ? addressData.state : '',
            pincode: addressData ? addressData.pincode : '',
            photoKey: data.getTransporterDriverById.photo || null,
            panKey: data.getTransporterDriverById.panFile || null,
            photoUrl: panAndPhotoUrl.photoUrl,
            panFileUrl: panAndPhotoUrl.panUrl,
          }}
        />
      ) : null}
    </>
  );
};

export default EditTransporterDriverScreen;
