import React from 'react';
import { Tabs } from 'antd';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';

/* extracting Tab pane from Tabs */
const { TabPane } = Tabs;

const WeighbridgeProductTabLayout = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();
  /* useLocation destructuring */
  const location = useLocation();

  /* function to get the active tab key */
  const getActiveKey = () => {
    if (location.pathname.includes('load')) {
      return 'materialLoadKey';
    } else if (location.pathname.includes('finish')) {
      return 'finishShipmentKey';
    }
    return 'createShipmentKey';
  };

  return (
    <div className="tabLayout">
      <Tabs
        defaultActiveKey="createShipmentKey"
        onTabClick={(key: string) => {
          if (key === 'materialLoadKey') {
            navigate('load');
          } else if (key === 'finishShipmentKey') {
            navigate('finish');
          } else {
            navigate('create');
          }
        }}
        activeKey={getActiveKey()}
        tabBarGutter={50}
        destroyInactiveTabPane
      >
        <TabPane tab="Create Shipment" key="createShipmentKey">
          <Outlet />
        </TabPane>
        <TabPane tab="Material Loading" key="materialLoadKey">
          <Outlet />
        </TabPane>
        <TabPane tab="Finish Shipment" key="finishShipmentKey">
          <Outlet />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default WeighbridgeProductTabLayout;
