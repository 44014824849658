import React from 'react';
import { Table } from 'antd';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import ColumnSearchComponent from '../../../../components/ColumnSearchComponent';
import { dateFormatFunc } from '../../../../utils/globals';
import { OutwardShipmentsReportsType } from '../../../../utils/types';

/* Daily Reports Outward Product Table Prop Type */
type DailyReportsOutwardProductTablePropType = {
  /* prop type used to get data for the table */
  data: OutwardShipmentsReportsType[];
};

/* React functional component */
const DailyReportsOutwardProductTable = ({ data }: DailyReportsOutwardProductTablePropType) => {
  return (
    <div className="reportTableContainer">
      <div className="reportTable">
        <Table<OutwardShipmentsReportsType>
          dataSource={data}
          className="tableStyle"
          bordered
          size="small"
          rowKey="id"
          pagination={{ showSizeChanger: true }}
        >
          <Table.Column<OutwardShipmentsReportsType>
            title="GRN"
            dataIndex="grn"
            key="grn"
            align="center"
            onFilter={(value, record) => {
              return record.grn
                ? record.grn.toLowerCase().includes(value.toString().toLowerCase())
                : false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(text, record) => record.grn}
          />
          <Table.Column<OutwardShipmentsReportsType>
            title="Started At"
            dataIndex="createdAt"
            key="createdAt"
            align="center"
            render={(text, record) => dateFormatFunc(record.createdAt as string)}
          />
          <Table.Column<OutwardShipmentsReportsType>
            title="Status"
            dataIndex="status"
            key="status"
            align="center"
            filters={[
              {
                text: 'Completed',
                value: 'Completed',
              },
              {
                text: 'Invoice not added',
                value: 'Invoice not added',
              },
              {
                text: 'Loading done',
                value: 'Loading done',
              },
              {
                text: 'Loading...',
                value: 'Loading...',
              },
            ]}
            onFilter={(value, record) => record.status === value}
            filterIcon={(filtered) => {
              return <FilterFilled className={filtered ? 'searchIcon' : ''} />;
            }}
          />

          <Table.Column<OutwardShipmentsReportsType>
            title="Transporter Name"
            key="transporterName"
            dataIndex="transporterName"
            align="center"
            onFilter={(value, record) => {
              return record.transporter && record.transporter.name
                ? record.transporter.name.toLowerCase().includes(value.toString().toLowerCase())
                : false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent
                filterBy="transporter name"
                filterDropDownProp={filterDropDownProp}
              />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(text, record) => (record.transporter ? record.transporter.name : '-')}
          />
          <Table.Column<OutwardShipmentsReportsType>
            title="Vehicle Number"
            key="vehicleNumber"
            dataIndex="vehicleNumber"
            align="center"
            onFilter={(value, record) => {
              return record.transporterVehicle && record.transporterVehicle.number
                ? record.transporterVehicle.number
                    .toLowerCase()
                    .includes(value.toString().toLowerCase())
                : false;
            }}
            filterDropdown={(filterDropDownProp) => (
              <ColumnSearchComponent
                filterBy="vehicle number"
                filterDropDownProp={filterDropDownProp}
              />
            )}
            filterIcon={(filtered) => {
              return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
            }}
            render={(text, record) =>
              record.transporterVehicle ? record.transporterVehicle.number : '-'
            }
          />
          <Table.Column<OutwardShipmentsReportsType>
            title="Net Weight (quintals)"
            key="netWeight"
            dataIndex="netWeight"
            align="center"
            render={(text, record) =>
              Math.abs(record.fullVehicleWtKg - record.emptyVehicleWtKg) / 100
            }
          />
        </Table>
      </div>
    </div>
  );
};

export default DailyReportsOutwardProductTable;
