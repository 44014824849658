import React from 'react';
import { InwardShipmentBySellerIdType } from '../types';

/**
 * Function to calculate the 'net total amount' which is used to
 * display in farmer/trader payment ledger table
 */
const getNetTotalAmount = (record: InwardShipmentBySellerIdType) => {
  let netTotalAmount = 0;

  if (record.weighbridgeCharges) {
    netTotalAmount -= record.weighbridgeCharges;
  }

  record.items.forEach((item) => {
    if (item.id) {
      /* const used to store number of bags in shipment */
      const numberOfBags = item.bagsCount ? item.bagsCount : 0;

      if (!item.materialReturned) {
        /* const used to store total net material weight */
        const totalNetMaterialWeight =
          numberOfBags * (item.netMaterialWtPerBagKg ? item.netMaterialWtPerBagKg : 0);

        /* const used to store total new material weight in quintal  */
        const totalNetMaterialWeightInQt = parseFloat((totalNetMaterialWeight / 100).toFixed(2));

        /* const used to store price of total weight */
        const priceOfTotalWt =
          totalNetMaterialWeightInQt * ((item.pricePerKg ? item.pricePerKg : 0) * 100);

        /* const used to store total price of empty bags weight */
        const priceOfTotalEmptyBagWt = (item.emptyBagCost ? item.emptyBagCost : 0) * numberOfBags;

        /* const used to store price of brokerage */
        const priceOfBrokerage =
          (item.brokeragePerQuintal ? item.brokeragePerQuintal : 0) * totalNetMaterialWeightInQt;

        /* const used to store sub total */
        const subtotal = parseFloat(
          (priceOfTotalWt + priceOfBrokerage - priceOfTotalEmptyBagWt).toFixed(2),
        );

        /* const used to store total amount */
        netTotalAmount += subtotal;

        if (
          record.laborChargeType &&
          record.laborChargePerBag !== null &&
          record.laborChargePerBag !== undefined
        ) {
          if (record.laborChargeType === 'add') {
            /* total amount for labour charge type 'add' */
            netTotalAmount += numberOfBags * record.laborChargePerBag;
          } else {
            /* total amount for labour charge type 'deduct' */
            netTotalAmount -= numberOfBags * record.laborChargePerBag;
          }
        }
      }
    }
  });

  return netTotalAmount;
};

export default getNetTotalAmount;
