import React from 'react';
import styles from './LoadingScreen.module.scss';

/* React functional component */
const LoadingScreen = (): JSX.Element => {
  return (
    <div className="fullScreen">
      <div className={styles.loader} />
    </div>
  );
};

export default LoadingScreen;
