import React, { useMemo, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, useLazyQuery, ApolloError } from '@apollo/client';
import { Table, Button, Descriptions, Divider, Modal, message, Row, Col } from 'antd';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import {
  GetAllRmSellersQuery,
  GetAllRmSellersQueryVariables,
  RmSellers,
  GetRmSellerByIdQuery,
  GetRmSellerByIdQueryVariables,
  InwardShipmentItems,
  RawMaterials,
  Godowns,
  InwardShipments,
  GetAllInwardShipmentBySellerIdQueryVariables,
  GetAllInwardShipmentBySellerIdQuery,
} from '../../../graphql/graphql-types';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import FormItem from '../../../components/FormItem';
import Select from '../../../components/Select';
import { dateFormatFunc, inputComponentCommonStyle } from '../../../utils/globals';
import {
  AddressType,
  EditVendorDataType,
  InwardShipmentBySellerIdType,
} from '../../../utils/types';
import dayjs from 'dayjs';
import AddOrEditVendorForm from '../../management/vendors/AddOrEditVendorForm';
import { logger } from '../../../utils/helpers';
import styles from './FarmerOrTraderHistory.module.scss';
import FinancialYearComponent from '../../../components/FinancialYearComponent';
import RadioGroup from '../../../components/RadioGroup';
import getTotalPaidAndDeductionAmount from '../../../utils/helpers/getTotalPaidAndDeductionAmount';
import getNetTotalAmount from '../../../utils/helpers/getNetTotalAmount';

/* loading get all vendors query */
const getAllVendorsQuery = loader('../../../graphql/queries/getAllRmSellersQuery.graphql');

/* loading get vendor by Id query */
const getVendorByIdQuery = loader('../../../graphql/queries/getRmSellerByIdQuery.graphql');

/* loading get all inward shipment by seller Id query */
const getAllInwardShipmentBySellerIdQuery = loader(
  '../../../graphql/queries/getAllInwardShipmentBySellerIdQuery.graphql',
);

/* type for payment summary table */
type PaymentSummaryTableType = InwardShipmentBySellerIdType;

/* farmer or trader history report form type */
type SellerHistoryReportFormType = {
  /* vendor id */
  vendorId: number | null;
  /* prop for seller history */
  sellerHistory: 'purchase' | 'payment';
};

/* farmer or trader history table data type */
type FarmerTraderHistoryTableType = Pick<
  InwardShipmentItems,
  | 'bagsCount'
  | 'createdAt'
  | 'destination'
  | 'materialReturned'
  | 'paddyGrade'
  | 'netMaterialWtPerBagKg'
  | 'pricePerKg'
  | 'emptyBagCost'
  | 'emptyBagsWtKg'
  | 'id'
> & { rawMaterial?: Pick<RawMaterials, 'id' | 'name'> | null } & {
  godown?: Pick<Godowns, 'id' | 'name'> | null;
} & {
  shipment: Pick<
    InwardShipments,
    'id' | 'grn' | 'completedAt' | 'fullVehicleWtKg' | 'emptyVehicleWtKg'
  >;
};

/* farmer or trader details type */
type FarmerOrTraderDetailsType = Pick<
  RmSellers,
  'gstin' | 'id' | 'name' | 'primaryContactMobile' | 'primaryContactName'
> & { address?: AddressType | null };

/* react functional component */
const FarmerOrTraderHistoryScreen = () => {
  /* this state used to store start and end date, which is selected using financial year radio option */
  const [selectedDate, setSelectedDate] = useState<{
    // prop definition for start date which is set when financial year from select box is present
    startDate: string | null;
    // prop definition for end date which is set when financial year from select box is present
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  /* this state used to store filtered data of table */
  const [tableFilteredData, setTableFilteredData] = useState<Array<FarmerTraderHistoryTableType>>(
    [],
  );

  /* state used to store farmer/trader (vendor) data on 'edit profile' button click and to manage form modal visibility */
  const [vendorDataAndIsModalVisible, setVendorDataAndIsModalVisible] =
    useState<EditVendorDataType | null>(null);

  /* get all vendors query used to show list of farmer/Trader on 'Select seller' field */
  const {
    data: getAllVendorsData,
    loading: getAllVendorsLoading,
    error: getAllVendorsError,
  } = useQuery<GetAllRmSellersQuery, GetAllRmSellersQueryVariables>(getAllVendorsQuery);

  /* get vendor by Id query used to show farmer or trader information  */
  const [
    getVendorById,
    { data: getVendorByIdData, loading: getVendorByIdDataLoading, error: getVendorByIdDataError },
  ] = useLazyQuery<GetRmSellerByIdQuery, GetRmSellerByIdQueryVariables>(getVendorByIdQuery);

  /* 'GetAllInwardShipmentBySellerIdQuery' to get all inward shipments data for selected farmer */
  const [
    getAllInwardShipmentBySellerId,
    {
      data: getAllInwardShipmentBySellerIdData,
      loading: getAllInwardShipmentBySellerIdLoading,
      error: getAllInwardShipmentBySellerIdError,
    },
  ] = useLazyQuery<
    GetAllInwardShipmentBySellerIdQuery,
    GetAllInwardShipmentBySellerIdQueryVariables
  >(getAllInwardShipmentBySellerIdQuery);

  const inwardShipmentDataBySellerArray =
    getVendorByIdData &&
    getVendorByIdData.getRmSellerById &&
    getVendorByIdData.getRmSellerById.inwardShipmentItems;

  /** const to store inward shipment item details when user selects 'Payment' option */
  const getAllInwardShipmentBySellerIdDetails =
    getAllInwardShipmentBySellerIdData && getAllInwardShipmentBySellerIdData.inwardShipments;

  /**
   * If user selects financial year filter to show data for particular financial year then
   * 'inwardShipmentDataBySellerArray' is filtered based on financial year
   * otherwise 'inwardShipmentDataBySellerArray' data is displayed
   */
  const inwardShipmentDataBySeller = useMemo(() => {
    if (
      selectedDate &&
      selectedDate.startDate &&
      selectedDate.endDate &&
      inwardShipmentDataBySellerArray
    ) {
      return inwardShipmentDataBySellerArray.filter((item) => {
        return (
          (item.shipment.completedAt as string) >= (selectedDate.startDate as string) &&
          (item.shipment.completedAt as string) <= (selectedDate.endDate as string)
        );
      });
    }

    return inwardShipmentDataBySellerArray;
  }, [inwardShipmentDataBySellerArray, selectedDate]);

  /** If user selects financial year filter to show data for particular financial year then
   * `getAllInwardShipmentBySellerIdDetails` is filtered based on financial year
   * otherwise `getAllInwardShipmentBySellerIdDetails` data is displayed
   */
  const inwardShipmentPaymentDetailsBySeller = useMemo(() => {
    if (
      selectedDate &&
      selectedDate.startDate &&
      selectedDate.endDate &&
      getAllInwardShipmentBySellerIdDetails &&
      Array.isArray(getAllInwardShipmentBySellerIdDetails) &&
      getAllInwardShipmentBySellerIdDetails.length > 0
    ) {
      return getAllInwardShipmentBySellerIdDetails.filter((item) => {
        return (
          (item.createdAt as string) >= (selectedDate.startDate as string) &&
          (item.createdAt as string) <= (selectedDate.endDate as string)
        );
      });
    }

    return getAllInwardShipmentBySellerIdDetails;
  }, [getAllInwardShipmentBySellerIdDetails, selectedDate]);

  /* useForm declaration */
  const { control, watch } = useForm<SellerHistoryReportFormType>({
    defaultValues: { vendorId: null, sellerHistory: 'payment' },
    mode: 'onChange',
  });

  const selectedVendorId = watch('vendorId');

  /* this function used to close form modal */
  const closeModal = () => {
    setVendorDataAndIsModalVisible(null);
  };

  /* this function used to calculate inward shipment table calculations */
  const inwardShipmentCalculation = (
    inwardShipments: Array<FarmerTraderHistoryTableType>,
  ): {
    totalNumberOfBagsInShipments: number;
    totalNetAmount: number;
    totalNetMaterialWeightInQuintal: number;
  } => {
    /* this variable used to store total amount of inward shipments */
    let totalNetAmount = 0;
    /* this variable used to store total number of bags in inward shipments  */
    let totalNumberOfBagsInShipments = 0;
    /* this variable used to store total net material weight in inward shipments */
    let totalNetMaterialWeightInQuintal = 0;

    /* calculate total number of bags in inward shipment by counting each shipment item bags. */
    inwardShipments.forEach((shipment) => {
      if (shipment.bagsCount) {
        totalNumberOfBagsInShipments += shipment.bagsCount;
      }
    });

    inwardShipments.forEach((item) => {
      /* calculate net weight of material from empty & full vehicle weight. ( this weight include material & material's bags weight) */
      const weightOfAllMaterialsWithBags =
        item.shipment.fullVehicleWtKg - item.shipment.emptyVehicleWtKg;

      /* this const used to store per bag material weight (this per bag material weight also include bag weight) */
      const materialWithBagWeightPerKg =
        weightOfAllMaterialsWithBags / totalNumberOfBagsInShipments;

      /* remove weight of empty bag from material weight , so that we can get only material weight */
      const weightOfMaterialPerBagInKg = materialWithBagWeightPerKg - item.emptyBagsWtKg;

      if (item.bagsCount) {
        /* calculate total net material weight in inward shipments */
        totalNetMaterialWeightInQuintal += (item.netMaterialWtPerBagKg * item.bagsCount) / 100;

        /* calculate total amount from weight of material per bag kg , price of material per kg & number of bags for that shipment item */
        totalNetAmount += weightOfMaterialPerBagInKg * item.pricePerKg * item.bagsCount;

        /* if empty bags are returned then remove that bags price from total amount */
        if (item.emptyBagCost) {
          totalNetAmount = totalNetAmount - item.emptyBagCost * item.bagsCount;
        }
      }
    });

    return {
      totalNetAmount: totalNetAmount,
      totalNetMaterialWeightInQuintal: totalNetMaterialWeightInQuintal,
      totalNumberOfBagsInShipments: totalNumberOfBagsInShipments,
    };
  };

  /* const used to store 'totalNetMaterialWeightInQuintal' , 'totalNetAmount' & 'totalNumberOfBagsInShipments' calculations
   by destructing 'inwardShipmentCalculation' function return */
  const { totalNumberOfBagsInShipments, totalNetAmount, totalNetMaterialWeightInQuintal } =
    inwardShipmentCalculation(tableFilteredData);

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllVendorsError || getVendorByIdDataError || getAllInwardShipmentBySellerIdError) {
    let errorMessage;
    if (getAllVendorsError) {
      errorMessage = getAllVendorsError.message;
    } else if (getVendorByIdDataError) {
      errorMessage = getVendorByIdDataError.message;
    } else if (getAllInwardShipmentBySellerIdError) {
      errorMessage = getAllInwardShipmentBySellerIdError.message;
    }
    return <div className="errorText">{errorMessage}</div>;
  }

  /* this react functional component used to show farmer or trader details */
  const TraderOrFarmerDetails = (): JSX.Element => {
    /* if farmer/trader data loaded , then destruct the data */
    const { primaryContactMobile, primaryContactName, gstin, address } = (
      getVendorByIdData as GetRmSellerByIdQuery
    ).getRmSellerById as FarmerOrTraderDetailsType;

    return (
      <>
        <Row gutter={16} style={{ marginTop: 30, marginBottom: 15 }}>
          <Col className="gutter-row" span={3}>
            <Button
              type="primary"
              onClick={() => {
                if (getVendorByIdData && getVendorByIdData.getRmSellerById) {
                  setVendorDataAndIsModalVisible(getVendorByIdData.getRmSellerById);
                }
              }}
            >
              Edit Profile
            </Button>
          </Col>
          <Col className="gutter-row" span={4}>
            <Row>
              <Col className={styles.colHeading}>GST :</Col>
              <Col className={styles.colContent}>{gstin || '-'}</Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={6}>
            <Row>
              <Col className={styles.colHeading} span={8}>
                Contact name :
              </Col>
              <Col className={styles.colContent} span={15}>
                {primaryContactName || '-'}
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={5}>
            <>
              <span className={styles.colHeading}>Contact mobile :</span>
              <span className={styles.colContent}>{primaryContactMobile || '-'}</span>
            </>
          </Col>
          <Col className="gutter-row" span={6}>
            <div style={{ display: 'flex' }}>
              <span className={styles.colHeading} style={{ whiteSpace: 'nowrap' }}>
                Address :
              </span>
              <span className={styles.colContent}>
                {address ? (
                  <div>
                    {address.address || '-'}
                    <br />
                    {address.city} {address.city ? ',' : null} {address.state}
                    {address.state ? '-' : null} {address.pincode}
                  </div>
                ) : (
                  '-'
                )}
              </span>
            </div>
          </Col>
        </Row>
        <Descriptions
          column={1}
          labelStyle={{ fontWeight: 'bold' }}
          contentStyle={{ color: 'purple', fontWeight: '500', textTransform: 'capitalize' }}
        >
          <Descriptions.Item label=" Total net material weight (quintals)">
            {totalNetMaterialWeightInQuintal.toFixed(2)}
          </Descriptions.Item>
          <Descriptions.Item label="Total net amount (Rs.)">
            {totalNetAmount.toFixed(2)}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  return (
    <div className="tabLayout">
      {vendorDataAndIsModalVisible ? (
        <Modal
          visible={vendorDataAndIsModalVisible ? true : false}
          title="Edit Vendor"
          footer={null}
          onCancel={() => {
            closeModal();
          }}
          className="buyerOrFarmerTraderHistoryModal"
          destroyOnClose
        >
          <div style={{ paddingLeft: 20 }}>
            <AddOrEditVendorForm
              mode="edit"
              editVendorData={vendorDataAndIsModalVisible}
              editVendorCalledFrom="farmerOrTraderHistory"
              closeModal={() => {
                closeModal();
              }}
            />
          </div>
        </Modal>
      ) : null}
      <form>
        <Col span={12}>
          <FormItem
            label="Select Seller :"
            isRequired
            requiredMark="after"
            inputColSpan={18}
            labelColSpan={6}
            customStyle={{ alignItems: 'center' }}
          >
            <Select
              customStyles={inputComponentCommonStyle}
              placeholder="Please select farmer/trader"
              name="vendorId"
              rhfControllerProps={{
                control,
              }}
              selectProps={{
                showSearch: true,
                loading: getAllVendorsLoading,
                optionFilterProp: 'children',
              }}
              options={
                getAllVendorsData
                  ? getAllVendorsData.getAllRmSellers.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChange={async (rhfOnChange, value: number) => {
                try {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                  rhfOnChange(value);
                  if (value) {
                    /* get vendor details */
                    const vendorDetails = await getVendorById({ variables: { id: value } });

                    /* if vendor by Id data loaded successfully, then assign that data as filtered table data */
                    if (
                      vendorDetails &&
                      vendorDetails.data &&
                      vendorDetails.data.getRmSellerById &&
                      vendorDetails.data.getRmSellerById.inwardShipmentItems &&
                      Array.isArray(vendorDetails.data.getRmSellerById.inwardShipmentItems) &&
                      vendorDetails.data.getRmSellerById.inwardShipmentItems.length > 0
                    ) {
                      /* set table filtered data */
                      setTableFilteredData(vendorDetails.data.getRmSellerById.inwardShipmentItems);
                    }

                    /* calling 'GetAllInwardShipmentBySellerIdQuery' to get all inward shipment data for selected seller */
                    await getAllInwardShipmentBySellerId({
                      variables: {
                        rmSellerId: value,
                      },
                    });
                  }
                } catch (error) {
                  const errorObj = error as ApolloError;
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  message.error(errorObj.message);
                  logger(errorObj);
                  /* reset table filtered data */
                  setTableFilteredData([]);
                }
              }}
            />
          </FormItem>
          {selectedVendorId ? (
            <FormItem
              label="Select History :"
              isRequired
              requiredMark="after"
              inputColSpan={18}
              labelColSpan={6}
              customStyle={{ alignItems: 'center' }}
            >
              <RadioGroup
                name="sellerHistory"
                options={[
                  {
                    label: 'Purchase',
                    value: 'purchase',
                  },
                  {
                    label: 'Payment',
                    value: 'payment',
                  },
                ]}
                rhfControllerProps={{ control }}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: 218,
                }}
              />
            </FormItem>
          ) : null}
        </Col>
      </form>

      {selectedVendorId &&
      watch('sellerHistory') === 'purchase' &&
      getVendorByIdData &&
      getVendorByIdData.getRmSellerById ? (
        <>
          <FinancialYearComponent setSelectedDate={setSelectedDate} isDateRangeRequired={false} />
          <TraderOrFarmerDetails />
          <Divider style={{ marginTop: 10 }} />
          <Table<FarmerTraderHistoryTableType>
            dataSource={inwardShipmentDataBySeller || []}
            rowKey="id"
            className="tableStyle farmerTraderHistoryTable"
            bordered
            loading={getVendorByIdDataLoading}
            size="small"
            pagination={{ showSizeChanger: true }}
            onChange={(
              pagination,
              filters,
              sorter,
              extra = {
                currentDataSource: [],
                action: 'paginate',
              },
            ) => {
              /* store table filtered data, so we can use it for further calculations */
              setTableFilteredData(extra.currentDataSource);
            }}
          >
            <Table.Column<FarmerTraderHistoryTableType>
              title="Date"
              dataIndex="date"
              key="date"
              align="center"
              render={(text, record) => {
                if (record.shipment.completedAt) {
                  return dateFormatFunc(record.shipment.completedAt);
                }
                return '-';
              }}
              filterMultiple={false}
              filters={[
                {
                  text: 'Past Day',
                  value: 'day',
                },
                {
                  text: 'Past Week',
                  value: 'week',
                },
                {
                  text: 'Past Month',
                  value: 'month',
                },
                {
                  text: 'Past Year',
                  value: 'year',
                },
              ]}
              onFilter={(value, record) => {
                if (value === 'day' && record.shipment.completedAt) {
                  return dayjs(dayjs(record.shipment.completedAt)).isBetween(
                    dayjs().subtract(1, 'days'),
                    dayjs(),
                  );
                }
                if (value === 'week') {
                  return dayjs(dayjs(record.shipment.completedAt)).isBetween(
                    dayjs().subtract(7, 'days'),
                    dayjs(),
                  );
                }
                if (value === 'month') {
                  return dayjs(dayjs(record.shipment.completedAt)).isBetween(
                    dayjs().subtract(1, 'months'),
                    dayjs(),
                  );
                }
                if (value === 'year') {
                  return dayjs(dayjs(record.shipment.completedAt)).isBetween(
                    dayjs().subtract(1, 'years'),
                    dayjs(),
                  );
                }

                return false;
              }}
            />
            <Table.Column<FarmerTraderHistoryTableType>
              title="GRN"
              dataIndex="grn"
              key="grn"
              align="center"
              render={(text, record) => record.shipment.grn || '-'}
              onFilter={(value, record) => {
                return record.shipment.grn
                  ? record.shipment.grn.toLowerCase().includes(value.toString().toLowerCase())
                  : false;
              }}
              filterDropdown={(filterDropDownProp) => (
                <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
              )}
              filterIcon={(filtered) => {
                return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
              }}
            />

            <Table.Column<FarmerTraderHistoryTableType>
              title="Material type"
              dataIndex="rawMaterial"
              key="rawMaterial"
              align="center"
              render={(text, record) => (record.rawMaterial ? record.rawMaterial.name : '-')}
              filters={getVendorByIdData.getRmSellerById.inwardShipmentItems
                .filter((item) => item.rawMaterial !== null)
                .map((shipment) => shipment.rawMaterial?.name as string)
                .filter((value, index, self) => value && self.indexOf(value) === index)
                .map((item) => ({ text: item, value: item }))}
              onFilter={(value, record) =>
                record.rawMaterial ? record.rawMaterial.name === value : false
              }
            />
            <Table.Column<FarmerTraderHistoryTableType>
              title="Paddy grade"
              dataIndex="grade"
              key="grade"
              align="center"
              render={(text, record) => record.paddyGrade || '-'}
              filterMultiple
              filters={[
                {
                  text: '1 (One)',
                  value: '1 (One)',
                },
                {
                  text: '2 (Two)',
                  value: '2 (Two)',
                },
                {
                  text: '3 (Three)',
                  value: '3 (Three)',
                },
              ]}
              onFilter={(value, record) => record.paddyGrade === value}
              filterIcon={(filtered) => <FilterFilled className={filtered ? 'searchIcon' : ''} />}
            />
            <Table.Column
              title="No. of bags"
              dataIndex="bagsCount"
              key="bagsCount"
              align="center"
              render={(noOfBags: number | undefined) => noOfBags || '-'}
            />
            <Table.Column<FarmerTraderHistoryTableType>
              title="Material Returned"
              dataIndex="materialReturned"
              key="materialReturned"
              align="center"
              width={80}
              render={(materialReturned) => (materialReturned ? 'Yes' : 'No')}
              filters={[
                {
                  text: 'Yes',
                  value: true,
                },
                {
                  text: 'No',
                  value: false,
                },
              ]}
              onFilter={(value, record) => record.materialReturned === value}
              filterIcon={(filtered) => <FilterFilled className={filtered ? 'searchIcon' : ''} />}
            />
            <Table.Column<FarmerTraderHistoryTableType>
              title="Unloading location "
              dataIndex="destination"
              key="destination"
              align="center"
              render={(text, record) => {
                if (record.destination === 'mill') {
                  return <span>Mill</span>;
                } else if (record.destination === 'godown') {
                  return (
                    <span style={{ textTransform: 'capitalize' }}>
                      {(record.godown && record.godown.name) || '-'}
                    </span>
                  );
                }
                return <span>Returned</span>;
              }}
              filters={getVendorByIdData.getRmSellerById.inwardShipmentItems
                .filter((shipment) => shipment.destination !== null)
                .map((item) => {
                  if (item.destination === 'godown' && item.godown) {
                    return { text: item.godown.name, value: item.godown.name };
                  } else {
                    return { text: 'Direct', value: 'mill' };
                  }
                })
                .reduce((item: Array<{ text: string; value: string }>, current) => {
                  const duplicateItem = item.find((val) => val.text === current.text);
                  if (!duplicateItem) {
                    return item.concat([current]);
                  } else {
                    return item;
                  }
                }, [])}
              filterMultiple
              onFilter={(value, record) =>
                record.godown ? record.godown.name === value : record.destination === value
              }
              filterIcon={(filtered) => <FilterFilled className={filtered ? 'searchIcon' : ''} />}
            />
            <Table.Column<FarmerTraderHistoryTableType>
              title="Net material weight (quintals)"
              dataIndex="netWeight"
              key="netWeight"
              align="center"
              width={150}
              render={(text, record) => {
                if (record.bagsCount) {
                  const netWeight = (record.netMaterialWtPerBagKg * record.bagsCount) / 100;
                  return <span>{netWeight.toFixed(2)}</span>;
                }
                return '-';
              }}
            />
            <Table.Column<FarmerTraderHistoryTableType>
              title="Rate (Rs.)"
              dataIndex="pricePerKg"
              key="pricePerKg"
              align="center"
              render={(pricePerKg: number | undefined) => pricePerKg || '-'}
            />
            <Table.Column<FarmerTraderHistoryTableType>
              title="Net amount(Rs.)"
              dataIndex="amount"
              align="center"
              key="amount"
              render={(text, record) => {
                /* calculate net weight of material from empty & full vehicle weight. ( this weight include material & material's bags weight) */
                const weightOfMaterialsWithBags =
                  record.shipment.fullVehicleWtKg - record.shipment.emptyVehicleWtKg;

                /* if shipment has shipment items(material) and number of bags for respective shipment item is given then calculate total/Net amount */
                if (record.bagsCount) {
                  /* this const used to store calculation of weight of material per bag (this weight of material include bag weight) */
                  const materialWithBagWeightPerKg =
                    weightOfMaterialsWithBags / totalNumberOfBagsInShipments;

                  /* remove weight of empty bag from material weight , so that we can get only material weight */
                  const weightOfMaterialPerBagInKg =
                    materialWithBagWeightPerKg - record.emptyBagsWtKg;

                  /* calculate total amount from weight of material per bag kg , price of material per kg & number of bags for that shipment item */
                  let totalAmount =
                    weightOfMaterialPerBagInKg * record.pricePerKg * record.bagsCount;

                  /* if empty bags are returned then remove that bags price from total amount */
                  if (record.emptyBagCost) {
                    totalAmount = totalAmount - record.emptyBagCost * record.bagsCount;
                  }

                  return <span>{isNaN(totalAmount) ? '-' : totalAmount.toFixed(2)}</span>;
                }
                return '-';
              }}
            />
          </Table>
        </>
      ) : null}

      {selectedVendorId && watch('sellerHistory') === 'payment' ? (
        <>
          <FinancialYearComponent setSelectedDate={setSelectedDate} isDateRangeRequired={false} />
          <div className="tabLayout">
            <Table<PaymentSummaryTableType>
              dataSource={inwardShipmentPaymentDetailsBySeller || []}
              className="tableStyle farmerTraderHistoryTable"
              bordered
              size="small"
              rowKey="id"
              pagination={{ showSizeChanger: true }}
              loading={getAllInwardShipmentBySellerIdLoading}
              style={{ marginTop: 50 }}
            >
              <Table.Column<PaymentSummaryTableType>
                title="GRN"
                dataIndex="grn"
                key="grn"
                render={(value, record) => record.grn_v2 || '-'}
                onFilter={(value, record) => {
                  return record.grn_v2
                    ? record.grn_v2.toLowerCase().includes(value.toString().toLowerCase())
                    : false;
                }}
                filterDropdown={(filterDropDownProp) => (
                  <ColumnSearchComponent filterBy="GRN" filterDropDownProp={filterDropDownProp} />
                )}
                filterIcon={(filtered) => {
                  return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
                }}
              />

              <Table.Column<PaymentSummaryTableType>
                title="Date"
                dataIndex="date"
                key="date"
                render={(text, record) => {
                  if (record.createdAt) {
                    return dateFormatFunc(record.createdAt);
                  }
                  return '-';
                }}
                filterMultiple={false}
                filters={[
                  {
                    text: 'Past Day',
                    value: 'day',
                  },
                  {
                    text: 'Past Week',
                    value: 'week',
                  },
                  {
                    text: 'Past Month',
                    value: 'month',
                  },
                  {
                    text: 'Past Year',
                    value: 'year',
                  },
                ]}
                onFilter={(value, record) => {
                  if (value === 'day' && record.createdAt) {
                    return dayjs(dayjs(record.createdAt)).isBetween(
                      dayjs().subtract(1, 'days'),
                      dayjs(),
                    );
                  }
                  if (value === 'week') {
                    return dayjs(dayjs(record.createdAt)).isBetween(
                      dayjs().subtract(7, 'days'),
                      dayjs(),
                    );
                  }
                  if (value === 'month') {
                    return dayjs(dayjs(record.createdAt)).isBetween(
                      dayjs().subtract(1, 'months'),
                      dayjs(),
                    );
                  }
                  if (value === 'year') {
                    return dayjs(dayjs(record.createdAt)).isBetween(
                      dayjs().subtract(1, 'years'),
                      dayjs(),
                    );
                  }

                  return false;
                }}
              />
              <Table.Column<PaymentSummaryTableType>
                title="Total Net Amount (Rs.)"
                dataIndex="totalNetAmount"
                key="totalNetAmount"
                render={(val, record) => {
                  const netTotalAmount = getNetTotalAmount(record);

                  return netTotalAmount.toFixed(2) || '-';
                }}
              />

              <Table.Column<PaymentSummaryTableType>
                title="Paid Amount (Rs.)"
                dataIndex="paidAmount"
                key="paidAmount"
                render={(val, record) => {
                  /** const to store seller payments data */
                  const sellerPaymentData = record.payments || [];

                  /** calling `getTotalPaidAndDeductionAmount` function to get amount paid for each grn */
                  const paymentPaid = getTotalPaidAndDeductionAmount(sellerPaymentData, record.id);

                  return paymentPaid.totalPaidAmount.toFixed(2) || '-';
                }}
              />

              <Table.Column<PaymentSummaryTableType>
                title="Total Deducted amount (Rs.)"
                dataIndex="deductedAmount"
                key="deductedAmount"
                render={(val, record) => {
                  /** const to store seller payments data */
                  const sellerPaymentData = record.payments || [];

                  /** calling `getTotalPaidAndDeductionAmount` function to get amount paid for each grn */
                  const paymentPaid = getTotalPaidAndDeductionAmount(sellerPaymentData, record.id);

                  return paymentPaid.deductedAmount.toFixed(2) || '-';
                }}
              />

              <Table.Column<PaymentSummaryTableType>
                title="Remaining Amount (Rs.)"
                dataIndex="remainingAmount"
                key="remainingAmount"
                render={(val, record) => {
                  /** const to store seller payments data */
                  const sellerPaymentData = record.payments || [];

                  /** calling `getTotalPaidAndDeductionAmount` function to get amount paid for each grn */
                  const paymentPaid = getTotalPaidAndDeductionAmount(sellerPaymentData, record.id);

                  /** calling `getNetTotalAmount` function to get total amount which user has to paid for each grn */
                  const netTotalAmount = getNetTotalAmount(record);

                  return (
                    (
                      netTotalAmount -
                      (paymentPaid.totalPaidAmount + paymentPaid.deductedAmount)
                    ).toFixed(2) || '-'
                  );
                }}
              />

              <Table.Column<PaymentSummaryTableType>
                title="Payment Status"
                dataIndex="paymentStatus"
                key="paymentStatus"
                render={(value, record) => {
                  /** const to store seller payments data */
                  const sellerPaymentData = record.payments || [];

                  /** calling `getTotalPaidAndDeductionAmount` function to get amount paid for each grn */
                  const paymentPaid = getTotalPaidAndDeductionAmount(sellerPaymentData, record.id);

                  if (record.isPaymentDone) {
                    return 'Completed';
                  } else if (paymentPaid.totalPaidAmount + paymentPaid.deductedAmount === 0) {
                    return 'Payment Not Initiated';
                  } else {
                    return 'Partially Completed';
                  }
                }}
              />
            </Table>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default FarmerOrTraderHistoryScreen;
