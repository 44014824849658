import React, { useState } from 'react';
import { Button, Descriptions, Divider, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ApolloError, useApolloClient, useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GetInwardShipmentByIdQuery,
  GetInwardShipmentByIdQueryVariables,
  InwardShipments,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
} from '../../../../graphql/graphql-types';
import {
  InwardShipmentsReportWithExtraFieldType,
  UploadedFileKeyAndUrlType,
} from '../../../../utils/types';
import { dateFormatFunc } from '../../../../utils/globals';
import { logger } from '../../../../utils/helpers';
import InwardShipmentSummaryTable from './InwardShipmentSummaryTable';
import LightBoxComponent from '../../../../components/LightboxComponent';

/* loading get Inward Shipment By Id Query */
const getInwardShipmentByIdQuery = loader(
  '../../../../graphql/queries/getInwardShipmentByIdQuery.graphql',
);

/* loading get files url query */
const getFilesUrlQuery = loader('../../../../graphql/queries/getFilesURLQuery.graphql');

/* inward shipment summary screen prop type */
type InwardShipmentSummaryPropType = {
  /* This prop use to store inwardShipment id whose grn is clicked so that we can pass that id as query variable to fetch inward Shipment data and this is optional prop */
  summaryId?: number | null;
};

/* React functional component */
const InwardShipmentSummaryScreen = ({ summaryId }: InwardShipmentSummaryPropType): JSX.Element => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* destructing id from useParams()  */
  const { id } = useParams();

  /* extracting apollo Client from use use apollo client*/
  const apolloClient = useApolloClient();

  /* state used to store object of vehicle image which contain image key and its respective url */
  const [imageUrlObjectArray, setImageUrlObjectArray] = useState<Array<UploadedFileKeyAndUrlType>>(
    [],
  );

  /* state used to set the visibility of lightBox for image display */
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

  /*  get inward shipment by id data query */
  const {
    data: getInwardShipmentByIdData,
    loading: getInwardShipmentByIdLoading,
    error: getInwardShipmentByIdError,
  } = useQuery<GetInwardShipmentByIdQuery, GetInwardShipmentByIdQueryVariables>(
    getInwardShipmentByIdQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id: id ? Number(id) : Number(summaryId) },
      onCompleted: (res) => {
        /* destructing fullVehicleImage, emptyVehicleImage from data */
        const { fullVehicleImage, emptyVehicleImage } =
          res.getInwardShipmentById as InwardShipments;

        /* const array used to store vehicle images keys */
        const vehicleImageKeyArray: string[] = [];

        /* if full vehicle image present then push that image key into vehicle image array */
        if (fullVehicleImage) {
          vehicleImageKeyArray.push(fullVehicleImage);
        }

        /* if empty vehicle image present then push that image key into vehicle image array */
        if (emptyVehicleImage) {
          vehicleImageKeyArray.push(emptyVehicleImage);
        }

        /* if vehicle images added for the respective shipment, then get that image urls using image key  */
        if (vehicleImageKeyArray)
          /* get files url query used to get urls & key of the images */
          apolloClient
            .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
              query: getFilesUrlQuery,
              variables: {
                keys: vehicleImageKeyArray,
              },
            })
            .then((imageUrlsData) => {
              /* destructing images file url and key */
              const { getFilesUrl } = imageUrlsData.data;

              /* const array used to store key and url of image */
              const fileUrlAndKeyArray: Array<UploadedFileKeyAndUrlType> = [];

              /* if url and key present for image then push it into file url array for further operation */
              if (getFilesUrl) {
                getFilesUrl.forEach((item) => {
                  if (item) {
                    /* create key and url object from resultant data to simplify further data handling */
                    fileUrlAndKeyArray.push({
                      key: item.key,
                      url: item.url,
                    });
                  }
                });
              }
              /* update url and key of images array into state , so that we can use it to display image */
              setImageUrlObjectArray(fileUrlAndKeyArray);
            })
            .catch((getFilesUrlError: ApolloError) => {
              logger(getFilesUrlError);
            });
      },
    },
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getInwardShipmentByIdError) {
    return <div className="errorText">{getInwardShipmentByIdError.message}</div>;
  }

  /* destructuring inward shipment data */
  if (getInwardShipmentByIdData && !getInwardShipmentByIdLoading) {
    const {
      grn,
      status,
      createdAt,
      completedAt,
      vehicleNumber,
      driverName,
      items,
      fullVehicleWtKg,
      emptyVehicleWtKg,
      laborChargeType,
      isPaymentDone,
      paymentComments,
      weighbridgeCharges,
      laborChargePerBag,
      billing_details: billingDetails,
    } = getInwardShipmentByIdData.getInwardShipmentById as InwardShipmentsReportWithExtraFieldType;

    /* const used to store full vehicle weight in kg */
    const fullVehicleWeightInKg = fullVehicleWtKg ? fullVehicleWtKg : 0;

    /* const used to store empty vehicle weight in kg */
    const emptyVehicleWeightInKg = emptyVehicleWtKg ? emptyVehicleWtKg : 0;

    /* stored total amount */
    let totalAmount = 0;
    if (weighbridgeCharges) {
      totalAmount -= weighbridgeCharges;
    }

    items.forEach((item) => {
      if (item.id) {
        /* destructing inward shipment item data */
        const { bagsCount, netMaterialWtPerBagKg, emptyBagCost, pricePerKg, brokeragePerQuintal } =
          item;

        /* const used to store number of bags in shipment */
        const numberOfBags = bagsCount ? bagsCount : 0;

        if (!item.materialReturned) {
          /* const used to store total net material weight */
          const totalNetMaterialWeight =
            numberOfBags * (netMaterialWtPerBagKg ? netMaterialWtPerBagKg : 0);

          /* const used to store total new material weight in quintal  */
          const totalNetMaterialWeightInQt = parseFloat((totalNetMaterialWeight / 100).toFixed(2));

          /* const used to store price of total weight */
          const priceOfTotalWt = totalNetMaterialWeightInQt * ((pricePerKg ? pricePerKg : 0) * 100);

          /* const used to store total price of empty bags weight */
          const priceOfTotalEmptyBagWt = (emptyBagCost ? emptyBagCost : 0) * numberOfBags;
          /* const used to store price of brokerage */
          const priceOfBrokerage =
            (brokeragePerQuintal ? brokeragePerQuintal : 0) * totalNetMaterialWeightInQt;

          /* const used to store sub total */
          const subtotal = parseFloat(
            (priceOfTotalWt + priceOfBrokerage - priceOfTotalEmptyBagWt).toFixed(2),
          );

          /* const used to store total amount */
          totalAmount += subtotal;

          if (laborChargeType && laborChargePerBag !== null && laborChargePerBag !== undefined) {
            if (laborChargeType === 'add') {
              /* total amount for labour charge type 'add' */
              totalAmount += numberOfBags * laborChargePerBag;
            } else {
              /* total amount for labour charge type 'deduct' */
              totalAmount -= numberOfBags * laborChargePerBag;
            }
          }
        }
      }
    });

    return (
      <>
        <div className={id ? 'tabLayout' : 'modalLayout'}>
          <div style={{ display: 'flex' }}>
            {id ? (
              <>
                <div
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowLeftOutlined className="goBackButton" />
                </div>
                <h2>Inward Shipment Summary</h2>
                <Button
                  className="hideInPrint"
                  style={{ marginLeft: 'auto' }}
                  type="primary"
                  onClick={() => {
                    navigate(`print`);
                  }}
                >
                  Print
                </Button>
              </>
            ) : null}
          </div>
          <>
            <Descriptions
              colon={false}
              column={1}
              labelStyle={{ width: 260 }}
              style={{ marginTop: 15 }}
              contentStyle={{ textTransform: 'capitalize' }}
            >
              <Descriptions.Item label="GRN">{grn || '-'}</Descriptions.Item>
              <Descriptions.Item label="Status">{status || '-'}</Descriptions.Item>
              <Descriptions.Item label="Start Date">
                {createdAt ? dateFormatFunc(createdAt as Date) : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Completed On">
                {completedAt ? dateFormatFunc(completedAt as Date) : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Vehicle Number">{vehicleNumber || '-'}</Descriptions.Item>
              <Descriptions.Item label="Driver Name">{driverName || '-'}</Descriptions.Item>
              <Descriptions.Item label="Gross Weight (in kgs)">
                {fullVehicleWtKg ? fullVehicleWtKg.toFixed(2) : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Tare Weight (in kgs)">
                {emptyVehicleWtKg || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Net Weight (in kgs)">
                {(fullVehicleWeightInKg - emptyVehicleWeightInKg).toFixed(2)}
              </Descriptions.Item>
              <Descriptions.Item label="Weighbridge charges (in Rs.)">
                {weighbridgeCharges || '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Labor Charges (Rs. per bag)">
                {laborChargeType === 'add' ? '+ ' : '- '}
                {laborChargePerBag}
              </Descriptions.Item>

              {billingDetails ? (
                <>
                  <Descriptions.Item label="Bill number">
                    {billingDetails.bill_number || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Net weight (in quintals)">
                    {billingDetails.net_weight || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Weight difference (in quintals)">
                    {(
                      (fullVehicleWeightInKg - emptyVehicleWeightInKg) / 100 -
                      (billingDetails.net_weight || 0)
                    ).toFixed(2) || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Broker">
                    {billingDetails.broker_name || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Additional information">
                    {billingDetails.additional_information || '-'}
                  </Descriptions.Item>
                </>
              ) : null}

              <Descriptions.Item label="Net Total Amount (in Rs.)">
                <b>{totalAmount.toFixed(2)}</b>
              </Descriptions.Item>
              <Descriptions.Item label="Payment Done?">
                {isPaymentDone ? 'Yes' : 'No'}
              </Descriptions.Item>
              <Descriptions.Item label="Payment Details">
                {paymentComments || '-'}
              </Descriptions.Item>
              <Descriptions.Item>
                <Divider style={{ marginTop: '1px', marginBottom: '1px' }} />
              </Descriptions.Item>
              <Descriptions.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  onClick={() => {
                    setIsImageOpen(true);
                  }}
                  disabled={imageUrlObjectArray.length === 0}
                >
                  View Vehicle photos
                </Button>
                {isImageOpen && (
                  <LightBoxComponent
                    imageUrlObjectArray={imageUrlObjectArray}
                    setIsImageOpen={setIsImageOpen}
                    screenType="others"
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </>
          <InwardShipmentSummaryTable tableData={items} />
        </div>
      </>
    );
  }
  return <Spin className="loadingSpinner" />;
};

export default InwardShipmentSummaryScreen;
