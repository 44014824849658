import React, { useState } from 'react';
import {
  Button,
  Col,
  Descriptions,
  DescriptionsProps,
  Divider,
  message,
  Popconfirm,
  Row,
  Spin,
  Table,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApolloError, Reference, useMutation, useQuery } from '@apollo/client';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { loader } from 'graphql.macro';
import {
  DeleteInwardShipmentItemMutation,
  DeleteInwardShipmentItemMutationVariables,
  GetInwardShipmentByIdModifyGrnQuery,
  GetInwardShipmentByIdModifyGrnQueryVariables,
  UpdateInwardShipmentAdminMutation,
  UpdateInwardShipmentAdminMutationVariables,
} from '../../graphql/graphql-types';
import { logger } from '../../utils/helpers';
import { dateFormatFunc } from '../../utils/globals';
import {
  AddOrEditGRNModalPropType,
  InwardShipmentsReportType,
  ItemsShipment,
} from '../../utils/types';
import FormItem from '../../components/FormItem';
import InputNumber from '../../components/InputNumber';
import { useForm } from 'react-hook-form';
import Input from '../../components/Input';
import AddOrEditGrnModal from './AddOrEditGrnModal';

/* loading get admin open inward shipment query */
const getAdminOpenInwardShipmentsQuery = loader(
  '../../graphql/queries/getAdminOpenInwardShipmentsQuery.graphql',
);

/* loading get inward shipment by Id  modify grn query */
const getInwardShipmentByIdModifyGrnQuery = loader(
  '../../graphql/queries/getInwardShipmentByIdModifyGrnQuery.graphql',
);

/* loading update inward shipment admin mutation */
const updateInwardShipmentAdminMutation = loader(
  '../../graphql/mutations/updateInwardShipmentAdminMutation.graphql',
);

/* loading delete inward shipment item mutation  */
const deleteInwardShipmentItemMutation = loader(
  '../../graphql/mutations/deleteInwardShipmentItemMutation.graphql',
);

/* modify grn form field type  */
type ModifyGRNFormFieldType = {
  /* empty vehicle weight kg */
  emptyVehicleWtKg: number | null;
  /* vehicle number */
  vehicleNumber: string | null;
  /* driver name */
  driverName: string | null;
  /* full vehicle weight kg */
  fullVehicleWtKg: number | null;
};

// process grn screen prop type
type ModifyGRNScreenPropType = {
  // this prop used to get clicked inward shipment id from inwardShipment reports screen,
  inwardShipmentId?: number;
  // this prop is use to close the modal by setting modal visibility state its optional prop and passed only when screen called from report section
  closeModal?: () => void;
};

/* styling for Description component*/
const descriptionComponentStyleProps: DescriptionsProps = {
  /* define number of column in row */
  column: 1,
  /* set description components item colon false */
  colon: false,
  /* styling of description component labels */
  labelStyle: { width: '305px' },
};

/* validations required for useForm */
const schema = yup.object({
  emptyVehicleWtKg: yup
    .number()
    .nullable()
    .required('Please enter empty vehicle weight and try again'),
  vehicleNumber: yup
    .string()
    .nullable()
    .required('Please enter vehicle number and try again')
    .matches(validationRegex.vehicleRegistrationNumber, {
      message: 'Please enter a valid vehicle number',
      excludeEmptyString: true,
    }),
  driverName: yup.string().nullable().required('Please enter driver name and try again'),
  fullVehicleWtKg: yup.number().nullable().required('Please enter gross weight and try again'),
});

/* common input component style */
export const inputComponentCommonStyle = {
  /* border radius of input component */
  borderRadius: 5,
  /* border to input component */
  border: '1px solid black',
  /* width of input component */
  width: '70%',
};

/* React functional component */
const ModifyGRNScreen = ({ inwardShipmentId, closeModal }: ModifyGRNScreenPropType) => {
  /* extracting param from useParam hook */
  const param = useParams();

  /* variable for stored shipment id based on screen from which its called if called from reports section then inwardShipmentId else will extract id from param*/
  const shipmentId = inwardShipmentId ? inwardShipmentId : Number(param.id);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  const location = useLocation();

  const isPathIncludesEdit = location.pathname.includes('inward/edit');

  /* this state used to store grn data, which is passed to addOrEditGrnModal */
  const [grnDataAndIsFormVisible, setGrnDataAndIsFormVisible] =
    useState<AddOrEditGRNModalPropType>(null);

  /* this state used to show loading indicator on update inward shipment button */
  const [isUpdateBtnLoading, setIsUpdateBtnLoading] = useState<boolean>(false);

  /* state used to store id of the shipment or item whose delete button is clicked  */
  const [shipmentOrItemIdToDelete, setShipmentOrItemIdToDelete] = useState<number | null>(null);

  /* get inward shipment data for modify grn by id */
  const {
    data: getInwardShipmentByIdModifyGrnData,
    loading: getInwardShipmentByIdModifyGrnLoading,
    error: getInwardShipmentByIdModifyGrnError,
  } = useQuery<GetInwardShipmentByIdModifyGrnQuery, GetInwardShipmentByIdModifyGrnQueryVariables>(
    getInwardShipmentByIdModifyGrnQuery,
    {
      variables: { id: shipmentId },
    },
  );

  /* update inward shipment admin mutation */
  const [updateInwardShipmentAdmin] = useMutation<
    UpdateInwardShipmentAdminMutation,
    UpdateInwardShipmentAdminMutationVariables
  >(updateInwardShipmentAdminMutation);

  /* delete inward shipment item mutation */
  const [deleteInwardShipmentItem] = useMutation<
    DeleteInwardShipmentItemMutation,
    DeleteInwardShipmentItemMutationVariables
  >(deleteInwardShipmentItemMutation);

  /* variable for storing query data */
  let data;
  if (getInwardShipmentByIdModifyGrnData) {
    data = getInwardShipmentByIdModifyGrnData.getInwardShipmentByIdModifyGrn;
  }

  /* function used to close the visibility of modal by setting state value to null. */
  const closeAddOrEditRawGRNForm = () => {
    setGrnDataAndIsFormVisible(null);
  };

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<ModifyGRNFormFieldType>({
    defaultValues: {
      emptyVehicleWtKg: null,
      fullVehicleWtKg: null,
      driverName: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* useEffect for set default values for update inward shipment */
  React.useEffect(() => {
    if (getInwardShipmentByIdModifyGrnData) {
      const inwardShipmentData =
        getInwardShipmentByIdModifyGrnData.getInwardShipmentByIdModifyGrn as InwardShipmentsReportType;
      if (inwardShipmentData) {
        reset({
          emptyVehicleWtKg: inwardShipmentData.emptyVehicleWtKg
            ? inwardShipmentData.emptyVehicleWtKg
            : null,
          fullVehicleWtKg: inwardShipmentData.fullVehicleWtKg
            ? inwardShipmentData.fullVehicleWtKg
            : null,
          driverName: inwardShipmentData.driverName,
          vehicleNumber: inwardShipmentData.vehicleNumber,
        });
      }
    }
  }, [getInwardShipmentByIdModifyGrnData, reset]);

  /* onSubmit Function  */
  const onsubmit = (updatedData: ModifyGRNFormFieldType) => {
    setIsUpdateBtnLoading(true);
    /* update inward shipment admin mutation */
    updateInwardShipmentAdmin({
      variables: {
        id: shipmentId,
        emptyVehicleWtKg: updatedData.emptyVehicleWtKg,
        fullVehicleWtKg: updatedData.fullVehicleWtKg,
        vehicleNumber: updatedData.vehicleNumber as string,
        driverName: updatedData.driverName as string,
      },
      refetchQueries: [{ query: getAdminOpenInwardShipmentsQuery }],
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Inward Shipment has been successfully updated.');
        setIsUpdateBtnLoading(false);
        if (closeModal) {
          closeModal();
        }
      })
      .catch((error: ApolloError) => {
        logger(error);
        setIsUpdateBtnLoading(false);
      });
  };

  /* function to handle delete inward shipment item */
  const deleteInwardShipmentItemFunc = (itemId: number) => {
    /* set id of the item whose delete button and used to show loading indicator on delete button while deleting a item */
    setShipmentOrItemIdToDelete(itemId);
    /* delete inward shipment item */
    deleteInwardShipmentItem({
      variables: {
        id: itemId,
      },
      /* after deleting a  inward shipment item, the update function is used to update the cache and display an updated  inward shipment items array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a inward shipment item that was just removed. */
        const deletedDataId = deleteData?.deleteInwardShipmentItem?.id;
        if (
          getInwardShipmentByIdModifyGrnData &&
          getInwardShipmentByIdModifyGrnData.getInwardShipmentByIdModifyGrn
        ) {
          cache.modify({
            /* used cache identify to get the cache of the inward shipment items array from which to remove . */
            id: cache.identify(getInwardShipmentByIdModifyGrnData.getInwardShipmentByIdModifyGrn),
            fields: {
              items(existingInwardShipmentItems: Array<Reference>, { readField }) {
                /* return data after deleted item using deletedDataId  */
                return existingInwardShipmentItems.filter(
                  (item) => deletedDataId !== readField('id', item),
                );
              },
            },
          });
        }
      },
    })
      .then(() => {
        setShipmentOrItemIdToDelete(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Inward Shipment item has been successfully deleted.');
      })
      .catch((error: ApolloError) => {
        setShipmentOrItemIdToDelete(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  /* show loading indicator on the screen until data get fetch from the server */
  if (getInwardShipmentByIdModifyGrnLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getInwardShipmentByIdModifyGrnError) {
    return <div className="errorText">{getInwardShipmentByIdModifyGrnError.message}</div>;
  }

  return (
    <>
      <Row>
        <Col span={21}>
          <Descriptions {...descriptionComponentStyleProps}>
            <Descriptions.Item label="GRN">{data && data.grn}</Descriptions.Item>
            <Descriptions.Item label="Date">
              {data && dateFormatFunc(data.createdAt as string)}
            </Descriptions.Item>
          </Descriptions>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={handleSubmit(onsubmit)}>
            <FormItem
              labelColSpan={6}
              inputColSpan={8}
              requiredMark="after"
              isRequired
              label="Empty vehicle weight (in kgs)"
              customStyle={{ paddingTop: 0 }}
              errorText={
                errors && errors.emptyVehicleWtKg ? errors.emptyVehicleWtKg.message : undefined
              }
            >
              <InputNumber
                name="emptyVehicleWtKg"
                placeholder="Please enter empty vehicle weight"
                rhfControllerProps={{
                  control,
                }}
                customStyles={inputComponentCommonStyle}
                inputNumberProps={{ min: 0 }}
              />
            </FormItem>
            <FormItem
              label="Vehicle number"
              labelColSpan={6}
              inputColSpan={8}
              isRequired
              requiredMark="after"
              customStyle={{ paddingTop: '16px' }}
              errorText={errors && errors.vehicleNumber ? errors.vehicleNumber.message : undefined}
            >
              <Input
                customStyles={inputComponentCommonStyle}
                name="vehicleNumber"
                placeholder="Please enter vehicle number"
                rhfControllerProps={{
                  control,
                }}
                onChange={(rhfOnChange, value) => {
                  rhfOnChange(value.toUpperCase());
                }}
              />
            </FormItem>
            <FormItem
              label="Driver Name"
              labelColSpan={6}
              inputColSpan={8}
              isRequired
              requiredMark="after"
              customStyle={{ paddingTop: '16px' }}
              errorText={errors && errors.driverName ? errors.driverName.message : undefined}
            >
              <Input
                customStyles={inputComponentCommonStyle}
                name="driverName"
                placeholder="Please enter driver name"
                rhfControllerProps={{
                  control,
                }}
              />
            </FormItem>
            <FormItem
              labelColSpan={6}
              inputColSpan={8}
              isRequired
              requiredMark="after"
              label="Gross weight (in kgs)"
              customStyle={{ paddingTop: '16px' }}
              errorText={
                errors && errors.fullVehicleWtKg ? errors.fullVehicleWtKg.message : undefined
              }
            >
              <InputNumber
                name="fullVehicleWtKg"
                placeholder="Please enter gross weight"
                rhfControllerProps={{
                  control,
                }}
                customStyles={inputComponentCommonStyle}
                inputNumberProps={{ min: 0 }}
              />
            </FormItem>
            <FormItem labelColSpan={6} inputColSpan={7} customStyle={{ paddingBottom: 10 }}>
              <div style={{ display: 'flex', columnGap: '12px' }}>
                <Button htmlType="submit" type="primary" loading={isUpdateBtnLoading}>
                  Update
                </Button>
                <Button
                  type="default"
                  onClick={() => {
                    /* reset form */
                    reset();
                    if (isPathIncludesEdit) {
                      navigate('/shipment/admin/inward/create');
                    } else if (closeModal) {
                      closeModal();
                    }
                  }}
                >
                  Cancel
                </Button>
              </div>
            </FormItem>
          </form>
        </Col>
        {inwardShipmentId ? null : (
          <Col span={3}>
            <div style={{ marginLeft: '35px', marginTop: '20px' }}>
              <Button
                type="primary"
                style={{ marginLeft: 20 }}
                onClick={() => {
                  navigate(`/shipment/admin/inward/process/${shipmentId}`);
                }}
              >
                Process GRN
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <Divider style={{ marginTop: '2px', marginBottom: '9px' }} />
      <span>
        <i>
          All changes done below are automatically saved to the current GRN without clicking
          'Update' button
        </i>
      </span>
      <br />
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        style={{
          marginTop: '7px',
          paddingTop: '4px',
          paddingBottom: '5px',
          paddingLeft: '5px',
        }}
        onClick={() => {
          setGrnDataAndIsFormVisible({
            mode: 'add',
          });
        }}
      >
        Add Item
      </Button>
      <h4 style={{ marginTop: '20px' }}>Items</h4>
      <Table<ItemsShipment>
        dataSource={data ? data.items : []}
        style={{
          marginTop: '8px',
          marginBottom: '20px',
          width: '100%',
        }}
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<ItemsShipment>
          title="Farmer/Trader (Purchase) Name"
          dataIndex="sellerName"
          key="sellerName"
          align="center"
          render={(text, record) => {
            if (record.seller !== null) {
              return record.seller && record.seller.name;
            }
            return '';
          }}
        />
        <Table.Column<ItemsShipment>
          title="Material Type"
          dataIndex="rawMaterial"
          key="rawMaterial"
          align="center"
          render={(text, record) => {
            if (record.rawMaterial !== null) {
              return record.rawMaterial && record.rawMaterial.name;
            }
            return '';
          }}
        />
        <Table.Column<ItemsShipment>
          title="Paddy Grade"
          dataIndex="paddyGrade"
          key="paddyGrade"
          align="center"
          render={(text, record) => {
            if (record.paddyGrade !== null) {
              return record.paddyGrade;
            }
            return '';
          }}
        />
        <Table.Column<ItemsShipment>
          title="Unloading Location"
          dataIndex="destination"
          key="destination"
          align="center"
          render={(text, record) => {
            if (record.destination && record.destination !== null) {
              if (record.destination === 'godown') {
                return record.godown && record.godown.name;
              }
              return 'Mill';
            }
            return 'NA';
          }}
        />
        <Table.Column<ItemsShipment>
          title="No. Of Bags"
          dataIndex="bagsCount"
          key="bagsCount"
          align="center"
          render={(text, record) => {
            if (record.bagsCount !== null) {
              return record.bagsCount;
            }
            return '';
          }}
        />
        <Table.Column<ItemsShipment>
          title="Paddy Returned"
          align="center"
          dataIndex="materialReturned"
          key="materialReturned"
          render={(text, record) => {
            if (record.materialReturned === true) {
              return 'Yes';
            }
            return 'No';
          }}
        />
        <Table.Column<ItemsShipment>
          title="Actions"
          dataIndex="actions"
          key="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    setGrnDataAndIsFormVisible({
                      mode: 'edit',
                      dataToEdit: record,
                    });
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Are you sure you want to delete this Inward Shipment Item?"
                  okText="Yes"
                  onConfirm={() => {
                    deleteInwardShipmentItemFunc(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                >
                  <Button className="deleteButton" loading={shipmentOrItemIdToDelete === record.id}>
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>

      {grnDataAndIsFormVisible &&
      getInwardShipmentByIdModifyGrnData &&
      getInwardShipmentByIdModifyGrnData.getInwardShipmentByIdModifyGrn ? (
        <AddOrEditGrnModal
          data={{
            godownData: getInwardShipmentByIdModifyGrnData.getAllGodowns,
            rawMaterialsData: getInwardShipmentByIdModifyGrnData.getAllRawMaterials,
            RmSellersData: getInwardShipmentByIdModifyGrnData.getAllRmSellers,
            processId: shipmentId,
          }}
          rawDataToEdit={grnDataAndIsFormVisible.dataToEdit as ItemsShipment}
          mode={grnDataAndIsFormVisible.mode}
          closeModal={closeAddOrEditRawGRNForm}
          inwardShipmentData={getInwardShipmentByIdModifyGrnData.getInwardShipmentByIdModifyGrn}
        />
      ) : null}
    </>
  );
};

export default ModifyGRNScreen;
