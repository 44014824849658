import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, ApolloError, useMutation, Reference } from '@apollo/client';
import { Table, Button, Popconfirm, message } from 'antd';
import { PlusOutlined, WarningFilled, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { logger } from '../../../utils/helpers';
import {
  GetAllTransportersQuery,
  GetAllTransportersQueryVariables,
  DeleteTransporterMutation,
  DeleteTransporterMutationVariables,
  Transporters,
} from '../../../graphql/graphql-types';
import { AddressType } from '../../../utils/types';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';
import colors from '../../../scss/variables.module.scss';

/* transporter data type */
type TransportersDataType = Pick<
  Transporters,
  | 'id'
  | 'name'
  | 'gstin'
  | 'profileCompleted'
  | 'primaryContactName'
  | 'primaryContactEmail'
  | 'primaryContactMobile'
> & {
  /* address of transporter */
  address?: AddressType | null;
};

/* loading get all transporter query with the help of loader */
const getAllTransporterQuery = loader('../../../graphql/queries/getAllTransportersQuery.graphql');

/* loading delete transporter mutation with the help of loader */
const deleteTransporterMutation = loader(
  '../../../graphql/mutations/deleteTransporterMutation.graphql',
);

/* React functional component */
const ViewAllTransportersScreen = () => {
  /* useState to store selected transporters id when user hits delete button which will then use for loading indication */
  const [deleteTransporterId, setDeleteTransporterId] = useState<number | null>(null);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* get all transporter query */
  const { data, error, loading } = useQuery<
    GetAllTransportersQuery,
    GetAllTransportersQueryVariables
  >(getAllTransporterQuery);

  /* delete user mutation */
  const [deleteTransporter] = useMutation<
    DeleteTransporterMutation,
    DeleteTransporterMutationVariables
  >(deleteTransporterMutation);

  if (error) {
    return <div className="errorText">{error.message}</div>;
  }

  /* function to handle delete transporter */
  const handleDeleteTransporter = (transporterId: number) => {
    deleteTransporter({
      variables: {
        id: transporterId,
      },
      update(cache, { data: deleteData }) {
        /* to get the id of the data to be deleted */
        const idToRemove = deleteData?.deleteTransporter?.id;
        cache.modify({
          fields: {
            transporters(existingTransporters: Array<Reference>, { readField }) {
              if (idToRemove) {
                return existingTransporters.filter(
                  (transportersData) => idToRemove !== readField('id', transportersData),
                );
              }
              return existingTransporters;
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteTransporterId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Transporter has been successfully deleted.');
      })
      .catch((deleteTransporterError: ApolloError) => {
        let errorMsg = deleteTransporterError.message;
        setDeleteTransporterId(null);
        if (deleteTransporterError.message.includes('Foreign key violation.')) {
          errorMsg = 'Cannot delete since transporter is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteTransporterError);
      });
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          navigate('create');
        }}
      >
        Create transporter
      </Button>
      <Table<TransportersDataType>
        dataSource={data ? data.getAllTransporters : []}
        loading={loading}
        className="tableStyle"
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<TransportersDataType>
          key="name"
          title="Transporter Name"
          dataIndex="name"
          align="center"
          render={(transportersName) => {
            return <span style={{ textTransform: 'capitalize' }}>{transportersName || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.name
              ? record.name.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="transporter name"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<TransportersDataType>
          key="primaryContactName"
          title="Owner Name"
          dataIndex="primaryContactName"
          align="center"
          render={(ownersName) => {
            return <span style={{ textTransform: 'capitalize' }}>{ownersName || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.primaryContactName
              ? record.primaryContactName
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="owner name" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<TransportersDataType>
          key="address"
          title="Address"
          dataIndex="address"
          width="30%"
          align="center"
          render={(text, record) => {
            if (record.address) {
              const address = record.address;
              return (
                <span>
                  {address.address},{address.city}
                  <br />
                  {address.state} - {address.pincode}
                </span>
              );
            }
            return '-';
          }}
        />
        <Table.Column<TransportersDataType>
          key="primaryContactMobile"
          title="Mobile"
          dataIndex="primaryContactMobile"
          align="center"
          render={(mobileNumber: string) => mobileNumber || '-'}
          onFilter={(value, record) => {
            return record.primaryContactMobile
              ? record.primaryContactMobile
                  .toString()
                  .toLowerCase()
                  .includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="mobile number"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<TransportersDataType>
          key="gstin"
          title="GST"
          dataIndex="gstin"
          align="center"
          render={(gstin: string) => gstin || '-'}
          onFilter={(value, record) => {
            return record.gstin
              ? record.gstin.toString().toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="GST" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<TransportersDataType>
          key="Actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    navigate(`${record.id}/edit`);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete Transporter. Are you sure?"
                  okText="Yes"
                  onConfirm={() => {
                    handleDeleteTransporter(record.id);
                    setDeleteTransporterId(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                >
                  <Button className="deleteButton" loading={record.id === deleteTransporterId}>
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};
export default ViewAllTransportersScreen;
