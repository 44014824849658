import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery, ApolloError, useMutation, Reference } from '@apollo/client';
import { Table, Button, Popconfirm, message } from 'antd';
import { PlusOutlined, SearchOutlined, WarningFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { addressFormatFunction, logger } from '../../../utils/helpers';
import {
  GetAllBrokersQuery,
  GetAllBrokersQueryVariables,
  DeleteBrokerByIdMutation,
  DeleteBrokerByIdMutationVariables,
  Brokers,
} from '../../../graphql/graphql-types';
import { AddressType } from '../../../utils/types';
import colors from '../../../scss/variables.module.scss';
import ColumnSearchComponent from '../../../components/ColumnSearchComponent';

/* broker data type */
type BrokerDataType = Pick<Brokers, 'id' | 'mobile' | 'name' | 'email' | 'gst'> & {
  address?: AddressType;
};

/* loading get all brokers query with the help of loader */
const getAllBrokersQuery = loader('../../../graphql/queries/getAllBrokersQuery.graphql');

/* loading delete broker by id mutation with the help of loader */
const deleteBrokerByIdMutation = loader(
  '../../../graphql/mutations/deleteBrokerByIdMutation.graphql',
);

/* React functional component */
const ViewAllBrokersScreen = () => {
  /* useState to store selected brokers id when user hits delete button which will then use for loading indication */
  const [deleteBrokerId, setDeleteBrokerId] = useState<string | null>(null);

  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /* get all brokers query */
  const { data, error, loading } = useQuery<GetAllBrokersQuery, GetAllBrokersQueryVariables>(
    getAllBrokersQuery,
  );

  /* delete broker mutation */
  const [deleteTransporter] = useMutation<
    DeleteBrokerByIdMutation,
    DeleteBrokerByIdMutationVariables
  >(deleteBrokerByIdMutation);

  if (error) {
    return <div className="errorText">{error.message}</div>;
  }

  /* function to handle delete broker */
  const handleDeleteBroker = (brokerId: string) => {
    deleteTransporter({
      variables: {
        id: brokerId,
      },
      update(cache, { data: deleteData }) {
        /* to get the id of the data to be deleted */
        const idToRemove = deleteData?.delete_brokers_by_pk?.id;
        cache.modify({
          fields: {
            brokers(existingBrokers: Array<Reference>, { readField }) {
              if (idToRemove) {
                return existingBrokers.filter(
                  (brokersData) => idToRemove !== readField('id', brokersData),
                );
              }
              return existingBrokers;
            },
          },
        });
      },
    })
      .then(() => {
        setDeleteBrokerId(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Broker has been successfully deleted.');
      })
      .catch((deleteBrokerError: ApolloError) => {
        let errorMsg = deleteBrokerError.message;
        setDeleteBrokerId(null);
        if (deleteBrokerError.message.includes('Foreign key violation.')) {
          errorMsg = 'Cannot delete since broker is linked with shipment(s).';
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(errorMsg);
        logger(deleteBrokerError);
      });
  };

  return (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined style={{ fontSize: 15 }} />}
        className="createAndNavigateButton"
        onClick={() => {
          navigate('create');
        }}
      >
        Create broker
      </Button>
      <Table<BrokerDataType>
        dataSource={data ? data.brokers : []}
        loading={loading}
        className="tableStyle"
        bordered
        size="small"
        pagination={{ showSizeChanger: true }}
      >
        <Table.Column<BrokerDataType>
          key="name"
          title="Broker Name"
          dataIndex="name"
          align="center"
          render={(brokersName) => {
            return <span style={{ textTransform: 'capitalize' }}>{brokersName || '-'}</span>;
          }}
          onFilter={(value, record) => {
            return record.name
              ? record.name.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="broker name" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<BrokerDataType>
          key="address"
          title="Address"
          dataIndex="address"
          width="30%"
          align="center"
          render={(text, record) => {
            if (record.address) {
              return (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  {addressFormatFunction(record.address, 'doubleLine')}
                </div>
              );
            }
          }}
          onFilter={(value, record) => {
            if (record.address) {
              const address = record.address;
              const addressData = `${address.address} ${address.city} ${
                address.state ? address.state : ''
              } ${address.pincode}`;
              return addressData
                ? addressData.toString().toLowerCase().includes(value.toString().toLowerCase())
                : false;
            }
            return false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent filterBy="address" filterDropDownProp={filterDropDownProp} />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column<BrokerDataType>
          key="mobile"
          title="Mobile"
          dataIndex="mobile"
          align="center"
          render={(mobileNumber: string) => mobileNumber || '-'}
          onFilter={(value, record) => {
            return record.mobile
              ? record.mobile.toLowerCase().includes(value.toString().toLowerCase())
              : false;
          }}
          filterDropdown={(filterDropDownProp) => (
            <ColumnSearchComponent
              filterBy="mobile number"
              filterDropDownProp={filterDropDownProp}
            />
          )}
          filterIcon={(filtered) => {
            return <SearchOutlined className={filtered ? 'searchIcon' : ''} />;
          }}
        />
        <Table.Column
          key="email"
          title="Email"
          dataIndex="email"
          align="center"
          render={(emailId: string) => emailId || '-'}
        />
        <Table.Column<BrokerDataType>
          key="Actions"
          title="Actions"
          dataIndex="actions"
          align="center"
          render={(text, record) => {
            return (
              <div className="buttonContainer">
                <Button
                  onClick={() => {
                    navigate(`${record.id}/edit`);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  title="Delete Broker. Are you sure?"
                  okText="Yes"
                  onConfirm={() => {
                    handleDeleteBroker(record.id);
                    setDeleteBrokerId(record.id);
                  }}
                  okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                  cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                >
                  <Button className="deleteButton" loading={record.id === deleteBrokerId}>
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            );
          }}
        />
      </Table>
    </>
  );
};
export default ViewAllBrokersScreen;
