import React, { useState } from 'react';
import { loader } from 'graphql.macro';
import { ApolloError, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { Spin } from 'antd';
import {
  GetTransporterVehicleByIdQuery,
  GetTransporterVehicleByIdQueryVariables,
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
} from '../../../graphql/graphql-types';
import AddOrEditTransporterVehicleForm from './AddOrEditTransporterVehicleForm';
import { logger } from '../../../utils/helpers';

/* loading get files url query with the help of loader */
const getFilesUrlQuery = loader('../../../graphql/queries/getFilesURLQuery.graphql');

/* loading get transporter vehicle by id query with the help of loader */
const getTransporterVehicleByIdQuery = loader(
  '../../../graphql/queries/getTransporterVehicleByIdQuery.graphql',
);

/* React functional component */
const EditTransporterVehicleScreen = () => {
  // state to store the url of file in order to pass it down to transporter vehicle form where it will be used to download the file
  const [filesUrl, setFilesUrl] = useState<string | null>(null);

  /* extracting params from use params hook */
  const params = useParams();

  /* extracting apollo Client from use use apollo client*/
  const apolloClient = useApolloClient();

  /* use lazy query to fetch transporter vehicle by id */
  const {
    data: transporterVehicleByIdData,
    error: transporterVehicleByIdDataError,
    loading: transporterVehicleByIdDataLoading,
  } = useQuery<GetTransporterVehicleByIdQuery, GetTransporterVehicleByIdQueryVariables>(
    getTransporterVehicleByIdQuery,
    {
      variables: { id: Number(params.id) },
      onCompleted: ({ getTransporterVehicleById }) => {
        if (getTransporterVehicleById && getTransporterVehicleById?.rcS3Key) {
          apolloClient
            .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
              query: getFilesUrlQuery,
              variables: {
                keys: getTransporterVehicleById.rcS3Key,
              },
            })
            .then((filesUrlData) => {
              const { getFilesUrl } = filesUrlData.data;
              if (Array.isArray(getFilesUrl) && getFilesUrl.length > 0 && getFilesUrl[0]) {
                setFilesUrl(getFilesUrl[0].url);
              }
            })
            .catch((getFilesUrlError: ApolloError) => {
              logger(getFilesUrlError);
            });
        }
      },
    },
  );

  /* show loading indicator on the screen until data get fetch from the server */
  if (transporterVehicleByIdDataLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (transporterVehicleByIdDataError) {
    return <div className="errorText">{transporterVehicleByIdDataError.message}</div>;
  }

  return (
    <>
      {transporterVehicleByIdData && transporterVehicleByIdData.getTransporterVehicleById ? (
        <AddOrEditTransporterVehicleForm
          mode="edit"
          dataToEdit={{
            transporterId: transporterVehicleByIdData.getTransporterVehicleById.transporter.id,
            vehicleNumber: transporterVehicleByIdData.getTransporterVehicleById.number as string,
            engineNumber: transporterVehicleByIdData.getTransporterVehicleById.engineNo as string,
            chassisNumber: transporterVehicleByIdData.getTransporterVehicleById.chassisNo as string,
            capacity: transporterVehicleByIdData.getTransporterVehicleById.capacityKgs as number,
            rcCopy: null,
            idOfTransporter: transporterVehicleByIdData.getTransporterVehicleById.transporter.id,
            rcCopyUrl: filesUrl, // this key is passed to download the file and also to check weather file is present with the help of url
            rcS3Key: transporterVehicleByIdData.getTransporterVehicleById.rcS3Key || null, // this key is passed in order to delete the file using deleteS3File mutation
          }}
        />
      ) : null}
    </>
  );
};

export default EditTransporterVehicleScreen;
