import React from 'react';
import CreateOtherItemInwardOrOutwardShipmentForm from '../../../components/CreateOtherItemInwardOrOutwardShipmentForm';

/* React functional component */
const OtherItemsScreen: React.FC = () => {
  return (
    <div className="tabLayout">
      <CreateOtherItemInwardOrOutwardShipmentForm shipmentType="inward" />
    </div>
  );
};

export default OtherItemsScreen;
