import React from 'react';
import { Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetAllInwardShipmentForBagsQuery,
  GetAllInwardShipmentForBagsQueryVariables,
} from '../../../../graphql/graphql-types';
import { DailyReportsPropType, InwardShipmentsReportType } from '../../../../utils/types';
import InwardShipmentBagOrOtherItemReportTable from '../../InwardShipmentBagOrOtherItemReportTable';

/* loading get All Inward Shipment For Bags Query */
const getAllInwardShipmentForBagsQuery = loader(
  '../../../../graphql/queries/getAllInwardShipmentForBagsQuery.graphql',
);

/* React functional component */
const DailyReportsInwardBagsScreen = ({ startDate, endDate }: DailyReportsPropType) => {
  /* get All Inward Shipment For Bags data using query with start and end date from date range field */
  const {
    data: getAllInwardShipmentForBagsData,
    loading: getAllInwardShipmentForBagsLoading,
    error: getAllInwardShipmentForBagsError,
  } = useQuery<GetAllInwardShipmentForBagsQuery, GetAllInwardShipmentForBagsQueryVariables>(
    getAllInwardShipmentForBagsQuery,
    {
      variables: {
        createdAt:
          startDate && endDate
            ? {
                _gte: startDate,
                _lte: endDate,
              }
            : {},
      },
    },
  );

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllInwardShipmentForBagsError) {
    return <div className="errorText">{getAllInwardShipmentForBagsError.message}</div>;
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getAllInwardShipmentForBagsLoading) {
    return <Spin className="loadingSpinner" />;
  }

  return (
    <>
      {getAllInwardShipmentForBagsData ? (
        <InwardShipmentBagOrOtherItemReportTable
          data={getAllInwardShipmentForBagsData.inwardShipments as InwardShipmentsReportType[]}
          inwardShipmentType="bags"
          reportScreenType="daily"
        />
      ) : null}
    </>
  );
};

export default DailyReportsInwardBagsScreen;
