import React from 'react';
import { Menu, Spin } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useApp } from '../context/AppContext';
import colors from '../scss/variables.module.scss';
import styles from './PrimaryNavBar.module.scss';
import { useApolloClient, useQuery } from '@apollo/client';
import localforage from 'localforage';
import { logger } from '../utils/helpers';
import authData from '../utils/authData';
import { loader } from 'graphql.macro';
import { GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables } from '../graphql/graphql-types';

/* loading get bhagwati mill id query */
const getBhagwatiMillIdQuery = loader('../graphql/queries/getBhagwatiMillIdQuery.graphql');

/* variable to store nav bar options when user logged in has 'admin' role */
const adminNavBarOptions = [
  {
    name: 'Dashboard',
    path: '/dashBoard',
  },
  {
    name: 'Inward User',
    path: '/shipment/inward',
  },
  {
    name: 'Inward Admin',
    path: '/shipment/admin/inward/create',
  },
  {
    name: 'Outward',
    path: '/shipment/outward',
  },
  // {
  //   name: 'Milling User',
  //   path: '/user/milling/create',
  // },
  {
    name: 'Internal Transfer',
    path: '/shipment/internalTransfer',
  },
  {
    name: 'Weighbridge',
    path: '/weighbridgeReceipts/create',
  },
  {
    name: 'Purchase',
    path: '/purchaseOrders',
  },
  // {
  //   name: 'Inventory',
  //   path: '/inventory',
  // },
  {
    name: 'Management',
    path: '/management',
  },
  {
    name: 'Reports',
    path: '/report',
  },
];

/* variable to store nav bar options when user logged in has 'manager' role */
const managerNavBarOptions = [
  {
    name: 'Dashboard',
    path: '/dashBoard',
  },
  {
    name: 'Inward User',
    path: '/shipment/inward',
  },
  {
    name: 'Process GRN (Manager)',
    path: '/shipment/admin/inward/create',
  },
  {
    name: 'Outward',
    path: '/shipment/outward',
  },
  {
    name: 'Internal Transfer',
    path: '/shipment/internalTransfer',
  },
  {
    name: 'Weighbridge',
    path: '/weighbridgeReceipts/create',
  },
  {
    name: 'Purchase',
    path: '/purchaseOrders',
  },
  {
    name: 'Management',
    path: '/management',
  },
  {
    name: 'Reports',
    path: '/report',
  },
];

/* variable to store nav bar options when user logged in has 'staff' role */
const staffNavBarOptions = [
  {
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    name: 'Inward User',
    path: '/shipment/inward',
  },
  {
    name: 'Inward Admin',
    path: '/shipment/admin/inward/create',
  },
  {
    name: 'Outward',
    path: '/shipment/outward',
  },
  {
    name: 'Internal Transfer',
    path: '/shipment/internalTransfer',
  },
  {
    name: 'Weighbridge',
    path: '/weighbridgeReceipts/create',
  },
  {
    name: 'Reports',
    path: '/report',
  },
];

const MillName = () => {
  /* get bhagwati mill id query used to show Mill name */
  const {
    data: getBhagwatiMillIdData,
    error: getBhagwatiMillIdQueryError,
    loading: getBhagwatiMillIdQueryLoader,
  } = useQuery<GetBhagwatiMillIdQuery, GetBhagwatiMillIdQueryVariables>(getBhagwatiMillIdQuery);

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getBhagwatiMillIdQueryError) {
    return <div className="errorText">{getBhagwatiMillIdQueryError.message}</div>;
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getBhagwatiMillIdQueryLoader) {
    <Spin className="loadingSpinner" />;
  }

  return (
    <h1
      style={{
        color: colors.navBarLogoColor,
        fontSize: 26,
        backgroundColor: colors.colorBlack,
        padding: '0px 15px 0px 10px',
        margin: 0,
      }}
    >
      {getBhagwatiMillIdData &&
        getBhagwatiMillIdData.mills[0] &&
        getBhagwatiMillIdData.mills[0].name}
    </h1>
  );
};

/* React functional component */
const PrimaryNavBar = () => {
  /* extracting location from useLocation hook */
  const location = useLocation();

  /* extracting apollo Client from use use apollo client*/
  const apolloClient = useApolloClient();

  /* extracting user from context */
  const { user, setUser } = useApp();

  /* nav bar options to display depending on role of user-initially stored to admin nav bar options initially */
  let navBarOptions = adminNavBarOptions;
  if (user && user.role === 'staff') {
    navBarOptions = staffNavBarOptions;
  }
  if (user && user.role === 'manager') {
    navBarOptions = managerNavBarOptions;
  }
  /* to store the value of padding on right and left side depending upon the user role */
  const paddingLeftAndRight = user?.role === 'admin' || 'manager' ? '10px' : '20px';

  return (
    <div style={{ display: 'flex' }} className="hideInPrint">
      <MillName />
      <Menu
        mode="horizontal"
        style={{
          backgroundColor: colors.navBarBgColor,
          fontWeight: 500,
          fontSize: 16,
          flex: 1,
        }}
      >
        {navBarOptions.map((item, index) => {
          return (
            <Menu.Item
              key={item.path}
              style={{
                marginLeft: index === 0 ? 30 : 0,
                padding: `3px ${paddingLeftAndRight} 3px ${paddingLeftAndRight}`,
                backgroundColor: location.pathname.includes(item.path)
                  ? colors.colorBlack
                  : 'transparent',
              }}
            >
              <Link
                to={item.path}
                className={styles.link}
                style={{
                  color: location.pathname.includes(item.path)
                    ? colors.colorWhite
                    : colors.colorBlack,
                }}
              >
                {item.name}
              </Link>
            </Menu.Item>
          );
        })}
        <Menu.Item
          style={{
            marginLeft: 'auto',
            padding: `3px ${paddingLeftAndRight} 3px ${paddingLeftAndRight}`,
          }}
          onClick={() => {
            /**
             * Here we are logging out the user from our application. for that we need to update following things
             * 1. remove user auth token
             * 2. reset apollo client store
             * 3. clearing data for localforage
             * 4. clearing user data from context
             */
            authData.removeToken();
            apolloClient.resetStore().catch((err: Error) => {
              logger(err);
            });
            localforage.clear().catch((err: Error) => {
              logger(err);
            });
            setUser(undefined);
          }}
        >
          Logout
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default PrimaryNavBar;
