import React, { useRef, useState } from 'react';
import {
  Button,
  Col,
  Descriptions,
  DescriptionsProps,
  Divider,
  message,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
} from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ApolloError, gql, useApolloClient, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { loader } from 'graphql.macro';
import {
  GetFilesUrlQuery,
  GetFilesUrlQueryVariables,
  GetInwardShipmentByIdQuery,
  GetInwardShipmentByIdQueryVariables,
  InwardShipments,
} from '../../graphql/graphql-types';
import { dateFormatFunc } from '../../utils/globals';
import {
  InwardShipmentItemsType,
  InwardShipmentsReportType,
  ItemsShipment,
  UploadedFileKeyAndUrlType,
} from '../../utils/types';
import RadioGroup from '../../components/RadioGroup';
import { logger, convertKgsToQuintals } from '../../utils/helpers';
import colors from '../../scss/variables.module.scss';
import { useApp } from '../../context/AppContext';
import FormItem from '../../components/FormItem';
import InputNumber from '../../components/InputNumber';
import LightBoxComponent from '../../components/LightboxComponent';
import PurchaseBillPreviewModal from './PurchaseBillPreviewModal';
import Switch from '../../components/Switch';
import Input from '../../components/Input';
import differenceInWeightCalculation from '../../utils/helpers/differenceInWeightCalculation';
import { useReactToPrint } from 'react-to-print';

/* loading get inward shipment by Id query */
const getInwardShipmentByIdQuery = loader(
  '../../graphql/queries/getInwardShipmentByIdQuery.graphql',
);

/* loading get admin open inward shipments query */
const getAdminOpenInwardShipmentsQuery = loader(
  '../../graphql/queries/getAdminOpenInwardShipmentsQuery.graphql',
);

/* loading get admin review inward shipments Query */
const getAdminReviewInwardShipmentsQuery = loader(
  '../../graphql/queries/getAdminReviewInwardShipmentsQuery.graphql',
);

/* loading get files url query */
const getFilesUrlQuery = loader('../../graphql/queries/getFilesURLQuery.graphql');

/* process grn form field type */
export type ProcessGRNFormFieldType = {
  /* weighbridge charges */
  weighbridgeCharges: number | null;
  /* labor charge type */
  laborChargeType: string | null;
  /* labor charge per bag */
  laborChargePerBag: number | null;
  /*  array of inward shipment items */
  inwardShipmentItems: Array<InwardShipmentItemsType>;
  /* prop type for bill send by party or not */
  isBillSendByParty?: boolean;
  /* prop type for bill number */
  billNumber?: string | null;
  /* prop type for net weight in quintals */
  netWeightInQuintals?: number | null;
  /* prop type for broker */
  broker?: string | null;
  /* prop type for additional information */
  additionalInformation?: string | null;
};

/* inward shipment items new data */
type InwardShipmentItemsUpdatedDataArray = {
  /* inward shipment item id */
  inwardShipmentItemId: number;
  /* empty bag cost */
  emptyBagCost: number;
  /* empty bags weight kg */
  emptyBagsWtKg: number;
  /* price per kg */
  pricePerKg: number;
  /* net material weight per bag kg */
  netMaterialWtPerBagKg: number;
  /* user empty bags weight unit */
  userEmptyBagWtUnit: string;
  /* brokerage per quintal */
  brokeragePerQuintal: number;
};

// process grn screen prop type
type ProcessGRNScreenPropType = {
  // this prop used to get clicked inward shipment id from inwardShipment reports screen,
  inwardShipmentId?: number;
  // this prop is use to close the modal by setting modal visibility state its optional prop and passed only when screen called from report section
  closeModal?: () => void;
  /* refetch get all inward shipment for rawMaterial query*/
  refetch?: () => void;
};

/* styling for Description component */
const descriptionComponentStyleProps: DescriptionsProps = {
  /* define number of column in row */
  column: 1,
  /* set description components item colon false */
  colon: false,
  /* styling of description component labels */
  labelStyle: { width: '29%' },
};

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 6,
  /* input column span of FormItem */
  inputColSpan: 8,
};

/* input component styling props */
const inputCustomStyleProps = {
  borderRadius: 5,
  border: '1px solid black',
  width: '70%',
  justifyContent: 'center',
};

/* common input component style */
export const inputComponentCommonStyle = {
  /* border radius of input component */
  borderRadius: 5,
  /* border to input component */
  border: '1px solid black',
  /* width of input component */
  width: '100%',
  /* justify center  */
  justifyContent: 'center',
};

/* validations required for useForm */
const schema = yup.object({
  weighbridgeCharges: yup
    .number()
    .required('Please enter weighbridge charges and try again.')
    .nullable(),
  laborChargeType: yup
    .string()
    .nullable()
    .required('Please select labor charge type and try again.'),
  laborChargePerBag: yup
    .number()
    .required('Please enter labour charges per bag and try again')
    .nullable(),
});

/* Process GRN Screen */
const ProcessGRNScreen = ({ inwardShipmentId, closeModal, refetch }: ProcessGRNScreenPropType) => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* variable for stored param id */
  const paramId = Number(params.id);

  /* extracting navigate  from useNavigate hook */
  const navigate = useNavigate();

  /* extracting apollo Client from use use apollo client*/
  const apolloClient = useApolloClient();

  /* extracting user from useApp context */
  const { user } = useApp();

  /* state used to store object of vehicle image which contain image key and its respective url */
  const [imageUrlObjectArray, setImageUrlObjectArray] = useState<Array<UploadedFileKeyAndUrlType>>(
    [],
  );

  /* state used to set the visibility of lightBox for image display */
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

  /* state used to set the visibility of modal of purchase print preview  */
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);

  /* this state used to show loading indicator on complete grn button */
  const [isCompleteGrnBtnLoading, setIsCompleteGrnBtnLoading] = useState<boolean>(false);

  /** const to store reference which is used to print content */
  const printRef = useRef(null);

  /** const used to call 'react-to-print' print method*/
  const handlePrint = useReactToPrint({
    /* content for printing */
    content: () => printRef.current,
  });

  /* useForm declaration */
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    watch,
  } = useForm<ProcessGRNFormFieldType>({
    mode: 'onChange',
    defaultValues: {
      weighbridgeCharges: null,
      laborChargeType: null,
      laborChargePerBag: null,
      inwardShipmentItems: [],
      isBillSendByParty: false,
      additionalInformation: null,
      billNumber: null,
      broker: null,
      netWeightInQuintals: null,
    },
    resolver: user && user.role === 'admin' ? yupResolver(schema) : undefined,
  });

  /* use field array of inward shipment items */
  const { fields } = useFieldArray({
    name: 'inwardShipmentItems',
    keyName: 'id',
    control,
  });

  /* variable used for stored sum of bagsCount of all inwardShipmentItems in a selected inwardShipment */
  let totalBags = 0;

  /* variable used for stored total material weight in kg (combination of empty bag wt and actual material) of selected shipment */
  let totalMaterialWtInKg = 0;

  /* function for passed watch data */
  const watchDataFunction = (index: number) => {
    /* stored  price per quintal */
    const pricePerQuintal = watch(`inwardShipmentItems.${index}.pricePerQuintal`);

    /* stored  brokerage price per quintal */
    const brokeragePricePerQuintal = watch(`inwardShipmentItems.${index}.brokeragePricePerQuintal`);

    /* stored empty bag weight type field value*/
    const bagWeightType = watch(`inwardShipmentItems.${index}.bagWeightType`);

    /* stored  empty bag Weight Kg */
    const emptyBagWtKg = watch(`inwardShipmentItems.${index}.emptyBagWtKg`);

    /* stored empty bag cost */
    const emptyBagCost = watch(`inwardShipmentItems.${index}.emptyBagCost`);

    return {
      emptyBagCost: emptyBagCost,
      emptyBagWtKg: emptyBagWtKg,
      bagWeightType: bagWeightType,
      pricePerQuintal: pricePerQuintal,
      brokeragePricePerQuintal: brokeragePricePerQuintal,
    };
  };

  /* function for net material weight calculation */
  const netMaterialWeightCalculationFunction = (
    item: ItemsShipment,
    index: number,
    emptyBagWtKgValue: number | null,
    // empty bag weight type
    bagWeightTypeValue: string | null,
  ) => {
    /* stored empty bag weight type */
    const bagWeightType = watch(`inwardShipmentItems.${index}.bagWeightType`);

    /* variable to storing  bag count value */
    const bagsCount = item.bagsCount ? item.bagsCount : 0;

    /*  if bagWeightTypeValue is present then its stored in variable other default type stored.  */
    const bagTypeValue = bagWeightTypeValue ? bagWeightTypeValue : bagWeightType;

    // here we are calculating total material wt(combination of empty bag wt and actual material) for given shipment item (single item)
    /* calculation of total weight of bags in kg */
    const totalWtOfBagsInKg = (bagsCount * totalMaterialWtInKg) / totalBags;

    // here we are calculating total material wt(combination of empty bag wt and actual material) for given shipment item (single item) in quintal
    /* calculation of total material weight in quintal */
    const totalMaterialWtInQt = convertKgsToQuintals(totalWtOfBagsInKg);

    /* calculation of weight of per bag */
    const wtOfPerBag = totalWtOfBagsInKg / bagsCount;

    /* stored weight of empty bag value */
    let wtOfEmptyBag = 0;

    /* if bag type value is 'kgPerQuintal' */
    if (bagTypeValue === 'kgPerQuintal') {
      if (emptyBagWtKgValue) {
        /* calculation of total weight of empty bag */
        const totalWtOfEmptyBag = emptyBagWtKgValue * totalMaterialWtInQt;

        /* calculation of weight of empty bag */
        wtOfEmptyBag = totalWtOfEmptyBag / bagsCount;
      }

      /* calculation of weight of material per bag */
      const wtOfMaterialPerBag = wtOfPerBag - wtOfEmptyBag;

      return parseFloat(wtOfMaterialPerBag.toFixed(2));
    }

    /* if bag type value is 'kgPerBag */
    if (bagTypeValue === 'kgPerBag') {
      /* calculation of weight of material per bag */
      const wtOfMaterialPerBag = wtOfPerBag - (emptyBagWtKgValue ? emptyBagWtKgValue : 0);

      return parseFloat(wtOfMaterialPerBag.toFixed(2));
    }
  };

  /* function for sub total calculation */
  const subTotalCalculationFunction = (
    record: ItemsShipment,
    index: number,
    emptyBagCostValue: number | null,
    pricePerQuintalValue: number | null,
    brokeragePricePerQuintalValue: number | null,
    emptyBagWtKgValue: number | null,
    // empty bag weight type
    bagWeightTypeValue: string | null,
  ) => {
    if (record.materialReturned !== true) {
      /* variable to store bags count of individual inwardShipmentItem */
      const bagsCount = record.bagsCount ? record.bagsCount : 0;

      /* variable for stored subtotal value */
      let subTotal = 0;

      /* variable for price Of total Weight */
      let priceOfTotalWt = 0;

      /* variable for price of brokerage  */
      let priceOfBrokerage = 0;

      /* variable for stored subtotal value */
      let priceOfTotalEmptyBagWt = 0;

      // const use to store actual weight of material in single bag
      const netActualMaterialWtInKgPerBag = netMaterialWeightCalculationFunction(
        record,
        index,
        emptyBagWtKgValue,
        bagWeightTypeValue,
      );

      if (netActualMaterialWtInKgPerBag) {
        // const use to store sum of netActualMaterialWtInKgPerBag for every bag in selected item based on bagsCount
        const totalNetActualMaterialWtInKg = netActualMaterialWtInKgPerBag * bagsCount;

        const totalNetActualMaterialWtInQuintal = parseFloat(
          convertKgsToQuintals(totalNetActualMaterialWtInKg).toFixed(2),
        );

        if (pricePerQuintalValue) {
          priceOfTotalWt = totalNetActualMaterialWtInQuintal * pricePerQuintalValue;
        }

        if (brokeragePricePerQuintalValue) {
          priceOfBrokerage = brokeragePricePerQuintalValue * totalNetActualMaterialWtInQuintal;
        }
        if (emptyBagCostValue) {
          priceOfTotalEmptyBagWt = emptyBagCostValue * bagsCount;
        }

        subTotal = priceOfTotalWt + priceOfBrokerage - priceOfTotalEmptyBagWt;
      }
      return parseFloat(subTotal.toFixed(2));
    }
  };

  /* get inward shipment query data using id */
  const {
    data: getInwardShipmentByIdData,
    loading: getInwardShipmentByIdDataLoading,
    error: getInwardShipmentByIdDataError,
  } = useQuery<GetInwardShipmentByIdQuery, GetInwardShipmentByIdQueryVariables>(
    getInwardShipmentByIdQuery,
    {
      fetchPolicy: 'network-only',
      variables: { id: inwardShipmentId ? inwardShipmentId : paramId },
      onCompleted: (res) => {
        /* inwardShipmentDataById stored inward shipment query data  */
        const inwardShipmentDataById: InwardShipmentsReportType | undefined | null =
          res.getInwardShipmentById;

        // variable use to store inwardShipmentItem initial value in two different scenario, as in 1st case for shipment with status value equal to adminReview and user role as admin
        // we want to show prefield values entered by user of role staff or manager else in other case we just want to set netWeightValue, total netWeightValue and rest other value to null or undefined
        let inwardShipmentItemObject: InwardShipmentItemsType;

        /* setting initial values entered by staff or manager for inwardShipment fields (other than items field) when status of shipment is admin review */
        if (
          user &&
          user.role === 'admin' &&
          inwardShipmentDataById &&
          inwardShipmentDataById.status === 'adminReview'
        ) {
          /* set value of labor charge per bag */
          setValue(
            'laborChargePerBag',
            inwardShipmentDataById.laborChargePerBag
              ? inwardShipmentDataById.laborChargePerBag
              : null,
          );
          /* set value of weighbridge charges*/
          setValue(
            'weighbridgeCharges',
            inwardShipmentDataById.weighbridgeCharges
              ? inwardShipmentDataById.weighbridgeCharges
              : null,
          );
          /* set value of labor charge type*/
          setValue(
            'laborChargeType',
            inwardShipmentDataById.laborChargeType ? inwardShipmentDataById.laborChargeType : null,
          );
        }
        if (
          inwardShipmentDataById &&
          Array.isArray(inwardShipmentDataById.items) &&
          inwardShipmentDataById.items.length > 0
        ) {
          /* set values of items array values */
          inwardShipmentDataById.items.forEach((data, index) => {
            /* for user with admin role we are setting initial values  entered by staff or manager for inwardShipment's items field when status of shipment is admin review */
            if (user && user.role === 'admin' && inwardShipmentDataById.status === 'adminReview') {
              const brokeragePerQuintal = data.brokeragePerQuintal || undefined;
              const emptyBagsWtKg = data.emptyBagsWtKg || null;
              const emptyBagCost = data.emptyBagCost || null;
              const pricePerKg = data.pricePerKg
                ? Number((data.pricePerKg * 100).toFixed(2))
                : undefined;
              const userEmptyBagWtUnit = data.userEmptyBagWtUnit || null;

              /* stored sub total value to set value for subTotal */
              const subTotalValue = subTotalCalculationFunction(
                data,
                index,
                emptyBagCost,
                pricePerKg || null,
                brokeragePerQuintal || null,
                emptyBagsWtKg,
                userEmptyBagWtUnit,
              );

              /* stored net material weight  value to set value for netMaterialWeightValue */
              const netMaterialWeightValue = netMaterialWeightCalculationFunction(
                data,
                index,
                emptyBagsWtKg,
                userEmptyBagWtUnit,
              );

              // assigning value to inwardShipmentItemObject
              inwardShipmentItemObject = {
                brokeragePricePerQuintal: brokeragePerQuintal,
                emptyBagWtKg: emptyBagsWtKg,
                emptyBagCost: emptyBagCost,
                pricePerQuintal: pricePerKg,
                inwardShipmentItemId: data.id,
                bagWeightType: userEmptyBagWtUnit,
                netMaterialWtPerBagKg: netMaterialWeightValue || 0,
                subTotal: subTotalValue,
                bagsCount: data.bagsCount || 0,
                materialReturned: data.materialReturned,
                paddyGrade: data.paddyGrade,
                sellerName: data.seller ? data.seller.name : '',
                rawMaterialName: data.rawMaterial ? data.rawMaterial.name : '',
                emptyBagsReturned: data.emptyBagsReturned,
              };
            } else {
              const bagWeightType = 'kgPerQuintal';

              const emptyBagWtKg = data.emptyBagsWtKg as number;

              /* stored net material weight  value to set value for netMaterialWeightValue */
              const netActualMaterialWeightPerBagInKg = netMaterialWeightCalculationFunction(
                data,
                index,
                emptyBagWtKg,
                bagWeightType,
              );

              const netActualMaterialWeightPerBagInKgValue = netActualMaterialWeightPerBagInKg
                ? netActualMaterialWeightPerBagInKg
                : 0;

              // assigning value to inwardShipmentItemObject
              inwardShipmentItemObject = {
                netMaterialWtPerBagKg:
                  data.materialReturned !== true ? netActualMaterialWeightPerBagInKgValue : 0,
                bagWeightType: bagWeightType,
                brokeragePricePerQuintal: undefined,
                bagsCount: data.bagsCount ? data.bagsCount : 0,
                inwardShipmentItemId: data.id,
                pricePerQuintal: undefined,
                subTotal: undefined,
                emptyBagCost: data.emptyBagCost as number,
                emptyBagWtKg: data.emptyBagsWtKg as number,
                materialReturned: data.materialReturned ? data.materialReturned : null,
                paddyGrade: data.paddyGrade ? data.paddyGrade : null,
                sellerName: data.seller ? data.seller.name : '',
                rawMaterialName: data.rawMaterial ? data.rawMaterial.name : '',
                emptyBagsReturned: data.emptyBagsReturned ? data.emptyBagsReturned : null,
              };
            }
            /* setValue of  inwardShipmentItems */
            setValue(`inwardShipmentItems.${index}`, inwardShipmentItemObject, {
              shouldValidate: true,
            });
          });
        }

        /* ----------------- logic to get image url start ------------ */
        /* destructing fullVehicleImage, emptyVehicleImage from data */
        const { fullVehicleImage, emptyVehicleImage } = inwardShipmentDataById as InwardShipments;

        /* const array used to store vehicle images keys */
        const vehicleImageKeyArray: string[] = [];

        /* if full vehicle image present then push that image key into vehicle image array */
        if (fullVehicleImage) {
          vehicleImageKeyArray.push(fullVehicleImage);
        }

        /* if empty vehicle image present then push that image key into vehicle image array */
        if (emptyVehicleImage) {
          vehicleImageKeyArray.push(emptyVehicleImage);
        }

        /* if vehicle images added for the respective shipment, then get that image urls using image key  */
        if (vehicleImageKeyArray) {
          /* get files url query used to get urls & key of the images */
          apolloClient
            .query<GetFilesUrlQuery, GetFilesUrlQueryVariables>({
              query: getFilesUrlQuery,
              variables: {
                keys: vehicleImageKeyArray,
              },
            })
            .then((imageUrlsData) => {
              /* destructing images file url and key */
              const { getFilesUrl } = imageUrlsData.data;

              /* const array used to store key and url of image */
              const fileUrlAndKeyArray: Array<UploadedFileKeyAndUrlType> = [];

              /* if url and key present for image then push it into file url array for further operation */
              if (getFilesUrl) {
                getFilesUrl.forEach((item) => {
                  if (item) {
                    /* create key and url object from resultant data to simplify further data handling */
                    fileUrlAndKeyArray.push({
                      key: item.key,
                      url: item.url,
                    });
                  }
                });
              }
              /* update url and key of images array into state , so that we can use it to display image */
              setImageUrlObjectArray(fileUrlAndKeyArray);
            })
            .catch((getFilesUrlError: ApolloError) => {
              logger(getFilesUrlError);
            });
        }
        /* ----------------- logic to get image url end ------------ */
      },
    },
  );

  /* if data is present in get inward shipment by id data query stored in inwardShipmentData otherwise stored undefined  */
  const inwardShipmentData = getInwardShipmentByIdData?.getInwardShipmentById;

  /* calculation for total bags  and total material weight( combination of empty bag weight and actual material for all items in shipment) of shipment in kg */
  if (inwardShipmentData) {
    inwardShipmentData.items.forEach((item) => {
      if (!item.materialReturned) totalBags += item.bagsCount ? item.bagsCount : 0;
    });
    totalMaterialWtInKg = inwardShipmentData.fullVehicleWtKg - inwardShipmentData.emptyVehicleWtKg;
  }

  /* onSubmit Function */
  const onSubmit = handleSubmit((formData: ProcessGRNFormFieldType) => {
    setIsCompleteGrnBtnLoading(true);

    /* if user role is admin then stored status value as completed otherwise status value is adminReview */
    const statusValue = user && user.role === 'admin' ? 'completed' : 'adminReview';

    /*  stored  data of inward shipment items updated  and removing null value */
    const inwardShipmentItemsUpdatedData: Array<InwardShipmentItemsUpdatedDataArray> = [];

    formData.inwardShipmentItems.filter((item) => {
      if (item !== null && item.inwardShipmentItemId) {
        inwardShipmentItemsUpdatedData.push({
          inwardShipmentItemId: item.inwardShipmentItemId,
          emptyBagCost: item.emptyBagCost ? item.emptyBagCost : 0,
          emptyBagsWtKg: item.emptyBagWtKg ? item.emptyBagWtKg : 0,
          pricePerKg: item.pricePerQuintal ? item.pricePerQuintal / 100 : 0,
          netMaterialWtPerBagKg: item.netMaterialWtPerBagKg ? item.netMaterialWtPerBagKg : 0,
          userEmptyBagWtUnit: item.bagWeightType ? item.bagWeightType : '',
          brokeragePerQuintal: item.brokeragePricePerQuintal ? item.brokeragePricePerQuintal : 0,
        });
      }
      return false;
    });

    /* variable for update all inward shipment items mutation string */
    let updateAllInwardShipmentItemsMutationString = '';

    inwardShipmentItemsUpdatedData.forEach((inwardShipmentItem) => {
      /* update mutation string for individual inward shipment item to edit data */
      const updateSingleInwardShipmentItemMutationString = ` update_inwardShipmentItem_${inwardShipmentItem.inwardShipmentItemId}: update_inwardShipmentItems_by_pk(pk_columns: {id: ${inwardShipmentItem.inwardShipmentItemId}}, _set: {emptyBagCost: ${inwardShipmentItem.emptyBagCost}, emptyBagsWtKg: ${inwardShipmentItem.emptyBagsWtKg}, pricePerKg: ${inwardShipmentItem.pricePerKg}, netMaterialWtPerBagKg: ${inwardShipmentItem.netMaterialWtPerBagKg}, userEmptyBagWtUnit: ${inwardShipmentItem.userEmptyBagWtUnit}, brokeragePerQuintal: ${inwardShipmentItem.brokeragePerQuintal}}) {
  id
  }`;
      /* combine all inward shipment items update mutation string into one string */
      updateAllInwardShipmentItemsMutationString += updateSingleInwardShipmentItemMutationString;
    });

    /* eslint-disable @typescript-eslint/restrict-template-expressions */
    // creating a const which store logic to create dynamic mutation.
    const adminProcessInwardShipmentMutationString = `mutation($completedAt: timestamptz) {
        update_inwardShipments_by_pk(pk_columns: {id: ${
          inwardShipmentId ? inwardShipmentId : paramId
        }}, _set: {completedAt:$completedAt, status:${statusValue}, weighbridgeCharges: ${
      formData.weighbridgeCharges as number
    }, laborChargePerBag: ${formData.laborChargePerBag as number}, laborChargeType: ${
      formData.laborChargeType as string
    }, billing_details: ${
      formData.isBillSendByParty
        ? `{
       bill_number: ${formData.billNumber ? `"${formData.billNumber}"` : null},
       net_weight: ${formData.netWeightInQuintals ? formData.netWeightInQuintals : null},
       additional_information: ${
         formData.additionalInformation ? `"${formData.additionalInformation}"` : null
       },
       broker_name: ${formData.broker ? `"${formData.broker}"` : null}
    }`
        : null
    }}) {
  id
  }
  ${updateAllInwardShipmentItemsMutationString}
  }`;
    /* eslint-enable */

    // call to mutation which we define externally in const adminProcessInwardShipmentMutationString
    apolloClient
      .mutate({
        mutation: gql`
          ${adminProcessInwardShipmentMutationString}
        `,
        variables: {
          completedAt: user && user.role === 'admin' ? new Date() : null,
        },
        refetchQueries: [
          {
            query:
              inwardShipmentData && inwardShipmentData.status === 'adminReview'
                ? getAdminReviewInwardShipmentsQuery
                : getAdminOpenInwardShipmentsQuery,
          },
        ],
      })
      .then(() => {
        setIsCompleteGrnBtnLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Inward shipment processed.');
        setModalIsVisible(true);
      })
      .catch((error: ApolloError) => {
        setIsCompleteGrnBtnLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  });

  /* function for get total amount function */
  const getTotalAmountFunction = () => {
    /* stored total amount */
    let totalAmount = 0;

    /* stored  weighbridge charges */
    const weighbridgeCharges = watch('weighbridgeCharges');

    /* stored  laborCharge type */
    const laborChargeType = watch('laborChargeType');

    /* stored  labor charge per bag */
    const laborChargePerBag = watch('laborChargePerBag');

    /* stored  items array */
    let items = watch('inwardShipmentItems');

    /* items stored filter data of items */
    items = items ? items.filter((item) => item) : [];

    /* if weighbridge charges is not undefined and null then subtract weighbridge charges stored in total amount*/
    if (weighbridgeCharges) {
      totalAmount -= weighbridgeCharges;
    }

    if (items) {
      items.forEach((item) => {
        if (item.inwardShipmentItemId) {
          /* extracting subtotal from items */
          const { subTotal } = item;

          if (
            laborChargeType &&
            laborChargePerBag !== null &&
            laborChargePerBag !== undefined &&
            !item.materialReturned
          ) {
            if (subTotal) {
              totalAmount += subTotal;
            }

            if (laborChargeType === 'add') {
              totalAmount += item.bagsCount * (laborChargePerBag ? laborChargePerBag : 0);
            } else {
              totalAmount -= item.bagsCount * (laborChargePerBag ? laborChargePerBag : 0);
            }
          }
        }
      });
    }
    return parseFloat(totalAmount.toFixed(2));
  };

  /* function for get loading location */
  const getLoadingLocation = (record: ItemsShipment) => {
    if (!record.destination) return 'Unknown';
    if (record.materialReturned === true) return 'Returned';
    if (record.destination === 'mill') return 'Mill';
    return record.godown && record.godown.name;
  };

  /*  function for return form data and passed to purchase bill preview modal */
  const formData = () => {
    /* stored  weighbridge charges */
    const weighbridgeCharges = watch('weighbridgeCharges');

    /* stored  laborCharge type */
    const laborChargeType = watch('laborChargeType');

    /* stored  labor charge per bag */
    const laborChargePerBag = watch('laborChargePerBag');

    /* stored  inward shipment items array */
    const inwardShipmentItemsData = watch('inwardShipmentItems');

    return {
      laborChargePerBag: laborChargePerBag,
      laborChargeType: laborChargeType,
      weighbridgeCharges: weighbridgeCharges,
      inwardShipmentItems: inwardShipmentItemsData,
    };
  };

  const inwardShipmentDataDetails =
    getInwardShipmentByIdData &&
    (getInwardShipmentByIdData.getInwardShipmentById as InwardShipmentsReportType);

  /* DescriptionDetails Component  */
  const DescriptionDetails = (): JSX.Element => {
    if (getInwardShipmentByIdData) {
      const { grn, createdAt, emptyVehicleWtKg, vehicleNumber, driverName, fullVehicleWtKg } =
        getInwardShipmentByIdData.getInwardShipmentById as InwardShipmentsReportType;
      return (
        <>
          <Descriptions {...descriptionComponentStyleProps}>
            <Descriptions.Item label="GRN">{grn}</Descriptions.Item>
            <Descriptions.Item label="Date">
              {dateFormatFunc(createdAt as string)}
            </Descriptions.Item>
            <Descriptions.Item label="Vehicle No">{vehicleNumber}</Descriptions.Item>
            <Descriptions.Item label="Driver name">{driverName}</Descriptions.Item>
            <Descriptions.Item label="Empty vehicle weight (in kgs)">
              {emptyVehicleWtKg ? emptyVehicleWtKg.toFixed(2) : 0}
            </Descriptions.Item>
            <Descriptions.Item label="Gross weight (in kgs)">
              {fullVehicleWtKg ? fullVehicleWtKg.toFixed(2) : 0}
            </Descriptions.Item>
          </Descriptions>
        </>
      );
    }
    return <div />;
  };

  /* show loading indicator on the screen until data get fetch from the server */
  if (getInwardShipmentByIdDataLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getInwardShipmentByIdDataError) {
    return <div className="errorText">{getInwardShipmentByIdDataError.message}</div>;
  }

  return (
    <div>
      <Row>
        <Col span={21}>
          <DescriptionDetails />
        </Col>
        {inwardShipmentId ? null : (
          <Col span={3}>
            <div style={{ marginLeft: '35px', marginTop: '20px' }}>
              <Button
                type="primary"
                style={{
                  marginLeft: 20,
                  display: user && user.role === 'admin' ? 'block' : 'none',
                }}
                onClick={() => {
                  navigate(`/shipment/admin/inward/edit/${paramId}`);
                }}
              >
                Modify GRN
              </Button>
            </div>
          </Col>
        )}
      </Row>
      <form>
        <FormItem
          label="Deduct weighbridge charges (in Rs.)"
          isRequired={user && user.role === 'admin' ? true : false}
          requiredMark={user && user.role === 'admin' ? 'after' : undefined}
          errorText={
            errors && errors.weighbridgeCharges ? errors.weighbridgeCharges.message : undefined
          }
          {...formItemStyleProps}
          customStyle={{ paddingTop: 0 }}
        >
          <InputNumber
            name="weighbridgeCharges"
            placeholder="Please enter weighbridge charges"
            rhfControllerProps={{
              control,
            }}
            customStyles={{ ...inputCustomStyleProps }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        <FormItem
          label="Labour charge type"
          isRequired={user && user.role === 'admin' ? true : false}
          requiredMark={user && user.role === 'admin' ? 'after' : undefined}
          customStyle={{ paddingTop: '16px' }}
          labelStyle={{ bottom: '7px' }}
          errorText={
            errors && errors.laborChargeType && user && user.role === 'admin'
              ? errors.laborChargeType.message
              : undefined
          }
          {...formItemStyleProps}
        >
          <RadioGroup
            name="laborChargeType"
            rhfControllerProps={{
              control,
            }}
            options={[
              { label: 'Add', value: 'add' },
              {
                label: 'Deduct',
                value: 'deduct',
              },
            ]}
          />
        </FormItem>
        <FormItem
          label="Labour charges per bag (in Rs.)"
          isRequired={user && user.role === 'admin' ? true : false}
          requiredMark={user && user.role === 'admin' ? 'after' : undefined}
          errorText={
            errors && errors.laborChargePerBag ? errors.laborChargePerBag.message : undefined
          }
          {...formItemStyleProps}
          customStyle={{ paddingTop: '16px' }}
        >
          <InputNumber
            customStyles={{ ...inputCustomStyleProps }}
            name="laborChargePerBag"
            placeholder="Please enter labour charges per bag"
            rhfControllerProps={{
              control,
            }}
            inputNumberProps={{ min: 0 }}
          />
        </FormItem>
        <FormItem label="Is Bill send by party?" {...formItemStyleProps}>
          <Switch name="isBillSendByParty" rhfControllerProps={{ control }} />
        </FormItem>
        {watch('isBillSendByParty') ? (
          <>
            <FormItem label="Bill number" {...formItemStyleProps}>
              <Input
                name="billNumber"
                customStyles={{ ...inputCustomStyleProps }}
                placeholder="Enter bill number"
                rhfControllerProps={{
                  control,
                }}
              />
            </FormItem>
            <FormItem label="Net Weight (In quintals)" {...formItemStyleProps}>
              <InputNumber
                name="netWeightInQuintals"
                customStyles={{ ...inputCustomStyleProps }}
                placeholder=" Enter net weight from party bill"
                rhfControllerProps={{
                  control,
                }}
                inputNumberProps={{ min: 0 }}
              />
            </FormItem>
            <FormItem label="Weight difference (in quintals)" {...formItemStyleProps}>
              <>
                {differenceInWeightCalculation({
                  fullVehicleWtKg:
                    (inwardShipmentDataDetails && inwardShipmentDataDetails.fullVehicleWtKg) || 0,
                  emptyVehicleWtKg:
                    (inwardShipmentDataDetails && inwardShipmentDataDetails.emptyVehicleWtKg) || 0,
                  netWeightInQuintals: watch('netWeightInQuintals') || 0,
                })}
              </>
            </FormItem>
            <FormItem label="Broker" {...formItemStyleProps}>
              <Input
                name="broker"
                customStyles={{ ...inputCustomStyleProps }}
                placeholder="Enter broker name"
                rhfControllerProps={{
                  control,
                }}
              />
            </FormItem>
            <FormItem label="Additional information" {...formItemStyleProps}>
              <Input
                name="additionalInformation"
                customStyles={{ ...inputCustomStyleProps }}
                isTextAreaInput
                placeholder="Enter Additional information"
                rhfControllerProps={{
                  control,
                }}
              />
            </FormItem>
          </>
        ) : null}
        <div style={{ marginTop: '20px' }}>
          <Descriptions>
            <Descriptions.Item>
              <Button
                htmlType="submit"
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  setIsImageOpen(true);
                }}
                disabled={imageUrlObjectArray.length === 0}
              >
                View Vehicle photos
              </Button>

              {isImageOpen && (
                <LightBoxComponent
                  imageUrlObjectArray={imageUrlObjectArray}
                  setIsImageOpen={setIsImageOpen}
                  screenType="others"
                />
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>
        <Divider style={{ marginTop: 0 }} />
        <h3>Details</h3>
        <Table<ItemsShipment>
          dataSource={inwardShipmentData ? inwardShipmentData.items : []}
          rowKey="id"
          style={{ marginTop: '10px', marginBottom: '20px', width: '100%' }}
          bordered
          size="small"
          pagination={{ showSizeChanger: true }}
        >
          <Table.Column<ItemsShipment>
            title="Details"
            dataIndex="details"
            key="details"
            render={(text, record, index) => {
              return (
                <>
                  <div style={{ fontWeight: 'bold' }}>Raw Material</div>
                  {record.rawMaterial && record.rawMaterial.name}
                  <div style={{ fontWeight: 'bold' }}>Paddy Grade</div>
                  {record.paddyGrade}
                  <div style={{ fontWeight: 'bold' }}>Seller</div>
                  {record.seller && record.seller.name}
                  <div style={{ fontWeight: 'bold' }}>Unloading location</div>
                  {getLoadingLocation(record)}
                  <div style={{ fontWeight: 'bold' }}>No. of bags</div>
                  {record.bagsCount}
                  {record.materialReturned !== true && (
                    <FormItem>
                      <InputNumber
                        name={`inwardShipmentItems.${index}.bagsCount`}
                        defaultValue={record.bagsCount ? record.bagsCount : 0}
                        rhfControllerProps={{
                          control,
                        }}
                        customStyles={{ width: '60px', display: 'none' }}
                      />
                    </FormItem>
                  )}
                </>
              );
            }}
          />
          <Table.Column<ItemsShipment>
            title="Cost Of Empty Bag (per unit)"
            dataIndex="emptyBagCost"
            key="emptyBagCost"
            align="center"
            render={(text, record, index) => {
              if (record.materialReturned === true) {
                return null;
              }
              const { emptyBagsReturned } = record;
              if (emptyBagsReturned === null || emptyBagsReturned === false) {
                return (
                  <div style={{ paddingTop: '55px' }}>
                    Empty bags not Returned.
                    <FormItem>
                      <InputNumber
                        name={`inwardShipmentItems.${index}.emptyBagCost`}
                        defaultValue={0.0}
                        rhfControllerProps={{
                          control,
                        }}
                        customStyles={{ width: '140px', display: 'none' }}
                        inputNumberProps={{ min: 0 }}
                      />
                    </FormItem>
                  </div>
                );
              }
              return (
                <div style={{ paddingTop: '23px' }}>
                  Empty bags Returned.
                  <FormItem
                    labelColSpan={0}
                    customStyle={{ paddingTop: 0, justifyContent: 'center', marginTop: '5px' }}
                  >
                    <InputNumber
                      name={`inwardShipmentItems.${index}.emptyBagCost`}
                      placeholder="Enter cost of empty bag"
                      onChange={(value) => {
                        /* extracting values from watch data function */
                        const {
                          bagWeightType,
                          brokeragePricePerQuintal,
                          emptyBagWtKg,
                          pricePerQuintal,
                        } = watchDataFunction(index);

                        /* stored calculated subtotal value */
                        const subTotalValue = subTotalCalculationFunction(
                          record,
                          index,
                          value,
                          pricePerQuintal ? pricePerQuintal : 0,
                          brokeragePricePerQuintal ? brokeragePricePerQuintal : 0,
                          emptyBagWtKg ? emptyBagWtKg : 0,
                          bagWeightType ? bagWeightType : '',
                        );

                        /* inwardShipmentItemData stored inward shipment  single item data  */
                        const inwardShipmentItemData = watch(`inwardShipmentItems.${index}`);

                        /* setValue of  inwardShipmentItems */
                        setValue(`inwardShipmentItems.${index}`, {
                          ...inwardShipmentItemData,
                          emptyBagCost: value || 0,
                          subTotal: subTotalValue ? subTotalValue : 0,
                        });
                      }}
                      rhfControllerProps={{
                        control,
                      }}
                      customStyles={{ ...inputComponentCommonStyle, fontSize: '10px' }}
                      inputNumberProps={{ min: 0 }}
                    />
                  </FormItem>
                </div>
              );
            }}
          />
          <Table.Column<ItemsShipment>
            title="Empty Bag Weight (in kgs)"
            dataIndex="emptyBagsWeight"
            key="emptyBagsWeight"
            width="220px"
            align="center"
            render={(text, record, index) => {
              if (record.materialReturned === true) {
                return null;
              }
              return (
                <>
                  <FormItem
                    labelColSpan={0}
                    customStyle={{ paddingTop: 0, justifyContent: 'center' }}
                  >
                    <RadioGroup
                      name={`inwardShipmentItems.${index}.bagWeightType`}
                      displayMode="row"
                      rhfControllerProps={{
                        control,
                        defaultValue: 'kgPerQuintal',
                      }}
                      onChange={(e) => {
                        /* extracting values from watch data function */
                        const {
                          brokeragePricePerQuintal,
                          emptyBagCost,
                          emptyBagWtKg,
                          pricePerQuintal,
                        } = watchDataFunction(index);

                        /* stored calculated subtotal value */
                        const subTotalValue = subTotalCalculationFunction(
                          record,
                          index,
                          emptyBagCost ? emptyBagCost : 0,
                          pricePerQuintal ? pricePerQuintal : 0,
                          brokeragePricePerQuintal ? brokeragePricePerQuintal : 0,
                          emptyBagWtKg ? emptyBagWtKg : 0,
                          e.target.value as string,
                        );

                        /* stored calculated net Material Weight value */
                        const netMaterialWeightValue = netMaterialWeightCalculationFunction(
                          record,
                          index,
                          emptyBagWtKg ? emptyBagWtKg : 0,
                          e.target.value as string,
                        );

                        /* inwardShipmentItemData stored inward shipment  single item data  */
                        const inwardShipmentItemData = watch(`inwardShipmentItems.${index}`);

                        /* setValue of  inwardShipmentItems */
                        setValue(`inwardShipmentItems.${index}`, {
                          ...inwardShipmentItemData,
                          netMaterialWtPerBagKg: netMaterialWeightValue
                            ? netMaterialWeightValue
                            : 0,
                          bagWeightType: e.target.value as string,
                          subTotal: subTotalValue ? subTotalValue : 0,
                        });
                      }}
                      options={[
                        { label: 'Per Quintal', value: 'kgPerQuintal' },
                        {
                          label: 'Bag',
                          value: 'kgPerBag',
                        },
                      ]}
                    />
                  </FormItem>
                  <FormItem
                    labelColSpan={0}
                    customStyle={{ paddingTop: 0, justifyContent: 'center', marginTop: '5px' }}
                  >
                    <InputNumber
                      name={`inwardShipmentItems.${index}.emptyBagWtKg`}
                      placeholder="Enter empty bags weight"
                      rhfControllerProps={{
                        control,
                      }}
                      onChange={(value) => {
                        /* extracting values from watch data function */
                        const {
                          bagWeightType,
                          brokeragePricePerQuintal,
                          emptyBagCost,
                          pricePerQuintal,
                        } = watchDataFunction(index);

                        /* stored calculated net Material Weight value */
                        const netMaterialWeightValue = netMaterialWeightCalculationFunction(
                          record,
                          index,
                          value,
                          bagWeightType ? bagWeightType : '',
                        );

                        /* stored calculated subtotal value */
                        const subTotalValue = subTotalCalculationFunction(
                          record,
                          index,
                          emptyBagCost ? emptyBagCost : 0,
                          pricePerQuintal ? pricePerQuintal : 0,
                          brokeragePricePerQuintal ? brokeragePricePerQuintal : 0,
                          value,
                          bagWeightType ? bagWeightType : '',
                        );

                        /* inwardShipmentItemData stored inward shipment single item data  */
                        const inwardShipmentItemData = watch(`inwardShipmentItems.${index}`);

                        /* setValue of  inwardShipmentItems */
                        setValue(`inwardShipmentItems.${index}`, {
                          ...inwardShipmentItemData,
                          netMaterialWtPerBagKg: netMaterialWeightValue
                            ? netMaterialWeightValue
                            : 0,
                          emptyBagWtKg: value || 0,
                          subTotal: subTotalValue ? subTotalValue : 0,
                        });
                      }}
                      customStyles={{ ...inputComponentCommonStyle, fontSize: '10px' }}
                      inputNumberProps={{ min: 0 }}
                    />
                  </FormItem>
                </>
              );
            }}
          />
          <Table.Column<ItemsShipment>
            title="Net Material Weight (in kgs/bag)"
            dataIndex="netMaterialWeight"
            key="netMaterialWeight"
            width="160px"
            align="center"
            render={(text, record, index) => {
              if (record.materialReturned === true) {
                return null;
              }
              /* stored net material weight per bag kg value */
              const netMaterialWtPerBagKgValue = watch(
                `inwardShipmentItems.${index}.netMaterialWtPerBagKg`,
              );

              if (netMaterialWtPerBagKgValue) {
                return netMaterialWtPerBagKgValue.toFixed(2);
              }
            }}
          />
          <Table.Column<ItemsShipment>
            title="Total Net material weight (in quintals)"
            dataIndex="totalNetMaterialWeight"
            key="totalNMaterialWeight"
            width="160px"
            align="center"
            render={(text, record, index) => {
              if (record.materialReturned === true) {
                return null;
              }
              // const use to store actual material wt in a single bag.
              const netActualMaterialWtPerBagKgValue = watch(
                `inwardShipmentItems.${index}.netMaterialWtPerBagKg`,
              );

              if (netActualMaterialWtPerBagKgValue && record.bagsCount) {
                // cont use to store sum of all netActualMaterialWt for selected item's bags count in kg
                const totalNetActualMaterialWtInKg =
                  netActualMaterialWtPerBagKgValue * record.bagsCount;

                // cont use to store sum of all netActualMaterialWt for selected item's bags count in quintal
                const totalNetActualMaterialWtInQuintal = convertKgsToQuintals(
                  totalNetActualMaterialWtInKg,
                );
                return totalNetActualMaterialWtInQuintal.toFixed(2);
              }
            }}
          />
          <Table.Column<ItemsShipment>
            title="Price Of Paddy (Rs/quintal)"
            dataIndex="pricePerQuintal"
            key="pricePerQuintal"
            align="center"
            render={(text, record, index) => {
              if (record.materialReturned === true) {
                return null;
              }
              return (
                <FormItem
                  labelColSpan={0}
                  customStyle={{ paddingTop: 3, justifyContent: 'center' }}
                >
                  <InputNumber
                    name={`inwardShipmentItems.${index}.pricePerQuintal`}
                    placeholder="Enter price of paddy"
                    rhfControllerProps={{
                      control,
                    }}
                    onChange={(value) => {
                      /* extracting values from watch data function */
                      const {
                        bagWeightType,
                        brokeragePricePerQuintal,
                        emptyBagCost,
                        emptyBagWtKg,
                      } = watchDataFunction(index);

                      /* stored calculated subtotal value */
                      const subTotalValue = subTotalCalculationFunction(
                        record,
                        index,
                        emptyBagCost ? emptyBagCost : 0,
                        value,
                        brokeragePricePerQuintal ? brokeragePricePerQuintal : 0,
                        emptyBagWtKg ? emptyBagWtKg : 0,
                        bagWeightType ? bagWeightType : '',
                      );

                      /* inwardShipmentItemData stored inward shipment single item data  */
                      const inwardShipmentItemData = watch(`inwardShipmentItems.${index}`);

                      /* setValue of  inwardShipmentItems */
                      setValue(`inwardShipmentItems.${index}`, {
                        ...inwardShipmentItemData,
                        pricePerQuintal: value || 0,
                        subTotal: subTotalValue ? subTotalValue : 0,
                      });
                    }}
                    customStyles={{ ...inputComponentCommonStyle, fontSize: '10px' }}
                    inputNumberProps={{ min: 0 }}
                  />
                </FormItem>
              );
            }}
          />
          <Table.Column<ItemsShipment>
            title="Brokerage (Rs/quintal)"
            dataIndex="brokeragePricePerQuintal"
            key="brokeragePricePerQuintal"
            align="center"
            render={(text, record, index) => {
              if (record.materialReturned === true) {
                return null;
              }
              return (
                <FormItem
                  labelColSpan={0}
                  customStyle={{ paddingTop: 3, justifyContent: 'center' }}
                >
                  <InputNumber
                    name={`inwardShipmentItems.${index}.brokeragePricePerQuintal`}
                    placeholder="Enter brokerage"
                    rhfControllerProps={{
                      control,
                    }}
                    onChange={(value: number) => {
                      /* extracting values from watch data function */
                      const { bagWeightType, emptyBagCost, emptyBagWtKg, pricePerQuintal } =
                        watchDataFunction(index);

                      /* stored calculated subtotal value */
                      const subTotalValue = subTotalCalculationFunction(
                        record,
                        index,
                        emptyBagCost ? emptyBagCost : 0,
                        pricePerQuintal ? pricePerQuintal : 0,
                        value,
                        emptyBagWtKg ? emptyBagWtKg : 0,
                        bagWeightType ? bagWeightType : '',
                      );

                      /* inwardShipmentItemData stored inward shipment single item data  */
                      const inwardShipmentItemData = watch(`inwardShipmentItems.${index}`);

                      /* setValue of  inwardShipmentItems */
                      setValue(`inwardShipmentItems.${index}`, {
                        ...inwardShipmentItemData,
                        brokeragePricePerQuintal: value || 0,
                        subTotal: subTotalValue ? subTotalValue : 0,
                      });
                    }}
                    customStyles={{ ...inputComponentCommonStyle, fontSize: '10px' }}
                    inputNumberProps={{ min: 0 }}
                  />
                </FormItem>
              );
            }}
          />
          <Table.Column<ItemsShipment>
            title="Sub Total (Rs.)"
            dataIndex="subtotal"
            key="subtotal"
            width="140px"
            align="center"
            render={(text, record, index) => {
              /* stored subTotal value */
              const subTotal = watch(`inwardShipmentItems.${index}.subTotal`);
              let valueSubtotal = 0;
              if (subTotal) {
                valueSubtotal = subTotal;
              }

              if (record.materialReturned !== true) {
                return <>{valueSubtotal.toFixed(2)}</>;
              }
              return null;
            }}
          />
        </Table>
        <Row style={{ marginTop: 20, marginBottom: 30 }}>
          <Col span={21}>
            <span style={{ fontSize: '20px' }}>Net Total Amount :</span>
            <span style={{ fontSize: '20px' }}> {getTotalAmountFunction().toFixed(2)} Rupees</span>
          </Col>
          <Col style={{ marginLeft: '40px' }}>
            {user && user.role === 'admin' ? (
              <>
                <Popconfirm
                  title="Please make sure you have entered all relevant details before proceeding."
                  okText="Yes"
                  cancelText="No"
                  icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                  onConfirm={() => {
                    if (inwardShipmentData && inwardShipmentData.status === 'adminOpen') {
                      if (!isValid) {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        message.error('Form incomplete. Review your form and submit again.');
                      }
                    } else {
                      if (
                        !watch('laborChargePerBag') &&
                        !watch('laborChargeType') &&
                        !watch('weighbridgeCharges')
                      ) {
                        // eslint-disable-next-line @typescript-eslint/no-floating-promises
                        message.error('Form incomplete. Review your form and submit again.');
                      }
                    }
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    onSubmit();
                  }}
                >
                  <Button type="primary" loading={isCompleteGrnBtnLoading}>
                    Complete GRN
                  </Button>
                </Popconfirm>
              </>
            ) : (
              <Button
                type="primary"
                loading={isCompleteGrnBtnLoading}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  onSubmit();
                  navigate(`/shipment/admin/inward/create`);
                }}
              >
                Save GRN
              </Button>
            )}
          </Col>
        </Row>
      </form>
      <Modal
        visible={modalIsVisible}
        className="printModalLayoutStyle"
        footer={[
          <span className="hideInPrint" style={{ marginRight: 20, fontWeight: 'bold' }}>
            Do you wish to print ?
          </span>,
          <Button
            type="primary"
            key="print"
            className="hideInPrint"
            onClick={() => {
              handlePrint();
            }}
          >
            Print
          </Button>,
          <Button
            type="default"
            key="skip"
            className="hideInPrint"
            onClick={() => {
              if (closeModal) {
                closeModal();
              } else if (inwardShipmentData && inwardShipmentData.status === 'adminOpen') {
                navigate(`/shipment/admin/inward/create`);
              } else {
                navigate(`/shipment/admin/inward/review`);
              }
              setModalIsVisible(false);
              if (refetch) {
                refetch();
              }
            }}
          >
            Skip
          </Button>,
        ]}
        onCancel={() => {
          if (closeModal) {
            closeModal();
          } else if (inwardShipmentData && inwardShipmentData.status === 'adminOpen') {
            navigate(`/shipment/admin/inward/create`);
          } else {
            navigate(`/shipment/admin/inward/review`);
          }
          setModalIsVisible(false);
        }}
        width={1200}
      >
        {inwardShipmentData ? (
          <PurchaseBillPreviewModal
            previewData={inwardShipmentData}
            formData={formData()}
            millDetails={
              getInwardShipmentByIdData &&
              getInwardShipmentByIdData.mills &&
              getInwardShipmentByIdData.mills[0]
            }
            printReference={printRef}
          />
        ) : null}
      </Modal>
    </div>
  );
};

export default ProcessGRNScreen;
