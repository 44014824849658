import { Button, Modal, message } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Brokers,
  CreateBrokerMutation,
  CreateBrokerMutationVariables,
} from '../graphql/graphql-types';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { inputComponentCommonStyle } from '../utils/globals';
import FormItem from './FormItem';
import * as yup from 'yup';
import Input from './Input';
import { yupResolver } from '@hookform/resolvers/yup';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation } from '@apollo/client';
import { logger } from '../utils/helpers';
import { useLocation } from 'react-router-dom';

const createBrokerMutation = loader('../graphql/mutations/createBrokerMutation.graphql');

const getAllBrokersQuery = loader('../graphql/queries/getAllBrokersQuery.graphql');

const getShipmentOrdersQuery = loader('../graphql/queries/getShipmentOrdersQuery.graphql');

type QuickAddBrokerPropType = {
  /* this prop type used to handle modal visibility */
  isModalOpen: boolean;
  /* prop type used to close form modal */
  closeModal: () => void;
};

type QuickAddBrokerFormPropType = Pick<Brokers, 'gst' | 'mobile' | 'name'>;

const schema = yup.object({
  name: yup
    .string()
    .required('Please enter broker name and try again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  gst: yup.string().matches(validationRegex.gstin, {
    message: 'Please enter a valid GSTIN',
    excludeEmptyString: true,
  }),
  mobile: yup.string().matches(validationRegex.mobile, {
    message: 'Please enter a valid mobile number',
    excludeEmptyString: true,
  }),
});

/* formItem component styling props */
const formItemStyleProps = {
  /* label column span of FormItem */
  labelColSpan: 7,
  /* input column span of FormItem */
  inputColSpan: 17,
  /* style prop type to determine place where require mark '*' show on input field. (Before or after input field)*/
  requiredMark: 'after' as 'after' | 'before',
};

const QuickAddBrokerForm = ({ closeModal, isModalOpen }: QuickAddBrokerPropType) => {
  /* state to set the loading condition of button */
  const [isAddBrokerBtnLoading, setIsAddBrokerBtnLoading] = useState<boolean>(false);

  const [createBroker] = useMutation<CreateBrokerMutation, CreateBrokerMutationVariables>(
    createBrokerMutation,
  );

  const location = useLocation();

  const isPathIncludesOtherItems = location.pathname.includes('otherItems');

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<QuickAddBrokerFormPropType>({
    defaultValues: {
      name: '',
      gst: '',
      mobile: '',
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  /* function to handle error while creating or updating brokers */
  const errorHandlingForBrokerForm = (createAndUpdateBrokerError: ApolloError) => {
    if (
      createAndUpdateBrokerError.message ===
      'Uniqueness violation. duplicate key value violates unique constraint "brokers_gst_key"'
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('GST number is already registered');
    } else if (
      createAndUpdateBrokerError.message ===
      'Uniqueness violation. duplicate key value violates unique constraint "brokers_mobile_key"'
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error('Mobile number is already registered');
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      message.error(createAndUpdateBrokerError.message);
    }
  };

  /* function to handle submitted data */
  const onSubmit = handleSubmit((data: QuickAddBrokerFormPropType) => {
    setIsAddBrokerBtnLoading(true);

    createBroker({
      variables: {
        name: data.name,
        gst: data.gst || null,
        mobile: data.mobile || null,
      },

      refetchQueries: [
        {
          query: isPathIncludesOtherItems ? getAllBrokersQuery : getShipmentOrdersQuery,
        },
      ],
    })
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Broker has been added successfully.');
        reset();
        setIsAddBrokerBtnLoading(false);
        closeModal();
      })
      .catch((error: ApolloError) => {
        errorHandlingForBrokerForm(error);
        setIsAddBrokerBtnLoading(false);
        logger(error);
      });
  });

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={onSubmit}
    >
      <Modal
        visible={isModalOpen}
        title="Add Broker"
        footer={[
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <Button type="primary" key="add" onClick={onSubmit} loading={isAddBrokerBtnLoading}>
            Add Broker
          </Button>,
          <Button
            type="default"
            key="cancel"
            onClick={() => {
              closeModal();
              reset();
            }}
          >
            Cancel
          </Button>,
        ]}
        onCancel={() => {
          closeModal();
        }}
        destroyOnClose
      >
        <FormItem
          label="Broker Name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter name of broker"
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="GST"
          {...formItemStyleProps}
          errorText={errors && errors.gst ? errors.gst.message : undefined}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter GST of broker"
            name="gst"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Mobile"
          errorText={errors && errors.mobile ? errors.mobile.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter mobile number of broker"
            name="mobile"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
      </Modal>
    </form>
  );
};

export default QuickAddBrokerForm;
