import { Col, Row } from 'antd';
import React from 'react';
import seal from '../../src/assets/images/seal_sarda_group.png';
import { useLocation } from 'react-router-dom';

/* type definition for signature component */
type SignatureComponentPropType = {
  /* prop type for mill name */
  millName?: string;
};

const SignatureComponent = ({ millName }: SignatureComponentPropType) => {
  const location = useLocation();

  const isPurchaseOrderPrint = location.pathname.includes('purchaseOrders/print');

  return (
    <>
      <Row
        style={{
          textAlign: 'center',
          alignItems: 'end',
          marginTop: 30,
        }}
        className="boldContentInPrint"
      >
        <Col offset={17} span={3}>
          <img src={seal} alt="seal" width={80} height={72} />
        </Col>
        <Col span={4}>_______________________</Col>
      </Row>

      <Row
        style={{
          textAlign: 'center',
          fontWeight: 500,
        }}
        className="boldContentInPrint"
      >
        <Col offset={17} span={3}>
          Verified by
        </Col>
        <Col span={4}>
          <span>
            {isPurchaseOrderPrint ? 'Authorized Signatory Name & Sign' : 'Authorized Signatory'}
          </span>
        </Col>
      </Row>
      {isPurchaseOrderPrint ? (
        <Row
          style={{
            textAlign: 'center',
            fontWeight: 500,
          }}
          className="boldContentInPrint"
        >
          <Col offset={20} span={4}>
            {millName}
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default SignatureComponent;
