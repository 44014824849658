import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, message, Modal, Popconfirm, Spin, Table } from 'antd';
import { loader } from 'graphql.macro';
import { ApolloError, useMutation, useQuery, useLazyQuery, Reference } from '@apollo/client';
import RequiredMessage from '../../../components/RequiredMessage';
import FormItem from '../../../components/FormItem';
import { inputComponentCommonStyle, formItemStyleProps } from '../../../utils/globals';
import { logger } from '../../../utils/helpers';
import Select from '../../../components/Select';
import {
  Enum_Item_Category_Enum,
  Godowns,
  InternalTransferOpenShipmentsQuery,
  InternalTransferOpenShipmentsQueryVariables,
  InternalTransferShipmentItems,
  InternalTransferShipments,
  InternalTransferShipmentsItemsQuery,
  InternalTransferShipmentsItemsQueryVariables,
  DeleteInternalTransferShipmentItemMutation,
  DeleteInternalTransferShipmentItemMutationVariables,
  Mills,
  RawMaterials,
  UpdateInternalTransferShipmentMutation,
  UpdateInternalTransferShipmentMutationVariables,
} from '../../../graphql/graphql-types';
import { ExclamationCircleFilled, WarningFilled } from '@ant-design/icons';
import colors from '../../../scss/variables.module.scss';
import Input from '../../../components/Input';
import {
  CreateOrUpdateInternalShipmentItemFormType,
  PaddyOrProductInternalTransferShipmentType,
} from '../../../utils/types';
import CreateOrEditInternalShipmentItemForm from './CreateOrEditInternalShipmentItemForm';

/* internal shipment material unloading form type */
type InternalShipmentItemMaterialUnloadingFormType = Pick<InternalTransferShipments, 'grn'> & {
  /* prop type used to store 'id' of the shipment whose vehicle number get selected from 'select vehicle' dropdown */
  shipmentIdFromVehicleNumber: string | null;
};

/* internal transfer shipment item table type */
type InternalTransferShipmentItemTableType = Pick<
  InternalTransferShipmentItems,
  | 'sourceGodownId'
  | 'destinationGodownId'
  | 'sourceMillId'
  | 'destinationMillId'
  | 'rawMaterialId'
  | 'bagsCount'
  | 'destination'
  | 'source'
  | 'rmPaddyGrade'
  | 'id'
> & { rmEmptyBagsWtKg?: number | null } & {
  rawMaterial?: Pick<RawMaterials, 'id' | 'name'> | null | undefined;
} & {
  sourceGodown?: Pick<Godowns, 'id' | 'name'> | null | undefined;
} & {
  sourceMill?: Pick<Mills, 'id' | 'name'> | null | undefined;
} & {
  destinationGodown?: Pick<Godowns, 'id' | 'name'> | null | undefined;
} & {
  destinationMill?: Pick<Mills, 'id' | 'name'> | null | undefined;
};

/* loading get open internal transfer shipment query */
const getInternalTransferOpenShipmentsQuery = loader(
  '../../../graphql/queries/internalTransferOpenShipmentsQuery.graphql',
);

/* loading get open internal transfer shipment items query */
const getInternalTransferOpenShipmentItemsQuery = loader(
  '../../../graphql/queries/internalTransferShipmentsItemsQuery.graphql',
);

/* loading delete internal shipment item mutation  */
const deleteInternalShipmentItemMutation = loader(
  '../../../graphql/mutations/deleteInternalTransferShipmentItemMutation.graphql',
);

/* loading finish internal transfer shipment query  */
const finishInternalTransferShipmentQuery = loader(
  '../../../graphql/queries/internalTransferShipmentsForFinishingQuery.graphql',
);

/* loading finish internal transfer shipment mutation  */
const updateInternalTransferShipmentMutation = loader(
  '../../../graphql/mutations/updateInternalTransferShipmentMutation.graphql',
);

/* react functional component */
const InternalShipmentMaterialUnloadingScreen = () => {
  /* state used to store id of the shipment item whose delete button is clicked which is then used to show loading indicator on delete button while deleting a shipment */
  const [shipmentItemIdToDltAndShowLoading, setShipmentItemIdToDltAndShowLoading] = useState<
    string | null
  >(null);

  /* state used to store data of the shipment item whose edit button is clicked which is then used to update that shipment item data & maintain modal visibility */
  const [shipmentItemDataAndModalVisible, setShipmentItemDataAndModalVisible] =
    useState<CreateOrUpdateInternalShipmentItemFormType | null>(null);

  /* this state used to show loading indicator on finish shipment button */
  const [isShipmentFinishBtnLoading, setIsShipmentFinishBtnLoading] = useState<boolean>(false);

  /* get all open internal shipments query used, to show vehicle numbers list as an options on 'vehicle number' field */
  const {
    data: getOpenInternalShipmentData,
    loading: getOpenInternalShipmentDataLoading,
    error: getOpenInternalShipmentDataError,
  } = useQuery<InternalTransferOpenShipmentsQuery, InternalTransferOpenShipmentsQueryVariables>(
    getInternalTransferOpenShipmentsQuery,
    { variables: { materialType: Enum_Item_Category_Enum.RawMaterial } },
  );

  /* get all internal shipment items query used to show internal shipment items data into table */
  const [
    getInternalShipmentItems,
    {
      data: getInternalShipmentItemsData,
      error: getInternalShipmentItemsDataError,
      loading: getInternalShipmentItemsDataLoading,
    },
  ] = useLazyQuery<
    InternalTransferShipmentsItemsQuery,
    InternalTransferShipmentsItemsQueryVariables
  >(getInternalTransferOpenShipmentItemsQuery);

  /* delete internal shipment item mutation */
  const [deleteInternalShipmentItem] = useMutation<
    DeleteInternalTransferShipmentItemMutation,
    DeleteInternalTransferShipmentItemMutationVariables
  >(deleteInternalShipmentItemMutation);

  /* finish internal transfer shipment mutation */
  const [updateInternalTransferShipment] = useMutation<
    UpdateInternalTransferShipmentMutation,
    UpdateInternalTransferShipmentMutationVariables
  >(updateInternalTransferShipmentMutation);

  /* useForm declaration */
  const { control, reset, setValue, watch } =
    useForm<InternalShipmentItemMaterialUnloadingFormType>({
      defaultValues: {
        grn: null,
        shipmentIdFromVehicleNumber: null,
      },
      mode: 'onChange',
    });

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getOpenInternalShipmentDataError || getInternalShipmentItemsDataError) {
    return (
      <div className="errorText">
        {(getOpenInternalShipmentDataError && getOpenInternalShipmentDataError.message) ||
          (getInternalShipmentItemsDataError && getInternalShipmentItemsDataError.message)}
      </div>
    );
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getInternalShipmentItemsDataLoading) {
    <Spin className="loadingSpinner" />;
  }

  /* function to handle delete open internal shipment item */
  const deleteInternalShipmentItemFunc = (shipmentId: string) => {
    setShipmentItemIdToDltAndShowLoading(shipmentId);
    deleteInternalShipmentItem({
      variables: {
        id: shipmentId,
      },
      /* after deleting a shipment item, the update function is used to update the cache and display an updated shipment items array. */
      update(cache, { data: deleteData }) {
        /* using cache data, const to store the id of a shipment item that was just removed. */
        const deletedDataId = deleteData?.delete_internalTransferShipmentItems_by_pk?.id;
        cache.modify({
          fields: {
            internalTransferShipmentItems(
              existingInternalShipmentItems: Array<Reference>,
              { readField },
            ) {
              if (deletedDataId) {
                return existingInternalShipmentItems.filter(
                  (shipmentRef) => deletedDataId !== readField('id', shipmentRef),
                );
              }
              return existingInternalShipmentItems;
            },
          },
        });
      },
    })
      .then(() => {
        setShipmentItemIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.success('Shipment has been deleted successfully.');
      })
      .catch((error: ApolloError) => {
        setShipmentItemIdToDltAndShowLoading(null);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        message.error(error.message);
        logger(error);
      });
  };

  /* this const used to store shipment, which is selected to unload using it's 'vehicle number' */
  const shipmentSelectedToUnload =
    getOpenInternalShipmentData &&
    getOpenInternalShipmentData.internalTransferShipments.find(
      (item) => item.id === watch('shipmentIdFromVehicleNumber'),
    );

  /* function to handle finish internal shipment */
  const finishInternalTransferShipmentFunc = () => {
    setIsShipmentFinishBtnLoading(true);

    /* destructing shipment data */
    if (shipmentSelectedToUnload) {
      const {
        id,
        personName,
        firstVehicleWtKg,
        vehicleNumber,
        firstVehicleImage,
        firstVehicleWtTakenAt,
      } = shipmentSelectedToUnload as PaddyOrProductInternalTransferShipmentType;

      updateInternalTransferShipment({
        variables: {
          id,
          personName: personName as string,
          firstVehicleWtKg,
          vehicleNumber: vehicleNumber as string,
          firstVehicleImage,
          detailsEnteredAt: new Date(),
          materialType: Enum_Item_Category_Enum.RawMaterial,
          firstVehicleWtTakenAt: firstVehicleWtTakenAt as Date,
        },
        /* after unloading material successfully, refetch open shipments & finish shipment query to get updated open shipments list */
        refetchQueries: [
          getInternalTransferOpenShipmentsQuery,
          finishInternalTransferShipmentQuery,
        ].map((query) => ({
          query: query,
          variables: { materialType: 'raw_material' },
        })),
      })
        .then(() => {
          setIsShipmentFinishBtnLoading(false);
          reset();
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('Shipment has been successfully unloaded.');
        })
        .catch((error: ApolloError) => {
          setIsShipmentFinishBtnLoading(false);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(error.message);
          logger(error);
        });
    }
  };

  return (
    <>
      {shipmentItemDataAndModalVisible && shipmentSelectedToUnload ? (
        <Modal
          visible={shipmentItemDataAndModalVisible ? true : false}
          title="Edit Shipment Item"
          footer={null}
          className="editInternalShipmentModal"
          onCancel={() => {
            setShipmentItemDataAndModalVisible(null);
          }}
          width={670}
          destroyOnClose
        >
          <CreateOrEditInternalShipmentItemForm
            mode="edit"
            shipmentItemDataToEdit={shipmentItemDataAndModalVisible}
            shipmentIdSelectedToUnload={shipmentSelectedToUnload.id}
            closeModal={() => {
              setShipmentItemDataAndModalVisible(null);
            }}
          />
        </Modal>
      ) : null}
      <form>
        <RequiredMessage />
        <FormItem label="Select Vehicle" isRequired requiredMark="after" {...formItemStyleProps}>
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select vehicle number"
            name="shipmentIdFromVehicleNumber"
            rhfControllerProps={{
              control,
            }}
            selectProps={{
              showSearch: true,
              loading: getOpenInternalShipmentDataLoading,
              optionFilterProp: 'children',
            }}
            options={
              getOpenInternalShipmentData
                ? getOpenInternalShipmentData.internalTransferShipments.map((item) => ({
                    value: item.id,
                    label: item.vehicleNumber as string,
                  }))
                : []
            }
            onChange={(rhfOnChange, value: string) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              rhfOnChange(value);
              if (value && getOpenInternalShipmentData) {
                /* this const used to store shipment, which is selected to unload using it's 'vehicle number' */
                const selectedShipment = getOpenInternalShipmentData.internalTransferShipments.find(
                  (item) => item.id === value,
                );

                /* if user has selected any shipment to unload, then get shipment items of that respective shipment */
                if (selectedShipment) {
                  /* set grn number to shipment's grn number */
                  setValue('grn', selectedShipment.grn);
                  // setShipmentSelectedToUnload(selectedShipment);
                  /* call 'getInternalShipmentItems' to get open shipment items */
                  void getInternalShipmentItems({
                    variables: { _internalTransferShipmentId: selectedShipment.id },
                  });
                }
              }
            }}
          />
        </FormItem>
        {watch('shipmentIdFromVehicleNumber') ? (
          <FormItem label="GRN" {...formItemStyleProps}>
            <Input
              customStyles={inputComponentCommonStyle}
              name="grn"
              placeholder="Please enter grn number"
              rhfControllerProps={{
                control,
              }}
              inputProps={{ disabled: true }}
            />
          </FormItem>
        ) : null}
      </form>
      {watch('shipmentIdFromVehicleNumber') && shipmentSelectedToUnload ? (
        <>
          <CreateOrEditInternalShipmentItemForm
            mode="add"
            shipmentIdSelectedToUnload={shipmentSelectedToUnload.id}
          />
          <h3>Shipments Items</h3>
          <Table<InternalTransferShipmentItemTableType>
            dataSource={
              getInternalShipmentItemsData &&
              getInternalShipmentItemsData.internalTransferShipmentItems
            }
            loading={getOpenInternalShipmentDataLoading}
            className="tableStyle"
            bordered
            size="small"
            pagination={{ showSizeChanger: true }}
          >
            <Table.Column<InternalTransferShipmentItemTableType>
              title="Material Type"
              dataIndex="materialType"
              key="materialType"
              align="center"
              width={200}
              render={(text, record) => {
                return (record.rawMaterial && record.rawMaterial.name) || '-';
              }}
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              title="Paddy Grade"
              dataIndex="rmPaddyGrade"
              key="rmPaddyGrade"
              align="center"
              width={120}
              render={(text, record) => record.rmPaddyGrade || '-'}
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              title="No. Of Bags"
              dataIndex="bagsCount"
              key="bagsCount"
              align="center"
              width={120}
              render={(text, record) => record.bagsCount || '-'}
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              title="Empty bag weight (in kgs)"
              dataIndex="rmEmptyBagsWtKg"
              align="center"
              width={120}
              key="rmEmptyBagsWtKg"
              render={(text, record) => record.rmEmptyBagsWtKg || '-'}
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              title="Source"
              dataIndex="source"
              key="source"
              align="center"
              width={250}
              render={(source, record) => {
                return (
                  <>
                    {source === 'godown' ? (
                      <>
                        <span style={{ fontWeight: 'bold' }}>Godown: </span>
                        {(record.sourceGodown && record.sourceGodown.name) || '-'}
                      </>
                    ) : (
                      <>
                        <span style={{ fontWeight: 'bold', textAlign: 'left' }}>Mill: </span>
                        {(record.sourceMill && record.sourceMill.name) || '-'}
                      </>
                    )}
                  </>
                );
              }}
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              title="Destination"
              dataIndex="destination"
              key="destination"
              align="center"
              width={270}
              render={(destination, record) => {
                return (
                  <>
                    {destination === 'godown' ? (
                      <>
                        <span style={{ fontWeight: 'bold' }}>Godown: </span>
                        {(record.destinationGodown && record.destinationGodown.name) || '-'}
                      </>
                    ) : (
                      <>
                        <span style={{ fontWeight: 'bold', textAlign: 'left' }}>Mill: </span>
                        {(record.destinationMill && record.destinationMill.name) || '-'}
                      </>
                    )}
                  </>
                );
              }}
            />
            <Table.Column<InternalTransferShipmentItemTableType>
              key="Actions"
              title="Actions"
              dataIndex="actions"
              align="center"
              width={200}
              render={(text, record) => {
                return (
                  <div className="buttonContainer">
                    <Button
                      onClick={() => {
                        /* destructing record data */
                        const {
                          id,
                          bagsCount,
                          sourceGodown,
                          sourceMill,
                          destinationGodown,
                          destinationMill,
                          destination,
                          rmEmptyBagsWtKg,
                          rmPaddyGrade,
                          rawMaterial,
                          source,
                        } = record;

                        setShipmentItemDataAndModalVisible({
                          id,
                          bagsCount,
                          rmPaddyGrade,
                          sourceGodownId: sourceGodown ? sourceGodown.id : null,
                          sourceMillId: sourceMill ? sourceMill.id : null,
                          destinationGodownId: destinationGodown ? destinationGodown.id : null,
                          destinationMillId: destinationMill ? destinationMill.id : null,
                          rmEmptyBagsWtKg: rmEmptyBagsWtKg ? rmEmptyBagsWtKg : null,
                          destination: destination ? destination : null,
                          source: source ? source : null,
                          rawMaterialId: rawMaterial ? rawMaterial.id : null,
                        });
                      }}
                    >
                      Edit
                    </Button>
                    <Popconfirm
                      title="Delete shipment item. Are you sure?"
                      okText="Yes"
                      onConfirm={() => {
                        deleteInternalShipmentItemFunc(record.id);
                      }}
                      okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
                      cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
                      cancelText="No"
                      icon={<WarningFilled style={{ color: colors.deleteUserIconColor }} />}
                    >
                      <Button
                        className="deleteButton"
                        loading={record.id === shipmentItemIdToDltAndShowLoading ? true : false}
                      >
                        Delete
                      </Button>
                    </Popconfirm>
                  </div>
                );
              }}
            />
          </Table>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 20 }}>
            <Popconfirm
              title="Finish unloading, Are you sure?"
              placement="topLeft"
              okText="Yes"
              onConfirm={() => {
                finishInternalTransferShipmentFunc();
              }}
              okButtonProps={{ style: { borderRadius: 4, marginLeft: 2 } }}
              cancelButtonProps={{ style: { borderRadius: 4, marginRight: 7 } }}
              cancelText="No"
              icon={<ExclamationCircleFilled style={{ color: '#faad14' }} />}
              disabled={
                getInternalShipmentItemsData &&
                Array.isArray(getInternalShipmentItemsData.internalTransferShipmentItems) &&
                getInternalShipmentItemsData.internalTransferShipmentItems.length === 0
              }
            >
              <Button
                type="primary"
                loading={isShipmentFinishBtnLoading}
                disabled={
                  getInternalShipmentItemsData &&
                  Array.isArray(getInternalShipmentItemsData.internalTransferShipmentItems) &&
                  getInternalShipmentItemsData.internalTransferShipmentItems.length === 0
                }
              >
                Finish Unloading
              </Button>
            </Popconfirm>
          </div>
        </>
      ) : null}
    </>
  );
};

export default InternalShipmentMaterialUnloadingScreen;
