import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, message } from 'antd';
import * as yup from 'yup';
import validationRegex from '@eumentis-cloud/js-validation-regex';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, Reference, ApolloError } from '@apollo/client';
import { loader } from 'graphql.macro';
import { statesOptionsForSelect } from '../../../utils/globals';
import { UserDataType } from '../../../utils/types';
import { logger } from '../../../utils/helpers';
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  UserRole,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  Enum_User_Role_Enum,
  Users_Set_Input,
} from '../../../graphql/graphql-types';
import FormItem from '../../../components/FormItem';
import Input from '../../../components/Input';
import RadioGroup from '../../../components/RadioGroup';
import Select from '../../../components/Select';
import DatePicker from '../../../components/DatePicker';
import { inputComponentCommonStyle, formItemStyleProps } from '../../../utils/globals';
import { AddressType } from '../../../utils/types';
import RequiredMessage from '../../../components/RequiredMessage';

// User from props type
type UserFormPropsType = {
  /* prop type used to check whether the user form is being called from  edit mode  or add mode */
  mode: 'edit' | 'add';
  /* prop type used to store initial data of the form , when form is in 'edit' mode */
  userDataToEdit?: UserFormType;
};

/* Add and Edit user form type */
type UserFormType = Omit<UserDataType, 'address' | 'token' | 'updatePassword' | 'id' | 'role'> & {
  /* role of user */
  role: Enum_User_Role_Enum | null;
} & AddressType;

/* loading update user mutation with the help of loader */
const updateUserMutation = loader('../../../graphql/mutations/updateUserMutation.graphql');

/* loading get user by Id query with the help of loader */
const createUserMutation = loader('../../../graphql/mutations/createUserMutation.graphql');

/* validations required for each input field */
const schema = yup.object({
  name: yup
    .string()
    .required('Please enter name and try again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  email: yup.string().email('Please enter a valid email id').nullable(),
  mobile: yup
    .string()
    .required('Please enter mobile number and try again')
    .matches(validationRegex.mobile, {
      message: 'Please enter a valid mobile number',
    }),
  address: yup.string().required('Please enter address and try again'),
  city: yup
    .string()
    .required('Please enter city and try again')
    .matches(/^[aA-zZ\s]+$/, 'Please enter a valid name with alphabets only'),
  state: yup.string().required('Please select state and try again').nullable(),
  pincode: yup
    .string()
    .required('Please enter pincode and try again')
    .matches(validationRegex.pincode, {
      message: 'Please enter a valid pincode',
    }),
  role: yup.string().required('Please select role and try again').nullable(),
});

/* this function used to handle AddOrEditUserForm errors */
const errorHandlerForAddOrEditUserForm = (error: ApolloError): string => {
  if (
    error.message ===
    'Uniqueness violation. duplicate key value violates unique constraint "users_mobile_key"'
  ) {
    return 'Mobile number is already registered';
  }
  return error.message;
};

/* React functional component */
const AddOrEditUserForm = ({ mode, userDataToEdit = undefined }: UserFormPropsType) => {
  /* extracting params from useParams hook */
  const params = useParams();

  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* mutation to add user */
  const [addUser] = useMutation<CreateUserMutation, CreateUserMutationVariables>(
    createUserMutation,
  );

  /* mutation to update user */
  const [updateUser] = useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    updateUserMutation,
  );

  /* state to set the loading condition of button */
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  /* extracting required content from use form hook */
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserFormType>({
    defaultValues:
      mode === 'edit' && userDataToEdit
        ? {
            ...userDataToEdit,
            role: userDataToEdit.role as Enum_User_Role_Enum,
          }
        : {
            name: '',
            email: '',
            mobile: '',
            dateOfBirth: null,
            address: '',
            city: '',
            state: null,
            pincode: '',
            role: null,
          },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  /* function to handle onsubmit data */
  const onSubmit = (data: UserFormType) => {
    setBtnLoader(true);

    /* const used to store mutation variables which are common in both updateUser and addUser mutation */
    const commonMutationVariables = {
      name: data.name,
      mobile: data.mobile,
      address: {
        address: data.address,
        city: data.city,
        state: data.state,
        pincode: data.pincode,
      },
      dob: data.dateOfBirth || null,
      email: data.email || null,
    };

    if (mode === 'add') {
      addUser({
        variables: {
          ...commonMutationVariables,
          role: data.role as unknown as UserRole,
        },
        update(cache, { data: addedUser }) {
          /* extracting the new data added by user */
          const dataToAdd = addedUser?.createUser;
          cache.modify({
            fields: {
              users(existingUsers: Array<Reference>) {
                return [...existingUsers, dataToAdd];
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('User has been added successfully');
          setBtnLoader(false);
          reset();
          navigate('/management/users');
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorHandlerForAddOrEditUserForm(error));
          logger(error);
          setBtnLoader(false);
        });
    } else {
      // variable to store mutation variables that we will pass to edit mutation
      let updateMutationVariables: Users_Set_Input = {
        ...commonMutationVariables,
        role: data.role as Enum_User_Role_Enum,
      };

      /**
       * We are setting the extra variable session_token as null only when a user's role changes.
       * This way, users will need to log in again whenever their role is updated.
       */
      if (userDataToEdit && userDataToEdit.role !== data.role) {
        updateMutationVariables = { ...updateMutationVariables, session_token: null };
      }

      updateUser({
        variables: {
          _set: updateMutationVariables,
          id: Number(params.id),
        },
        update(cache, { data: updatedData }) {
          /* data to update */
          const dataToUpdate = updatedData?.update_users_by_pk;
          cache.modify({
            fields: {
              users(existingUsers: Array<Reference>, { readField }) {
                if (dataToUpdate) {
                  return existingUsers.map((userData) => {
                    if (dataToUpdate.id === readField('id', userData)) {
                      return dataToUpdate;
                    }
                    return userData;
                  });
                }
                return existingUsers;
              },
            },
          });
        },
      })
        .then(() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.success('User has been updated successfully');
          setBtnLoader(false);
          reset();
          navigate('/management/users');
        })
        .catch((error: ApolloError) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          message.error(errorHandlerForAddOrEditUserForm(error));
          logger(error);
          setBtnLoader(false);
        });
    }
  };

  return (
    <>
      <RequiredMessage />
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormItem
          label="Name"
          isRequired
          errorText={errors && errors.name ? errors.name.message : undefined}
          requiredMark="after"
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter name of user"
            name="name"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Email"
          errorText={errors && errors.email ? errors.email.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter email of user"
            name="email"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Mobile"
          isRequired
          requiredMark="after"
          errorText={errors && errors.mobile ? errors.mobile.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter mobile number of user"
            name="mobile"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="DOB"
          errorText={errors && errors.dateOfBirth ? errors.dateOfBirth.message : undefined}
          {...formItemStyleProps}
        >
          <DatePicker
            name="dateOfBirth"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
          />
        </FormItem>
        <FormItem
          label="Address"
          isRequired
          requiredMark="after"
          errorText={errors && errors.address ? errors.address.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            name="address"
            isTextAreaInput={true}
            placeholder="Please enter address of user"
            rhfControllerProps={{
              control,
            }}
            customStyles={inputComponentCommonStyle}
            textAreaProps={{ rows: 4 }}
          />
        </FormItem>
        <FormItem
          label="City"
          isRequired
          requiredMark="after"
          errorText={errors && errors.city ? errors.city.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter city of user"
            name="city"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="State"
          errorText={errors && errors.state ? errors.state.message : undefined}
          isRequired
          requiredMark="after"
          {...formItemStyleProps}
        >
          <Select
            customStyles={inputComponentCommonStyle}
            placeholder="Please select state of user"
            name="state"
            rhfControllerProps={{
              control,
            }}
            selectProps={{ showSearch: true }}
            options={statesOptionsForSelect}
          />
        </FormItem>
        <FormItem
          label="Pincode"
          isRequired
          requiredMark="after"
          errorText={errors && errors.pincode ? errors.pincode.message : undefined}
          {...formItemStyleProps}
        >
          <Input
            customStyles={inputComponentCommonStyle}
            placeholder="Please enter pincode of user"
            name="pincode"
            rhfControllerProps={{
              control,
            }}
          />
        </FormItem>
        <FormItem
          label="Role"
          isRequired
          requiredMark="after"
          errorText={errors && errors.role ? errors.role.message : undefined}
          {...formItemStyleProps}
        >
          <RadioGroup
            name="role"
            rhfControllerProps={{
              control,
            }}
            options={[
              { label: 'Staff', value: 'staff' },
              { label: 'Manager', value: 'manager' },
              { label: 'Admin', value: 'admin' },
            ]}
          />
        </FormItem>
        <FormItem {...formItemStyleProps} customStyle={{ marginBottom: 20 }}>
          <>
            <Button
              htmlType="submit"
              type="primary"
              loading={btnLoader}
              style={{ marginRight: 10 }}
            >
              {mode === 'edit' ? 'Update' : 'Create user'}
            </Button>
            <Button
              type="default"
              onClick={() => {
                navigate('/management/users');
              }}
            >
              Cancel
            </Button>
          </>
        </FormItem>
      </form>
    </>
  );
};

export default AddOrEditUserForm;
