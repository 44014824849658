import React, { useState } from 'react';
import { Card, Col, Divider, Row, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  GetInwardShipmentsForMonitoringQueryQuery,
  GetInwardShipmentsForMonitoringQueryQueryVariables,
} from '../../../../graphql/graphql-types';
import FormItem from '../../../../components/FormItem';
import RadioGroup from '../../../../components/RadioGroup';
import colors from '../../../../scss/variables.module.scss';

/* loading get inward shipments for monitoring query */
const getInwardShipmentsForMonitoringQuery = loader(
  '../../../../graphql/queries/getInwardShipmentsForMonitoringQuery.graphql',
);

/* inward Select Form Type */
type InwardSelectFormType = {
  /* status */
  status: string;
};

/* React functional component */
const MonitorShipmentsInward = () => {
  /* extracting navigate from useNavigate() */
  const navigate = useNavigate();

  /* status state used to stored filter status value  */
  const [status, setStatus] = useState<'all' | 'open' | 'unloaded' | 'adminOpen'>('all');

  /*  get inward shipments for monitoring query */
  const {
    data: getInwardShipmentsForMonitoringData,
    loading: getInwardShipmentsForMonitoringLoading,
    error: getInwardShipmentsForMonitoringError,
  } = useQuery<
    GetInwardShipmentsForMonitoringQueryQuery,
    GetInwardShipmentsForMonitoringQueryQueryVariables
  >(getInwardShipmentsForMonitoringQuery, {
    fetchPolicy: 'network-only',
  });

  /* useForm Declaration */
  const { control } = useForm<InwardSelectFormType>({
    defaultValues: {
      status: 'all',
    },
  });

  /* shipments used to stored filter data  */
  let shipments;
  if (getInwardShipmentsForMonitoringData) {
    shipments = getInwardShipmentsForMonitoringData.getInwardShipmentsForMonitoring.filter(
      (shipment) => {
        if (status === 'all') {
          return shipment;
        }
        return shipment.status === status;
      },
    );
  }

  /* show loading indicator on the screen until data get fetch from the server */
  if (getInwardShipmentsForMonitoringLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getInwardShipmentsForMonitoringError) {
    return <div className="errorText">{getInwardShipmentsForMonitoringError.message}</div>;
  }

  return (
    <>
      <FormItem label="Filter by Status : " labelColSpan={2}>
        <RadioGroup
          name="status"
          rhfControllerProps={{
            control,
          }}
          defaultValue={'all'}
          options={[
            { label: 'All', value: 'all' },
            {
              label: 'Open',
              value: 'open',
            },
            {
              label: 'Unloaded',
              value: 'unloaded',
            },
            {
              label: 'Admin Open',
              value: 'adminOpen',
            },
          ]}
          onChange={(e) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setStatus(e.target.value);
          }}
        />
      </FormItem>
      <div style={{ marginTop: '30px' }}>
        <Row gutter={[16, 16]}>
          {shipments &&
            shipments.map((shipment) => {
              return (
                <Col span={8} key={shipment.id} style={{ marginBottom: 20 }}>
                  <Card
                    bordered
                    style={{ width: '90%' }}
                    type="inner"
                    hoverable
                    onClick={() => {
                      navigate(`paddy/${shipment.id}`);
                    }}
                  >
                    <span style={{ fontWeight: 'bold', color: colors.grnTextColor }}>
                      {shipment.grn}
                    </span>
                    <span style={{ float: 'right' }}>
                      {dayjs(shipment.createdAt).format('MMM DD, YYYY [at] h:mm A')}
                    </span>
                    <br />
                    {shipment.vehicleNumber}
                    <br />
                    {shipment.driverName}
                    <Divider />
                    <div style={{ textAlign: 'center' }}>
                      {shipment.status === 'open' && 'Unloading'}
                      {shipment.status === 'unloaded' && 'Unloading Done'}
                      {shipment.status === 'adminOpen' && 'Ready for admin processing'}
                    </div>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </div>
    </>
  );
};

export default MonitorShipmentsInward;
