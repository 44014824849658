import React, { useRef } from 'react';
import { Button, Col, Descriptions, Modal, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { InwardShipmentItemsTableType, RawMaterialInwardShipmentType } from '../../../utils/types';
import { InwardShipmentItems, Mills } from '../../../graphql/graphql-types';
import logo from '../../../../src/assets/images/sarda_group_logo.png';
import { useReactToPrint } from 'react-to-print';
import SignatureComponent from '../../../components/SignatureComponent';

/* material unloading print Screen prop type */
type MaterialUnloadingPrintScreenPropType = {
  /* this prop type used to handle modal visibility */
  isModalOpen: boolean;
  /* prop type used to close form modal */
  closeModal: () => void;
  /* prop type used to store array of inward shipment items */
  inwardShipmentsItems: Array<InwardShipmentItemsTableType>;
  /* prop type used to store shipment data which is selected to unload */
  shipmentSelectedToUnload: RawMaterialInwardShipmentType | undefined;
  /* prop type used to refetch get all material unloading query after finishing material unloading */
  refetchingGetAllMaterialUnloadingData: () => void;
  /* prop type used to get mill details */
  millDetails: Pick<Mills, 'address' | 'company_name' | 'name' | 'id'> | null;
};

/* type for material unloading print table */
type MaterialUnloadingPrintTableType = Pick<
  InwardShipmentItems,
  | 'seller'
  | 'rawMaterial'
  | 'paddyGrade'
  | 'bagsCount'
  | 'materialReturned'
  | 'emptyBagsReturned'
  | 'destination'
  | 'godown'
  | 'mill'
>;

/* React functional component */
const MaterialUnloadingPrintScreen = ({
  isModalOpen,
  closeModal,
  inwardShipmentsItems,
  shipmentSelectedToUnload,
  refetchingGetAllMaterialUnloadingData,
  millDetails,
}: MaterialUnloadingPrintScreenPropType) => {
  /* extracting navigate from useNavigate hook */
  const navigate = useNavigate();

  /** const to store reference which is used to print content */
  const printRef = useRef(null);

  /** const used to call 'react-to-print' print method*/
  const handlePrint = useReactToPrint({
    /* content for printing */
    content: () => printRef.current,
  });

  const materialUnloadingPrintLayout = (
    <div className="printModal">
      <Row style={{ textAlign: 'center', alignItems: 'center' }}>
        <Col span={6}>
          <img src={logo} alt="logo" width={80} height={72} />
        </Col>
        <Col span={13}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: 10 }}>|| Shri Ganeshay Namah ||</span>
            <b style={{ fontSize: 17 }} className="millNameStyle">
              {millDetails && millDetails.company_name
                ? millDetails.company_name.toUpperCase()
                : ''}
            </b>
            <span>{(millDetails && millDetails.address) || ''}</span>
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <span>
                <b>GSTIN : </b> 27AAFFB7204E1ZU
              </span>
              <span>
                <b>FSSAI :</b>
                {millDetails &&
                millDetails.name &&
                millDetails.name.toLowerCase().includes('bhagwati')
                  ? 11517059000099
                  : 11521999000463}
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Col
        span={24}
        style={{ fontWeight: 'bold', fontSize: 15, marginTop: 10, textAlign: 'center' }}
        className="printBillInUIHeader"
      >
        Material Unloaded Details
      </Col>
      <Col
        span={24}
        style={{
          fontWeight: 'bold',
          fontSize: 15,
          marginTop: 10,
          textAlign: 'center',
          display: 'none',
        }}
        className="printOfficeCopyHeader"
      >
        Material Unloaded Details (Office Copy)
      </Col>
      <Col
        span={24}
        style={{
          fontWeight: 'bold',
          fontSize: 15,
          marginTop: 10,
          textAlign: 'center',
          display: 'none',
        }}
        className="printSellerCopyHeader"
      >
        Material Unloaded Details (Seller Copy)
      </Col>
      <Row>
        <Col span={17}>
          <Descriptions column={1} className="printDescriptions">
            <Descriptions.Item label="GRN">
              {shipmentSelectedToUnload && shipmentSelectedToUnload.grn}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={7}>
          <Descriptions column={1} className="printDescriptions">
            <Descriptions.Item label="Vehicle No.">
              {shipmentSelectedToUnload && shipmentSelectedToUnload.vehicleNumber}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Col
        span={24}
        style={{ fontWeight: 500, fontSize: 14, marginTop: 10, textAlign: 'left' }}
        className="boldContentInPrint"
      >
        Shipment Items
      </Col>
      <Col span={24} style={{ textAlign: 'start', alignItems: 'center' }}>
        <Table<MaterialUnloadingPrintTableType>
          dataSource={(inwardShipmentsItems as MaterialUnloadingPrintTableType[]) || []}
          size="small"
          rowKey="id"
          className="tableStyle historyTable"
          bordered
          pagination={false}
        >
          <Table.Column<MaterialUnloadingPrintTableType>
            title="Farmer/Trader (Purchase Name)"
            align="center"
            dataIndex="paddyTypesAndNoOfBags"
            key="paddyTypesAndNoOfBags"
            render={(text, record) => {
              return (record.seller && record.seller.name) || '-';
            }}
          />
          <Table.Column<MaterialUnloadingPrintTableType>
            title="Material Type"
            align="center"
            dataIndex="paddyTypesAndNoOfBags"
            key="paddyTypesAndNoOfBags"
            render={(text, record) => {
              return (record.rawMaterial && record.rawMaterial.name) || '-';
            }}
          />
          <Table.Column<MaterialUnloadingPrintTableType>
            title="Paddy Grade"
            align="center"
            dataIndex="paddyTypesAndNoOfBags"
            key="paddyTypesAndNoOfBags"
            render={(text, record) => {
              return record.paddyGrade || '-';
            }}
          />
          <Table.Column<MaterialUnloadingPrintTableType>
            title="No. Of Bags"
            align="center"
            dataIndex="paddyTypesAndNoOfBags"
            key="paddyTypesAndNoOfBags"
            render={(text, record) => {
              return record.bagsCount || '-';
            }}
          />
          <Table.Column<MaterialUnloadingPrintTableType>
            title="Empty Bags Returned"
            align="center"
            dataIndex="paddyTypesAndNoOfBags"
            key="paddyTypesAndNoOfBags"
            render={(text, record) => {
              if (record.materialReturned) {
                return '-';
              } else if (record.emptyBagsReturned) {
                return 'Yes';
              } else {
                return 'No';
              }
            }}
          />
          <Table.Column<MaterialUnloadingPrintTableType>
            title="Unloading Location"
            align="center"
            dataIndex="paddyTypesAndNoOfBags"
            key="paddyTypesAndNoOfBags"
            render={(text, record) => {
              if (record.destination) {
                if (record.destination === 'godown') {
                  return (
                    <span style={{ whiteSpace: 'nowrap' }}>
                      Godown : {(record.godown && record.godown.name) || '-'}
                    </span>
                  );
                } else {
                  return (
                    <span style={{ whiteSpace: 'nowrap' }}>
                      Mill : {millDetails && millDetails.name}
                    </span>
                  );
                }
              } else {
                return 'Returned';
              }
            }}
          />
        </Table>
      </Col>
      <SignatureComponent />
    </div>
  );

  return (
    <Modal
      visible={isModalOpen}
      width={1100}
      className="printModalLayoutStyle"
      footer={[
        <span className="hideInPrint" style={{ marginRight: 20, fontWeight: 'bold' }}>
          Do you wish to print ?
        </span>,
        <Button
          type="primary"
          key="print"
          className="hideInPrint"
          onClick={() => {
            handlePrint();
          }}
        >
          Print
        </Button>,
        <Button
          type="default"
          key="skip"
          className="hideInPrint"
          onClick={() => {
            /* refetch get all material unloading query data, so that we can get updated unloading shipments list */
            refetchingGetAllMaterialUnloadingData();
            /* close modal */
            closeModal();
            navigate('../truckOut');
          }}
        >
          Skip
        </Button>,
      ]}
      onCancel={() => {
        /* refetch get all material unloading query data, so that we can get updated unloading shipments list */
        refetchingGetAllMaterialUnloadingData();
        /* close modal */
        closeModal();
      }}
      bodyStyle={{ textAlign: 'center', fontSize: 12 }}
      destroyOnClose
    >
      <div className="printRefDiv" ref={printRef}>
        {materialUnloadingPrintLayout}
        <div className="printDuplicateMaterialUnloadingBill" style={{ display: 'none' }}>
          {materialUnloadingPrintLayout}
        </div>
      </div>
    </Modal>
  );
};

export default MaterialUnloadingPrintScreen;
