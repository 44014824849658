import axios from 'axios';
import { loader } from 'graphql.macro';
import { ApolloClient, ApolloError } from '@apollo/client';
import {
  FileUploadS3UrlQueryVariables,
  FileUploadS3UrlQuery,
  GetWeighbridgeWeightQueryVariables,
  GetWeighbridgeWeightQuery,
} from '../graphql/graphql-types';
import { AddressType } from './types';

/* Function used to log errors */
export const logger = (error: Error | ApolloError): void => {
  console.log('message', error.message);
  console.log('error name', error.name);

  /* If apollo errors */
  if ((error as ApolloError).graphQLErrors) {
    const apolloError = error as ApolloError;
    console.log('ApolloError.extraInfo', apolloError.extraInfo);
    console.log('ApolloError.graphQLErrors', apolloError.graphQLErrors);
  }
  // any other type of error
  else {
    console.log(error.stack);
    console.log(error);
  }
};

// ************  function to handle single file upload ************* //

// loading the create User query with the help of loader
const fileUploadS3UrlQuery = loader('../graphql/queries/fileUploadS3UrlQuery.graphql');

// handleSingleFileUploadFuc which is called for upload functionality,this function accepts two argument
// one is client which is apolloClient use to call query and one is fileData which store file object returned from antd upload/Dragger
export const handleSingleFileUploadFunc = async (client: ApolloClient<any>, fileData: File) => {
  const res = await client.query<FileUploadS3UrlQuery, FileUploadS3UrlQueryVariables>({
    query: fileUploadS3UrlQuery,
    variables: {
      fileName: fileData.name,
      contentType: fileData.type,
    },
  });
  // const to store query result data
  const fileAWSData = res.data.fileUploadS3Url;
  if (fileAWSData) {
    const {
      key,
      policy,
      algorithm,
      credential,
      date,
      signature,
      contentType,
      contentDisposition,
      url,
    } = fileAWSData;
    // calling javascript FormData to create new formData object.
    const fileFormData = new FormData();
    fileFormData.append('key', key);
    fileFormData.append('policy', policy);
    fileFormData.append('x-amz-algorithm', algorithm);
    fileFormData.append('x-amz-credential', credential);
    fileFormData.append('x-amz-date', date);
    fileFormData.append('x-amz-signature', signature);
    fileFormData.append('Content-Type', contentType);
    fileFormData.append('Content-Disposition', contentDisposition);
    fileFormData.append('file', fileData);

    // sending post request store file data on server
    const fileResult = await axios.post(url, fileFormData);
    // returning key when the user has uploaded a file in order to pass the key in the respective mutation
    if (fileResult) {
      return key;
    }
  } else {
    throw new Error('Upload failed');
  }
};

// ************  function to handle render amount in purchase   ************* //
/* function to return the calculated amount of individual purchase order item  */
export const renderAmount = (
  orderedItemsCount: number,
  unitPrice: number,
  gstPercent: number,
  itemDiscount: number,
) => {
  /** const to store gst value, if gst is included then performing below calculation otherwise for 0 gst calculation value will be 1  */
  const gstCalculation = gstPercent === 0 ? 1 : 1 + 0.01 * gstPercent;

  const amount = (orderedItemsCount || 0) * (unitPrice || 0) * gstCalculation;

  const discountedAmount = amount * (itemDiscount / 100);

  const amountAfterDiscount = amount - discountedAmount;

  return { amountAfterDiscount, discountedAmount };
};

/* addressFormatFunction function for format of address in table  */
export const addressFormatFunction = (
  rawAddress: AddressType,
  addressFormatType: 'singleLine' | 'doubleLine',
) => {
  /* Destructuring address,city,state,pincode from rawAddress */
  const { address, city, state, pincode } = rawAddress || {};
  /* if address,city,pincode,state are present, display address in final format; else, display '-' */
  if (address || city || state || pincode) {
    /*  addressArray stored address,city,pincode,state */
    const addressArray = [];
    /* if Address present push into addressArray */
    if (address) {
      addressArray.push(address);
    }
    /* if city present push into addressArray  */
    if (city) {
      addressArray.push(city);
    }
    /* if state present and addressFormatType is singleLine then state push into addressArray  */
    if (state && addressFormatType === 'singleLine') {
      addressArray.push(state);
    }
    /*  addressWithSeparator stored address,city,state with ',' separator*/
    const addressWithSeparator = addressArray.join(', ');
    /* stored final address with ',' separator  */
    let finalAddress = addressWithSeparator;
    /* if addressFormatType is singleLine then show address in single line with pincode */
    if (addressFormatType === 'singleLine') {
      if (finalAddress && pincode) {
        /* if address,city,state preset in final address before pincode show '-' */
        finalAddress = `${finalAddress} - ${pincode}`;
      } else {
        /* if address,city,state is not preset in final address then before pincode dont show '-' */
        finalAddress = pincode;
      }
    } else {
      /* stored state and pincode for display at second line */
      const stateAndPincodeArray = [];
      /* if state present push into stateAndPincodeArray */
      if (state) {
        stateAndPincodeArray.push(state);
      }
      /* if pincode present push into stateAndPincodeArray */
      if (pincode) {
        stateAndPincodeArray.push(pincode);
      }
      /*  secondLineAddress stored state and pincode with ',' separator*/
      const secondLineAddress = stateAndPincodeArray.join('-');
      /* if addressFormatType is doubleLine  add final address  secondLineAddress at newLine else only show secondLine address at first Line  */
      finalAddress = finalAddress ? `${finalAddress}\n${secondLineAddress}` : secondLineAddress;
    }
    return finalAddress;
  } else {
    return '-';
  }
};

/* this function used to handle AddOrEditVendorForm errors */
export const errorHandlerForAddOrEditVendorForm = (error: ApolloError): string => {
  if (
    error.message ===
    'Uniqueness violation. duplicate key value violates unique constraint "rmSellers_gstin_key"'
  ) {
    return 'GST number is already registered';
  }
  return error.message;
};

/* this function used to find object for required 'id' from object array. */
export const findObjectForIdFromObjectArray = (
  /* this prop type used to store id , using this id we will search in object array */
  id: number,
  /* we use this object array to find object */
  objectArray: Array<{ id: number; name: string }>,
) => {
  /* if the required id is present in the object array, this const will store the object of that id,
  else it will be undefined. */
  const value = objectArray.find((object) => object.id === id);
  /* return object of that id */
  return value ? value : null;
};

// this function used to convert kg to quintal
export const convertKgsToQuintals = (val: number) => {
  return val / 100;
};

// this function used to convert quintal to kgs
export const convertQuintalsToKgs = (val: number) => {
  return val * 100;
};

// loading the getWeighbridgeWeightQuery with the help of loader
const getWeighbridgeWeightQuery = loader('../graphql/queries/getWeighbridgeWeightQuery.graphql');

//function use to get the weight of vehicle standing on weighbridge with the help of  weighbridge server
export const handleGetWeightFunction = async (client: ApolloClient<any>) => {
  try {
    // calling query to get weight of vehicle from weighbridge server
    const {
      data: { getWeighbridgeWeight },
    } = await client.query<GetWeighbridgeWeightQuery, GetWeighbridgeWeightQueryVariables>({
      query: getWeighbridgeWeightQuery,
      fetchPolicy: 'network-only',
    });

    return (getWeighbridgeWeight && getWeighbridgeWeight.weight) || 0;
  } catch (error) {
    throw new Error((error as ApolloError).message);
  }
};
