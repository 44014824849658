import { Enum_Item_Category_Enum } from '../../graphql/graphql-types';

type ItemCategoryDataPropsType = {
  // path of current screen
  locationPathname: string;
};

/** function to get itemCategory key, value and material name based on screen  */
const getItemCategoryData = ({ locationPathname }: ItemCategoryDataPropsType) => {
  if (locationPathname.includes('paddyBags')) {
    return {
      value: Enum_Item_Category_Enum.PaddyBag,
      key: 'paddyBags',
      materialName: 'Paddy Bags',
    };
  } else if (locationPathname.includes('productBags')) {
    return {
      value: Enum_Item_Category_Enum.ProductBag,
      key: 'productBags',
      materialName: 'Product Bags',
    };
  } else {
    return {
      value: Enum_Item_Category_Enum.Others,
      key: 'otherItems',
      materialName: 'Other Items',
    };
  }
};

export default getItemCategoryData;
