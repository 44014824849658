import React from 'react';
import {
  GetAllInwardShipmentForBagsQuery,
  GetAllInwardShipmentForBagsQueryVariables,
} from '../../../../graphql/graphql-types';
import { useQuery } from '@apollo/client';
import { loader } from 'graphql.macro';
import { Spin } from 'antd';
import InwardShipmentBagOrOtherItemReportTable from '../../InwardShipmentBagOrOtherItemReportTable';
import { InwardShipmentsReportType } from '../../../../utils/types';

/* loading get all inward bags shipment data query */
const getAllBagsQuery = loader(
  '../../../../graphql/queries/getAllInwardShipmentForBagsQuery.graphql',
);

/* react functional component */
const InwardShipmentBag = () => {
  /* get all inward bags shipment data query */
  const {
    data: getAllBagsShipmentData,
    loading: getAllBagsShipmentDataLoading,
    error: getAllBagsShipmentDataError,
  } = useQuery<GetAllInwardShipmentForBagsQuery, GetAllInwardShipmentForBagsQueryVariables>(
    getAllBagsQuery,
    {
      // we are fetching all data from query
      variables: {
        createdAt: {},
      },
    },
  );

  /* show loading indicator on the screen until data get fetch from the server */
  if (getAllBagsShipmentDataLoading) {
    return <Spin className="loadingSpinner" />;
  }

  /* show error text on the screen. if it has any error while loading data from the server */
  if (getAllBagsShipmentDataError) {
    return <div className="errorText">{getAllBagsShipmentDataError.message}</div>;
  }

  return (
    <>
      {getAllBagsShipmentData ? (
        <InwardShipmentBagOrOtherItemReportTable
          data={getAllBagsShipmentData.inwardShipments as InwardShipmentsReportType[]}
          inwardShipmentType="bags"
        />
      ) : null}
    </>
  );
};

export default InwardShipmentBag;
