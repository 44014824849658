import React from 'react';
import { Payment } from '../../graphql/graphql-types';

/* type for amount calculation */
type PaymentDetailsType = Pick<
  Payment,
  'amount' | 'created_at' | 'deductions' | 'id' | 'inward_shipment_id'
>[];

/**
 * Function to calculate the total paid amount and total deduction for specific GRN
 * 'sellerPaymentData' is array of objects representing payment data for different shipments and
 * 'id' is inward shipment id which is used to get seller payment details
 */
const getTotalPaidAndDeductionAmount = (sellerPaymentData: PaymentDetailsType | [], id: number) => {
  /** const to store payment details for specific shipment  */
  const sellerPaymentDataByGRN =
    sellerPaymentData && sellerPaymentData.filter((item) => item.inward_shipment_id === id);

  /* condition to check payment data for specific shipment is present or not */
  if (sellerPaymentDataByGRN) {
    const calculatedAmount = sellerPaymentDataByGRN.reduce(
      (acc, item) => {
        // Add the item's amount to the totalPaidAmount
        acc.totalPaidAmount += Number(item.amount);

        // Check for deductions and add them to the totalDeductedAmount
        if (item.deductions && Array.isArray(item.deductions) && item.deductions.length > 0) {
          acc.totalDeductedAmount += item.deductions.reduce(
            (deductionAcc: number, deduction: { amount: number }) => {
              return deductionAcc + (deduction.amount || 0);
            },
            0,
          );
        }

        return acc;
      },
      { totalPaidAmount: 0, totalDeductedAmount: 0 },
    );

    return {
      totalPaidAmount: calculatedAmount.totalPaidAmount,
      deductedAmount: calculatedAmount.totalDeductedAmount,
    };
  }

  return {
    totalPaidAmount: 0,
    deductedAmount: 0,
  };
};

export default getTotalPaidAndDeductionAmount;
